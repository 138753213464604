import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Table, Button
    , Segment, Grid, Form, Input
    , Checkbox

} from 'semantic-ui-react'
import InputMask from 'react-input-mask';


import If from '../../utils/If';

class Parceiros extends Component {

    state = { query: '' }
    constructor(props) {
        super(props);
    }

    componentDidMount() {


    }

    handleFormSubmit = (e) => {
        this.props.ProcessamentosListParceiroAction(this.state.query).then(d => { })
    }

    handleInputChange = (e) => {
        this.setState({
            query: e.target.value
        });
    }

    updateCheckBoxICMS(e, { checked, name }) {
        if (name == 'ad_fixartabb2b') {
            this.props.ProcessamentosChangeParceiroAction({ target: { 'name': name, 'value': (checked ? 1 : 0) } })
        } else {
            this.props.ProcessamentosChangeParceiroAction({ target: { 'name': name, 'value': (checked ? 'S' : 'N') } })
        }

    }

    handleSave = (acao) => {
        if (this.props.issavingparceiro === false) {
            this.props.ProcessamentosSaveParceiroAction(this.props.ProcessamentosParceiroEntity, acao).then(d => { })
        }
    }

    render() {

        let { ProcessamentosParceiroEntity } = this.props

        return (
            <Segment>
                <Grid columns={1} relaxed='very'>
                    <Grid.Column verticalAlign='middle'>
                        <Form>

                            <Input
                                action={{ color: 'blue', content: 'Buscar', onClick: this.handleFormSubmit.bind(this) }}
                                icon='search'
                                iconPosition='left'
                                placeholder='CodParc #'
                                onChange={this.handleInputChange.bind(this)}
                                loading={this.props.issearchingparceiro}
                                maxLength={10}
                            />
                        </Form>

                    </Grid.Column>
                    <If test={this.props.ProcessamentosParceiroEntity.codparc != 0}>
                        <Grid.Column>
                            <Table definition>
                                <Table.Body>
                                    <Table.Row>
                                        <Table.Cell width={2}>Parceiro</Table.Cell>
                                        <Table.Cell>{ProcessamentosParceiroEntity.nomeparc}</Table.Cell>
                                    </Table.Row>
                                    <Table.Row>
                                        <Table.Cell>ICMS</Table.Cell>
                                        <Table.Cell>
                                            <Checkbox toggle
                                                checked={(ProcessamentosParceiroEntity.ipiincicms === 'S' ? true : false)}
                                                onChange={this.updateCheckBoxICMS.bind(this)}
                                                name="ipiincicms"
                                            />

                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Tabela de Preço</Table.Cell>
                                        <Table.Cell>
                                            <Checkbox toggle
                                                checked={(ProcessamentosParceiroEntity.ad_fixartabb2b === 1 ? true : false)}
                                                onChange={this.updateCheckBoxICMS.bind(this)}
                                                name="ad_fixartabb2b"
                                            />
                                            CodTab Atual: {ProcessamentosParceiroEntity.codtab}
                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>Classificação</Table.Cell>
                                        <Table.Cell>
                                            {ProcessamentosParceiroEntity.classificacaofin}
                                        </Table.Cell>
                                    </Table.Row>

                                    <Table.Row>
                                        <Table.Cell>E-Mail B2B:</Table.Cell>
                                        <Table.Cell>
                                            <Input
                                                //action={{ color: 'blue', content: 'Buscar' }}
                                                icon='mail'
                                                iconPosition='left'
                                                placeholder='Email'
                                                name='ad_emailb2b'
                                                value={ProcessamentosParceiroEntity.ad_emailb2b}
                                                onChange={this.props.ProcessamentosChangeParceiroAction}
                                                //loading={this.props.issearchingparceiro}
                                                maxLength={70}
                                                style={{width:300}}
                                            />{ProcessamentosParceiroEntity.parceirosemail}
                                        </Table.Cell>
                                    </Table.Row>



                                </Table.Body>
                            </Table>
                        </Grid.Column>
                        <Grid.Column>
                            <Button primary loading={this.props.issavingparceiro} onClick={e => this.handleSave('save')}>Salvar</Button>
                            <Button secondary onClick={e => this.handleSave('cancelar')}>Cancelar</Button>
                        </Grid.Column>
                    </If>

                </Grid>
            </Segment>
        )
    }



}

Parceiros.defaultProps = {
    issavingparceiro: false
    , issearchingparceiro: false
}

Parceiros.propTypes = {
    //exportar: PropTypes.array.isRequired
    //, CadastroDeProdutosExportListAction: PropTypes.func.isRequired
    ProcessamentosParceiroEntity: PropTypes.object.isRequired
    , issavingparceiro: PropTypes.bool.isRequired
    , issearchingparceiro: PropTypes.bool.isRequired
    , ProcessamentosListParceiroAction: PropTypes.func.isRequired
    , ProcessamentosChangeParceiroAction: PropTypes.func.isRequired
    , ProcessamentosSaveParceiroAction: PropTypes.func.isRequired
}

export default Parceiros