import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import { NumericFormat } from 'react-number-format';
import {
    Grid, Form, Icon, Label
    , Modal, Button, Header, Table, Message, Dropdown
    , Input, TextArea
} from 'semantic-ui-react'
import {
    despesasDeViagensChegadasInsertAdicionalAction
    , despesasDeViagensListAdicionalAction
} from '../../actions/DespesasDeViagensActions'
import If from '../../utils/If';

import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
    max-width: 700px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 4px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class ModalDespesas extends Component {

    constructor(props) {
        super(props);
        this.state = {
            selectedNatureza: '',
            selectedNaturezaName: '',
            selectedDate: '',
            valor: '',
            observacoes: '',
            despesasadicionais: [],
            errors: {
                selectedNatureza: false,
                selectedDate: false,
                valor: false
            }
        };
    }

    generateDateOptions(startDate, endDate) {
        const start = moment(startDate);
        const end = moment(endDate);
        const options = [];

        while (start <= end) {
            options.push({
                text: start.format('DD/MM/YYYY'),
                value: start.format('YYYY-MM-DD')
            });
            start.add(1, 'days');
        }

        return options;
    }

    handleSave = () => {
        const { selectedNatureza, selectedNaturezaName, selectedDate, valor, observacoes, despesasadicionais } = this.state;
        const valorNumerico = this.convertToNumber(valor);

        const errors = {
            selectedNatureza: !selectedNatureza,
            selectedDate: !selectedDate,
            valor: !valor || valorNumerico > 500
        };

        if (errors.selectedNatureza || errors.selectedDate || errors.valor) {
            this.setState({
                errors: errors
            });
            return;
        }

        const newDespesa = {
            natureza: selectedNatureza,
            naturezaName: selectedNaturezaName,
            date: selectedDate,
            valor: valorNumerico,
            observacoes: observacoes,
            qtde: 1,
            ordemcarga: this.props.entidade.ordemcarga,
            idviagem: this.props.entidade.idviagem,
            codparcmotorista: this.props.entidade.codmotorista,
            errors: {
                selectedNatureza: false,
                selectedDate: false,
                valor: false
            }
        };


        this.setState({
            despesasadicionais: [...despesasadicionais, newDespesa],
            selectedNatureza: '',
            selectedNaturezaName: '',
            selectedDate: '',
            valor: '',
            observacoes: '',
            ordemcarga: '',
            idviagem: '',
            codpaarcmotorista: '',
            errors: {
                selectedNatureza: false,
                selectedDate: false,
                valor: false
            }
        });
        console.log(despesasadicionais)


        console.log('Nova despesa adicionada:', newDespesa);
    }

    handleRemove = (index) => {
        const { despesasadicionais } = this.state;
        const newDespesasAdicionais = [...despesasadicionais];
        newDespesasAdicionais.splice(index, 1);
        this.setState({ despesasadicionais: newDespesasAdicionais });
    }

    handleDropdownChange = (e, { name, value, options }) => {
        const selectedOption = options.find(option => option.value === value);
        this.setState({
            selectedNatureza: value,
            selectedNaturezaName: selectedOption ? selectedOption.text : ''
        });
    }
    handleChange = (e, { name, value }) => {
        this.setState({ [name]: value });
    }

    handleValueChange = (values) => {
        const { formattedValue } = values;
        this.setState({ valor: formattedValue });
    }
    convertToNumber(value) {
        // Remove prefixo "R$" e espaços
        let numericValue = value.replace(/[R$\s]/g, '');
        // Substitui apenas o último separador de milhar (se houver)
        numericValue = numericValue.replace(/\./g, '').replace(',', '.');
        return parseFloat(numericValue);
    }
    handleConfirm = () => {
        const { despesasadicionais } = this.state;

        // Chame as funções de ação aqui     depois que o estado for atualizado
        this.props.despesasDeViagensChegadasInsertAdicionalAction(despesasadicionais).then(() => {
            this.props.handleSave(this.props.entidade)



        });


    }

    state = {}




    onValidSubmit = (formData) => {
        //this.form.reset()
        //this.props.handleSavePesquisa()
    }



    componentWillReceiveProps() {




    }

    componentDidMount() {




    }


    render() {
        let { entidade } = this.props

        let totalmotorista = 0
        let totaladicional = 0
        let totalajudanteum = 0

        let totalajudantedois = 0

        let despesasmotorista = []
        let despesasajudanteum = []
        let despesasajudantedois = []
        const { despesasadicionais, selectedNatureza, selectedDate, valor, observacoes } = this.state;



        despesasmotorista = this.props.despesas.filter(i => i.codparcmotorista != 0)
        despesasajudanteum = this.props.despesas.filter(i => i.codparcajudante != 0)
        despesasajudantedois = this.props.despesas.filter(i => i.codparcajudanteduplo != 0)


        const despesasmotoristaSum = [
            ...despesasmotorista.reduce(
                (map, item) => {
                    const { idviagemdespesa: key, qtde } = item;
                    const prev = map.get(key);

                    if (prev) {
                        prev.qtde += qtde
                    } else {
                        map.set(key, Object.assign({}, item))
                    }

                    return map
                },
                new Map()
            ).values()
        ]



        const despesasajudanteumSum = [
            ...despesasajudanteum.reduce(
                (map, item) => {
                    const { idviagemdespesa: key, qtde } = item;
                    const prev = map.get(key);

                    if (prev) {
                        prev.qtde += qtde
                    } else {
                        map.set(key, Object.assign({}, item))
                    }

                    return map
                },
                new Map()
            ).values()
        ]

        const despesasajudantedoisSum = [
            ...despesasajudantedois.reduce(
                (map, item) => {
                    const { idviagemdespesa: key, qtde } = item;
                    const prev = map.get(key);

                    if (prev) {
                        prev.qtde += qtde
                    } else {
                        map.set(key, Object.assign({}, item))
                    }

                    return map
                },
                new Map()
            ).values()
        ]
        const dateOptions = this.generateDateOptions(entidade.dtsaida, entidade.dtprevchegada);


        const natureza = [
            { text: 'Estacionamento', value: 10 },
            { text: 'Despesas Gerais com Veiculo', value: 8 },
            { text: 'Combustível', value: 7 },
            { text: 'Pedágio', value: 25 },
            { text: 'Chapa', value: 6 },
            { text: 'Despesas Diversas', value: 9 }
        ];
        return (
            <Modal

                trigger={this.props.botao}
                open={this.props.entidade.modalOpen}
                onClose={(e) => this.props.handleClose(this.props.entidade)}
            >
                <Modal.Header>Despesas de Viagem</Modal.Header>
                <Modal.Content image>

                    <Modal.Description>

                        <Form>
                            <Form.Group widths='equal' color='gray'>
                                <div className="field"><label>Ordem de Carga</label>
                                    <div className="ui fluid input">{entidade.ordemcarga}</div>
                                </div>

                                <div className="field"><label>Veículo:</label>
                                    <div className="ui fluid input">{entidade.veiculo}</div>
                                </div>

                                <div className="field"><label>Data de Saída</label>
                                    <div className="ui fluid input">{moment(entidade.dtsaida).format('DD/MM/YYYY HH:mm')}</div>
                                </div>

                                <div className="field"><label>Data de Chegada:</label>
                                    <div className="ui fluid input">{moment(entidade.dtprevchegada).format('DD/MM/YYYY HH:mm')}</div>
                                </div>
                            </Form.Group>
                        </Form>


                        <Header>Motorista: {entidade.motorista}</Header>

                        <Form>
                            <h3>Despesas Adicionais</h3>
                            <Form.Group color='gray'>
                                <div className="field" style={{ width: '245px' }}>
                                    <label>Natureza</label>
                                    <Form.Field error={this.state.errors.selectedNatureza}>
                                        <Dropdown
                                            options={natureza}
                                            selection
                                            placeholder='Filtros'
                                            name='selectedNatureza'
                                            fluid
                                            onChange={this.handleDropdownChange}
                                            value={selectedNatureza}
                                        />
                                    </Form.Field>
                                </div>
                                <div className="field" style={{ width: '165px' }}>
                                    <label>Data:</label>
                                    <Form.Field error={this.state.errors.selectedDate}>
                                        <Dropdown
                                            options={dateOptions}
                                            selection
                                            placeholder='Selecione a Data'
                                            name='selectedDate'
                                            fluid
                                            onChange={this.handleChange}
                                            value={selectedDate}
                                        />
                                    </Form.Field>
                                </div>
                                <div className="field" style={{ width: '120px' }}>
                                    <label>Valor:</label>
                                    <Form.Field error={this.state.errors.valor}>
                                        <NumericFormat
                                            thousandSeparator="."
                                            decimalSeparator=","
                                            decimalScale={2}
                                            fixedDecimalScale={true}
                                            prefix="R$ "
                                            placeholder='Valor R$'
                                            customInput={Form.Input}
                                            onValueChange={this.handleValueChange}
                                            value={valor}
                                        />
                                    </Form.Field>
                                </div>
                                <div className="field" style={{ width: '220px' }}>
                                    <label>Observação:</label>
                                    <Form.Field
                                        control={TextArea}
                                        id='observacoes'
                                        name='observacoes'
                                        maxLength={140}
                                        rows='1'
                                        onChange={this.handleChange}
                                        value={observacoes}
                                    />
                                </div>
                                <div className="field" style={{ marginLeft: '20px' }}>
                                    <label>&nbsp;</label>
                                    <Button primary onClick={this.handleSave}>Salvar</Button>
                                </div>
                            </Form.Group>
                        </Form>
                        <If test={despesasadicionais.length > 0}>
                            <StyledTable celled>
                                <StyledTableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell width={1}>Data</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Observação.</Table.HeaderCell>
                                        <Table.HeaderCell width={2}>Valor</Table.HeaderCell>
                                        <Table.HeaderCell width={1}></Table.HeaderCell>
                                    </Table.Row>
                                </StyledTableHeader>
                                <StyledTableBody key="bodyadicional">
                                    {despesasadicionais.map((despesa, index) => (
                                        (
                                            totaladicional += parseFloat(despesa.valor) * despesa.qtde
                                        ),
                                        <Table.Row key={index}>
                                            <Table.Cell>{moment(despesa.date).format('DD/MM/YYYY')}</Table.Cell>
                                            <Table.Cell >{despesa.naturezaName}</Table.Cell>
                                            <Table.Cell>{despesa.observacoes}</Table.Cell>
                                            <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                            <Table.Cell>
                                                <Button size="mini" icon color='red' onClick={() => this.handleRemove(index)}>
                                                    <Icon name='trash' />
                                                </Button>
                                            </Table.Cell>
                                        </Table.Row>
                                    ))
                                    }
                                </StyledTableBody>
                                <StyledTableFooter >
                                    <Table.Row>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell><b>Total</b></Table.HeaderCell>
                                        <Table.HeaderCell><Label color='green' horizontal>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totaladicional)}</Label></Table.HeaderCell>
                                    </Table.Row>
                                </StyledTableFooter>
                            </StyledTable>
                        </If>

                        <div style={{ marginBottom: '30px' }}></div>

                        <If test={despesasmotorista.length == 0}>
                            <Message warning>
                                <p>Não existem despesas</p>
                            </Message>
                        </If>



                        <If test={despesasmotorista.length > 0}>
                            <StyledTable celled>
                                <StyledTableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell width={6}>Despesa</Table.HeaderCell>
                                        <Table.HeaderCell width={1}>Qtde.</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Valor Unit.</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Valor Total</Table.HeaderCell>

                                    </Table.Row>
                                </StyledTableHeader>

                                <StyledTableBody key="bodymotorista">
                                    {
                                        despesasmotoristaSum.map(data => (
                                            (
                                                totalmotorista += data.valor * data.qtde
                                            ),
                                            <Table.Row key={'m' + data.idviagemdespesa}>
                                                <Table.Cell>{data.despesa}</Table.Cell>
                                                <Table.Cell textAlign="center"><Label horizontal size="tiny">{data.qtde}</Label></Table.Cell>
                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.valor)}</Table.Cell>
                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.valor * data.qtde)}</Table.Cell>

                                            </Table.Row>
                                        ))
                                    }


                                </StyledTableBody>

                                <StyledTableFooter>
                                    <Table.Row>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell><Label color='green' horizontal>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalmotorista)}</Label></Table.HeaderCell>
                                    </Table.Row>
                                </StyledTableFooter>
                            </StyledTable>
                        </If>

                        <Header>Ajudante: {entidade.ajudanteum}</Header>
                        <If test={despesasajudanteum.length == 0}>
                            <Message warning>
                                <p>Não existem despesas</p>
                            </Message>
                        </If>

                        <If test={despesasajudanteum.length > 0}>
                            <StyledTable celled>
                                <StyledTableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell width={6}>Despesa</Table.HeaderCell>
                                        <Table.HeaderCell width={1}>Qtde.</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Valor Unit.</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Valor Total</Table.HeaderCell>

                                    </Table.Row>
                                </StyledTableHeader>

                                <StyledTableBody key="bodyajudanteum">
                                    {
                                        despesasajudanteumSum.map(data => (
                                            (
                                                totalajudanteum += data.valor * data.qtde
                                            ),
                                            <Table.Row key={'a1' + data.idviagemdespesa}>
                                                <Table.Cell>{data.despesa}</Table.Cell>
                                                <Table.Cell textAlign="center"><Label horizontal size="tiny">{data.qtde}</Label></Table.Cell>
                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.valor)}</Table.Cell>
                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.valor * data.qtde)}</Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </StyledTableBody>

                                <StyledTableFooter>
                                    <Table.Row>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell><Label color='green' horizontal>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalajudanteum)}</Label></Table.HeaderCell>
                                    </Table.Row>
                                </StyledTableFooter>
                            </StyledTable>
                        </If>










                        <Header>Ajudante 2º: {entidade.ajudantedois}</Header>
                        <If test={despesasajudantedois.length == 0}>
                            <Message warning>
                                <p>Não existem despesas</p>
                            </Message>
                        </If>

                        <If test={despesasajudantedois.length > 0}>
                            <StyledTable celled>
                                <StyledTableHeader>
                                    <Table.Row>
                                        <Table.HeaderCell width={6}>Despesa</Table.HeaderCell>
                                        <Table.HeaderCell width={1}>Qtde.</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Valor Unit.</Table.HeaderCell>
                                        <Table.HeaderCell width={3}>Valor Total</Table.HeaderCell>

                                    </Table.Row>
                                </StyledTableHeader>

                                <StyledTableBody key="bodyajudantedois">
                                    {
                                        despesasajudantedoisSum.map(data => (
                                            (
                                                totalajudantedois += data.valor * data.qtde
                                            ),
                                            <Table.Row key={'a2' + data.idviagemdespesa}>
                                                <Table.Cell>{data.despesa}</Table.Cell>
                                                <Table.Cell textAlign="center"><Label horizontal size="tiny">{data.qtde}</Label></Table.Cell>
                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.valor)}</Table.Cell>
                                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(data.valor * data.qtde)}</Table.Cell>
                                            </Table.Row>
                                        ))
                                    }
                                </StyledTableBody>

                                <StyledTableFooter>
                                    <Table.Row>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell></Table.HeaderCell>
                                        <Table.HeaderCell><Label color='green' horizontal>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalajudantedois)}</Label></Table.HeaderCell>
                                    </Table.Row>
                                </StyledTableFooter>
                            </StyledTable>
                        </If>






                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>
                    <Button color='red' onClick={(e) => this.props.handleClose(this.props.entidade)}>
                        <Icon name='remove' /> Cancelar
                    </Button>
                    <Button color='green' loading={this.props.entidade.issaving}
                        onClick={(e) => {

                            this.handleConfirm();
                        }}                    >
                        <Icon name='checkmark' /> Confirmar
                    </Button>
                </Modal.Actions>

            </Modal >
        )

    }

}

ModalDespesas.defaultProps = {
    entidade: {}
    , botao: {}
};

ModalDespesas.propTypes = {
    entidade: PropTypes.object.isRequired
    , botao: PropTypes.object.isRequired
    , handleChangeForm: PropTypes.func.isRequired
    , handleSave: PropTypes.func.isRequired
    , handleClose: PropTypes.func.isRequired
    , despesas: PropTypes.array.isRequired
};

const mapStateToProps = state => (
    {

    }
);

export default withRouter(connect(mapStateToProps, {
    despesasDeViagensChegadasInsertAdicionalAction,
    despesasDeViagensListAdicionalAction
}
)(ModalDespesas));


