import {
    COMISSOES_LIST
    , COMISSOES_VENDEDORES_LIST
    , COMISSOES_PEDIDO_ISSAVING
    , COMISSOES_CHANGE_VENDEDOR
    , COMISSOES_PEDIDO_UPDATE
    , COMISSOES_ISLOADING
    , COMISSOES_RELATORIO_LIST
    , COMISSOES_UPDATE_TICKET
} from '../actions/ActionsTypes'


const INITIAL_STATE = {
    comissoes: []
    , vendedores: []
    , isloading: true
    , comissoesrelatorio: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case COMISSOES_UPDATE_TICKET:
            return {
                ...state,
                comissoes: state.comissoes.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits ?
                        { ...pedido, ticketocta: action.payload }
                        : pedido
                )
            }


        case COMISSOES_RELATORIO_LIST:
            return { ...state, comissoesrelatorio: action.payload }

        case COMISSOES_LIST:
            return { ...state, comissoes: action.payload }

        case COMISSOES_ISLOADING:
            return { ...state, isloading: action.payload }

        case COMISSOES_VENDEDORES_LIST:
            return { ...state, vendedores: action.payload }

        case COMISSOES_PEDIDO_ISSAVING:
            return {
                ...state,
                comissoes: state.comissoes.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits && pedido.nunota === action.nunota ?
                        { ...pedido, issaving: action.issaving }
                        : pedido
                )
            }

        case COMISSOES_CHANGE_VENDEDOR:
            return {
                ...state,
                comissoes: state.comissoes.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits && pedido.nunota === action.nunota ?
                        { ...pedido, vendedorvcc: action.vendedorvcc }
                        : pedido
                )
            }

        case COMISSOES_PEDIDO_UPDATE:
            return {
                ...state,
                comissoes: state.comissoes.map(
                    (pedido, i) => pedido.pedidofbits === action.payload.pedidofbits && pedido.nunota === action.payload.nunota ?
                        action.payload
                        : pedido
                )
            }




        default:
            return state;
    }
}