import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import {
    Tab, Icon, Button, Table, Input, Message, Dropdown, ModalHeader,
    ModalDescription,
    ModalContent,
    ModalActions,
    Modal,
    Checkbox,
    ItemDescription,
} from 'semantic-ui-react';
import {
    permissoesListUsuariosMGRAction,
    permissoesUsuariosEditAction,
    permissoesUsuariosAtualizaAction,
    permissoesUsuariosChangeAction,
    permissoesUsuariosInsereAction,
    permissoesUsuariosCopiaAction,
    permissoesUsuariosSalvaAction

} from '../../actions/PermissoesActions';
import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }lengthU
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;
const StyledCell = styled(Table.Cell)`
&&&{
    div{
        font-size:15px !important;
    },
    span{
        font-size:15px !important;
      }
    }
`;

function Criarusuario(props) {

    //actions
    const { permissoesListUsuariosMGRAction
        , permissoesUsuariosEditAction
        , permissoesUsuariosAtualizaAction
        , permissoesUsuariosChangeAction
        , permissoesUsuariosInsereAction
        , permissoesUsuariosCopiaAction
        , permissoesUsuariosSalvaAction } = props;

    //props reducer
    const { usuarios, menu, userid, history } = props;

    const [formData, setFormData] = useState({
        login: '',
        email: '',
        vendedorvcc: '',
    });

    const [errors, setErrors] = useState([]);
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isLoadingCriar, setIsLoadingCriar] = useState(false);
    const [isLoadingSave, setIsLoadingSave] = useState(false);
    const [isLoadingList, setLoadingList] = useState(true);
    const [editedLogin, setEditedLogin] = useState('');
    const [editedEmail, setEditedEmail] = useState('');
    const [editedVendedorvcc, setEditedVendedorvcc] = useState('');
    const [logins, setLogins] = useState([]);
    const [origemSelecionada, setOrigemSelecionada] = useState(null);
    const [destinoSelecionado, setDestinoSelecionado] = useState(null);
    const [open, setOpen] = useState(false)
    const [userPopup, setUserPopup] = useState("")
    const [permissoesPopup, setPermissionsPopup] = useState([])
    const [checkedMenuItems, setCheckedMenuItems] = useState([]);
    const [selectedPermissions, setSelectedPermissions] = useState([]);
    const [usuarioId, setUsuarioId] = useState("")
    //---- mapeia todos usuarios do array criado para ser passado para o dropdown
    const stateOptions = usuarios.map((state) => ({
        key: state.usupedfbitsid,
        text: state.login,
        value: state.usupedfbitsid,
    }))

    // --------  func para nao deixar letras no campo vendedorvcc
    const handleInputChange = (field, value) => {
        if (field === 'vendedorvcc' && value !== '' && !/^\d+$/.test(value)) {
            return;
        }

        setFormData((prevData) => ({ ...prevData, [field]: value }));
    };

    //--- func para salvar ao passar permissoes de um usuario para outro
    const handleSalvarPermissoesClick = () => {
        const origem = origemSelecionada;
        const destino = destinoSelecionado;
        const result = { origem, destino }
        setIsLoading(true)

        permissoesUsuariosCopiaAction(result).then(d => {
            setSuccessMessage('Permissoes copiadas com sucesso!'); // Define a mensagem de sucesso
            // Limpar os campos após o sucesso
            setIsLoading(false);    // para de carregar
            setTimeout(() => {
                setErrors([]);
                setSuccessMessage('');  //limpa mensagem de sucesso
            }, 1000);
        });
    };

    // ------- func para salvar ao criar novo usuario
    const handleSalvarClick = () => {
        const { login, email, vendedorvcc } = formData;
        const newErrors = [];

        if (!login.trim()) {
            newErrors.push('O campo Login é obrigatório');
        }

        if (!email.trim()) {
            newErrors.push('O campo E-mail é obrigatório');
        }

        if (newErrors.length === 0) {
            // Simular uma requisição assíncrona


            setIsLoadingCriar(true);
            permissoesUsuariosInsereAction(formData).then((response) => {
                setSuccessMessage('Usuário criado com sucesso!'); // Define a mensagem de sucesso
                // Limpar os campos após o sucesso
                setIsLoadingCriar(false);
                setTimeout(() => {
                    setErrors([]);
                    setSuccessMessage('');  //limpa mensagem de sucesso
                    // para de carregar
                    setFormData({           //limpa os inputs
                        login: '',
                        email: '',
                        vendedorvcc: '',
                    });
                }, 1000);
            });

        } else {
            setErrors(newErrors);
        }
    };

    // ------ Effect para chamar as actions
    useEffect(() => {
        permissoesListUsuariosMGRAction().then((d) => {
            setLoadingList(false)
        });

        if (menu.filter(m => m.menuid === 26 && m.status === 1).length === 0) { history.push(`../Home`) }

    }, [menu]);

    // --------- botões de editar,salvar e cancelar

    const onEditingUsuario = (usupedfbitsid, login, email, vendedorvcc, isediting) => {

        setEditedLogin(login);
        setEditedEmail(email);
        setEditedVendedorvcc(vendedorvcc);

        permissoesUsuariosEditAction(usupedfbitsid, !isediting);
    };


    const onSalvarUsuario = (usuario) => {
        if (!usuario.issaving) {
            const entidade = {
                usupedfbitsid: usuario.usupedfbitsid,
                editedLogin: usuario.login,
                editedEmail: usuario.email,
                editedVendedorvcc: parseFloat(usuario.vendedorvcc)
            };
            permissoesUsuariosAtualizaAction(entidade).then(d => {
                // console.log(idviagemajudante, "id ajudante salvar edit")
                permissoesUsuariosEditAction(usuario.usupedfbitsid, false);
            });
        }
    }

    const onCancelarUsuario = (Usupedfbitsid, isediting) => {
        if (isediting === true) {
            permissoesUsuariosEditAction(Usupedfbitsid, false);
        }
    }

    const onOpenPopup = (usuario) => {
        setUserPopup(usuario);
        setOpen(true);
        setPermissionsPopup(JSON.parse(usuario.menus_geral));


        const userPermissions = JSON.parse(usuario.menus_usuario);
        setUsuarioId(usuario.usupedfbitsid)
        if (userPermissions === null || userPermissions === undefined) {
            const permsNull = []
            setSelectedPermissions(permsNull);
        } else {
            const perms = userPermissions.map((menuItem) => menuItem.menuid)
            setSelectedPermissions(perms);
        }
    };
    const onCheckboxChange = (menuItem) => {
        const menuid = menuItem.menuid;

        if (selectedPermissions.includes(menuid)) {
            // Se estiver presente, remova
            setSelectedPermissions((prevSelected) =>
                prevSelected.filter((id) => id !== menuid)
            );
        } else {
            // Se não estiver presente, adicione
            setSelectedPermissions((prevSelected) => [...prevSelected, menuid]);
        }
    };
    const handleSalvarClickModal = () => {

        const sendResult = { usuarioId, selectedPermissions }
        setIsLoadingSave(true)
        permissoesUsuariosSalvaAction(sendResult).then(d => {
            permissoesListUsuariosMGRAction()
            setOpen(false);
            setIsLoadingSave(false)
        });
    };
    return (
        <>
            {successMessage && (
                <Message success header="Sucesso!" content={successMessage} />
            )}
            {errors.length > 0 && (
                <Message
                    error
                    header="Houve alguns erros com o seu envio"
                    list={errors} // Exibe os erros aqui
                />
            )}

            <Tab.Pane style={{ margin: '0px' }}>
                <Modal
                    open={open}
                    onClose={() => setOpen(false)}
                    onOpen={() => setOpen(true)}

                >
                    <ModalHeader>{userPopup.login}</ModalHeader>
                    <ModalContent image scrolling>
                        <ModalDescription>
                            {permissoesPopup?.map(menuItem => (
                                <div key={menuItem.menuid}>
                                    <Checkbox
                                        label={menuItem.nome}
                                        checked={selectedPermissions.includes(menuItem.menuid)}
                                        onChange={() => onCheckboxChange(menuItem)}
                                    />
                                </div>
                            ))}
                        </ModalDescription>
                    </ModalContent>
                    <ModalActions>
                        <Button onClick={() => setOpen(false)} color='red'>
                            Cancelar  <Icon name='cancel' />
                        </Button>
                        <Button color='green' onClick={handleSalvarClickModal} loading={isLoadingSave}
                        >
                            Salvar<Icon name='check' />
                        </Button>
                    </ModalActions>

                </Modal>
                <StyledTable celled id="listPendentes" compact="very" size="">
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">Login do Mitra</Table.HeaderCell>
                            <Table.HeaderCell width="3">E-mail</Table.HeaderCell>
                            <Table.HeaderCell width="1">VendedorVCC</Table.HeaderCell>
                            <Table.HeaderCell width="2">Criar</Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    <Table.Row>
                        <Table.Cell>
                            <Input
                                value={formData.login}
                                maxLength={100}
                                onChange={(e) => handleInputChange('login', e.target.value)}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Input
                                value={formData.email}
                                onChange={(e) => handleInputChange('email', e.target.value)}
                                maxLength={100}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Input
                                value={formData.vendedorvcc}
                                onChange={(e) => handleInputChange('vendedorvcc', e.target.value)}
                                maxLength={100}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Button basic color="blue" onClick={handleSalvarClick} loading={isLoadingCriar}>
                                <Icon name="check" />
                                Criar
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </StyledTable>
            </Tab.Pane>

            <Tab.Pane style={{ margin: '0px' }} >
                <h3>Copiar Permissões</h3>
                <StyledTable celled id="listPendentes" compact="very" size="">
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">Usuario de Origem</Table.HeaderCell>
                            <Table.HeaderCell width="1">Usuario de Destino</Table.HeaderCell>
                            <Table.HeaderCell width="1">Salvar</Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    <Table.Row>
                        <Table.Cell>
                            <Dropdown
                                selection
                                name='Origem'
                                options={stateOptions}
                                fluid
                                placeholder='Origem'
                                search
                                onChange={(e, { value }) => setOrigemSelecionada(value)}
                            >
                            </Dropdown>
                        </Table.Cell>
                        <Table.Cell>
                            <Dropdown
                                selection
                                name='Destino'
                                options={stateOptions}
                                fluid
                                placeholder='Destino'
                                search
                                onChange={(e, { value }) => setDestinoSelecionado(value)}
                            >
                            </Dropdown>
                        </Table.Cell>
                        <Table.Cell>
                            <Button basic color="blue"
                                onClick={handleSalvarPermissoesClick}
                                loading={isLoading}
                            >
                                <Icon name="check" />
                                Salvar
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </StyledTable>
            </Tab.Pane>
            <Tab.Pane loading={isLoadingList}>
                <div key='1'>
                </div>
                <StyledTable celled id="listPendentes" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">Id</Table.HeaderCell>
                            <Table.HeaderCell width="3">Login</Table.HeaderCell>
                            <Table.HeaderCell width="5">E-mail</Table.HeaderCell>
                            <Table.HeaderCell width="1">VendedorVCC</Table.HeaderCell>
                            <Table.HeaderCell width="1">Funcionalidades</Table.HeaderCell>
                            <Table.HeaderCell>Ação</Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    <StyledTableBody>
                        {usuarios.map(item =>
                            <Table.Row key={"usuariosmgr" + item.usupedfbitsid}>
                                <Table.Cell style={{ textAlign: 'center' }}>{item.usupedfbitsid}</Table.Cell>
                                <StyledCell>
                                    {(!item.isediting ? item.login
                                        :
                                        <Input
                                            value={item.login}
                                            name='login'
                                            onChange={(e) => permissoesUsuariosChangeAction(e, item)}
                                        //onChange={(e) => setEditedLogin(e.target.value)}
                                        //onChange={(e) => handleInputUsuarioLoginChange(e, item.USUPEDFBITSID)}
                                        />
                                    )}
                                </StyledCell>
                                <StyledCell>
                                    {(!item.isediting ? item.email
                                        :
                                        <Input
                                            value={item.email}
                                            name='email'
                                            onChange={(e) => permissoesUsuariosChangeAction(e, item)}
                                            style={{ width: '100%' }}
                                        />
                                    )}
                                </StyledCell>
                                <StyledCell>
                                    {(!item.isediting ? item.vendedorvcc
                                        :
                                        <Input
                                            size='small'
                                            value={item.vendedorvcc}
                                            name='vendedorvcc'
                                            onChange={(e) => permissoesUsuariosChangeAction(e, item)}
                                            style={{ width: '100px' }}
                                        />
                                    )}
                                </StyledCell>
                                <Table.Cell>
                                    <Button
                                        onClick={() => onOpenPopup(item)}
                                        basic color='blue' size='mini'>
                                        Permissões({item.qtd_permissoes})
                                    </Button>
                                </Table.Cell>
                                <StyledCell>
                                    {item.isediting ? (
                                        <div>
                                            <Button
                                                onClick={() => onSalvarUsuario(item)}
                                                basic color='blue'
                                                loading={item.issaving}
                                                size='mini'>
                                                <Icon name='check' />
                                                Salvar
                                            </Button>
                                            <Button
                                                onClick={() => onCancelarUsuario(item.usupedfbitsid, item.isediting)}
                                                basic color='red'
                                                size='mini'
                                            >
                                                <Icon name='cancel' />
                                                Cancelar
                                            </Button>
                                        </div>
                                    ) : (
                                        <Button
                                            loading={item.isediting}
                                            onClick={() => onEditingUsuario(item.usupedfbitsid, item.login, item.email, item.vendedorvcc, item.isediting)}
                                            basic
                                            color='green'
                                            size='mini'
                                        >
                                            <Icon name='pencil' />
                                            Editar
                                        </Button>
                                    )}
                                </StyledCell>
                            </Table.Row>
                        )}
                    </StyledTableBody>
                </StyledTable>
            </Tab.Pane>
        </>
    );
}


const mapStateToProps = (state) => ({
    menu: state.LoginReducer.menu
    , userid: state.LoginReducer.userid
    , usuarios: state.PermissoesReducer.usuarios

})


export default withRouter(connect(mapStateToProps, {
    permissoesListUsuariosMGRAction,
    permissoesUsuariosEditAction,
    permissoesUsuariosAtualizaAction,
    permissoesUsuariosChangeAction,
    permissoesUsuariosInsereAction,
    permissoesUsuariosCopiaAction,
    permissoesUsuariosSalvaAction
}
)(Criarusuario));

