import sortBy from 'sort-by';

import {
    GRAFICODEVENDAS_LIST
    , GRAFICODEVENDAS_LOADER
    , GRAFICODEVENDAS_POPUP_FATURAR
    , GRAFICODEVENDAS_POPUP_PAGAR
    , GRAFICODEVENDAS_POPUP_FATURAR_LOADER
    , GRAFICODEVENDAS_POPUP_PAGAR_LOADER

} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    graficodevendas: []
    , mes: []
    , loaderGraficodevendas: true
    , meses: []
    , vendedores: []
    , graficodevendas_popup_faturar: []
    , graficodevendas_popup_faturar_loader: true
    , graficodevendas_popup_pagar: []
    , graficodevendas_popup_pagar_loader: true
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case GRAFICODEVENDAS_LIST:
            return { ...state, graficodevendas: action.vendas, mes: action.mes, meses: action.meses, vendedores: action.vendedores }

        case GRAFICODEVENDAS_POPUP_FATURAR:
            return { ...state, graficodevendas_popup_faturar: action.payload }

        case GRAFICODEVENDAS_POPUP_PAGAR:
            return { ...state, graficodevendas_popup_pagar: action.payload }

        case GRAFICODEVENDAS_POPUP_FATURAR_LOADER:
            return { ...state, graficodevendas_popup_faturar_loader: action.payload }

        case GRAFICODEVENDAS_POPUP_PAGAR_LOADER:
            return { ...state, graficodevendas_popup_pagar_loader: action.payload }

        case GRAFICODEVENDAS_LOADER:
            return { ...state, loaderGraficodevendas: action.payload }

        default:
            return state;
    }
}