import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { lifecycle } from "recompose";

import moment from 'moment';
import 'moment/locale/pt-br'

import {
    appEntregasHandleChangeAction
    , appEntregasHandleCancelarAction
    , appEntregasHandleSearchAction
} from '../../actions/AppEntregasActions';

import {
    Table, Button, Input, Form, Segment, Message, Tab, Icon, Menu, Breadcrumb, Grid, Checkbox
    , Header, Image
} from 'semantic-ui-react';

import ReactToPrint from "react-to-print";

import If from '../../utils/If';

import styled from 'styled-components';

import ReactExport from "react-data-export";


import _ from "lodash";
import { compose, withProps } from "recompose";
import {
    withScriptjs,
    withGoogleMap,
    GoogleMap,
    Marker,
    DirectionsRenderer
} from "react-google-maps";
/* global google */

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;








const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


const MyMap = compose(
    withProps({
        googleMapURL:
            "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `450px` }} />,
        mapElement: <div style={{ height: `100%` }} />
    }),
    withScriptjs,
    withGoogleMap
)(props => (
    <GoogleMap defaultZoom={17} defaultCenter={{ lat: props.lat, lng: props.lng }}>
        <Marker position={{ lat: props.lat, lng: props.lng }} />
    </GoogleMap>
));

//js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs

const MapWithADirectionsRenderer = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `400px` }} />,
        mapElement: <div style={{ height: `100%` }} />,
    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const DirectionsService = new google.maps.DirectionsService();

            DirectionsService.route({
                origin: new google.maps.LatLng(41.8507300, -87.6512600),
                destination: new google.maps.LatLng(41.8525800, -87.6514100),
                travelMode: google.maps.TravelMode.DRIVING,
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                    });
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    })
)(props =>
    <GoogleMap
        defaultZoom={7}
        defaultCenter={new google.maps.LatLng(41.8507300, -87.6512600)}
    >
        {props.directions && <DirectionsRenderer directions={props.directions} />}
    </GoogleMap>
);





const MapWithADirectionsRenderer2 = compose(
    withProps({
        googleMapURL: "https://maps.googleapis.com/maps/api/js?key=AIzaSyDkYZGyXi8QjtnjZOwfJj-qg5uilkPpSzs&v=3.exp&libraries=geometry,drawing,places",
        loadingElement: <div style={{ height: `100%` }} />,
        containerElement: <div style={{ height: `500px` }} />,
        mapElement: <div style={{ height: `100%` }} />,


    }),
    withScriptjs,
    withGoogleMap,
    lifecycle({
        componentDidMount() {
            const DirectionsService = new google.maps.DirectionsService();

            DirectionsService.route({
                //origin: new google.maps.LatLng(-20.0398, -47.7803),
                //destination: new google.maps.LatLng(-18.903,-47.1549),
                origin: new google.maps.LatLng(-22.88086, -47.12631),//esplane
                destination: new google.maps.LatLng(-22.92645, -47.03623),//casa do rodrigo
                travelMode: google.maps.TravelMode.DRIVING,
              
  
                


                waypoints: [

                    //B-C
                    { location: new google.maps.LatLng(-22.8883141, -47.1260743) },
                    { location: new google.maps.LatLng(-22.8883264, -47.126030) },


                    //D-E
                    { location: new google.maps.LatLng(-22.8883264, -47.1260301) },
                    { location: new google.maps.LatLng(-22.8949103, -47.123015) },

                    //F-G
                    { location: new google.maps.LatLng(-22.8949103, -47.123015) },
                    { location: new google.maps.LatLng(-22.8949647, -47.1231002) },


                    { location: new google.maps.LatLng(-22.8949647, -47.1231002) },
                    { location: new google.maps.LatLng(-22.8984197, -47.1121537) },

                    /*
                    
                    { location: new google.maps.LatLng(00000, 111111) },
                    { location: new google.maps.LatLng(22222, 333333) },
                    */

          
                    






                ]
            }, (result, status) => {
                if (status === google.maps.DirectionsStatus.OK) {
                    this.setState({
                        directions: result,
                    });
                    
                } else {
                    console.error(`error fetching directions ${result}`);
                }
            });
        }
    })
)(props =>
    <GoogleMap
        defaultZoom={7}
        defaultCenter={new google.maps.LatLng(41.8507300, -87.6512600)}
    >
        {props.directions && <DirectionsRenderer directions={props.directions} />}
    </GoogleMap>
);






class Mapas extends Component {

    state = {}

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 24 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------








    }







    render() {

        let { consulta, parametros, issearching } = this.props;

        let { dadosnf } = this.props;

        let panes = [];


        let validacampo = () => {

        }





        panes.push({
            menuItem: <Menu.Item key='TABpar'><Icon name='list alternate outline' />Exemplos de Mapa</Menu.Item>,
            render: () =>
                <Tab.Pane>
                    <Grid columns={1} relaxed='very'>
                        <Grid.Column verticalAlign='middle'>
                            <Form>

                                <Input
                                    action={{
                                        color: 'blue', content: 'Buscar', onClick: this.props.appEntregasHandleSearchAction
                                    }}
                                    icon='search'
                                    iconPosition='left'
                                    placeholder='Nota Fiscal #'
                                    onChange={this.props.appEntregasHandleChangeAction}
                                    loading={this.props.issearchingnf}
                                    name='nf'
                                    maxLength={10}
                                    value={this.props.searchnf}
                                />
                            </Form>

                        </Grid.Column>


                        <Grid.Column>



                            <div ref={el => (this.componentRef = el)}>


                                <table width="100%" border="0">

                                    <tr>
                                        <td colSpan='3'>

                                            <table cellPadding='0' cellSpacing='0' width='100%'>
                                                <tr>
                                                    <td><Image src='img/logo_esplane.png' size='small' align="left" valign="middle" /></td>
                                                    <td><b>COMPROVANTE DE ENTREGA DA MERCADORIA</b></td>
                                                </tr>
                                            </table>


                                        </td>

                                    </tr>


                                    <tr>
                                        <td>
                                            <MapWithADirectionsRenderer2 key="mapcheckin2"
                                            />
                                        </td>
                                    </tr>

                                    <tr>
                                        <td>
                                            <MyMap key="mapcheckin"
                                                lat={Number.parseFloat(-20.0553)}
                                                lng={Number.parseFloat(-47.7867)}
                                            />
                                        </td>
                                    </tr>


                                    <tr>
                                        <td>
                                            <MapWithADirectionsRenderer key="mapcheckin2"
                                            />
                                        </td>
                                    </tr>
















                                </table>





                            </div>


                        </Grid.Column>
                        {dadosnf.length > 0 && (
                            <Grid.Column>
                                <ReactToPrint
                                    trigger={() => <Button color='green'>Imprimir</Button>}
                                    content={() => this.componentRef}
                                    pageStyle="@media print {pre, blockquote, table, div {page-break-inside: avoid; border: 1px solid #ffff;}}"
                                //onAfterPrint={(e) =>this.props.handlePrint(this.props.datalist)}


                                />

                                <Button secondary
                                    onClick={e => this.props.appEntregasHandleCancelarAction()}
                                >Cancelar</Button>
                            </Grid.Column>
                        )}


                    </Grid>
                </Tab.Pane>
        })

        return (

            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>APP Entregas </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>


                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>


            </div>

        )

    }

}


const mapStateToProps = state => ({
    menu: state.LoginReducer.menu

    , searchnf: state.AppEntregasReducer.searchnf
    , dadosnf: state.AppEntregasReducer.dadosnf
    , issearchingnf: state.AppEntregasReducer.issearchingnf


})

export default withRouter(connect(mapStateToProps, {
    appEntregasHandleChangeAction
    , appEntregasHandleCancelarAction
    , appEntregasHandleSearchAction
}
)(Mapas));
