import {
    LOGIN_POST
    , LOGIN_MENU_POST
    , LOGIN_REDIRECT
} from './ActionsTypes'

import * as Api from '../utils/API';
import sortBy from 'sort-by';


export const loginRedirectAction = (url) => {
    return (dispatch) => {
        dispatch({ type: LOGIN_REDIRECT, payload: url })
    }
}

export const loginValidAction = (isAuthenticated, history) => {
    return (dispatch) => {
        if (!isAuthenticated) history.push(`/`);
    };
}

/*
export const loginPostAction = (formData, history) => {
    return (dispatch) => {
        Api.postLogin(formData).then(result => {
            if(result.status==='Allowed'){
                dispatch({ type: LOGIN_POST, isAuthenticated: true, login:result.login })
                sessionStorage.setItem("isAuthenticated", true );
                sessionStorage.setItem("login", result.login);
                history.push(`/PainelPedidos`);
            }else{
                dispatch({ type: LOGIN_POST, isAuthenticated: false, login:"" })
                sessionStorage.setItem("isAuthenticated",false)
                sessionStorage.setItem("login","");
                history.push(`/`);
            }            
        });

    };
}
*/


export const loginPostAction = (formData) => (dispatch) =>
    new Promise(function (resolve, reject) {





        Api.postLogin(formData).then(result => {
            if (result.status === 'Allowed') {

                console.log(result)
                dispatch({ type: LOGIN_POST, isAuthenticated: true, login: result.login, userid: result.usupedfbitsid, vendedorvcc: result.vendedorvcc, nomeparc: result.nomeparc })

                sessionStorage.setItem("isAuthenticated", true);
                sessionStorage.setItem("login", result.login);
                sessionStorage.setItem("userid", result.usupedfbitsid);
                sessionStorage.setItem("vendedorvcc", result.vendedorvcc);
                sessionStorage.setItem("nomeparc", result.nomeparc);

                let usupedfbitsid = result.usupedfbitsid;

                Api.postToken(formData).then(resulttok => {
                    let token = resulttok.access_token;

                    localStorage.setItem('tokenAPI', token);

                    Api.getLoginMenu(usupedfbitsid).then(res => {
                        sessionStorage.setItem("menu", JSON.stringify(res));
                        dispatch({ type: LOGIN_MENU_POST, payload: res })
                        resolve({ status: "OK", msg: "" })
                    })
                });



                //history.push(`/PainelPedidos`);
            } else {
                dispatch({ type: LOGIN_POST, isAuthenticated: false, login: "", userid: 0 })
                sessionStorage.setItem("isAuthenticated", false)
                sessionStorage.setItem("login", "");
                sessionStorage.setItem("userid", 0);
                sessionStorage.setItem("vendedorvcc", "");
                sessionStorage.setItem("nomeparc", "");

                resolve({ status: "Invalid", msg: "Usuário ou senha inválidos" })
                //history.push(`/`);
            }
        });



    });






