import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Container,
  Divider,
  Segment,
  List,
  Image,
  Tab,
  Checkbox,
  Label,
  Header,
  Icon,
  Modal,
  Input,
  Form,
  TextArea,
  Dropdown,
  Popup,
} from "semantic-ui-react";
import { Table, Confirm } from "semantic-ui-react";
import DateTimePicker from "react-datetime-picker";
import moment from "moment";
import logScreen from "./LogScreen";
import LogScreen from "./LogScreen";
import styled from "styled-components";
import parse from "html-react-parser";
import { CopyToClipboard } from "react-copy-to-clipboard";

const inlineStyle = {
  modal: {
    marginTop: "0px !important",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;

class ListASair extends Component {
  state = { isSaving: false, openEntrega: false, isSavingNSaiu: false };

  constructor(props) {
    super(props);
    let {
      handleCHKInsertEmRota,
      handleCHKRemoveEmRota,
      handleCHKUpdateEmRota,
      handleSavePedidosNaoEntregue,
    } = this.props;

    this.updateStateList = this.updateStateList.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeModalOpen = this.onChangeModalOpen.bind(this);
    this.onChangeModalClose = this.onChangeModalClose.bind(this);
    this.onChangePedido = this.onChangePedido.bind(this);
    this.onChangeDevolucao = this.onChangeDevolucao.bind(this);
  }

  onChangeDevolucao(e, pedido, carga) {
    if (!pedido.isupdatingdevolucao) {
      //console.log('#############')
      //console.log(pedido)
      //console.log('@@@@@@@@@@@')
      this.props.handleDevolucao(pedido, carga);
    }
  }

  updateStateList(e, { checked, value, pedido }) {
    this.setState({ isSaving: false });

    if (checked) {
      this.props.handleCHKInsertEmRota(pedido);
    } else {
      this.props.handleCHKRemoveEmRota(pedido);
    }
  }

  onChangeDate(e, pedido) {
    this.setState({ date: e });
    pedido.dataentrega = e;
    this.props.handleCHKUpdateEmRota(pedido);
  }

  componentDidMount() {
    let { handlecargasNaoEntregueQtdeChange } = this.props;
  }

  onChangeModalOpen(e, pedido) {
    this.props.handlePedidoModal(pedido, true, false);
  }

  onChangeModalClose(e, pedido) {
    this.props.handlePedidoModal(pedido, false, false);
  }

  onChangePedido(e, pedido) {
    pedido.ad_trackndescr = e.target.value;
    pedido.alterado = true;
    this.props.handlePedido(pedido);
  }

  onChangePedidoMotivo(e, data) {
    data.pedido.ad_tracknentid = data.value;
    data.pedido.alterado = true;
    this.props.handlePedido(data.pedido);
  }

  onSaveMotivo(e, data) {
    if (data.pedido.ad_trackndescr != null && data.pedido.ad_tracknentid != -1)
      this.props.handlePedidoModal(data.pedido, false, true);
  }

  onCancelMotivo(e, data) {
    data.pedido.alterado = false;
    this.props.handlePedidoModal(data.pedido, false, false);
  }

  onSavePedido(e) {
    this.setState({ isSaving: true });
    if (this.state.isSaving == false) {
      let pedidos = this.props.chkEmRota;

      for (let p of pedidos) {
        p.ad_tracknusuari = this.props.login;
      }

      this.props.handleSavePedidosEmRota(pedidos).then((d) => {
        this.setState({ isSaving: false });
      });
    }
  }

  /*openEntrega = () => this.setState({ openEntrega: true })
    closeEntrega = () => this.setState({ openEntrega: false })
    */

  //Frete
  onChangeFreteOpen = (e, ordem) => {
    this.props.handleASairFreteOpenClose(ordem, true);
  };

  onChangeFreteClose = (e, ordem) => {
    // alert(`close ${ordem.id}`)
    this.props.handleASairFreteOpenClose(ordem, false);
  };

  onChangeFreteCheck = (e, ordem) => {
    this.props.handleASairFreteCheck(ordem, !ordem.fretecheck);
  };
  onChangeFreteSave = (e, ordem) => {
    //alert(`save ${ordem.id}`)
    if (!ordem.isfretesaving && ordem.fretecheck) {
      this.props.handleASairFreteSave(ordem);
    }
  };

  onChangeModalMotivoNSaiuOpen(e, pedido) {
    //this.props.handlePedidoModal(pedido, true, false);
    this.setState({ isSavingNSaiu: false });
    this.props.handlePedidoModalMotivoNSaiu(pedido, true, false);
  }

  onChangeModalMotivoNSaiuClose(e, pedido) {
    //this.props.handlePedidoModal(pedido, false, false);
    this.props.handlePedidoModalMotivoNSaiu(pedido, false, false);
  }

  onChangePedidoMotivoNSaiu(e, data) {
    data.pedido.ad_tracknentidnsaiu = data.value;
    data.pedido.alteradonsaiu = true;
    //this.props.handlePedido(data.pedido);
    this.props.handlePedidoMotivoNSaiu(data.pedido);
  }

  onChangePedidoNSaiu(e, pedido) {
    pedido.ad_trackndescrnsaiu = e.target.value;
    pedido.alteradonsaiu = true;
    this.props.handlePedidoMotivoNSaiu(pedido);
  }

  onSavePedidoNSaiu(e, data) {
    this.setState({ isSavingNSaiu: true });

    //this.props.handleSavePedidosNaoEntregue()
    if (!this.state.isSavingNSaiu) {
      this.props.handleSavePedidosNSaiu(data.pedido).then((d) => {
        this.setState({ isSavingNSaiu: false });
      });
    }

    //alert('salvar')
  }

  onCancelMotivoNSaiu(e, data) {
    data.pedido.alterado = false;
    //this.props.handlePedidoModal(data.pedido, false, false);
    this.props.handlePedidoModalMotivoNSaiu(data.pedido, false, false);
  }

  getMotivo = (id, motivos) => {
    let motivo = "";
    if (motivos.length > 0) {
      try {
        motivo = motivos.find((m) => m.value.toString() == id).text + " | ";
      } catch (e) {
        motivo = "";
      }
    }
    return motivo;
  };

  render() {
    let { cargas, loader, chkEmRota, motivos } = this.props;

    const popstyle = {
      whiteSpace: "normal",
    };

    return (
      <Tab.Pane loading={loader}>
        {cargas.map((carga) => (
          <div key={carga.id}>
            <List horizontal id={carga.id} size="small">
              <List.Item>
                <List.Content>
                  <List.Header>Ordem: {carga.id}</List.Header>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Placa: {carga.placa}</List.Header>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>Motorista: {carga.motorista}</List.Header>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>
                    Data da Ordem:{" "}
                    {moment(carga.dataordemcarga).format("DD/MM/YYYY")}
                  </List.Header>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>
                    Transportadora: {carga.transportadora}
                  </List.Header>
                </List.Content>
              </List.Item>
              <List.Item>
                <List.Content>
                  <List.Header>
                    <Modal
                      trigger={
                        <Button
                          basic
                          icon={"location arrow"}
                          color={"blue"}
                          size="mini"
                          onClick={(e) => this.onChangeFreteOpen(e, carga)}
                        />
                      }
                      open={carga.freteopen}
                      onClose={(e) => this.onChangeFreteClose(e, carga)}
                    >
                      <Modal.Header>
                        Ordem de Carga: {carga.id} - Confirma que todos pedidos
                        listados serão marcados como "em Rota" ?
                      </Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <StyledTable
                            celled
                            id={carga.id}
                            compact="very"
                            size="small"
                          >
                            <StyledTableHeader>
                              <Table.Row>
                                <Table.HeaderCell width="1">
                                  FBits
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1">
                                  Data Pedido
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1">
                                  Nota
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  Data Nota
                                </Table.HeaderCell>
                                <Table.HeaderCell width="3">
                                  Parceiro
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  Cidade
                                </Table.HeaderCell>
                              </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                              {carga.pedidos.map((pedido) => (
                                <Table.Row key={pedido.numnota}>
                                  <Table.Cell>{pedido.pedidofbits}</Table.Cell>
                                  <Table.Cell>
                                    {moment(pedido.datapedido).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>{pedido.numnota}</Table.Cell>
                                  <Table.Cell>
                                    {moment(pedido.datanf).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>{pedido.parceiro}</Table.Cell>
                                  <Table.Cell>
                                    {pedido.cidadeparceiro}
                                  </Table.Cell>
                                </Table.Row>
                              ))}
                            </StyledTableBody>
                          </StyledTable>
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          color="red"
                          onClick={(e) => this.onChangeFreteClose(e, carga)}
                        >
                          <Icon name="remove" /> Cancelar
                        </Button>
                        <Button
                          color="green"
                          loading={carga.isfretesaving}
                          onClick={(e) => this.onChangeFreteSave(e, carga)}
                        >
                          <Icon name="checkmark" /> Confirmar
                        </Button>

                        <Checkbox
                          toggle={true}
                          onChange={(e) => this.onChangeFreteCheck(e, carga)}
                          name="fretecheck"
                          label="Estou ciente"
                          checked={carga.fretecheck}
                        />
                      </Modal.Actions>
                    </Modal>
                  </List.Header>
                </List.Content>
              </List.Item>
            </List>

            <StyledTable celled id={carga.id} compact="very" size="small">
              <StyledTableHeader>
                <Table.Row>
                  <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                  <Table.HeaderCell width="1">Data Pedido</Table.HeaderCell>
                  <Table.HeaderCell width="1">Nota</Table.HeaderCell>
                  <Table.HeaderCell width="2">Data Nota</Table.HeaderCell>
                  <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                  <Table.HeaderCell width="2">Cidade</Table.HeaderCell>
                  <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                  <Table.HeaderCell width="1">Saiu em Rota</Table.HeaderCell>
                  <Table.HeaderCell width="1">Não Saiu</Table.HeaderCell>
                  <Table.HeaderCell width="1">Devolução</Table.HeaderCell>
                </Table.Row>
              </StyledTableHeader>

              <StyledTableBody>
                {carga.pedidos.map((pedido) => (
                  <Table.Row
                    key={pedido.numnota}
                    warning={pedido.isatrasado == 1 ? true : false}
                    negative={pedido.isatrasado == 2 ? true : false}
                  >
                    <Table.Cell>{pedido.pedidofbits}</Table.Cell>
                    <Popup
                      trigger={
                        <Table.Cell>
                          {moment(pedido.datapedido).format("DD/MM/YYYY")}
                        </Table.Cell>
                      }
                      inverted
                      style={popstyle}
                    >
                      {parse(pedido.detalhes)}
                    </Popup>

                    <CopyToClipboard
                      text={pedido.chavenfe}
                      onCopy={() =>
                        this.setState({ pedidofbits: pedido.pedidofbits })
                      }
                      style={{ cursor: "cell" }}
                    >
                      <Table.Cell>{pedido.numnota}</Table.Cell>
                    </CopyToClipboard>
                    {this.state.copied}

                    <Table.Cell>
                      {moment(pedido.datanf).format("DD/MM/YYYY HH:mm")}
                    </Table.Cell>
                    <Table.Cell>{pedido.parceiro}</Table.Cell>

                    <Popup
                      trigger={
                        <Table.Cell>
                          {pedido.cidadeparceiro != "Retira"
                            ? pedido.cidadeparceiro
                            : ""}
                          <Header
                            color={
                              pedido.cidadeparceiro == "Retira"
                                ? "teal"
                                : "grey"
                            }
                            size="tiny"
                          >
                            {pedido.cidadeparceiro == "Retira"
                              ? "(Retira)"
                              : ""}
                          </Header>
                        </Table.Cell>
                      }
                      content={pedido.enderecoentrega}
                      inverted
                    />
                    <Table.Cell>{pedido.vendedor}</Table.Cell>
                    <Table.Cell>
                      <div>
                        <Checkbox
                          toggle
                          name="chkEntregou"
                          key={pedido.nunota}
                          onChange={this.updateStateList}
                          pedido={pedido}
                        />
                        {
                          <Popup
                            trigger={
                              <Icon
                                color={
                                  pedido.frete == "Transportadora"
                                    ? "red"
                                    : "blue"
                                }
                                name={
                                  pedido.frete == "Transportadora"
                                    ? "dolly"
                                    : "truck"
                                }
                              />
                            }
                            content={
                              pedido.frete == "Transportadora"
                                ? "Coleta"
                                : "Caminhão Esplane"
                            }
                            size="mini"
                          />
                        }
                      </div>
                    </Table.Cell>
                    <Table.Cell>
                      <Modal
                        trigger={
                          <Popup
                            trigger={
                              <Button
                                basic
                                icon={!pedido.alteradonsaiu ? "write" : "edit"}
                                color={
                                  pedido.alteradonsaiu
                                    ? "red"
                                    : !pedido.alteradonsaiu &&
                                      pedido.ad_trackndescrnsaiu != null
                                    ? "green"
                                    : "blue"
                                }
                                size="mini"
                                onClick={(e) =>
                                  this.onChangeModalMotivoNSaiuOpen(e, pedido)
                                }
                              />
                            }
                            content={`
                                                         ${this.getMotivo(
                                                           pedido.ad_tracknentidnsaiu,
                                                           motivos
                                                         )}
                                                        ${
                                                          pedido.ad_trackndescrnsaiu
                                                        }
                                                        `}
                            inverted
                            disabled={
                              pedido.ad_trackndescrnsaiu != null ? false : true
                            }
                            flowing
                            hoverable
                          />
                        }
                        open={pedido.modalnsaiu}
                        onClose={(e) =>
                          this.onChangeModalMotivoNSaiuClose(e, pedido)
                        }
                        basic
                        style={inlineStyle.modal}
                        size="small"
                      >
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <Header
                          icon="browser"
                          content="Justifique o motivo de não ter saído"
                        />
                        <Modal.Content>
                          <Form name="formModal">
                            <Form.Group widths="equal">
                              <Dropdown
                                onChange={this.onChangePedidoMotivoNSaiu.bind(
                                  this
                                )}
                                options={motivos}
                                selection
                                value={pedido.ad_tracknentidnsaiu}
                                pedido={pedido}
                                placeholder="Motivo"
                                name="combo"
                              />
                            </Form.Group>
                            <Form.TextArea
                              label="ad_trackndescr"
                              placeholder="Escreva a Descrição"
                              value={
                                pedido.ad_trackndescrnsaiu === null
                                  ? ""
                                  : pedido.ad_trackndescrnsaiu
                              }
                              onChange={(e) =>
                                this.onChangePedidoNSaiu(e, pedido)
                              }
                              name="ad_trackndescr"
                              maxLength={255}
                            />
                            <Label as="a" basic color="red" pointing inverted>
                              {pedido.ad_tracknusuarinsaiu != null
                                ? pedido.ad_tracknusuarinsaiu
                                : "Sem usuário"}
                            </Label>
                          </Form>
                        </Modal.Content>
                        <Modal.Actions>
                          <Button
                            color="red"
                            onClick={this.onCancelMotivoNSaiu.bind(this)}
                            pedido={pedido}
                            inverted
                          >
                            <Icon name="cancel" /> Cancel
                          </Button>
                          <Button
                            color="green"
                            onClick={this.onSavePedidoNSaiu.bind(this)}
                            pedido={pedido}
                            loading={this.state.isSavingNSaiu}
                            inverted
                          >
                            <Icon name="checkmark" /> Salvar
                          </Button>
                        </Modal.Actions>
                      </Modal>
                    </Table.Cell>

                    <Table.Cell>
                      <div>
                        <Button
                          basic
                          icon="hand point down outline"
                          color={"red"}
                          size="mini"
                          loading={pedido.isupdatingdevolucao}
                          onClick={(e) =>
                            this.onChangeDevolucao(e, pedido, carga)
                          }
                        />
                      </div>
                    </Table.Cell>
                  </Table.Row>
                ))}
              </StyledTableBody>
            </StyledTable>

            {chkEmRota.length > 0 ? (
              <div>
                <Button.Group floated="right">
                  <Button
                    positive
                    compact
                    onClick={this.onSavePedido.bind(this)}
                    loading={this.state.isSaving}
                  >
                    Gravar
                  </Button>
                </Button.Group>
                <br />
                <br />
                <Divider horizontal floated="left">
                  .
                </Divider>
              </div>
            ) : (
              <Divider horizontal floated="left">
                .
              </Divider>
            )}
          </div>
        ))}
      </Tab.Pane>
    );
  }
}

ListASair.defaultProps = {
  cargas: [],
  loader: true,
  chkEmRota: [],
  login: "",
  motivos: [],
};

ListASair.propTypes = {
  cargas: PropTypes.array.isRequired,
  loader: PropTypes.bool.isRequired,
  handleCHKInsertEmRota: PropTypes.func.isRequired,
  handleCHKRemoveEmRota: PropTypes.func.isRequired,
  handleCHKUpdateEmRota: PropTypes.func.isRequired,
  chkEmRota: PropTypes.array.isRequired,
  login: PropTypes.string.isRequired,
  handleSavePedidosEmRota: PropTypes.func.isRequired,
  handleASairFreteOpenClose: PropTypes.func.isRequired,
  handleASairFreteCheck: PropTypes.func.isRequired,
  handleASairFreteSave: PropTypes.func.isRequired,

  motivos: PropTypes.array.isRequired,
  handlePedidoModalMotivoNSaiu: PropTypes.func.isRequired,
  handlePedidoMotivoNSaiu: PropTypes.func.isRequired,
  handleSavePedidosNSaiu: PropTypes.func.isRequired,

  handleDevolucao: PropTypes.func.isRequired,
};

export default ListASair;
