import {
    BANK_FILES_ML_LOADER
    , BANK_LIST_ML
} from '../actions/ActionsTypes'



const INITIAL_STATE = {

     filesmlloader: true
    , filesml: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        

        case BANK_FILES_ML_LOADER:
            return { ...state, filesmlloader: action.payload }


        case BANK_LIST_ML:
            return { ...state, filesml: action.payload }

        default:
            return state;
    }
}