import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
    , Modal, Button, Header, Table, Message
    , Container
} from 'semantic-ui-react'

import If from '../../utils/If';


import { Form, TextArea } from 'formsy-semantic-ui-react'



const Pergunta = (props) => {

    let { entidade } = props;
    let questao = entidade.questao;
    let resposta = entidade.resposta;



    let opcao, nameicone, coloricone, pergunta = ''



    if (resposta === 1) {
        nameicone = 'frown'
        coloricone = 'red'
        opcao = "Muito Insatisfeito"
    } else if (resposta === 2) {
        nameicone = 'frown outline'
        coloricone = 'orange'
        opcao = "Pouco Insatisfeito"
    } else if (resposta === 3) {
        nameicone = 'meh'
        coloricone = 'yellow'
        opcao = "Neutro"
    } else if (resposta === 4) {
        nameicone = 'smile outline'
        coloricone = 'blue'
        opcao = "Pouco Satisfeito"
    } else if (resposta === 5) {
        nameicone = 'smile'
        coloricone = 'green'
        opcao = "Muito Satisfeito"
    } else if (resposta === 6) {
        nameicone = 'question circle outline'
        coloricone = ''
        opcao = "Não consigo avaliar"
    }


    if (questao === 1) {
        pergunta = `Qual a sua satisfação em relação ao atendimento do nosso consultor ${entidade.consultor}?`
    } else if (questao === 2) {
        pergunta = `Qual a sua satisfação em relação ao atendimento do nosso vendedor ${entidade.vendedor}?`
    } else if (questao === 3) {
        pergunta = 'Qual a sua satisfação em relação à nossa área Comercial como um todo?'
    } else if (questao === 4) {
        pergunta = 'Qual a sua satisfação em relação à nossa área Financeira?'
    } else if (questao === 5) {
        pergunta = 'Qual a sua satisfação em relação à nossa área de Logística?'
    }

    return (
        <div>
            <b>{pergunta}</b><br></br>
            <Icon name={nameicone} color={coloricone} size='big' /> {opcao} <br></br><br></br>
            <b>Deixe abaixo alguma observação, insatisfação, elogio ou o que desejar sobre a Esplane:</b><br></br>
            {(entidade.mensagem === "" ? "O Cliente não escreveu mensagem" : entidade.mensagem)}

        </div>
    )
}








class ModalPendentes extends Component {
    //const ModalPendentes = (props) => {


    state = {}

    constructor(props) {
        super(props);
    }


    onValidSubmit = (formData) => {
        this.form.reset()
        //console.log('valido')
        this.props.handleSavePesquisa()
    }



    componentWillReceiveProps() {
        /*     //tarefaexterna
             if(this.props.tarefaexterna!=0){
                 if(this.props.tarefaexterna ===this.props.entidade.tarefaid){
                     this.setState({abreexterno: true})
                 }
             }else{
                 this.setState({abreexterno: false})
             }*/


        //sessionStorage.setItem("abriutarefa", false);



    }

    componentDidMount() {


    }


    render() {


        let historico = []

        for (let item of this.props.entidade.avaliacaogestor.split("<br>")) {
            //console.log('item:' + item.length)
            if (item.length > 1) {
                historico.push(item)
            }
        }

        const errorLabel = <Label color="red" pointing />


        //if(this.props.tarefaexterna==this.props.entidade.tarefaid){
        //console.log('4545354353######'+this.props.tarefaexterna)
        //console.log('000000000######'+this.props.entidade.tarefaid)
        //}

        return (
            <Modal
      
                trigger={this.props.botao}
                open={this.props.entidade.modalOpen}
                onClose={this.props.handleClose}
            >
                <Modal.Header>Pesquisa de Satisfação - Avaliações</Modal.Header>
                <Modal.Content image>

                    <Modal.Description>
                        <Header>{this.props.entidade.cliente} ({this.props.entidade.codparc})</Header>

                        <Grid verticalAlign='middle' textAlign='center'>
                            <Grid.Column style={{ maxWidth: 790 }}>
                                <Message info>
                                    <p align="center">
                                        Pesquisa respondida pelo parceiro em {moment(this.props.entidade.datapesquisa).format('DD/MM/YYYY HH:mm')}<br /><br />


                                        <Pergunta entidade={this.props.entidade} />


                                    </p>
                                </Message>
                            </Grid.Column>
                        </Grid>


                        <Form
                            name="FormTarefa"
                            ref={ref => this.form = ref}
                            onValidSubmit={this.onValidSubmit.bind(this)}
                        >

                            <If test={this.props.entidade.status === 9}>
                                <p align="right">
                                    <Label as='a' color='teal' tag size='massive'>
                                        Caso encerrado
                                </Label>
                                </p>
                            </If>

                            <If test={this.props.entidade.status === 0 ||this.props.entidade.status === 1}>
                            
                                <Form.TextArea
                                    id='formOcorrido'
                                    name='formOcorrido'
                                    placeholder='Digite a mensagem...'
                                    onChange={this.props.handleChangeForm}
                                    maxLength={2000}
                                    disabled={(this.props.entidade.status === 0 || this.props.entidade.status === 1 ? false : true)}

                                    value=""
                                    label="Descrição do ocorrido e ações que serão tomadas:"
                                    errorLabel={errorLabel}
                                    //required={(this.props.entidade.status === 0 || this.props.entidade.status === 1 ? true : false)}
                                    validationErrors={
                                        {
                                            isDefaultRequiredValue: 'Preencha o campo'
                                        }
                                    }
                                />
                            </If>


                            <If test={this.props.entidade.status != 9}>
                                <Form.TextArea
                                    id='formSolucao'
                                    name='formSolucao'
                                    placeholder='Digite a mensagem...'
                                    onChange={this.props.handleChangeForm}
                                    maxLength={2000}
                                    disabled={(this.props.entidade.status === 2 ? false : true)}

                                    value=""
                                    label="Descrição da solução:"
                                    errorLabel={errorLabel}
                                    //required={(this.props.entidade.status === 2 ? true : false)}
                                    validationErrors={
                                        {
                                            isDefaultRequiredValue: 'Preencha o campo'
                                        }
                                    }
                                />
                            </If>

                            <p></p>
                            <Form.Field control={TextArea} label=''
                                id='formObservacao'
                                name='formObservacao'
                                placeholder='Digite a mensagem...'
                                onChange={this.props.handleChangeForm}
                                maxLength={2000}
                                value=""
                                label="Observações gerais:"

                            />
                            <p align="center">
                                <Button primary
                                //onClick={this.props.handleSavePesquisa.bind(this)}
                                >
                                    Salvar <Icon name='right chevron' />
                                </Button>
                            </p>




                        </Form>

                        <br></br>


                        <Grid verticalAlign='middle' textAlign='center'>
                            <Grid.Column style={{ maxWidth: 790 }}>

                                <Message
                                    floating
                                    warning
                                    header='Histórico'
                                    list={historico} />

                            </Grid.Column>
                        </Grid>








                    </Modal.Description>
                </Modal.Content>

            </Modal>
        )

    }

}

ModalPendentes.defaultProps = {
    entidade: {}
    , botao: {}
    //, tarefaexterna:0
};

ModalPendentes.propTypes = {
    entidade: PropTypes.object.isRequired
    , botao: PropTypes.object.isRequired
    , handleChangeForm: PropTypes.func.isRequired
    , handleSavePesquisa: PropTypes.func.isRequired
    , handleClose: PropTypes.func.isRequired
};

export default ModalPendentes
    /*const mapStateToProps = state => (
        {
    
        }
    );
    
    
    export default withRouter(connect(mapStateToProps, {
    
    }
    )(ModalPendentes));
    */

