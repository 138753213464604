import React, { Component } from "react";
import PropTypes from "prop-types";
import {
  Button,
  Grid,
  Divider,
  Segment,
  List,
  Image,
  Tab,
  Checkbox,
  Label,
  Header,
  Icon,
  Modal,
  Dimmer,
  Form,
  Loader,
  Dropdown,
  Popup,
} from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import DateTimePicker from "react-date-picker";
import moment from "moment";
import logScreen from "./LogScreen";
import LogScreen from "./LogScreen";
import styled from "styled-components";
import parse from "html-react-parser";
import If from "../../utils/If";
import { CopyToClipboard } from "react-copy-to-clipboard";
const inlineStyle = {
  modal: {
    marginTop: "0px !important",
    marginLeft: "auto",
    marginRight: "auto",
  },
};

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;

class ListEmRota extends Component {
  state = { isSaving: false };

  constructor(props) {
    super(props);
    let {
      handleCHKInsertNaoEntregue,
      handleCHKRemoveNaoEntregue,
      handleCHKUpdateNaoEntregue,
      handleSavePedidosNaoEntregue,
    } = this.props;

    this.updateStateList = this.updateStateList.bind(this);
    this.onChangeDate = this.onChangeDate.bind(this);
    this.onChangeModalOpen = this.onChangeModalOpen.bind(this);
    this.onChangeModalClose = this.onChangeModalClose.bind(this);
    this.onChangePedido = this.onChangePedido.bind(this);
    this.onChangeDevolucao = this.onChangeDevolucao.bind(this);
  }

  onChangeEmRotaParaASair(e, pedido, carga) {
    if (!pedido.isupdatingasair) {
      this.props.handleVoltarASair(pedido, carga);
    }
  }

  onChangeDevolucao(e, pedido, carga) {
    if (!pedido.isupdatingdevolucao) {
      //console.log('#############')
      //console.log(pedido)
      //console.log('@@@@@@@@@@@')
      this.props.handleEmRotaParaDevolucao(pedido, carga);
    }
  }

  updateStateList(e, { checked, value, pedido }) {
    this.setState({ isSaving: false });

    if (checked) {
      this.props.handleCHKInsertNaoEntregue(pedido);
    } else {
      this.props.handleCHKRemoveNaoEntregue(pedido);
    }
  }

  onChangeDate(e, pedido) {
    this.setState({ date: e });
    pedido.dataentrega = e;
    this.props.handleCHKUpdateNaoEntregue(pedido);
  }

  componentDidMount() {
    let { handlecargasNaoEntregueQtdeChange } = this.props;
  }

  onChangeModalOpen(e, pedido) {
    this.props.handlePedidoModal(pedido, true, false);
  }

  onChangeModalClose(e, pedido) {
    this.props.handlePedidoModal(pedido, false, false);
  }

  onChangePedido(e, pedido) {
    pedido.ad_trackndescr = e.target.value;
    pedido.alterado = true;
    this.props.handlePedido(pedido);
  }

  onChangePedidoMotivo(e, data) {
    data.pedido.ad_tracknentid = data.value;
    data.pedido.alterado = true;
    this.props.handlePedido(data.pedido);
  }

  onSaveMotivo(e, data) {
    if (data.pedido.ad_trackndescr != null && data.pedido.ad_tracknentid != -1)
      this.props.handlePedidoModal(data.pedido, false, true);
  }

  onCancelMotivo(e, data) {
    data.pedido.alterado = false;
    this.props.handlePedidoModal(data.pedido, false, false);
  }

  onSavePedido(e) {
    this.setState({ isSaving: true });
    this.props.handleSavePedidosNaoEntregue();
  }

  //Frete
  onChangeFreteOpen = (e, ordem) => {
    this.props.handleEmRotaFreteOpenClose(ordem, true);
  };

  onChangeFreteClose = (e, ordem) => {
    // alert(`close ${ordem.id}`)
    this.props.handleEmRotaFreteOpenClose(ordem, false);
  };

  onChangeFreteCheck = (e, ordem) => {
    this.props.handleEmRotaFreteCheck(ordem, !ordem.fretecheck);
  };
  onChangeFreteSave = (e, ordem) => {
    //alert(`save ${ordem.id}`)
    if (!ordem.isfretesaving && ordem.fretecheck) {
      this.props.handleEmRotaFreteSave(ordem);
    }
  };

  onChangePrazos = (pedido, campo, data) => {
    /*console.log(`data: ${data}
        campo: ${campo}
        `)*/
    //console.log(pedido)
    this.props.ordemCargaEmRotaUpdatePrazosAction(pedido, campo, data);
  };

  getMotivo = (id, motivos) => {
    let motivo = "";
    if (motivos.length > 0) {
      try {
        motivo = motivos.find((m) => m.value.toString() == id).text + " | ";
      } catch (e) {
        motivo = "";
      }
    }
    return motivo;
  };

  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Faz a rolagem suave
    });
  };

  render() {
    let {
      cargas,
      cargasNaoEntregueQtde,
      loader,
      motivos,
      chkNaoEntregue,
      handleCHKInsertNaoEntregue,
      handleCHKRemoveNaoEntregue,
      handleCHKUpdateNaoEntregue,
      handleSavePedidosNaoEntregue,
      handlePedidoModal,
      handlePedido,
      logScreen,
    } = this.props;

    return (
      <>
        <div style={{ display: 'flex', position: 'fixed', zIndex: '999', right: '20px' }}>
          <button
            onClick={this.scrollToTop}
            style={{
              backgroundColor: "#4CAF50", // cor de fundo verde
              color: "white", // cor do texto
              padding: "5px 10px", // espaçamento interno
              fontSize: "16px", // tamanho da fonte
              border: "none", // remove a borda padrão
              borderRadius: "5px", // cantos arredondados
              cursor: "pointer", // muda o cursor para 'pointer' ao passar o mouse
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // sombra leve
              transition: "background-color 0.3s ease", // transição suave na cor de fundo
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#45a049")} // cor ao passar o mouse
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4CAF50")} // cor ao remover o mouse
          >
            <Icon name='arrow up' />

          </button>
        </div>

        <Tab.Pane loading={loader}>
          <LogScreen logData={logScreen} />

          {cargas.map((carga) => (
            <div key={`emrota${carga.id}`}>
              <List horizontal id={carga.id} size="small">
                <List.Item>
                  <List.Content>
                    <List.Header>Ordem: {carga.id}</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Placa: {carga.placa}</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>Motorista: {carga.motorista}</List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <List.Header>
                      Data da Ordem:{" "}
                      {moment(carga.dataordemcarga).format("DD/MM/YYYY")}
                    </List.Header>
                  </List.Content>
                </List.Item>
                <List.Item>
                  <List.Content>
                    <Modal
                      trigger={
                        <Button
                          basic
                          icon={"location arrow"}
                          color={"blue"}
                          size="mini"
                          onClick={(e) => this.onChangeFreteOpen(e, carga)}
                        />
                      }
                      open={carga.freteopen}
                      onClose={(e) => this.onChangeFreteClose(e, carga)}
                    >
                      <Modal.Header>
                        Ordem de Carga: {carga.id} - Confirma que todos pedidos
                        listados serão marcados como "Entregou" ?
                      </Modal.Header>
                      <Modal.Content>
                        <Modal.Description>
                          <StyledTable
                            celled
                            id={carga.id}
                            compact="very"
                            size="small"
                          >
                            <StyledTableHeader>
                              <Table.Row>
                                <Table.HeaderCell width="1">
                                  FBits
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1">
                                  Data Pedido
                                </Table.HeaderCell>
                                <Table.HeaderCell width="1">
                                  Nota
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  Data Nota
                                </Table.HeaderCell>
                                <Table.HeaderCell width="3">
                                  Parceiro
                                </Table.HeaderCell>
                                <Table.HeaderCell width="2">
                                  Cidade
                                </Table.HeaderCell>
                              </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                              {carga.pedidos.map((pedido) => (
                                <Table.Row key={pedido.numnota}>
                                  <Table.Cell>{pedido.pedidofbits}</Table.Cell>
                                  <Table.Cell>
                                    {moment(pedido.datapedido).format(
                                      "DD/MM/YYYY"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>{pedido.numnota}</Table.Cell>
                                  <Table.Cell>
                                    {moment(pedido.datanf).format(
                                      "DD/MM/YYYY HH:mm"
                                    )}
                                  </Table.Cell>
                                  <Table.Cell>{pedido.parceiro}</Table.Cell>
                                  <Table.Cell>{pedido.cidadeparceiro}</Table.Cell>
                                </Table.Row>
                              ))}
                            </StyledTableBody>
                          </StyledTable>
                        </Modal.Description>
                      </Modal.Content>
                      <Modal.Actions>
                        <Button
                          color="red"
                          onClick={(e) => this.onChangeFreteClose(e, carga)}
                        >
                          <Icon name="remove" /> Cancelar
                        </Button>
                        <Button
                          color="green"
                          loading={carga.isfretesaving}
                          onClick={(e) => this.onChangeFreteSave(e, carga)}
                        >
                          <Icon name="checkmark" /> Confirmar
                        </Button>

                        <Checkbox
                          toggle={true}
                          onChange={(e) => this.onChangeFreteCheck(e, carga)}
                          name="fretecheck"
                          label="Estou ciente"
                          checked={carga.fretecheck}
                        />
                      </Modal.Actions>
                    </Modal>

                    <Button
                      basic
                      icon={"flag"}
                      color={"red"}
                      size="mini"
                      onClick={(e) =>
                        this.props.ordemCargaEmRotaListAppEntregasAction(carga.id)
                      }
                    />
                  </List.Content>
                </List.Item>
              </List>

              <StyledTable celled id={carga.id} compact="very" size="small">
                <StyledTableHeader>
                  <Table.Row>
                    <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                    <Table.HeaderCell width="2">Data Pedido</Table.HeaderCell>
                    <Table.HeaderCell width="1">Nota</Table.HeaderCell>
                    <Table.HeaderCell width="2">Data Nota</Table.HeaderCell>
                    <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                    <Table.HeaderCell width="2">Cidade</Table.HeaderCell>
                    <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                    <Table.HeaderCell width="1">Entregou</Table.HeaderCell>
                    <Table.HeaderCell width="1">Não Entregou</Table.HeaderCell>
                    <Table.HeaderCell width="1">Devolução</Table.HeaderCell>
                    <Table.HeaderCell>A Sair</Table.HeaderCell>
                  </Table.Row>
                </StyledTableHeader>

                <StyledTableBody>
                  {carga.pedidos.map((pedido) => (
                    <Table.Row
                      key={pedido.numnota}
                      active={pedido.isatrasado == 3 ? true : false}
                      warning={pedido.isatrasado == 1 ? true : false}
                      negative={pedido.isatrasado == 2 ? true : false}
                    >
                      <CopyToClipboard
                        text={pedido.pedidofbits}
                        style={{ cursor: "cell" }}
                      >

                        <Table.Cell>{pedido.pedidofbits}</Table.Cell>

                      </CopyToClipboard>
                      <Popup
                        trigger={
                          <Table.Cell>
                            {moment(pedido.datapedido).format("DD/MM/YYYY")}
                          </Table.Cell>
                        }
                        flowing
                        hoverable
                        pinned
                        on={["hover", "click"]}
                      >
                        <Segment secondary>
                          <table
                            width="100%"
                            id={pedido.nunota + "tb" + pedido.pedidofbits}
                          >
                            <tbody>
                              <tr>
                                <td>
                                  <b>Data Envio:</b>
                                </td>
                                <td>
                                  <b>Previsão de entrega:</b>
                                </td>
                                <td></td>
                              </tr>
                              <tr>
                                <td>
                                  <DateTimePicker
                                    onChange={this.onChangePrazos.bind(
                                      this,
                                      pedido,
                                      "ad_dtenvio"
                                    )}
                                    //onChange={this.props.ordemCargaEmRotaUpdatePrazosAction}
                                    value={
                                      pedido.ad_dtenvio == null
                                        ? ""
                                        : new Date(pedido.ad_dtenvio)
                                    }
                                    locale="pt-br"
                                    required={true}
                                    clearIcon=""
                                    disableClock={true}
                                    disableCalendar={true}
                                  />
                                </td>
                                <td>
                                  <DateTimePicker
                                    onChange={this.onChangePrazos.bind(
                                      this,
                                      pedido,
                                      "ad_dtprevisaoen"
                                    )}
                                    //onChange={this.props.ordemCargaEmRotaUpdatePrazosAction}
                                    value={
                                      pedido.ad_dtprevisaoen == null
                                        ? ""
                                        : new Date(pedido.ad_dtprevisaoen)
                                    }
                                    locale="pt-br"
                                    required={true}
                                    clearIcon=""
                                    disableClock={true}
                                    disableCalendar={true}
                                  />
                                </td>
                                <td>
                                  <Button
                                    positive
                                    size="tiny"
                                    loading={pedido.is_savingdt}
                                    onClick={(e) =>
                                      this.props.ordemCargaEmRotaSavePrazosAction(
                                        pedido,
                                        this.props.login
                                      )
                                    }
                                  >
                                    Alterar
                                  </Button>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </Segment>

                        {parse(pedido.detalhes)}
                      </Popup>
                      <CopyToClipboard
                        text={pedido.chavenfe}
                        onCopy={() =>
                          this.setState({ pedidofbits: pedido.pedidofbits })
                        }
                        style={{ cursor: "cell" }}
                      >
                        <Table.Cell>{pedido.numnota}</Table.Cell>
                      </CopyToClipboard>
                      {this.state.copied}
                      <Table.Cell>
                        {moment(pedido.datanf).format("DD/MM/YYYY HH:mm")}
                      </Table.Cell>
                      <Table.Cell>{pedido.parceiro}</Table.Cell>

                      <Popup
                        trigger={
                          <Table.Cell>
                            {pedido.cidadeparceiro != "Retira"
                              ? pedido.cidadeparceiro
                              : ""}
                            <Header
                              color={
                                pedido.cidadeparceiro == "Retira" ? "teal" : null
                              }
                              size="tiny"
                            >
                              {pedido.cidadeparceiro == "Retira"
                                ? "(Retira)"
                                : ""}
                            </Header>
                          </Table.Cell>
                        }
                        content={pedido.enderecoentrega}
                        inverted
                      />
                      <Table.Cell>{pedido.vendedor}</Table.Cell>
                      <Table.Cell>
                        <div>
                          <Checkbox
                            toggle
                            name="chkEntregou"
                            key={pedido.nunota}
                            onChange={this.updateStateList}
                            pedido={pedido}
                          />
                          {
                            <Popup
                              trigger={
                                <Icon
                                  color={
                                    pedido.frete == "Transportadora"
                                      ? "red"
                                      : "blue"
                                  }
                                  name={
                                    pedido.frete == "Transportadora"
                                      ? "dolly"
                                      : "truck"
                                  }
                                />
                              }
                              content={
                                pedido.frete == "Transportadora"
                                  ? "Coleta"
                                  : "Caminhão Esplane"
                              }
                              size="mini"
                            />
                          }
                          {/* 
                                                    se habilitar essa funcionalidade, remover as chaves só desse input   
                                                    <input 
                                                    key={pedido.numnota} 
                                                    name='list' 
                                                    type='checkbox' 
                                                    onClick={(e)=>this.updateStateList(e,pedido)}
                                                    className="ui fitted checkbox"
                                                     />*/}

                          {/*chkNaoEntregue.map(checkbox => (
                                                   (checkbox.numnota === pedido.numnota?
                                                    ( 
                                                    <div key={checkbox.numnota}><DateTimePicker key={checkbox.numnota}
                                                        onChange={(e)=>this.onChangeDate(e,pedido)}
                                                        //value={this.state.date}
                                                        value={checkbox.dataentrega}
                                                        locale="pt-br"
                                                        /></div>
                                                    ):(
                                                        <div key={checkbox.numnota}></div>
                                                    ))
                                                ))*/}
                        </div>
                      </Table.Cell>
                      <Table.Cell>
                        <Modal
                          trigger={
                            <Popup
                              trigger={
                                <Button
                                  basic
                                  icon={!pedido.alterado ? "write" : "edit"}
                                  color={
                                    pedido.alterado
                                      ? "red"
                                      : !pedido.alterado &&
                                        pedido.ad_trackndescr != null
                                        ? "green"
                                        : "blue"
                                  }
                                  size="mini"
                                  onClick={(e) =>
                                    this.onChangeModalOpen(e, pedido)
                                  }
                                />
                              }
                              content={`
                                                        ${this.getMotivo(
                                pedido.ad_tracknentid,
                                motivos
                              )}
                                                        ${pedido.ad_trackndescr}
                                                        `}
                              inverted
                              disabled={
                                pedido.ad_trackndescr != null ? false : true
                              }
                            />
                          }
                          open={pedido.modal}
                          onClose={(e) => this.onChangeModalClose(e, pedido)}
                          basic
                          style={inlineStyle.modal}
                          size="small"
                        >
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <br />
                          <Header
                            icon="browser"
                            content="Justifique o motivo da não entrega"
                          />
                          <Modal.Content>
                            <Form name="formModal">
                              <Form.Group widths="equal">
                                <Dropdown
                                  onChange={this.onChangePedidoMotivo.bind(this)}
                                  options={motivos}
                                  selection
                                  value={pedido.ad_tracknentid}
                                  pedido={pedido}
                                  placeholder="Motivo"
                                  name="combo"
                                />
                              </Form.Group>
                              <Form.TextArea
                                label="ad_trackndescr"
                                placeholder="Escreva a Descrição"
                                value={
                                  pedido.ad_trackndescr === null
                                    ? ""
                                    : pedido.ad_trackndescr
                                }
                                onChange={(e) => this.onChangePedido(e, pedido)}
                                name="ad_trackndescr"
                                maxLength={255}
                              />
                              <Label as="a" basic color="red" pointing inverted>
                                {pedido.ad_tracknusuari != null
                                  ? pedido.ad_tracknusuari
                                  : "Sem usuário"}
                              </Label>
                            </Form>
                          </Modal.Content>
                          <Modal.Actions>
                            <Button
                              color="red"
                              onClick={this.onCancelMotivo.bind(this)}
                              pedido={pedido}
                              inverted
                            >
                              <Icon name="cancel" /> Cancel
                            </Button>
                            <Button
                              color="green"
                              onClick={this.onSaveMotivo.bind(this)}
                              pedido={pedido}
                              inverted
                            >
                              <Icon name="checkmark" /> Salvar
                            </Button>
                          </Modal.Actions>
                        </Modal>
                      </Table.Cell>

                      <Table.Cell>
                        <div>
                          <Button
                            basic
                            icon="hand point down outline"
                            color={"red"}
                            size="mini"
                            loading={pedido.isupdatingdevolucao}
                            onClick={(e) =>
                              this.onChangeDevolucao(e, pedido, carga)
                            }
                          />
                        </div>
                      </Table.Cell>

                      <Table.Cell>
                        <div>
                          <Button
                            basic
                            icon="hand point left outline"
                            color={"blue"}
                            size="mini"
                            loading={pedido.isupdatingasair}
                            onClick={(e) =>
                              this.onChangeEmRotaParaASair(e, pedido, carga)
                            }
                          />
                        </div>
                      </Table.Cell>
                    </Table.Row>
                  ))}
                </StyledTableBody>
              </StyledTable>

              {chkNaoEntregue.length > 0 ? (
                <div>
                  <Button.Group floated="right">
                    <Button
                      positive
                      compact
                      onClick={this.onSavePedido.bind(this)}
                      loading={this.state.isSaving}
                    >
                      Gravar
                    </Button>
                  </Button.Group>
                  <br />
                  <br />
                  <Divider horizontal floated="left">
                    .
                  </Divider>
                </div>
              ) : (
                <Divider horizontal floated="left">
                  .
                </Divider>
              )}
            </div>
          ))}

          <Modal
            open={this.props.appentregamodal}
            onClose={() =>
              this.props.ordemCargaEmRotaModalAppEntregasAction(false)
            }
          //onOpen={() => setOpen(true)}
          //trigger={<Button>Scrolling Content Modal</Button>}
          >
            <Modal.Header>Detalhes do APP de Entrega</Modal.Header>
            <Modal.Content image scrolling>
              <Modal.Description>
                <If test={this.props.loaderappentrega}>
                  <Segment>
                    <Dimmer active inverted>
                      <Loader size="large">Loading</Loader>
                    </Dimmer>

                    <Image src="/img/paragraph.png" />
                  </Segment>
                </If>

                <If test={!this.props.loaderappentrega}>
                  <StyledTable celled id={"modal"} compact="very" size="small">
                    <StyledTableHeader>
                      <Table.Row>
                        <Table.HeaderCell width="1">OC</Table.HeaderCell>
                        <Table.HeaderCell>PedidoFBits</Table.HeaderCell>
                        <Table.HeaderCell>SEQ</Table.HeaderCell>
                        <Table.HeaderCell>NumNota</Table.HeaderCell>

                        <Table.HeaderCell width="2">Início</Table.HeaderCell>
                        <Table.HeaderCell width="2">Check-in</Table.HeaderCell>
                        <Table.HeaderCell width="2">Assinatura</Table.HeaderCell>
                        <Table.HeaderCell width="2">Check-out</Table.HeaderCell>

                        <Table.HeaderCell>peso</Table.HeaderCell>
                        <Table.HeaderCell>Ocorr.</Table.HeaderCell>
                        <Table.HeaderCell>Fotos</Table.HeaderCell>
                        <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                        <Table.HeaderCell width="3">Endereço</Table.HeaderCell>
                      </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                      {this.props.appentrega.map((item) => (
                        <Table.Row
                          key={item.nunota}
                          warning={item.pedidofbits ? true : false}
                          negative={
                            item.qtd_ocorrenc > 0 && item.pedidofbits != null
                              ? true
                              : false
                          }
                        >
                          <Table.Cell>{item.ordemcarga}</Table.Cell>
                          <Table.Cell>{item.pedidofbits}</Table.Cell>
                          <Table.Cell>{item.seqcarga}</Table.Cell>
                          <Table.Cell>{item.numnota}</Table.Cell>
                          <Table.Cell
                            positive={item.inicio != null}
                            error={item.inicio == null}
                          >
                            {item.inicio != null ? (
                              <>
                                <Icon name="checkmark" />
                                {moment(item.inicio).format("DD/MM/YYYY HH:mm")}
                              </>
                            ) : (
                              <>
                                <Icon name="wait" />
                                Aguardando
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            positive={item.checkin != null}
                            error={item.checkin == null}
                          >
                            {item.checkin != null ? (
                              <>
                                <Icon name="checkmark" />
                                {moment(item.checkin).format("DD/MM/YYYY HH:mm")}
                              </>
                            ) : (
                              <>
                                <Icon name="wait" />
                                Aguardando
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            positive={item.assinatura != null}
                            error={item.assinatura == null}
                          >
                            {item.assinatura != null ? (
                              <>
                                <Icon name="checkmark" />
                                {moment(item.assinatura).format(
                                  "DD/MM/YYYY HH:mm"
                                )}
                              </>
                            ) : (
                              <>
                                <Icon name="wait" />
                                Aguardando
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell
                            positive={item.checkout != null}
                            error={item.checkout == null}
                          >
                            {item.checkout != null ? (
                              <>
                                <Icon name="checkmark" />
                                {moment(item.checkout).format("DD/MM/YYYY HH:mm")}
                              </>
                            ) : (
                              <>
                                <Icon name="wait" />
                                Aguardando
                              </>
                            )}
                          </Table.Cell>
                          <Table.Cell>{item.peso.toFixed(2)}</Table.Cell>
                          <Table.Cell>{item.qtd_ocorrenc}</Table.Cell>
                          <Table.Cell>{item.qtd_fotos}</Table.Cell>
                          <Table.Cell>{item.parceiro}</Table.Cell>
                          <Table.Cell>{item.endentrega}</Table.Cell>
                        </Table.Row>
                      ))}
                    </StyledTableBody>
                  </StyledTable>
                </If>
              </Modal.Description>
            </Modal.Content>
            <Modal.Actions>
              <Button
                negative
                onClick={() =>
                  this.props.ordemCargaEmRotaModalAppEntregasAction(false)
                }
              >
                Fechar
              </Button>
            </Modal.Actions>
          </Modal>
        </Tab.Pane>
      </>

    );
  }
}

ListEmRota.defaultProps = {
  cargas: [],
  loader: true,
  chkNaoEntregue: [],
  motivos: [],
  appentrega: [],
  loaderappentrega: false,
  appentregamodal: false,
};

ListEmRota.propTypes = {
  cargas: PropTypes.array.isRequired,
  loader: PropTypes.bool.isRequired,
  handleCHKInsertNaoEntregue: PropTypes.func.isRequired,
  handleCHKRemoveNaoEntregue: PropTypes.func.isRequired,
  handleCHKUpdateNaoEntregue: PropTypes.func.isRequired,
  chkNaoEntregue: PropTypes.array.isRequired,
  handleSavePedidosNaoEntregue: PropTypes.func.isRequired,
  handlePedidoModal: PropTypes.func.isRequired,
  handlePedido: PropTypes.func.isRequired,
  motivos: PropTypes.array.isRequired,
  handleDevolucao: PropTypes.func.isRequired,

  handleEmRotaFreteOpenClose: PropTypes.func.isRequired,
  handleEmRotaFreteCheck: PropTypes.func.isRequired,
  handleEmRotaFreteSave: PropTypes.func.isRequired,
  handleEmRotaParaDevolucao: PropTypes.func.isRequired,

  ordemCargaEmRotaUpdatePrazosAction: PropTypes.func.isRequired,
  ordemCargaEmRotaSavePrazosAction: PropTypes.func.isRequired,
  login: PropTypes.string.isRequired,

  appentrega: PropTypes.array.isRequired,
  loaderappentrega: PropTypes.bool.isRequired,
  appentregamodal: PropTypes.bool.isRequired,
  ordemCargaEmRotaListAppEntregasAction: PropTypes.func.isRequired,
  ordemCargaEmRotaModalAppEntregasAction: PropTypes.func.isRequired,
};

export default ListEmRota;
