import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tab, Header, Button, Modal, Form, Input } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`

//


class ListNaoPagos extends Component {

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

    }





    render() {
        let { dataList, dataQtde, loader
        } = this.props;

        let total = 0;


        const trocarCorLigar = (ligar) => {
            let color = '';

            if (ligar == 'S') {
                color = 'green';
            } else if (ligar == 'N') {
                color = 'red';
            } else {
                color = 'grey';
            }
            return color;

        }


        return (
            <Tab.Pane loading={loader}>
                <StyledTable celled id="ListNaoPagos" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="3">FBits</Table.HeaderCell>
                            <Table.HeaderCell width="2">Data Pedido</Table.HeaderCell>

                            <Table.HeaderCell width="5">Parceiro</Table.HeaderCell>
                            <Table.HeaderCell width="3">Cidade</Table.HeaderCell>
                            <Table.HeaderCell width="3">Vendedor</Table.HeaderCell>
                            <Table.HeaderCell width="1">Ligar?</Table.HeaderCell>



                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {dataList.map(data => (
                            (
                                total += data.valornota
                            ),
                            <Table.Row key={"NP" + data.fbits}>
                                <Table.Cell>{data.fbits}</Table.Cell>
                                <Table.Cell>{moment(data.datapedido).format('DD/MM/YYYY')}</Table.Cell>

                                <Table.Cell>{data.parceiro}</Table.Cell>
                                <Table.Cell>{(data.cidade != 'Retira' ? data.cidade : '')}<Header color={(data.cidade == 'Retira' ? 'teal' : null)} size='tiny'>{(data.cidade == 'Retira' ? '(Retira)' : '')}</Header></Table.Cell>
                                <Table.Cell>{data.vendedor}</Table.Cell>
                                <Table.Cell>
                                    <Button
                                        basic icon={'tty'} color={trocarCorLigar(data.ligar)} size="mini"
                                        onClick={() => {
                                            if (!data.isloading_ligar) {
                                                //
                                                if (data.ligar == 'N' || data.ligar == null) {
                                                    this.props.ordemCargaNaoPagosMODALLigarAction(data.nunota, true)

                                                } else {

                                                    this.props.ordemCargaNaoPagosLigarAction(data.nunota, data.ligar, data.ligarcomplemento)

                                                }
                                            }
                                        }
                                        }
                                        loading={data.isloading_ligar}

                                    />

                                    <Modal
                                        size={'mini'}
                                        open={data.modal_ligar}
                                    >
                                        <Modal.Header>Ligar 1h antes para o cliente?</Modal.Header>
                                        <Modal.Content>
                                            <p>Digite abaixo se tem mais alguma observação</p>
                                            <Form.TextArea

                                                placeholder='Escreva a observação'
                                                labe
                                                value={data.ligarcomplemento}
                                                onChange={(e) => {
                                                    this.props.ordemCargaNaoPagosCOMPLEMENTOLigarAction(data.nunota, e.target.value)
                                                }}
                                                name="ligarcomplemento"
                                                maxLength={50}
                                                rows={5}
                                                style={{ width: '100%' }}


                                            />

                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button negative
                                                onClick={() => this.props.ordemCargaNaoPagosMODALLigarAction(data.nunota, false)}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button positive
                                                loading={data.isloading_ligar}
                                                onClick={() => {
                                                    if (!data.isloading_ligar) {
                                                        this.props.ordemCargaNaoPagosLigarAction(data.nunota, data.ligar, data.ligarcomplemento).then(d => {
                                                            this.props.ordemCargaNaoPagosMODALLigarAction(data.nunota, false);
                                                        })
                                                    }
                                                }}
                                            >
                                                Confirmar
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Table.Cell>
                            </Table.Row>
                        ))}


                    </StyledTableBody>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell>
                                <Header as='h3'>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</Header>
                            </Table.HeaderCell>
                            <Table.HeaderCell />
                            <Table.HeaderCell />
                            <Table.HeaderCell />
                            <Table.HeaderCell />

                        </Table.Row>
                    </Table.Footer>

                </StyledTable>

            </Tab.Pane>

        )
    }
}

ListNaoPagos.defaultProps = {
    dataList: []
    , loader: true

};

ListNaoPagos.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , ordemCargaNaoPagosLigarAction: PropTypes.func.isRequired
    , ordemCargaNaoPagosCOMPLEMENTOLigarAction: PropTypes.func.isRequired
    , ordemCargaNaoPagosMODALLigarAction: PropTypes.func.isRequired
};



export default ListNaoPagos
