import {
    ROBODEPRECOS_LIST_CONFIGURACOES
    , ROBODEPRECOS_QTDE_CONFIGURACOES
    , ROBODEPRECOS_LOADER_CONFIGURACOES

    , ROBODEPRECOS_LIST_MODIFICADOS
    , ROBODEPRECOS_QTDE_MODIFICADOS
    , ROBODEPRECOS_LOADER_MODIFICADOS

    , ROBODEPRECOS_LIST_MANTIDOS
    , ROBODEPRECOS_QTDE_MANTIDOS
    , ROBODEPRECOS_LOADER_MANTIDOS

    , ROBODEPRECOS_LIST_MONITORADOS
    , ROBODEPRECOS_QTDE_MONITORADOS
    , ROBODEPRECOS_LOADER_MONITORADOS

    , ROBODEPRECOS_CHANGE_CONFIGURACOES
    , ROBODEPRECOS_CLEAR_CONFIGURACOES
    , ROBODEPRECOS_UPDATE_CONFIGURACOES

    , ROBODEPRECOS_LIST_STATUS

    , ROBODEPRECOS_LIST_FORADOSLIMITES
    , ROBODEPRECOS_QTDE_FORADOSLIMITES
    , ROBODEPRECOS_LOADER_FORADOSLIMITES



    , ROBODEPRECOS_LIST_CONCORRENTES
    , ROBODEPRECOS_LIST_GERAL
    , ROBODEPRECOS_LOADER_GERAL
    , ROBODEPRECOS_LIST_PRODUTOS


} from '../actions/ActionsTypes';



import { START_TIMER } from 'redux-timer';
import { STOP_TIMER } from 'redux-timer';
import * as Api from '../utils/API';
import sortBy from 'sort-by';

import { RoboPrecosConfigEntity } from '../entities/RoboPrecosConfigEntity'


function analisaproduto(codprod,webpriceid,geral){
    let status = ''

    let countproduto = geral.filter(f=>f.codprod===codprod && f.webpriceid ===webpriceid).length
    if(countproduto>0){
        if(geral.filter(f=>f.codprod===codprod && f.webpriceid ===webpriceid)[0].preco_avista > 0){
            status='E'
        }else{
            status='Indisponível'
        }
    }else{
        status='N/M'
    }

    return status
}


function crialinha(produto,concorrentes,geral){

    //conta qtde de concorrentes
    produto.qtdecc = contaconcorrenteporsku(produto.codprod,geral)

    let blnteveamarelo=0
    let blntevevermelho=0
    let blnteveazul=0

    
    for(let c of concorrentes){
        let resultado = analisaproduto(produto.codprod,c.webpriceid,geral);  

        produto[`cc${c.webpriceid}`] = resultado
        
        if(resultado=='Indisponível'){
            blnteveamarelo=1
        }

        if(c.webpriceid==2450){
            if(produto.estoque>0){
            
                if(resultado=='Indisponível'||resultado=='N/M'){
                    blntevevermelho=1
                }
            }
        }
        
        
    }

    if(produto.statusproduto!='Ativo'){
        blnteveazul=1
    }
    
    produto.amarelo=blnteveamarelo
    produto.vermelho=blntevevermelho
    produto.azul=blnteveazul

    


  return produto
}

function contaconcorrenteporsku(codprod,geral){
    let numero = geral.filter(f=>f.codprod===codprod).length
    return numero
}


export const roboDePrecosGeralListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({type:ROBODEPRECOS_LOADER_GERAL, payload:true})
        Api.getAllRoboDePrecosGeral().then(dados=>{

            
            let concorrentes = []
            let produtos = []
            let geral = []


            
            produtos = dados.produtos
            concorrentes = dados.concorrentes
            geral = dados.geral

            concorrentes.push({id:0,concorrente:'Vcc', webpriceid: 2450})


            
            let relatoriogeral = []

            for (let p of produtos){
                relatoriogeral.push(crialinha(p,concorrentes,geral))
            }
            
            //console.log('###relatoriogeral')
            //console.log(relatoriogeral)

            let vermelho = []
            let amarelo = []
            let azul = []

            vermelho = relatoriogeral.filter(f=>f.vermelho==1 && f.azul==0)
            amarelo = relatoriogeral.filter(f=>f.vermelho==0 && f.amarelo==1 && f.azul==0)
            azul = relatoriogeral.filter(f=>f.azul==1)

            dispatch({type:ROBODEPRECOS_LIST_GERAL, 
                payload:relatoriogeral.filter(f=>f.vermelho==0 && f.amarelo==0 && f.azul==0).sort(sortBy('descrprod'))
                ,vermelho: vermelho.sort(sortBy('descrprod'))
                ,amarelo: amarelo.sort(sortBy('descrprod'))
                ,azul: azul.sort(sortBy('descrprod'))
            })

            dispatch({type:ROBODEPRECOS_LIST_CONCORRENTES, payload:dados.concorrentes})
            //dispatch({type:ROBODEPRECOS_LIST_GERAL, payload:geral})
            //'descrprod',
            
            dispatch({type:ROBODEPRECOS_LIST_PRODUTOS, payload:produtos})
            dispatch({type:ROBODEPRECOS_LOADER_GERAL, payload:false})
        })
        /*Api.getRoboDePrecosStatus().then(dados => {
            dispatch({ type: ROBODEPRECOS_LIST_STATUS, robostatus: parseInt(dados[0].valor,10), robodt: dados[1].valor })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })*/
    });



export const roboDePrecosStatusStartTimerAction = () => async dispatch => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'robodePrecosStatus',
            action: async () => {
                try {
                    //console.log('tick graph!')
                    
                    dispatch(roboDePrecosStatusListAction())
                   
                    
                } catch (e) {
                    // do something with this error
                }
            },
            interval: 10000,
            runImmediately: false
        }
    });
};

export const roboDePrecosStatusListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getRoboDePrecosStatus().then(dados => {
            dispatch({ type: ROBODEPRECOS_LIST_STATUS, robostatus: parseInt(dados[0].valor,10), robodt: dados[1].valor })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });

export const roboDePrecosStatusUpdateAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        
        dispatch({ type: ROBODEPRECOS_LIST_STATUS, robostatus: 2, robodt: null })
        Api.postRoboDePrecosStatus().then(dados => {
            
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });
    

export const roboClearConfiguracoesAction = () => {
    return {
        type: ROBODEPRECOS_CLEAR_CONFIGURACOES
        , payload: RoboPrecosConfigEntity
    }
}


export const roboHandleConfigChangeAction = (event) => {
    return {
        type: ROBODEPRECOS_CHANGE_CONFIGURACOES
        , field: event.target.name
        , payload: event.target.value
    }
};

export const roboSaveConfiguracoesAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //STP_TI_PESQUISA_SAVE_INTERNO

        dispatch({ type: ROBODEPRECOS_CLEAR_CONFIGURACOES, payload: RoboPrecosConfigEntity })

        dispatch({ type: ROBODEPRECOS_LOADER_CONFIGURACOES, payload: true })
   
        Api.postRoboPrecoConfigSave(entidade).then(dados => {

            //atualiza configuração na tela
            dispatch(
                {
                    type: ROBODEPRECOS_UPDATE_CONFIGURACOES
                    , id: entidade.id
                    , grupo: entidade.grupo
                    , pctminimo: entidade.pctminimo
                    , pctmaximo: entidade.pctmaximo
                    , pctdiferenca: entidade.pctdiferenca
                }
            )
            dispatch({ type: ROBODEPRECOS_LOADER_CONFIGURACOES, payload: false })

            //dispatch(roboDePrecosConfiguracoesAction())
            resolve(dados)
        }).catch(error => {

            reject(error);
        })
    });




export const roboDePrecosConfiguracoesAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRoboDePrecosConfiguracoes().then(dados => {
            dispatch({ type: ROBODEPRECOS_LIST_CONFIGURACOES, payload: dados })
            dispatch({ type: ROBODEPRECOS_QTDE_CONFIGURACOES, payload: dados.length })
            dispatch({ type: ROBODEPRECOS_LOADER_CONFIGURACOES, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })

    });

export const roboDePrecosModificadosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRoboDePrecosModificados().then(dados => {
            dispatch({ type: ROBODEPRECOS_LIST_MODIFICADOS, payload: dados })
            dispatch({ type: ROBODEPRECOS_QTDE_MODIFICADOS, payload: dados.length })
            dispatch({ type: ROBODEPRECOS_LOADER_MODIFICADOS, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });


export const roboDePrecosForaDosLimitesAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRoboDePrecosForaDosLimites().then(dados => {
            dispatch({ type: ROBODEPRECOS_LIST_FORADOSLIMITES, payload: dados })
            dispatch({ type: ROBODEPRECOS_QTDE_FORADOSLIMITES, payload: dados.length })
            dispatch({ type: ROBODEPRECOS_LOADER_FORADOSLIMITES, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });



export const roboDePrecosMantidosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRoboDePrecosMantidos().then(dados => {
            dispatch({ type: ROBODEPRECOS_LIST_MANTIDOS, payload: dados })
            dispatch({ type: ROBODEPRECOS_QTDE_MANTIDOS, payload: dados.length })
            dispatch({ type: ROBODEPRECOS_LOADER_MANTIDOS, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });

export const roboDePrecosMonitoradosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRoboDePrecosMonitorados().then(dados => {
            dispatch({ type: ROBODEPRECOS_LIST_MONITORADOS, payload: dados })
            dispatch({ type: ROBODEPRECOS_QTDE_MONITORADOS, payload: dados.length })
            dispatch({ type: ROBODEPRECOS_LOADER_MONITORADOS, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });

