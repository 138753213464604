import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import If from '../../utils/If';


import {
    Grid, Menu, Breadcrumb, Tab, Icon,Table

} from 'semantic-ui-react'

import {
    corporativoListFechamentoAction
    , corporativoUpdateAction
    , corporativoAddValidacao
    , corporativoRemoveValidacao
    , corporativoListAction
    , corporativoListSearchAction
    , corporativoListCidadesAction
} from '../../actions/CorporativoActions';

import Fechamento from './Fechamento';
import Historico from './Historico';


import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class Corporativo extends Component {

    state = {}

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 16 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        //this.props.corporativoListFechamentoAction().then(dados => { })

        this.props.corporativoListAction().then(dados => { })

        this.props.corporativoListCidadesAction().then(dados => { })



    }

    componentWillReceiveProps() {

    }

    render() {

        let { fechamento } = this.props

        let panes = [

            {
                menuItem: <Menu.Item key='TABHistorico'><Icon name='list alternate outline' />Historico</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={false}>
                        <Historico
                            entidade={this.props.RepresentanteBuscaEntity}
                            corporativoUpdateAction={this.props.corporativoUpdateAction}
                            corporativoAddValidacao={this.props.corporativoAddValidacao}
                            corporativoRemoveValidacao={this.props.corporativoRemoveValidacao}
                            validacao={this.props.validacao}
                            isSearching={this.props.isSearching}
                            representantes={this.props.representantes}
                            datalist={this.props.search}
                            corporativoListSearchAction={this.props.corporativoListSearchAction}
                            RepresentanteBuscaEntity={this.props.RepresentanteBuscaEntity}
                            cidades={this.props.cidades}
                            
                        />
                    </Tab.Pane>
            },


        ]


        if (this.props.userid == "3" || this.props.userid == "2" || this.props.userid == "4" || this.props.userid == "6") {
            panes.push({
                menuItem: <Menu.Item key='TABassinaturas'><Icon name='list alternate outline' />Fechamento</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderRepresentantesFechamento}>
                        <Fechamento
                            datalist={fechamento}
                            corporativoListFechamentoAction={this.props.corporativoListFechamentoAction}
                        />
                    </Tab.Pane>
            })

        }

        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Representantes </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , fechamento: state.CorporativoReducer.fechamento
        , loaderRepresentantesFechamento: state.CorporativoReducer.loaderRepresentantesFechamento

        , RepresentanteBuscaEntity: state.CorporativoReducer.RepresentanteBuscaEntity
        , validacao: state.CorporativoReducer.validacao
        , isSearching: state.CorporativoReducer.isSearching

        , representantes: state.CorporativoReducer.representantes
        , search: state.CorporativoReducer.search
        , cidades: state.CorporativoReducer.cidades

    }
);


export default withRouter(connect(mapStateToProps, {
    corporativoListFechamentoAction
    , corporativoUpdateAction
    , corporativoAddValidacao
    , corporativoRemoveValidacao
    , corporativoListAction
    , corporativoListSearchAction
    , corporativoListCidadesAction
}
)(Corporativo));

