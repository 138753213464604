import React, { Component, useState } from 'react'

import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import moment from 'moment';
import 'moment/locale/pt-br'

import {
    trackersPainelListAction
    , trackersPainelSplitAction
    , trackerTimerSplitAction
    , trackerTimerPainelListAction
    , trackersPainelScreenAction
    , trackerHandleChangeLogsAction
    , trackerSaveLogsAction
    , trackersListReasonsAction
    , trackersDifferentCarsAction
} from '../../actions/TrackersActions';

import {
    Table, Button, Input, Form, Segment, Message, Tab, Icon, Menu, Breadcrumb, Grid, Checkbox
    , Header, Image, Label, Popup
} from 'semantic-ui-react';

import Display from './Display';

import If from '../../utils/If';

import ModalSteps from './ModalSteps';

import soundAlert from '../../audio/nextel_alert.mp3';

const temStatusZero = (objeto) => {
    return objeto.logs.some(log => (log.status === 0) && (log.eventid !== -5) && (log.eventid !== -1));
};

let idsTemp = [2, 3, 4, 79, 86]; // lista de ids temporários que é ocultado para quem não for de TI (enquanto não resolve esse log) - 26/10/2023

class Rastreador extends Component {

    constructor(props) {
        super(props);
        this.audioRef = React.createRef();
        this.state = { audioError: false };
    }

    alertLogs = () => {
        setInterval(() => {
            this.props.paineldata.forEach(objeto => {
                if (temStatusZero(objeto)) {
                    this.playAudio();
                    return false;
                }
            });
        }, 6000);
    }

    playAudio = async () => {
        this.setState({ audioError: false });
        try {
            await this.audioRef.current.play();
        } catch (error) {
            console.error('Erro ao reproduzir áudio:', error);
            this.setState({ audioError: true });
        }
    }

    componentDidMount() {
        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 25 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        this.props.trackersDifferentCarsAction();

        this.props.trackersPainelListAction().then(d => {


            this.props.trackerTimerSplitAction();//faz split na tela
            this.props.trackerTimerPainelListAction(); //vai no banco de dados


        });

        this.props.trackersListReasonsAction();

        /*
         setInterval(() => {
             console.log('tickkk');
             this.props.trackersPainelListAction();
         }, 15000);
         */

        /*setInterval(() => {
           console.log('tickkk split');
           this.props.trackersPainelSplitAction().then(d=>{

           });
       }, 35000);*/

        this.alertLogs();
    }

    render() {

        let { consulta, parametros, issearching } = this.props;

        let { userid, paineldata, painelloader, paineldatasplit, painelsplit, differentcars } = this.props;

        let { logsreasons } = this.props;

        let panes = [];

        const idPermissionTemp = idsTemp.some(item => item == userid); // Verifica se o usuario é do time de TI

        panes.push({
            menuItem: <Menu.Item key='TABpar'><Icon name='list alternate outline' />Exemplos de Mapa</Menu.Item>,
            render: () =>
                <Tab.Pane>

                </Tab.Pane>
        })


        //let flights = [];
        // flights.push({ id: 0, name: 'FSW2753' }, { id: 1, name: 'teste1' }, { id: 2, name: 'ETB5002' }, { id: 3, name: 'teste3' }, { id: 4, name: 'teste4' }, { id: 5, name: 'teste5' }, { id: 6, name: 'GCW3251' }, { id: 7, name: 'GBW2049' }, { id: 8, name: 'teste8' }, { id: 9, name: 'FWR0498' }, { id: 10, name: 'teste10' }, { id: 10, name: 'teste10' });

        const tableheaderbg = "#2b2b2b";
        const fontcolor = '#ffffff';
        const tabletdbg = '#333333';
        const tabletdalert = '#410505';
        const tabletdalert2 = '#991010';
        const tabletdDisabled = '#a4a4a4'
        const tabletdVolta = '#1f744b';

        function getDifference(array1, array2) {
            return array1.filter(object1 => {
                return !array2.some(object2 => {
                    return object1.oc === object2.oc;
                });
            });
        }

        return (
            <div>
                <audio ref={this.audioRef} id="playAudio" >
                    <source src={soundAlert} type="audio/mpeg" />
                    Seu navegador não suporta o elemento audio
                </audio>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active> Rastreador  |<span style={{ fontSize: '45px' }}> {painelsplit + 1} de {paineldatasplit.length} {(painelloader ? <Icon loading name='asterisk' color='blue' /> : '')}
                                {
                                    //(!painelloader ? <Button  visible={false} size="tiny" onClick={() => (this.props.trackersPainelListAction())}>UP</Button> : '')
                                }

                                <Button icon
                                    onClick={() => (this.props.trackersPainelSplitAction())}
                                    size='tiny'
                                >
                                    <Icon name='right arrow' />
                                </Button>
                                <Popup
                                    trigger={
                                        <Label style={{ marginLeft: 20 }} color={differentcars.result === undefined || differentcars.result === null ? 'green' : 'red'}>
                                            {differentcars.result === undefined || differentcars.result === null ? 0 : differentcars.result?.length}
                                        </Label>
                                    }
                                    flowing
                                    hoverable
                                    on={differentcars.result === undefined || differentcars.result === null ? [] : ["hover"]}
                                >
                                    <table border='1px' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                        <thead>
                                            <tr>
                                                <th style={{ padding: '4px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>VEICID</th>
                                                <th style={{ padding: '4px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>VEICTAG</th>
                                                <th style={{ padding: '4px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>VEICTAGDIFF</th>
                                            </tr>
                                        </thead>
                                        {differentcars.result && differentcars.result.map(item => (
                                            <tbody>
                                                <tr>
                                                    <td style={{ padding: '4px', borderBottom: '1px solid #ddd' }}>{item.veicid}</td>
                                                    <td style={{ padding: '4px', borderBottom: '1px solid #ddd' }}>{item.veictag}</td>
                                                    <td style={{ padding: '4px', borderBottom: '1px solid #ddd' }}>{item.veictagdiff}</td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </Popup>
                            </span></Breadcrumb.Section>

                        </Breadcrumb>

                    </Menu.Item>
                    <div style={{ width: '100%' }}>
                        {this.state.audioError && (
                            <Message negative icon={true} style={{ height: '100%' }}>
                                <Icon name='volume up' />
                                <Message.Content>
                                    <Message.Header>Erro ao Emitir Som</Message.Header>
                                    Clique em qualquer parte da página para solucionar!
                                </Message.Content>
                            </Message>

                        )}
                    </div>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>

                        <table border="1" width={'100%'} style={{ borderCollapse: 'collapse' }}>
                            <tr>

                                <th style={{ backgroundColor: tableheaderbg, color: '#fcfd00', fontSize: 20 }}>
                                    <Icon name='truck' size='large' style={{ border: 'solid 4px rgb(26,26,26)', backgroundColor: 'black' }} />&nbsp;&nbsp;

                                </th>
                                <th style={{ backgroundColor: tableheaderbg, color: fontcolor, fontSize: 20 }}>Saída</th>
                                <th style={{ backgroundColor: tableheaderbg, color: fontcolor, fontSize: 20 }}>Entregas</th>
                                <th style={{ backgroundColor: tableheaderbg, color: fontcolor, fontSize: 20, padding: '0 10px', width: '200px' }}>Status Motorista</th>
                                <th style={{ backgroundColor: tableheaderbg, color: fontcolor, fontSize: 20 }}>Status</th>
                            </tr>


                            <If test={paineldatasplit.length > 0}>

                                {paineldatasplit[painelsplit]?.dados.map((item, _index) => {
                                    const words = Object.values(item);

                                    let fundolinha = tabletdbg;

                                    if (item.status == 3) {
                                        fundolinha = tabletdDisabled;
                                    } else {
                                        if (item.logs.filter(d => d.status == 0).length > 0) {
                                            if (item.logs.length > 1) {
                                                fundolinha = tabletdalert2;
                                            } else {
                                                fundolinha = tabletdalert;
                                            }

                                        }
                                    }

                                    if (item.status == 5) {
                                        fundolinha = tabletdVolta;
                                    }

                                    return (
                                        <tr>
                                            <td style={{ color: fontcolor, backgroundColor: fundolinha, textAlign: 'center' }} width={80}>
                                                <div style={{ display: 'inline-flex', color: '#fcfd00', fontSize: '30px' }}>
                                                    <Display key={_index} word={item.veitag.toLocaleUpperCase()} index={_index} />
                                                </div>
                                            </td>

                                            <td style={{ color: fontcolor, backgroundColor: fundolinha, textAlign: 'center' }} width={100}>

                                                <div style={{ display: 'inline-flex', fontSize: '10px' }}>
                                                    <Display key={_index} word={moment(item.cd_dt).format("DD/MM/YYYY").toString()} index={_index} />
                                                </div>

                                                <div style={{ display: 'inline-flex', fontSize: '12px' }}>
                                                    <Display key={_index} word={moment(item.cd_dt).format("hh:mm").toString()} index={_index} />
                                                </div>



                                            </td>

                                            <td style={{ color: fontcolor, backgroundColor: fundolinha, textAlign: 'center' }} width={100}>
                                                <div style={{ display: 'inline-flex', fontSize: '30px' }}>
                                                    <Display key={_index} word={`${item.oc_checkout}/${item.oc_total}`} index={_index} />
                                                </div>
                                            </td>


                                            <td style={{ color: fontcolor, backgroundColor: fundolinha, textAlign: 'center' }} width={90}>
                                                <div style={{ display: 'inline-flex' }}>



                                                    &nbsp;&nbsp;&nbsp;&nbsp;

                                                    {/* <Button size='small' color='grey' inverted style={{ paddingLeft: 3, paddingRight: 3, paddingTop: 3, paddingBottom: 0 }}>
                                                        <img src={'img/user4.svg'} alt="SVG logo image" style={{
                                                            width: 35, height: 35,
                                                            filter: `invert(100%) sepia(27%) saturate(22%) hue-rotate(313deg) brightness(104%) contrast(106%)`
                                                        }} />
                                                    </Button>

                                                    <Button size='small' color='grey' inverted style={{ paddingLeft: 3, paddingRight: 3, paddingTop: 3, paddingBottom: 0 }}>
                                                        <img src={'img/store.svg'} alt="SVG logo image" style={{
                                                            width: 35, height: 35,
                                                            filter: `invert(100%) sepia(27%) saturate(22%) hue-rotate(313deg) brightness(104%) contrast(106%)`
                                                        }} />
                                                    </Button>
                                                    <ModalSteps data={item}
                                                        trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                        button={
                                                            <Button disabled={(painelloader ? true : false)} size='small' color='grey' inverted style={{ paddingLeft: 3, paddingRight: 3, paddingTop: 3, paddingBottom: 0 }}>
                                                                <img src={'img/route1.svg'} alt="SVG logo image" style={{
                                                                    width: 35, height: 35,
                                                                    filter: `invert(100%) sepia(27%) saturate(22%) hue-rotate(313deg) brightness(104%) contrast(106%)`
                                                                }} />
                                                            </Button>
                                                        }
                                                        logsreasons={logsreasons}
                                                        trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                        trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                        idtemp={idPermissionTemp} //ID TEMPORARIO
                                                    />


                                                    <Button size='small' color='grey' inverted style={{ paddingLeft: 3, paddingRight: 3, paddingTop: 3, paddingBottom: 0 }}>
                                                        <img src={'img/store.svg'} alt="SVG logo image" style={{
                                                            width: 35, height: 35,
                                                            filter: `invert(100%) sepia(27%) saturate(22%) hue-rotate(313deg) brightness(104%) contrast(106%)`
                                                        }} />
                                                    </Button> */}

                                                    <Segment inverted style={{ padding: 2, width: '120px' }}>
                                                        <ModalSteps data={item}
                                                            trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                            button={<Button color={(item.logs.filter(d => d.eventid == -6 && d.status == 0).length > 0 ? 'red' : 'green')}
                                                                disabled={(painelloader ? true : false)} size='small' inverted={(item.logs.filter(d => d.eventid == -6 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, marginLeft: 5, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }}
                                                                className={(item.logs.filter(d => d.eventid == -6 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>{item.pausa_situacao}<br></br></Button>}
                                                            logsreasons={logsreasons}
                                                            trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                            trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                            idtemp={idPermissionTemp} //ID TEMPORARIO
                                                        />

                                                    </Segment>


                                                </div>
                                            </td>

                                            <td style={{ color: fontcolor, backgroundColor: fundolinha, textAlign: 'center' }}>
                                                <Segment inverted style={{ padding: 2, width: 450 }}>
                                                    <div style={{ display: 'inline-flex' }}>
                                                        {/* {idsTemp.map((id) => {
                                                            if(userid == id) {
                                                                return (<ModalSteps data={item}
                                                                    trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                                    button={<Button color={(item.logs.filter(d => d.eventid == -1 && d.status == 0).length > 0 ? 'red' : 'blue')} disabled={(painelloader ? true : false)} size='small' inverted={(item.logs.filter(d => d.eventid == -1 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }} className={(item.logs.filter(d => d.eventid == -1 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>Afastamento<br></br>({item.logs.filter(d => d.eventid == -1).length})</Button>}
                                                                    logsreasons={logsreasons}
                                                                    trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                                    trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                                />);
                                                            } else return "";
                                                        })} */}

                                                        {/* <ModalSteps data={item}
                                                            trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                            button={<Button color={(item.logs.filter(d => d.eventid == -6 && d.status == 0).length > 0 ? 'red' : 'green')} disabled={(painelloader ? true : false)} size='small' inverted={(item.logs.filter(d => d.eventid == -6 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }} className={(item.logs.filter(d => d.eventid == -6 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>Refeição<br></br></Button>}
                                                            logsreasons={logsreasons}
                                                            trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                            trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                            idtemp={idPermissionTemp} //ID TEMPORARIO
                                                        /> */}

                                                        {!idPermissionTemp ? null : (<ModalSteps data={item}
                                                            trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                            button={<Button color={(item.logs.filter(d => d.eventid == -1 && d.status == 0).length > 0 ? 'red' : 'blue')} disabled={(painelloader ? true : false)} size='small' inverted={(item.logs.filter(d => d.eventid == -1 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }} className={(item.logs.filter(d => d.eventid == -1 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>Afastamento<br></br>({item.logs.filter(d => d.eventid == -1).length})</Button>}
                                                            logsreasons={logsreasons}
                                                            trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                            trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                            idtemp={idPermissionTemp} //ID TEMPORARIO
                                                        />)}

                                                        <ModalSteps data={item}
                                                            trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                            button={<Button size='small' disabled={(painelloader ? true : false)} color={(item.logs.filter(d => d.eventid == -3 && d.status == 0).length > 0 ? 'red' : 'yellow')} inverted={(item.logs.filter(d => d.eventid == -3 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }} className={(item.logs.filter(d => d.eventid == -3 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>Tempo<br></br> Duração ({item.logs.filter(d => d.eventid == -3).length})</Button>}
                                                            logsreasons={logsreasons}
                                                            trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                            trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                            idtemp={idPermissionTemp} //ID TEMPORARIO
                                                        />
                                                        <ModalSteps data={item}
                                                            trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                            button={<Button size='small' disabled={(painelloader ? true : false)} color={(item.logs.filter(d => d.eventid == -2 && d.status == 0).length > 0 ? 'red' : '')} inverted={(item.logs.filter(d => d.eventid == -2 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }} className={(item.logs.filter(d => d.eventid == -2 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>Distância<br></br>({item.logs.filter(d => d.eventid == -2).length})<br></br> <br></br> </Button>}
                                                            logsreasons={logsreasons}
                                                            trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                            trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                            idtemp={idPermissionTemp} //ID TEMPORARIO
                                                        />
                                                        <ModalSteps data={item}
                                                            trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                            button={<Button size='small' disabled={(painelloader ? true : false)} color={(item.logs.filter(d => d.eventid == -4 && d.status == 0).length > 0 ? 'red' : 'orange')} inverted={(item.logs.filter(d => d.eventid == -4 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }} className={(item.logs.filter(d => d.eventid == -4 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>Corte Sinal<br></br>({item.logs.filter(d => d.eventid == -4).length})<br></br> <br></br> </Button>}
                                                            logsreasons={logsreasons}
                                                            trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                            trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                            idtemp={idPermissionTemp} //ID TEMPORARIO
                                                        />
                                                        <ModalSteps data={item}
                                                            trackersPainelScreenAction={this.props.trackersPainelScreenAction}
                                                            button={<Button size='small' disabled={(painelloader ? true : false)} color={(item.logs.filter(d => d.eventid == -5 && d.status == 0).length > 0 ? 'red' : 'teal')} inverted={(item.logs.filter(d => d.eventid == -5 && d.status == 0).length > 0 ? false : true)} style={{ height: 35, paddingTop: 5, paddingLeft: 4, paddingRight: 4 }} className={(item.logs.filter(d => d.eventid == -5 && d.status == 0 && painelloader == false).length > 0 ? 'btn-pisca' : '')}>Chegando<br></br>({item.logs.filter(d => d.eventid == -5).length})<br></br> <br></br> </Button>}
                                                            logsreasons={logsreasons}
                                                            trackerHandleChangeLogsAction={this.props.trackerHandleChangeLogsAction}
                                                            trackerSaveLogsAction={this.props.trackerSaveLogsAction}
                                                            idtemp={idPermissionTemp} //ID TEMPORARIO
                                                        />


                                                    </div>
                                                </Segment>
                                            </td>
                                        </tr>

                                    )
                                })}
                            </If>

                        </table>

                    </Grid.Column>
                </Grid>
            </div >
        )
    }

}

Rastreador.defaultProps = {
    differentcars: [],
};

Rastreador.propTypes = {
    differentcars: PropTypes.object.isRequired,
};

const mapStateToProps = state => ({
    userid: state.LoginReducer.userid
    , menu: state.LoginReducer.menu
    , paineldatasplit: state.TrackersReducer.paineldatasplit
    , painelsplit: state.TrackersReducer.painelsplit
    , paineldata: state.TrackersReducer.paineldata
    , painelloader: state.TrackersReducer.painelloader
    , logsreasons: state.TrackersReducer.logsreasons
    , differentcars: state.TrackersReducer.differentcars

})

export default withRouter(connect(mapStateToProps, {
    trackersPainelListAction
    , trackersPainelSplitAction
    , trackerTimerSplitAction
    , trackerTimerPainelListAction
    , trackersPainelScreenAction
    , trackerHandleChangeLogsAction
    , trackerSaveLogsAction
    , trackersListReasonsAction
    , trackersDifferentCarsAction
}
)(Rastreador));
