import sortBy from 'sort-by';

import {
    INSTALADORES_UPDATE_BUSCA
    , INSTALADORES_ISSEARCHING_BUSCA
    , INSTALADORES_LIST_SEARCH
} from '../actions/ActionsTypes';

import {InstaladoresBuscaEntity} from '../entities/InstaladoresBuscaEntity'


const INITIAL_STATE = {
    InstaladoresBuscaEntity
  
    , isSearching: false
    , search:[]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        //ok
        case INSTALADORES_UPDATE_BUSCA:
            return { ...state, InstaladoresBuscaEntity: {...state.InstaladoresBuscaEntity, [action.field]: action.payload}}
        
        //ok
        case INSTALADORES_ISSEARCHING_BUSCA:
            return {...state, isSearching:action.payload }

        //ok
        case INSTALADORES_LIST_SEARCH:
            return {...state, search:action.payload}

        default:
            return state;
    }
}