import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Icon, Breadcrumb, Menu, Tab } from 'semantic-ui-react'
import styled from 'styled-components';


import VccChart from './VccChart'
import EsplaneChart from './EsplaneChart'
import MDFChart from './MDFChart'
import MarketPlace from './MarketPlace'


import {
    GraficoDeVendasListAction
    , startTimerAction
    , GraficoDeVendasListFaturarAction
    , GraficoDeVendasListPagarAction
} from '../../actions/GraficoDeVendasActions';
//import MDFChart from './MDFChart';


const StyledTab = styled(Tab)`
&&& {

  .ui.tabular.menu .item{
      font-size:10px !important;
      height:10px !important;
  }
  .ui.menu{
    min-height: 0.0em !important;
  }

 }`






class GraficoDeVendas extends Component {



    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 3 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        this.props.GraficoDeVendasListAction().then(data => { })
        this.props.startTimerAction()
    }



    QtdeDiasUteisAteMomento = (dia) => {
        let valor = dia;

        let mes = this.props.mes
        if (mes.length > 0) {
            valor = mes[0]['dia' + dia];
        }

        return valor
    }



    render() {

        let { graficodevendas, loaderGraficodevendas, mes, meses, vendedores, userid

            , GraficoDeVendasListFaturarAction, GraficoDeVendasListPagarAction

            , graficodevendas_popup_faturar
            , graficodevendas_popup_faturar_loader
            , graficodevendas_popup_pagar
            , graficodevendas_popup_pagar_loader

        } = this.props;







        let qtdeDiasUteisMesCorrente = 0
        let qtdeDiasUteisHoje = 0




        //Dados VCC-------------------------------------------------
        let dataVcc = graficodevendas.filter(d => d.id === 1);
        let dropVCC = graficodevendas.filter(d => d.id === 1).map(item => ({ key: item.anomes, text: item.anomes, value: item.anomes }))
        //----------------------------------------------------------

        //Dados Marketplaces----------------------------------------
        let dataMarketplaces = graficodevendas.filter(d => d.id === 999);

        let dropMarketplaces = graficodevendas.filter(d => d.id === 999).map(item => ({ key: item.anomes, text: item.anomes, value: item.anomes }))
        //distinct
        dropMarketplaces = [...new Set(dropMarketplaces.map(o => JSON.stringify(o)))]
        dropMarketplaces = dropMarketplaces.map(o => JSON.parse(o))

        let vendedoresMarketplaces = vendedores.map(item => ({ key: item.codvend, text: item.apelido, value: item.codvend }))



        //----------------------------------------------------------


        //console.log('--------')
        //console.log(dataVcc)



        let dataEsplane = graficodevendas.filter(d => d.id === 0);
        let dropEsplane = graficodevendas.filter(d => d.id === 0).map(item => ({ key: item.anomes, text: item.anomes, value: item.anomes }))

        if (dataEsplane.length > 0) {
            qtdeDiasUteisMesCorrente = mes[0].uteis

            let meta100MesCorrenteEsplane = dataEsplane[0].meta100;
            dataEsplane.push(
                {
                    anomes: "Meta 100%"
                    , dia01: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(1)
                    , dia02: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(2)
                    , dia03: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(3)
                    , dia04: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(4)
                    , dia05: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(5)
                    , dia06: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(6)
                    , dia07: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(7)
                    , dia08: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(8)
                    , dia09: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(9)
                    , dia10: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(10)
                    , dia11: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(11)
                    , dia12: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(12)
                    , dia13: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(13)
                    , dia14: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(14)
                    , dia15: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(15)
                    , dia16: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(16)
                    , dia17: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(17)
                    , dia18: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(18)
                    , dia19: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(19)
                    , dia20: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(20)
                    , dia21: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(21)
                    , dia22: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(22)
                    , dia23: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(23)
                    , dia24: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(24)
                    , dia25: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(25)
                    , dia26: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(26)
                    , dia27: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(27)
                    , dia28: (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(28)
                    , dia29: (this.QtdeDiasUteisAteMomento(29) === 99 ? null : (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(29))
                    , dia30: (this.QtdeDiasUteisAteMomento(30) === 99 ? null : (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(30))
                    , dia31: (this.QtdeDiasUteisAteMomento(31) === 99 ? null : (meta100MesCorrenteEsplane / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(31))
                    , fechado: 0
                    , id: 1
                    , meta100: 0
                    , meta100falta: 0
                    , meta100pct: 0
                    , meta110: 0
                    , meta110falta: 0
                    , meta110pct: 0
                    , pedidos: 0
                    , previsao: 0
                    , ticketmedio: 0
                    , uteis: 0
                    , uteishoje: 0
                }
            )

            for (let c of dataEsplane) {
                c.uteis = qtdeDiasUteisMesCorrente
                c.uteishoje = qtdeDiasUteisHoje
            }


        }




        let dataMDF = graficodevendas.filter(d => d.id === 10);
        let dropMDF = graficodevendas.filter(d => d.id === 10).map(item => ({ key: item.anomes, text: item.anomes, value: item.anomes }))

        if (dataMDF.length > 0) {
            qtdeDiasUteisMesCorrente = mes[0].uteis

            let meta100MesCorrenteMDF = dataMDF[0].meta100;
            dataMDF.push(
                {
                    anomes: "Meta 100%"
                    , dia01: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(1)
                    , dia02: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(2)
                    , dia03: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(3)
                    , dia04: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(4)
                    , dia05: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(5)
                    , dia06: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(6)
                    , dia07: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(7)
                    , dia08: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(8)
                    , dia09: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(9)
                    , dia10: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(10)
                    , dia11: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(11)
                    , dia12: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(12)
                    , dia13: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(13)
                    , dia14: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(14)
                    , dia15: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(15)
                    , dia16: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(16)
                    , dia17: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(17)
                    , dia18: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(18)
                    , dia19: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(19)
                    , dia20: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(20)
                    , dia21: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(21)
                    , dia22: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(22)
                    , dia23: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(23)
                    , dia24: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(24)
                    , dia25: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(25)
                    , dia26: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(26)
                    , dia27: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(27)
                    , dia28: (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(28)
                    , dia29: (this.QtdeDiasUteisAteMomento(29) === 99 ? null : (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(29))
                    , dia30: (this.QtdeDiasUteisAteMomento(30) === 99 ? null : (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(30))
                    , dia31: (this.QtdeDiasUteisAteMomento(31) === 99 ? null : (meta100MesCorrenteMDF / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(31))
                    , fechado: 0
                    , id: 1
                    , meta100: 0
                    , meta100falta: 0
                    , meta100pct: 0
                    , meta110: 0
                    , meta110falta: 0
                    , meta110pct: 0
                    , pedidos: 0
                    , previsao: 0
                    , ticketmedio: 0
                    , uteis: 0
                    , uteishoje: 0
                }
            )
            for (let c of dataMDF) {
                c.uteis = qtdeDiasUteisMesCorrente
                c.uteishoje = qtdeDiasUteisHoje
            }

        }





        let panes = [

            {
                menuItem: <Menu.Item key='TAB1'><Icon name='area graph' />Você Constrói</Menu.Item>,
                render: () => <VccChart dataList={dataVcc} dataDrop={dropVCC} loader={loaderGraficodevendas}
                    GraficoDeVendasListFaturarAction={GraficoDeVendasListFaturarAction}
                    GraficoDeVendasListPagarAction={GraficoDeVendasListPagarAction}
                    graficodevendas_popup_faturar={graficodevendas_popup_faturar}
                    graficodevendas_popup_faturar_loader={graficodevendas_popup_faturar_loader}
                    graficodevendas_popup_pagar={graficodevendas_popup_pagar}
                    graficodevendas_popup_pagar_loader={graficodevendas_popup_pagar_loader}
                    datameses={meses} />,
            },

            {
                menuItem: <Menu.Item key='TAB2'><Icon name='area graph' />Esplane</Menu.Item>,
                render: () => <EsplaneChart dataList={dataEsplane} dataDrop={dropEsplane} loader={loaderGraficodevendas} />,
            },
            {
                menuItem: <Menu.Item key='TAB3'><Icon name='area graph' />Corporativo</Menu.Item>,
                render: () => <MDFChart dataList={dataMDF} dataDrop={dropMDF} loader={loaderGraficodevendas} />,
            },

            {
                menuItem: <Menu.Item key='TAB4'><Icon name='area graph' />Marketplaces e Vendedores</Menu.Item>,
                render: () => <MarketPlace
                    dataList={dataMarketplaces}
                    dataDrop={dropMarketplaces}
                    loader={loaderGraficodevendas}
                    datameses={meses}
                    vendedores={vendedoresMarketplaces}
                    userid={userid}
                />,
            }




        ]

        return (
            <div>
                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Gráfico de Vendas</Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>


                <Grid columns={1} doubling>
                    <Grid.Column>
                        <StyledTab panes={panes} />
                    </Grid.Column>
                </Grid>

            </div>
        );
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , vendedorvcc: state.LoginReducer.vendedorvcc
        , graficodevendas: state.GraficoDeVendasReducer.graficodevendas
        , loaderGraficodevendas: state.GraficoDeVendasReducer.loaderGraficodevendas
        , mes: state.GraficoDeVendasReducer.mes
        , meses: state.GraficoDeVendasReducer.meses
        , vendedores: state.GraficoDeVendasReducer.vendedores

        , graficodevendas_popup_faturar: state.GraficoDeVendasReducer.graficodevendas_popup_faturar
        , graficodevendas_popup_faturar_loader: state.GraficoDeVendasReducer.graficodevendas_popup_faturar_loader
        , graficodevendas_popup_pagar: state.GraficoDeVendasReducer.graficodevendas_popup_pagar
        , graficodevendas_popup_pagar_loader: state.GraficoDeVendasReducer.graficodevendas_popup_pagar_loader

    }
);

export default withRouter(connect(mapStateToProps, {
    GraficoDeVendasListAction
    , startTimerAction
    , GraficoDeVendasListFaturarAction
    , GraficoDeVendasListPagarAction
}
)(GraficoDeVendas));

