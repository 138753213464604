import { useState, useEffect } from 'react';
import PropTypes from 'prop-types'
import {
    Tab, Icon
    , Button, Table
    , Dropdown, Input, Message
} from 'semantic-ui-react';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
    }
`;

const StyledCell = styled(Table.Cell)`
&&&{
    div{
        font-size:15px !important;
    },
    span{
        font-size:15px !important;
      }
    }
`;



function Ajudante({
    ajudante,
    userid,
    despesasDeViagensAjudanteChangeNomeAction,
    despesasDeViagensAjudanteChangeAtivoAction,
    despesasDeViagensAjudanteAtualizaAction,
    despesasDeViagensAjudanteEditAction,
    despesasDeViagensAjudanteInsereAction
}) {

    // console.log("userid:", userid)

    const [idviagemajudanteInput, setIdviagemajudanteInput] = useState('');
    const [viagemajudanteInput, setViagemajudanteInput] = useState('');
    const [ativoSelecionado, setAtivoSelecionado] = useState('');
    const [errors, setErrors] = useState([]); // Estado para os erros
    const [successMessage, setSuccessMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        if (successMessage) {
            const timer = setTimeout(() => {
                setSuccessMessage('');
                setIsLoading(false);
            }, 3000);

            return () => clearTimeout(timer);
        }
    }, [successMessage]);

    const handleSalvarClick = (idviagemajudante, viagemajudante, ativo) => {
        // console.log(idviagemajudante, viagemajudante, ativo, 'props onsalvarajudante');
        // Verifique se algum campo está vazio
        if (!isLoading) {
            const errors = [];
            if (!idviagemajudanteInput) {
                errors.push("O campo Cod. Parceiro é obrigatório");
            }
            if (!viagemajudanteInput) {
                errors.push("O campo Nome é obrigatório");
            }
            if (!ativoSelecionado) {
                errors.push("O campo Status é obrigatório");
            }
            if (errors.length > 0) {
                setErrors(errors);
                return;
            }

            const idviagemajudanteNumber = parseInt(idviagemajudanteInput, 10); // Use a base 10 para garantir a interpretação correta

            // Se todas as verificações passaram, crie o objeto entidade
            const entidade = {
                idviagemajudante: idviagemajudanteNumber,
                viagemajudante: viagemajudanteInput,
                ativo: ativoSelecionado,
            };

            // console.log("Objeto entidade criado:", entidade);

            setIsLoading(true);
            // Dispare a action utilizando o mapDispatchToProps.

            despesasDeViagensAjudanteInsereAction(entidade).then((response) => {
                setSuccessMessage('Usuário criado com sucesso!'); // Define a mensagem de sucesso
                // Limpar os campos após o sucesso
                setIdviagemajudanteInput('');
                setViagemajudanteInput('');
                setAtivoSelecionado('');
                setErrors([]); // Limpa os erros se não houver mais erros
            });
        }


    };


    const onEditingAjudante = (idajudante, viagemajudante, isediting) => {

        const item = { idajudante: idajudante, viagemajudante: viagemajudante }

        despesasDeViagensAjudanteEditAction(idajudante, !isediting);
    }

    const handleInputAjudanteNomeChange = (e, id) => {
        const novoValor = e.target.value;
        // Atualize o estado com o novo valor
        //this.setState({ valorInput: novoValor });
        // console.log(`Novo valor inserido: ${novoValor} em ${id}`);
        despesasDeViagensAjudanteChangeNomeAction(id, novoValor);
    };

    const handleInputAjudanteAtivoChange = (e, { idviagemajudante, value }) => {
        const novoValor = value;
        // Atualize o estado com o novo valor
        //this.setState({ valorInput: novoValor });
        // console.log(`Novo valor inserido: ${novoValor} em ${idviagemajudante}`);
        despesasDeViagensAjudanteChangeAtivoAction(idviagemajudante, novoValor);
    };

    const onSalvarAjudante = (idviagemajudante, viagemajudante, ativo, issaving) => {
        // Crie um objeto entidade com os dados necessários.
        console.log(issaving, 'issaving');

        if (!issaving) {

            const entidade = {
                idviagemajudante,
                viagemajudante,
                ativo,
            };
            // console.log(entidade, "valores entidade");

            despesasDeViagensAjudanteAtualizaAction(entidade).then(d => {

                // Feche o modo de edição após salvar
                const idajudante = idviagemajudante
                // console.log(idviagemajudante, "id ajudante salvar edit")
                despesasDeViagensAjudanteEditAction(idajudante, false);
            });


        }

    };



    const onCancelarAjudante = (idajudante, isediting) => {
        if (isediting === true) {
            despesasDeViagensAjudanteEditAction(idajudante, false);
        }
    }

    const ativo = [
        { key: 'S', text: 'S', value: 'S' },
        { key: 'N', text: 'N', value: 'N' },
    ];

    // const isUserAllowed = ["2", "3", "79", "20", "18"].includes(userid);
    // if (!isUserAllowed) {
    //     return null;
    // }
    return (
        <> {successMessage && (
            <Message
                success
                header='Sucesso!'
                content={successMessage}
            />
        )}
            {errors.length > 0 && (
                <Message
                    error
                    header='Houve alguns erros com o seu envio'
                    list={errors} // Exibe os erros aqui
                />
            )}
            <Tab.Pane loading={false}>
                <StyledTable celled id="listPendentes" compact='very' size='' >
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">Cod. Parceiro</Table.HeaderCell>
                            <Table.HeaderCell width="3">Nome</Table.HeaderCell>
                            <Table.HeaderCell width="1">Status</Table.HeaderCell>
                            <Table.HeaderCell width="2">Salvar</Table.HeaderCell>
                        </Table.Row>
                        <Table.Row>
                            <Table.Cell>
                                <Input
                                    value={idviagemajudanteInput}
                                    maxLength={7}
                                    onChange={(e) => setIdviagemajudanteInput(e.target.value)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Input
                                    value={viagemajudanteInput}
                                    maxLength={100}
                                    onChange={(e) => setViagemajudanteInput(e.target.value)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Dropdown
                                    compact
                                    selection
                                    placeholder='Status'
                                    options={ativo}
                                    value={ativoSelecionado}
                                    onChange={(e, { value }) => setAtivoSelecionado(value)}
                                />
                            </Table.Cell>
                            <Table.Cell>
                                <Button
                                    basic
                                    color='blue'
                                    onClick={handleSalvarClick}
                                    // disabled={isLoading}
                                    // onClick={(isLoading?handleSalvarClick:null)}
                                    loading={isLoading}
                                >
                                    <Icon name='check' />
                                    Salvar
                                </Button>
                            </Table.Cell>
                        </Table.Row>
                    </StyledTableHeader>
                </StyledTable>
            </Tab.Pane>
            <Tab.Pane loading={false}>
                <StyledTable celled id="listPendentes" compact='very'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell >Cod. Parceiro</Table.HeaderCell>
                            <Table.HeaderCell >Nome</Table.HeaderCell>
                            <Table.HeaderCell width="1">Status</Table.HeaderCell>
                            <Table.HeaderCell width="4">Editar</Table.HeaderCell>
                        </Table.Row>
                        {ajudante.map(item => (
                            <Table.Row key={"viagemajudante" + item.idviagemajudante}>
                                <StyledCell>
                                    {item.idviagemajudante}
                                </StyledCell>
                                <StyledCell>
                                    {(!item.isediting ? item.viagemajudante
                                        :
                                        <Input value={item.viagemajudante}
                                            onChange={(e) => handleInputAjudanteNomeChange(e, item.idviagemajudante)}
                                        />
                                    )}
                                </StyledCell>
                                <StyledCell>
                                    {(!item.isediting ? (
                                        item.ativo
                                    ) : (
                                        <div style={{ width: '10' }}>
                                            <Dropdown
                                                compact
                                                defaultValue={item.ativo}
                                                selection
                                                placeholder='Ativo'
                                                options={ativo}
                                                idviagemajudante={item.idviagemajudante}
                                                onChange={handleInputAjudanteAtivoChange}

                                            >
                                            </Dropdown></div>
                                    ))}
                                </StyledCell>
                                <StyledCell>
                                    {item.isediting ? (
                                        <div>
                                            <Button

                                                onClick={() => onSalvarAjudante(item.idviagemajudante, item.viagemajudante, item.ativo, item.issaving)}
                                                basic color='blue'
                                                loading={item.issaving}
                                            >
                                                <Icon name='check' />
                                                Salvar
                                            </Button>
                                            <Button

                                                onClick={() => onCancelarAjudante(item.idviagemajudante, item.isediting)}
                                                basic color='red'>
                                                <Icon name='cancel' />
                                                Cancelar
                                            </Button>

                                        </div>
                                    ) : (
                                        <Button
                                            loading={item.isediting}
                                            onClick={() => onEditingAjudante(item.idviagemajudante, item.viagemajudante, item.isediting)}
                                            basic color='green'
                                        >
                                            <Icon name='pencil' />
                                            Editar
                                        </Button>
                                    )}
                                </StyledCell>
                            </Table.Row>
                        ))}
                    </StyledTableHeader>
                </StyledTable>
            </Tab.Pane>
        </>
    )
}


Ajudante.defaultProps = {
    ajudante: []
};

Ajudante.propTypes = {
    ajudante: PropTypes.array.isRequired
    , handleSalvarClick: PropTypes.func.isRequired
    , despesasDeViagensAjudanteChangeNomeAction: PropTypes.func.isRequired
    , despesasDeViagensAjudanteChangeAtivoAction: PropTypes.func.isRequired
    , despesasDeViagensAjudanteAtualizaAction: PropTypes.func.isRequired
    , despesasDeViagensAjudanteEditAction: PropTypes.func.isRequired
};

export default Ajudante;