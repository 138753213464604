import * as Api from '../utils/API';
import {
    PIX_LIST_PIX,
    PIX_FILTRO_PIX,
    PIX_DETALHE_PIX,
    PIX_APPEND_PIX
} from '../actions/ActionsTypes';

export const pixListPixAction = (entidade, page = 1) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log(entidade)
        Api.getAllListPixFast({ ...entidade, ...page }).then(dados => {
            dispatch({
                type: page === 1 ? PIX_LIST_PIX : PIX_APPEND_PIX, // PIX_APPEND_PIX se for paginação
                payload: dados,
            });
            resolve([]);
        }).catch(error => {
            reject(error);
        })
    });

export const pixFiltroPixAction = (entidade, page = 1) => (dispatch) =>
    new Promise((resolve, reject) => {
        Api.postFiltroPixFast({ ...entidade, ...page }) // Inclui o número da página na entidade
            .then((dados) => {
                dispatch({
                    type: page === 1 ? PIX_FILTRO_PIX : PIX_APPEND_PIX, // PIX_APPEND_PIX se for paginação
                    payload: dados,
                });
                resolve([]);
            })
            .catch((error) => {
                reject(error);
            });
    });

export const pixDetalhePixAction = (entidade, page = 1, pageSize = 100) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log(entidade)
        Api.getDetalhePixFast(entidade).then(dados => {
            console.log('DADOS ->', dados[0])
            dispatch({ type: PIX_DETALHE_PIX, payload: dados })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });
