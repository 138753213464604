import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Table, Input, Button, Icon, Pagination, Dropdown, Tab } from 'semantic-ui-react';
import ModalAnalisar from './ModalDevolucao/ModalAnalisar'; // Importe o Modal
import ModalCriarDevolucao from './ModalDevolucao/ModalCriarDevolucao'; // Importe o Modal
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { marketplacesDevoluçõesListAction, marketplacesDevoluçõesAnaliseAction } from './../../actions/MarketPlacesActions'


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;

function Devolucao(props) {

    const { marketplacesDevoluçõesListAction, marketplacesDevoluçõesAnaliseAction } = props; //  actions
    const { devolucaoList, isdevolucaoloading, isLoadingList, isLoadingAnalisar } = props; // reducers

    useEffect(() => {
        let pagination = {
            page: 1,
            limit: 10
        }
        marketplacesDevoluçõesListAction(pagination).then((data) => {
            let page_size = 10;
            let totalpages = Math.ceil(data[0]?.qtd_total / page_size);
            setTotalPages(totalpages)
        });
    }, [])


    const [openModalAnalisar, setOpenModalAnalisar] = useState(false); // Estado para controlar o modal
    const [openModalDevolucao, setOpenModalDevolucao] = useState(false); // Estado para controlar o modal
    const [searchValue, setSearchValue] = useState('');
    const [dropdownValue, setDropdownValue] = useState('todos');
    const [activePage, setActivePage] = useState(1);
    const [totalPages, setTotalPages] = useState(null);
    const [loadingId, setLoadingId] = React.useState(null);


    const options = [
        { key: 'codparc', text: 'codparc', value: 'codparc' },
        { key: 'nufin', text: 'nufin', value: 'nufin' },
        { key: 'devolucaoid', text: 'devolucaoid', value: 'devolucaoid' },
        { key: 'todos', text: 'todos', value: 'todos' }
    ]


    const truncateName = (name, wordLimit) => {
        const words = name?.split(' ');

        if (words?.length > wordLimit) {
            return words.slice(0, wordLimit).join(' ') + '...';
        }
        return name;
    };

    const handleSearch = () => {
        let data = '';
        if (dropdownValue === "codparc") {
            data = { codparc: Number(searchValue) };
            marketplacesDevoluçõesListAction(data);
        } else if (dropdownValue === "nufin") {
            data = { nufin: Number(searchValue) }
            marketplacesDevoluçõesListAction(data);
        } else if (dropdownValue === "devolucaoid") {
            data = { devolucaoid: Number(searchValue) }
            marketplacesDevoluçõesListAction(data);
        }
        else if (dropdownValue === "todos") {
            setSearchValue('')
            let pagination = {
                page: 1,
                limit: 10
            }
            marketplacesDevoluçõesListAction(pagination).then((data) => {
                let page_size = 10;
                let totalpages = Math.ceil(data[0]?.qtd_total / page_size);
                setTotalPages(totalpages)
            });
        }

    };

    const handlePageChange = (e, { activePage }) => {
        setActivePage(activePage); // Atualiza o estado com a nova página ativa
        console.log(`Página ${activePage}`); // Exibe o número da página no console
        // Aqui você pode adicionar a lógica para buscar os dados da nova página, se necessário
        let pagination = {
            page: activePage,
            limit: 10
        };
        marketplacesDevoluçõesListAction(pagination).then((data) => {
            let page_size = 10;
            let totalpages = Math.ceil(data[0]?.qtd_total / page_size);
            setTotalPages(totalpages)
        });
    };

    const handleAnalisar = (devolucaoid) => {
        setLoadingId(devolucaoid)
        marketplacesDevoluçõesAnaliseAction(devolucaoid).then((item) => {
            if (isLoadingAnalisar == false) {
                setOpenModalAnalisar(true)
                setLoadingId(null);
            }
        })

    }


    let loading = true;
    return (
        <>
            <h5>Busca Devolução</h5>
            <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'space-between', marginBottom: '1rem' }}>
                <div style={{ display: 'flex', gap: '0.5rem' }}>
                    <Input
                        label={<Dropdown defaultValue='todos' options={options} onChange={(e, data) => setDropdownValue(data.value)} />}
                        labelPosition='left'
                        placeholder='Buscar'
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                        onKeyPress={(event) => {
                            if (!/[0-9]/.test(event.key)) {
                                event.preventDefault();
                            }
                        }}
                        loading={isdevolucaoloading}
                        style={{ width: '300px' }} // Define uma largura fixa para o Input
                    />
                    <Button
                        color='blue'
                        style={{ marginLeft: '15px' }}
                        onClick={handleSearch}
                    >
                        <Icon name='search' />
                        Buscar
                    </Button>
                </div>
                <div>
                    <Button
                        color='blue'
                        style={{ marginLeft: '15px' }}
                        onClick={() => setOpenModalDevolucao(true)}
                    >
                        <Icon name='plus' />
                        Criar Devolução
                    </Button>
                </div>
            </div>
            <Tab.Pane loading={isLoadingList}>
                <StyledTable>
                    <StyledTableHeader>
                        <Table.Row style={{ textAlign: 'center' }}>
                            <Table.HeaderCell>Id</Table.HeaderCell>
                            <Table.HeaderCell>Nufin</Table.HeaderCell>
                            <Table.HeaderCell>codparc</Table.HeaderCell>
                            <Table.HeaderCell>Nome Parc</Table.HeaderCell>
                            <Table.HeaderCell>Ocorrência</Table.HeaderCell>
                            <Table.HeaderCell>Valor</Table.HeaderCell>
                            <Table.HeaderCell>Vencimento</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    {devolucaoList.map((item) => (
                        <StyledTableBody style={{ textAlign: 'center' }} >
                            <Table.Cell>{item.devolucaoid}</Table.Cell>
                            <Table.Cell>{item.nufin}</Table.Cell>
                            <Table.Cell>{item.codparc}</Table.Cell>
                            <Table.Cell>{truncateName(item.nomeparc, 3)}</Table.Cell>
                            <Table.Cell>{item.relato}</Table.Cell>
                            <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}</Table.Cell>
                            <Table.Cell>
                                {new Date(item.vencimento).toLocaleDateString('pt-BR', {
                                    day: '2-digit',
                                    month: '2-digit',
                                    year: 'numeric',
                                })}
                            </Table.Cell>
                            <Table.Cell>
                                <Button color='green' onClick={() => handleAnalisar(item.devolucaoid)} loading={loadingId === item.devolucaoid}>
                                    <Icon name='search' />
                                    Analisar
                                </Button>
                            </Table.Cell>
                        </StyledTableBody>
                    ))}

                </StyledTable>
            </Tab.Pane>

            <div style={{ textAlign: 'right', marginTop: '15px' }}>
                <Pagination
                    boundaryRange={0}
                    defaultActivePage={1}
                    ellipsisItem={null}
                    firstItem={1}
                    lastItem={totalPages}
                    siblingRange={1}
                    totalPages={totalPages}
                    activePage={activePage} // Vincula o estado da página ativa
                    onPageChange={handlePageChange} // Chama o manipulador ao mudar de página
                />
            </div>

            <ModalCriarDevolucao open={openModalDevolucao} onClose={() => setOpenModalDevolucao(false)} />
            <ModalAnalisar open={openModalAnalisar} onClose={() => setOpenModalAnalisar(false)} />
        </>
    );
}


const mapStateToProps = (state) => ({
    devolucaoList: state.MarketPlacesReducer.devolucaoList,
    isdevolucaoloading: state.MarketPlacesReducer.isdevolucaoloading,
    isLoadingList: state.MarketPlacesReducer.isLoadingList,
    isLoadingAnalisar: state.MarketPlacesReducer.isLoadingAnalisar

})
export default withRouter(connect(mapStateToProps, {
    marketplacesDevoluçõesListAction,
    marketplacesDevoluçõesAnaliseAction

}
)(Devolucao));