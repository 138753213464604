import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Message } from 'semantic-ui-react'
import If from '../../utils/If';


class LogScreen extends Component {

    constructor(props) {
        super(props);
    }

    render() {


        let { logData
        } = this.props;

        let DataOK = [];
        let DataError = [];

        for (let i of logData) {
            if (i.status === 'OK') {
                DataOK.push(i.item + ' - ' + i.mensagem)
            } else if (i.status === 'Error') {
                DataError.push(i.item + ' - ' + i.mensagem)
            }
        }

        return (
            <div>
            <If test={DataOK.length > 0}>
                <Message
                    positive
                    header='Os itens foram enviados com sucesso'
                    list={DataOK} />
            </If>
            <If test={DataError.length > 0}>
                <Message
                    error
                    header='Houve alguns erros com o seu envio'
                    list={DataError} />
            </If>
            </div>





        )
    }
}

LogScreen.defaultProps = {
    logData: []
};

LogScreen.propTypes = {
    logData: PropTypes.array.isRequired
};


export default LogScreen