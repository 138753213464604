import * as Api from '../utils/API';
import {
    ASSISTENCIA_LIST,
    ASSISTENCIA_CARD_LIST,
    ASSISTENCIA_LIST_LOADING,
    ASSISTENCIA_MODAL,
    ASSISTENCIA_MODAL_LOADING,
    ASSISTENCIA_LIST_FILTRO,
    ASSISTENCIA_GRID_LOADING,
    ASSISTENCIA_LOADING_SAVE,
    ASSISTENCIA_LOADING_SEARCH,
    ASSISTENCIA_ATUALPAG,
    ASSISTENCIA_TOTALPAG,
    ASSISTENCIA_LIST_FILTRO_STATUS,
    ASSISTENCIA_LOADING_SAVE_IMG_VISITA
} from '../actions/ActionsTypes';

export const assistenciaListAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_LIST_FILTRO_STATUS, payload: null })
        dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: true })
        const page = { page: dados };

        Api.getListAssistenciaMGR(page).then(dados => {
            dispatch({ type: ASSISTENCIA_LIST, payload: dados })
            dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: false })
            dispatch({ type: ASSISTENCIA_GRID_LOADING, payload: false })
            dispatch({ type: ASSISTENCIA_LIST_FILTRO, payload: true })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });




export const assistenciaCardListAction = () => (dispatch) =>
    new Promise(function (res, reject) {
        // dispatch({ type: ASSISTENCIA_GRID_LOADING, payload: true })
        Api.getListAssistenciaCardMGR().then(dados => {
            dispatch({ type: ASSISTENCIA_CARD_LIST, payload: dados })
            dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: false })
            // dispatch({ type: ASSISTENCIA_GRID_LOADING, payload: false })
            res(dados)
        }).catch(error => {
            reject(error);
        })
    });


export const assistenciaListFiltroAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log(dados.status)

        dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: true })
        dispatch({ type: ASSISTENCIA_LIST_FILTRO, payload: true })
        dispatch({ type: ASSISTENCIA_LIST_FILTRO_STATUS, payload: dados.status })


        if (dados.codparc || dados.protocolo) {
            dispatch({ type: ASSISTENCIA_LOADING_SEARCH, payload: true })
        }

        Api.getListFiltroAssistenciaMGR(dados).then(dados => {
            dispatch({ type: ASSISTENCIA_LIST, payload: dados })
            dispatch({ type: ASSISTENCIA_LIST_LOADING, payload: false })
            dispatch({ type: ASSISTENCIA_LOADING_SEARCH, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });


export const assistenciaModalAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        console.log('chamou a action')
        console.log(dados)

        dispatch({ type: ASSISTENCIA_MODAL_LOADING, payload: true })
        const data = { assistencia_id: dados }

        Api.getModalAssistenciaMGR(data).then(dados => {
            dispatch({ type: ASSISTENCIA_MODAL, payload: dados })
            dispatch({ type: ASSISTENCIA_MODAL_LOADING, payload: false })
            resolve('OK')
        }).catch(error => {
            reject(error);
        })
    });

export const assistenciaModalClearAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_MODAL, payload: [] })
    });

export const assistenciaStatusAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.postStatusAssistenciaMGR(dados).then(dados => {
            resolve('OK')
        }).catch(error => {
            reject(error);
        })
    });
// Função para converter ArrayBuffer em Base64
function arrayBufferToBase64(buffer) {
    const binary = [];
    const bytes = new Uint8Array(buffer);
    for (let i = 0; i < bytes.byteLength; i++) {
        binary.push(String.fromCharCode(bytes[i]));
    }
    return btoa(binary.join(''));
}

export const assistenciaRespostaClienteAction = (dados, files) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_LOADING_SAVE, payload: true })

        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        if (files.length > 0) {


            const binaryFiles = Promise.all(
                files.map(async (file, index) => {
                    const binary = await convertFileToBinary(file); // Garantindo que o file seja um Blob/File
                    const base64Binary = arrayBufferToBase64(binary);

                    return {
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        identidade: `nomearq_${index + 1}`,
                        content: base64Binary
                    };
                })
            );

            binaryFiles
                .then((resolvedBinaryFiles) => {
                    if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                        throw new Error("Erro ao processar arquivos binários");
                    }

                    // Converter o ArrayBuffer para Base64
                    const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                        const key = `arquivo_${index + 1}`;
                        const nomeArqKey = `nomearq_${index + 1}`;
                        return { [key]: file.content, [nomeArqKey]: file.name };
                    });

                    // Atualizando combinedData com o base64Files e binaryFiles
                    const updatedCombinedData = [dados, arrayBase64, resolvedBinaryFiles];
                    console.log(updatedCombinedData);

                    console.log(updatedCombinedData)
                    Api.postRespostaAssistenciaMGR(updatedCombinedData).then(dados => {
                        dispatch({ type: ASSISTENCIA_LOADING_SAVE, payload: false })
                        resolve('OK')
                    }).catch(error => {
                        dispatch({ type: ASSISTENCIA_LOADING_SAVE, payload: false })
                        reject(error);
                    })
                })
                .catch((error) => {
                    console.error("Erro ao processar arquivos:", error);

                    reject(error);
                });
        } else {
            const updatedCombinedData = [dados, [], []];
            Api.postRespostaAssistenciaMGR(updatedCombinedData).then(dados => {
                dispatch({ type: ASSISTENCIA_LOADING_SAVE, payload: false })
                resolve('OK')
            }).catch(error => {
                dispatch({ type: ASSISTENCIA_LOADING_SAVE, payload: false })
                reject(error);
            })
        }
    });




export const assistenciaSalvaPagination = (entidade, item) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log('OIIIII')

        dispatch({ type: ASSISTENCIA_ATUALPAG, payload: entidade })
        dispatch({ type: ASSISTENCIA_TOTALPAG, payload: item })
        resolve()
    }
    )


export const assistenciaSaveImageVisitaTecnicaAction = (dados, files) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ASSISTENCIA_LOADING_SAVE_IMG_VISITA, payload: true })

        const convertFileToBinary = (file) => {
            return new Promise((resolve, reject) => {
                if (!(file instanceof Blob)) {
                    return reject(new Error("O objeto passado não é um Blob ou File válido."));
                }

                const reader = new FileReader();
                reader.readAsArrayBuffer(file);
                reader.onload = () => resolve(reader.result);
                reader.onerror = (error) => reject(error);
            });
        };

        if (files.length > 0) {


            const binaryFiles = Promise.all(
                files.map(async (file, index) => {
                    const binary = await convertFileToBinary(file); // Garantindo que o file seja um Blob/File
                    const base64Binary = arrayBufferToBase64(binary);

                    return {
                        name: file.name,
                        type: file.type,
                        size: file.size,
                        identidade: `nomearq_${index + 1}`,
                        content: base64Binary
                    };
                })
            );

            binaryFiles
                .then((resolvedBinaryFiles) => {
                    if (!resolvedBinaryFiles || !Array.isArray(resolvedBinaryFiles)) {
                        throw new Error("Erro ao processar arquivos binários");
                    }

                    // Converter o ArrayBuffer para Base64
                    const arrayBase64 = resolvedBinaryFiles.map((file, index) => {
                        const key = `arquivo_${index + 1}`;
                        const nomeArqKey = `nomearq_${index + 1}`;
                        return { [key]: file.content, [nomeArqKey]: file.name };
                    });

                    // Atualizando combinedData com o base64Files e binaryFiles
                    const updatedCombinedData = [dados, arrayBase64, resolvedBinaryFiles];
                    console.log(updatedCombinedData);

                    console.log(dados, updatedCombinedData)
                    Api.postSaveImageVisitaTecnica(updatedCombinedData).then(dados => {
                        dispatch({ type: ASSISTENCIA_LOADING_SAVE_IMG_VISITA, payload: false })
                        resolve(dados)
                    }).catch(error => {
                        dispatch({ type: ASSISTENCIA_LOADING_SAVE_IMG_VISITA, payload: false })
                        reject(error);
                    })
                })
                .catch((error) => {
                    console.error("Erro ao processar arquivos:", error);
                    dispatch({ type: ASSISTENCIA_LOADING_SAVE_IMG_VISITA, payload: false })
                    reject(error);
                });
        } else {
            const updatedCombinedData = [dados, [], []];
            // Api.postRespostaAssistenciaMGR(updatedCombinedData).then(dados => {
            //     dispatch({ type: ASSISTENCIA_LOADING_SAVE, payload: false })
            //     resolve('OK')
            // }).catch(error => {
            //     dispatch({ type: ASSISTENCIA_LOADING_SAVE, payload: false })
            //     reject(error);
            // })
        }
    });