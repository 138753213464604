export const TarefaEntity = {
    tarefaid: 0
    ,codparc: 0
    ,cliente: ""
    ,datapesquisa: null
    ,status: 0
    ,statusdescricao: ""
    ,dtstatus: null
    ,questao: 0
    ,avaliacao: ""
    ,resposta: 0
    ,mensagem: ""
    ,avaliacaogestor: ""
    ,vendedor:""
    ,consultor:""
    ,userid:""
    ,login:""
    ,formOcorrido:""
    ,formSolucao:""
    ,formObservacao:""
    ,modalOpen:false
}