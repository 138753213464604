import {
    GRAFICODEVENDAS_LIST
    , GRAFICODEVENDAS_LOADER
    , GRAFICODEVENDAS_POPUP_FATURAR
    , GRAFICODEVENDAS_POPUP_PAGAR
    , GRAFICODEVENDAS_POPUP_FATURAR_LOADER
    , GRAFICODEVENDAS_POPUP_PAGAR_LOADER

} from '../actions/ActionsTypes';

import { START_TIMER } from 'redux-timer';
import { STOP_TIMER } from 'redux-timer';
import * as Api from '../utils/API';
import sortBy from 'sort-by';


export const startTimerAction = () => async dispatch => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'painelGraficoTimer',
            action: async () => {
                try {
                    //console.log('tick graph!')

                    dispatch(GraficoDeVendasListAction())


                } catch (e) {
                    // do something with this error
                }
            },
            interval: 180000,
            runImmediately: false
        }
    });
};


export const GraficoDeVendasListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllGraficoDeVendas().then(dados => {

            let vendedores = []


            let userid = sessionStorage.getItem("userid")
            let vendedorvcc = sessionStorage.getItem("vendedorvcc")
            // //rodrigo|daniel|guilherme|michel|Jeter
            if (userid != "2" && userid != "4" && userid != "37" && userid != "3" && userid != "44" && vendedorvcc != "null" && vendedorvcc != "") {
                for (let i of dados.vendedores) {
                    if (i.codvend == "94" || i.codvend == vendedorvcc) {
                        vendedores.push(i)
                    }
                }
            } else {
                vendedores = dados.vendedores
            }


            /*alert(`
            userid: ${sessionStorage.getItem("userid")}
            vendedorvcc: ${sessionStorage.getItem("vendedorvcc")}
            `)*/

            dispatch({ type: GRAFICODEVENDAS_LIST, vendas: dados.vendas, mes: dados.mes, meses: dados.meses, vendedores: vendedores })
            dispatch({ type: GRAFICODEVENDAS_LOADER, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })

    });

export const GraficoDeVendasListFaturarAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: GRAFICODEVENDAS_POPUP_FATURAR_LOADER, payload: true });
        Api.getAllGraficoDeVendasDadosFaturar().then(dados => {
            // console.log('###Faturar', dados)
            dispatch({ type: GRAFICODEVENDAS_POPUP_FATURAR, payload: dados });
            dispatch({ type: GRAFICODEVENDAS_POPUP_FATURAR_LOADER, payload: false });
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

export const GraficoDeVendasListPagarAction = () => (dispatch) =>
    new Promise((resolve, reject) => {
        dispatch({ type: GRAFICODEVENDAS_POPUP_PAGAR_LOADER, payload: true });
        Api.getAllGraficoDeVendasDadosPagar().then(dados => {
            dispatch({ type: GRAFICODEVENDAS_POPUP_PAGAR, payload: dados });
            dispatch({ type: GRAFICODEVENDAS_POPUP_PAGAR_LOADER, payload: false });
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });
