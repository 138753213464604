import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Segment, List, Form, Tab, Modal, Input, Header } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';
import If from '../../utils/If';




const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`

//


class ListEmAndamento extends Component {

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }

    onEntrega(e, pedido) {
        pedido.ad_tracknusuari = this.props.login

        if (pedido.isentregasaving == false) {
            this.props.ordemCargaEmAndamentoEntregueAction(pedido)
        }
    }


    componentDidMount() {

    }

    onSaveFrete(e, pedido) {
        if (!pedido.isfretecontextsaving) {
            this.props.ordemCargaSaveNaoPagosFaturadosFrete(pedido);
        }


    }

    onEditFrete(e, pedido) {
        this.props.ordemCargaUpdateNaoPagosFaturadosFreteAction(pedido, true)
    }
    onEditFreteTexto(e, pedido) {
        this.props.ordemCargaUpdateNaoPagosFaturadosFreteTextoAction(pedido, e.target.value)
    }

    onCancelFrete(e, pedido) {
        this.props.ordemCargaUpdateNaoPagosFaturadosFreteAction(pedido, false)
    }




    render() {
        let { dataList, dataQtde, loader
        } = this.props;

        let total = 0
        let afaturar = 0
        let faturado = 0

        const trocarCorLigar = (ligar) => {
            let color = '';

            if (ligar == 'S') {
                color = 'green';
            } else if (ligar == 'N') {
                color = 'red';
            } else {
                color = 'grey';
            }
            return color;

        }


        return (
            <Tab.Pane loading={loader}>
                <StyledTable celled id="ListPagosNaoFaturados" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="2">FBits</Table.HeaderCell>
                            <Table.HeaderCell width="1">Data Pedido</Table.HeaderCell>

                            <Table.HeaderCell width="1">Nota Fiscal</Table.HeaderCell>
                            <Table.HeaderCell width="1">Data da Nota</Table.HeaderCell>
                            <Table.HeaderCell width="1">Status</Table.HeaderCell>


                            <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                            <Table.HeaderCell width="2">Cidade</Table.HeaderCell>
                            <Table.HeaderCell width="1">Entregou</Table.HeaderCell>
                            <Table.HeaderCell width="2">Frete</Table.HeaderCell>
                            <Table.HeaderCell width="1">Frete</Table.HeaderCell>
                            <Table.HeaderCell width="1">Ligar?</Table.HeaderCell>


                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {dataList.map(data => (
                            (
                                total += data.valornota
                                , afaturar += (data.nota == null ? data.valornota : 0)
                                , faturado += (data.nota != null ? data.valornota : 0)
                            ),
                            <Table.Row key={"NF" + data.fbits}>
                                <Table.Cell>{data.fbits}</Table.Cell>
                                <Table.Cell>{moment(data.datapedido).format('DD/MM/YYYY')}</Table.Cell>

                                <Table.Cell>{data.nota}</Table.Cell>
                                <Table.Cell>{(data.datanota == null ? '' : moment(data.datanota).format('DD/MM/YYYY'))}</Table.Cell>
                                <Table.Cell>{data.statusnota}</Table.Cell>

                                <Table.Cell>{data.parceiro}</Table.Cell>
                                <Table.Cell>{(data.cidade != 'Retira' ? data.cidade : '')}<Header color={(data.cidade == 'Retira' ? 'teal' : '')} size='tiny'>{(data.cidade == 'Retira' ? '(Retira)' : '')}</Header></Table.Cell>
                                <Table.Cell>
                                    <If test={data.cidade == 'Retira'}>
                                        <Button basic icon='write' color='blue' size="mini"
                                            onClick={(e) => this.onEntrega(e, data)}
                                            loading={data.isentregasaving}
                                        />
                                    </If>
                                </Table.Cell>
                                <Table.Cell>
                                    {(data.isfretecontextediting) ?
                                        (<Input placeholder='Frete...' value={data.fretecontext}
                                            onChange={(e) => this.onEditFreteTexto(e, data)}
                                        />) :
                                        (<div>{data.fretecontext}</div>)
                                    }


                                </Table.Cell>
                                <Table.Cell>
                                    {(data.isfretecontextediting) ?
                                        (<div><Button key={`save_${data.pedidofbits}${data.pedidofbits}`} basic icon={'write'} color={'green'} size="mini"
                                            onClick={(e) => this.onSaveFrete(e, data)}
                                            loading={data.isfretecontextsaving}
                                        />

                                            <Button key={`cancel_${data.pedidofbits}${data.pedidofbits}`} basic icon={'cancel'} color={'red'} size="mini"
                                                onClick={(e) => this.onCancelFrete(e, data)}
                                            />

                                        </div>) : (

                                            <Button key={`edit_${data.pedidofbits}${data.pedidofbits}`} basic icon={'edit'} color={'blue'} size="mini"
                                                onClick={(e) => this.onEditFrete(e, data)}
                                            />

                                        )
                                    }

                                </Table.Cell>

                                <Table.Cell>
                                    <Button
                                        basic icon={'tty'} color={trocarCorLigar(data.ligar)} size="mini"
                                        onClick={() => {
                                            if (!data.isloading_ligar) {
                                                //
                                                if (data.ligar == 'N' || data.ligar == null) {
                                                    this.props.ordemCargaEmAndamentoMODALLigarAction(data.nunota, true)

                                                } else {

                                                    this.props.ordemCargaEmAndamentoLigarAction(data.nunota, data.ligar, data.ligarcomplemento)

                                                }
                                            }
                                        }
                                        }
                                        loading={data.isloading_ligar}
                                        disabled={(data.statusnota == 'A' ? false : true)}

                                    />

                                    <Modal
                                        size={'mini'}
                                        open={data.modal_ligar}
                                    >
                                        <Modal.Header>Ligar 1h antes para o cliente?</Modal.Header>
                                        <Modal.Content>
                                            <p>Digite abaixo se tem mais alguma observação</p>
                                            <Form.TextArea

                                                placeholder='Escreva a observação'
                                                labe
                                                value={data.ligarcomplemento}
                                                onChange={(e) => {
                                                    this.props.ordemCargaEmAndamentoCOMPLEMENTOLigarAction(data.nunota, e.target.value)
                                                }}
                                                name="ligarcomplemento"
                                                maxLength={50}
                                                rows={5}
                                                style={{ width: '100%' }}


                                            />

                                        </Modal.Content>
                                        <Modal.Actions>
                                            <Button negative
                                                onClick={() => this.props.ordemCargaEmAndamentoMODALLigarAction(data.nunota, false)}
                                            >
                                                Cancelar
                                            </Button>
                                            <Button positive
                                                loading={data.isloading_ligar}
                                                onClick={() => {
                                                    if (!data.isloading_ligar) {
                                                        this.props.ordemCargaEmAndamentoLigarAction(data.nunota, data.ligar, data.ligarcomplemento).then(d => {
                                                            this.props.ordemCargaEmAndamentoMODALLigarAction(data.nunota, false);
                                                        })
                                                    }
                                                }}
                                            >
                                                Confirmar
                                            </Button>
                                        </Modal.Actions>
                                    </Modal>
                                </Table.Cell>

                            </Table.Row>
                        ))}

                    </StyledTableBody>

                    <Table.Footer>
                        <Table.Row>
                            <Table.HeaderCell colSpan='8'>
                                <Header as='h4'>A Faturar:{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(afaturar)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Faturado:{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(faturado)}&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Total:{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}</Header>

                            </Table.HeaderCell>


                        </Table.Row>
                    </Table.Footer>
                </StyledTable>

            </Tab.Pane>

        )
    }
}

ListEmAndamento.defaultProps = {
    dataList: []
    , loader: true
    , login: ''
};

ListEmAndamento.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , ordemCargaEmAndamentoEntregueAction: PropTypes.func.isRequired
    , login: PropTypes.string.isRequired
    , ordemCargaUpdateNaoPagosFaturadosFreteAction: PropTypes.func.isRequired
    , ordemCargaUpdateNaoPagosFaturadosFreteTextoAction: PropTypes.func.isRequired
    , ordemCargaSaveNaoPagosFaturadosFrete: PropTypes.func.isRequired
    , ordemCargaEmAndamentoLigarAction: PropTypes.func.isRequired

    , ordemCargaEmAndamentoCOMPLEMENTOLigarAction: PropTypes.func.isRequired
    , ordemCargaEmAndamentoMODALLigarAction: PropTypes.func.isRequired
};



export default ListEmAndamento
