import {
    PERMISSOES_LIST_USUARIOS
    , PERMISSOES_EDITING_USUARIOS
    , PERMISSOES_CHANGE_USUARIO_SAVING
    , PERMISSOES_CHANGE_USUARIOS
    , DESPESASDEVIAGENS_EDITING_CHANGE_USUARIOS_LOGIN
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    usuarios: []
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case PERMISSOES_LIST_USUARIOS:
            return { ...state, usuarios: action.payload }

        case PERMISSOES_CHANGE_USUARIOS:
            return {
                ...state,
                usuarios: state.usuarios.map(
                    (item) => item.usupedfbitsid === action.usupedfbitsid ?
                        { ...item, [action.field]: action.payload }
                        : item
                )
            }


        case PERMISSOES_EDITING_USUARIOS:
            return {
                ...state,
                usuarios: state.usuarios.map(
                    (usuarios) => usuarios.usupedfbitsid === action.payload ?
                        { ...usuarios, isediting: action.isediting }
                        : usuarios
                )
            }

        case PERMISSOES_CHANGE_USUARIO_SAVING:
            return {
                ...state,
                usuarios: state.usuarios.map(
                    (usuarios, i) => usuarios.usupedfbitsid === action.payload ?
                        { ...usuarios, issaving: action.issaving }
                        : usuarios
                )
            }


        default:
            return state;
    }
}