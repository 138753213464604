import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br'


import {
    Table, Button, Modal, Form, Segment, Message, Image, Header

} from 'semantic-ui-react'

import If from '../../utils/If';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styled from 'styled-components';




const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




class ConsultaRJ extends Component {


    state = { errordtinicial: 0, errordtfinal: 0 }

    constructor(props) {
        super(props);
    }


    componentWillReceiveProps() {




    }

    componentDidMount() {

        const datePickerInicial = document.getElementsByClassName("react-datepicker__input-container")[0];
        datePickerInicial.childNodes[0].setAttribute("readOnly", true);

        const datePickerFinal = document.getElementsByClassName("react-datepicker__input-container")[1];
        datePickerFinal.childNodes[0].setAttribute("readOnly", true);

    }

    handleChangeDateInicial = (value) => {

        let valor = null

        if (value != null) {
            valor = new Date(value)
        }

        this.props.despesasDeViagensUpdateBuscaAction({ target: { 'name': 'dtinicial', 'value': valor } })


    }

    handleChangeDateFinal = (value) => {
        let valor = null

        if (value != null) {
            valor = new Date(value)
        }

        this.props.despesasDeViagensUpdateBuscaAction({ target: { 'name': 'dtfinal', 'value': valor } })

    }

    handleMotorista = (e, { value }) => {
        this.props.despesasDeViagensUpdateBuscaAction({ target: { 'name': 'codparcmotorista', 'value': parseInt(value, 10) } })
    }




    handleBusca = () => {
        this.setState({ errordtinicial: 0 })
        this.setState({ errordtfinal: 0 })


        this.props.despesasDeViagensRemoveValidacao().then(result => {

            if (this.props.entidade.dtinicial === null) {
                this.props.despesasDeViagensAddValidacao(1, "Data Inicial", "É necessário preencher a Data Inicial")
                this.setState({ errordtinicial: 1 })
            }

            if (this.props.entidade.dtfinal === null) {
                this.props.despesasDeViagensAddValidacao(2, "Data Final", "É necessário preencher a Data Final")
                this.setState({ errordtfinal: 1 })
            }

            if (this.props.entidade.dtinicial != null && this.props.entidade.dtfinal != null) {
                if (this.props.entidade.dtinicial > this.props.entidade.dtfinal) {
                    this.props.despesasDeViagensAddValidacao(3, "Data Inicial", "A Data Inicial não pode ser maior que a Data Final")
                    this.setState({ errordtinicial: 1 })

                }
            }

            if (this.props.validacao.length === 0) {
                
                this.props.despesasDeViagensListSearchAction(this.props.RJBuscaEntity).then(dados => {

                })

            }

        })





    }





    render() {

        let { motoristas, datalist, cidades } = this.props

        let total = 0


        return (
            <div>
                <Segment secondary>

                    <Form loading={this.props.isSearching}>
                        <Form.Group widths='equal'>




                        </Form.Group>
                        <Form.Group widths='eleven'>

                            <Form.Select name="codparcmotorista" options={motoristas}
                                //defaultValue={this.props.RJBuscaEntity.codconsultor.toString()} 
                                label='Motorista'
                                onChange={this.handleMotorista.bind(this)}
                                search
                            />

                            <div className={this.state.errordtinicial === 0 ? "field" : "error field"}><label>Data Inicial</label>
                                <div>
                                    <DatePicker
                                        selected={this.props.entidade.dtinicial}
                                        onChange={this.handleChangeDateInicial.bind(this)}
                                        //onSelect={this.handleChangeDateInicial.bind(this)}
                                        isClearable={true}
                                        todayButton={"Hoje"}
                                        name="dtinicial"
                                        id="dtinicial"
                                        dateFormat="dd/MM/yyyy"
                                        required={true}

                                    />

                                </div>
                            </div>



                            <div className={this.state.errordtfinal === 0 ? "field" : "error field"}><label>Data Final</label>
                                <div>
                                    <DatePicker
                                        selected={this.props.entidade.dtfinal}
                                        onChange={this.handleChangeDateFinal.bind(this)}
                                        //onSelect={this.handleChangeDateFinal.bind(this)}
                                        isClearable={true}
                                        todayButton={"Hoje"}
                                        name="dtfinal"
                                        id="dtfinal"
                                        dateFormat="dd/MM/yyyy"
                                        required={true}

                                    />

                                </div>
                            </div>



                            <div className="field"><label>&nbsp;</label>

                                <Form.Button primary
                                    //loading={isSaving}
                                    onClick={this.handleBusca}
                                >Buscar</Form.Button>
                            </div>



                        </Form.Group>
                        {
                            (this.props.validacao.length > 0 ? (
                                <Message negative>
                                    {this.props.validacao.map(valida => (
                                        <p key={valida.id}>{valida.mensagem}</p>
                                    ))}

                                </Message>
                            ) : (
                                    <div></div>
                                ))
                        }


                    </Form>
                </Segment>
                <If test={datalist.length > 0}>
                    {`Encontramos ${datalist.length} registro(s)`}
                </If>

                <If test={datalist.length > 0}>
                    <StyledTable id="listFechamento" compact celled >
                        <StyledTableHeader>
                            <Table.Row>

                                <Table.HeaderCell>Ordem de Carga</Table.HeaderCell>
                                <Table.HeaderCell>Placa</Table.HeaderCell>
                                <Table.HeaderCell>Saída</Table.HeaderCell>
                                <Table.HeaderCell>Chegada</Table.HeaderCell>

                                <Table.HeaderCell>Motorista</Table.HeaderCell>
                                <Table.HeaderCell>Ajudante</Table.HeaderCell>
                                <Table.HeaderCell>2º Ajudante</Table.HeaderCell>

                                <Table.HeaderCell>Valor</Table.HeaderCell>


                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>

                            {datalist.map(item => (
                                (
                                    total += item.valor
                                ),
                                <Table.Row key={"search_" + item.ordemcarga}>

                                    <Table.Cell>{item.ordemcarga}</Table.Cell>
                                    <Table.Cell>{item.placa}</Table.Cell>
                                    <Table.Cell>{moment(item.dtsaida).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                                    <Table.Cell>{moment(item.dtchegada).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                                    <Table.Cell>{item.motorista}</Table.Cell>
                                    <Table.Cell>{item.ajudante}</Table.Cell>
                                    <Table.Cell>{item.ajudanteduplo}</Table.Cell>
                                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}</Table.Cell>

                                </Table.Row>
                            ))}



                        </StyledTableBody>

                        <Table.Footer>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell />


                                <Table.HeaderCell>
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}
                                </Table.HeaderCell>


                            </Table.Row>
                        </Table.Footer>
                    </StyledTable>
                </If>
            </div>

        )
    }

}

ConsultaRJ.defaultProps = {
    datalist: []
    , entidade: {}
    , motoristas: []
};

ConsultaRJ.propTypes = {
    datalist: PropTypes.array.isRequired
    , despesasDeViagensUpdateBuscaAction: PropTypes.func.isRequired
    , entidade: PropTypes.object.isRequired

    , despesasDeViagensAddValidacao: PropTypes.func.isRequired
    , despesasDeViagensRemoveValidacao: PropTypes.func.isRequired
    , validacao: PropTypes.array.isRequired
    , isSearching: PropTypes.bool.isRequired
    , motoristas: PropTypes.array.isRequired
    , despesasDeViagensListSearchAction: PropTypes.func.isRequired
    , RJBuscaEntity: PropTypes.object.isRequired


};

export default ConsultaRJ

