
import {
    DESPESASDEVIAGENS_LIST_SAIDAS
    , DESPESASDEVIAGENS_LIST_AJUDANTE
    , DESPESASDEVIAGENS_LIST_MOTORISTAS
    , DESPESASDEVIAGENS_LIST_AJUDANTES
    , DESPESASDEVIAGENS_LOADER_SAIDAS

    , DESPESASDEVIAGENS_CHANGE_SAIDAS_MOTORISTA
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEUM
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEDOIS
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_DTSAIDA
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_ALTEROU
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_REMOVE
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING

    , DESPESASDEVIAGENS_LIST_CHEGADAS
    , DESPESASDEVIAGENS_LOADER_CHEGADAS
    , DESPESASDEVIAGENS_CHANGE_CHEGADAS_DTCHEGADA
    , DESPESASDEVIAGENS_LIST_VIAGEMDESPESAS
    , DESPESASDEVIAGENS_CHANGE_CHEGADAS
    , DESPESASDEVIAGENS_INSERT_CHEGADAS
    , DESPESASDEVIAGENS_ADICIONAL_INSERT_CHEGADAS
    , DESPESASDEVIAGENS_CHANGE_CHEGADAS_SAVING
    , DESPESASDEVIAGENS_CHANGE_CHEGADAS_REMOVE
    , DESPESASDEVIAGENS_LIST_FERIADOS

    , DESPESASDEVIAGENS_LIST_RECIBOS
    , DESPESASDEVIAGENS_LOADER_RECIBOS
    , DESPESASDEVIAGENS_CHANGEDT_RECIBOS

    , DESPESASDEVIAGENS_LIST_ASSINATURAS
    , DESPESASDEVIAGENS_LOADER_ASSINATURAS
    , DESPESASDEVIAGENS_LOADER_ASSINATURAS_FILTER
    , DESPESASDEVIAGENS_CHANGEDT_ASSINATURAS
    , DESPESASDEVIAGENS_LIST_ASSINATURAS_RH

    , DESPESASDEVIAGENS_LIST_FINANCEIROAPROCESSAR
    , DESPESASDEVIAGENS_LOADER_FINANCEIROAPROCESSAR


    , DESPESASDEVIAGENS_LIST_FINANCEIROGERADO
    , DESPESASDEVIAGENS_LOADER_FINANCEIROGERADO
    , DESPESASDEVIAGENS_LIST_FINANCEIROGERADO_USERSFIN
    , DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING
    , DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO
    , DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO_OC

    , DESPESASDEVIAGENS_CHANGE_ISCANCELING
    , DESPESASDEVIAGENS_REMOVE_ORDEMCARGA

    , DESPESASDEVIAGENS_UPDATE_BUSCA
    , DESPESASDEVIAGENS_VALIDACAO_BUSCA_ADD
    , DESPESASDEVIAGENS_VALIDACAO_BUSCA_REMOVE
    , DESPESASDEVIAGENS_ISSEARCHING_BUSCA
    , DESPESASDEVIAGENS_LIST_SEARCH
    , DESPESASDEVIAGENS_SAVING_AJUDANTE
    , DESPESASDEVIAGENS_EDITING_AJUDANTE
    , DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_NOME
    , DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_ATIVO
    , DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING

    , DESPESASDEVIAGENS_LIST_MESMODIA
    , DESPESASDEVIAGENS_ISREMOVING_MESMODIA
    , DESPESASDEVIAGENS_REMOVE_MESMODIA
    , DESPESASDEVIAGENS_LIST_ADICIONAL

} from '../actions/ActionsTypes';



import * as Api from '../utils/API';
import sortBy from 'sort-by';

import { START_TIMER } from 'redux-timer';
import { STOP_TIMER } from 'redux-timer';


export const despesasDeViagensCancelAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: DESPESASDEVIAGENS_CHANGE_ISCANCELING, payload: true })

        Api.postDespesasDeViagensCancel(entidade).then(dados => {
            if (dados[0].status === "OK") {
                dispatch({ type: DESPESASDEVIAGENS_REMOVE_ORDEMCARGA, payload: entidade.idviagem })
                dispatch(despesasDeViagensListSaidasAction())
            }
            dispatch({ type: DESPESASDEVIAGENS_CHANGE_ISCANCELING, payload: false })
            resolve([])
        })
    }
    )


export const despesasDeViagensTimerFinanceiroGeradoAction = () => async dispatch => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'despesasDeViagensFinanceiroGerado',
            action: async () => {
                try {
                    //console.log('tick graph!')
                    dispatch(despesasDeViagensListFinanceiroGeradoAction())
                } catch (e) {
                    // do something with this error
                }
            },
            interval: 300000,
            runImmediately: false
        }
    });
};

export const despesasDeViagensTimerFinanceiroAProcessarAction = () => async dispatch => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'despesasDeViagensFinanceiroAProcessar',
            action: async () => {
                try {
                    //console.log('tick graph!')
                    dispatch(despesasDeViagensListFinanceiroAProcessarAction())
                } catch (e) {
                    // do something with this error
                }
            },
            interval: 300000,
            runImmediately: false
        }
    });
};


export const despesasDeViagensListFinanceiroGeradoAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllDespesasDeViagensFinanceiroGerado().then(dados => {
            let gerados = dados[0].gerados;

            // Criando campos para gerenciamento de estado na tela
            gerados.forEach(s => {
                s.issaving = false;
            });

            // Criar um mapa para armazenar os dados agrupados por motorista
            const groupedData = {};
            gerados.forEach(item => {


                if (!groupedData[item.motorista]) {
                    groupedData[item.motorista] = {
                        motorista: item.motorista,
                        veiculo: item.veiculo,
                        placa: item.placa,
                        dtprocfinanceiro: item.dtprocfinanceiro,
                        issaving: item.issaving,
                        ordensDeCarga: [],
                        valoresdob: 0, // Adicionando a propriedade valoresdob para armazenar a soma dos valores das ordens de carga
                        nufinArray: []
                    };
                }
                groupedData[item.motorista].ordensDeCarga.push({
                    ordemcarga: item.ordemcarga,
                    natureza: item.natureza,
                    nufin: item.nufin,
                    valorespopup: item.vlrdesdob,
                    codnat: item.codnat,
                    //  valorTotalOrdemCarga: item.ordemcarga.find(oc => oc.ordemcarga === item.ordemcarga)?.valorespopup // Adicionando o valor total da ordem de carga
                });
                // Adicionando o valor da ordem de carga à soma total da mesma ordem de carga
                groupedData[item.motorista].valoresdob += item.vlrdesdob; // Adicionando o valor da ordem de carga à soma total
                groupedData[item.motorista].nufinArray.push(item.nufin); // Adicionando a nufin ao novo array

            });

            const arrayPrincipal = Object.values(groupedData);

            arrayPrincipal.map(item => {
                let ocnum = '';
                let ocsoma = 0;

                item.ordensDeCarga.map(oc => {
                    if (ocnum !== oc.ordemcarga) {
                        ocnum = oc.ordemcarga;
                        ocsoma += oc.valorespopup;
                    } else {
                        ocnum = oc.ordemcarga;
                    }
                });

                // Adiciona a soma das ordens de carga como uma nova propriedade ao objeto
                item.ordensDeCarga.somaOrdensDeCarga = ocsoma;
            });

            const groupedDataArray = Object.values(groupedData);
            groupedDataArray.forEach(item => {
                const ordensDeCargaArray = []
                const ordensDeCarga = {}; // Objeto para armazenar as somas das ordens de carga
                item.ordensDeCarga.forEach(oc => {
                    if (!ordensDeCarga[oc.ordemcarga]) {
                        ordensDeCarga[oc.ordemcarga] = 0;
                    }
                    ordensDeCarga[oc.ordemcarga] += oc.valorespopup;
                });
                ordensDeCargaArray.push(ordensDeCarga)
                item.totalPorOrdemDeCarga = ordensDeCargaArray;

            });
            const groupedDataArrayComTotal = groupedDataArray.map(item => {
                let totalOrdensDeCarga = 0;
                item.ordensDeCarga.forEach(oc => {
                    totalOrdensDeCarga += oc.valorespopup;
                });
                return {
                    ...item,
                    totalOrdensDeCarga: totalOrdensDeCarga
                };
            });

            dispatch({ type: DESPESASDEVIAGENS_LIST_FINANCEIROGERADO_USERSFIN, payload: dados[0].usersfin.toString().split(",") });
            dispatch({ type: DESPESASDEVIAGENS_LIST_FINANCEIROGERADO, payload: groupedDataArrayComTotal });
            dispatch({ type: DESPESASDEVIAGENS_LOADER_FINANCEIROGERADO, payload: false });
            resolve([]);
        });
    });





export const despesasDeViagensSaveFinanceiroGeradoAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING, motorista: entidade.motorista, issaving: true })


        Api.postDespesasDeViagensFinanceiroGeradoOCSave(entidade).then(dados => {
            if (dados[0].status === "OK") {

                dispatch({ type: DESPESASDEVIAGENS_REMOVE_FINANCEIROGERADO_OC, motorista: entidade.motorista })


            } else {
                dispatch({ type: DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING, nufin: entidade.nufin, issaving: false })
            }

            resolve(dados)
        }).catch(error => {
            dispatch({ type: DESPESASDEVIAGENS_CHANGE_FINANCEIROGERADO_SAVING, nufin: entidade.nufin, issaving: false })
            reject(error);
        })


    });





export const despesasDeViagensListFinanceiroAProcessarAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllDespesasDeViagensFinanceiroAProcessar().then(dados => {
            dispatch({ type: DESPESASDEVIAGENS_LIST_FINANCEIROAPROCESSAR, payload: dados })
            dispatch({ type: DESPESASDEVIAGENS_LOADER_FINANCEIROAPROCESSAR, payload: false })
            resolve([])
        })
    })



export const despesasDeViagensChageDTAssinaturasAction = (ordemcarga, userid, entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        let objeto = {}
        objeto.entidade = []
        objeto.entidade = entidade
        objeto.ordemcarga = ordemcarga
        objeto.userid = userid

        Api.postDespesasDeViagensAssinaturas(objeto).then(dados => {
            if (dados[0].status === "OK") {
                dispatch({ type: DESPESASDEVIAGENS_CHANGEDT_ASSINATURAS, ordemcarga: ordemcarga, tipodado: objeto.entidade[0].tipodado, data: objeto.entidade[0].dtviagemfinanceiro })
                dispatch(despesasDeViagensListRecibosAction());
                dispatch(despesasDeViagensListFinanceiroAProcessarAction());
            }
            resolve([])
        })

    }
    )

export const despesasDeViagensListAssinaturasAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllDespesasDeViagensAssinaturas().then(data => {

            dispatch({ type: DESPESASDEVIAGENS_LIST_ASSINATURAS_RH, payload: data[0].usersrh.toString().split(",") })

            let dados = []
            let todosdias = []

            let cargas = []

            for (let i of data[0].assinaturas) {
                todosdias = []

                for (let f of i.despesas) {
                    if (f.codparcmotorista != 0) {
                        f.tipodado = 'motorista'
                    } else if (f.codparcajudante != 0) {
                        f.tipodado = 'ajudante'
                    } else if (f.codparcaajudanteduplo != 0) {
                        f.tipodado = 'ajudanteduplo'
                    }
                    todosdias.push({ dia: f.dtviagemfinanceiro, despesas: [] })
                }

                todosdias = [...new Set(todosdias.map(o => JSON.stringify(o)))]
                todosdias = todosdias.map(o => JSON.parse(o))

                let arrmotoristadespesas = []
                let arrajudantedespesas = []
                let arrajudanteduplodespesas = []

                for (let d of todosdias) {
                    arrmotoristadespesas.push({ dia: d.dia, despesas: [] })
                    arrajudantedespesas.push({ dia: d.dia, despesas: [] })
                    arrajudanteduplodespesas.push({ dia: d.dia, despesas: [] })
                }

                let objeto = {
                    ordemcarga: i.ordemcarga
                    , motorista: i.motorista
                    , ajudante: i.ajudante
                    , ajudanteduplo: i.ajudanteduplo
                    , motoristadespesas: arrmotoristadespesas
                    , ajudantedespesas: arrajudantedespesas
                    , ajudanteduplodespesas: arrajudanteduplodespesas
                }

                for (let mo of objeto.motoristadespesas) {
                    let arrdesp = [...i.despesas.filter(t => t.tipodado === 'motorista' && t.dtviagemfinanceiro === mo.dia)]
                    if (arrdesp.length > 0) {
                        mo.despesas.push(arrdesp)
                    }
                }

                for (let aj of objeto.ajudantedespesas) {
                    let arrdesp = [...i.despesas.filter(t => t.tipodado === 'ajudante' && t.dtviagemfinanceiro === aj.dia)]
                    if (arrdesp.length > 0) {
                        aj.despesas.push(arrdesp)
                    }
                }

                for (let ajd of objeto.ajudanteduplodespesas) {
                    let arrdesp = [...i.despesas.filter(t => t.tipodado === 'ajudanteduplo' && t.dtviagemfinanceiro === ajd.dia)]
                    if (arrdesp.length > 0) {
                        ajd.despesas.push(arrdesp)
                    }
                }



                cargas.push(objeto)
                dados.push(i)

            }

            dispatch({ type: DESPESASDEVIAGENS_LIST_ASSINATURAS, payload: cargas })
            dispatch({ type: DESPESASDEVIAGENS_LOADER_ASSINATURAS, payload: false })
            resolve([])
        })
    }
    )



export const despesasDeViagensListAssinaturasFilterAction = (dados) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_LOADER_ASSINATURAS_FILTER, payload: true })
        Api.PostAllDespesasDeViagensAssinaturasFilter(dados).then(data => {

            console.log(data)


            if (data.length > 0) {


                let dados = []
                let todosdias = []

                let cargas = []
                todosdias = []

                for (let f of data[0].despesas) {
                    if (f.codparcmotorista != 0) {
                        f.tipodado = 'motorista'
                    } else if (f.codparcajudante != 0) {
                        f.tipodado = 'ajudante'
                    } else if (f.codparcaajudanteduplo != 0) {
                        f.tipodado = 'ajudanteduplo'
                    }
                    todosdias.push({ dia: f.dtviagemfinanceiro, despesas: [] })
                }

                todosdias = [...new Set(todosdias.map(o => JSON.stringify(o)))]
                todosdias = todosdias.map(o => JSON.parse(o))

                let arrmotoristadespesas = []
                let arrajudantedespesas = []
                let arrajudanteduplodespesas = []

                for (let d of todosdias) {
                    arrmotoristadespesas.push({ dia: d.dia, despesas: [] })
                    arrajudantedespesas.push({ dia: d.dia, despesas: [] })
                    arrajudanteduplodespesas.push({ dia: d.dia, despesas: [] })
                }

                let objeto = {
                    ordemcarga: data[0].ordemcarga
                    , motorista: data[0].motorista
                    , ajudante: data[0].ajudante
                    , ajudanteduplo: data[0].ajudanteduplo
                    , motoristadespesas: arrmotoristadespesas
                    , ajudantedespesas: arrajudantedespesas
                    , ajudanteduplodespesas: arrajudanteduplodespesas
                }

                for (let mo of objeto.motoristadespesas) {
                    let arrdesp = [...data[0].despesas.filter(t => t.tipodado === 'motorista' && t.dtviagemfinanceiro === mo.dia)]
                    if (arrdesp.length > 0) {
                        mo.despesas.push(arrdesp)
                    }
                }

                for (let aj of objeto.ajudantedespesas) {
                    let arrdesp = [...data[0].despesas.filter(t => t.tipodado === 'ajudante' && t.dtviagemfinanceiro === aj.dia)]
                    if (arrdesp.length > 0) {
                        aj.despesas.push(arrdesp)
                    }
                }

                for (let ajd of objeto.ajudanteduplodespesas) {
                    let arrdesp = [...data[0].despesas.filter(t => t.tipodado === 'ajudanteduplo' && t.dtviagemfinanceiro === ajd.dia)]
                    if (arrdesp.length > 0) {
                        ajd.despesas.push(arrdesp)
                    }
                }



                cargas.push(objeto)



                dispatch({ type: DESPESASDEVIAGENS_LIST_ASSINATURAS, payload: cargas })
                dispatch({ type: DESPESASDEVIAGENS_LOADER_ASSINATURAS_FILTER, payload: false })
                resolve([])
            } else {
                dispatch({ type: DESPESASDEVIAGENS_LIST_ASSINATURAS, payload: [] })
                dispatch({ type: DESPESASDEVIAGENS_LOADER_ASSINATURAS_FILTER, payload: false })
                resolve([])
            }

        })
    }
    )



export const despesasDeViagensChageDTRecibosAction = (ordens, userid) => (dispatch) =>
    new Promise(function (resolve, reject) {


        let entidade = []
        let dtimpressao = new Date()

        for (let i of ordens) {
            entidade.push({ ordemcarga: i.ordemcarga, data: dtimpressao, userid: userid })
        }

        Api.postDespesasDeViagensRecibos(entidade).then(dados => {
            //if(dados)michel
            if (dados[0].status === "OK") {
                //percorrendo cada ordem de carga para atualizar a data
                for (let item of entidade) {
                    dispatch({ type: DESPESASDEVIAGENS_CHANGEDT_RECIBOS, ordemcarga: item.ordemcarga, payload: item.data })
                }
                dispatch(despesasDeViagensListAssinaturasAction())
            }

            resolve([])
        })
    }
    )


export const despesasDeViagensListRecibosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllDespesasDeViagensRecibos().then(dados => {
            dispatch({ type: DESPESASDEVIAGENS_LIST_RECIBOS, payload: dados })
            dispatch({ type: DESPESASDEVIAGENS_LOADER_RECIBOS, payload: false })
            resolve([])
        })
    }
    )






export const despesasDeViagensListFeriadosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllDespesasDeViagensFeriados().then(dados => {

            let todosferiados = []

            /*
            //atualizando ano atual dos feriados recorrentes
            dados.filter(d => d.recorrente === 'S').map(s => {

             

               
                //s.dtferiado = new Date(new Date(s.dtferiado).setFullYear(new Date().getFullYear()))
                s.dtferiado = new Date(new Date().getFullYear(), new Date(s.dtferiado).getMonth(), new Date(s.dtferiado).getDate(), 0, 0, 0);
                let existe = todosferiados.filter(f=>f.descricao===s.descricao).length
                if(existe===0){
                    todosferiados.push(s)
                }
            })*/

            /*
                        let copiaFeriados = []
                        for (let i of todosferiados.filter(d => d.recorrente === 'S')) {
                            copiaFeriados.push(
                                {
                                    dtferiado: new Date(new Date(i.dtferiado).setFullYear(new Date().getFullYear() + 1))
                                    , descricao: i.descricao
                                    , recorrente: i.recorrente
                                }
                            )
                        }
            
            */


            /*
                        //criando feriados para o próximo ano pois na virada do ano pode dar problema com o dia primeiro
                        copiaFeriados.map(s => (
                            s.dtferiado = new Date(new Date(s.dtferiado).setFullYear(new Date().getFullYear() + 1))
                        ))
            */




            /*let dataTeste = new Date()
            dataTeste.setDate(28)
            dataTeste.setMonth(10)
            dataTeste.setHours(0);
            dataTeste.setMinutes(0);
            dataTeste.setSeconds(0);
            dataTeste.setMilliseconds(0);
            dados.push({dtferiado:dataTeste, descricao:'Teste', recorrente:'N'})
            */

            //
            //[...todosferiados, ...copiaFeriados]

            dispatch({ type: DESPESASDEVIAGENS_LIST_FERIADOS, payload: dados })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });


export const despesasDeViagensSaidasChangeMotoristaAction = (ordemcarga, codmotorista) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_MOTORISTA, ordemcarga: ordemcarga, codmotorista: codmotorista })
    });

export const despesasDeViagensSaidasChangeAjudanteUmAction = (ordemcarga, codajudante) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEUM, ordemcarga: ordemcarga, codajudante: codajudante })
    });

export const despesasDeViagensSaidasChangeAjudanteDoisAction = (ordemcarga, codajudante) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEDOIS, ordemcarga: ordemcarga, codajudante: codajudante })
    });

export const despesasDeViagensSaidasChangeDtSaidaAction = (ordemcarga, dtsaida) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_DTSAIDA, ordemcarga: ordemcarga, payload: dtsaida })
    });

export const despesasDeViagensSaidasCancelAction = (ordemcarga) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_ALTEROU, ordemcarga: ordemcarga })
    });


export const despesasDeViagensSaidasSaveAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        if (entidade.ajudanteum === "") {
            entidade.ajudanteum = 0
        }
        if (entidade.ajudantedois === "") {
            entidade.ajudantedois = 0
        }


        dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING, ordemcarga: entidade.ordemcarga, issaving: true })

        Api.postDespesasDeViagensSaidasSave(entidade).then(dados => {
            if (dados[0].status === "OK") {
                dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_REMOVE, ordemcarga: entidade.ordemcarga })
                //removido, pois nao podia atualizar pois perdia os dados em tela
                //dispatch(despesasDeViagensListSaidasAction())
                Api.getAllDespesasDeViagensChegadas().then(chegadas => {
                    dispatch({ type: DESPESASDEVIAGENS_LIST_CHEGADAS, payload: chegadas.sort(sortBy('motorista')) })
                })

            } else {
                dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING, ordemcarga: entidade.ordemcarga, issaving: false })
            }

            resolve(dados)
        }).catch(error => {
            dispatch({ type: DESPESASDEVIAGENS_CHANGE_SAIDAS_SAVING, ordemcarga: entidade.ordemcarga, issaving: false })
            reject(error);
        })


    });


/*
export const pesquisaSaveAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) { 
        //STP_TI_PESQUISA_SAVE_INTERNO
        Api.postPesquisaSave(entidade).then(dados => {
            //console.log('salvou')
            resolve(dados)
        }).catch(error => {
            //console.log('n salvou')
            reject(error);
        })
    });*/



export const despesasDeViagensListAjudanteAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllListAjudante().then(dados => {
            let ajudante = []
            ajudante = dados;

            ajudante.map(s => (
                s.isediting = false
                , s.issaving = false
            ))
            dispatch({ type: DESPESASDEVIAGENS_LIST_AJUDANTE, payload: ajudante })
            resolve([])
        }).catch(error => {
            reject(error);
        })

    });

export const despesasDeViagensAjudanteEditAction = (idajudante, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log("Entrou no edit", acao, idajudante)
        dispatch({ type: DESPESASDEVIAGENS_EDITING_AJUDANTE, payload: idajudante, isediting: acao });
        resolve([]);
    });

export const despesasDeViagensAjudanteChangeNomeAction = (idviagemajudante, novoValor) => {
    console.log('achangenome:', idviagemajudante, '|novoValor:', novoValor);
    return (dispatch) => {
        dispatch({
            type: DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_NOME,
            payload: idviagemajudante, viagemajudante: novoValor
        });
    };
};

export const despesasDeViagensAjudanteChangeAtivoAction = (idviagemajudante, novoValor) => {
    console.log('changeativo:', idviagemajudante, '|novoValor:', novoValor);
    return (dispatch) => {
        dispatch({
            type: DESPESASDEVIAGENS_EDITING_CHANGE_AJUDANTE_ATIVO,
            payload: idviagemajudante, ativo: novoValor
        });
    };
};

export const despesasDeViagensAjudanteAtualizaAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING, payload: entidade.idviagemajudante, issaving: true });

        // console.log(entidade, 'chegou no action');

        Api.postAjudanteAtualiza(entidade).then((response) => {
            if (response.mensagem === "OK") {
                // console.log("Chegou no if de ok")
                dispatch({ type: DESPESASDEVIAGENS_EDITING_AJUDANTE, payload: entidade.idviagemajudante, isediting: false });
                dispatch({ type: DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING, payload: entidade.idviagemajudante, issaving: false });

            } else {

                dispatch({ type: DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING, payload: entidade.idviagemajudante, issaving: false });
            }
            resolve([]);
        }).catch((error) => {
            console.log(error);
            dispatch({ type: DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING, payload: entidade.idviagemajudante, issaving: false });
            resolve([]);
        });

    });


export const despesasDeViagensAjudanteInsereAction = (entidade, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING, payload: entidade.idviagemajudante, issaving: acao });

        Api.postAjudanteInsere(entidade)
            .then((response) => {
                // console.log(response, 'response then');
                if (response.mensagem === "OK") {
                    console.log("Chegou no if de ok")

                    // Após a criação bem-sucedida, chame a action para atualizar a lista de ajudantes
                    dispatch(despesasDeViagensListAjudanteAction());

                    resolve([]);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve([]);
            });
    });



// export const despesasDeViagensAjudanteInsereAction = (entidade) => (dispatch) =>
//     new Promise(function (resolve, reject) {
//         dispatch({ type: DESPESASDEVIAGENS_CHANGE_AJUDANTE_SAVING, payload: entidade.idviagemajudante, issaving: true });

//         // console.log(entidade, 'chegou no action INSERE');

//         Api.postAjudanteInsere(entidade)

//             .then((response) => {
//                 console.log(response, 'response then');
//                 // console.log(entidade, 'ENTRO NO THEN INSERE');
//                 if (response.mensagem === "OK") {
//                     console.log("Chegou no if de ok")
//                     // dispatch(despesasDeViagensListAjudanteAction());
//                 }
//             })
//             .catch((error) => {
//                 console.log(error);
//             });
//         resolve([]);
//     });




export const despesasDeViagensListSaidasAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllDespesasDeViagensSaidas().then(dados => {
            // alert('Seleccion')
            //saídas------------------------------------------------------

            let saidas = []
            saidas = dados[0].saidas;

            //criando campos para gerenciamento de estado na tela
            saidas.map(s => (
                s.alterou = 0
                , s.ajudanteum = ""
                , s.ajudantedois = ""
                , s.userid = 0
                , s.issaving = false
            ))

            dispatch({ type: DESPESASDEVIAGENS_LIST_SAIDAS, payload: saidas })


            //Motoristas
            let motoristas = []
            if (dados[0].motoristas.length > 0) {
                for (let m of dados[0].motoristas) {
                    motoristas.push({ key: m.codparcmotorista.toString(), text: `${m.motorista} (${m.codparcmotorista})`, value: m.codparcmotorista.toString() })
                }
            }
            dispatch({ type: DESPESASDEVIAGENS_LIST_MOTORISTAS, payload: motoristas })


            //Ajudantes
            let ajudantes = []
            ajudantes.push({ key: "0", text: "Sem ajudante", value: "0" })
            if (dados[0].ajudantes.length > 0) {
                for (let m of dados[0].ajudantes) {
                    ajudantes.push({ key: m.idviagemajudante.toString(), text: m.viagemajudante, value: m.idviagemajudante.toString() })
                }
            }
            dispatch({ type: DESPESASDEVIAGENS_LIST_AJUDANTES, payload: ajudantes })
            dispatch({ type: DESPESASDEVIAGENS_LOADER_SAIDAS, payload: false })
            //-------------------------------------------------------------------------



            //chegadas-----------------------------------------------------------------
            let chegadas = []
            chegadas = dados[0].chegadas;


            //criando campos para gerenciamento de estado na tela
            chegadas.map(c => (
                c.modalOpen = false
                , c.issaving = false
            ))


            dispatch({ type: DESPESASDEVIAGENS_LIST_CHEGADAS, payload: chegadas.sort(sortBy('motorista')) })
            dispatch({ type: DESPESASDEVIAGENS_LOADER_CHEGADAS, payload: false })

            let viagemdespesas = []
            viagemdespesas = dados[0].viagemdespesas;
            dispatch({ type: DESPESASDEVIAGENS_LIST_VIAGEMDESPESAS, payload: viagemdespesas })

            //-------------------------------------------------------------------------


            //mesmodia-----------------------------------------------------------------
            let mesmodia = []
            mesmodia = dados[0].mesmodia;
            for (let i of mesmodia) {
                i.isremoving = false
            }

            dispatch({ type: DESPESASDEVIAGENS_LIST_MESMODIA, payload: mesmodia.sort(sortBy('motorista')) })
            //-------------------------------------------------------------------------




            resolve([])
        }).catch(error => {
            reject(error);
        })

    });

export const despesasDeViagensChegadasHandleChangeAction = (event, ordemcarga) => {
    return {
        type: DESPESASDEVIAGENS_CHANGE_CHEGADAS
        , field: event.target.name
        , payload: event.target.value
        , ordemcarga: ordemcarga
    }
}


export const despesasDeViagensChegadasChangeDtChegadaAction = (ordemcarga, dtchegada) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_CHANGE_CHEGADAS_DTCHEGADA, ordemcarga: ordemcarga, payload: dtchegada })
    });


export const despesasDeViagensChegadasInsertAction = (despesas) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_INSERT_CHEGADAS, payload: despesas })
    });
export const despesasDeViagensChegadasInsertAdicionalAction = (despesas) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // console.log(despesas)
        dispatch({ type: DESPESASDEVIAGENS_ADICIONAL_INSERT_CHEGADAS, payload: despesas })
        resolve([])
    });

export const despesasDeViagensChegadasSaveAction = (entidadechegada, despesas, despesasadicional, userid) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: DESPESASDEVIAGENS_CHANGE_CHEGADAS_SAVING, ordemcarga: entidadechegada.ordemcarga, issaving: true })

        entidadechegada.despesas = []
        if (despesas != undefined) {
            if (despesas.length > 0) {
                entidadechegada.despesas = despesas;
            }
        }
        if (despesasadicional != undefined) {
            if (despesasadicional.length > 0) {
                entidadechegada.despesasAdicional = despesasadicional;
            }
        }
        entidadechegada.userid = userid;

        console.log(entidadechegada)

        Api.postDespesasDeViagensChegadas(entidadechegada).then(dados => {

            if (dados[0].status === "OK") {
                dispatch({ type: DESPESASDEVIAGENS_CHANGE_CHEGADAS_REMOVE, ordemcarga: entidadechegada.ordemcarga })
                dispatch(despesasDeViagensChegadasHandleChangeAction({ target: { 'name': 'modalOpen', 'value': false } }, entidadechegada.ordemcarga))
                dispatch(despesasDeViagensListRecibosAction())
                dispatch(despesasDeViagensListSaidasAction())
            } else {
                dispatch({ type: DESPESASDEVIAGENS_CHANGE_CHEGADAS_SAVING, ordemcarga: entidadechegada.ordemcarga, issaving: false })
            }
            resolve([])
        })

    });










export const despesasDeViagensUpdateBuscaAction = (event) => {
    return {
        type: DESPESASDEVIAGENS_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}



export const despesasDeViagensAddValidacao = (id, campo, mensagem) => {
    return {
        type: DESPESASDEVIAGENS_VALIDACAO_BUSCA_ADD
        , payload: { id: id, item: campo, mensagem: mensagem, status: 'Error' }
    }
}


export const despesasDeViagensRemoveValidacao = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_VALIDACAO_BUSCA_REMOVE, payload: [] })
        resolve([])
    }
    );


export const despesasDeViagensListSearchAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: DESPESASDEVIAGENS_ISSEARCHING_BUSCA, payload: true })

        Api.postDespesasDeViagensSearch(entidade).then(dados => {
            dispatch({ type: DESPESASDEVIAGENS_LIST_SEARCH, payload: dados })
            dispatch({ type: DESPESASDEVIAGENS_ISSEARCHING_BUSCA, payload: false })
            resolve([])
        })
    }
    )


export const despesasDeViagensRemoveMesmoDiaAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        let idviagem = entidade.idviagem

        dispatch({ type: DESPESASDEVIAGENS_ISREMOVING_MESMODIA, payload: idviagem, isremoving: true })

        Api.postDespesasDeViagensMesmoDiaRemove(entidade).then(dados => {
            console.log(dados)
            if (dados[0].status === 'OK') {
                dispatch({ type: DESPESASDEVIAGENS_REMOVE_MESMODIA, payload: idviagem })
                dispatch(despesasDeViagensListSaidasAction())
                resolve(dados)
            } else {
                dispatch({ type: DESPESASDEVIAGENS_ISREMOVING_MESMODIA, payload: idviagem, isremoving: false })
                resolve(dados)
            }

        })
    })


export const despesasDeViagensListAdicionalAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        // dispatch({ type: DESPESASDEVIAGENS_ISSEARCHING_BUSCA, payload: true })

        Api.getAllDespesaAdicional(entidade).then(dados => {
            // console.log(dados)
            dispatch({ type: DESPESASDEVIAGENS_LIST_ADICIONAL, payload: dados })
            // dispatch({ type: DESPESASDEVIAGENS_ISSEARCHING_BUSCA, payload: false })
            resolve([])
        })
    }
    )


export const despesasDeViagensUpdateAdicionalAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.postDespesaAdicional(entidade).then(dados => {
            if (dados[0].status === 'OK') {
                dispatch(despesasDeViagensListAdicionalAction())
                resolve([])
            } else {
                resolve([])
            }

        })
    })









