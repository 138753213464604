import * as Api from '../utils/API';
import {
    MONITORAMENTO_ERROS
} from '../actions/ActionsTypes';

export const monitoramentoErrosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getMonitoramentoErrors().then(dados => {
            // console.log("###################", dados)
            dispatch({ type: MONITORAMENTO_ERROS, payload: dados })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });

