import {
    ORDEMCARGA_LIST
    , ORDEMCARGA_LIST_NAOENTREGUE
    , ORDEMCARGA_QTDE_NAOENTREGUE
    , ORDEMCARGA_CHKNAOENTREGUE_REMOVE
    , ORDEMCARGA_CHKNAOENTREGUE_INSERT
    , ORDEMCARGA_CHKNAOENTREGUE_UPDATE
    , ORDEMCARGA_CHKNAOENTREGUE_CLEAR
    , ORDEMCARGA_LOADER_NAOENTREGUE
    , ORDEMCARGA_LOG_INSERT
    , ORDEMCARGA_PEDIDOMODAL_UPDATE
    , ORDEMCARGA_LIST_MOTIVOS

    , ORDEMCARGA_LIST_ENTREGUE
    , ORDEMCARGA_QTDE_ENTREGUE
    , ORDEMCARGA_LOADER_ENTREGUE

    , ORDEMCARGA_LIST_PEDIDOSNAOPADOS
    , ORDEMCARGA_QTDE_PEDIDOSNAOPADOS
    , ORDEMCARGA_LOADER_PEDIDOSNAOPADOS
    , ORDEMCARGA_LOADER_PEDIDOSNAOPADOS_LIGAR
    , ORDEMCARGA_COMPLEMENTO_PEDIDOSNAOPADOS_LIGAR
    , ORDEMCARGA_MODAL_PEDIDOSNAOPADOS_LIGAR


    //em andamento
    , ORDEMCARGA_ISSAVING_FRETE_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_UPDATE_FRETE_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_ISEDITING_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_LIST_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_QTDE_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_LOADER_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_EMANDAMENTO_ISENTREGASAVING
    , ORDEMCARGA_EMANDAMENTO_REMOVE
    , ORDEMCARGA_LOADER_PEDIDOSEMANDAMENTO_LIGAR
    , ORDEMCARGA_COMPLEMENTO_PEDIDOSEMANDAMENTO_LIGAR
    , ORDEMCARGA_MODAL_PEDIDOSEMANDAMENTO_LIGAR


    , ORDEMCARGA_LIST_PEDIDOSFATURADOSSEMROTA
    , ORDEMCARGA_QTDE_PEDIDOSFATURADOSSEMROTA
    , ORDEMCARGA_LOADER_PEDIDOSFATURADOSSEMROTA

    , ORDEMCARGA_LIST_EMTRANSPORTADORA
    , ORDEMCARGA_QTDE_EMTRANSPORTADORA
    , ORDEMCARGA_CHKEMTRANSPORTADORA_REMOVE
    , ORDEMCARGA_CHKEMTRANSPORTADORA_INSERT
    , ORDEMCARGA_CHKEMTRANSPORTADORA_UPDATE
    , ORDEMCARGA_CHKEMTRANSPORTADORA_CLEAR
    , ORDEMCARGA_LOADER_EMTRANSPORTADORA
    , ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT
    , ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE

    , ORDEMCARGA_LOADER_ISUPDATING_NAOENTREGUE
    , ORDEMCARGA_REMOVEITEM_NAOENTREGUE
    , ORDEMCARGA_REMOVE_NAOENTREGUE
    , ORDEMCARGA_QTDE_NAOENTREGUE_UPDATE

    , ORDEMCARGA_LIST_DEVOLUCAO
    , ORDEMCARGA_QTDE_DEVOLUCAO_UPDATE
    , ORDEMCARGA_LOADER_DEVOLUCAO

    , ORDEMCARGA_LOADER_ISUPDATING_DEVOLUCAO
    , ORDEMCARGA_REMOVEITEM_DEVOLUCAO
    , ORDEMCARGA_REMOVE_DEVOLUCAO
    , ORDEMCARGA_QTDE_DEV_UPDATE

    , ORDEMCARGA_LOADER_ISUPDATING_DEVTRANSP
    , ORDEMCARGA_REMOVE_DEVTRANSP
    , ORDEMCARGA_REMOVEITEM_DEVTRANSP
    , ORDEMCARGA_QTDE_DEVTRANSP_UPDATE

    , ORDEMCARGA_LOADER_ISUPDATING_DEVENTREGUE
    , ORDEMCARGA_REMOVE_DEVENTREGUE
    , ORDEMCARGA_REMOVEITEM_DEVENTREGUE
    , ORDEMCARGA_QTDE_DEVENTREGUE_UPDATE
    , ORDEMCARGA_PEDIDOMODALDEV_UPDATE

    , ORDEMCARGA_LIST_ASAIR
    , ORDEMCARGA_QTDE_ASAIR
    , ORDEMCARGA_LOADER_ASAIR
    , ORDEMCARGA_ASAIR_CHKEMROTA_INSERT
    , ORDEMCARGA_ASAIR_CHKEMROTA_REMOVE
    , ORDEMCARGA_ASAIR_CHKEMROTA_UPDATE
    , ORDEMCARGA_ASAIR_CHKEMROTA_CLEAR
    , ORDEMCARGA_QTDE_ASAIR_DEVOLUCAO
    , ORDEMCARGA_LOADER_ASAIR_DEVOLUCAO_ISUPDATING
    , ORDEMCARGA_REMOVE_ASAIR_DEVOLUCAO
    , ORDEMCARGA_REMOVEITEM_ASAIR_DEVOLUCAO

    //Frete a sair
    , ORDEMCARGA_ASAIR_FRETEOPENCLOSE_UPDATE
    , ORDEMCARGA_ASAIR_FRETECHECK_UPDATE
    , ORDEMCARGA_ASAIR_FRETEISSAVING_UPDATE

    //Frete EM ROTA
    , ORDEMCARGA_EMROTA_FRETEOPENCLOSE_UPDATE
    , ORDEMCARGA_EMROTA_FRETECHECK_UPDATE
    , ORDEMCARGA_EMROTA_FRETEISSAVING_UPDATE
    , ORDEMCARGA_EMROTA_ASAIR_ISUPDATING

    , ORDEMCARGA_EMROTA_CHANGE_GERAL

    , ORDEMCARGA_EMROTA_LIST_APPENTREGA
    , ORDEMCARGA_EMROTA_LOADER_APPENTREGA
    , ORDEMCARGA_EMROTA_MODAL_APPENTREGA



    //Frete EM TRANSPORTADORA
    , ORDEMCARGA_EMTRANSPORTADORA_FRETEOPENCLOSE_UPDATE
    , ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE
    , ORDEMCARGA_EMTRANSPORTADORA_FRETEISSAVING_UPDATE

    //A SAIR MOTIVO N SAIU
    , ORDEMCARGA_PEDIDOMODALNSAIU_UPDATE

    , ORDEMCARGA_EMTRANSPORTADORA_CHANGE_GERAL
    , ORDEMCARGA_DEVOLUCAO_CHANGE_GERAL

    , ORDEMCARGA_LIST_VENDEDORES
    , ORDEMCARGA_CHECK_VENDEDORES


    , ORDEMCARGA_LIST_AUTO
    , ORDEMCARGA_QTDE_AUTO
    , ORDEMCARGA_LOADER_AUTO
    , ORDEMCARGA_LOADER_ISUPDATING_AUTO
    , ORDEMCARGA_REMOVE_AUTO


} from './ActionsTypes'

import { START_TIMER } from 'redux-timer';
import { STOP_TIMER } from 'redux-timer';
import * as Api from '../utils/API';
import sortBy from 'sort-by';


export const startTimerAction = () => async dispatch => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'painelPedidosTimer',
            action: async () => {
                try {
                    //console.log('tick!')

                    dispatch(ordemCargaListNaoEntregueAction())
                    dispatch({ type: ORDEMCARGA_LOG_INSERT, payload: [] })

                    dispatch(ordemCargaListEntregueAction())
                    dispatch(ordemCargaListAutoAction())

                    dispatch(ordemCargaListPedidosAction())

                    //Em Transportadora--------------------------------------
                    dispatch(ordemCargaListEmTransportadoraAction())
                    dispatch({ type: ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT, payload: [] })
                    //-------------------------------------------------------

                } catch (e) {
                    // do something with this error
                }
            },
            interval: 180000,
            runImmediately: false
        }
    });
};

export const stopTimerAction = () => ({
    type: STOP_TIMER,
    payload: {
        name: 'painelPedidosTimer'
    }
});


//a sair motivo
export const ordemCargaPedidoModalMotivoNSaiuUpdateAction = (PedidoModel, Open) => {

    PedidoModel.modalnsaiu = Open;
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_PEDIDOMODALNSAIU_UPDATE, payload: PedidoModel });
    };
};

export const ordemCargaPedidoMotivoNSaiuUpdateAction = (PedidoModel) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_PEDIDOMODALNSAIU_UPDATE, payload: PedidoModel });
    };
};

export const ordemCargaPedidoMotivoNSaiuSaveAction = (pedido) => (dispatch) => //{
    new Promise(function (resolve, reject) {

        Api.saveOrdemCargaASairNSaiu(pedido).then(result => {
            dispatch(ordemCargaListASairAction());
            resolve(result)
        });



    });












//a Sair Frete
export const handleASairFreteOpenClose = (ordem, acao) => (dispatch) => {
    dispatch({ type: ORDEMCARGA_ASAIR_FRETEOPENCLOSE_UPDATE, id: ordem.id, payload: acao });
    if (!acao) {
        dispatch({ type: ORDEMCARGA_ASAIR_FRETECHECK_UPDATE, id: ordem.id, payload: false });
    }
}

export const handleASairFreteCheck = (ordem, acao) => (dispatch) => {
    dispatch({ type: ORDEMCARGA_ASAIR_FRETECHECK_UPDATE, id: ordem.id, payload: acao });
}


export const handleASairFreteSave = (ordem) => (dispatch) => {
    new Promise(function (resolve, reject) {

        dispatch({ type: ORDEMCARGA_ASAIR_FRETEISSAVING_UPDATE, id: ordem.id, payload: true });

        let PedidosList = []

        for (let p of ordem.pedidos) {
            PedidosList.push({ pedidofbits: p.pedidofbits, nunota: p.nunota, numnota: p.numnota })
        }

        //FALTA API PARA FINALIZAR A TAREFA
        Api.saveOrdemCargaEmRota(PedidosList).then(result => {

            dispatch({ type: ORDEMCARGA_ASAIR_FRETECHECK_UPDATE, id: ordem.id, payload: false });
            dispatch({ type: ORDEMCARGA_ASAIR_FRETEOPENCLOSE_UPDATE, id: ordem.id, payload: false });
            dispatch({ type: ORDEMCARGA_ASAIR_CHKEMROTA_CLEAR })

            dispatch(ordemCargaListASairAction());
            dispatch(ordemCargaListNaoEntregueAction());

            resolve(result)
        });

    })
    //dispatch({ type: ORDEMCARGA_ASAIR_FRETECHECK_UPDATE, id: ordem.id, payload: acao });
}


//a EmRota Frete
export const handleEmRotaFreteOpenClose = (ordem, acao) => (dispatch) => {
    dispatch({ type: ORDEMCARGA_EMROTA_FRETEOPENCLOSE_UPDATE, id: ordem.id, payload: acao });
    if (!acao) {
        dispatch({ type: ORDEMCARGA_EMROTA_FRETECHECK_UPDATE, id: ordem.id, payload: false });
    }
}

export const handleEmRotaFreteCheck = (ordem, acao) => (dispatch) => {
    dispatch({ type: ORDEMCARGA_EMROTA_FRETECHECK_UPDATE, id: ordem.id, payload: acao });
}

export const handleEmRotaFreteSave = (ordem) => (dispatch) => {
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_EMROTA_FRETEISSAVING_UPDATE, id: ordem.id, payload: true });

        let PedidosList = []

        for (let p of ordem) {
            PedidosList.push({
                pedidofbits: p.pedidofbits
                , nunota: p.nunota
                , numnota: p.numnota
                /*,ad_tracknentid:0
                ,ad_trackndescr:''
                ,ad_tracknusuari:''*/
            })
        }

        Api.saveOrdemCargaEntrega(PedidosList).then(result => {
            dispatch({ type: ORDEMCARGA_LOG_INSERT, payload: result })
            dispatch({ type: ORDEMCARGA_LOADER_NAOENTREGUE, payload: true })
            dispatch({ type: ORDEMCARGA_LOADER_ENTREGUE, payload: true })
            dispatch({ type: ORDEMCARGA_CHKNAOENTREGUE_CLEAR });
            dispatch({ type: ORDEMCARGA_LIST_NAOENTREGUE, payload: [] })
            dispatch({ type: ORDEMCARGA_QTDE_NAOENTREGUE, payload: 0 })
            dispatch({ type: ORDEMCARGA_LIST_ENTREGUE, payload: [] })
            dispatch({ type: ORDEMCARGA_QTDE_ENTREGUE, payload: 0 })
            dispatch(ordemCargaListNaoEntregueAction());
            dispatch(ordemCargaListEmTransportadoraAction())
            dispatch(ordemCargaListEntregueAction());

            dispatch({ type: ORDEMCARGA_EMROTA_FRETECHECK_UPDATE, id: ordem.id, payload: false });
            dispatch({ type: ORDEMCARGA_EMROTA_FRETEOPENCLOSE_UPDATE, id: ordem.id, payload: false });
            resolve('')
        });

        //FALTA API PARA FINALIZAR A TAREFA
    })
    //dispatch({ type: ORDEMCARGA_ASAIR_FRETECHECK_UPDATE, id: ordem.id, payload: acao });
}





//a Em Transportadora Frete
export const handleEmTransportadoraFreteOpenClose = (ordem, acao) => (dispatch) => {
    dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_FRETEOPENCLOSE_UPDATE, id: ordem.id, payload: acao });
    if (!acao) {
        dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE, id: ordem.id, payload: false });
    }
}

export const handleEmTransportadoraFreteCheck = (ordem, acao) => (dispatch) => {
    dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE, id: ordem.id, payload: acao });
}

export const handleEmTransportadoraFreteSave = (ordem) => (dispatch) => {
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_FRETEISSAVING_UPDATE, id: ordem.id, payload: true });

        let PedidosList = []

        for (let p of ordem.pedidos) {
            PedidosList.push({ pedidofbits: p.pedidofbits, nunota: p.nunota, numnota: p.numnota, frete: undefined })
        }


        Api.saveOrdemCargaEntrega(PedidosList).then(result => {
            dispatch({ type: ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT, payload: result })
            dispatch({ type: ORDEMCARGA_LOADER_EMTRANSPORTADORA, payload: true })
            dispatch({ type: ORDEMCARGA_CHKEMTRANSPORTADORA_CLEAR });
            dispatch({ type: ORDEMCARGA_LIST_EMTRANSPORTADORA, payload: [] })
            dispatch({ type: ORDEMCARGA_QTDE_EMTRANSPORTADORA, payload: 0 })
            dispatch(ordemCargaListEmTransportadoraAction());
            dispatch(ordemCargaListEntregueAction())
            dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE, id: ordem.id, payload: false });
            dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_FRETEOPENCLOSE_UPDATE, id: ordem.id, payload: false });
            resolve('')
        });

        //FALTA API PARA FINALIZAR A TAREFA
    })
    //dispatch({ type: ORDEMCARGA_ASAIR_FRETECHECK_UPDATE, id: ordem.id, payload: acao });
}




export const ordemCargaListASairAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        const { vendedorselecionado } = getState().OrdemCargaReducer;
        let filtrobusca = { option: 'asair', vendedores: vendedorselecionado }

        Api.postAllOrdemCargaPorVend(filtrobusca).then(cargas => {

            //Api.getAllOrdemCarga('asair').then(cargas => {
            let qtdeASair = 0;
            dispatch({ type: ORDEMCARGA_LIST_ASAIR, payload: cargas })
            for (let i of cargas) {
                qtdeASair += i.pedidos.length;

                i.freteopen = false
                i.fretecheck = false
                i.isfretesaving = false
            }

            dispatch({ type: ORDEMCARGA_QTDE_ASAIR, payload: qtdeASair })
            dispatch({ type: ORDEMCARGA_LOADER_ASAIR, payload: false })
            resolve(cargas)
        }).catch(error => {
            reject(error);
        })

    });

export const ordemCargaMultiSelectCHKEmRotaAction = (pedido, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //return (dispatch) => {
        pedido.dataentrega = new Date();
        dispatch({ type: ORDEMCARGA_ASAIR_CHKEMROTA_INSERT, payload: pedido });
        resolve('')
        //};
    });

export const ordemCargaInsertCHKEmRotaAction = (PedidoModel) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //return (dispatch) => {
        PedidoModel.dataentrega = new Date();
        dispatch({ type: ORDEMCARGA_ASAIR_CHKEMROTA_INSERT, payload: PedidoModel });
        resolve('')
        //};
    });


export const ordemCargaRemoveCHKEmRotaAction = (PedidoModel) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //return (dispatch) => {
        dispatch({ type: ORDEMCARGA_ASAIR_CHKEMROTA_REMOVE, payload: PedidoModel });
        resolve('')
        //};
    });

export const ordemCargaUpdateCHKEmRotaAction = (PedidoModel) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_ASAIR_CHKEMROTA_UPDATE, payload: PedidoModel });
    };
};

export const ordemCargaSaveEmRotaAction = (PedidosList) => (dispatch) => //{
    new Promise(function (resolve, reject) {

        Api.saveOrdemCargaEmRota(PedidosList).then(result => {

            dispatch({ type: ORDEMCARGA_ASAIR_CHKEMROTA_CLEAR })

            dispatch(ordemCargaListASairAction());
            dispatch(ordemCargaListNaoEntregueAction());

            resolve(result)
        });
    });







export const ordemCargaEmAndamentoEntregueAction = (pedido) => (dispatch) => //{
    new Promise(function (resolve, reject) {

        dispatch({ type: ORDEMCARGA_EMANDAMENTO_ISENTREGASAVING, pedidofbits: pedido.pedidofbits, payload: true })

        let pedidoslist = []

        pedidoslist.push(pedido)

        Api.saveOrdemCargaEntrega(pedidoslist).then(result => {
            dispatch({ type: ORDEMCARGA_EMANDAMENTO_ISENTREGASAVING, pedidofbits: pedido.pedidofbits, payload: false })
            dispatch({ type: ORDEMCARGA_EMANDAMENTO_REMOVE, payload: pedido.pedidofbits })
            dispatch(ordemCargaListEntregueAction());
            resolve('')
        });



    });


//problema
export const ordemCargaUpdateDevolucaoAction = (entidade, carga) => (dispatch) =>
    //devolucao oficial
    new Promise(function (resolve, reject) {

        dispatch({ type: ORDEMCARGA_LOADER_ISUPDATING_NAOENTREGUE, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })
        dispatch({ type: ORDEMCARGA_LOADER_ASAIR_DEVOLUCAO_ISUPDATING, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })

        Api.saveOrdemCargaDevolucao(entidade).then(result => {
            dispatch({ type: ORDEMCARGA_REMOVEITEM_NAOENTREGUE, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })
            dispatch({ type: ORDEMCARGA_REMOVEITEM_ASAIR_DEVOLUCAO, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })

            dispatch({ type: ORDEMCARGA_QTDE_NAOENTREGUE_UPDATE, payload: 1 })
            dispatch({ type: ORDEMCARGA_QTDE_ASAIR_DEVOLUCAO, payload: 1 })

            dispatch(ordemCargaListDevolucaoAction())
            dispatch(ordemCargaListNaoEntregueAction())

            if (carga.pedidos.length == 0) {
                dispatch({ type: ORDEMCARGA_REMOVE_ASAIR_DEVOLUCAO, ordemcarga: entidade.parentid })
                dispatch({ type: ORDEMCARGA_REMOVE_NAOENTREGUE, ordemcarga: entidade.parentid })
            }
            resolve('')
        });

    });



export const ordemCargaUpdateEmRotaParaASairAction = (entidade, carga) => (dispatch) =>
    //devolucao oficial
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_EMROTA_ASAIR_ISUPDATING, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })

        Api.saveOrdemCargaParaASair(entidade).then(result => {
            dispatch(ordemCargaListASairAction())
            dispatch(ordemCargaListNaoEntregueAction())
            resolve('')
        });

    });




export const ordemCargaUpdateDevolucaoTranspAction = (entidade, carga) => (dispatch) =>
    //Devolução transportadora
    new Promise(function (resolve, reject) {

        dispatch({ type: ORDEMCARGA_LOADER_ISUPDATING_DEVTRANSP, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })

        Api.saveOrdemCargaDevolucao(entidade).then(result => {
            dispatch({ type: ORDEMCARGA_REMOVEITEM_DEVTRANSP, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })

            dispatch({ type: ORDEMCARGA_QTDE_DEVTRANSP_UPDATE, payload: 1 })

            dispatch(ordemCargaListDevolucaoAction())

            if (carga.pedidos.length == 0) {
                dispatch({ type: ORDEMCARGA_REMOVE_DEVTRANSP, ordemcarga: entidade.parentid })
            }
            resolve('')
        });

    });

export const ordemCargaUpdateDevolucaoEntreguesAction = (entidade) => (dispatch) =>
    //Devolução entregues
    new Promise(function (resolve, reject) {

        dispatch({ type: ORDEMCARGA_LOADER_ISUPDATING_DEVENTREGUE, pedidofbits: entidade.pedidofbits })

        Api.saveOrdemCargaDevolucao(entidade).then(result => {
            dispatch({ type: ORDEMCARGA_REMOVEITEM_DEVENTREGUE, pedidofbits: entidade.pedidofbits })

            dispatch({ type: ORDEMCARGA_QTDE_DEVENTREGUE_UPDATE, payload: 1 })

            dispatch(ordemCargaListDevolucaoAction())

            /*if(carga.pedidos.length==0){
                dispatch({type: ORDEMCARGA_REMOVE_DEVENTREGUE, ordemcarga: entidade.parentid}) 
            }*/
            resolve('')
        });

    });

export const ordemCargaUpdateDevolucaoFinalizaAction = (entidade, carga) => (dispatch) =>
    new Promise(function (resolve, reject) {


        dispatch({ type: ORDEMCARGA_LOADER_ISUPDATING_DEVOLUCAO, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })


        Api.saveOrdemCargaDevolucaoFim(entidade).then(result => {
            dispatch({ type: ORDEMCARGA_REMOVEITEM_DEVOLUCAO, ordemcarga: entidade.parentid, nunota: entidade.nunota, pedidofbits: entidade.pedidofbits })

            dispatch({ type: ORDEMCARGA_QTDE_DEV_UPDATE, payload: 1 })

            dispatch(ordemCargaListDevolucaoAction())

            if (carga.pedidos.length == 0) {
                dispatch({ type: ORDEMCARGA_REMOVE_DEVOLUCAO, ordemcarga: entidade.parentid })
            }
            resolve('')
        });

    });


export const ordemCargaListDevolucaoAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {


        const { vendedorselecionado } = getState().OrdemCargaReducer;
        let filtrobusca = { option: 'devolucao', vendedores: vendedorselecionado }

        //dispatch({ type: ORDEMCARGA_LOADER_NAOENTREGUE, payload: true })
        dispatch({ type: ORDEMCARGA_LOADER_DEVOLUCAO, payload: true })
        Api.postAllOrdemCargaPorVend(filtrobusca).then(cargas => {
            //Api.getAllDevolucao().then(cargas => {
            let qtdeDevolucao = 0;
            dispatch({ type: ORDEMCARGA_LIST_DEVOLUCAO, payload: cargas })
            for (let i of cargas) {
                qtdeDevolucao += i.pedidos.length;
            }

            dispatch({ type: ORDEMCARGA_QTDE_DEVOLUCAO_UPDATE, payload: qtdeDevolucao })

            dispatch({ type: ORDEMCARGA_LOADER_DEVOLUCAO, payload: false })
            //dispatch({ type: ORDEMCARGA_LOADER_NAOENTREGUE, payload: false })
            resolve('')
        }).catch(error => {
            reject(error);
        })

    });

export const ordemCargaSaveNaoEntregueAction = (PedidosList) => (dispatch) => //{
    new Promise(function (resolve, reject) {
        //return (dispatch) => {
        //esse é o original
        Api.saveOrdemCargaEntrega(PedidosList).then(result => {
            dispatch({ type: ORDEMCARGA_LOG_INSERT, payload: result })
            dispatch({ type: ORDEMCARGA_LOADER_NAOENTREGUE, payload: true })
            dispatch({ type: ORDEMCARGA_LOADER_ENTREGUE, payload: true })
            dispatch({ type: ORDEMCARGA_CHKNAOENTREGUE_CLEAR });
            dispatch({ type: ORDEMCARGA_LIST_NAOENTREGUE, payload: [] })
            dispatch({ type: ORDEMCARGA_QTDE_NAOENTREGUE, payload: 0 })
            dispatch({ type: ORDEMCARGA_LIST_ENTREGUE, payload: [] })
            dispatch({ type: ORDEMCARGA_QTDE_ENTREGUE, payload: 0 })
            dispatch(ordemCargaListNaoEntregueAction());
            dispatch(ordemCargaListEmTransportadoraAction())
            dispatch(ordemCargaListEntregueAction());
            resolve('')
        });
        //};
    });

export const ordemCargaSaveMotivoNaoEntregueAction = (PedidosList) => (dispatch) => //{
    new Promise(function (resolve, reject) {
        Api.saveOrdemCargaMotivo(PedidosList).then(result => {
            dispatch({ type: ORDEMCARGA_LOG_INSERT, payload: result })
            resolve(result)
        });
    });




export const ordemCargaInsertCHKNaoEntregueAction = (PedidoModel) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //return (dispatch) => {
        PedidoModel.dataentrega = new Date();
        dispatch({ type: ORDEMCARGA_CHKNAOENTREGUE_INSERT, payload: PedidoModel });
        resolve('')
        //};
    });


export const ordemCargaRemoveCHKEntregueAction = (PedidoModel) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //return (dispatch) => {
        dispatch({ type: ORDEMCARGA_CHKNAOENTREGUE_REMOVE, payload: PedidoModel });
        resolve('')
        //};
    });

export const ordemCargaUpdateCHKNaoEntregueAction = (PedidoModel) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_CHKNAOENTREGUE_UPDATE, payload: PedidoModel });
    };
};



export const ordemCargaPedidoModalUpdateAction = (PedidoModel, Open) => {
    PedidoModel.modal = Open;
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_PEDIDOMODAL_UPDATE, payload: PedidoModel });
    };
};

export const ordemCargaPedidoModalDEVUpdateAction = (PedidoModel, Open) => {
    PedidoModel.modal = Open;
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_PEDIDOMODALDEV_UPDATE, payload: PedidoModel });
    };
};




export const ordemCargaPedidoUpdateAction = (PedidoModel) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_PEDIDOMODAL_UPDATE, payload: PedidoModel });
    };
};

export const ordemCargaPedidoDEVUpdateAction = (PedidoModel) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_PEDIDOMODALDEV_UPDATE, payload: PedidoModel });
    };
};

export const ordemCargaListNaoEntregueAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        const { vendedorselecionado } = getState().OrdemCargaReducer;
        let filtrobusca = { option: 'naoentregue', vendedores: vendedorselecionado }
        dispatch({ type: ORDEMCARGA_LOADER_NAOENTREGUE, payload: true })
        Api.postAllOrdemCargaPorVend(filtrobusca).then(cargas => {
            //Api.getAllOrdemCarga('naoentregue').then(cargas => {
            let qtdeNaoEntregue = 0;
            dispatch({ type: ORDEMCARGA_LIST_NAOENTREGUE, payload: cargas })
            for (let i of cargas) {
                qtdeNaoEntregue += i.pedidos.length;
                i.freteopen = false
                i.fretecheck = false
                i.isfretesaving = false
            }

            dispatch({ type: ORDEMCARGA_QTDE_NAOENTREGUE, payload: qtdeNaoEntregue })
            dispatch({ type: ORDEMCARGA_LOADER_NAOENTREGUE, payload: false })
            resolve(cargas)
        }).catch(error => {
            reject(error);
        })

    });

export const ordemCargaListEntregueAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        const { vendedorselecionado } = getState().OrdemCargaReducer;
        let filtrobusca = { option: 'entregues', vendedores: vendedorselecionado }
        dispatch({ type: ORDEMCARGA_LOADER_ENTREGUE, payload: true })
        Api.postAllOrdemCargaPorVend(filtrobusca).then(cargas => {

            //Api.getAllOrdemCarga('entregues').then(cargas => {
            let qtdeEntregue = 0;
            dispatch({ type: ORDEMCARGA_LIST_ENTREGUE, payload: cargas })
            qtdeEntregue = cargas.length;

            dispatch({ type: ORDEMCARGA_QTDE_ENTREGUE, payload: qtdeEntregue })
            dispatch({ type: ORDEMCARGA_LOADER_ENTREGUE, payload: false })
            resolve(cargas)
        }).catch(error => {
            reject(error);
        })

    });







export const ordemCargaSaveNaoPagosFaturadosFrete = (pedido) => {


    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_ISSAVING_FRETE_PEDIDOSPAGOSNAOFATURADOS, pedidofbits: pedido.pedidofbits, acao: true });

        Api.saveOrdemCargaFrete(pedido).then(dados => {
            dispatch({ type: ORDEMCARGA_ISSAVING_FRETE_PEDIDOSPAGOSNAOFATURADOS, pedidofbits: pedido.pedidofbits, acao: false });
            dispatch({ type: ORDEMCARGA_ISEDITING_PEDIDOSPAGOSNAOFATURADOS, pedidofbits: pedido.pedidofbits, acao: false });
        })
    }
}




export const ordemCargaUpdateNaoPagosFaturadosFreteAction = (pedido, acao) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_ISEDITING_PEDIDOSPAGOSNAOFATURADOS, pedidofbits: pedido.pedidofbits, acao: acao });
    };
};

export const ordemCargaUpdateNaoPagosFaturadosFreteTextoAction = (pedido, texto) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_UPDATE_FRETE_PEDIDOSPAGOSNAOFATURADOS, pedidofbits: pedido.pedidofbits, texto: texto });
    };
};




export const ordemCargaListPedidosAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        const { vendedorselecionado } = getState().OrdemCargaReducer;
        let filtrobusca = { option: 'geral', vendedores: vendedorselecionado }

        Api.postAllOrdemCargaPorVend(filtrobusca).then(pedidos => {
            //Api.getAllOrdemCargaPedidos().then(pedidos => {
            let pedidosNaoPagos = [];
            let pedidosPagosNaoFaturados = [];
            let pedidosFaturadosSemRota = [];

            pedidosNaoPagos = pedidos.filter(p => p.tipo === '1 - Não pagos');
            for (let i of pedidosNaoPagos) {
                i.isloading_ligar = false;
                i.modal_ligar = false;
            }


            pedidosPagosNaoFaturados = pedidos.filter(p => p.tipo === '2 - Em andamento');
            for (let i of pedidosPagosNaoFaturados) {
                i.isloading_ligar = false;
                i.modal_ligar = false;
            }

            pedidosFaturadosSemRota = pedidos.filter(p => p.tipo === '3 - Aguardando');


            dispatch({ type: ORDEMCARGA_LIST_PEDIDOSNAOPADOS, payload: pedidosNaoPagos })
            dispatch({ type: ORDEMCARGA_LIST_PEDIDOSPAGOSNAOFATURADOS, payload: [] })//zerando antes para evitar sobrar pedido
            dispatch({ type: ORDEMCARGA_LIST_PEDIDOSPAGOSNAOFATURADOS, payload: pedidosPagosNaoFaturados })
            dispatch({ type: ORDEMCARGA_LIST_PEDIDOSFATURADOSSEMROTA, payload: pedidosFaturadosSemRota })


            //em andamento
            dispatch({ type: ORDEMCARGA_QTDE_PEDIDOSNAOPADOS, payload: pedidosNaoPagos.length })
            dispatch({ type: ORDEMCARGA_QTDE_PEDIDOSPAGOSNAOFATURADOS, payload: pedidosPagosNaoFaturados.length })
            dispatch({ type: ORDEMCARGA_QTDE_PEDIDOSFATURADOSSEMROTA, payload: pedidosFaturadosSemRota.length })

            dispatch({ type: ORDEMCARGA_LOADER_PEDIDOSNAOPADOS, payload: false })
            dispatch({ type: ORDEMCARGA_LOADER_PEDIDOSPAGOSNAOFATURADOS, payload: false })
            dispatch({ type: ORDEMCARGA_LOADER_PEDIDOSFATURADOSSEMROTA, payload: false })

            resolve('')
        }).catch(error => {
            reject(error);
        })

    });




export const ordemCargaCheckVendedoresAction = (vendedores) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_CHECK_VENDEDORES, payload: vendedores })



        resolve('');
    });


export const ordemCargaListVendedoresAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        let newVendedores = [];
        // newVendedores.push({ key: "-1", text: "", value: "-1" })

        Api.getAllVendedores().then(vendedores => {


            for (let v of vendedores) {
                newVendedores.push({ key: v.vendedor, text: v.vendedor, value: v.tipagem })
                if (v.vendedor == 'TODOS - VCC+MKTPL+B2B') {

                    dispatch({ type: ORDEMCARGA_CHECK_VENDEDORES, payload: v.tipagem })
                }
            }

            dispatch({ type: ORDEMCARGA_LIST_VENDEDORES, payload: newVendedores })
            resolve('')
        }).catch(error => {
            reject(error);
        })

    });

export const ordemCargaListMotivosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        let newMotivos = [];
        newMotivos.push({ key: "-1", text: "", value: "-1" })

        Api.getAllMotivos().then(motivos => {


            for (let m of motivos) {
                newMotivos.push({ key: m.motivonaoentregaid, text: m.motivo, value: m.motivonaoentregaid })
            }

            dispatch({ type: ORDEMCARGA_LIST_MOTIVOS, payload: newMotivos })
            resolve(newMotivos)
        }).catch(error => {
            reject(error);
        })

    });
















//Em Transportadora--------------------------------------------------------------------------------------------
//OK
export const ordemCargaSaveEmTransportadoraAction = (PedidosList) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //##esse é o clone
        Api.saveOrdemCargaEntrega(PedidosList).then(result => {
            dispatch({ type: ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT, payload: result })
            dispatch({ type: ORDEMCARGA_LOADER_EMTRANSPORTADORA, payload: true })
            dispatch({ type: ORDEMCARGA_CHKEMTRANSPORTADORA_CLEAR });
            dispatch({ type: ORDEMCARGA_LIST_EMTRANSPORTADORA, payload: [] })
            dispatch({ type: ORDEMCARGA_QTDE_EMTRANSPORTADORA, payload: 0 })
            dispatch(ordemCargaListEmTransportadoraAction());
            dispatch(ordemCargaListEntregueAction())
            resolve('')
        });
    });

//OK
export const ordemCargaSaveMotivoEmTransportadoraAction = (PedidosList) => (dispatch) => //{
    new Promise(function (resolve, reject) {
        Api.saveOrdemCargaMotivo(PedidosList).then(result => {
            dispatch({ type: ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT, payload: result })
            resolve(result)
        });
    });

//OK
export const ordemCargaInsertCHKEmTransportadoraAction = (PedidoModel) => (dispatch) =>
    new Promise(function (resolve, reject) {
        PedidoModel.dataentrega = new Date();
        dispatch({ type: ORDEMCARGA_CHKEMTRANSPORTADORA_INSERT, payload: PedidoModel });
        resolve('')
    });

//OK
export const ordemCargaUpdateCHKEmTransportadoraAction = (PedidoModel) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_CHKEMTRANSPORTADORA_UPDATE, payload: PedidoModel });
    };
};

//ok
export const ordemCargaRemoveCHKEmTransportadoraAction = (PedidoModel) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_CHKEMTRANSPORTADORA_REMOVE, payload: PedidoModel });
        resolve('')
    });


//OK
export const ordemCargaPedidoEmTransportadoraModalUpdateAction = (PedidoModel, Open) => {
    PedidoModel.modal = Open;
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE, payload: PedidoModel });
    };
};

//OK
export const ordemCargaPedidoEmTransportadoraUpdateAction = (PedidoModel) => {
    return (dispatch) => {
        dispatch({ type: ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE, payload: PedidoModel });
    };
};

//OK
export const ordemCargaListEmTransportadoraAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {


        const { vendedorselecionado } = getState().OrdemCargaReducer;
        let filtrobusca = { option: 'emtransportadora', vendedores: vendedorselecionado }

        let qtdeEmTransportadora = 0;
        //Api.getAllOrdemCarga('emtransportadora').then(cargas => {
        dispatch({ type: ORDEMCARGA_LOADER_EMTRANSPORTADORA, payload: true })

        Api.postAllOrdemCargaPorVend(filtrobusca).then(cargas => {
            dispatch({ type: ORDEMCARGA_LIST_EMTRANSPORTADORA, payload: cargas })
            for (let i of cargas) {
                qtdeEmTransportadora += i.pedidos.length;
                i.freteopen = false
                i.fretecheck = false
                i.isfretesaving = false
            }

            dispatch({ type: ORDEMCARGA_QTDE_EMTRANSPORTADORA, payload: qtdeEmTransportadora })
            dispatch({ type: ORDEMCARGA_LOADER_EMTRANSPORTADORA, payload: false })
            resolve(cargas)
        }).catch(error => {
            reject(error);
        })
    });
//-------------------------------------------------------------------------------------------------------------




export const ordemCargaEmRotaUpdatePrazosAction = (pedido, campo, data) => (dispatch) =>

    new Promise(function (resolve, reject) {
        /*console.log(`
        pedido: ${pedido.pedidofbits}|data: ${data}| campo: ${campo}
        `)*/
        dispatch({ type: ORDEMCARGA_EMROTA_CHANGE_GERAL, ordemcarga: pedido.parentid, nunota: pedido.nunota, pedidofbits: pedido.pedidofbits, campo: campo, payload: data })
        resolve('')

    });


export const ordemCargaEmRotaSavePrazosAction = (pedido, login) => (dispatch) => {
    new Promise(function (resolve, reject) {

        if (!pedido.is_savingdt) {
            dispatch({ type: ORDEMCARGA_EMROTA_CHANGE_GERAL, ordemcarga: pedido.parentid, nunota: pedido.nunota, pedidofbits: pedido.pedidofbits, campo: 'is_savingdt', payload: true })
            pedido.login = login
            Api.saveOrdemCargaEmRotaPrazos(pedido).then(result => {
                dispatch(ordemCargaListNaoEntregueAction());
                resolve(result)
            });

        }

    })
}



export const ordemCargaEmTransportadoraUpdatePrazosAction = (pedido, campo, data) => (dispatch) =>

    new Promise(function (resolve, reject) {


        dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_CHANGE_GERAL, ordemcarga: pedido.parentid, nunota: pedido.nunota, pedidofbits: pedido.pedidofbits, campo: campo, payload: data })
        resolve('')


    });


export const ordemCargaEmTransportadoraSavePrazosAction = (pedido, login) => (dispatch) => {
    new Promise(function (resolve, reject) {

        if (!pedido.is_savingdt) {
            dispatch({ type: ORDEMCARGA_EMTRANSPORTADORA_CHANGE_GERAL, ordemcarga: pedido.parentid, nunota: pedido.nunota, pedidofbits: pedido.pedidofbits, campo: 'is_savingdt', payload: true })
            pedido.login = login

            Api.saveOrdemCargaEmRotaPrazos(pedido).then(result => {
                dispatch(ordemCargaListEmTransportadoraAction());
                resolve(result)
            });

        }

    })
}


export const ordemCargaEmDevolucaoUpdatePrazosAction = (pedido, campo, data) => (dispatch) =>

    new Promise(function (resolve, reject) {


        dispatch({ type: ORDEMCARGA_DEVOLUCAO_CHANGE_GERAL, ordemcarga: pedido.parentid, nunota: pedido.nunota, pedidofbits: pedido.pedidofbits, campo: campo, payload: data })
        resolve('')


    });


export const ordemCargaEmDevolucaoSavePrazosAction = (pedido, login) => (dispatch) => {
    new Promise(function (resolve, reject) {

        if (!pedido.is_savingdt) {
            dispatch({ type: ORDEMCARGA_DEVOLUCAO_CHANGE_GERAL, ordemcarga: pedido.parentid, nunota: pedido.nunota, pedidofbits: pedido.pedidofbits, campo: 'is_savingdt', payload: true })
            pedido.login = login

            Api.saveOrdemCargaEmRotaPrazos(pedido).then(result => {
                dispatch(ordemCargaListDevolucaoAction());
                resolve(result)
            });

        }

    })
}




export const ordemCargaListAutoAction = (interno) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {

        const { vendedorselecionado } = getState().OrdemCargaReducer;
        let filtrobusca = { option: 'auto', vendedores: vendedorselecionado }
        if (interno != true) {
            dispatch({ type: ORDEMCARGA_LOADER_AUTO, payload: true })
        }

        Api.postAllOrdemCargaAutoPorVend(filtrobusca).then(cargas => {

            let qtdeEntregue = 0;
            dispatch({ type: ORDEMCARGA_LIST_AUTO, payload: cargas })
            qtdeEntregue = cargas.length;

            dispatch({ type: ORDEMCARGA_QTDE_AUTO, payload: qtdeEntregue })
            dispatch({ type: ORDEMCARGA_LOADER_AUTO, payload: false })
            resolve(cargas)
        }).catch(error => {
            reject(error);
        })

    });






export const ordemCargaUpdateAutoAction = (entidade) => (dispatch, getState) =>
    //Devolução entregues
    new Promise(function (resolve, reject) {

        const { login } = getState().LoginReducer;
        dispatch({ type: ORDEMCARGA_LOADER_ISUPDATING_AUTO, pedidofbits: entidade.pedidofbits, nunota: entidade.nunota });

        let pedido = entidade;

        pedido.login = login;



        Api.saveOrdemCargaAuto(pedido).then(result => {
            //dispatch({ type: ORDEMCARGA_REMOVE_AUTO, pedidofbits: entidade.pedidofbits, nunota: entidade.nunota });

            dispatch(ordemCargaListAutoAction(true))
            resolve('')
        });



    });





export const ordemCargaNaoPagosLigarAction = (nunota, flativo, complemento) => (dispatch, getState) =>
    //Devolução entregues
    //postOrdemCargaLigar
    new Promise(function (resolve, reject) {

        const { login } = getState().LoginReducer;

        let entidade = {}

        entidade.nunota = nunota;
        entidade.flativo = flativo;
        entidade.login = login;
        entidade.complemento = complemento;

        dispatch({ type: ORDEMCARGA_LOADER_PEDIDOSNAOPADOS_LIGAR, nunota: nunota, payload: true });

        Api.postOrdemCargaLigar(entidade).then(result => {
            //dispatch(ordemCargaListAutoAction(true))

            let resligar = '';
            let resligarcomplemento = '';

            if (result[0].status == 'OK') {
                if (flativo == 'S') {
                    resligar = 'N';
                    resligarcomplemento = complemento;

                } else if (flativo == 'N') {
                    resligar = 'S';
                    resligarcomplemento = complemento;

                } else {
                    resligar = 'S';
                    resligarcomplemento = complemento;

                };
            } else {
                resligar = null;
                resligarcomplemento = '';

            }

            dispatch({
                type: ORDEMCARGA_LOADER_PEDIDOSNAOPADOS_LIGAR, nunota: nunota, payload: false
                , ligar: resligar, ligarcomplemento: resligarcomplemento
            });

            resolve('')
        });



    });




export const ordemCargaEmAndamentoLigarAction = (nunota, flativo, complemento) => (dispatch, getState) =>
    //Devolução entregues
    //postOrdemCargaLigar
    new Promise(function (resolve, reject) {

        const { login } = getState().LoginReducer;

        let entidade = {}

        entidade.nunota = nunota;
        entidade.flativo = flativo;
        entidade.login = login;
        entidade.complemento = complemento;

        dispatch({ type: ORDEMCARGA_LOADER_PEDIDOSEMANDAMENTO_LIGAR, nunota: nunota, payload: true });

        Api.postOrdemCargaLigar(entidade).then(result => {
            //dispatch(ordemCargaListAutoAction(true))

            let resligar = '';
            let resligarcomplemento = '';

            if (result[0].status == 'OK') {
                if (flativo == 'S') {
                    resligar = 'N';
                    resligarcomplemento = complemento;

                } else if (flativo == 'N') {
                    resligar = 'S';
                    resligarcomplemento = complemento;

                } else {
                    resligar = 'S';
                    resligarcomplemento = complemento;

                };
            } else {
                resligar = null;
                resligarcomplemento = '';

            }

            dispatch({
                type: ORDEMCARGA_LOADER_PEDIDOSEMANDAMENTO_LIGAR, nunota: nunota, payload: false
                , ligar: resligar, ligarcomplemento: resligarcomplemento
            });

            resolve('')
        });



    });





export const ordemCargaNaoPagosCOMPLEMENTOLigarAction = (nunota, complemento) => (dispatch, getState) =>
    //Devolução entregues
    //postOrdemCargaLigar
    new Promise(function (resolve, reject) {
        //REMOVE acentuação
        complemento = complemento.replace(/[^A-Za-z 0-9]/g, "").toUpperCase();


        dispatch({ type: ORDEMCARGA_COMPLEMENTO_PEDIDOSNAOPADOS_LIGAR, nunota: nunota, payload: complemento });
        resolve('');
    });

export const ordemCargaEmAndamentoCOMPLEMENTOLigarAction = (nunota, complemento) => (dispatch, getState) =>
    //Devolução entregues
    //postOrdemCargaLigar
    new Promise(function (resolve, reject) {
        //REMOVE acentuação
        complemento = complemento.replace(/[^A-Za-z 0-9]/g, "").toUpperCase();

        dispatch({ type: ORDEMCARGA_COMPLEMENTO_PEDIDOSEMANDAMENTO_LIGAR, nunota: nunota, payload: complemento });
        resolve('');
    });


export const ordemCargaNaoPagosMODALLigarAction = (nunota, acao) => (dispatch, getState) =>
    //Devolução entregues
    //postOrdemCargaLigar
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_MODAL_PEDIDOSNAOPADOS_LIGAR, nunota: nunota, payload: acao });
        resolve('');
    });

export const ordemCargaEmAndamentoMODALLigarAction = (nunota, acao) => (dispatch, getState) =>
    //Devolução entregues
    //postOrdemCargaLigar
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_MODAL_PEDIDOSEMANDAMENTO_LIGAR, nunota: nunota, payload: acao });
        resolve('');
    });



export const ordemCargaEmRotaListAppEntregasAction = (ordemcarga) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_EMROTA_LOADER_APPENTREGA, payload: true });
        dispatch({ type: ORDEMCARGA_EMROTA_MODAL_APPENTREGA, payload: true });

        Api.getAllOrdemCargaAppEntregas(ordemcarga).then(result => {
            //dispatch(ordemCargaListAutoAction(true))

            dispatch({ type: ORDEMCARGA_EMROTA_LIST_APPENTREGA, payload: result });
            dispatch({ type: ORDEMCARGA_EMROTA_LOADER_APPENTREGA, payload: false });
            resolve('')
        });
    });


export const ordemCargaEmRotaModalAppEntregasAction = (acao) => (dispatch, getState) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ORDEMCARGA_EMROTA_MODAL_APPENTREGA, payload: acao });
        resolve('')
    });
















