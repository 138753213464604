
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import InputMask from 'react-input-mask';
import { saveAs } from 'file-saver';


import moment from 'moment';
import 'moment/locale/pt-br'

import {
  mercadolivreListTokenAction
  , MercadoLivreHandleDimensoesChangeAction
  , MercadoLivreDimensoesSaveAction
  , MercadoLivreAnuncioUpdateAction
  , MercadoLivreAnunciosSaveAction
  , mercadolivreListEnviadosAction
  , mercadolivreListPendentesAction
  , mercadolivreCheckAction
  , mercadolivreNFEnviaAction
} from '../../actions/MercadoLivreActions';

import {
  Table, Form, Segment, Message, Menu, Breadcrumb, Grid, Input, Select, Divider
  , Tab, Button, Icon, Checkbox, Popup

} from 'semantic-ui-react'

import If from '../../utils/If';
import styled from 'styled-components';
import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;



class MercadoLivre extends Component {

  state = {
    vtitle: false
    , vprice: false
    , vcategory: false
    , vquantity: false
    , check: false
    , isenviando: false
    , loaderenviar: false
    , loaderenviados: false
    , loadingnf: true
    , isenviados: true
    , isenviar: true
    , categoria: ''

  }

  constructor(props) {
    super(props);
  }

  componentDidMount() {

    //Verificando permissão de funcionalidade do MENU-------------------
    let { menu, history } = this.props;
    if (menu.filter(m => m.menuid === 20 && m.status === 1).length === 0) { history.push(`/Home`) }
    //------------------------------------------------------------------

    this.props.mercadolivreListTokenAction(this.props.codigo)

    this.props.mercadolivreListEnviadosAction().then(d => {
      this.setState({ isenviados: false })


    });
    this.props.mercadolivreListPendentesAction().then(d => {
      this.setState({ isenviar: false })
    });
  }

  /*handleBusca = () => {

    let pedidos = this.props.parametros
    if (pedidos != '') {
      this.props.marketplacesListAction({ pedidos: pedidos }).then(d => { })
    }

  }*/

  SaveDimensoes = () => {
    if (!this.props.dimensoessaving) {
      this.props.MercadoLivreDimensoesSaveAction(this.props.dimensoes, this.props.token)
    }
  }



  SaveAnuncio = (entidade) => {

    let valido = true;


    if (entidade.title == '') {
      this.setState({ vtitle: true })
      valido = false
    } else {
      this.setState({ vtitle: false })
    }

    if (entidade.price == 0 || entidade.price == "") {
      this.setState({ vprice: true })
      valido = false
    } else {
      this.setState({ vprice: false })
    }

    try {
      let val = Number.parseFloat(entidade.price)
    } catch (err) {
      valido = false
      //alert('akiiii')
    }


    if (entidade.category_id == '') {
      this.setState({ vcategory: true })
      valido = false
    } else {
      this.setState({ vcategory: false })
    }


    if (entidade.available_quantity == '') {
      this.setState({ vquantity: true })
      valido = false
    } else {
      this.setState({ vquantity: false })
    }

    if (valido) {
      //alert('grava')
      if (!this.props.anunciosaving) {
        this.props.MercadoLivreAnunciosSaveAction(entidade, this.props.token)
      }
    }
  }


  handleChange = (e, { value }) => {


    this.props.MercadoLivreAnuncioUpdateAction({ target: { 'name': 'category_id', 'value': value } })

    this.setState({ categoria: value })
  }
  onEnviaNF = (pedidos, login, token) => {
    this.setState({ isenviando: true })

    if (!this.state.isenviando) {
      this.props.mercadolivreNFEnviaAction(pedidos, login, token).then(d => {
        this.setState({ isenviando: false })
      })

    }




  }

  onCheckML = (e, item) => {
    this.props.mercadolivreCheckAction(item, !item.ischecked)
  }

  onCheckMLAll = (pedidos) => {
    //this.props.mercadolivreCheckAction(item, !item.ischecked)


    if (this.state.check) {
      this.setState({ check: false })
    } else {
      this.setState({ check: true })
    }


    for (let i of pedidos) {
      this.props.mercadolivreCheckAction(i, !this.state.check)
    }

  }

  onAtualizaEnviados = () => {
    this.setState({ loaderenviados: true })

    if (!this.state.loaderenviados) {
      this.props.mercadolivreListEnviadosAction().then(d => {
        this.setState({ loaderenviados: false })
      });

    }


  }


  onAtualizaEnviar = () => {
    this.setState({ loaderenviar: true })

    if (!this.state.loaderenviar) {
      this.props.mercadolivreListPendentesAction().then(d => {
        this.setState({ loaderenviar: false })
      });

    }


  }

  //download pdf from stream blob
  onDownloadPDF = (pedido, pdf) => {

    let buffer = Buffer.from(pdf.data);
    let arraybuffer = Uint8Array.from(buffer).buffer;

    const file = new Blob(
      [arraybuffer],
      { type: 'application/pdf' });

    //const fileURL = URL.createObjectURL(file);
    //window.open(fileURL);
    saveAs(file, `${pedido}.pdf`);

  }

  render() {

    let { consulta, parametros, issearching, dimensoesresultado, MercadoLivreAnuncioEntity, category_id_list, anuncioresultado
      , pendentes, enviados } = this.props

    let { vtitle, vprice, vcategory, vquantity } = this.state;


    let definestatus = (status) => {
      let texto = ''
      if (status == 0) {
        texto = 'Aguardando Integrar'
      } else if (status == 1) {
        texto = 'Aguardando Mercado Livre'
      } else if (status == 2) {
        texto = 'OK'
      } else if (status == 3) {
        texto = 'Erro'
      }
      return texto

    }

    let inteiro = {
      mask: '999999',
      maskChar: '',
      alwaysShowMask: false,
      formatChars: {
        '9': '[0-9]',
      },
    }

    let flutuante = {
      mask: '99999',
      maskChar: '',
      alwaysShowMask: false,
      formatChars: {
        '9': '[0-9]',
      },
    }


    /*const BrlCurrencyComponent = () => {
      const handleChange = (event, value, maskedValue) => {
        event.preventDefault();
     
        console.log(value); // value without mask (ex: 1234.56)
        console.log(maskedValue); // masked value (ex: R$1234,56)
      };*/


    return (

      <div>

        <Menu stackable size='mini'>
          <Menu.Item>
            <Breadcrumb size='mini'>
              <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section active>MercadoLivre </Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu>

        <Grid columns={2} doubling>
          <Grid.Row>

            <Grid.Column>
              <div>

                <Message>
                  <Message.Header>Token atual:</Message.Header>
                  <p>
                    {this.props.token}
                  </p>

                  <Message.Header>Código:</Message.Header>
                  <p>
                    {this.props.codigo}
                  </p>
                </Message>

                <Segment secondary>
                  <Message>
                    <Message.Header>Cadastro de um novo Anúncio:</Message.Header>
                  </Message>
                  <Form>
                    <Form.Group widths='equal'>
                      <Form.Field
                        name='title'
                        control={Input}
                        label={`Título (${MercadoLivreAnuncioEntity.title.length}) caracteres`}
                        placeholder='Título'
                        error={vtitle}
                        value={MercadoLivreAnuncioEntity.title}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                        maxLength={60}
                      />

                      <Form.Field
                        name='price'
                        control={InputMask}
                        //{...flutuante}
                        type="number"
                        label='Preço'
                        placeholder='Preço'
                        error={vprice}
                        value={MercadoLivreAnuncioEntity.price}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                      />

                    </Form.Group>



                    <Form.Group widths='equal'>
                      <Form.Field
                        name='category_id'
                        control={Select}
                        options={category_id_list}
                        label={{ children: 'Categorias', htmlFor: 'form-select-control-gender' }}
                        placeholder='Categorias'
                        search
                        searchInput={{ id: 'form-select-control-gender' }}
                        error={vcategory}
                        value={MercadoLivreAnuncioEntity.category_id}
                        onChange={this.handleChange}
                      />

                      <Form.Field
                        name='available_quantity'
                        control={InputMask}
                        {...inteiro}
                        label='Quantidade'
                        placeholder='Quantidade'
                        error={vquantity}
                        value={MercadoLivreAnuncioEntity.available_quantity}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                      />


                      <Form.Field
                        name='dimensions'
                        control={Input}
                        label='Dimensão'
                        placeholder='243x16x16,2001'
                        value={MercadoLivreAnuncioEntity.dimensions}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                      />

                    </Form.Group>

                    <Form.Group widths='equal'>
                      <Form.Field
                        name='brand'
                        control={Input}
                        label='Marca'
                        placeholder='Eucafloor'
                        value={MercadoLivreAnuncioEntity.brand}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                      />

                      <Form.Field
                        name='model'
                        control={Input}
                        label='Modelo'
                        placeholder='Atenas'
                        value={MercadoLivreAnuncioEntity.model}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                      />
                    </Form.Group>

                    <Form.Group widths='equal'>
                      <Form.Field
                        name='sales_unit'
                        control={Input}
                        label='Unidade de venda'
                        placeholder='1'
                        value={MercadoLivreAnuncioEntity.sales_unit}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                      />
                      {this.state.categoria === 'MLB271830' ? ( // Se caso a categoria for RODAPÉ troca para outro campo do formulario
                        <Form.Field
                          name='baseboards_yield_of_sales_unit'
                          control={Input}
                          label='Rendimento da unidade de rodapés'
                          placeholder='4,33 m²'
                          value={MercadoLivreAnuncioEntity.baseboards_yield_of_sales_unit}
                          onChange={this.props.MercadoLivreAnuncioUpdateAction}
                        />
                      ) : (

                        <Form.Field
                          name='yield_of_sales_unit'
                          control={Input}
                          label='Rendimento da unidade'
                          placeholder='4,33 m²'
                          value={MercadoLivreAnuncioEntity.yield_of_sales_unit}
                          onChange={this.props.MercadoLivreAnuncioUpdateAction}
                        />

                      )}
                    </Form.Group>
                    <Form.Group widths='equal'>
                      <Form.TextArea name="description" fluid label='Descrição' placeholder='Dígite a descrição do produto'
                        value={MercadoLivreAnuncioEntity.description}
                        //onChange={this.props.MercadoLivreHandleDimensoesChangeAction}
                        //value={this.props.dimensoes}
                        onChange={this.props.MercadoLivreAnuncioUpdateAction}
                        rows={5}
                      />

                    </Form.Group>

                    <Form.Group widths='eleven'>


                      <div className="field"><label>&nbsp;</label>

                        <Form.Button primary

                          onClick={(e) => this.SaveAnuncio(MercadoLivreAnuncioEntity)}
                          loading={this.props.anunciosaving}
                        >Publicar</Form.Button>
                      </div>



                    </Form.Group>

                    <If test={anuncioresultado.length > 0}>
                      <Message>
                        <p>{anuncioresultado.map(d =>
                          (<p>{d.id}|{d.mensagem}</p>))}</p>
                      </Message>
                    </If>

                  </Form>

                </Segment>

              </div>
            </Grid.Column>


            <Grid.Column>



              <Segment secondary>
                {


                  <Form loading={this.props.issearching}>


                    <Message>
                      <Message.Header>Ajuste de Dimensões do Produto:</Message.Header>
                    </Message>

                    <Message>
                      <Message.Header>Exemplo:</Message.Header>
                      <p>
                        MLB857534562|243x16x16,2001#<br></br>
                        MLB857534562|243x16x16,2003#<br></br>
                        MLB857534562|243x16x16,2002#<br></br>
                        |2943x1996x9916,2001#<br></br>


                      </p>
                    </Message>

                    <Form.Group widths='equal'>

                      <Form.TextArea name="dimensoes" fluid label='Dimensões' placeholder='Dígite os pedidos marketplace'
                        //onChange={this.handleProdutos.bind(this)}
                        onChange={this.props.MercadoLivreHandleDimensoesChangeAction}
                        value={this.props.dimensoes}
                        rows={13}
                      />


                    </Form.Group>



                    <Form.Group widths='eleven'>


                      <div className="field"><label>&nbsp;</label>

                        <Form.Button primary

                          onClick={(e) => this.SaveDimensoes()}
                          loading={this.props.dimensoessaving}
                        >Alterar</Form.Button>
                      </div>



                    </Form.Group>
                    <If test={dimensoesresultado.length > 0}>
                      <Message>
                        <p>{dimensoesresultado.map(d => (<p>{d.codprod}|{d.mensagem}</p>))}</p>
                      </Message>
                    </If>



                  </Form>
                }
              </Segment>



            </Grid.Column>
          </Grid.Row>
        </Grid>


        <Segment basic textAlign='center'>
          <Divider horizontal>Envio de XML de Notas Fiscais</Divider>
          <Tab panes={[
            {
              menuItem: 'À Enviar', render: () => <Tab.Pane loading={this.state.isenviar}>
                <Button loading={this.state.loaderenviar} floated="right" onClick={() => this.onAtualizaEnviar()}>Atualizar</Button>

                <Table celled compact definition>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell />
                      <Table.HeaderCell>NF</Table.HeaderCell>
                      <Table.HeaderCell>Pedido FBits</Table.HeaderCell>
                      <Table.HeaderCell>Pedido ML</Table.HeaderCell>
                      <Table.HeaderCell>Data do Pedido</Table.HeaderCell>
                      <Table.HeaderCell>Parceiro</Table.HeaderCell>

                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {pendentes.map(item =>
                      <Table.Row>
                        <Table.Cell collapsing>
                          <Checkbox slider onChange={(e) => this.onCheckML(e, item)} checked={item.ischecked} />
                        </Table.Cell>
                        <Table.Cell>{item.nunota}</Table.Cell>
                        <Table.Cell>{item.ad_pedidofbits}</Table.Cell>
                        <Table.Cell>{item.ad_mktplsit}</Table.Cell>
                        <Table.Cell>{moment(item.dtneg).format("DD/MM/YYYY")}</Table.Cell>
                        <Table.Cell>{item.codparc} - {item.nomeparc}</Table.Cell>

                      </Table.Row>
                    )}



                  </Table.Body>

                  <Table.Footer fullWidth>
                    <Table.Row>

                      <Table.HeaderCell colSpan='6'>
                        <Button
                          floated='right'
                          icon
                          labelPosition='right'
                          primary
                          size='small'
                          loading={this.state.isenviando}
                          onClick={() => this.onEnviaNF(pendentes, this.props.login, this.props.token)}
                        >
                          <Icon name='arrow right' /> Enviar
                        </Button>
                        <Button size='small' onClick={() => this.onCheckMLAll(pendentes)}>Selecionar Todos</Button>

                      </Table.HeaderCell>
                    </Table.Row>
                  </Table.Footer>
                </Table>

              </Tab.Pane>
            },
            {
              menuItem: 'Enviados', render: () => <Tab.Pane loading={this.state.isenviados}>
                <Button loading={this.state.loaderenviados} floated="right" onClick={() => this.onAtualizaEnviados()}>Atualizar</Button>
                <Table>
                  <Table.Header>
                    <Table.Row>
                      <Table.HeaderCell>NU</Table.HeaderCell>
                      <Table.HeaderCell>NF</Table.HeaderCell>
                      <Table.HeaderCell>Pedido FBits</Table.HeaderCell>
                      <Table.HeaderCell>Pedido ML</Table.HeaderCell>
                      <Table.HeaderCell>Data do Pedido</Table.HeaderCell>
                      <Table.HeaderCell>Parceiro</Table.HeaderCell>
                      <Table.HeaderCell>Data de Envio no ML</Table.HeaderCell>
                      <Table.HeaderCell>Usuário</Table.HeaderCell>
                      <Table.HeaderCell>Status</Table.HeaderCell>
                      {
                        //01/09/2021 - desativado, pois emissao de nfe começou no sankhyaW e nao temos mais o pdf
                        //<Table.HeaderCell>NF</Table.HeaderCell>
                      }

                    </Table.Row>
                  </Table.Header>

                  <Table.Body>
                    {enviados.map(item =>
                      <Table.Row negative={(item.status == 3 ? true : false)} warning={(item.status == 1 ? true : false)} positive={(item.status == 2 ? true : false)}>

                        <Table.Cell>{item.nunota}</Table.Cell>
                        <Table.Cell>{item.numnota}</Table.Cell>
                        <Table.Cell>{item.ad_pedidofbits}</Table.Cell>
                        <Table.Cell>{item.ad_mktplsit}</Table.Cell>
                        <Table.Cell>{moment(item.dtneg).format("DD/MM/YYYY")}</Table.Cell>
                        <Table.Cell>{item.codparc} - {item.nomeparc}</Table.Cell>
                        <Table.Cell>{moment(item.dt_enviado).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                        <Table.Cell>{item.login}</Table.Cell>
                        {(item.status == 3) ?
                          (<Popup
                            trigger={<Table.Cell>{definestatus(item.status)}</Table.Cell>}
                            content={`Problema: ${item.message}`}
                            inverted
                          />)
                          :
                          (<Table.Cell>{definestatus(item.status)}</Table.Cell>)
                        }
                        {

                          /*
                            <Table.Cell>
                              <Button basic size="mini" icon onClick={(e) => this.onDownloadPDF(item.ad_pedidofbits, item.pdfdanfe)}>
                                <Icon name='file pdf outline' color="red" />
                              </Button>
                            </Table.Cell>  
                          */
                        }



                      </Table.Row>
                    )}




                  </Table.Body>
                </Table>


              </Tab.Pane>
            },
          ]} />
        </Segment>


      </div>

    )

  }

}

MercadoLivre.defaultProps = {
  codigo: ''
};
MercadoLivre.propTypes = {
  codigo: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  menu: state.LoginReducer.menu
  , login: state.LoginReducer.login
  , token: state.MercadoLivreReducer.token
  , dimensoes: state.MercadoLivreReducer.dimensoes
  , dimensoessaving: state.MercadoLivreReducer.dimensoessaving
  , dimensoesresultado: state.MercadoLivreReducer.dimensoesresultado
  , MercadoLivreAnuncioEntity: state.MercadoLivreReducer.MercadoLivreAnuncioEntity
  , category_id_list: state.MercadoLivreReducer.category_id_list
  , anunciosaving: state.MercadoLivreReducer.anunciosaving
  , anuncioresultado: state.MercadoLivreReducer.anuncioresultado
  , pendentes: state.MercadoLivreReducer.pendentes
  , enviados: state.MercadoLivreReducer.enviados
  //, consulta: state.MercadoLivreReducer.consulta
  //, issearching: state.MercadoLivreReducer.issearching
  //, parametros: state.MercadoLivreReducer.parametros


})

export default withRouter(connect(mapStateToProps, {
  mercadolivreListTokenAction
  , MercadoLivreHandleDimensoesChangeAction
  , MercadoLivreDimensoesSaveAction
  , MercadoLivreAnuncioUpdateAction
  , MercadoLivreAnunciosSaveAction

  , mercadolivreListEnviadosAction
  , mercadolivreListPendentesAction
  , mercadolivreCheckAction
  , mercadolivreNFEnviaAction
}
)(MercadoLivre));
