import sortBy from 'sort-by';

import {RoboPrecosConfigEntity} from '../entities/RoboPrecosConfigEntity';

import {
    ROBODEPRECOS_LIST_CONFIGURACOES
    , ROBODEPRECOS_QTDE_CONFIGURACOES
    , ROBODEPRECOS_LOADER_CONFIGURACOES

    , ROBODEPRECOS_LIST_MODIFICADOS
    , ROBODEPRECOS_QTDE_MODIFICADOS
    , ROBODEPRECOS_LOADER_MODIFICADOS

    , ROBODEPRECOS_LIST_MANTIDOS
    , ROBODEPRECOS_QTDE_MANTIDOS
    , ROBODEPRECOS_LOADER_MANTIDOS

    , ROBODEPRECOS_LIST_MONITORADOS
    , ROBODEPRECOS_QTDE_MONITORADOS
    , ROBODEPRECOS_LOADER_MONITORADOS

    , ROBODEPRECOS_CHANGE_CONFIGURACOES
    , ROBODEPRECOS_CLEAR_CONFIGURACOES
    , ROBODEPRECOS_UPDATE_CONFIGURACOES

    , ROBODEPRECOS_LIST_STATUS

    , ROBODEPRECOS_LIST_FORADOSLIMITES
    , ROBODEPRECOS_QTDE_FORADOSLIMITES
    , ROBODEPRECOS_LOADER_FORADOSLIMITES

    , ROBODEPRECOS_LIST_CONCORRENTES
    , ROBODEPRECOS_LIST_GERAL
    , ROBODEPRECOS_LOADER_GERAL
    , ROBODEPRECOS_LIST_PRODUTOS


} from '../actions/ActionsTypes';




const INITIAL_STATE = {
    configuracoes: []
    , loaderConfiguracoes: true
    , configuracoesQtde: 0

    , modificados: []
    , loaderModificados: true
    , modificadosQtde: 0

    , mantidos: []
    , loaderMantidos: true
    , mantidosQtde: 0

    , monitorados: []
    , loaderMonitorados: true
    , monitoradosQtde: 0
    , teste:0

    , robostatus:0
    , robodt:null

    , RoboPrecosConfigEntity

    , foradoslimites: []
    , loaderForadoslimites: true
    , foradoslimitesQtde: 0
    

    
    , geral: []
    , vermelho:[]
    , amarelo:[]
    , azul:[]
    , concorrentes: []
    , produtos: []
    , loadergeral: true
    
}

export default (state = INITIAL_STATE, action) => {
 //console.log('aki reducer:'+action.type)
    switch (action.type) {

        
        
        case ROBODEPRECOS_LIST_CONCORRENTES:
            return {...state, concorrentes: action.payload}

        case ROBODEPRECOS_LIST_GERAL:
            return {...state, geral: action.payload, vermelho: action.vermelho, amarelo: action.amarelo, azul: action.azul}

        case ROBODEPRECOS_LOADER_GERAL:
            return {...state, loadergeral: action.payload}

        case ROBODEPRECOS_LIST_PRODUTOS:
            return {...state, produtos: action.payload}




        case ROBODEPRECOS_LIST_STATUS:
            return { ...state, robostatus: action.robostatus, robodt: action.robodt }

        
        case ROBODEPRECOS_LIST_CONFIGURACOES:
            return { ...state, configuracoes: action.payload }

        case ROBODEPRECOS_QTDE_CONFIGURACOES:
            return { ...state, configuracoesQtde: action.payload }

        case ROBODEPRECOS_LOADER_CONFIGURACOES:
            return { ...state, loaderConfiguracoes: action.payload }


        case ROBODEPRECOS_LIST_MODIFICADOS:
            return { ...state, modificados: action.payload }

        case ROBODEPRECOS_QTDE_MODIFICADOS:
            return { ...state, modificadosQtde: action.payload }

        case ROBODEPRECOS_LOADER_MODIFICADOS:
            return { ...state, loaderModificados: action.payload }


        case ROBODEPRECOS_LIST_FORADOSLIMITES:
            return { ...state, foradoslimites: action.payload }

        case ROBODEPRECOS_QTDE_FORADOSLIMITES:
            return { ...state, foradoslimitesQtde: action.payload }

        case ROBODEPRECOS_LOADER_FORADOSLIMITES:
            return { ...state, loaderForadoslimites: action.payload }
        

        case ROBODEPRECOS_LIST_MANTIDOS:
            return { ...state, mantidos: action.payload }

        case ROBODEPRECOS_QTDE_MANTIDOS:
            return { ...state, mantidosQtde: action.payload }

        case ROBODEPRECOS_LOADER_MANTIDOS:
            return { ...state, loaderMantidos: action.payload }


        case ROBODEPRECOS_LIST_MONITORADOS:
            return { ...state, monitorados: action.payload }

        case ROBODEPRECOS_QTDE_MONITORADOS:
            return { ...state, monitoradosQtde: action.payload }

        case ROBODEPRECOS_LOADER_MONITORADOS:
            return { ...state, loaderMonitorados: action.payload }

        case ROBODEPRECOS_CHANGE_CONFIGURACOES:
            return { ...state, RoboPrecosConfigEntity: { ...state.RoboPrecosConfigEntity, [action.field]: action.payload } }
        
        case ROBODEPRECOS_CLEAR_CONFIGURACOES:
            return { ...state, RoboPrecosConfigEntity: action.payload } 

        case ROBODEPRECOS_UPDATE_CONFIGURACOES:
            return { 
                ...state, 
                configuracoes: state.configuracoes.map(
                    (config, i) => config.id === action.id && config.grupo===action.grupo ? {...config, pctminimo: action.pctminimo, pctmaximo: action.pctmaximo, pctdiferenca: action.pctdiferenca}
                                            : config
                )
            }


   


        default:
            return state;
    }
}