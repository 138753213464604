import { Button, Table, Popup, Icon, Checkbox } from 'semantic-ui-react';
import If from '../../../utils/If';
import DescFbitsIndividual from './DescFbitsIndividual';


function DescFbitsTabela({
    descontofbitsstatus,
    descontofbitsdt,
    libmarketplace,
    updateLibMarketPlace,
    setSuccessMessage,
    setErrors
}) {



    return (
        <Table stackable>
            <Table.Header>
                <Table.Row>
                    <Table.HeaderCell>Processo</Table.HeaderCell>
                    <Table.HeaderCell>Última Execução</Table.HeaderCell>
                    <Table.HeaderCell textAlign='right'>Ação</Table.HeaderCell>
                </Table.Row>
            </Table.Header>
            <Table.Body>
                <Table.Row>
                    <Table.Cell>Desconto Pedido FBits</Table.Cell>
                    <Table.Cell>{(descontofbitsstatus === 0 ? descontofbitsdt : "-----------")}</Table.Cell>
                    <Table.Cell textAlign='right'>
                        <If test={descontofbitsstatus === 1 || descontofbitsstatus === 2}>
                            <Popup
                                trigger={<Icon loading name='cog' color='blue' size='large' />}
                                content='o Robô está em processamento...'
                                size='tiny'
                            />
                        </If>
                        <If test={descontofbitsstatus === 0}>
                            <Popup
                                trigger={<Icon name='cog' color='red' size='large' />}
                                content='o Robô está parado.'
                                size='tiny'
                            />
                            <Button basic color='blue' content='Iniciar' size='mini' onClick={(e) => this.onUpdateStatus()} />
                        </If>
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <Table.Cell>Habilitar Sankhya W para liberações Marketplaces</Table.Cell>
                    <Table.Cell></Table.Cell>
                    <Table.Cell textAlign='right'>
                        <Checkbox toggle
                            checked={libmarketplace}
                            onChange={updateLibMarketPlace}
                        />
                    </Table.Cell>
                </Table.Row>
                <Table.Row>
                    <DescFbitsIndividual setSuccessMessage={setSuccessMessage} setErrors={setErrors} />
                </Table.Row>
            </Table.Body>
        </Table>
    )
}

export default DescFbitsTabela