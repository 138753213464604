import sortBy from 'sort-by';

import {

    ORDEMCARGA_LIST
    , ORDEMCARGA_LIST_NAOENTREGUE
    , ORDEMCARGA_QTDE_NAOENTREGUE
    , ORDEMCARGA_CHKNAOENTREGUE_REMOVE
    , ORDEMCARGA_CHKNAOENTREGUE_INSERT
    , ORDEMCARGA_CHKNAOENTREGUE_UPDATE
    , ORDEMCARGA_CHKNAOENTREGUE_CLEAR
    , ORDEMCARGA_LOADER_NAOENTREGUE
    , ORDEMCARGA_LOG_INSERT
    , ORDEMCARGA_PEDIDOMODAL_UPDATE
    , ORDEMCARGA_LIST_MOTIVOS

    , ORDEMCARGA_LIST_ENTREGUE
    , ORDEMCARGA_QTDE_ENTREGUE
    , ORDEMCARGA_LOADER_ENTREGUE

    , ORDEMCARGA_LIST_PEDIDOSNAOPADOS
    , ORDEMCARGA_QTDE_PEDIDOSNAOPADOS
    , ORDEMCARGA_LOADER_PEDIDOSNAOPADOS
    , ORDEMCARGA_LOADER_PEDIDOSNAOPADOS_LIGAR
    , ORDEMCARGA_COMPLEMENTO_PEDIDOSNAOPADOS_LIGAR
    , ORDEMCARGA_MODAL_PEDIDOSNAOPADOS_LIGAR

    , ORDEMCARGA_ISSAVING_FRETE_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_UPDATE_FRETE_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_ISEDITING_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_EMANDAMENTO_ISENTREGASAVING
    , ORDEMCARGA_EMANDAMENTO_REMOVE
    , ORDEMCARGA_LIST_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_QTDE_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_LOADER_PEDIDOSPAGOSNAOFATURADOS
    , ORDEMCARGA_LOADER_PEDIDOSEMANDAMENTO_LIGAR
    , ORDEMCARGA_COMPLEMENTO_PEDIDOSEMANDAMENTO_LIGAR
    , ORDEMCARGA_MODAL_PEDIDOSEMANDAMENTO_LIGAR


    , ORDEMCARGA_LIST_PEDIDOSFATURADOSSEMROTA
    , ORDEMCARGA_QTDE_PEDIDOSFATURADOSSEMROTA
    , ORDEMCARGA_LOADER_PEDIDOSFATURADOSSEMROTA

    , ORDEMCARGA_LIST_EMTRANSPORTADORA
    , ORDEMCARGA_QTDE_EMTRANSPORTADORA
    , ORDEMCARGA_CHKEMTRANSPORTADORA_REMOVE
    , ORDEMCARGA_CHKEMTRANSPORTADORA_INSERT
    , ORDEMCARGA_CHKEMTRANSPORTADORA_UPDATE
    , ORDEMCARGA_CHKEMTRANSPORTADORA_CLEAR
    , ORDEMCARGA_LOADER_EMTRANSPORTADORA
    , ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT
    , ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE
    , DESPESASDEVIAGENS_CHANGE_SAIDAS_AJUDANTEDOIS

    , ORDEMCARGA_LOADER_ISUPDATING_DEVOLUCAO
    , ORDEMCARGA_REMOVEITEM_DEVOLUCAO
    , ORDEMCARGA_REMOVE_DEVOLUCAO
    , ORDEMCARGA_QTDE_DEV_UPDATE


    , ORDEMCARGA_LOADER_ISUPDATING_NAOENTREGUE
    , ORDEMCARGA_REMOVE_NAOENTREGUE
    , ORDEMCARGA_REMOVEITEM_NAOENTREGUE
    , ORDEMCARGA_QTDE_NAOENTREGUE_UPDATE


    , ORDEMCARGA_LOADER_ISUPDATING_DEVTRANSP
    , ORDEMCARGA_REMOVE_DEVTRANSP
    , ORDEMCARGA_REMOVEITEM_DEVTRANSP
    , ORDEMCARGA_QTDE_DEVTRANSP_UPDATE

    , ORDEMCARGA_LOADER_ISUPDATING_DEVENTREGUE
    , ORDEMCARGA_REMOVE_DEVENTREGUE
    , ORDEMCARGA_REMOVEITEM_DEVENTREGUE
    , ORDEMCARGA_QTDE_DEVENTREGUE_UPDATE

    , ORDEMCARGA_LIST_DEVOLUCAO
    , ORDEMCARGA_QTDE_DEVOLUCAO_UPDATE
    , ORDEMCARGA_PEDIDOMODALDEV_UPDATE
    , ORDEMCARGA_LOADER_DEVOLUCAO

    , ORDEMCARGA_LIST_ASAIR
    , ORDEMCARGA_QTDE_ASAIR
    , ORDEMCARGA_LOADER_ASAIR
    , ORDEMCARGA_ASAIR_CHKEMROTA_INSERT
    , ORDEMCARGA_ASAIR_CHKEMROTA_REMOVE
    , ORDEMCARGA_ASAIR_CHKEMROTA_UPDATE
    , ORDEMCARGA_ASAIR_CHKEMROTA_CLEAR

    //FRETE a sair
    , ORDEMCARGA_ASAIR_FRETEOPENCLOSE_UPDATE
    , ORDEMCARGA_ASAIR_FRETECHECK_UPDATE
    , ORDEMCARGA_ASAIR_FRETEISSAVING_UPDATE
    , ORDEMCARGA_QTDE_ASAIR_DEVOLUCAO
    , ORDEMCARGA_LOADER_ASAIR_DEVOLUCAO_ISUPDATING
    , ORDEMCARGA_REMOVE_ASAIR_DEVOLUCAO
    , ORDEMCARGA_REMOVEITEM_ASAIR_DEVOLUCAO



    //FRETE EM ROTA
    , ORDEMCARGA_EMROTA_FRETEOPENCLOSE_UPDATE
    , ORDEMCARGA_EMROTA_FRETECHECK_UPDATE
    , ORDEMCARGA_EMROTA_FRETEISSAVING_UPDATE
    , ORDEMCARGA_EMROTA_ASAIR_ISUPDATING

    , ORDEMCARGA_EMROTA_LIST_APPENTREGA
    , ORDEMCARGA_EMROTA_LOADER_APPENTREGA
    , ORDEMCARGA_EMROTA_MODAL_APPENTREGA


    //FRETE EM TRANSPORTADORA
    , ORDEMCARGA_EMTRANSPORTADORA_FRETEOPENCLOSE_UPDATE
    , ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE
    , ORDEMCARGA_EMTRANSPORTADORA_FRETEISSAVING_UPDATE

    //A SAIR MOTIVO N SAIU
    , ORDEMCARGA_PEDIDOMODALNSAIU_UPDATE

    , ORDEMCARGA_EMROTA_CHANGE_GERAL

    , ORDEMCARGA_EMTRANSPORTADORA_CHANGE_GERAL
    , ORDEMCARGA_DEVOLUCAO_CHANGE_GERAL

    , ORDEMCARGA_LIST_VENDEDORES
    , ORDEMCARGA_CHECK_VENDEDORES

    , ORDEMCARGA_LIST_AUTO
    , ORDEMCARGA_QTDE_AUTO
    , ORDEMCARGA_LOADER_AUTO
    , ORDEMCARGA_LOADER_ISUPDATING_AUTO
    , ORDEMCARGA_REMOVE_AUTO

} from '../actions/ActionsTypes';

import { ordemCargaListAction } from '../actions/OrdemCargaActions';

const INITIAL_STATE = {
    //Em Rota
    cargasNaoEntregue: []
    , cargasNaoEntregueQtde: 0
    , chkNaoEntregue: []
    , logScreen: []
    , loaderNaoEntregue: true

    //Entregues
    , cargasEntregue: []
    , chkEntregue: []
    , loaderEntregue: true
    , cargasEntregueQtde: 0

    //Não Pagos
    , pedidosNaoPagos: []
    , loaderPedidosNaoPagos: true
    , pedidosNaoPagosQtde: 0

    //A Faturar
    , pedidosPagosNaoFaturados: []
    , loaderPedidosPagosNaoFaturados: true
    , pedidosPagosNaoFaturadosQtde: 0

    //Aguardando
    , pedidosFaturadosSemRota: []
    , loaderPedidosFaturadosSemRota: true
    , pedidosFaturadosSemRotaQtde: 0
    , motivos: []

    //Transportadoras
    , chkEmTransportadora: []
    , cargasEmTransportadoraQtde: 0
    , cargasEmTransportadora: []
    , loaderEmTransportadora: true
    , logEmTransportadora: []


    //Devolucao
    , devolucao: []
    , devolucaoqtde: 0
    , loaderDevolucao: true

    //A Sair
    , cargasASair: []
    , cargasASairQtde: 0
    , loaderASair: true
    , chkEmRota: []

    , vendedores: []
    , vendedorselecionado: ""


    , cargasAuto: []
    , loaderAuto: true
    , cargasAutoQtde: 0

    , appentrega: []
    , loaderappentrega: false
    , appentregamodal: false


}


let updatingdevolucao = (carga, nunota, pedidofbits) => {

    let pedidos = carga.pedidos

    pedidos.map(pedido => {
        if (pedido.nunota == nunota && pedido.pedidofbits == pedidofbits) {
            pedido.isupdatingdevolucao = true
        }
    })

    carga.pedidos = pedidos

    return carga

}

let updatingauto = (pedido, nunota, pedidofbits) => {

    let ped = pedido

    ped.isupdating = true

    return ped

}

let updatingasair = (carga, nunota, pedidofbits) => {

    let pedidos = carga.pedidos

    pedidos.map(pedido => {
        if (pedido.nunota == nunota && pedido.pedidofbits == pedidofbits) {
            pedido.isupdatingasair = true
        }
    })

    carga.pedidos = pedidos

    return carga

}



let updatinggeralemrota = (carga, nunota, pedidofbits, campo, payload) => {

    let pedidos = carga.pedidos

    pedidos.map(pedido => {
        if (pedido.nunota == nunota && pedido.pedidofbits == pedidofbits) {
            pedido[campo] = payload
        }
    })

    carga.pedidos = pedidos

    return carga

}


let removedevolucao = (carga, nunota, pedidofbits) => {

    let pedidos = carga.pedidos.filter(c => nunota !== c.nunota)
    carga.pedidos = pedidos

    return carga

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {


        case ORDEMCARGA_LIST_VENDEDORES:
            return { ...state, vendedores: action.payload }

        case ORDEMCARGA_CHECK_VENDEDORES:
            return { ...state, vendedorselecionado: action.payload }


        //DEVOLUÇÃO TRANSPORTADORA
        case ORDEMCARGA_LOADER_ISUPDATING_DEVTRANSP:
            return {
                ...state,
                cargasEmTransportadora: state.cargasEmTransportadora.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatingdevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }
        case ORDEMCARGA_REMOVEITEM_DEVTRANSP:
            return {
                ...state,
                cargasEmTransportadora: state.cargasEmTransportadora.map(
                    (carga, i) => carga.id === action.ordemcarga ? removedevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }
        case ORDEMCARGA_QTDE_DEVTRANSP_UPDATE:
            return { ...state, cargasEmTransportadoraQtde: state.cargasEmTransportadoraQtde - action.payload }

        case ORDEMCARGA_REMOVE_DEVTRANSP:
            return { ...state, cargasEmTransportadora: [...state.cargasEmTransportadora.filter(c => action.ordemcarga !== c.id)] }


        //DEVOLUÇÃO ENTREGUES
        case ORDEMCARGA_LOADER_ISUPDATING_DEVENTREGUE:
            /*return {
                ...state,
                cargasEntregue: state.cargasEntregue.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatingdevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }*/

            return {
                ...state,
                cargasEntregue: state.cargasEntregue.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits ?
                        { ...pedido, isupdatingdevolucao: true }
                        : pedido
                )
            }


        case ORDEMCARGA_REMOVEITEM_DEVENTREGUE:
            return { ...state, cargasEntregue: [...state.cargasEntregue.filter(c => action.pedidofbits !== c.pedidofbits)] }


        case ORDEMCARGA_QTDE_DEVENTREGUE_UPDATE:
            return { ...state, cargasEntregueQtde: state.cargasEntregueQtde - action.payload }

        /*case ORDEMCARGA_REMOVE_DEVENTREGUE:
            return { ...state, cargasEntregue: [...state.cargasEntregue.filter(c => action.ordemcarga !== c.id)] }
        */






        case ORDEMCARGA_LIST_DEVOLUCAO:
            return { ...state, devolucao: action.payload }

        case ORDEMCARGA_QTDE_DEVOLUCAO_UPDATE:
            return { ...state, devolucaoqtde: action.payload }

        case ORDEMCARGA_LOADER_DEVOLUCAO:
            return { ...state, loaderDevolucao: action.payload }



        case ORDEMCARGA_LOADER_ISUPDATING_DEVOLUCAO:
            return {
                ...state,
                devolucao: state.devolucao.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatingdevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }

        case ORDEMCARGA_REMOVEITEM_DEVOLUCAO:
            return {
                ...state,
                devolucao: state.devolucao.map(
                    (carga, i) => carga.id === action.ordemcarga ? removedevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }

        case ORDEMCARGA_REMOVE_DEVOLUCAO:
            return { ...state, devolucao: [...state.devolucao.filter(c => action.ordemcarga !== c.id)] }



        case ORDEMCARGA_QTDE_DEV_UPDATE:
            return { ...state, devolucaoqtde: state.devolucaoqtde - action.payload }



        case ORDEMCARGA_LOADER_ISUPDATING_NAOENTREGUE:
            return {
                ...state,
                cargasNaoEntregue: state.cargasNaoEntregue.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatingdevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }


        case ORDEMCARGA_REMOVE_NAOENTREGUE:
            return { ...state, cargasNaoEntregue: [...state.cargasNaoEntregue.filter(c => action.ordemcarga !== c.id)] }


        case ORDEMCARGA_REMOVEITEM_NAOENTREGUE:
            return {
                ...state,
                cargasNaoEntregue: state.cargasNaoEntregue.map(
                    (carga, i) => carga.id === action.ordemcarga ? removedevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }


        //A SAIR DEVOLUCAO------------------------------
        case ORDEMCARGA_QTDE_ASAIR_DEVOLUCAO:
            return { ...state, cargasASairQtde: state.cargasASairQtde - action.payload }



        case ORDEMCARGA_LOADER_ASAIR_DEVOLUCAO_ISUPDATING:
            return {
                ...state,
                cargasASair: state.cargasASair.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatingdevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }
        case ORDEMCARGA_REMOVE_ASAIR_DEVOLUCAO:
            return { ...state, cargasASair: [...state.cargasASair.filter(c => action.ordemcarga !== c.id)] }


        case ORDEMCARGA_REMOVEITEM_ASAIR_DEVOLUCAO:
            return {
                ...state,
                cargasNaoEntregue: state.cargasASair.map(
                    (carga, i) => carga.id === action.ordemcarga ? removedevolucao(carga, action.nunota, action.pedidofbits) : carga
                )
            }
        //-----------------------------------------------


        //desativado
        case ORDEMCARGA_LIST:
            return { ...state, cargasGeral: action.payload }

        case ORDEMCARGA_LIST_NAOENTREGUE:
            return { ...state, cargasNaoEntregue: action.payload }

        case ORDEMCARGA_QTDE_NAOENTREGUE:
            return { ...state, cargasNaoEntregueQtde: action.payload }

        case ORDEMCARGA_QTDE_NAOENTREGUE_UPDATE:
            return { ...state, cargasNaoEntregueQtde: state.cargasNaoEntregueQtde - action.payload }

        case ORDEMCARGA_CHKNAOENTREGUE_REMOVE:
            return { ...state, chkNaoEntregue: [...state.chkNaoEntregue.filter(c => action.payload.numnota !== c.numnota)] }

        case ORDEMCARGA_CHKNAOENTREGUE_INSERT:
            return { ...state, chkNaoEntregue: [...state.chkNaoEntregue, action.payload] }

        case ORDEMCARGA_CHKNAOENTREGUE_UPDATE:
            return { ...state, chkNaoEntregue: state.chkNaoEntregue.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }

        case ORDEMCARGA_CHKNAOENTREGUE_CLEAR:
            return { ...state, chkNaoEntregue: [] }

        case ORDEMCARGA_LOADER_NAOENTREGUE:
            return { ...state, loaderNaoEntregue: action.payload }

        case ORDEMCARGA_LOG_INSERT:
            return { ...state, logScreen: action.payload }

        case ORDEMCARGA_PEDIDOMODAL_UPDATE:
            return { ...state, cargasNaoEntregue: state.cargasNaoEntregue.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }

        case ORDEMCARGA_PEDIDOMODALDEV_UPDATE:
            return { ...state, devolucao: state.devolucao.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }

        case ORDEMCARGA_LIST_MOTIVOS:
            return { ...state, motivos: action.payload }



        case ORDEMCARGA_LIST_ENTREGUE:
            return { ...state, cargasEntregue: action.payload }

        case ORDEMCARGA_LOADER_ENTREGUE:
            return { ...state, loaderEntregue: action.payload }

        case ORDEMCARGA_QTDE_ENTREGUE:
            return { ...state, cargasEntregueQtde: action.payload }



        case ORDEMCARGA_LIST_PEDIDOSNAOPADOS:
            return { ...state, pedidosNaoPagos: action.payload };

        case ORDEMCARGA_QTDE_PEDIDOSNAOPADOS:
            return { ...state, pedidosNaoPagosQtde: action.payload };

        case ORDEMCARGA_LOADER_PEDIDOSNAOPADOS:
            return { ...state, loaderPedidosNaoPagos: action.payload };

        case ORDEMCARGA_LOADER_PEDIDOSNAOPADOS_LIGAR:
            return {
                ...state,
                pedidosNaoPagos: state.pedidosNaoPagos.map(
                    (pedido, i) => pedido.nunota === action.nunota ?
                        {
                            ...pedido, isloading_ligar: action.payload
                            , ligar: action.ligar
                            , ligarcomplemento: action.ligarcomplemento
                        }
                        : pedido
                )
            }

        case ORDEMCARGA_COMPLEMENTO_PEDIDOSNAOPADOS_LIGAR:
            return {
                ...state,
                pedidosNaoPagos: state.pedidosNaoPagos.map(
                    (pedido, i) => pedido.nunota === action.nunota ?
                        {
                            ...pedido,
                            ligarcomplemento: action.payload
                        }
                        : pedido
                )
            }

        case ORDEMCARGA_MODAL_PEDIDOSNAOPADOS_LIGAR:
            return {
                ...state,
                pedidosNaoPagos: state.pedidosNaoPagos.map(
                    (pedido, i) => pedido.nunota === action.nunota ?
                        {
                            ...pedido, modal_ligar: action.payload
                        }
                        : pedido
                )
            }





        //Em Andamento 
        //##michel


        case ORDEMCARGA_ISSAVING_FRETE_PEDIDOSPAGOSNAOFATURADOS:
            return {
                ...state,
                pedidosPagosNaoFaturados: state.pedidosPagosNaoFaturados.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits ?
                        { ...pedido, isfretecontextsaving: action.acao }
                        : pedido
                )
            }

        case ORDEMCARGA_ISEDITING_PEDIDOSPAGOSNAOFATURADOS:
            return {
                ...state,
                pedidosPagosNaoFaturados: state.pedidosPagosNaoFaturados.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits ?
                        { ...pedido, isfretecontextediting: action.acao }
                        : pedido
                )
            }

        case ORDEMCARGA_UPDATE_FRETE_PEDIDOSPAGOSNAOFATURADOS:
            return {
                ...state,
                pedidosPagosNaoFaturados: state.pedidosPagosNaoFaturados.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits ?
                        { ...pedido, fretecontext: action.texto }
                        : pedido
                )
            }

        case ORDEMCARGA_LIST_PEDIDOSPAGOSNAOFATURADOS:
            return { ...state, pedidosPagosNaoFaturados: action.payload }

        case ORDEMCARGA_QTDE_PEDIDOSPAGOSNAOFATURADOS:
            return { ...state, pedidosPagosNaoFaturadosQtde: action.payload }

        case ORDEMCARGA_LOADER_PEDIDOSPAGOSNAOFATURADOS:
            return { ...state, loaderPedidosPagosNaoFaturados: action.payload }

        case ORDEMCARGA_EMANDAMENTO_ISENTREGASAVING:
            return {
                ...state,
                pedidosPagosNaoFaturados: state.pedidosPagosNaoFaturados.map(
                    (pedido, i) => pedido.pedidofbits === action.pedidofbits ?
                        { ...pedido, isentregasaving: action.payload }
                        : pedido
                )
            }

        case ORDEMCARGA_EMANDAMENTO_REMOVE:
            return {
                ...state,
                pedidosPagosNaoFaturados: [...state.pedidosPagosNaoFaturados.filter(d => action.payload !== d.pedidofbits)]
            }

        case ORDEMCARGA_LOADER_PEDIDOSEMANDAMENTO_LIGAR:
            return {
                ...state,
                pedidosPagosNaoFaturados: state.pedidosPagosNaoFaturados.map(
                    (pedido, i) => pedido.nunota === action.nunota ?
                        {
                            ...pedido, isloading_ligar: action.payload
                            , ligar: action.ligar
                            , ligarcomplemento: action.ligarcomplemento
                        }
                        : pedido
                )
            }


        case ORDEMCARGA_COMPLEMENTO_PEDIDOSEMANDAMENTO_LIGAR:
            return {
                ...state,
                pedidosPagosNaoFaturados: state.pedidosPagosNaoFaturados.map(
                    (pedido, i) => pedido.nunota === action.nunota ?
                        {
                            ...pedido,
                            ligarcomplemento: action.payload
                        }
                        : pedido
                )
            }

        case ORDEMCARGA_MODAL_PEDIDOSEMANDAMENTO_LIGAR:
            return {
                ...state,
                pedidosPagosNaoFaturados: state.pedidosPagosNaoFaturados.map(
                    (pedido, i) => pedido.nunota === action.nunota ?
                        {
                            ...pedido, modal_ligar: action.payload
                        }
                        : pedido
                )
            }






        case ORDEMCARGA_LIST_PEDIDOSFATURADOSSEMROTA:
            return { ...state, pedidosFaturadosSemRota: action.payload }

        case ORDEMCARGA_QTDE_PEDIDOSFATURADOSSEMROTA:
            return { ...state, pedidosFaturadosSemRotaQtde: action.payload }

        case ORDEMCARGA_LOADER_PEDIDOSFATURADOSSEMROTA:
            return { ...state, loaderPedidosFaturadosSemRota: action.payload }



        //EM TRANSPORTADORA--------------------------------------------------------
        case ORDEMCARGA_LIST_EMTRANSPORTADORA:
            return { ...state, cargasEmTransportadora: action.payload }

        case ORDEMCARGA_QTDE_EMTRANSPORTADORA:
            return { ...state, cargasEmTransportadoraQtde: action.payload }

        case ORDEMCARGA_CHKEMTRANSPORTADORA_REMOVE:
            return { ...state, chkEmTransportadora: [...state.chkEmTransportadora.filter(c => action.payload.numnota !== c.numnota)] }

        case ORDEMCARGA_CHKEMTRANSPORTADORA_INSERT:
            return { ...state, chkEmTransportadora: [...state.chkEmTransportadora, action.payload] }

        case ORDEMCARGA_CHKEMTRANSPORTADORA_UPDATE:
            return { ...state, chkEmTransportadora: state.chkEmTransportadora.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }

        case ORDEMCARGA_CHKEMTRANSPORTADORA_CLEAR:
            return { ...state, chkEmTransportadora: [] }

        case ORDEMCARGA_LOADER_EMTRANSPORTADORA:
            return { ...state, loaderEmTransportadora: action.payload }

        case ORDEMCARGA_LOGEMTRANSPORTADORA_INSERT:
            return { ...state, logEmTransportadora: action.payload }

        case ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE:
            return { ...state, cargasEmTransportadora: state.cargasEmTransportadora.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }
        //------------------------------------------------------------------------


        case ORDEMCARGA_LIST_ASAIR:
            return { ...state, cargasASair: action.payload }

        case ORDEMCARGA_QTDE_ASAIR:
            return { ...state, cargasASairQtde: action.payload }

        case ORDEMCARGA_LOADER_ASAIR:
            return { ...state, loaderASair: action.payload }

        case ORDEMCARGA_ASAIR_CHKEMROTA_REMOVE:
            return { ...state, chkEmRota: [...state.chkEmRota.filter(c => action.payload.numnota !== c.numnota)] }

        case ORDEMCARGA_ASAIR_CHKEMROTA_INSERT:
            return { ...state, chkEmRota: [...state.chkEmRota, action.payload] }

        case ORDEMCARGA_ASAIR_CHKEMROTA_UPDATE:
            return { ...state, chkEmRota: state.chkEmRota.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }

        case ORDEMCARGA_ASAIR_CHKEMROTA_CLEAR:
            return { ...state, chkEmRota: [] }

        //FRETE A SAIR
        case ORDEMCARGA_ASAIR_FRETEOPENCLOSE_UPDATE:
            return {
                ...state,
                cargasASair: state.cargasASair.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, freteopen: action.payload }
                        : carga
                )
            }

        case ORDEMCARGA_ASAIR_FRETECHECK_UPDATE:
            return {
                ...state,
                cargasASair: state.cargasASair.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, fretecheck: action.payload }
                        : carga
                )
            }
        case ORDEMCARGA_ASAIR_FRETEISSAVING_UPDATE:
            return {
                ...state,
                cargasASair: state.cargasASair.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, isfretesaving: action.payload }
                        : carga
                )
            }






        //FRETE EM ROTA
        case ORDEMCARGA_EMROTA_FRETEOPENCLOSE_UPDATE:
            return {
                ...state,
                cargasNaoEntregue: state.cargasNaoEntregue.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, freteopen: action.payload }
                        : carga
                )
            }

        case ORDEMCARGA_EMROTA_FRETECHECK_UPDATE:
            return {
                ...state,
                cargasNaoEntregue: state.cargasNaoEntregue.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, fretecheck: action.payload }
                        : carga
                )
            }
        case ORDEMCARGA_EMROTA_FRETEISSAVING_UPDATE:
            return {
                ...state,
                cargasNaoEntregue: state.cargasNaoEntregue.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, isfretesaving: action.payload }
                        : carga
                )
            }


        case ORDEMCARGA_EMROTA_ASAIR_ISUPDATING:
            return {
                ...state,
                cargasNaoEntregue: state.cargasNaoEntregue.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatingasair(carga, action.nunota, action.pedidofbits) : carga
                )
            }





        //FRETE EM TRANSPORTADORA
        case ORDEMCARGA_EMTRANSPORTADORA_FRETEOPENCLOSE_UPDATE:
            return {
                ...state,
                cargasEmTransportadora: state.cargasEmTransportadora.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, freteopen: action.payload }
                        : carga
                )
            }

        case ORDEMCARGA_EMTRANSPORTADORA_FRETECHECK_UPDATE:
            return {
                ...state,
                cargasEmTransportadora: state.cargasEmTransportadora.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, fretecheck: action.payload }
                        : carga
                )
            }
        case ORDEMCARGA_EMTRANSPORTADORA_FRETEISSAVING_UPDATE:
            return {
                ...state,
                cargasEmTransportadora: state.cargasEmTransportadora.map(
                    (carga, i) => carga.id === action.id ?
                        { ...carga, isfretesaving: action.payload }
                        : carga
                )
            }


        //A SAIR - N SAIU
        case ORDEMCARGA_PEDIDOMODALNSAIU_UPDATE:
            return { ...state, cargasASair: state.cargasASair.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }



        case ORDEMCARGA_EMROTA_CHANGE_GERAL:
            return {
                ...state,
                cargasNaoEntregue: state.cargasNaoEntregue.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatinggeralemrota(carga, action.nunota, action.pedidofbits, action.campo, action.payload) : carga
                )
            }


        case ORDEMCARGA_EMTRANSPORTADORA_CHANGE_GERAL:
            return {
                ...state,
                cargasEmTransportadora: state.cargasEmTransportadora.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatinggeralemrota(carga, action.nunota, action.pedidofbits, action.campo, action.payload) : carga
                )
            }

        case ORDEMCARGA_DEVOLUCAO_CHANGE_GERAL:
            return {
                ...state,
                devolucao: state.devolucao.map(
                    (carga, i) => carga.id === action.ordemcarga ? updatinggeralemrota(carga, action.nunota, action.pedidofbits, action.campo, action.payload) : carga
                )
            }






        case ORDEMCARGA_LIST_AUTO:
            return { ...state, cargasAuto: action.payload }

        case ORDEMCARGA_LOADER_AUTO:
            return { ...state, loaderAuto: action.payload }

        case ORDEMCARGA_QTDE_AUTO:
            return { ...state, cargasAutoQtde: action.payload }

        case ORDEMCARGA_LOADER_ISUPDATING_AUTO:
            return {
                ...state,
                cargasAuto: state.cargasAuto.map(
                    (item, i) => item.pedidofbits === action.pedidofbits && item.nunota === action.nunota ? updatingauto(item, action.nunota, action.pedidofbits) : item
                )
            }

        case ORDEMCARGA_REMOVE_AUTO:
            return { ...state, cargasAuto: [...state.cargasAuto.filter(c => action.pedidofbits !== c.pedidofbits && action.nunota !== c.nunota)] }



        case ORDEMCARGA_EMROTA_LIST_APPENTREGA:
            return { ...state, appentrega: action.payload }

        case ORDEMCARGA_EMROTA_LOADER_APPENTREGA:
            return { ...state, loaderappentrega: action.payload }

        case ORDEMCARGA_EMROTA_MODAL_APPENTREGA:
            return { ...state, appentregamodal: action.payload }


            

        default:
            return state;
    }
}