import {
    AGRUPADOR_LIST
    , AGRUPADOR_ISSEARCHING
    , AGRUPADOR_UPDATE_PARAMETROS
    , AGRUPADOR_ISSAVING
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';



export const agrupadorHandleChangeAction = (event) => {
    return {
        type: AGRUPADOR_UPDATE_PARAMETROS
        , payload: event.target.value
    }
}

export const agrupadorListAction = (codprod) => (dispatch) =>
    new Promise(function (resolve, reject) {

        if (codprod === '') {
            codprod = -1
        }

        dispatch({ type: AGRUPADOR_ISSEARCHING, payload: true })

        //console.log("###ENTIDADE")
        //console.log(entidade)
        Api.getAllAgrupador(codprod).then(dados => {
            let produtos = dados.produtos
            if (dados.produtos.length > 0) {

                /*let Cores = []
                for(let item of dados.produtos){
                    Cores.push({cor:item.cor})
                }
                Cores = [...new Set(Cores.map(o => JSON.stringify(o)))]
                Cores = Cores.map(o => JSON.parse(o))
                console.log(Cores)*/

                for (let item of dados.produtos) {
                    if (produtos.filter(d => d.cor === item.cor).length > 1) {
                        item.repetido = true
                    } else {
                        item.repetido = false
                    }
                }




                dispatch({ type: AGRUPADOR_LIST, produtos: dados.produtos, codprod: dados.codprod, agrupador: dados.agrupador })
            } else {
                dispatch({ type: AGRUPADOR_LIST, produtos: [], codprod: dados.codprod, agrupador: dados.agrupador })
            }


            dispatch({ type: AGRUPADOR_ISSEARCHING, payload: false })
            resolve([])
        })
    }
    )


export const agrupadorSaveAction = (parametros) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: AGRUPADOR_ISSAVING, payload: true })
        Api.postAgrupador({ parametros: parametros }).then(dados => {
            dispatch({ type: AGRUPADOR_ISSAVING, payload: false })
            resolve(dados)
        })
    }
    )








