import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Segment, List, Image, Tab, Checkbox, Label, Popup, Icon } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    font-size: .8em;
}
`

class ListForaDosLimites extends Component {

    constructor(props) {
        moment.locale('pt-br');
        super(props);
      }

    componentDidMount() {

    }

    render() {
        let { dataList, dataQtde, loader 
        } = this.props;

        return (
            <Tab.Pane loading={loader}>
                        <StyledTable celled id="ListFaturadosSemRota" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    
                                    <Table.HeaderCell width="0">SKU</Table.HeaderCell>
                                    <Table.HeaderCell width="6">Produto</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Concorrente</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Preço Concorrente</Table.HeaderCell>
                                     <Table.HeaderCell width="2">Preço Desejado</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Preço Definido</Table.HeaderCell>
                                    <Table.HeaderCell width="1">PCT Dif.</Table.HeaderCell>
                                    <Table.HeaderCell width="2">DT Alteração</Table.HeaderCell>
                                    <Table.HeaderCell width="1"></Table.HeaderCell>
                                    
                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {dataList.map(data => (
                                    <Table.Row key={data.id}>
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>{data.codprod}</Table.Cell>
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>
                                            {((data.pctdefinido.toFixed(2)-data.vlrvendaold.toFixed(2))<=-6?(<Icon name='warning sign' color='red' title='Analisar se a conversão de unidade foi feita corretamente!'/>):"")}
                                            {data.produto}
                                        </Table.Cell>
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>{data.concorrente}</Table.Cell>
                                       
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>R${data.precoconcorrente.toFixed(2)}|M²{(data.precoconcorrente/data.fatorconversao).toFixed(2)}</Table.Cell>
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>R${data.precodesejado.toFixed(2)}</Table.Cell>
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>R${data.precodefinido.toFixed(2)}|M²{(data.precodefinido/data.fatorconversao).toFixed(2)}</Table.Cell>
                                        <Table.Cell negative={data.percentualdiferenca<0?true:false} positive={data.percentualdiferenca>0?true:false}>{data.percentualdiferenca.toFixed(2)}</Table.Cell>
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>{moment(data.dtalteracao).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                                        <Table.Cell negative={data.acao==='Diminuir'?true:false}>
                                        <Icon 
                                            name={(data.flforadoslimites===1?'arrow up':'arrow down')} 
                                            color={(data.flforadoslimites===2?'red':'green')}
                                            />
                                        </Table.Cell>
                                    </Table.Row>
                                ))}
                                
                            </StyledTableBody>
                        </StyledTable>
            </Tab.Pane>
        )
    }
}

ListForaDosLimites.defaultProps = {
    dataList: []
    , loader: true
};

ListForaDosLimites.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
};



export default ListForaDosLimites
