import * as Api from '../utils/API';

import {
    PERMISSOES_LIST_USUARIOS
    , PERMISSOES_EDITING_USUARIOS
    , PERMISSOES_CHANGE_USUARIO_SAVING
    , PERMISSOES_CHANGE_USUARIOS
} from '../actions/ActionsTypes';

export const permissoesListUsuariosMGRAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {

        /*let usuarios = [
            {USUPEDFBITSID:1,isediting:false, issaving:false, LOGIN:'MICHEL',VENDEDORVCC:'',EMAIL:'HUDFHAUSIDHFUAHFUIH UIAHFUIDSH FUAH@HEHEHE.COM', QTD_PERMISSOES:100}
            ,{USUPEDFBITSID:2,isediting:false,issaving:false, LOGIN:'MICHE2',VENDEDORVCC:'',EMAIL:'HUDFHAUSIDHFUAHFUIH UIAHFUIDSH FUAH@HEHEHE.COM', QTD_PERMISSOES:100}
        
        ]
        dispatch({ type: PERMISSOES_LIST_USUARIOS, payload: usuarios })
        resolve([])
        */

        Api.getAllListUsuariosMGR().then(dados => {
            let usuarios = []
            usuarios = dados;

            usuarios.map(s => (
                s.isediting = false
                , s.issaving = false
            ))

            dispatch({ type: PERMISSOES_LIST_USUARIOS, payload: usuarios })
            resolve([])
        }).catch(error => {
            reject(error);
        })


    });

export const permissoesUsuariosEditAction = (Usupedfbitsid, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: PERMISSOES_EDITING_USUARIOS, payload: Usupedfbitsid, isediting: acao });

        resolve([]);
    });

export const permissoesUsuariosAtualizaAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PERMISSOES_CHANGE_USUARIO_SAVING, payload: entidade.usupedfbitsid, issaving: true });

        // console.log(entidade, 'chegou no action');

        Api.postUsuariosMGRAtualiza(entidade).then((response) => {
            if (response.mensagem === "OK") {
                // console.log("Chegou no if de ok")
                dispatch({ type: PERMISSOES_EDITING_USUARIOS, payload: entidade.usupedfbitsid, isediting: false });
                dispatch({ type: PERMISSOES_CHANGE_USUARIO_SAVING, payload: entidade.usupedfbitsid, issaving: false });

            } else {

                dispatch({ type: PERMISSOES_CHANGE_USUARIO_SAVING, payload: entidade.usupedfbitsid, issaving: false });
            }
            resolve([]);
        }).catch((error) => {
            console.log(error);
            dispatch({ type: PERMISSOES_CHANGE_USUARIO_SAVING, payload: entidade.usupedfbitsid, issaving: false });
            resolve([]);
        });

    });

export const permissoesUsuariosChangeAction = (e, usuario) => {
    return {
        type: PERMISSOES_CHANGE_USUARIOS
        , field: e.target.name
        , payload: e.target.value
        , usupedfbitsid: usuario.usupedfbitsid
    }

}

export const permissoesUsuariosInsereAction = (entidade, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PERMISSOES_CHANGE_USUARIO_SAVING, payload: entidade.usupedfbitsid, issaving: acao });



        Api.postUsuarioInsere(entidade)
            .then((response) => {
                // console.log(response, 'response then');
                if (response.mensagem === "OK") {

                    // Após a criação bem-sucedida, chame a action para atualizar a lista de ajudantes
                    dispatch(permissoesListUsuariosMGRAction());
                    resolve([]);
                }
            })
            .catch((error) => {
                console.log(error);
                resolve([]);
            });
    });


export const permissoesUsuariosCopiaAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PERMISSOES_CHANGE_USUARIO_SAVING, payload: entidade.origem, issaving: true });

        Api.postUsuarioCopiaPermissoes(entidade).then((response) => {
            if (response.mensagem === "OK") {
                dispatch(permissoesListUsuariosMGRAction());
            }
            resolve([]);
        }).catch((error) => {
            console.log(error);
            resolve([]);
        });
    });

export const permissoesUsuariosSalvaAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PERMISSOES_CHANGE_USUARIO_SAVING, payload: entidade.usuarioId, issaving: true });

        Api.postUsuarioSalvaPermissoes(entidade).then((response) => {
            if (response.mensagem === "OK") {
                dispatch(permissoesListUsuariosMGRAction());
            }
            resolve([]);
        }).catch((error) => {
            console.log(error);
            resolve([]);
        });
    });