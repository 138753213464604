import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Icon, Tab, Divider } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';
import { CopyToClipboard } from 'react-copy-to-clipboard';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;

}


`

//font-size: .8em;


class ListAuto extends Component {

    state = { copied:'', pedidofbits:'' }

    constructor(props) {
        moment.locale('pt-br');
        super(props);
        this.onChangeConfere = this.onChangeConfere.bind(this);
    }


    componentDidMount() {

    }

    onChangeConfere(e, pedido) {

        if (!pedido.isupdatingdevolucao) {
            this.props.handleAuto(pedido);
        }

    }



    render() {
        let { dataList, dataQtde, loader
        } = this.props;


        let entreguependetes = dataList.filter(f => (f.acao == 2 || f.acao == 3) && f.dtconferido == null);
        let conferidos = dataList.filter(f => (f.acao == 2 || f.acao == 3) && f.dtconferido != null);
        let sairam = dataList.filter(f => f.acao == 1);

        const getstatus = (acao)=>{
            if(acao==1){
                return "Saiu"
            }else if(acao==2){
                return "Entregou"
            }else if(acao==3){
                return "Ocorrência"
                
            }


        }



        return (
            <Tab.Pane loading={loader}>
                <Divider horizontal>Pendentes de conferência</Divider>
                <StyledTable celled id="ListEntregasAuto" compact='very' size='small' selectable>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                            <Table.HeaderCell width="1">Numnota</Table.HeaderCell>
                            <Table.HeaderCell width="4">Parceiro</Table.HeaderCell>
                            <Table.HeaderCell width="1">Ação</Table.HeaderCell>
                            <Table.HeaderCell width="2">Dt Ação</Table.HeaderCell>
                            <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                            <Table.HeaderCell width="1"></Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {entreguependetes.map(data => (
                            <Table.Row key={"La" + data.numnota}>
                                <Table.Cell >{data.pedidofbits}</Table.Cell>
                                <Table.Cell>{data.numnota}</Table.Cell>
                                <Table.Cell>{data.codparc} - {data.parceiro}</Table.Cell>
                                <Table.Cell>{
                                    getstatus(data.acao)

                                }</Table.Cell>
                                <Table.Cell>{moment(data.dtacao).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                                <Table.Cell>{data.vendedor}</Table.Cell>
                                <Table.Cell>
                                    <Button color='green'
                                        inverted
                                        size="mini"
                                        loading={data.isupdating}
                                        onClick={(e) => this.onChangeConfere(e, data)}
                                    >
                                        <Icon name='check' />
                                        Conferir
                                    </Button>

                                </Table.Cell>
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>




                <Divider horizontal>Conferidas</Divider>


                <StyledTable celled id="ListaentregasAutoConferidos" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                            <Table.HeaderCell width="1">Numnota</Table.HeaderCell>
                            <Table.HeaderCell width="4">Parceiro</Table.HeaderCell>
                            <Table.HeaderCell width="1">Ação</Table.HeaderCell>
                            <Table.HeaderCell width="2">Dt Ação</Table.HeaderCell>
                            <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                            <Table.HeaderCell width="2">Usuário</Table.HeaderCell>
                            <Table.HeaderCell width="2">Conferido</Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {conferidos.map(data => (
                            <Table.Row key={"Lb" + data.numnota}>
                                <Table.Cell positive>{data.pedidofbits}</Table.Cell>
                                <Table.Cell positive>{data.numnota}</Table.Cell>
                                <Table.Cell positive>{data.codparc} - {data.parceiro}</Table.Cell>
                                <Table.Cell positive>{ getstatus(data.acao)}</Table.Cell>
                                <Table.Cell positive>{moment(data.dtacao).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                                <Table.Cell positive>{data.vendedor}</Table.Cell>
                                <Table.Cell positive>{data.login}</Table.Cell>
                                <Table.Cell positive>{moment(data.dtconferido).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>



                <Divider horizontal>Saiu</Divider>


                <StyledTable celled id="ListaentregasAutoSaiu" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                            <Table.HeaderCell width="1">Numnota</Table.HeaderCell>
                            <Table.HeaderCell width="4">Parceiro</Table.HeaderCell>
                            <Table.HeaderCell width="1">Ação</Table.HeaderCell>
                            <Table.HeaderCell width="2">Dt Ação</Table.HeaderCell>
                            <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {sairam.map(data => (
                            <Table.Row key={"Lc" + data.numnota}>
                                <Table.Cell>{data.pedidofbits}</Table.Cell>
                                <CopyToClipboard text={data.chavenfe}
                                    onCopy={() => this.setState({ pedidofbits: data.pedidofbits })}
                                    style={{ cursor: 'cell' }}
                                >

                                    <Table.Cell negative={(data.pedidofbits==this.state.pedidofbits?true:false)}>{data.numnota}</Table.Cell>
                                </CopyToClipboard>
                                <Table.Cell>{data.codparc} - {data.parceiro}</Table.Cell>
                                <Table.Cell>{ getstatus(data.acao)}</Table.Cell>
                                <Table.Cell>{moment(data.dtacao).format('DD/MM/YYYY HH:mm:ss')}</Table.Cell>
                                <Table.Cell>{data.vendedor}</Table.Cell>

                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>

            </Tab.Pane>




        )
    }
}

ListAuto.defaultProps = {
    dataList: []
    , loader: true
};

ListAuto.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , handleAuto: PropTypes.func.isRequired
};



export default ListAuto
