import sortBy from 'sort-by';

import {
    CORPORATIVO_LIST_FECHAMENTO
    , CORPORATIVO_LOADER_FECHAMENTO
    , CORPORATIVO_UPDATE_BUSCA

    , CORPORATIVO_VALIDACAO_BUSCA_ADD
    , CORPORATIVO_VALIDACAO_BUSCA_REMOVE
    , CORPORATIVO_ISSEARCHING_BUSCA
    , CORPORATIVO_LIST
    , CORPORATIVO_LIST_SEARCH
    , CORPORATIVO_LIST_CIDADES
} from '../actions/ActionsTypes';

import {RepresentanteBuscaEntity} from '../entities/RepresentanteBuscaEntity'

const INITIAL_STATE = {
    fechamento:[]
    , loaderRepresentantesFechamento: true
    , RepresentanteBuscaEntity
    , isSearching: false
    , validacao:[]
    , representantes:[]
    , search:[]
    , cidades:[]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case CORPORATIVO_LIST_FECHAMENTO:
            return { ...state, fechamento: action.payload }

        case CORPORATIVO_LOADER_FECHAMENTO:
            return { ...state, loaderRepresentantesFechamento: action.payload }
        
        case CORPORATIVO_UPDATE_BUSCA:
            return { ...state, RepresentanteBuscaEntity: {...state.RepresentanteBuscaEntity, [action.field]: action.payload}}

        case CORPORATIVO_VALIDACAO_BUSCA_ADD:
            return { ...state, validacao: [...state.validacao, action.payload] }

        case CORPORATIVO_VALIDACAO_BUSCA_REMOVE:
            return { ...state, validacao: [] }
            
        case CORPORATIVO_ISSEARCHING_BUSCA:
            return {...state, isSearching:action.payload }

        case CORPORATIVO_LIST:
            return {...state, representantes:action.payload }

        case CORPORATIVO_LIST_SEARCH:
            return {...state, search:action.payload}

        case CORPORATIVO_LIST_CIDADES:
            return {...state, cidades:action.payload}

        default:
            return state;
    }
}