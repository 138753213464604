import React, { Component } from 'react'
import PropTypes from 'prop-types'
import XlsxPopulate from 'xlsx-populate';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Table, Button

} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';

import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



class Exportar extends Component {


    state = {}

    constructor(props) {
        super(props);
    }


    componentWillReceiveProps() {

    }

    async componentDidMount() {
    }

    handleExport = () => {




    }

    handleButtonClick = () => {
        this.fileInput.click();
    };



    render() {

        let { exportar, isexporting, exportarLeroy, isexportingLeroy } = this.props

        let colunas = []

        if (exportar.length > 0) {
            colunas = Object.keys(exportar[0])
        }

        let colunasLeroy = []

        if (exportarLeroy.length > 0) {
            colunasLeroy = Object.keys(exportarLeroy[0])
        }

        function converterArrayDeObjetos(arrayDeObjetos) {
            const arrayNormal = [];
            arrayDeObjetos.map(objeto => {
                arrayNormal.push(Object.values(objeto));
            });
            return arrayNormal;
        }

        const dataLeroy = converterArrayDeObjetos(exportarLeroy);

        const handleExport = (loader) => {
            console.log('resultado do loader ------------>', loader);
            if (loader === false && exportar.length === 0) {
                this.setState({ isexporting: true });
                this.props.CadastroDeProdutosExportListAction();
                if (exportar.length > 0) {
                    this.setState({ isexporting: false });
                }
            }
        }
        const handleExportLeroy = (loader) => {
            if (loader === false && exportarLeroy.length === 0) {
                this.setState({ isexportingLeroy: true });
                this.props.CadastroDeProdutosExportLeroyAction();
                if (exportarLeroy.length > 0) {
                    this.setState({ isexportingLeroy: false });
                }
            }
        }

        const handleFileChange = async (event) => {
            try {
                const file = event.target.files[0];
                const workbook = await XlsxPopulate.fromDataAsync(file);

                // Adicionar linhas ao arquivo Excel e fixar as duas primeiras linhas
                const sheet = workbook.sheet(0);
                sheet.row(1).height(16); // Altura da primeira linha
                sheet.row(2).height(16); // Altura da segunda linha

                sheet.cell('A3').value(dataLeroy);

                // renomeando o arquivo Excel

                const currentDate = new Date();
                const formattedDate = `${currentDate.getDate().toString().padStart(2, '0')}-${(currentDate.getMonth() + 1)
                    .toString()
                    .padStart(2, '0')}-${currentDate.getFullYear()}`;
                const formattedTime = `${currentDate.getHours().toString().padStart(2, '0')}-${currentDate
                    .getMinutes()
                    .toString()
                    .padStart(2, '0')}`;
                const fileName = `leroy-precos-${formattedDate}-${formattedTime}.xlsx`;

                // Salvar o arquivo modificado
                const modifiedData = await workbook.outputAsync();
                const blob = new Blob([modifiedData], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
                const url = window.URL.createObjectURL(blob);

                // Disponibilizar o arquivo para download
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute('download', fileName);
                document.body.appendChild(link);
                link.click();
            } catch (error) {
                console.error('Erro ao processar o arquivo:', error);
            }
        }





        return (
            <div>
                {exportar.length === 0 ? (<Button onClick={() => handleExport(isexporting)} basic color={'blue'} loading={isexporting} size="mini">Carregar dados Geral</Button>)
                    : (
                        <ExcelFile element={<Button basic color={'blue'} loading={isexporting} size="mini">Exportar dados Geral</Button>}>
                            {isexporting === false && exportar !== undefined && (
                                <ExcelSheet data={exportar} name={`Fechamento`}>
                                    {
                                        colunas.map(item => (
                                            <ExcelColumn label={item} value={item} />
                                        ))
                                    }
                                </ExcelSheet>
                            )}
                        </ExcelFile>
                    )
                }
                {exportarLeroy.length === 0 ? (<Button onClick={() => { handleExportLeroy(isexportingLeroy) }} style={{ marginRight: 30 }} basic color={'green'} loading={isexportingLeroy} size="mini">Carregar dados Leroy Merlin</Button>)
                    : (

                        <Button onClick={this.handleButtonClick} style={{ marginRight: 30 }} basic color={'green'} loading={isexportingLeroy} size="mini">Exportar dados Leroy Merlin</Button>
                    )
                }

                <a href={`xlsx/Template_Leroy_Merlin.xlsx`} download="Template_Leroy_Merlin" target='_blank'>
                    <Button basic color={'grey'} size="mini">Template Leroy Merlin</Button>
                </a>
                <div style={{ marginTop: 30, width: 450 }}>
                    <p>Para exportar os dados da Leroy Merlin é necessário ter um template padrão de dados em formato xlsx.</p>
                    <span> <strong>1.</strong> Faça o download do template no botão <strong>Template Leroy Merlin.</strong></span><br />
                    <span> <strong>2.</strong> Carregue os dados no botão <strong>Carregar dados Leroy Merlin.</strong></span><br />
                    <span> <strong>3.</strong> Clique no botão <strong>Exportar dados Leroy Merlin</strong> e selecione o arquivo de template que foi baixado.</span>
                </div>
                <input type="file" accept=".xlsx" onChange={handleFileChange} style={{ display: 'none' }} ref={(ref) => (this.fileInput = ref)} />
            </div >

        )
    }

}

Exportar.defaultProps = {
    exportar: []
    , exportarLeroy: []
};

Exportar.propTypes = {
    exportar: PropTypes.array.isRequired
    , exportarLeroy: PropTypes.array.isRequired
    , CadastroDeProdutosExportListAction: PropTypes.func.isRequired
};

export default Exportar

