import {
    MERCADOLIVRE_LIST_TOKEN
    , MERCADOLIVRE_CHANGE_DIMENSOES
    , MERCADOLIVRE_DIMENSOES_SAVING
    , MERCADOLIVRE_ANUNCIO_UPDATE
    , MERCADOLIVRE_ANUNCIO_SAVING
    , MERCADOLIVRE_ANUNCIO_CLEAR
    , MERCADOLIVRE_LIST_PENDENTES
    , MERCADOLIVRE_LIST_ENVIADOS
    , MERCADOLIVRE_CHECK_PENDENTES
} from '../actions/ActionsTypes'


import { MercadoLivreAnuncioEntity } from '../entities/MercadoLivreAnuncioEntity'

const INITIAL_STATE = {
    token: ''
    , dimensoes: ''
    , dimensoessaving: false
    , dimensoesresultado: []
    , parametros: ''
    , issearching: false
    , category_id_list: [
        //{ key: "MLB30215", text: 'Cabos', value:"MLB30215"} 
        { key: "MLB14560", text: 'Outros', value: "MLB14560" }
        , { key: "MLB30185", text: 'Forros de PVC', value: "MLB30185" }
        // , { key: "MLB30225", text: 'Perfil de Divisória', value: "MLB30225" }
        , { key: "MLB179802", text: 'Piso Laminado', value: "MLB179802" }
        , { key: "MLB179800", text: 'Piso Vinílico', value: "MLB179800" }
        , { key: "MLB435306", text: 'Placa Cimentícia', value: "MLB435306" }
        , { key: "MLB271830", text: 'Rodapés', value: "MLB271830" }
        , { key: "MLB418787", text: 'Perfis', value: "MLB418787" }
        , { key: "MLB179703", text: 'Portas', value: "MLB179703" }
        , { key: "MLB186419", text: 'Paineis Decorativos', value: "MLB186419" }

        //, { key: "MLB271830", text: 'Rodapé', value: "MLB271830" }


    ]
    , MercadoLivreAnuncioEntity
    , anunciosaving: false
    , anuncioresultado: []

    , pendentes: []
    , enviados: []




}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MERCADOLIVRE_LIST_TOKEN:
            return { ...state, token: action.payload }

        case MERCADOLIVRE_CHANGE_DIMENSOES:
            return { ...state, [action.field]: action.payload }

        case MERCADOLIVRE_ANUNCIO_UPDATE:
            return { ...state, MercadoLivreAnuncioEntity: { ...state.MercadoLivreAnuncioEntity, [action.field]: action.payload } }

        case MERCADOLIVRE_ANUNCIO_SAVING:
            if (action.payload) {
                return { ...state, anunciosaving: action.payload, anuncioresultado: action.resultado }
            } else {
                return { ...state, anunciosaving: action.payload, anuncioresultado: action.resultado }

            }

        case MERCADOLIVRE_ANUNCIO_CLEAR:
            return { ...state, MercadoLivreAnuncioEntity: MercadoLivreAnuncioEntity }


        case MERCADOLIVRE_DIMENSOES_SAVING:
            return { ...state, dimensoessaving: action.payload, dimensoesresultado: action.resultado }


        case MERCADOLIVRE_LIST_PENDENTES:
            return { ...state, pendentes: action.payload }

        case MERCADOLIVRE_LIST_ENVIADOS:
            return { ...state, enviados: action.payload }

        case MERCADOLIVRE_CHECK_PENDENTES:
            return {
                ...state,
                pendentes: state.pendentes.map(
                    (item, i) => item.ad_pedidofbits === action.id ?
                        { ...item, ischecked: action.payload }
                        : item
                )
            }


        default:
            return state;
    }
}