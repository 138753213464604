export const MercadoLivreAnuncioEntity = {
    title: ""
    , category_id: ""
    , price: "0.00"
    , available_quantity: 1
    , description: ""
    , dimensions: ""
    , brand: ""
    , model: ""
    , sales_unit: 1
    , yield_of_sales_unit: ""
    , baseboards_yield_of_sales_unit: ""
}

