import {
    PROCESSAMENTOS_DESCONTOFBITS_ISLOADING
    , PROCESSAMENTOS_DESCONTOFBITS_LIST

    , PROCESSAMENTOS_PARCEIROS_LIST
    , PROCESSAMENTOS_PARCEIROS_ISSEARCHING
    , PROCESSAMENTOS_PARCEIROS_ISSAVING
    , PROCESSAMENTOS_PARCEIROS_CHANGE
    , PROCESSAMENTOS_CHANGE_LIBMARKETPLACE

} from '../actions/ActionsTypes';

import * as Api from '../utils/API';

import { START_TIMER } from 'redux-timer';

import { ProcessamentosParceiroEntity } from '../entities/ProcessamentosParceiroEntity';


export const ProcessamentosLibMarketPlaceListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllProcessamentosLibMarketPlace().then(dados => {
            let blnvalor = false

            if (dados[0].valor == '1') {
                blnvalor = true
            } else {
                blnvalor = false
            }

            dispatch({ type: PROCESSAMENTOS_CHANGE_LIBMARKETPLACE, payload: blnvalor })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });


export const ProcessamentosLibMarketPlaceUpdateAction = (valor) => (dispatch) =>
    new Promise(function (resolve, reject) {

        let entidade = {}
        entidade.valor = valor

        Api.postSaveProcessamentosMarketPlace(entidade).then(dados => {
            dispatch({ type: PROCESSAMENTOS_CHANGE_LIBMARKETPLACE, payload: valor })
            resolve([])
        }).catch(error => {
            reject(error);
        })


    });





export const ProcessamentosDescontoFbits = () => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: PROCESSAMENTOS_DESCONTOFBITS_ISLOADING, payload: true })

        Api.getAllProcessamentosDescontoFbits().then(dados => {
            dispatch({ type: PROCESSAMENTOS_DESCONTOFBITS_ISLOADING, payload: false })

            if (dados.status == 'OK') {

            }

            resolve(dados)
        })

    })

export const ProcessamentosStartTimerAction = () => async dispatch => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'robodePrecosStatus',
            action: async () => {
                try {
                    //console.log('tick graph!')

                    dispatch(ProcessamentosStatusListAction())


                } catch (e) {
                    // do something with this error
                }
            },
            interval: 10000,
            runImmediately: false
        }
    });
};

export const ProcessamentosStatusListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllProcessamentosStatus().then(dados => {
            dispatch({ type: PROCESSAMENTOS_DESCONTOFBITS_LIST, status: parseInt(dados[0].valor, 10), dt: dados[1].valor })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });

export const ProcessamentosStatusUpdateAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        //console.log('update')
        dispatch({ type: PROCESSAMENTOS_DESCONTOFBITS_LIST, status: 2, dt: null })
        Api.getAllProcessamentosDescontoFbits().then(dados => {

            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });

export const DescontoFBitsIndividualAction = (pedidofbits) =>
    new Promise(function (resolve, reject) {

        Api.postDescontoFBitsIndividual(pedidofbits).then(dados => {
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });

export const ProcessamentosListParceiroAction = (codparc) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: PROCESSAMENTOS_PARCEIROS_ISSEARCHING, payload: true })

        Api.getAllProcessamentosParceiro(codparc).then(dados => {


            let entidade = ProcessamentosParceiroEntity;
            entidade.codparc = dados[0].codparc;
            entidade.nomeparc = dados[0].nomeparc;
            entidade.ipiincicms = dados[0].ipiincicms;
            entidade.ad_ultpedfbits = dados[0].ad_ultpedfbits;
            entidade.codtab = dados[0].codtab;
            entidade.classificacaofin = dados[0].classificacaofin;
            entidade.ad_fixartabb2b = dados[0].ad_fixartabb2b;
            entidade.ad_emailb2b = dados[0].ad_emailb2b;
            entidade.parceirosemail = dados[0].parceirosemail;


            dispatch({ type: PROCESSAMENTOS_PARCEIROS_LIST, payload: entidade });
            dispatch({ type: PROCESSAMENTOS_PARCEIROS_ISSEARCHING, payload: false });

            resolve(dados);
        }).catch(error => {
            reject(error);
        })
    });



export const ProcessamentosChangeParceiroAction = (event) => {
    return {
        type: PROCESSAMENTOS_PARCEIROS_CHANGE
        , field: event.target.name
        , payload: event.target.value
    }
}



export const ProcessamentosSaveParceiroAction = (entidade, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {

        if (acao === 'save') {
            dispatch({ type: PROCESSAMENTOS_PARCEIROS_ISSAVING, payload: true });

            Api.postSaveProcessamentosParceiro(entidade).then(dados => {

                let entity = ProcessamentosParceiroEntity;
                entity.codparc = 0;
                entity.nomeparc = '';
                entity.ipiincicms = '';
                entity.ad_emailb2b = '';
                entity.parceirosemail = '';

                if (dados[0].status == 'OK') {
                    dispatch({ type: PROCESSAMENTOS_PARCEIROS_LIST, payload: entity });
                }

                dispatch({ type: PROCESSAMENTOS_PARCEIROS_ISSAVING, payload: false });
                resolve(dados);
            }).catch(error => {
                reject(error);
            })
        } else {
            let entity = ProcessamentosParceiroEntity;
            entity.codparc = 0;
            entity.nomeparc = '';
            entity.ipiincicms = '';
            entity.ad_emailb2b = '';
            entity.parceirosemail = '';
            dispatch({ type: PROCESSAMENTOS_PARCEIROS_ISSAVING, payload: true });
            dispatch({ type: PROCESSAMENTOS_PARCEIROS_LIST, payload: entity });
            dispatch({ type: PROCESSAMENTOS_PARCEIROS_ISSAVING, payload: false });
            resolve([]);
        }




    });





