import sortBy from 'sort-by';

import {
    ORCAMENTOS_UPDATE_BUSCA
    , ORCAMENTOS_ISSEARCHING_BUSCA
    , ORCAMENTOS_LIST_SEARCH
} from '../actions/ActionsTypes';

import {OrcamentosBuscaEntity} from '../entities/OrcamentosBuscaEntity'

const INITIAL_STATE = {
    OrcamentosBuscaEntity
    , isSearching: false
    , search:[]

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    
        case ORCAMENTOS_UPDATE_BUSCA:
            return { ...state, OrcamentosBuscaEntity: {...state.OrcamentosBuscaEntity, [action.field]: action.payload}}

        case ORCAMENTOS_ISSEARCHING_BUSCA:
            return {...state, isSearching:action.payload }

        case ORCAMENTOS_LIST_SEARCH:
            return {...state, search:action.payload}

        default:
            return state;
    }
}