import React, { useState, useCallback } from 'react';
import styled from 'styled-components';
import { Icon } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';

const StatusDropdown = ({ value, onChange, status, onFilesChange }) => {
    const [isOpen, setIsOpen] = useState(false);
    const [selectedValue, setSelectedValue] = useState(value); // Controla o valor selecionado no dropdown
    const [uploadedFiles, setUploadedFiles] = useState([]); // Armazena os arquivos enviados

    const getStatusColor = (status) => {
        switch (status) {
            case 0:
                return '#6c757d';
            case 1:
                return '#20c997';
            case 2:
                return '#fd7e14';
            case 3:
                return '#383845';
            case 4:
                return '#28a745';
            case 5:
                return '#dc3545';
            default:
                return '#6c757d'; // Caso o status não seja 1, 2, 3, 4 ou 5
        }
    };

    const options = [
        { key: 0, text: 'Aguardando Análise', value: 0 },
        { key: 1, text: 'Em Análise', value: 1 },
        { key: 2, text: 'Aguardando Cliente', value: 2 },
        { key: 3, text: 'Aguardando Técnico', value: 3 },
        { key: 4, text: 'Procedente', value: 4 },
        { key: 5, text: 'Improcedente', value: 5 },
    ];

    const filteredOptions = options.filter(option => option.value !== selectedValue);

    const handleOptionClick = (option) => {
        setSelectedValue(option.value);
        onChange(option.value); // Chama a função para atualizar o estado externo
        setIsOpen(false);
    };

    const onDrop = useCallback((acceptedFiles) => {
        // Limitar a apenas um arquivo PDF
        if (acceptedFiles.length > 0) {
            const file = acceptedFiles[0]; // Pega o primeiro arquivo aceito
            if (file && file.type === 'application/pdf') {
                setUploadedFiles([file]); // Define apenas o arquivo PDF
                onFilesChange([file]); // Passa o arquivo selecionado para o componente pai
            } else {
                console.warn('Arquivo não suportado ou inválido:', file);
            }
        }
    }, [onFilesChange]);

    // Configurações do dropzone para aceitar apenas PDFs
    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: 'application/pdf', // Aceita apenas PDFs
    });

    // O campo de upload só aparece quando o valor selecionado é 4 ou 5
    const isUploadVisible = selectedValue === 4 || selectedValue === 5;

    // O dropdown fica desabilitado quando o status é 4 ou 5
    const isDisabled = status === 4 || status === 5;

    return (
        <Container>
            <DropdownContainer>
                <DropdownButton
                    onClick={() => !isDisabled && setIsOpen(!isOpen)}
                    disabled={isDisabled}
                >
                    <LabelWithColor color={getStatusColor(selectedValue)}>
                        {options.find(option => option.value === selectedValue)?.text || 'Selecione o Status'}
                    </LabelWithColor>
                    <DropdownIcon name={isOpen ? 'angle up' : 'angle down'} />
                </DropdownButton>
                {isOpen && !isDisabled && (
                    <DropdownMenu>
                        {filteredOptions.map(option => (
                            <DropdownMenuItem key={option.key} onClick={() => handleOptionClick(option)}>
                                <LabelWithColor color={getStatusColor(option.value)}>
                                    {option.text}
                                </LabelWithColor>
                            </DropdownMenuItem>
                        ))}
                    </DropdownMenu>
                )}
            </DropdownContainer>

            {isUploadVisible && !isDisabled && (
                <UploadContainer {...getRootProps({ className: 'dropzone' })}>
                    <input {...getInputProps()} />
                    <p>Clique ou arraste um arquivo o Laudo desta Assistência.</p>
                    {uploadedFiles.length > 0 && (
                        <FileList>
                            {uploadedFiles.map(file => (
                                <li key={file.path}>{file.path}</li>
                            ))}
                        </FileList>
                    )}
                </UploadContainer>
            )}
        </Container>
    );
};

// Estilos

const Container = styled.div`
    display: flex;
    align-items: center;  // Alinha verticalmente os elementos
    gap: 20px;  // Espaçamento entre o dropdown e a área de upload
`;

const DropdownContainer = styled.div`
    position: relative;
    width: 180px;
`;

const DropdownButton = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    width: 180px;
    background-color: #f1f1f1;
    border: 1px solid #ddd;
    border-radius: 5px;
    cursor: ${({ disabled }) => (disabled ? 'not-allowed' : 'pointer')}; // Estilo do cursor
    opacity: ${({ disabled }) => (disabled ? 0.6 : 1)}; // Visual desabilitado
`;

const DropdownIcon = styled(Icon)`
    position: absolute;
    top: 45%;
    right: 10px;
    transform: translateY(-50%);
    pointer-events: none;
`;

const DropdownMenu = styled.div`
    position: absolute;
    bottom: 100%;
    left: 0;
    background-color: #fff;
    width: 180px;
    border: 1px solid #ddd;
    border-radius: 5px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
`;

const DropdownMenuItem = styled.div`
    padding: 10px;
    cursor: pointer;
    width: 100%;
    &:hover {
        background-color: #f1f1f1;
    }
`;

const LabelWithColor = styled.span`
    display: flex;
    align-items: center;
    padding: 5px 10px;
    color: #fff;
    width: 100%;
    background-color: ${props => props.color};
    border-radius: 3px;
`;

const UploadContainer = styled.div`
    padding: 10px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    text-align: center;
    width: 290px;
    cursor: pointer;  // Adiciona o cursor pointer para o dropzone
`;

const FileList = styled.ul`
    list-style: none;
    padding: 0;
    margin: 10px 0 0;
    li {
        margin-bottom: 5px;
    }
`;

export default StatusDropdown;
