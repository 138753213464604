import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import If from '../../utils/If';


import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
    , Button, Table
    , Popup, Dropdown
} from 'semantic-ui-react'


import {
    instaladoresListSearchAction
    ,instaladoresUpdateAction
} from '../../actions/InstaladoresActions';

//import Fechamento from './Fechamento';
//import Historico from './Historico';
import Bonus from './Bonus';



import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;

class Instaladores extends Component {

    state = {}

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 17 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        /*

        this.props.consultoresListAction().then(dados => { })

        this.props.consultoresListCidadesAction().then(dados => { })
        */


    }

    componentWillReceiveProps() {

    }

    render() {

        let {  } = this.props

        let panes = [

            {
                menuItem: <Menu.Item key='TABHistorico'><Icon name='list alternate outline' />Consulta Bônus</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={false}>
                       <Bonus
                       datalist={this.props.search}
                       entidade={this.props.InstaladoresBuscaEntity}
                       instaladoresListSearchAction={this.props.instaladoresListSearchAction}
                       instaladoresUpdateAction={this.props.instaladoresUpdateAction}
                       isSearching={this.props.isSearching}
                       
                       />
                    </Tab.Pane>
            },







        ]


       /*if (this.props.userid == "3" || this.props.userid == "2" || this.props.userid == "4" || this.props.userid == "9") {
            panes.push({
                menuItem: <Menu.Item key='TABassinaturas'><Icon name='list alternate outline' />Fechamento</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderConsultoresFechamento}>
                        <div>aeeee</div>
                    </Tab.Pane>
            })

        }*/

        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Instaladores </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , InstaladoresBuscaEntity: state.InstaladoresReducer.InstaladoresBuscaEntity
        , search: state.InstaladoresReducer.search
        , isSearching: state.InstaladoresReducer.isSearching
        
     


    }
);


export default withRouter(connect(mapStateToProps, {
    instaladoresListSearchAction
    ,instaladoresUpdateAction
}
)(Instaladores));

