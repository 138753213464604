import {
    SERASA_UPDATE_BUSCA
    , SERASA_ISSEARCHING_BUSCA
    , SERASA_LIST_SEARCH
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';

export const serasaListSearchAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: SERASA_ISSEARCHING_BUSCA, payload: true })
        
        Api.postSerasaSearch(entidade).then(dados => {
            let search = dados
         
            dispatch({ type: SERASA_LIST_SEARCH, payload: search })
            dispatch({ type: SERASA_ISSEARCHING_BUSCA, payload: false })
            resolve([])
        })
    }
)


export const serasaUpdateAction = (event) => {
    return {
        type: SERASA_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}









