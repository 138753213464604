import sortBy from 'sort-by';

import {
    CONSULTORES_LIST_FECHAMENTO
    ,CONSULTORES_LOADER_FECHAMENTO

    ,CONSULTORES_LIST_FECHAMENTO90
    ,CONSULTORES_LOADER_FECHAMENTO90

    , CONSULTORES_UPDATE_BUSCA

    , CONSULTORES_VALIDACAO_BUSCA_ADD
    , CONSULTORES_VALIDACAO_BUSCA_REMOVE
    , CONSULTORES_ISSEARCHING_BUSCA
    , CONSULTORES_LIST
    , CONSULTORES_LIST_SEARCH
    , CONSULTORES_LIST_CIDADES
} from '../actions/ActionsTypes';

import {ConsultorBuscaEntity} from '../entities/ConsultorBuscaEntity'

const INITIAL_STATE = {
    fechamento:[]
    , loaderConsultoresFechamento: true

    , fechamento90:[]
    , loaderConsultoresFechamento90: true

    , ConsultorBuscaEntity
    , isSearching: false
    , validacao:[]
    , consultores:[]
    , search:[]
    , cidades:[]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        
        case CONSULTORES_LIST_FECHAMENTO:
            return { ...state, fechamento: action.payload }

        case CONSULTORES_LOADER_FECHAMENTO:
            return { ...state, loaderConsultoresFechamento: action.payload }

        case CONSULTORES_LIST_FECHAMENTO90:
            return { ...state, fechamento90: action.payload }
    
        case CONSULTORES_LOADER_FECHAMENTO90:
            return { ...state, loaderConsultoresFechamento90: action.payload }
        
        case CONSULTORES_UPDATE_BUSCA:
            return { ...state, ConsultorBuscaEntity: {...state.ConsultorBuscaEntity, [action.field]: action.payload}}

        case CONSULTORES_VALIDACAO_BUSCA_ADD:
            return { ...state, validacao: [...state.validacao, action.payload] }

        case CONSULTORES_VALIDACAO_BUSCA_REMOVE:
            return { ...state, validacao: [] }
            
        case CONSULTORES_ISSEARCHING_BUSCA:
            return {...state, isSearching:action.payload }

        case CONSULTORES_LIST:
            return {...state, consultores:action.payload }

        case CONSULTORES_LIST_SEARCH:
            return {...state, search:action.payload}

        case CONSULTORES_LIST_CIDADES:
            return {...state, cidades:action.payload}

        default:
            return state;
    }
}