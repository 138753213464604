//

import {
    BANK_FILES_ML_LOADER
    , BANK_LIST_ML
} from '../actions/ActionsTypes'


import * as Api from '../utils/API';


export const banklistArquivoMLAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: BANK_FILES_ML_LOADER, payload: true })
        Api.getBankAquivoML().then(dados => {
            dispatch({ type: BANK_FILES_ML_LOADER, payload: false })
            dispatch({ type: BANK_LIST_ML, payload: dados })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: BANK_FILES_ML_LOADER, payload: false })
            reject(error);
        })

    });



export const bankpostArquivoMLAction = (arquivo,conteudo,usuario) => (dispatch) =>
    new Promise(function (resolve, reject) {


            let dados = conteudo;
            let cont = 0;
            for(let i of dados){
                i.arquivo = arquivo;
                i.sequencia = cont;
                i.usuario = usuario;
                cont++;
            }


            Api.postBankArquivoML(dados).then(retorno => {
                dispatch(banklistArquivoMLAction());
                resolve(retorno)
            }).catch(error => {
                reject(error);
            })
        
        //timer
        //setInterval(() => {resolve('')}, 1000);

    });


