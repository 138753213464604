import { useState } from 'react';
const Display = ({ word,index }) => {

    const [flip, setFlip] = useState(false);

    setTimeout(() => {
        setFlip(true);
    }, 100 * index)


    return (
        Array.from(word).map((letter, _index) => {
            return (
                <div className={flip?'flip':null}>
                    <div className='letra'>
                        {flip ? letter : null}
                    </div>
                </div>
            )
        }
        ))
}

export default Display;