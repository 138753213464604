import React, { Component } from 'react'
import PropTypes from 'prop-types'


import moment from 'moment';
import 'moment/locale/pt-br'


import {
    Table, Button, Grid, Form, Segment, Icon
    , Input, Header, Checkbox, Popup, Radio, Modal, Label
} from 'semantic-ui-react'

import If from '../../utils/If';


import styled from 'styled-components';



const StyledCheckbox = styled(Checkbox)`
  &&& label{
    color:#209a3d !important; 
   }  
     
`;



const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
    font-size:12px;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;







class Renegociacao extends Component {


    state = { query: '', check: false, radio: '0', ispagamentoopen: false, isrepetidoopen: false, chkcreditoadsite: false, chklog: false }

    constructor(props) {
        super(props);
        //this.handleFormSubmit = this.handleFormSubmit.bind(this);
    }


    componentWillReceiveProps() {


    }

    componentDidMount() {


    }


    handleSave = (isdeleteexistente, pagamentos) => {


        if (this.props.loaderissaving === false) { //trava pra aceitar apenas uma requisicao ao clicar várias vezes

            let pagto = {}
            pagto.isdeleteexistente = isdeleteexistente
            pagto.radio = this.state.radio
            pagto.descricao = pagamentos['opcao' + this.state.radio + 'descricao']
            pagto.parcelas = pagamentos['opcao' + this.state.radio + 'parcelas']
            pagto.valor = pagamentos['opcao' + this.state.radio + 'vlr']
            pagto.total = pagamentos['opcao' + this.state.radio + 'vlr'] * pagamentos['opcao' + this.state.radio + 'parcelas']

            let entidade = {}
            //alert('Gravar')

            if (this.state.chkcreditoadsite) {
                //alert('Utilizou Crédito')

                entidade.dividas = this.props.dividasdados
                entidade.utilizoucredito = true;
                entidade.creditodados = this.props.creditodados
                entidade.compesdados = this.props.compesdados
                
            } else {
                //alert('Não utilizou')
                entidade.dividas = this.props.dividas.filter(d => d.selecionado == true)
                entidade.utilizoucredito = false;
            }

            entidade.pagamentos = pagto
            entidade.userid = this.props.userid
            entidade.configuracoes = this.props.configuracoes



            this.props.renegociacaoSaveAction(entidade).then(d => {
                this.setState({ isrepetidoopen: false })
                this.setState({ ispagamentoopen: false })
                this.setState({ chkcreditoadsite: false })
            })





        }





        /*alert(`
        isdeleteexistente: ${isdeleteexistente}
        radio:${this.state.radio}
        descricao: ${pagamentos['opcao' + this.state.radio + 'descricao']}
        parcelas: ${pagamentos['opcao' + this.state.radio + 'parcelas']}
        valor: ${pagamentos['opcao' + this.state.radio + 'vlr']}
        Total: ${pagamentos['opcao' + this.state.radio + 'vlr'] * pagamentos['opcao' + this.state.radio + 'parcelas']}
        `)*/

    }



    handleCloseRepetido = () => {
        this.setState({ isrepetidoopen: false })

    }


    handleOpenPagamento = () => {
        this.setState({ radio: '0' })
        //alert('calcula crédito utilizavel')
        this.setState({ chkcreditoadsite: false })

        this.props.renegociacaoListCreditoAction(this.props.codparc, this.props.dividas, this.props.configuracoes).then(d => {

        })

        this.setState({ ispagamentoopen: true })
    }

    handleClosePagamento = () => {
        this.setState({ ispagamentoopen: false })
        this.setState({ chkcreditoadsite: false })
    }

    handleConfirmaPagamento = (e, pagamentos) => {

        
        //##michel - dívida dados
        if(this.state.radio==='0' && this.state.chkcreditoadsite===true && this.props.credito != undefined){

            let somaquitou = 0

            for(let i of this.props.dividasdados ){
                
                somaquitou =+ i.vlrdesdob
            }

            if(somaquitou===0){
                this.setState({ radio: '3' })
            }
            
        }


        if (this.state.radio != '0') {


            //verifica se algum item selecionado ja foi renegociado antes
            let jarenegociado = this.props.dividas.filter(d => d.selecionado === true && d.existereneg === 1)
            if (jarenegociado.length === 0) {
                //alert('novo')

                //##final
                
                this.handleSave(false, pagamentos)

            } else {
                //alert('existe renegociado, exibir janela')
                this.setState({ ispagamentoopen: false })
                this.setState({ isrepetidoopen: true })

            }


        }

    }



    handleConfirmaEmail = (e, pagamentos) => {

        let entidade = {}
        entidade.dividas = this.props.dividas.filter(d => d.selecionado == true)
        entidade.pagamentos = pagamentos
        entidade.userid = this.props.userid
        entidade.configuracoes = this.props.configuracoes

        this.props.renegociacaoSimulacaoAction(entidade).then(d => {

            //fecha janela de pagamento
            this.setState({ isrepetidoopen: false })
            this.setState({ ispagamentoopen: false })

        })

    }

    handleSelectAll = () => {

        this.setState({ ispagamentoopen: false })

        this.props.renegociacaoCheckAllAction(this.state.check).then(d => { })

        if (this.state.check) {
            this.setState({ check: false })
        } else {
            this.setState({ check: true })
        }
    }

    handleChangeRadio = (e, { value }) => {
        //alert(value)
        this.setState({ radio: value })
    }

    toggle = () => {

        this.setState({ chkcreditoadsite: !this.state.chkcreditoadsite })


        //this.setState(prevState => ({ chkcreditoadsite: !prevState.chkcreditoadsite }))

    }

    togglelog = () => {

        this.setState({ chklog: !this.state.chklog })


        //this.setState(prevState => ({ chkcreditoadsite: !prevState.chkcreditoadsite }))

    }

    /*handleChangeCreditoAdSite = (e, { value }) => {
        //alert('valor checkbox:'+value)
        //this.setState({ chkcreditoadsite: value })
    }*/

    handleChangeCheckBox = (e, { value }) => {

        // alert('adiciona')

        //console.log(value)
        this.setState({ ispagamentoopen: false })
        this.props.renegociacaoCheckItemAction(value).then(d => { })
    }

    handleInputChange = (e) => {
        this.setState({
            query: e.target.value
        });
    }


    handleFormSubmit = (e) => {
        //alert('search:'+this.state.query);
        this.setState({ radio: '0' })
        this.props.renegociacaoListDividasAction(this.state.query, this.props.configuracoes).then(d => { })
    }




    render() {


        let totalvlrdesdobantes = 0
        let totalvlrtotalantes = 0
        let totalvlrjurosantes = 0
        let totalvlrmultaantes = 0


        let totalvlrdesdobdepois = 0
        let totalvlrtotaldepois = 0
        let totalvlrjurosdepois = 0
        let totalvlrmultadepois = 0
        let totalvlrcompensadodepois = 0

        let totalvlrbaixa = 0
        let totalvlrutilizado = 0
        let totalvlrdesc = 0

        let totalvlrdesdobcredito = 0
        let totalvlrdesagiocredito = 0
        let totaldescontoadsitecredito = 0
        let totalvlrutilizavelcredito = 0




        let { dividas, credito } = this.props

        let { chkcreditoadsite } = this.state

        let total = 0
        let totalpago = 0

        let parceiro = ''
        if (dividas.length > 0) {
            parceiro = dividas[0].parceiro
        }


        let dividasSelecionadas = dividas.filter(d => d.selecionado === true)

        let vlrtotalselecionado = dividasSelecionadas.reduce(function (prev, cur) {
            return prev + cur.vlrtotal;
        }, 0);


        let calculaformas = function (configuracoes, vlrtotal, dividas) {

            let opcoes = {}


            if (vlrtotal > 0 && chkcreditoadsite) {
                let vlrdividasoma = 0

                for (let divida of dividas) {
                    vlrdividasoma += divida.vlrtotal//(divida.vlrcompensado + divida.vlrdesdob)
                }




                /*alert(`
                calculaformas: ${vlrdividasoma}
                credito: ${credito}
                `)*/

                let opcao1pct = configuracoes.filter(d => d.configid == 12)[0].valor
                let opcao2pct = configuracoes.filter(d => d.configid == 13)[0].valor
                let opcao3pct = configuracoes.filter(d => d.configid == 14)[0].valor
                let opcao4pct = configuracoes.filter(d => d.configid == 15)[0].valor
                let opcao5pct = configuracoes.filter(d => d.configid == 16)[0].valor
                let opcao6pct = configuracoes.filter(d => d.configid == 17)[0].valor
                let opcao7pct = configuracoes.filter(d => d.configid == 18)[0].valor
                let opcao8pct = configuracoes.filter(d => d.configid == 19)[0].valor
                let opcao9pct = configuracoes.filter(d => d.configid == 20)[0].valor
                let opcao10pct = configuracoes.filter(d => d.configid == 21)[0].valor
                let opcao11pct = configuracoes.filter(d => d.configid == 22)[0].valor
                let opcao12pct = configuracoes.filter(d => d.configid == 23)[0].valor
                let opcao13pct = configuracoes.filter(d => d.configid == 24)[0].valor
                let opcao14pct = configuracoes.filter(d => d.configid == 25)[0].valor

                opcoes.opcao1status = (opcao1pct === 999 ? false : true)
                opcoes.opcao2status = (opcao2pct === 999 ? false : true)
                opcoes.opcao3status = (opcao3pct === 999 ? false : true)
                opcoes.opcao4status = (opcao4pct === 999 ? false : true)
                opcoes.opcao5status = (opcao5pct === 999 ? false : true)
                opcoes.opcao6status = (opcao6pct === 999 ? false : true)
                opcoes.opcao7status = (opcao7pct === 999 ? false : true)
                opcoes.opcao8status = (opcao8pct === 999 ? false : true)
                opcoes.opcao9status = (opcao9pct === 999 ? false : true)
                opcoes.opcao10status = (opcao10pct === 999 ? false : true)
                opcoes.opcao11status = (opcao11pct === 999 ? false : true)
                opcoes.opcao12status = (opcao12pct === 999 ? false : true)
                opcoes.opcao13status = (opcao13pct === 999 ? false : true)
                opcoes.opcao14status = (opcao14pct === 999 ? false : true)


                opcoes.opcao1descricao = "Boleto 1x"
                opcoes.opcao2descricao = "Depósito 1x"
                opcoes.opcao3descricao = "Pagamento Web 1x"
                opcoes.opcao4descricao = "Pagamento Web 2x"
                opcoes.opcao5descricao = "Pagamento Web 3x"
                opcoes.opcao6descricao = "Pagamento Web 4x"
                opcoes.opcao7descricao = "Pagamento Web 5x"
                opcoes.opcao8descricao = "Pagamento Web 6x"
                opcoes.opcao9descricao = "Pagamento Web 7x"
                opcoes.opcao10descricao = "Pagamento Web 8x"
                opcoes.opcao11descricao = "Pagamento Web 9x"
                opcoes.opcao12descricao = "Pagamento Web 10x"
                opcoes.opcao13descricao = "Pagamento Web 11x"
                opcoes.opcao14descricao = "Pagamento Web 12x"


                opcoes.opcao1vlr = vlrdividasoma + (vlrdividasoma * opcao1pct / 100)
                opcoes.opcao2vlr = vlrdividasoma + (vlrdividasoma * opcao2pct / 100)
                opcoes.opcao3vlr = vlrdividasoma + (vlrdividasoma * opcao3pct / 100)
                opcoes.opcao4vlr = (vlrdividasoma + (vlrdividasoma * (opcao4pct * 2) / 100)) / 2
                opcoes.opcao5vlr = (vlrdividasoma + (vlrdividasoma * (opcao5pct * 3) / 100)) / 3
                opcoes.opcao6vlr = (vlrdividasoma + (vlrdividasoma * (opcao6pct * 4) / 100)) / 4
                opcoes.opcao7vlr = (vlrdividasoma + (vlrdividasoma * (opcao7pct * 5) / 100)) / 5
                opcoes.opcao8vlr = (vlrdividasoma + (vlrdividasoma * (opcao8pct * 6) / 100)) / 6
                opcoes.opcao9vlr = (vlrdividasoma + (vlrdividasoma * (opcao9pct * 7) / 100)) / 7
                opcoes.opcao10vlr = (vlrdividasoma + (vlrdividasoma * (opcao10pct * 8) / 100)) / 8
                opcoes.opcao11vlr = (vlrdividasoma + (vlrdividasoma * (opcao11pct * 9) / 100)) / 9
                opcoes.opcao12vlr = (vlrdividasoma + (vlrdividasoma * (opcao12pct * 10) / 100)) / 10
                opcoes.opcao13vlr = (vlrdividasoma + (vlrdividasoma * (opcao13pct * 11) / 100)) / 11
                opcoes.opcao14vlr = (vlrdividasoma + (vlrdividasoma * (opcao14pct * 12) / 100)) / 12

                opcoes.opcao1parcelas = 1
                opcoes.opcao2parcelas = 1
                opcoes.opcao3parcelas = 1
                opcoes.opcao4parcelas = 2
                opcoes.opcao5parcelas = 3
                opcoes.opcao6parcelas = 4
                opcoes.opcao7parcelas = 5
                opcoes.opcao8parcelas = 6
                opcoes.opcao9parcelas = 7
                opcoes.opcao10parcelas = 8
                opcoes.opcao11parcelas = 9
                opcoes.opcao12parcelas = 10
                opcoes.opcao13parcelas = 11
                opcoes.opcao14parcelas = 12





            } else if (vlrtotal > 0 && !chkcreditoadsite) {
                //alert('descalculaformas:' + vlrtotal)


                let opcao1pct = configuracoes.filter(d => d.configid == 12)[0].valor
                let opcao2pct = configuracoes.filter(d => d.configid == 13)[0].valor
                let opcao3pct = configuracoes.filter(d => d.configid == 14)[0].valor
                let opcao4pct = configuracoes.filter(d => d.configid == 15)[0].valor
                let opcao5pct = configuracoes.filter(d => d.configid == 16)[0].valor
                let opcao6pct = configuracoes.filter(d => d.configid == 17)[0].valor
                let opcao7pct = configuracoes.filter(d => d.configid == 18)[0].valor
                let opcao8pct = configuracoes.filter(d => d.configid == 19)[0].valor
                let opcao9pct = configuracoes.filter(d => d.configid == 20)[0].valor
                let opcao10pct = configuracoes.filter(d => d.configid == 21)[0].valor
                let opcao11pct = configuracoes.filter(d => d.configid == 22)[0].valor
                let opcao12pct = configuracoes.filter(d => d.configid == 23)[0].valor
                let opcao13pct = configuracoes.filter(d => d.configid == 24)[0].valor
                let opcao14pct = configuracoes.filter(d => d.configid == 25)[0].valor

                opcoes.opcao1status = (opcao1pct === 999 ? false : true)
                opcoes.opcao2status = (opcao2pct === 999 ? false : true)
                opcoes.opcao3status = (opcao3pct === 999 ? false : true)
                opcoes.opcao4status = (opcao4pct === 999 ? false : true)
                opcoes.opcao5status = (opcao5pct === 999 ? false : true)
                opcoes.opcao6status = (opcao6pct === 999 ? false : true)
                opcoes.opcao7status = (opcao7pct === 999 ? false : true)
                opcoes.opcao8status = (opcao8pct === 999 ? false : true)
                opcoes.opcao9status = (opcao9pct === 999 ? false : true)
                opcoes.opcao10status = (opcao10pct === 999 ? false : true)
                opcoes.opcao11status = (opcao11pct === 999 ? false : true)
                opcoes.opcao12status = (opcao12pct === 999 ? false : true)
                opcoes.opcao13status = (opcao13pct === 999 ? false : true)
                opcoes.opcao14status = (opcao14pct === 999 ? false : true)

                opcoes.opcao1descricao = "Boleto 1x"
                opcoes.opcao2descricao = "Depósito 1x"
                opcoes.opcao3descricao = "Pagamento Web 1x"
                opcoes.opcao4descricao = "Pagamento Web 2x"
                opcoes.opcao5descricao = "Pagamento Web 3x"
                opcoes.opcao6descricao = "Pagamento Web 4x"
                opcoes.opcao7descricao = "Pagamento Web 5x"
                opcoes.opcao8descricao = "Pagamento Web 6x"
                opcoes.opcao9descricao = "Pagamento Web 7x"
                opcoes.opcao10descricao = "Pagamento Web 8x"
                opcoes.opcao11descricao = "Pagamento Web 9x"
                opcoes.opcao12descricao = "Pagamento Web 10x"
                opcoes.opcao13descricao = "Pagamento Web 11x"
                opcoes.opcao14descricao = "Pagamento Web 12x"

                opcoes.opcao1vlr = vlrtotal + (vlrtotal * opcao1pct / 100)
                opcoes.opcao2vlr = vlrtotal + (vlrtotal * opcao2pct / 100)
                opcoes.opcao3vlr = vlrtotal + (vlrtotal * opcao3pct / 100)
                opcoes.opcao4vlr = (vlrtotal + (vlrtotal * (opcao4pct * 2) / 100)) / 2
                opcoes.opcao5vlr = (vlrtotal + (vlrtotal * (opcao5pct * 3) / 100)) / 3
                opcoes.opcao6vlr = (vlrtotal + (vlrtotal * (opcao6pct * 4) / 100)) / 4
                opcoes.opcao7vlr = (vlrtotal + (vlrtotal * (opcao7pct * 5) / 100)) / 5
                opcoes.opcao8vlr = (vlrtotal + (vlrtotal * (opcao8pct * 6) / 100)) / 6
                opcoes.opcao9vlr = (vlrtotal + (vlrtotal * (opcao9pct * 7) / 100)) / 7
                opcoes.opcao10vlr = (vlrtotal + (vlrtotal * (opcao10pct * 8) / 100)) / 8
                opcoes.opcao11vlr = (vlrtotal + (vlrtotal * (opcao11pct * 9) / 100)) / 9
                opcoes.opcao12vlr = (vlrtotal + (vlrtotal * (opcao12pct * 10) / 100)) / 10
                opcoes.opcao13vlr = (vlrtotal + (vlrtotal * (opcao13pct * 11) / 100)) / 11
                opcoes.opcao14vlr = (vlrtotal + (vlrtotal * (opcao14pct * 12) / 100)) / 12

                opcoes.opcao1parcelas = 1
                opcoes.opcao2parcelas = 1
                opcoes.opcao3parcelas = 1
                opcoes.opcao4parcelas = 2
                opcoes.opcao5parcelas = 3
                opcoes.opcao6parcelas = 4
                opcoes.opcao7parcelas = 5
                opcoes.opcao8parcelas = 6
                opcoes.opcao9parcelas = 7
                opcoes.opcao10parcelas = 8
                opcoes.opcao11parcelas = 9
                opcoes.opcao12parcelas = 10
                opcoes.opcao13parcelas = 11
                opcoes.opcao14parcelas = 12

            }








            return opcoes
        }


        let pagamentos = {}

        if (this.props.configuracoes.length > 0) {
            pagamentos = calculaformas(this.props.configuracoes, vlrtotalselecionado, this.props.dividasdados)
        }



        return (
            <div>
                <Segment>


                    <Modal size='tiny' open={this.state.isrepetidoopen}
                        onClose={this.handleCloseRepetido}
                    >
                        <Modal.Header>Renegociação já Existente</Modal.Header>
                        <Modal.Content>
                            <p>Um ou mais registros selecionados já possui uma renegociação em aberto.
                            Deseja continuar e apagar a renegociação anterior e substituir por essa nova?
                    </p>
                            {
                                /*
                                <b>radio:</b>{this.state.radio}<br/>
                                <b>descricao:</b> {pagamentos['opcao' + this.state.radio + 'descricao']}<br/>
                                <b>parcelas:</b> {pagamentos['opcao' + this.state.radio + 'parcelas']}<br/>
                                <b>valor:</b> {pagamentos['opcao' + this.state.radio + 'vlr']}<br/>
                                <b>Total:</b> {pagamentos['opcao' + this.state.radio + 'vlr'] * pagamentos['opcao' + this.state.radio + 'parcelas']}<br/>
                                */
                            }

                        </Modal.Content>
                        <Modal.Actions>
                            <Button negative onClick={this.handleCloseRepetido}>Não</Button>
                            <Button positive icon='checkmark' labelPosition='right' content='Sim'
                                onClick={(e) => this.handleSave(true, pagamentos)}
                                loading={this.props.loaderissaving}
                            />
                        </Modal.Actions>
                    </Modal>

                    <Grid columns={1} relaxed='very'>
                        <Grid.Column verticalAlign='middle'>
                            <Form>

                                <Input
                                    action={{ color: 'blue', content: 'Buscar', onClick: this.handleFormSubmit.bind(this) }}
                                    icon='search'
                                    iconPosition='left'
                                    placeholder='CodParc #'
                                    onChange={this.handleInputChange.bind(this)}
                                    loading={this.props.loaderdividas}
                                    maxLength={10}
                                />
                            </Form>

                        </Grid.Column>

                    </Grid>


                </Segment>



                <If test={this.props.dividas.length > 0}>
                    <Header as='h4' floated='left'>
                        {parceiro}
                    </Header>
                    <StyledTable compact celled definition>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell>Vencimento</Table.HeaderCell>
                                <Table.HeaderCell width="3">Tipo de Título</Table.HeaderCell>
                                <Table.HeaderCell>Financeiro</Table.HeaderCell>
                                <Table.HeaderCell>Nota</Table.HeaderCell>
                                <Table.HeaderCell>Desdob.</Table.HeaderCell>
                                <Table.HeaderCell>Valor</Table.HeaderCell>
                                <Table.HeaderCell>Multa</Table.HeaderCell>
                                <Table.HeaderCell>Valor Multa</Table.HeaderCell>
                                <Table.HeaderCell>Juros</Table.HeaderCell>
                                <Table.HeaderCell>Valor Juros</Table.HeaderCell>
                                <Table.HeaderCell>Total</Table.HeaderCell>

                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {dividas.map(item => (
                                (
                                    total += (item.statusreneg == 0 ? item.vlrtotal : 0)
                                    , totalpago += (item.statusreneg == 1 ? item.vlrtotal : 0)
                                ),
                                <Table.Row key={`divida${item.nufin}`} negative={(item.statusreneg == 1 ? false : item.existereneg)} positive={(item.statusreneg == 1 ? true : false)}>
                                    <Table.Cell collapsing>
                                        <Checkbox slider
                                            checked={item.selecionado}
                                            onChange={this.handleChangeCheckBox}
                                            value={item.nufin}
                                            disabled={(item.statusreneg == 1 || item.isadsite>0? true : false)}
                                        />

                                        
                                    </Table.Cell>
                                    <Popup
                                        trigger={<Table.Cell>{moment(item.dtvenc).format("DD/MM/YYYY")}</Table.Cell>}

                                        flowing hoverable
                                    >


                                        <Grid divided columns={1}>
                                            <Grid.Column>
                                                <Header as='h4'>{`${item.regra}`}</Header>
                                                <p>
                                                    <font color='red'><b>Dias:</b></font> {item.dias} | <font color='blue'><b>Meses:</b></font> {item.meses}
                                                </p>
                                            </Grid.Column>

                                        </Grid>
                                    </Popup>
                                    <Table.Cell>{item.tipotitulo}</Table.Cell>
                                    <Table.Cell>
                                        {item.nufin}
                                        <If test={item.existereneg === 1}>
                                            <Popup trigger={<Icon circular name='handshake outline' color="red" />}>
                                                <p>
                                                    Já existe a renegociação <b>{item.idrenegociacaoweb}</b> para esse financeiro. {(item.statusreneg == 1 ? 'Pagamento:OK' : '')}<br /><br />
                                                    Acesso:<br />
                                                    <b>Código Renegociação:</b> {item.idrenegociacaoweb}<br />
                                                    <b>Código de Parceiro:</b> {item.codparc}<br />
                                                </p>
                                            </Popup>
                                        </If>
                                        <If test={item.isadsite>0}>
                                            <Popup trigger={<Icon circular name='attention' color="blue" />}>
                                                <p>
                                                    A renegociação <b>{item.idrenegociacaoweb}</b> possui créditos ADSite consumidos e não pode ser modificada.
                                                </p>
                                            </Popup>
                                        </If>
                                        

                                    </Table.Cell>
                                    <Table.Cell>{item.numnota}</Table.Cell>
                                    <Table.Cell>{item.desdobramento}</Table.Cell>
                                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrdesdob)}</Table.Cell>
                                    <Table.Cell>{item.pctmulta}%</Table.Cell>
                                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrmulta)}</Table.Cell>
                                    <Table.Cell>{item.pctjuros}%</Table.Cell>
                                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrjuros)}</Table.Cell>
                                    <Table.Cell positive>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrtotal)}</Table.Cell>



                                </Table.Row>
                            )
                            )}

                        </StyledTableBody>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell />
                                <Table.HeaderCell colSpan='10'>
                                    <Header as='h5' floated='right'>
                                        Total:<br />
                                        Já Pagos:
                                    </Header>
                                    <Button size='small' onClick={this.handleSelectAll.bind(this)} floated='left'>Selecionar Todos</Button>
                                    <If test={dividasSelecionadas.length > 0}>

                                        <Popup
                                            trigger={<Button color='red' icon='money' content='Opções de pagamento' floated='left' onClick={(e) => this.handleOpenPagamento(e)} />}
                                            on='click'
                                            position='top right'
                                            flowing
                                            open={this.state.ispagamentoopen}
                                        >
                                            <Form>
                                                <Form.Field>
                                                    {
                                                        /*
                                                            <b>radio:</b>{this.state.radio}<br/>
                                                            <b>descricao:</b> {pagamentos['opcao' + this.state.radio + 'descricao']}<br/>
                                                            <b>parcelas:</b> {pagamentos['opcao' + this.state.radio + 'parcelas']}<br/>
                                                            <b>valor:</b> {pagamentos['opcao' + this.state.radio + 'vlr']}<br/>
                                                            <b>Total:</b> {pagamentos['opcao' + this.state.radio + 'vlr'] * pagamentos['opcao' + this.state.radio + 'parcelas']}<br/>    
                                                        */
                                                    }



                                                    <b>Escolha a forma de pagamento:</b>

                                                </Form.Field>





                                                {
                                                    //<If test={this.props.credito > 0 }>
                                                }

                                                <If test={this.props.credito > 0}>

                                                    <StyledCheckbox label={`Utilizar crédito ADSite: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.props.credito)}`}
                                                        checked={this.state.chkcreditoadsite}
                                                        onChange={this.toggle}
                                                    />

                                                    <br />
                                                </If>


                                                <If test={pagamentos.opcao1status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao1descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao1vlr)}`}
                                                        name='radioGroup'
                                                        value="1"
                                                        checked={this.state.radio === '1'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao2status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao2descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao2vlr)}`}
                                                        name='radioGroup'
                                                        value="2"
                                                        checked={this.state.radio === '2'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao3status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao3descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao3vlr)}`}
                                                        name='radioGroup'
                                                        value="3"
                                                        checked={this.state.radio === '3'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>


                                                <If test={pagamentos.opcao4status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao4descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao4vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao4vlr * 2)})`}
                                                        name='radioGroup'
                                                        value="4"
                                                        checked={this.state.radio === '4'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao5status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao5descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao5vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao5vlr * 3)})`}
                                                        name='radioGroup'
                                                        value="5"
                                                        checked={this.state.radio === '5'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao6status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao6descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao6vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao6vlr * 4)})`}
                                                        name='radioGroup'
                                                        value="6"
                                                        checked={this.state.radio === '6'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao7status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao7descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao7vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao7vlr * 5)})`}
                                                        name='radioGroup'
                                                        value="7"
                                                        checked={this.state.radio === '7'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao8status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao8descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao8vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao8vlr * 6)})`}
                                                        name='radioGroup'
                                                        value="8"
                                                        checked={this.state.radio === '8'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao9status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao9descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao9vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao9vlr * 7)})`}
                                                        name='radioGroup'
                                                        value="9"
                                                        checked={this.state.radio === '9'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao10status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao10descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao10vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao10vlr * 8)})`}
                                                        name='radioGroup'
                                                        value="10"
                                                        checked={this.state.radio === '10'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao11status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao11descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao11vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao11vlr * 9)})`}
                                                        name='radioGroup'
                                                        value="11"
                                                        checked={this.state.radio === '11'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao12status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao12descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao12vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao12vlr * 10)})`}
                                                        name='radioGroup'
                                                        value="12"
                                                        checked={this.state.radio === '12'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao13status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao13descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao13vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao13vlr * 11)})`}
                                                        name='radioGroup'
                                                        value="13"
                                                        checked={this.state.radio === '13'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>

                                                <If test={pagamentos.opcao14status && pagamentos.opcao1vlr > 0}>
                                                    <Radio
                                                        label={`${pagamentos.opcao14descricao}: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao14vlr)} (${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pagamentos.opcao14vlr * 12)})`}
                                                        name='radioGroup'
                                                        value="14"
                                                        checked={this.state.radio === '14'}
                                                        onChange={this.handleChangeRadio}
                                                    /><br />
                                                </If>


                                                <If test={pagamentos.opcao1vlr <= 0 && this.props.credito != undefined}>




                                                    <p align="center">
                                                        
                                                        {
                                                        /*
                                                        
                                                        O valor da(s) dívida(s) selecionada(s) tem que ser <br/>maior que o valor do crédito ADSite disponível sem deságio<br /><br />
                                                        <Label as='a' color='teal'>
                                                            {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format((pagamentos.opcao1vlr * -1))}
                                                        </Label>*/
                                                        }
                                                    </p>
                                                    <br /><br />

                                                </If>

                                            </Form>

                                            <Button color='green' content='Confirmar' onClick={(e) => this.handleConfirmaPagamento(e, pagamentos)} loading={this.props.loaderissaving} />
                                            <Button color='grey' content='Cancelar' onClick={(e) => this.handleClosePagamento(e)} />

                                            <Button color='green' content='Simulação E-mail' onClick={(e) => this.handleConfirmaEmail(e, pagamentos)} loading={this.props.loaderissimulating} />


                                        </Popup>
                                        <Header as='h5' floated='left' color='grey'>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(vlrtotalselecionado)} selecionado para renegociação</Header>
                                    </If>
                                </Table.HeaderCell>
                                <Table.HeaderCell verticalAlign="top">
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(total)}<br />
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalpago)}
                                </Table.HeaderCell>


                            </Table.Row>
                        </Table.Footer>

                    </StyledTable>
                </If>


                <If test={this.props.dividas.filter(d => d.selecionado == true).length > 0}>

                    <Checkbox label='Exibir log de Créditos'
                        checked={this.state.chklog}
                        onChange={this.togglelog}
                    />

                    <br />
                </If>



                <If test={this.state.chklog == true}>

                    Divida Dados antes
                    <StyledTable color={'black'} key={'dividaantes'} inverted>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell>codparc</Table.HeaderCell>
                                <Table.HeaderCell>nufin</Table.HeaderCell>
                                <Table.HeaderCell>numnota</Table.HeaderCell>
                                <Table.HeaderCell>vlrdesdob</Table.HeaderCell>
                                <Table.HeaderCell>dias</Table.HeaderCell>
                                <Table.HeaderCell>meses</Table.HeaderCell>
                                <Table.HeaderCell>vlrtotal</Table.HeaderCell>
                                <Table.HeaderCell>pctjuros</Table.HeaderCell>
                                <Table.HeaderCell>vlrjuros</Table.HeaderCell>
                                <Table.HeaderCell>pctmulta</Table.HeaderCell>
                                <Table.HeaderCell>vlrmulta</Table.HeaderCell>

                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {this.props.dividas.filter(d => d.selecionado == true).map(item => (
                                (
                                    totalvlrdesdobantes += item.vlrdesdob
                                    , totalvlrtotalantes += item.vlrtotal
                                    , totalvlrjurosantes += item.vlrjuros
                                    , totalvlrmultaantes += item.vlrmulta
                                ),
                                <Table.Row>
                                    <Table.Cell>{item.codparc}</Table.Cell>
                                    <Table.Cell>{item.nufin}</Table.Cell>
                                    <Table.Cell>{item.numnota}</Table.Cell>
                                    <Table.Cell>{item.vlrdesdob}</Table.Cell>
                                    <Table.Cell>{item.dias}</Table.Cell>
                                    <Table.Cell>{item.meses}</Table.Cell>
                                    <Table.Cell>{item.vlrtotal}</Table.Cell>
                                    <Table.Cell>{item.pctjuros}</Table.Cell>
                                    <Table.Cell>{item.vlrjuros}</Table.Cell>
                                    <Table.Cell>{item.pctmulta}</Table.Cell>
                                    <Table.Cell>{item.vlrmulta}</Table.Cell>

                                </Table.Row>
                            ))}
                        </StyledTableBody>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>

                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    {totalvlrdesdobantes.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell>
                                    {totalvlrtotalantes.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell>
                                    {totalvlrjurosantes.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell>
                                    {totalvlrmultaantes.toFixed(2)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </StyledTable>



                    Créditos
                    <StyledTable color={'grey'} key={'creditos'} inverted>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell>nufindesp</Table.HeaderCell>
                                <Table.HeaderCell>vlrdesdob</Table.HeaderCell>
                                <Table.HeaderCell>vlrdesagio</Table.HeaderCell>
                                <Table.HeaderCell>descontoadsite</Table.HeaderCell>
                                <Table.HeaderCell>vlrutilizavel</Table.HeaderCell>
                                <Table.HeaderCell>percjurosrec</Table.HeaderCell>
                                <Table.HeaderCell>percjurosdesp</Table.HeaderCell>
                                <Table.HeaderCell>utilizou</Table.HeaderCell>
                                <Table.HeaderCell>vlrcomdesagio</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {this.props.creditodados.map(item => (
                                (
                                    totalvlrdesdobcredito += (item.original == 1 ? item.vlrdesdob : 0)
                                    , totalvlrdesagiocredito += (item.original == 1 ? item.vlrdesagio : 0)
                                    , totaldescontoadsitecredito += item.descontoadsite
                                    , totalvlrutilizavelcredito += (item.original == 1 ? item.vlrutilizavel : 0)
                                ),
                                <Table.Row>
                                    <Table.Cell>{item.nufindesp}</Table.Cell>
                                    <Table.Cell>{item.vlrdesdob}</Table.Cell>
                                    <Table.Cell>{item.vlrdesagio}</Table.Cell>
                                    <Table.Cell>{item.descontoadsite}</Table.Cell>
                                    <Table.Cell>{item.vlrutilizavel}</Table.Cell>
                                    <Table.Cell>{item.percjurosrec}</Table.Cell>
                                    <Table.Cell>{item.percjurosdesp}</Table.Cell>
                                    <Table.Cell>{item.utilizou}</Table.Cell>
                                    <Table.Cell>{item.vlrcomdesagio}</Table.Cell>

                                </Table.Row>
                            ))}
                        </StyledTableBody>
                        <Table.Footer fullWidth>
                            <Table.Row>


                                <Table.HeaderCell>
                                    Total:{(totalvlrdesdobcredito - totaldescontoadsitecredito).toFixed(2)}
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    {totalvlrdesdobcredito.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {totalvlrdesagiocredito.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {totaldescontoadsitecredito.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {totalvlrutilizavelcredito.toFixed(2)}
                                </Table.HeaderCell>

                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell>
                                    Pagar:{(totalvlrtotalantes - (totalvlrdesdobcredito - totaldescontoadsitecredito)).toFixed(2)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </StyledTable>



                    Compensação
                    <StyledTable color={'red'} key={'compes'} inverted>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell>nufindesp</Table.HeaderCell>
                                <Table.HeaderCell>dtvenc</Table.HeaderCell>
                                <Table.HeaderCell>tiptit</Table.HeaderCell>
                                <Table.HeaderCell>nufinrec</Table.HeaderCell>
                                <Table.HeaderCell>nucompens</Table.HeaderCell>
                                <Table.HeaderCell>vlrbaixa</Table.HeaderCell>
                                <Table.HeaderCell>vlrutilizado</Table.HeaderCell>
                                <Table.HeaderCell>vlrdesc</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {this.props.compesdados.map(item => ((
                                totalvlrbaixa += item.vlrbaixa
                                , totalvlrutilizado += item.vlrutilizado
                                , totalvlrdesc += item.vlrdesc
                            ),
                                <Table.Row>
                                    <Table.Cell>{item.nufindesp}</Table.Cell>
                                    <Table.Cell>{item.dtvenc}</Table.Cell>
                                    <Table.Cell>{item.tiptit}</Table.Cell>
                                    <Table.Cell>{item.nufinrec}</Table.Cell>
                                    <Table.Cell>{item.nucompens}</Table.Cell>
                                    <Table.Cell>{item.vlrbaixa}</Table.Cell>
                                    <Table.Cell>{item.vlrutilizado}</Table.Cell>
                                    <Table.Cell>{item.vlrdesc}</Table.Cell>
                                </Table.Row>
                            ))}
                        </StyledTableBody>

                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='5'>

                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    {totalvlrbaixa.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {totalvlrutilizado.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {totalvlrdesc.toFixed(2)}
                                </Table.HeaderCell>
                            </Table.Row>
                        </Table.Footer>
                    </StyledTable>



                    Divida Dados depois
                    <StyledTable color={'orange'} key={'divida'} inverted>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell>codparc</Table.HeaderCell>
                                <Table.HeaderCell>nufin</Table.HeaderCell>
                                <Table.HeaderCell>numnota</Table.HeaderCell>
                                <Table.HeaderCell>vlrdesdob</Table.HeaderCell>
                                <Table.HeaderCell>vlrcompens</Table.HeaderCell>
                                <Table.HeaderCell>dias</Table.HeaderCell>
                                <Table.HeaderCell>meses</Table.HeaderCell>
                                <Table.HeaderCell>vlrtotal</Table.HeaderCell>
                                <Table.HeaderCell>pctjuros</Table.HeaderCell>
                                <Table.HeaderCell>vlrjuros</Table.HeaderCell>
                                <Table.HeaderCell>pctmulta</Table.HeaderCell>
                                <Table.HeaderCell>vlrmulta</Table.HeaderCell>
                                <Table.HeaderCell>parcial</Table.HeaderCell>
                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {this.props.dividasdados.map(item => ((
                                totalvlrdesdobdepois += item.vlrdesdob
                                , totalvlrtotaldepois += item.vlrtotal
                                , totalvlrjurosdepois += item.vlrjuros
                                , totalvlrmultadepois += item.vlrmulta
                                , totalvlrcompensadodepois += item.vlrcompensado
                            ),
                                <Table.Row>
                                    <Table.Cell>{item.codparc}</Table.Cell>
                                    <Table.Cell>{item.nufin}</Table.Cell>
                                    <Table.Cell>{item.numnota}</Table.Cell>
                                    <Table.Cell>{item.vlrdesdob}</Table.Cell>
                                    <Table.Cell>{item.vlrcompensado}</Table.Cell>
                                    <Table.Cell>{item.dias}</Table.Cell>
                                    <Table.Cell>{item.meses}</Table.Cell>
                                    <Table.Cell>{item.vlrtotal}</Table.Cell>
                                    <Table.Cell>{item.pctjuros}</Table.Cell>
                                    <Table.Cell>{item.vlrjuros}</Table.Cell>
                                    <Table.Cell>{item.pctmulta}</Table.Cell>
                                    <Table.Cell>{item.vlrmulta}</Table.Cell>
                                    <Table.Cell>{item.parcial}</Table.Cell>
                                </Table.Row>
                            ))}
                        </StyledTableBody>
                        <Table.Footer fullWidth>
                            <Table.Row>
                                <Table.HeaderCell colSpan='3'>
                                    TOTAL:  {(totalvlrdesdobdepois + totalvlrcompensadodepois).toFixed(2)}
                                </Table.HeaderCell>

                                <Table.HeaderCell>
                                    {totalvlrdesdobdepois.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell>
                                    {totalvlrcompensadodepois.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell />
                                <Table.HeaderCell>
                                    {totalvlrtotaldepois.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell>
                                    {totalvlrjurosdepois.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                                <Table.HeaderCell>
                                    {totalvlrmultadepois.toFixed(2)}
                                </Table.HeaderCell>
                                <Table.HeaderCell />
                            </Table.Row>
                        </Table.Footer>
                    </StyledTable>
                </If>





            </div>

        )
    }

}

Renegociacao.defaultProps = {
    configuracoes: []
    , dividas: []
    , loaderdividas: false
    , userid: 0
    , loaderissaving: false
};

Renegociacao.propTypes = {
    configuracoes: PropTypes.array.isRequired
    , dividas: PropTypes.array.isRequired
    , loaderdividas: PropTypes.bool.isRequired
    , renegociacaoListDividasAction: PropTypes.func.isRequired
    , renegociacaoCheckItemAction: PropTypes.func.isRequired
    , renegociacaoCheckAllAction: PropTypes.func.isRequired
    , renegociacaoSaveAction: PropTypes.func.isRequired
    , userid: PropTypes.number.isRequired
    , loaderissaving: PropTypes.bool.isRequired

    , renegociacaoSimulacaoAction: PropTypes.func.isRequired
    , loaderissimulating: PropTypes.bool.isRequired

    , renegociacaoListCreditoAction: PropTypes.func.isRequired
    , credito: PropTypes.number.credito
    , codparc: PropTypes.number.codparc

    , creditodados: PropTypes.array.isRequired
    , compesdados: PropTypes.array.isRequired
    , dividasdados: PropTypes.array.isRequired

};

export default Renegociacao

