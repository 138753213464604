import {
    PESQUISA_LIST
    , PESQUISA_LOADER
    , PESQUISA_CHANGE
    , PESQUISA_CHANGE_STATUS

} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';



export const pesquisaHandleChangeAction = (event) => {
    return {
        type: PESQUISA_CHANGE
        , field: event.target.name
        , payload: event.target.value
    }
}


export const pesquisaListAction = (userid) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getPesquisaTarefas(userid).then(dados => {
            dispatch({ type: PESQUISA_LIST, payload: dados })
            dispatch({ type: PESQUISA_LOADER, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })

    });


export const pesquisaCheckAction = (tarefaid,login) => (dispatch) =>
    new Promise(function (resolve, reject) { 
        Api.getPesquisaCheck(tarefaid,login).then(dados => {
            dispatch({ type: PESQUISA_CHANGE, field:'avaliacaogestor', payload: dados[0].avaliacaogestor })

            dispatch(
                {type:PESQUISA_CHANGE_STATUS, tarefaid:tarefaid, statusdescricao:dados[0].statusdescricao
                , dtstatus:dados[0].dtstatus, status: dados[0].status}
                )
            //console.log('veio os dados:')
            //console.log(dados)
           //dispatch({ type: PESQUISA_LOADER, payload: false })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });


    export const pesquisaSaveAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) { 
        //STP_TI_PESQUISA_SAVE_INTERNO
        Api.postPesquisaSave(entidade).then(dados => {
            
            resolve(dados)
        }).catch(error => {
            
            reject(error);
        })
    });


    //