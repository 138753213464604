import React, { useState } from 'react';
import { Line } from 'react-chartjs-2';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
var moment = require('moment');
import { kpivisitasListAction } from '../../actions/KpiVisitasActions';

function KpiChart(props) {
    const [hideLines, setHideLines] = useState(false);

    const { kpivisitasListAction } = props;

    const { kpivisitas } = props;


    const colorPalette = [
        '#FF6384', '#36A2EB', '#FFCE56', '#4BC0C0', '#9966FF', '#FF9F40',
        '#8B0000', '#4682B4', '#32CD32', '#FF4500', '#DA70D6', '#010bf6',
        '#692a00', '#FFD700', '#CD5C5C', '#00FA9A', '#4169E1', '#FA8072',
        '#2E8B57', '#D2691E'
    ];

    const transformData = (kpivisitas, arrayAnos) => {
        const arrayAnosFiltrado = arrayAnos.slice(0, arrayAnos.length - 1);
        const funcionarios = [...new Set(kpivisitas.map(item => item.consultor))];

        return funcionarios.map((funcionario, index) => {
            const dados = arrayAnosFiltrado.map(ano => {
                const item = kpivisitas.find(i => i.consultor === funcionario && moment(i.dt_ref).format('YYYY/MM') === ano);
                return item ? item.pontos_total : 0;
            });

            return {
                label: funcionario,
                data: dados,
                fill: false,
                pointRadius: 5, // Aumenta o tamanho dos pontos
                pointHoverRadius: 7,
                backgroundColor: colorPalette[index % colorPalette.length],
                borderColor: colorPalette[index % colorPalette.length],
                tension: 0.1,
                hidden: hideLines // Ajuste conforme necessário
            };
        });
    };


    const arrayAnos = [...new Set(kpivisitas.map(item => moment(item.dt_ref).format('YYYY/MM')))];
    arrayAnos.sort((a, b) => a.localeCompare(b));

    const data = {
        datasets: transformData(kpivisitas, arrayAnos) // Dados formatados por funcionário
    };


    data.labels = arrayAnos.slice(0, arrayAnos.length - 1)

    // data.labels.push("Março/2024", "Abril/2024", "Maio/2024", "Junho/2024", "Julho/2024", "Agosto/2024")

    const options = {
        scales: {
            y: {
                beginAtZero: true,
            },
        },
        responsive: true,
        animation: {
            onComplete: function () {
                console.log('Animation complete');
            },
            delay: (context) => {
                let delay = 0;
                if (context.type === 'data' && context.mode === 'default' && !context.dropped) {
                    delay = context.dataIndex * 300 + context.datasetIndex * 100;
                    context.dropped = true;
                }
                return delay;
            },
            duration: 2000,
            easing: 'easeInOutQuart',
        }
    };

    const handleHideLines = () => {
        setHideLines(!hideLines);
    };

    return (
        <div style={{ marginTop: '20px' }}>
            <p><b>Gráfico da pontuação geral:</b></p>
            <button style={{ all: 'unset', display: 'flex', flexDirection: 'row', alignItems: 'center', marginLeft: '40px' }} onClick={handleHideLines}>
                <div style={{ width: '42px', height: '15px', backgroundColor: '#000', marginRight: 5 }} />
                <span style={{ color: '#656565', fontSize: 12, textDecoration: hideLines ? 'line-through' : 'none' }}>Ocultar linhas</span>
            </button>

            <Line data={data} width={450} heigth={450} options={options} />

        </div>
    );
}

const mapStateToProps = (state) => ({
    kpivisitas: state.KpiVisitasReducer.kpivisitas
})

export default withRouter(connect(mapStateToProps, {
    kpivisitasListAction
}
)(KpiChart));
