import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
    Dropdown
} from 'semantic-ui-react'

import If from '../../utils/If';


class Drop extends Component {

    state = {value:'45773', mostra:true}

    constructor(props) {
        super(props);
    }

    componentWillReceiveProps() {

    }

    componentDidMount() {

        this.handleChange()

    }

    handleChange() {
        
        this.setState({value: this.props.defaultValue});
        //this.setState({mostra:true});
        this.setState({mostra:false});
        this.setState({mostra:true});
      }


    render() {


        return (

        <If test={this.props.options.length >0}>
        <Dropdown
            options={this.props.options}
            selection
            placeholder='Motoristas'
            name='motoristas'
            fluid
            //defaultValue={this.state.value}
            //onChange={this.handleVendedores}
            //value="45773"
            defaultValue="45773"
        />
        {this.state.value}
        </If>
        
           
        )

    }

}

Drop.defaultProps = {
    options:[]
    , defaultValue: '45773'
}

Drop.propTypes = {
    options: PropTypes.array.isRequired
    ,defaultValue: PropTypes.string.isRequired
};

export default Drop


