import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import {
    Table
    , Header

} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




class Clientes extends Component {


    state = {}

    constructor(props) {
        super(props);

    }


    componentWillReceiveProps() {


    }

    componentDidMount() {

    }




    render() {

        let { datalist } = this.props



        return (
            <div>



                <Header as='h3' dividing>
                    Black Friday {new Date().getFullYear()}
                </Header>
                <StyledTable celled id="listClientes" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="3">Nome</Table.HeaderCell>
                            <Table.HeaderCell width="3">E-mail</Table.HeaderCell>
                            <Table.HeaderCell>Whatsaap</Table.HeaderCell>
                            <Table.HeaderCell>Aceita SMS</Table.HeaderCell>
                            <Table.HeaderCell>Campanha</Table.HeaderCell>
                            <Table.HeaderCell>Cidade de Entrega</Table.HeaderCell>
                            <Table.HeaderCell>Categorias</Table.HeaderCell>
                            <Table.HeaderCell>Data</Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {datalist.map(item => (
                            <Table.Row key={"rel_" + new Date().getMilliseconds}>
                                <Table.Cell>{item.nome}</Table.Cell>
                                <Table.Cell>{item.email}</Table.Cell>
                                <Table.Cell>{item.whatsapp}</Table.Cell>
                                <Table.Cell>{(item.sendsms?'Sim':'Não')}</Table.Cell>
                                <Table.Cell>{item.campanha}</Table.Cell>
                                <Table.Cell>{item.cidade}</Table.Cell>
                                <Table.Cell>{item.categorias}</Table.Cell>
                                <Table.Cell>{item.dataregistro}</Table.Cell>
                                
                            </Table.Row>
                        ))}


                    </StyledTableBody>
                </StyledTable>





            </div>

        )
    }

}

Clientes.defaultProps = {
    datalist: []

};

Clientes.propTypes = {
    datalist: PropTypes.array.isRequired


};

export default Clientes

