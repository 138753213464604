import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import moment from 'moment';
import 'moment/locale/pt-br'


import {
    Table, Form, Segment, Button

} from 'semantic-ui-react'

import If from '../../utils/If';

import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import styled from 'styled-components';


import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class Bonus extends Component {
    constructor(props) {
        super(props);
    }
    componentWillReceiveProps() {


    }

    componentDidMount() {



    }

    handleBusca=()=>{
        if(!this.props.isSearching){
            this.props.instaladoresListSearchAction(this.props.entidade);
        }
        

    }


    render() {

        let { datalist } = this.props

        return (

            <div>
                <Segment secondary>

                    <Form loading={this.props.isSearching}>
                        <Form.Group widths='equal'>
                        

                            <Form.Input name="cupom" fluid label='Cupom' placeholder='Informe o Cupom do instalador'
                                maxLength={50}
                                onChange={this.props.instaladoresUpdateAction} 
                                />

                        </Form.Group>
                        <Form.Group widths='eleven'>

                  






                            <div className="field"><label>&nbsp;</label>

                                <Form.Button primary
                                    onClick={this.handleBusca}
                                >Buscar</Form.Button>
                            </div>

                            






                        </Form.Group>
                        {
                            /*
                            (this.props.validacao.length > 0 ? (
                                <Message negative>
                                    {this.props.validacao.map(valida => (
                                        <p key={valida.id}>{valida.mensagem}</p>
                                    ))}

                                </Message>
                            ) : (
                                    <div></div>
                                ))*/
                        }


                    </Form>
                </Segment>
                <If test={datalist.length > 0}>
                    {`Encontramos ${datalist.length} registro(s)`}

                    <ExcelFile element={<Button basic color={'blue'} size="mini">Exportar para Excel</Button>}>
                    <ExcelSheet data={datalist} name={`Fechamento`}>
                        <ExcelColumn label="Pedido FBits" value="pedidofbits"/>
                        <ExcelColumn label="Nro Único" value="nrounico"/>
                        <ExcelColumn label="Nota" value="nota"/>
                        <ExcelColumn label="Danfe" value="danfe"/>
                        <ExcelColumn label="DT Faturamento" value="dtfatur"/>
                        <ExcelColumn label="CodParc" value="codparc"/>
                        <ExcelColumn label="Parceiro" value="parceiro"/>
                        
                        <ExcelColumn label="CodTipOper" value="codtipoper"/>
                        <ExcelColumn label="TipMov" value="tipmov"/>
                        <ExcelColumn label="Vlr Frete" value="vlrfrete"/>
                        <ExcelColumn label="Vlr Nota" value="vlrnota"/>
                        <ExcelColumn label="Cidade" value="cidade"/>

                 
                    </ExcelSheet>
                </ExcelFile>
                </If>


                


                <StyledTable id="listFechamento" compact celled >
                    <StyledTableHeader>
                        <Table.Row>

                            <Table.HeaderCell>Pedido FBits</Table.HeaderCell>
                            <Table.HeaderCell>NRO Único</Table.HeaderCell>
                            <Table.HeaderCell>Nota</Table.HeaderCell>
                            <Table.HeaderCell>DANFE</Table.HeaderCell>
                            <Table.HeaderCell>DT Fatur</Table.HeaderCell>
                            <Table.HeaderCell>CodParc</Table.HeaderCell>
                            <Table.HeaderCell>Parceiro</Table.HeaderCell>
                            <Table.HeaderCell>CodTipOper</Table.HeaderCell>
                            <Table.HeaderCell>TipMov</Table.HeaderCell>
                            <Table.HeaderCell>Frete</Table.HeaderCell>
                            <Table.HeaderCell>Vlr Nota</Table.HeaderCell>
                            <Table.HeaderCell>Cidade</Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>

                        {datalist.map(item => (
                            <Table.Row key={"search_" + item.pedidofbits}>

                                <Table.Cell>{item.pedidofbits}</Table.Cell>
                                <Table.Cell>{item.nrounico}</Table.Cell>
                                <Table.Cell>{item.nota}</Table.Cell>
                                <Table.Cell>{item.danfe}</Table.Cell>
                                <Table.Cell>{moment(item.dtfatur).format("DD/MM/YYYY")}</Table.Cell>
                                <Table.Cell>{item.codparc}</Table.Cell>
                                <Table.Cell>{item.parceiro}</Table.Cell>
                                <Table.Cell>{item.codtipoper}</Table.Cell>
                                <Table.Cell>{item.tipmov}</Table.Cell>
                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrfrete)}</Table.Cell>
                                <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrnota)}</Table.Cell>
                                <Table.Cell>{item.cidade}</Table.Cell>
                                
                            </Table.Row>
                        ))}



                    </StyledTableBody>
                </StyledTable>
            </div>

        )
    }


}

Bonus.defaultProps = {
    datalist: []
    , entidade: {}
}

Bonus.propTypes = {
    datalist: PropTypes.array.isRequired
    , entidade: PropTypes.object.isRequired
    , instaladoresListSearchAction: PropTypes.func.isRequired
    , instaladoresUpdateAction: PropTypes.func.isRequired
    , isSearching: PropTypes.bool.isRequired

}

export default Bonus

