import {
    RENEGOCIACAO_LIST_CONFIGURACOES
    ,RENEGOCIACAO_LIST_DIVIDAS
    ,RENEGOCIACAO_LOADER_DIVIDAS
    ,RENEGOCIACAO_CHECK_ITEM
    ,RENEGOCIACAO_CHECK_ALL
    , RENEGOCIACAO_UPDATE_CONFIG
    , RENEGOCIACAO_LOADER_ISSAVING
    , RENEGOCIACAO_LOADER_CONFIG
    , RENEGOCIACAO_LIST_CONSULTA
    , RENEGOCIACAO_LOADER_CONSULTA
    , RENEGOCIACAO_LOADER_ISSIMULATING
    , RENEGOCIACAO_LIST_CREDITO
    , RENEGOCIACAO_ADD_PARCEIRO
    , RENEGOCIACAO_LIST_CREDITODADOS
    , RENEGOCIACAO_LIST_COMPENSDADOS
    , RENEGOCIACAO_LIST_DIVIDASDADOS

    , RENEGOCIACAO_CANCEL_CONSULTA
    , RENEGOCIACAO_ISCANCELING

} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    configuracoes:[]
    ,dividas:[]
    ,loaderdividas: false
    ,loaderissaving: false
    ,loaderconfig: false
    ,consulta:[]
    ,loaderconsulta:false
    ,loaderissimulating:false
    ,credito:0
    ,creditodados:[]
    ,codparc:0
    ,compesdados:[]
    ,dividasdados:[]
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case RENEGOCIACAO_LIST_COMPENSDADOS:
            return {...state, compesdados: action.payload}

        case RENEGOCIACAO_LIST_DIVIDASDADOS:
            return {...state, dividasdados: action.payload}

        case RENEGOCIACAO_LIST_CREDITO:
            return {...state, credito: action.payload}
        
        case RENEGOCIACAO_LIST_CREDITODADOS:
            return {...state, creditodados: action.payload}

        
        case RENEGOCIACAO_ADD_PARCEIRO:
            return {...state, codparc: action.payload}

        case RENEGOCIACAO_LOADER_ISSIMULATING:
            return {...state, loaderissimulating: action.payload}
        
        case RENEGOCIACAO_LIST_CONFIGURACOES:
            return { ...state, configuracoes: action.payload }

        case RENEGOCIACAO_LIST_DIVIDAS:
            return { ...state, dividas:action.payload}

        case RENEGOCIACAO_LOADER_DIVIDAS:
            return { ...state, loaderdividas: action.payload }       

        case RENEGOCIACAO_CHECK_ITEM:
            return {
                ...state,
                dividas: state.dividas.map(
                    (divida, i) => divida.nufin === action.payload ?
                        { ...divida, selecionado: (divida.selecionado?false:true) }
                        : divida
                )
            }
               
        case RENEGOCIACAO_CHECK_ALL:
            return {
                ...state,
                dividas: state.dividas.map(
                    (divida, i) => ({ ...divida, selecionado: (action.payload?false:(divida.statusreneg==1||divida.isadsite>0?false:true)) })
                    
                        //divida.nufin === divida.nufin ?
                        //{ ...divida, selecionado: (action.payload?false:true) }
                        //: divida
                )
            }


        case RENEGOCIACAO_UPDATE_CONFIG:
            return {
                ...state,
                configuracoes: state.configuracoes.map(
                    (configuracao, i) => configuracao.configid === action.configid ?
                        { ...configuracao, valor: action.payload }
                        : configuracao
                )
            }
        
        case RENEGOCIACAO_LOADER_ISSAVING:
            return {...state, loaderissaving:action.payload}

        case RENEGOCIACAO_LOADER_CONFIG:
            return{...state, loaderconfig:action.payload}


        case RENEGOCIACAO_LIST_CONSULTA:
            return {...state,consulta:action.payload}
        
        case RENEGOCIACAO_LOADER_CONSULTA:
            return {...state,loaderconsulta:action.payload}

        
        case RENEGOCIACAO_CANCEL_CONSULTA:
            return { ...state, consulta: [...state.consulta.filter(c => action.payload !== c.idrenegociacaoweb)] }


        case RENEGOCIACAO_ISCANCELING:
                return {
                    ...state,
                    consulta: state.consulta.map(
                        (item, i) => item.idrenegociacaoweb === action.idrenegociacaoweb ?
                            { ...item, iscanceling: action.payload }
                            : item
                    )
                }
    
            

        



        default:
            return state;
    }
}