import React, { Component } from 'react'

import { Button } from 'semantic-ui-react'
import { Table, Segment, Icon, Grid, Checkbox, Divider, Header, Input } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';

import If from '../../utils/If';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}
`


class Contratos extends Component {


    state = { editing: false, prioritario:false }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

      

    }


    toggleedit = () => {

        if(this.state.editing){
            this.setState({ prioritario: false })    
        }
      
        this.setState({ editing: !this.state.editing })
       

    }

    toggleprioritario = () => {

        this.setState({ prioritario: !this.state.prioritario })

    }


    render() {


        let {editing, prioritario} = this.state

        return (<div>

            <StyledTable compact celled>
                <StyledTableHeader>
                    <Table.Row>
                        <Table.HeaderCell width="1"></Table.HeaderCell>
                        <Table.HeaderCell width="1">Contrato</Table.HeaderCell>
                        <Table.HeaderCell>Descricao</Table.HeaderCell>
                        <Table.HeaderCell width="1">UF</Table.HeaderCell>
                        <Table.HeaderCell>Tipo</Table.HeaderCell>
                        <Table.HeaderCell width="2">Fator Preço</Table.HeaderCell>
                        <Table.HeaderCell width="1">Prioritário</Table.HeaderCell>

                    </Table.Row>
                </StyledTableHeader>

                <StyledTableBody>
                    <Table.Row key={`con0`}>
                        <Table.Cell textAlign="center">
                            <Button compact
                                color='blue'
                            //onClick={(e) => this.onChangeModalOpenChegada(e, item)} 
                            >Ver</Button>
                        </Table.Cell>
                        <Table.Cell>121314</Table.Cell>
                        <Table.Cell>Transporte Padrão SP</Table.Cell>
                        <Table.Cell>SP</Table.Cell>
                        <Table.Cell>Fora de Rota</Table.Cell>
                        <Table.Cell>1</Table.Cell>
                        <Table.Cell textAlign='center'></Table.Cell>
                    </Table.Row>

                    <Table.Row key={`con1`} active={true}>
                        <Table.Cell textAlign="center">
                            <Button compact
                                color='blue'
                            //onClick={(e) => this.onChangeModalOpenChegada(e, item)} 
                            >Ver</Button>
                        </Table.Cell>
                        <Table.Cell>121314</Table.Cell>
                        <Table.Cell>Transporte Padrão SP</Table.Cell>
                        <Table.Cell>SP</Table.Cell>
                        <Table.Cell>Fora de Rota</Table.Cell>
                        <Table.Cell>1</Table.Cell>
                        <Table.Cell textAlign='center'><Icon color='green' name='checkmark' size='large' /></Table.Cell>
                    </Table.Row>

                    <Table.Row key={`con2`}>
                        <Table.Cell textAlign="center">
                            <Button compact
                                color='blue'
                            //onClick={(e) => this.onChangeModalOpenChegada(e, item)} 
                            >Ver</Button>
                        </Table.Cell>
                        <Table.Cell>121314</Table.Cell>
                        <Table.Cell>Transporte Padrão SP</Table.Cell>
                        <Table.Cell>SP</Table.Cell>
                        <Table.Cell>Fora de Rota</Table.Cell>
                        <Table.Cell>1</Table.Cell>
                        <Table.Cell textAlign='center'></Table.Cell>
                    </Table.Row>

                </StyledTableBody>

            </StyledTable>

            <Segment>

                <Grid columns={2} doubling>
                    <Grid.Column>
                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='bar chart' />
                                Contrato Principal
                            </Header>
                        </Divider>
                        <Table definition>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={2}>Editar</Table.Cell>
                                    <Table.Cell>
                                        <Checkbox toggle 
                                        value={this.editing}
                                        onChange={this.toggleedit}
                                        />
                                    </Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={2}>Contrato</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="codcontrato" placeholder='' value="99999999" />:"99999999")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Descrição</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="contrato" placeholder='' value="Transporte Padrão SP" />:"Transporte Padrão SP")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>UF</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="uf" placeholder='' value="SP" />:"SP")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Tipo</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="tipodivisao" placeholder='' value="Fora de Rota" />:"Fora de Rota")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Divisão</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="divisao" placeholder='' value="Rotas de SP" />:"Rotas de SP")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>F. Preço</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="fatorpreco" placeholder='' value="1" />:"1")}</Table.Cell>
                                </Table.Row>
                            </Table.Body>
                        </Table>
                    </Grid.Column>


                    <Grid.Column>
                        <Divider horizontal>
                            <Header as='h4'>
                                <Icon name='bar chart' />
                                Contrato Prioritário
                            </Header>
                        </Divider>

                        <Table definition>
                            <Table.Body>
                                <Table.Row>
                                    <Table.Cell width={2}>Prioritário</Table.Cell>
                                    <Table.Cell><Checkbox toggle disabled={(editing?false:true)} 
                                    checked={prioritario}
                                    onChange={this.toggleprioritario}
                                    
                                    /></Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell width={2}>Contrato</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="prioricodcontrato" placeholder='' value="121314" />:"121314")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>Descrição</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="prioricontrato" placeholder='' value="Transporte Padrão SP" />:"Transporte Padrão SP")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>P. Preço</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="prioridifpreco" placeholder='' value="1" />:"1")}</Table.Cell>
                                </Table.Row>
                                <Table.Row>
                                    <Table.Cell>P. Prazo</Table.Cell>
                                    <Table.Cell>{(editing?<Input name="prioridifprazo" placeholder='' value="1" />:"1")}</Table.Cell>
                                </Table.Row>

                            </Table.Body>
                        </Table>
                        <If test={editing}>
                            <Button primary >Salvar</Button>
                            <Button secondary >Cancelar</Button>
                        </If>
                    </Grid.Column>
                </Grid>

                
                
            </Segment>

            <StyledTable compact celled>
                <StyledTableHeader>
                    <Table.Row>
                        <Table.HeaderCell>Nufin</Table.HeaderCell>
                        <Table.HeaderCell>Tipo</Table.HeaderCell>
                        <Table.HeaderCell>Nota</Table.HeaderCell>

                    </Table.Row>
                </StyledTableHeader>

                <StyledTableBody>


                    <Table.Row key={`faixa`}>
                        <Table.Cell>0000</Table.Cell>
                        <Table.Cell>0000</Table.Cell>
                        <Table.Cell>0</Table.Cell>

                    </Table.Row>


                </StyledTableBody>

            </StyledTable>




        </div>)
    }


}

Contratos.defaultProps = {

}

Contratos.propTypes = {

}

export default Contratos
