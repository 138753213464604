import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Segment, List, Image, Tab, Checkbox, Label, Header, Icon, Modal, Input, Form, TextArea, Dropdown, Popup } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import logScreen from './LogScreen';
import LogScreen from './LogScreen';
import styled from 'styled-components';

import parse from 'html-react-parser';
import DateTimePicker from 'react-date-picker';



const inlineStyle = {
    modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
};

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`

class ListDevolucao extends Component {

    state = {}

    constructor(props) {
        super(props);
        let {
        } = this.props;


        this.onChangeDevolucao = this.onChangeDevolucao.bind(this);

        this.onChangeModalOpen = this.onChangeModalOpen.bind(this);
        this.onChangeModalClose = this.onChangeModalClose.bind(this);
        this.onChangePedido = this.onChangePedido.bind(this)

    }


    onChangeDevolucao(e, pedido, carga) {

        if (!pedido.isupdatingdevolucao) {
            //console.log('#############')
            //console.log(pedido)
            //console.log('@@@@@@@@@@@')
            this.props.handleDevolucao(pedido, carga);
        }

    }

    onChangeModalOpen(e, pedido) {
        //alert('alert open')
        this.props.handlePedidoModal(pedido, true, false);
    }

    onChangeModalClose(e, pedido) {
        //alert('alert close')
        this.props.handlePedidoModal(pedido, false, false);
    }

    onChangePedido(e, pedido) {
        //alert('change pedido')
        pedido.ad_trackndescr = e.target.value;
        pedido.alterado = true;
        //##michel
        this.props.handlePedido(pedido);
    }

    onChangePedidoMotivo(e, data) {
        //alert('change motivo')
        data.pedido.ad_tracknentid = data.value;
        data.pedido.alterado = true;
        this.props.handlePedido(data.pedido);
    }
    onSaveMotivo(e, data) {
        if (data.pedido.ad_trackndescr != null && data.pedido.ad_tracknentid != -1)
            this.props.handlePedidoModal(data.pedido, false, true);
        //alert('save motivo')
    }

    onCancelMotivo(e, data) {
        data.pedido.alterado = false;
        this.props.handlePedidoModal(data.pedido, false, false);
        //alert('cancel motivo')
    }

    getMotivo = (id, motivos) => {
        let motivo = ''
        if (motivos.length > 0) {
            try {
                motivo = motivos.find(m => m.value.toString() == id).text + ' | '
            } catch (e) {
                motivo = ''
            }

        }
        return motivo;
    }


    componentDidMount() {
        let { } = this.props;
    }

    onChangePrazos = (pedido, campo,data) => {
        /*console.log(`data: ${data}
        campo: ${campo}
        `)*/
        //console.log(pedido)
        this.props.ordemCargaEmDevolucaoUpdatePrazosAction(pedido,campo,data)
    }



    render() {
        let { cargas, loader
            , logScreen
            , motivos

        } = this.props;

        return (
            <Tab.Pane loading={loader}>

                <LogScreen logData={logScreen} />

                {cargas.map(carga => (
                    <div key={carga.id}>
                        <List horizontal id={carga.id} size='small'>
                            <List.Item>
                                <List.Content>
                                    <List.Header>Ordem: {carga.id}</List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content>
                                    <List.Header>Placa: {carga.placa}</List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content>
                                    <List.Header>Motorista: {carga.motorista}</List.Header>
                                </List.Content>
                            </List.Item>
                            <List.Item>
                                <List.Content>
                                    <List.Header>Data da Ordem: {(carga.dataordemcarga == null ? "" : moment(carga.dataordemcarga).format("DD/MM/YYYY"))}</List.Header>
                                </List.Content>
                            </List.Item>
                        </List>

                        <StyledTable celled id={carga.id} compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Data Pedido</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Nota</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Data Nota</Table.HeaderCell>
                                    <Table.HeaderCell width="4">Parceiro</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Cidade</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Desde</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Não Entregou</Table.HeaderCell>
                                    <Table.HeaderCell width="1"></Table.HeaderCell>

                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {carga.pedidos.map(pedido => (
                                    <Table.Row key={pedido.numnota} warning={(pedido.isatrasado == 1 ? true : false)} negative={(pedido.isatrasado == 2 ? true : false)}>
                                        <Table.Cell>{pedido.pedidofbits}</Table.Cell>
                                        <Popup
                                            trigger={<Table.Cell>{moment(pedido.datapedido).format("DD/MM/YYYY")}</Table.Cell>}

                                            flowing hoverable
                                            pinned
                                            on={['hover', 'click']}
                                        >
                                            <Segment secondary>
                                                <table width='100%' id={pedido.nunota + 'tb' + pedido.pedidofbits}>
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Data Envio:</b></td>
                                                            <td><b>Previsão de entrega:</b></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <DateTimePicker
                                                                    onChange={this.onChangePrazos.bind(this, pedido,'ad_dtenvio')}
                                                                    //onChange={this.props.ordemCargaEmRotaUpdatePrazosAction}
                                                                    value={(pedido.ad_dtenvio == null ? "" : new Date(pedido.ad_dtenvio))}
                                                                    locale="pt-br"
                                                                    required={true}
                                                                    clearIcon=""
                                                                    disableClock={true}
                                                                    disableCalendar={true}
                                                                    
                                                                />
                                                            </td>
                                                            <td>
                                                                <DateTimePicker
                                                                    onChange={this.onChangePrazos.bind(this, pedido,'ad_dtprevisaoen')}
                                                                    //onChange={this.props.ordemCargaEmRotaUpdatePrazosAction}
                                                                    value={(pedido.ad_dtprevisaoen == null ? "" : new Date(pedido.ad_dtprevisaoen))}
                                                                    locale="pt-br"
                                                                    required={true}
                                                                    clearIcon=""
                                                                    disableClock={true}
                                                                    disableCalendar={true}
                                                                    
                                                                />
                                                            </td>
                                                            <td>
                                                                <Button positive size="tiny"
                                                                    loading={pedido.is_savingdt}
                                                                    onClick={(e) => this.props.ordemCargaEmDevolucaoSavePrazosAction(pedido,this.props.login)}
                                                                >Alterar
                                                        </Button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Segment>









                                            {parse(pedido.detalhes)}


                                        </Popup>
                                        <Table.Cell>{pedido.numnota}</Table.Cell>
                                        <Table.Cell>{(pedido.datanf == null ? "" : moment(pedido.datanf).format("DD/MM/YYYY HH:mm"))}</Table.Cell>
                                        <Table.Cell>{pedido.parceiro}</Table.Cell>


                                        <Table.Cell>{(pedido.cidadeparceiro != 'Retira' ? pedido.cidadeparceiro : '')}<Header color={(pedido.cidadeparceiro == 'Retira' ? 'teal' : '')} size='tiny'>{(pedido.cidadeparceiro == 'Retira' ? '(Retira)' : '')}</Header></Table.Cell>

                                        <Table.Cell>
                                            {moment(pedido.dtinicio).format("DD/MM/YYYY HH:mm")}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {pedido.vendedor}
                                        </Table.Cell>
                                        <Table.Cell>

                                            <Modal
                                                trigger={


                                                    <Popup
                                                        trigger={<Button basic icon={(!pedido.alterado ? ('write') : ('edit'))}
                                                            color={(pedido.alterado ? ('red') : !pedido.alterado && pedido.ad_trackndescr != null ? ('green') : ('blue'))}
                                                            size="mini" onClick={(e) => this.onChangeModalOpen(e, pedido)}
                                                        />}
                                                        content={`
                                                             ${this.getMotivo(pedido.ad_tracknentid, motivos)}
                                                            ${pedido.ad_trackndescr}
                                                            `
                                                        }
                                                        inverted
                                                        disabled={(pedido.ad_trackndescr != null ? false : true)}
                                                    />

                                                
                                                }
                                            open={pedido.modal}
                                            onClose={(e) => this.onChangeModalClose(e, pedido)}
                                            basic
                                                style={inlineStyle.modal}
                                            size='small'>
                                                <br /><br /><br />
                                            <br /><br /><br />
                                            <Header icon='browser' content='Justifique o motivo da não entrega' />
                                            <Modal.Content>
                                                <Form name="formModal">

                                                    <Form.Group widths='equal'>
                                                        <Dropdown
                                                            onChange={this.onChangePedidoMotivo.bind(this)}
                                                            options={motivos}
                                                            selection
                                                            value={pedido.ad_tracknentid}
                                                            pedido={pedido}
                                                            placeholder='Motivo'
                                                            name='combo'
                                                        />
                                                    </Form.Group>
                                                    <Form.TextArea label='ad_trackndescr' placeholder='Escreva a Descrição' value={(pedido.ad_trackndescr === null) ? "" : pedido.ad_trackndescr} onChange={(e) => this.onChangePedido(e, pedido)}
                                                        name="ad_trackndescr"
                                                        maxLength={255}
                                                    />
                                                    <Label as='a' basic color='red' pointing inverted>{(pedido.ad_tracknusuari != null) ? pedido.ad_tracknusuari : "Sem usuário"}</Label>
                                                </Form>
                                            </Modal.Content>
                                            <Modal.Actions>
                                                <Button color='red' onClick={this.onCancelMotivo.bind(this)} pedido={pedido} inverted>
                                                    <Icon name='cancel' /> Cancel
                                                    </Button>
                                                <Button color='green'
                                                    onClick={this.onSaveMotivo.bind(this)}
                                                    pedido={pedido} inverted>
                                                    <Icon name='checkmark' /> Salvar
                                                    </Button>
                                            </Modal.Actions>
                                            </Modal>
                                        </Table.Cell>



                                    <Table.Cell>
                                        <div>

                                            <Button basic icon='hand point up outline' color={'blue'} size="mini"
                                                loading={pedido.isupdatingdevolucao}
                                                onClick={(e) => this.onChangeDevolucao(e, pedido, carga)}
                                            />

                                        </div>

                                    </Table.Cell>
                                    </Table.Row>
                            ))}
                            </StyledTableBody>
                        </StyledTable>


                    </div>
                ))}
            </Tab.Pane>

        )
    }
}

ListDevolucao.defaultProps = {
    cargas: []
    , loader: true
    , motivos: []
};

ListDevolucao.propTypes = {
    cargas: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , handleDevolucao: PropTypes.func.isRequired
    , motivos: PropTypes.array.isRequired
    , handlePedidoModal: PropTypes.func.isRequired
    , handlePedido: PropTypes.func.isRequired
    , ordemCargaEmDevolucaoUpdatePrazosAction: PropTypes.func.isRequired
    , ordemCargaEmDevolucaodoraSavePrazosAction: PropTypes.func.isRequired
    , login: PropTypes.string.isRequired
};



export default ListDevolucao
