import React, { useEffect, useState } from 'react';
import { Button, Modal, ModalActions, ModalContent, ModalDescription, ModalHeader, Icon, Loader, Dimmer, Image } from 'semantic-ui-react';

import { Table, TableRow, TableCell, TableFooter, TableHeaderCell } from 'semantic-ui-react';


import moment from 'moment';

import styled from 'styled-components';



const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    font-size: .8em;
}
`


function VccModalFaturar({ open, openModal, closeModal, textoHeader, dadosModal, loader }) {
    const [totalVlrNota, setTotalVlrNota] = useState(0);
    const [totalVlrRegra, setTotalVlrRegra] = useState(0);
    const [totalVlrSubst, setTotalVlrSubst] = useState(0);
    const [totalVlrIpi, setTotalVlrIpi] = useState(0);

    useEffect(() => {
        if (dadosModal !== undefined) {
            const totalRegra = dadosModal.reduce((acc, item) => acc + item.vlrregra, 0);
            const totalNota = dadosModal.reduce((acc, item) => acc + item.vlrnota, 0);
            const totalSubst = dadosModal.reduce((acc, item) => acc + item.vlrsubst, 0);
            const totalIpi = dadosModal.reduce((acc, item) => acc + item.vlripi, 0);
            setTotalVlrRegra(totalRegra);
            setTotalVlrNota(totalNota);
            setTotalVlrSubst(totalSubst);
            setTotalVlrIpi(totalIpi);
        }
    }, [dadosModal]);

    return (
        <Modal
            open={open}
            onClose={closeModal}
            onOpen={openModal}
        >
            {dadosModal && dadosModal !== undefined && loader === false ? (
                <>
                    <ModalHeader>{textoHeader}</ModalHeader>
                    <ModalContent scrolling>
                        <ModalDescription>

                            <StyledTable celled id="ListFatunomne" compact='very' size='small'>

                                <StyledTableHeader >
                                    <Table.Row>
                                        <Table.HeaderCell>DIAS ROTA</Table.HeaderCell>
                                        <Table.HeaderCell>A/L</Table.HeaderCell>
                                        <Table.HeaderCell>VLRNOTA</Table.HeaderCell>
                                        <Table.HeaderCell>NUNOTA</Table.HeaderCell>
                                        <Table.HeaderCell >DTNEG</Table.HeaderCell>
                                        <Table.HeaderCell>CLIENTE</Table.HeaderCell>
                                        <Table.HeaderCell>VENDEDOR</Table.HeaderCell>
                                        <Table.HeaderCell>ROTACIDADE</Table.HeaderCell>
                                    </Table.Row>
                                </StyledTableHeader>

                                <StyledTableBody>
                                    {dadosModal.map((item) => (
                                        <TableRow key={item.nunota}>
                                            <TableCell style={{ whiteSpace: 'nowrap', padding: '0 10px' }}>{item.diasrota}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: '0 10px' }}>{item.status}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>
                                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrnota)}
                                            </TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: '0 10px' }}>{item.nunota}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', textAlign: 'center', padding: '0 10px' }}> &nbsp; &nbsp; &nbsp;{moment(item.dtneg).format("DD/MM/YYYY")} &nbsp; &nbsp; &nbsp; </TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', padding: '0 10px' }}> &nbsp; {item.cliente}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', padding: '0 10px' }}> &nbsp; {item.vendedor}</TableCell>
                                            <TableCell style={{ whiteSpace: 'nowrap', padding: '0 10px' }}> &nbsp; {item.rotacidade}</TableCell>
                                        </TableRow>
                                    ))}
                                </StyledTableBody>

                                <TableFooter>
                                    <TableRow>
                                        <TableHeaderCell>{dadosModal.length} Pedidos</TableHeaderCell>
                                        <TableHeaderCell></TableHeaderCell>
                                        <TableHeaderCell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(totalVlrNota)}</TableHeaderCell>
                                        <TableHeaderCell></TableHeaderCell>
                                        <TableHeaderCell></TableHeaderCell>
                                        <TableHeaderCell></TableHeaderCell>

                                        <TableHeaderCell />
                                    </TableRow>
                                </TableFooter>

                            </StyledTable>

                        </ModalDescription>
                    </ModalContent>
                    <ModalActions>
                        <Button onClick={closeModal} negative>
                            <Icon name='remove' />    Fechar
                        </Button>
                    </ModalActions>
                </>
            ) : (
                <ModalContent scrolling>
                    <ModalDescription>
                        <Dimmer active inverted>
                            <Loader size='large'>Carregando Pedidos </Loader>
                        </Dimmer>
                        <Image src='/img/paragraph.png' />

                    </ModalDescription>
                </ModalContent>
            )}
        </Modal>
    );
}

export default VccModalFaturar;


