
import { 
    AGRUPADOR_LIST
    , AGRUPADOR_ISSEARCHING 
    , AGRUPADOR_UPDATE_PARAMETROS
    , AGRUPADOR_ISSAVING
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    produtos: []
    , agrupador: ''
    , codprod: 0
    , issearching: false
    , parametros:''
    , issaving: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case AGRUPADOR_LIST:
            return { ...state, produtos: action.produtos, agrupador: action.agrupador, codprod: action.codprod }
        case AGRUPADOR_ISSEARCHING:
            return { ...state, issearching: action.payload}
        case AGRUPADOR_UPDATE_PARAMETROS:
            return {...state, parametros: action.payload}
        case AGRUPADOR_ISSAVING:
            return { ...state, issaving: action.payload}

        default:
            return state;
    }
}