import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import parse from 'html-react-parser';


import If from '../../utils/If';

import {
    Grid, Menu, Breadcrumb, Tab, Icon
    , Table, Button, Segment, Dropdown, Form, Input
} from 'semantic-ui-react'


import {
    B2BListParceiroAction
    , B2BChangeParceiroAction
    , B2BSaveParceiroAction
} from '../../actions/B2BActions';






import styled from 'styled-components';
import { B2BParceiroEntity } from '../../entities/B2BParceiroEntity';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class B2B extends Component {

    state = {}

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 23 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------





    }

    componentWillReceiveProps() {

    }


    onUpdateStatus() {


    }

    handleInputChange = (e) => {
        this.setState({
            query: e.target.value
        });
    }

    handleFormSubmit = (e) => {
        //alert('enviar'+this.state.query)

        if (this.state.query != undefined && this.state.query != '') {
            this.props.B2BListParceiroAction(this.state.query).then(d => { })
        } else {
            this.props.B2BListParceiroAction(0).then(d => { })
        }

    }

    handleClassificacaoFin = (e, { value }) => {
        this.setState({ dropClassificacaoFin: value.toString() });
        this.props.B2BChangeParceiroAction({ target: { 'name': 'classificacaofinnovo', 'value': value.toString() } })
    }

    handleSave = (acao) => {
        if (acao == 'cancelar') {
            this.props.B2BListParceiroAction(0).then(d => { })
        } else {
            if (this.props.issavingparceiro === false) {


                this.props.B2BSaveParceiroAction(this.props.B2BParceiroEntity, acao).then(d => { })
            }

        }


    }






    render() {

        let { B2BParceiroEntity } = this.props

        let panes = []

        panes.push({
            menuItem: <Menu.Item key='TABparceiros'><Icon name='list alternate outline' />Classificação Financeira</Menu.Item>,
            render: () =>
                <Tab.Pane>
                    <Segment>
                        <Grid columns={1} relaxed='very'>
                            <Grid.Column verticalAlign='middle'>
                                <Form>

                                    <Input
                                        action={{ color: 'blue', content: 'Buscar', onClick: this.handleFormSubmit.bind(this) }}
                                        icon='search'
                                        iconPosition='left'
                                        placeholder='CodParc #'
                                        onChange={this.handleInputChange.bind(this)}
                                        loading={this.props.issearchingparceiro}
                                        maxLength={10}
                                    />
                                </Form>

                            </Grid.Column>
                            <If test={B2BParceiroEntity.codparc != 0}>
                                <Grid.Column>
                                    <Table definition>
                                        <Table.Body>
                                            <Table.Row>
                                                <Table.Cell width={2}>Parceiro</Table.Cell>
                                                <Table.Cell>{B2BParceiroEntity.nomeparc}</Table.Cell>
                                            </Table.Row>


                                            <Table.Row>
                                                <Table.Cell>Tabela de Preço</Table.Cell>
                                                <Table.Cell>

                                                    {B2BParceiroEntity.codtab} - {B2BParceiroEntity.nometab}
                                                </Table.Cell>
                                            </Table.Row>

                                            <Table.Row>
                                                <Table.Cell>Classificação</Table.Cell>
                                                <Table.Cell>
                                                    {

                                                        parse(`                                                
                                                        Classificação atual: ${B2BParceiroEntity.classificacaofin}<br>
                                                        Tipo: ${B2BParceiroEntity.tipoclassificacaofin}(${(B2BParceiroEntity.tipoclassificacaofin == 'M' ? 'Manual' : 'Automático')}) | Data: ${moment(B2BParceiroEntity.dtclassificacaofin).utc().format("DD/MM/YYYY")}<br>
                                                        Upgrade por ${B2BParceiroEntity.valormeses} meses?<br>
                                                        Em ${B2BParceiroEntity.mesestotal} meses para A: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(B2BParceiroEntity.valormina)} e para B: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(B2BParceiroEntity.valorminb)}, com compras em 3 meses diferentes.
                                                        `)

                                                    }


                                                    <If test={B2BParceiroEntity.classificacaofin != 'AA' && B2BParceiroEntity.classificacaofin != 'D' && B2BParceiroEntity.classificacaofin != 'A'}>
                                                        <div style={{ width: 55 }}>
                                                            <Dropdown
                                                                options={
                                                                    (B2BParceiroEntity.classificacaofin == 'C'
                                                                        ? [{ key: B2BParceiroEntity.classificacaofin, text: B2BParceiroEntity.classificacaofin, value: B2BParceiroEntity.classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                            , { key: 'B', text: 'B', value: 'B' }]
                                                                        : [{ key: B2BParceiroEntity.classificacaofin, text: B2BParceiroEntity.classificacaofin, value: B2BParceiroEntity.classificacaofin }, { key: 'A', text: 'A', value: 'A' }
                                                                        ])
                                                                }
                                                                selection
                                                                placeholder='Classificação Financeira'
                                                                name='classfin'
                                                                fluid
                                                                defaultValue={B2BParceiroEntity?.classificacaofinnovo?.toString()}
                                                                onChange={this.handleClassificacaoFin}

                                                            />
                                                        </div>
                                                    </If>


                                                </Table.Cell>
                                            </Table.Row>



                                        </Table.Body>
                                    </Table>
                                </Grid.Column>
                                <Grid.Column>
                                    <Button primary
                                        loading={this.props.issavingparceiro}
                                        onClick={e => this.handleSave('save')}
                                    >Salvar</Button>
                                    <Button secondary
                                        onClick={e => this.handleSave('cancelar')}
                                    >Cancelar</Button>
                                </Grid.Column>
                            </If>

                        </Grid>
                    </Segment>
                </Tab.Pane>
        })


        /*panes.push({
            menuItem: <Menu.Item key='TABProcessamentos'><Icon name='list alternate outline' />VCC</Menu.Item>,
            render: () =>
                <Tab.Pane loading={true}>
                   bbbbbbbbb
                </Tab.Pane>
        })*/






        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>B2B </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , B2BParceiroEntity: state.B2BReducer.B2BParceiroEntity
        , issearchingparceiro: state.B2BReducer.issearchingparceiro
        , issavingparceiro: state.B2BReducer.issavingparceiro


    }
);


export default withRouter(connect(mapStateToProps, {
    B2BListParceiroAction
    , B2BChangeParceiroAction
    , B2BSaveParceiroAction
}
)(B2B));

