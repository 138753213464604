//

import {
    MERCADOLIVRE_LIST_TOKEN
    , MERCADOLIVRE_CHANGE_DIMENSOES
    , MERCADOLIVRE_DIMENSOES_SAVING
    , MERCADOLIVRE_ANUNCIO_UPDATE
    , MERCADOLIVRE_ANUNCIO_SAVING
    , MERCADOLIVRE_ANUNCIO_CLEAR
    , MERCADOLIVRE_LIST_ENVIADOS
    , MERCADOLIVRE_LIST_PENDENTES
    , MERCADOLIVRE_CHECK_PENDENTES
} from '../actions/ActionsTypes'



import * as Api from '../utils/API';




export const MercadoLivreAnuncioUpdateAction = (event) => {


    return {
        type: MERCADOLIVRE_ANUNCIO_UPDATE
        , field: event.target.name
        , payload: (event.target.name == 'price' ? event.target.value : event.target.value)
    }
}

export const MercadoLivreAnunciosSaveAction = (entidade, mltoken) => (dispatch) => {

    new Promise(function (resolve, reject) {
        dispatch({ type: MERCADOLIVRE_ANUNCIO_SAVING, payload: true, resultado: [] })

        let data = {}
        data.mltoken = mltoken
        data.entidade = entidade

        Api.postMercadoLivreAnuncio(data).then(dados => {
            dispatch({ type: MERCADOLIVRE_ANUNCIO_SAVING, payload: false, resultado: dados })

            //console.log('ok')
            //console.log(dados)
            if (dados[0].status == 'OK') {
                dispatch({ type: MERCADOLIVRE_ANUNCIO_CLEAR, payload: [] })
            }

            resolve([])

        }).catch(error => {
            //dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
            reject(error);
        })




    })

}


export const MercadoLivreDimensoesSaveAction = (dimensoes, mltoken) => (dispatch) => {
    new Promise(function (resolve, reject) {
        let lista = []


        dispatch({ type: MERCADOLIVRE_DIMENSOES_SAVING, payload: true, resultado: [] })

        //replace removendo espaço em branco
        for (let i of dimensoes.replace(/\s/g, "").split("#")) {
            if (i != "") {
                let item = []
                item = i.split("|")
                lista.push({ codprod: item[0], dimensao: item[1] })
            }
        }


        if (lista.length > 0) {

            let entidade = {}
            entidade.mltoken = mltoken
            entidade.itens = lista

            Api.postMercadoLivreDimensoes(entidade).then(dados => {
                //dispatch({ type: MERCADOLIVRE_LIST_TOKEN, payload: dados.token })
                //alert('voltou'+dados.length)
                dispatch({ type: MERCADOLIVRE_DIMENSOES_SAVING, payload: false, resultado: dados })

                resolve(dados)
            }).catch(error => {
                //dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
                reject(error);
            })

        } else {
            dispatch({ type: MERCADOLIVRE_DIMENSOES_SAVING, payload: false, resultado: [] })

        }




        //alert('alerta:'+dimensoes)

    })
}

export const MercadoLivreHandleDimensoesChangeAction = (event) => {

    return {
        type: MERCADOLIVRE_CHANGE_DIMENSOES
        , field: event.target.name
        , payload: event.target.value
    }
}


export const mercadolivreListTokenAction = (codigo) => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.getMercadoLivreToken(codigo).then(dados => {
            dispatch({ type: MERCADOLIVRE_LIST_TOKEN, payload: dados.token })

            /*
            dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })*/
            resolve(dados)
        }).catch(error => {
            //dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
            reject(error);
        })


        /*dispatch({ type: MARKETPLACES_ISSEARCHING, payload: true })

        Api.postMarketPlacesPedidos(entidade).then(dados => {
            dispatch({ type: MARKETPLACES_LIST, payload: dados })
            dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
            resolve(dados)
        }).catch(error => {
            dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
            reject(error);
        })*/

    });



export const mercadolivreListEnviadosAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.getMercadoLivreEnviados().then(dados => {
            dispatch({ type: MERCADOLIVRE_LIST_ENVIADOS, payload: dados })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })

    });

export const mercadolivreListPendentesAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.getMercadoLivrePendentes().then(dados => {
            dispatch({ type: MERCADOLIVRE_LIST_PENDENTES, payload: dados })
            resolve(dados)
        }).catch(error => {
            reject(error);
        })

    });


export const mercadolivreCheckAction = (item, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: MERCADOLIVRE_CHECK_PENDENTES, id: item.ad_pedidofbits, payload: acao });
    });




export const mercadolivreNFEnviaAction = (pedidos, login, token) => (dispatch) =>
    new Promise(function (resolve, reject) {

        let nfs = pedidos.filter(f => f.ischecked == true)
        for (let i of nfs) {
            i.login = login
            i.token = token
        }
        if (nfs.length > 0) {
            Api.postMercadoLivreNF(nfs).then(dados => {
                dispatch(mercadolivreListEnviadosAction());
                dispatch(mercadolivreListPendentesAction());
                resolve('')
            }).catch(error => {
                reject(error);
            })
        } else {
            resolve('')
        }


        //timer
        //setInterval(() => {resolve('')}, 1000);

    });


