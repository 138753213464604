import React, { useState } from 'react';
import { Button, Table, Input, Message } from 'semantic-ui-react';
import {
    DescontoFBitsIndividualAction
} from '../../../actions/ProcessamentosActions';


function DescFbitsIndividual({ setSuccessMessage, setErrors }) {
    const [inputValue, setInputValue] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const handleInputChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, ''); // Remove qualquer caractere que não seja um número
        setInputValue(value);
    };

    const apiUrl = process.env.API_URL
    const handleSalvarClick = () => {
        setIsLoading(true);
        const enviarFBits = inputValue
        const numeroFBits = parseInt(enviarFBits, 10);
        const pedidofbits = {
            pedidofbits: numeroFBits
        }

        if (enviarFBits.length < 5 || enviarFBits === '') {
            setErrors('O codigo deve conter mais que 5 caracteres.');
            setIsLoading(false);
            setSuccessMessage('')
        } else {
            DescontoFBitsIndividualAction(pedidofbits)
                .then((response) => {
                    // console.log(response.executou);
                    if (response.executou === true) {
                        setErrors(null); // Limpe os erros se houver sucesso
                        setSuccessMessage('Desconto processado com sucesso!');
                        setInputValue('')
                        setIsLoading(false);
                    } else {

                        setErrors(response.mensagem);
                        setIsLoading(false);
                    }
                })
                .catch((error) => {
                    console.log(error);
                    setErrors(error);
                });
        }


    }

    return (
        <>
            <Table.Cell>Desconto Pedido FBits Individual</Table.Cell>
            <Table.Cell></Table.Cell>
            <Table.Cell textAlign='right'>
                <Input
                    value={inputValue}
                    onChange={handleInputChange}
                    maxLength={9}
                    placeholder="Pedido FBits"
                    size='mini'
                />
                <Button
                    basic
                    color='blue'
                    onClick={handleSalvarClick}
                    size='mini'
                    loading={isLoading}
                >
                    Ok
                </Button>
            </Table.Cell>
        </>
    );
}

export default DescFbitsIndividual;
