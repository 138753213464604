import React, { Component } from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom'


import OrdemCarga from './components/ordemcarga/OrdemCarga'
import LoginForm from './components/loginform/LoginForm'
import Home from './components/home/home'
import RoboDePrecos from './components/robodeprecos/RoboDePrecos'
import GraficoDeVendas from './components/graficodevendas/GraficoDeVendas'
import Pesquisa from './components/pesquisa/Pesquisa'
import ControleDeViagens from './components/controledeviagens/ControleDeViagens'
import Consultores from './components/consultores/Consultores'
import RenegociacaoDeDividas from './components/renegociacaodedividas/RenegociacaoDeDividas'
import MarketPlaces from './components/marketplaces/MarketPlaces';
import ComparativoProdutos from './components/comparativoprodutos/ComparativoProdutos';
import Comissoes from './components/comissoes/Comissoes';
import CadastroDeProdutos from './components/cadastrodeprodutos/CadastroDeProdutos';
import Processamentos from './components/processamentos/Processamentos';
import Frete from './components/frete/Frete';
import Agrupador from './components/agrupador/Agrupador';
import BlackFriday from './components/blackfriday/BlackFriday';
import Corporativo from './components/corporativo/Corporativo';
import Instaladores from './components/instaladores/Instaladores';
import Serasa from './components/serasa/Serasa';
import Orcamentos from './components/orcamentos/Orcamentos';
import MercadoLivre from './components/mercadolivre/MercadoLivre';
import Bank from './components/bank/Bank';
import PenteFinoDePedidos from './components/pentefinodepedidos/PenteFinoDePedidos';
import B2B from './components/b2b/B2B';
import AppEntregas from './components/AppEntregas/AppEntregas';
import Mapas from './components/mapas/mapas';
import Rastreador from './components/rastreador/rastreador';
import Assistencia from './components/assistencia/Assistencia';


import { Container } from 'semantic-ui-react';
import Permissoes from './components/Permissoes/Painel';
import Pix from './components/Pix/Painel';
import WhatsApp from './components/whatsapp/Painel';
import KpiVisitas from './components/kpivisitas/kpivisitas';



//Querystring Query String
let search = window.location.search;
let params = new URLSearchParams(search);
let mlcode = params.get('code');


const App = (location) => (
  <Router>
    <div>

      <Container style={{ marginTop: '0em' }}>
        <Route exact path="/" component={LoginForm} />
        <Route path="/PainelPedidos" component={OrdemCarga} />
        <Route path="/Home" component={Home} />
        <Route path="/RoboDePrecos" component={RoboDePrecos} />
        <Route path="/GraficoDeVendas" component={GraficoDeVendas} />
        <Route path="/Pesquisa/:tarefaid/:pesquisaid" component={PesquisaChild} />
        <Route exact path="/Pesquisa" component={Pesquisa} />
        <Route path="/ControleDeViagens" component={ControleDeViagens} />
        <Route path="/Consultores" component={Consultores} />
        <Route path="/RenegociacaoDeDividas" component={RenegociacaoDeDividas} />
        <Route path="/MarketPlaces" component={MarketPlaces} />
        <Route path="/ComparativoProdutos" component={ComparativoProdutos} />
        <Route path="/Comissoes" component={Comissoes} />
        <Route path="/CadastroDeProdutos" component={CadastroDeProdutos} />
        <Route path="/Processamentos" component={Processamentos} />
        <Route path="/Frete" component={Frete} />
        <Route path="/Agrupador" component={Agrupador} />
        <Route path="/BlackFriday" component={BlackFriday} />
        <Route path="/Corporativo" component={Corporativo} />
        <Route path="/Instaladores" component={Instaladores} />
        <Route path="/Serasa" component={Serasa} />
        <Route path="/Orcamentos" component={Orcamentos} />
        <Route path="/mercadolivre" component={() => <MercadoLivre codigo={mlcode} />} />
        <Route path="/Bank" component={Bank} />
        <Route path="/PenteFinoDePedidos" component={PenteFinoDePedidos} />
        <Route path="/B2B" component={B2B} />
        <Route path="/AppEntregas" component={AppEntregas} />
        <Route path="/Mapas" component={Mapas} />
        <Route path="/Rastreador" component={Rastreador} />
        <Route path="/Permissoes" component={Permissoes} />
        <Route path="/Pix" component={Pix} />
        <Route path="/Whatsapp" component={WhatsApp} />
        <Route path="/KpiVisitas" component={KpiChild} />
        <Route path="/Assistencia" component={Assistencia} />
      </Container>


    </div>

  </Router>
);

const HomeChild = ({ match }) => (
  <Home tela={match.params.tela} />
)

const PesquisaChild = ({ match }) => (
  <Pesquisa tarefaid={match.params.tarefaid} pesquisaid={match.params.pesquisaid} />
)

const KpiChild = ({ match }) => (
  <KpiVisitas />
)




export default App;
