import { Image } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

function FolhaDevolucao(props) {
    const { devolucaoAnalisar } = props;

    return (
        <>
            <div style={{
                display: 'flex',
                width: '100%',
                marginBottom: '30px',
            }}>
                <div style={{ width: '150px', marginTop: '20px' }}>
                    <Image src='/img/logo-esplane.png' alt="logo" />
                </div>
            </div>
            <div style={{
                border: '1px solid #000',
                padding: '15px',
                borderRadius: '4px',
                minHeight: '100vw', // Garantir altura mínima da página inteira
            }}>
                <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                    width: '100%',
                    textAlign: 'center',
                    marginBottom: '30px',
                }}>
                    <div>
                        <p style={{
                            fontSize: '62px',
                            fontWeight: 'bold',
                            marginTop: '65px',
                            border: '1px solid #000',
                            padding: '15px',
                            borderRadius: '4px',
                            backgroundColor: '#f9f9f9',
                        }}>Devolução</p>
                    </div>
                </div>


                {/* Conteúdo principal */}
                <div style={{
                    width: '100%',
                    padding: '70px',
                    fontSize: '20px',
                    lineHeight: '1.5',
                }}>
                    {devolucaoAnalisar.map((item, index) => (
                        <>
                            <div key={index} style={{
                                display: 'flex',
                                justifyContent: 'space-between',
                                marginBottom: '20px',
                                pageBreakInside: 'avoid',
                                border: '1px solid #000',
                                padding: '15px',
                                borderRadius: '4px',
                                backgroundColor: '#f9f9f9',
                            }}>
                                {/* Informações à esquerda */}
                                <div style={{ width: '48%' }}>
                                    <p><b>Número Único:</b> {item.nufin}</p>
                                    <p><b>Parceiro:</b> {item.nomeparc} ({item.codparc})</p>
                                    <p><b>Depositante:</b> {item.depositante}</p>
                                    <p><b>Ocorrência:</b> {item.relato}</p>
                                </div>

                                {/* Informações à direita */}
                                <div style={{ width: '48%', textAlign: 'right' }}>
                                    <p><b>Valor:</b> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}</p>
                                    <p><b>Vencimento: </b>{new Date(item.vencimento).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                    })}</p>
                                    <div style={{ position: 'relative', bottom: '0', right: '0' }}>SEM PÊNDENCIA</div>
                                </div>

                            </div>
                            <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                width: '100%',
                                marginBottom: '20px',
                                height: 'auto'
                            }}>
                                <p><b>Motivo:</b></p>
                                <p style={{
                                    border: '1px solid #000',
                                    padding: '10px',
                                    borderRadius: '4px',
                                    backgroundColor: '#f9f9f9',
                                    wordWrap: 'break-word',
                                }}>
                                    {item.motivo}
                                </p>
                            </div>

                        </>
                    ))}
                </div>
                {/* Assinatura do responsável */}
                <div style={{
                    textAlign: 'right',
                    fontSize: '20px',
                    // marginTop: '20px'
                }}>
                    <p>__________________________________________</p>
                    <p>Assinatura do responsável</p>
                </div>
                {devolucaoAnalisar.map((item, index) => (
                    <span>
                        {item.devolucaoid}
                    </span>

                ))}
            </div>

        </>
    );
}

const mapStateToProps = (state) => ({
    devolucaoAnalisar: state.MarketPlacesReducer.devolucaoAnalisar,
});

export default withRouter(connect(mapStateToProps)(FolhaDevolucao));
