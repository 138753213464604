import {
    BLACKFRIDAY_LIST_CLIENTES
    , BLACKFRIDAY_ISLOADING
} from '../actions/ActionsTypes'


const INITIAL_STATE = {
    clientes:[]
    , isloading:true
}

export default (state= INITIAL_STATE,action)=>{
    switch(action.type){

        case BLACKFRIDAY_LIST_CLIENTES:
            return {...state, clientes: action.payload}


        case BLACKFRIDAY_ISLOADING:
            return {...state, isloading: action.payload}
    
        default:
            return state;
    }
}