import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Table, Popup

} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 4px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class AProcessar extends Component {


    state = {}

    constructor(props) {
        super(props);
    }


    componentWillReceiveProps() {




    }

    componentDidMount() {




    }




    render() {
        
        let {datalist} = this.props

        return (
            <StyledTable celled id="listAProcessar" compact='very' size='small'>
                <StyledTableHeader>
                    <Table.Row>
                        <Table.HeaderCell width="1">Ordem de Carga</Table.HeaderCell>
                        <Table.HeaderCell width="1">Veículo</Table.HeaderCell>
                        <Table.HeaderCell width="3">Motorista</Table.HeaderCell>
                        <Table.HeaderCell width="2">Ajudante</Table.HeaderCell>
                        <Table.HeaderCell width="2">2º Ajudante</Table.HeaderCell>
                        <Table.HeaderCell width="2">Saída</Table.HeaderCell>
                        <Table.HeaderCell width="2">Chegada</Table.HeaderCell>
                        <Table.HeaderCell width="1">Status</Table.HeaderCell>
                    </Table.Row>
                </StyledTableHeader>

                <StyledTableBody>
                    {datalist.map(item => (
                        <Table.Row key={"aproc_" + item.ordemcarga} positive={(item.status === 'Aguardando rotina' ? true : false)} negative={(item.status != 'Aguardando rotina' ? true : false)}>

                            {(item.rota != null) ?
                                (<Popup
                                    trigger={<Table.Cell warning>{item.ordemcarga}</Table.Cell>}
                                    content={`Rota: ${item.rota}`}
                                    inverted
                                />)
                                :
                                (<Table.Cell>{item.ordemcarga}</Table.Cell>)
                            }


                            <Popup
                                trigger={<Table.Cell>{item.placa}</Table.Cell>}
                                content={item.veiculo}
                                inverted
                            />


                            <Table.Cell>
                                {item.motorista}
                            </Table.Cell>
                            <Table.Cell>
                                {item.ajudanteum}
                            </Table.Cell>
                            <Table.Cell>
                                {item.ajudantedois}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.dtsaida).format("DD/MM/YYYY HH:mm")}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.dtchegada).format("DD/MM/YYYY HH:mm")}
                            </Table.Cell>

                            <Table.Cell textAlign="center">
                                {item.status}
                            </Table.Cell>


                        </Table.Row>
                    ))}


                </StyledTableBody>
            </StyledTable>

        )
    }

}

AProcessar.defaultProps = {
    datalist: {}
};

AProcessar.propTypes = {
    datalist: PropTypes.array.isRequired
};

export default AProcessar

