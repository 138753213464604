import React, { useCallback, useState, useImperativeHandle, useRef, forwardRef } from 'react';
import { useDropzone } from 'react-dropzone';
import styled from 'styled-components';

const UploadContainer = styled.div`
    padding: 20px;
    border: 2px dashed #007bff; /* Mudando para um azul mais vibrante */
    border-radius: 10px;
    text-align: center;
    width: 290px;
    cursor: pointer;
    background-color: #f9f9f9; /* Fundo mais claro */
    transition: border-color 0.3s ease, background-color 0.3s ease;
    
    &:hover {
        border-color: #0056b3; /* Azul escuro ao passar o mouse */
        background-color: #e8f0fe; /* Um fundo levemente azul ao passar o mouse */
    }
`;

const FileList = styled.ul`
    list-style-type: none;
    padding: 0;
    width: 450px;
    margin-top: 10px;
    max-height: 150px; /* Adicionando altura máxima para a lista */
    overflow-y: auto; /* Adicionando rolagem vertical se necessário */
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #fff; /* Fundo branco para a lista */
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1); /* Sombra sutil */
`;

const FileItem = styled.li`
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    border-bottom: 1px solid #eee;

    &:last-child {
        border-bottom: none; /* Remove a borda do último item */
    }

    &:hover {
        background-color: #f1f1f1; /* Fundo ao passar o mouse */
    }
`;

const RemoveButton = styled.button`
    background-color: #ff4d4d; /* Vermelho para o botão de remover */
    color: white;
    border: none;
    border-radius: 5px;
    padding: 5px 10px;
    cursor: pointer;
    transition: background-color 0.3s ease;

    &:hover {
        background-color: #ff1a1a; /* Escurece o vermelho ao passar o mouse */
    }
`;

const UploadComponent = forwardRef(({ onFilesChange }, ref) => {
    const accept = {
        'application/pdf': ['.pdf'],
    };

    const [uploadedFilesVisita, setUploadedFilesVisita] = useState([]);
    const [fileError, setFileError] = useState('');
    const [fileMsgError, setFileMsgError] = useState('');
    const inputRef = useRef(null);

    const onDrop = useCallback((acceptedFiles) => {
        console.log(acceptedFiles);

        const validFiles = acceptedFiles.filter(file =>
            ['application/pdf'].includes(file.type)
        );

        if (validFiles.length > 0) {
            setUploadedFilesVisita(prevFiles => {
                const newFiles = [...prevFiles, ...validFiles];
                setFileError('')
                if (newFiles.length > 1) {
                    setFileError('Número máximo de arquivos atingido. Você só pode colocar 1 arquivo.');
                    return prevFiles;
                }
                setFileError('')
                onFilesChange(newFiles);
                return newFiles;
            });
        } else {
            setFileError('Arquivo(s) não suportado(s) ou inválido(s):', acceptedFiles);
        }
    }, [onFilesChange]);

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
    });


    // Limpa os arquivos carregados
    const clearFiles = () => {
        setUploadedFilesVisita([]);
        onFilesChange([]);  // Limpa o estado no componente pai também
    };

    // Expõe a função clearFiles para o componente pai através de uma referência
    useImperativeHandle(ref, () => ({
        clearFiles,
    }));

    const handleRemoveFile = (fileName) => {
        setUploadedFilesVisita(prevFiles => {

            const updatedFiles = prevFiles.filter(file => file.name !== fileName);
            onFilesChange(updatedFiles);
            return updatedFiles;
        });
    };

    return (
        <>
            <UploadContainer {...getRootProps()}>
                <input {...getInputProps()} />
                <p>Clique ou arraste um arquivo pdf.</p>
            </UploadContainer>
            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
            {uploadedFilesVisita.length > 0 && (
                <FileList>
                    {uploadedFilesVisita.map(file => (
                        <FileItem key={file.path || file.name}>
                            {file.path || file.name}
                            <RemoveButton onClick={() => handleRemoveFile(file.name)}>Remover</RemoveButton>
                        </FileItem>
                    ))}

                </FileList>
            )}
            {fileMsgError != '' ? <span style={{ color: 'red' }}>{fileMsgError}</span> : ''}
        </>
    );
});

export default UploadComponent;
