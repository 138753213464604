import {
    INSTALADORES_UPDATE_BUSCA
    , INSTALADORES_ISSEARCHING_BUSCA
    , INSTALADORES_LIST_SEARCH
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';


export const instaladoresListSearchAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: INSTALADORES_ISSEARCHING_BUSCA, payload: true })
        //console.log("###ENTIDADE")
        //console.log(entidade)
        Api.postInstaladoresSearch(entidade).then(dados => {
            dispatch({ type: INSTALADORES_LIST_SEARCH, payload: dados })
            dispatch({ type: INSTALADORES_ISSEARCHING_BUSCA, payload: false })
            resolve([])
        })
    }
)





export const instaladoresUpdateAction = (event) => {
    return {
        type: INSTALADORES_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}











