import {
    MARKETPLACES_LIST
    , MARKETPLACES_UPDATE_PARAMETROS
    , MARKETPLACES_ISSEARCHING

    , MARKETPLACES_PARCEIRO_CODPARC
    , MARKETPLACES_PARCEIRO_DADOS
    , MARKETPLACES_PARCEIRO_NOVACIDADE
    , MARKETPLACES_PARCEIRO_ISSAVING
    , MARKETPLACES_PARCEIRO_ISSEARCHING

    , MARKETPLACES_CHANGE_CAMPOS
    , MARKETPLACES_CONCI_LIST_IMPORTACOES
    , MARKETPLACES_CONCI_LOADER_IMPORTACOES
    , MARKETPLACES_CONCI_ISIMPORTING
    , MARKETPLACES_CONCI_IMPORT
    , MARKETPLACES_CONCI_ISPROCESSING
    , MARKETPLACES_CONCI_ISLISTINGREPASSE
    , MARKETPLACES_CONCI_LIST_REPASSE
    , MARKETPLACES_CONCI_IMPORT_CHANGE
    , MARKETPLACES_DEVOLUCOES_ACTION
    , MARKETPLACES_DEVOLUCOES_LOADING_ACTION
    , MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION
    , MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION
    , MARKETPLACES_DEVOLUCOES_ANALISAR_INFO
    , MARKETPLACES_DEVOLUCOES_ANALISAR_INFO_LOADING
    , MARKETPLACES_DEVOLUCOES_LOADING_LIST
    , MARKETPLACES_DEVOLUCOES_LOADING_ANALISAR
} from '../actions/ActionsTypes'


const INITIAL_STATE = {
    consulta: []
    , parametros: ''
    , issearching: false



    , parceirocodparc: ''
    , parceirodados: []
    , parceiroissearching: false
    , parceironovacidade: ''
    , parceiroissaving: false
    , devolucaoList: []
    , isdevolucaoloading: false
    , isdevolucaocrialoading: false
    , isDevolucaoModalInfoLoading: false
    , isLoadingEdit: false
    , devolucaoAnalisar: []
    , isLoadingList: false
    , isLoadingAnalisar: false

    , concimktplaceid: '0'

    , conciListMktPlace: [{ key: '0', text: 'Selecione', value: '0' }
        //, { key: '141', text: 'Mercado Livre', value: '141' }
        //, { key: '160', text: 'B2W', value: '160' }
        , { key: '220', text: 'Leroy Merlin', value: '220' }
        , { key: '184', text: 'Madeira Madeira', value: '184' }
        , { key: '182', text: 'Magazine Luiza', value: '182' }
    ]

    , conciimportacoes: []
    , conciimportacoesloader: false
    , conciisimporting: false
    , conciarquivojson: []
    , conciarquivobuffer: []
    , conciarquivonome: ''

    , concirepasse: []
    , concirepasselog: []

}

export default (state = INITIAL_STATE, action) => {

    //alert(action.type)
    switch (action.type) {



        case MARKETPLACES_CHANGE_CAMPOS:
            return { ...state, [action.field]: action.payload }

        case MARKETPLACES_CONCI_LIST_IMPORTACOES:
            return { ...state, conciimportacoes: action.payload }

        case MARKETPLACES_CONCI_LOADER_IMPORTACOES:
            return { ...state, conciimportacoesloader: action.payload }

        case MARKETPLACES_CONCI_ISIMPORTING:
            return { ...state, conciisimporting: action.payload }

        case MARKETPLACES_CONCI_IMPORT:
            return { ...state, conciarquivojson: action.payload, conciarquivobuffer: action.arquivobuffer, conciarquivonome: action.arquivonome }

        case MARKETPLACES_CONCI_ISPROCESSING:
            return {
                ...state,

                conciimportacoes: state.conciimportacoes.map(
                    (arquivo, i) => arquivo.nurepassemkp === action.payload ?
                        { ...arquivo, isprocessing: action.acao }
                        : arquivo
                )
            }

        case MARKETPLACES_CONCI_ISLISTINGREPASSE:
            return {
                ...state,

                conciimportacoes: state.conciimportacoes.map(
                    (arquivo, i) => arquivo.nurepassemkp === action.payload ?
                        { ...arquivo, islistingrepasse: action.acao }
                        : arquivo
                )
            }

        case MARKETPLACES_CONCI_LIST_REPASSE:
            return { ...state, concirepasse: action.concirepasse, concirepasselog: action.concirepasselog }








        case MARKETPLACES_LIST:
            return { ...state, consulta: action.payload }


        case MARKETPLACES_UPDATE_PARAMETROS:
            return { ...state, parametros: action.payload }

        case MARKETPLACES_ISSEARCHING:
            return { ...state, issearching: action.payload }


        case MARKETPLACES_PARCEIRO_CODPARC:
            return { ...state, parceirocodparc: action.payload }

        case MARKETPLACES_PARCEIRO_DADOS:
            return { ...state, parceirodados: action.payload }

        case MARKETPLACES_PARCEIRO_NOVACIDADE:
            return { ...state, parceironovacidade: action.payload }

        case MARKETPLACES_PARCEIRO_ISSAVING:
            return { ...state, parceiroissaving: action.payload }

        case MARKETPLACES_PARCEIRO_ISSEARCHING:
            return { ...state, parceiroissearching: action.payload }

        case MARKETPLACES_CONCI_IMPORT_CHANGE:
            return {
                ...state,
                conciimportacoes: state.conciimportacoes.map(
                    (item, i) => item.nurepassemkp === action.nurepassemkp ?
                        { ...item, [action.field]: action.payload }
                        : item
                )
            }


        case MARKETPLACES_DEVOLUCOES_ACTION:
            return { ...state, devolucaoList: action.payload }

        case MARKETPLACES_DEVOLUCOES_LOADING_ACTION:
            return { ...state, isdevolucaoloading: action.payload }

        case MARKETPLACES_DEVOLUCOES_CRIA_LOADING_ACTION:
            return { ...state, isdevolucaocrialoading: action.payload }

        case MARKETPLACES_DEVOLUCOES_MODAL_INFO_LOADING_ACTION:
            return { ...state, isDevolucaoModalInfoLoading: action.payload }

        case MARKETPLACES_DEVOLUCOES_ANALISAR_INFO:
            return { ...state, devolucaoAnalisar: action.payload }

        case MARKETPLACES_DEVOLUCOES_ANALISAR_INFO_LOADING:
            return { ...state, isLoadingEdit: action.payload }

        case MARKETPLACES_DEVOLUCOES_LOADING_LIST:
            return { ...state, isLoadingList: action.payload }

        case MARKETPLACES_DEVOLUCOES_LOADING_ANALISAR:
            return { ...state, isLoadingAnalisar: action.payload }


        default:
            return state;
    }
}