import sortBy from 'sort-by';

import {
    PESQUISA_LIST
    , PESQUISA_LOADER
    , PESQUISA_CHANGE
    , PESQUISA_CHANGE_STATUS

} from '../actions/ActionsTypes';


import { TarefaEntity } from '../entities/TarefaEntity'


const INITIAL_STATE = {
    pesquisas: []
    , TarefaEntity
    , loaderPesquisa: true
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case PESQUISA_LIST:
            return { ...state, pesquisas: action.payload}

        case PESQUISA_LOADER:
            return { ...state, loaderPesquisa: action.payload }

        case PESQUISA_CHANGE:
            return { ...state, TarefaEntity: { ...state.TarefaEntity, [action.field]: action.payload } }
        
        /*case ORDEMCARGA_EMTRANSPORTADORAMODAL_UPDATE:
            return { ...state, cargasEmTransportadora: state.cargasEmTransportadora.map(c => (c.numnota === action.payload.numnota ? action.payload : c)) }

             ofertasAnalise.map(oferta => (
                        oferta.preco_avista_comdif=oferta.preco_avista + (oferta.preco_avista * roboPrecosConfig.find(a=>a.webpriceid===oferta.loja_id).pctdiferenca/100)
                    ));
            */
        
        case PESQUISA_CHANGE_STATUS:
        return { 
            ...state, 
            pesquisas: state.pesquisas.map(
                (tarefa, i) => tarefa.tarefaid === action.tarefaid ? {...tarefa, statusdescricao: action.statusdescricao, dtstatus: action.dtstatus, status: action.status}
                                        : tarefa
            )
         }
        

        default:
            return state;
    }
}