import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Segment, Icon, Header, Menu, Message, Breadcrumb } from 'semantic-ui-react';
import { monitoramentoErrosAction } from '../../actions/MonitoramentoActions';

class Home extends Component {
    constructor(props) {
        super(props);
        this.state = {
            erros: []
        };
    }

    componentDidMount() {
        const { menu, history, monitoramentoErrosAction } = this.props;
        if (menu.length === 0) {
            history.push('/');
        } else {
            const itemMonitoramento = menu.find(item => item.nome === 'Monitoramento' && item.status === 1);
            if (itemMonitoramento) {
                monitoramentoErrosAction().then(dados => {
                    // this.setState({ erros: dados.descricao });
                });
            }
        }
        const itemMonitoramento = menu.find(item => item.nome === 'Monitoramento' && item.status === 1);

        // Inicia o setInterval para chamar monitoramentoErrosAction a cada 5 segundos
        if (itemMonitoramento) {
            this.interval = setInterval(() => {
                monitoramentoErrosAction();
            }, 60000);
        }

    }

    componentDidUpdate(prevProps) {
        if (prevProps.moniterros !== this.props.moniterros) {
            this.updateErros(this.props.moniterros);
        }
    }

    componentWillUnmount() {
        // Limpa o setInterval quando o componente for desmontado para evitar memory leaks
        clearInterval(this.interval);
    }

    updateErros(moniterros) {
        const erros = moniterros.map(erro => `ID(${erro.idmonit}) - ${erro.descricao} parado a mais de ${parseInt(erro.minutos)} minutos - Ocorrência em: ${new Date(erro.dtocorrencia).toLocaleDateString('pt-BR', {
            day: '2-digit',
            month: '2-digit',
            year: 'numeric',
            hour: '2-digit',
            minute: '2-digit',
            second: '2-digit'
        })}`);
        this.setState({ erros });
    }

    render() {
        const { menu } = this.props;
        const { erros } = this.state;

        // console.log(erros);
        const itemMonitoramento = menu.find(item => item.nome === 'Monitoramento' && item.status === 1);

        return (
            <div>
                <Menu stackable size="mini">
                    <Menu.Item>
                        <Breadcrumb size="mini">
                            <Breadcrumb.Section active>Home</Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <style>{`
                    .ui.segment.inverted.Change:hover { background-color: #000000 !important; }
                    .ui.segment.inverted.teal { background-color: #59cfc9 !important; }
                    .ui.segment.inverted.orange { background-color: #f6a26b !important; }
                    .ui.segment.inverted.olive { background-color: #cfde68 !important; }
                    .ui.segment.inverted.yellow { background-color: #fcd45e !important; }
                    .ui.segment.inverted.red { background-color: #e77373 !important; }
                    .ui.segment.inverted.blue { background-color: #6eafe0 !important; }
                    .ui.segment.inverted.green { background-color: #6ed286 !important; }
                    .ui.segment.inverted.purple { background-color: #ba6ad4 !important; }
                    .ui.segment.inverted.pink { background-color: #eb7ebb !important; }
                    .ui.segment.inverted.brown { background-color: #c49c82 !important; }
                    .ui.segment.inverted.grey { background-color: #a5a5a5 !important; }
                    .ui.segment.inverted.violet { background-color: #7a55c9 !important; }
                    .ui.segment.inverted.desativado { background-color: #e5e2e2 !important; }
                    .ui.segment.inverted.teal:hover { background-color: #00b5ad !important; }
                    .ui.segment.inverted.orange:hover { background-color: #f2711c !important; }
                    .ui.segment.inverted.olive:hover { background-color: #b5cc18 !important; }
                    .ui.segment.inverted.yellow:hover { background-color: #fbbd08 !important; }
                    .ui.segment.inverted.red:hover { background-color: #db2828 !important; }
                    .ui.segment.inverted.blue:hover { background-color: #2185d0 !important; }
                    .ui.segment.inverted.green:hover { background-color: #21ba45 !important; }
                    .ui.segment.inverted.purple:hover { background-color: #a333c8 !important; }
                    .ui.segment.inverted.pink:hover { background-color: #e03997 !important; }
                    .ui.segment.inverted.brown:hover { background-color: #a5673f !important; }
                    .ui.segment.inverted.grey:hover { background-color: #767676 !important; }
                    .ui.segment.inverted.violet:hover { background-color: #6435c9 !important; }
                `}</style>

                <hr />
                {menu.map((item, index) => {
                    if (item.nome === 'Monitoramento' && item.status === 1 && erros.length > 0) {
                        return (
                            <Message
                                key={index}
                                error
                                header="Processos parados:"
                                list={erros} // Exibe os erros aqui
                            />
                        );
                    }
                    return null;
                })}

                <Grid columns={4} doubling stackable>
                    {menu.map((item, index) => (
                        <Grid.Column key={index}>
                            {item.nome === 'Mercado Livre' ? (
                                <a rel="mltoken" href="http://auth.mercadolivre.com.br/authorization?response_type=code&client_id=3344198269232630&redirect_uri=https://adsite.esplane.com.br/mercadolivretoken.asp">
                                    <Segment className={item.status ? item.cor : 'desativado'} inverted>
                                        <Header as="h4">
                                            <Icon name={item.icon} />
                                            <Header.Content>{item.nome}</Header.Content>
                                        </Header>
                                    </Segment>
                                </a>
                            ) : (item.nome === "Monitoramento" ? ('') : (
                                <>
                                    <Link to={item.status ? item.rota : "/Home"} title={item.status ? item.descricao : `${item.descricao} - Você não tem acesso à essa funcionalidade`}>

                                        <Segment className={item.status ? item.cor : "desativado"} inverted>
                                            <Header as='h4'>
                                                <Icon name={item.icon} />
                                                <Header.Content>{item.nome}</Header.Content>
                                            </Header>
                                        </Segment>
                                    </Link>
                                </>

                            ))
                            }
                        </Grid.Column>
                    ))}
                </Grid>
            </div>
        );
    }
}

const mapStateToProps = state => ({
    menu: state.LoginReducer.menu,
    moniterros: state.MonitoramentoReducer.moniterros
});

export default withRouter(connect(mapStateToProps, {
    monitoramentoErrosAction
})(Home));
