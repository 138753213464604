import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Form, Input, Button, Header, Grid, Segment, Modal, Message, Tab, Icon, Popup, ItemContent } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';

import InputMask from 'react-input-mask';

import ModalCarta from './ModalCarta';
import ModalConfissao from './ModalConfissao';


import If from '../../utils/If';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}
`


class Consulta extends Component {

  state = { query: '' }

  constructor(props) {
    super(props);
  }

  componentDidMount() {

  }

  handleFormSubmit = (e) => {
    this.props.renegociacaoListConsultaAction(this.state.query).then(d => { })
  }

  handleInputChange = (e) => {
    this.setState({
      query: e.target.value
    });

  }

  tipopagamento = (tipo) => {
    let forma = ''

    switch (tipo) {
      case 0: {
        forma = 'ADSite';
        break;
      }

      case 1: {
        forma = 'Boleto';
        break;
      }
      case 2: {
        forma = 'Cartão de Crédito';
        break;
      }
      case 3: {
        forma = 'Depósito Bancário';
        break;
      }
      case 4: {
        forma = 'Somente ADSite';
        break;
      }
    }

    return forma

  }



  statuspagamento = (status) => {
    let pagto = ''

    switch (status) {
      case 1: {
        pagto = 'Pago';
        break;
      }
      case 0: {
        pagto = 'Pendente';
        break;
      }

    }

    return pagto

  }

  onCancelOrdemCarga(e, entidade) {
    if (entidade.iscanceling == false) {
      this.props.renegociacaoCancelConsultaAction(entidade).then(d => { })
    }
  }


  render() {


    let { consulta } = this.props

    let parceiro = ''
    if (consulta.length > 0) {
      parceiro = consulta[0].parceiro
    }



    return (
      <div>
        <Segment>
          <Grid columns={1} relaxed='very'>
            <Grid.Column verticalAlign='middle'>
              <Form>

                <Input
                  action={{ color: 'blue', content: 'Buscar', onClick: this.handleFormSubmit.bind(this) }}
                  icon='search'
                  iconPosition='left'
                  placeholder='CodParc #'
                  onChange={this.handleInputChange.bind(this)}
                  loading={this.props.loaderconsulta}
                  maxLength={10}
                />
              </Form>

            </Grid.Column>

          </Grid>










          <If test={consulta.length > 0}>
            <Header as='h4' floated='left'>
              {parceiro}
            </Header>
            <StyledTable compact >
              <StyledTableHeader>
                <Table.Row>
                  <Table.HeaderCell>Renegociação</Table.HeaderCell>
                  <Table.HeaderCell>DT Renegociação</Table.HeaderCell>
                  <Table.HeaderCell>Total c/ juros</Table.HeaderCell>
                  <Table.HeaderCell>Parcelas</Table.HeaderCell>
                  <Table.HeaderCell>VLR. Parcela</Table.HeaderCell>
                  <Table.HeaderCell>Forma Pagto</Table.HeaderCell>
                  <Table.HeaderCell>Pagamento</Table.HeaderCell>
                  <Table.HeaderCell>DT Acesso</Table.HeaderCell>
                  <Table.HeaderCell>Compensou?</Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>
                  <Table.HeaderCell></Table.HeaderCell>


                </Table.Row>
              </StyledTableHeader>

              <StyledTableBody>
                {consulta.map(item => (

                  <Table.Row key={`reneg${item.idrenegociacaoweb}`} negative={(item.status == 1 ? false : true)} positive={(item.status == 1 ? true : false)}>
                    <Table.Cell>{item.idrenegociacaoweb}</Table.Cell>
                    <Table.Cell>{moment(item.dtrenegociacao).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrtotal)}</Table.Cell>
                    <Table.Cell>{item.parcelas}</Table.Cell>
                    <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrparcela)}</Table.Cell>
                    <Table.Cell>{this.tipopagamento(item.pagto)}</Table.Cell>
                    <Table.Cell>{this.statuspagamento(item.status)}</Table.Cell>
                    <Table.Cell>{(item.dtacesso == null ? "Nunca acessou" : moment(item.dtacesso).format("DD/MM/YYYY HH:mm"))}</Table.Cell>
                    <Table.Cell negative={(item.nucompens>0?true:false)} textAlign="center">{(item.pagto==0||item.pagto==2?(item.nucompens>0?"Não":"Sim"):"---")}</Table.Cell>
                    <Table.Cell>


                      <Modal trigger={<Button basic icon={'search'} color={'blue'} size="mini" />}>
                        <Modal.Header>#{item.idrenegociacaoweb} Renegociação - {item.parceiro}({item.codparc}) -  {moment(item.dtrenegociacao).format("DD/MM/YYYY HH:mm")}
                        </Modal.Header>
                        <Modal.Content>
                          <Modal.Description>
                            <Form>
                              <Form.Group widths='equal' color='gray'>
                                <div className="field"><label>Pagamento</label>
                                  <div className="ui fluid input">
                                    {this.statuspagamento(item.status)}
                                  </div>
                                </div>

                                <div className="field"><label>Total c/ Juros</label>
                                  <div className="ui fluid input">{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrtotal)}</div>
                                </div>

                                <div className="field">
                                  <label>Parcelas</label>
                                  <div>
                                    {item.parcelas}
                                  </div>
                                </div>

                                <If test={item.nossonum != null}>
                                  <div className="field">
                                    <label>Nosso Num.</label>
                                    <div>
                                      {item.nossonum}
                                    </div>
                                  </div>
                                </If>

                                <div className="field"><label>Vlr Parcela</label>
                                  <div>
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrparcela)}
                                  </div>
                                </div>

                                <div className="field"><label>Forma Pagto.</label>
                                  <div>
                                    {this.tipopagamento(item.pagto)}
                                  </div>
                                </div>

                                <div className="field"><label>Dt Acesso</label>
                                  <div>
                                    {(item.dtacesso == null ? "Nunca acessou" : moment(item.dtacesso).format("DD/MM/YYYY HH:mm"))}
                                  </div>
                                </div>



                              </Form.Group>

                              <Form.Group widths='equal'>

                                <StyledTable compact celled>
                                  <StyledTableHeader>
                                    <Table.Row>
                                      <Table.HeaderCell>Nufin</Table.HeaderCell>
                                      <Table.HeaderCell>Tipo</Table.HeaderCell>
                                      <Table.HeaderCell>Nota</Table.HeaderCell>
                                      <Table.HeaderCell>Vencimento</Table.HeaderCell>
                                      <Table.HeaderCell>Devido</Table.HeaderCell>
                                      <Table.HeaderCell>%Multa</Table.HeaderCell>
                                      <Table.HeaderCell>Multa</Table.HeaderCell>
                                      <Table.HeaderCell>%Juros</Table.HeaderCell>
                                      <Table.HeaderCell>Juros</Table.HeaderCell>
                                      <Table.HeaderCell>Juros Parc</Table.HeaderCell>
                                      <Table.HeaderCell>Dias</Table.HeaderCell>
                                      <Table.HeaderCell>Meses</Table.HeaderCell>
                                      <Table.HeaderCell>Total</Table.HeaderCell>
                                    </Table.Row>
                                  </StyledTableHeader>

                                  <StyledTableBody>
                                    {item.dividas.map(divida => (

                                      <Table.Row key={`fin${divida.nufin}`}>
                                        <Table.Cell>{divida.nufin}</Table.Cell>
                                        <Popup trigger={<Table.Cell>{divida.codtiptit}</Table.Cell>} content={divida.tipotitulo} />
                                        <Table.Cell>{divida.numnota}</Table.Cell>
                                        <Table.Cell>{(divida.dtvenc == null ? "Nunca acessou" : moment(divida.dtvenc).format("DD/MM/YYYY"))}</Table.Cell>
                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(divida.vlrdesdob)}</Table.Cell>
                                        <Table.Cell>{divida.pctmulta}</Table.Cell>
                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(divida.vlrmulta)}</Table.Cell>
                                        <Table.Cell>{divida.pctjuros}</Table.Cell>
                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(divida.vlrjuros)}</Table.Cell>
                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(divida.vlrjuros_parc)}</Table.Cell>
                                        <Table.Cell>{divida.dias}</Table.Cell>
                                        <Table.Cell>{divida.meses}</Table.Cell>
                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(divida.vlrtotalparcela + divida.vlrjuros_parc)}</Table.Cell>
                                      </Table.Row>
                                    )
                                    )}

                                  </StyledTableBody>

                                </StyledTable>

                              </Form.Group>





                              <If test={item.dividas.filter(d => d.chequenome != null).length > 0}>

                                <Form.Group widths='equal'>

                                  <StyledTable compact >
                                    <StyledTableHeader>
                                      <Table.Row>
                                        <Table.HeaderCell>Nufin</Table.HeaderCell>
                                        <Table.HeaderCell>Cheque</Table.HeaderCell>
                                        <Table.HeaderCell>CPF/CNPJ</Table.HeaderCell>
                                        <Table.HeaderCell>Nome</Table.HeaderCell>
                                        <Table.HeaderCell>Banco</Table.HeaderCell>
                                        <Table.HeaderCell>Agência</Table.HeaderCell>
                                        <Table.HeaderCell>Conta</Table.HeaderCell>
                                        <Table.HeaderCell>Dt Cheque</Table.HeaderCell>
                                        <Table.HeaderCell>Vlr Cheque</Table.HeaderCell>
                                      </Table.Row>
                                    </StyledTableHeader>

                                    <StyledTableBody>
                                      {item.dividas.filter(d => d.chequenome != null).map(divida => (
                                        <Table.Row key={`ch${divida.nufin}`}>
                                          <Table.Cell>{divida.nufin}</Table.Cell>
                                          <Table.Cell>{divida.chequenumero}</Table.Cell>
                                          <Table.Cell>{divida.chequecpf}</Table.Cell>
                                          <Table.Cell>{divida.chequenome}</Table.Cell>
                                          <Table.Cell>{divida.chequebanco}</Table.Cell>
                                          <Table.Cell>{divida.chequeagencia}</Table.Cell>
                                          <Table.Cell>{divida.chequeconta}</Table.Cell>
                                          <Table.Cell>{(divida.chequedata == null ? "" : moment(divida.chequedata).format("DD/MM/YYYY"))}</Table.Cell>
                                          <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(divida.chequevlr)}</Table.Cell>
                                        </Table.Row>
                                      )
                                      )}

                                    </StyledTableBody>

                                  </StyledTable>

                                </Form.Group>
                              </If>

                            </Form>



                          </Modal.Description>
                        </Modal.Content>
                      </Modal>




                    </Table.Cell>

                    <Table.Cell>
                      <If test={item.status == 1 && item.pagto != 0}>
                        <ModalCarta
                          entidade={item}
                        />
                      </If>
                      <If test={item.status == 0 && item.pagto == 2}>
                        <ModalConfissao
                          entidade={item}
                        />
                      </If>
                    </Table.Cell>

                    <Table.Cell>
                      <If test={item.status == 0}>
                      <Button key='btncancel' basic icon={'cancel'} color={'red'} size="mini"
                        onClick={(e) => this.onCancelOrdemCarga(e, item)}
                        loading={item.iscanceling}
                      />
                      </If>

                    </Table.Cell>


                  </Table.Row>
                )
                )}

              </StyledTableBody>



            </StyledTable>
          </If>




        </Segment>




      </div>
    )
  }

}

Consulta.defaultProps = {
  consulta: []
  , loaderconsulta: false
};

Consulta.propTypes = {
  consulta: PropTypes.array.isRequired
  , loaderconsulta: PropTypes.array.isRequired
  , renegociacaoListConsultaAction: PropTypes.func.isRequired
}

export default Consulta




