import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import {
    Table, Button, Dropdown

} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';

import InputMask from 'react-input-mask';






const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyleTableCell = styled(Table.Cell)`
&&& {
    div{
        font-size:10px !important;
        width:150px !important;
    },
   }   
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    font-size:10px !important;
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}


`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 1px 1px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




class Pedidos extends Component {


    state = {}

    constructor(props) {
        super(props);

    }

    onChangeTicket(e, pedido) {

        //console.log(e.target.value)
        //console.log(e.target.name)

        this.props.comissoesUpdateTicketAction(pedido, e.target.value)

    }




    onChangeVendedor(e, { entidade, value }) {
        let vendedorvcc = ""
        vendedorvcc = value
        /*alert(`
        pedidofbits: ${entidade.pedidofbits}
        value: ${vendedorvcc}
        `)*/
        //console.log(value)
        //this.props.despesasDeViagensSaidasChangeMotoristaAction(ordemcarga, parseInt(value, 10))
        this.props.comissoesChangeVendedorAction(entidade, vendedorvcc)
    }


    onSave(entidade) {

        //entidade.userid = parseInt(this.props.userid, 10)

        if (entidade.vendedorvcc == "") {
            entidade.vendedorvcc = this.props.vendedorvcc//parseInt(this.props.vendedorvcc, 10)
        }

        if(entidade.vendedorvcc==null ||entidade.vendedorvcc=='null'){
            entidade.vendedorvcc=entidade.codvend
        }

        entidade.login = this.props.login;
        /*
        alert(`
        pedidofbits: ${entidade.pedidofbits}
        vendedorvcc: ${entidade.vendedorvcc}
        `)*/

        //entidade.userid = parseInt(this.props.userid, 10)
        //console.log('##data:'+this.isDate(entidade.dtprevsaida))

        if (entidade.vendedorvcc != "" && entidade.vendedorvcc != null) {
            this.props.comissoesSaveAction(entidade)
        }
    }



    componentWillReceiveProps() {




    }

    componentDidMount() {
        //this.props.consultoresListFechamentoAction().then(d=>{})

        //const { endpoint } = this.state;
        /*const socket = socketIOClient('http://localhost:3001');
        socket.on("FromAPI", data => this.setState({ response: data }));*/
    }




    render() {

        let { datalist, vendedores, vendedorvcc } = this.props


        let pro = {
            mask: '999999',
            maskChar: '',
            alwaysShowMask: false,
            formatChars: {
              '9': '[0-9]',
            },
          }



        return (
            <div>


                <StyledTable celled id="listPedidos" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell>Fbits</Table.HeaderCell>
                            <Table.HeaderCell>Nunota</Table.HeaderCell>
                            <Table.HeaderCell>Data Integração</Table.HeaderCell>
                            <Table.HeaderCell>Cliente</Table.HeaderCell>
                            <Table.HeaderCell>Valor</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell>Vendedor</Table.HeaderCell>
                            <Table.HeaderCell>Vendedor Original</Table.HeaderCell>
                            <Table.HeaderCell>Último Vendedor</Table.HeaderCell>
                            <Table.HeaderCell>Qtde trocas</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell width="1">Ticket</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {datalist.map(item => (
                            <Table.Row key={"pedido_" + item.pedidofbits}>
                                <Table.Cell>{item.pedidofbits}</Table.Cell>
                                <Table.Cell>{item.nunota}</Table.Cell>
                                <Table.Cell>{moment(item.dataintegracao).format('DD/MM/YYYY HH:mm')}</Table.Cell>

                                <Table.Cell singleLine>
                                    {item.parceiro}
                                </Table.Cell>
                                <Table.Cell>
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.status}
                                </Table.Cell>
                                <Table.Cell singleLine>
                                    {item.vendedor}
                                </Table.Cell>
                                <Table.Cell singleLine>
                                    {item.vendedororiginal}
                                </Table.Cell>
                                <Table.Cell singleLine>
                                    {item.vendedorultimo}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.vendedortrocas}
                                </Table.Cell>
                                <Table.Cell>
                                    {item.ticketocta}
                                </Table.Cell>
                                <Table.Cell>
                                    <InputMask name="ticketocta" placeholder=""
                                     
                                        value={item.ticketocta}
                                        {...pro}
                                        onChange={(e) => this.onChangeTicket(e, item)}
                                        style={{ width: "50px" }}
                                    />

                                </Table.Cell>
                                <StyleTableCell>
                                    <div>
                                        <Dropdown
                                        
                                            options={vendedores}
                                            selection
                                            placeholder='Vendedores'
                                            name='vendedores'
                                            fluid
                                            defaultValue={(vendedorvcc != null ? vendedorvcc.toString() : vendedorvcc)}
                                            entidade={item}
                                            onChange={this.onChangeVendedor.bind(this)}
                                        />
                                    </div>
                                </StyleTableCell>
                                <Table.Cell>
                                    <If test={vendedorvcc != 202 && vendedorvcc != 203}>
                                        <Button key={`save_${item.pedidofbits}`} basic icon={'hand point up outline'} color={'green'} size="mini"
                                            onClick={(e) => this.onSave(item)}
                                            loading={item.issaving}
                                            disabled={false}
                                        />
                                    </If>

                                    <If test={vendedorvcc == 202 || vendedorvcc == 203}>
                                        <Button key={`save_${item.pedidofbits}`} basic icon={'hand point up outline'} color={(item.codvend == 94 && (vendedorvcc == 202 || vendedorvcc == 203) ? 'green' : 'grey')} size="mini"
                                            onClick={(e) => this.onSave(item)}
                                            loading={item.issaving}
                                            disabled={(item.codvend == 94 && (vendedorvcc == 202 || vendedorvcc == 203) ? false : true)}
                                        />
                                    </If>

                                </Table.Cell>

                            </Table.Row>
                        ))}


                    </StyledTableBody>
                </StyledTable>
            </div>

        )
    }

}

Pedidos.defaultProps = {
    datalist: []
    , vendedores: []
    , vendedorvcc: ""
    , login: ""
};

Pedidos.propTypes = {
    datalist: PropTypes.array.isRequired
    , vendedores: PropTypes.array.isRequired
    , vendedorvcc: PropTypes.string.isRequired
    , comissoesSaveAction: PropTypes.func.isRequired
    , comissoesChangeVendedorAction: PropTypes.func.isRequired
    , login: PropTypes.string.isRequired
    , comissoesUpdateTicketAction: PropTypes.func.isRequired

};

export default Pedidos

