import {
    CADASTRODEPRODUTOS_EXPORT_ISEXPORTING
    , CADASTRODEPRODUTOS_EXPORT_LIST
    , CADASTRODEPRODUTOS_SEARCH
    , CADASTRODEPRODUTOS_SEARCH_RESULT

    , CADASTRODEPRODUTOS_EXPORT_LEROY
    , CADASTRODEPRODUTOS_EXPORT_ISLEROYEXPORTING


    , CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE
    , CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE


    , CADASTRODEPRODUTOSB2B_SEARCH
    , CADASTRODEPRODUTOSB2B_SEARCH_RESULT

    , CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE
    , CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE

} from '../actions/ActionsTypes';

import * as Api from '../utils/API';


export const CadastroDeProdutosInfoSaveAction = (entidade, produtos, login) => (dispatch) =>
    new Promise(function (resolve, reject) {

        entidade.produtos = produtos
        entidade.login = login
        dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "issaving", payload: true })
        //alert('salvar')


        Api.postSaveCadastroDeProdutosInfo(entidade).then(dados => {
            dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "issaving", payload: false })

            if (dados.status == 'OK') {
                dispatch({ type: CADASTRODEPRODUTOS_SEARCH, payload: [] })
                dispatch({ type: CADASTRODEPRODUTOS_SEARCH_RESULT, payload: [] })


                //limpando checkbox
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infotextoprodutoflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infotextogarantiaflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infourlpdfgarantiaflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infourlvideoprincipalflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infourlvideosecundarioflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infotextomododeusarflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infotextocuidadosflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infourlpdfcuidadosflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE, field: "infohtmlvalorreferenteflag", payload: false })

                //limpando campos
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infotextoproduto", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infotextogarantia", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infourlpdfgarantia", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infourlvideoprincipal", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infourlvideosecundario", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infotextomododeusar", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infotextocuidados", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infourlpdfcuidados", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "infohtmlvalorreferente", payload: "" })

                dispatch({ type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE, field: "produtos", payload: [] })



            }

            resolve(dados)
        })



    })


export const CadastroDeProdutosInfoCamposAction = (name, value) => {
    return {
        type: CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE
        , field: name
        , payload: value
    }
}


export const CadastroDeProdutosInfoCheckboxAction = (name, value) => {
    return {
        type: CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE
        , field: name
        , payload: value
    }
}



export const CadastroDeProdutosExportListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CADASTRODEPRODUTOS_EXPORT_ISEXPORTING, payload: true })
        //console.log("###ENTIDADE")
        //console.log(entidade)
        Api.getAllCadastroDeProdutosExport().then(dados => {
            dispatch({ type: CADASTRODEPRODUTOS_EXPORT_LIST, payload: dados })
            dispatch({ type: CADASTRODEPRODUTOS_EXPORT_ISEXPORTING, payload: false })
            resolve([])
        })
    }
    )

export const CadastroDeProdutosExportLeroyAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CADASTRODEPRODUTOS_EXPORT_ISLEROYEXPORTING, payload: true })
        //console.log("###ENTIDADE")
        //console.log(entidade)

        Api.getLeroyCadastroDeProdutosExport().then(dados => {
            dispatch({ type: CADASTRODEPRODUTOS_EXPORT_LEROY, payload: dados })
            dispatch({ type: CADASTRODEPRODUTOS_EXPORT_ISLEROYEXPORTING, payload: false })
            resolve([])
        })
    }
    )


export const CadastroDeProdutosInfoSearchAction = (search) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CADASTRODEPRODUTOS_SEARCH, payload: search })
        dispatch({ type: CADASTRODEPRODUTOS_SEARCH_RESULT, payload: [] })
        Api.postAllCadastroDeProdutosInfoSearch(search).then(dados => {

            let result = []
            for (let item of search) {
                let busca = {}

                let encontrou = dados.filter(r => (r.sku == item.searchint)).length;

                if (encontrou > 0) {
                    busca.sku = "" + dados.find(a => a.sku == item.searchint).sku + ""
                    busca.codprod = dados.find(a => a.sku == item.searchint).sku
                    busca.mitra = dados.find(a => a.sku == item.searchint).mitra
                    busca.fbits = dados.find(a => a.sku == item.searchint).fbits
                    busca.b2b = dados.find(a => a.sku == item.searchint).b2b
                    busca.produto = dados.find(a => a.sku == item.searchint).produto
                    busca.status = 1
                } else {
                    busca.sku = item.searchstr
                    busca.codprod = -1
                    busca.mitra = ""
                    busca.fbits = ""
                    busca.b2b = ""
                    busca.produto = ""
                    busca.status = 0
                }

                result.push(busca)
            }

            dispatch({ type: CADASTRODEPRODUTOS_SEARCH_RESULT, payload: result })


            resolve([])





        })
    })
















export const CadastroDeProdutosInfoSaveB2BAction = (entidade, produtos, login) => (dispatch) =>
    new Promise(function (resolve, reject) {

        entidade.produtos = produtos
        entidade.login = login
        dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "issaving", payload: true })
        //alert('salvar')


        Api.postSaveCadastroDeProdutosInfoB2B(entidade).then(dados => {
            dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "issaving", payload: false })

            if (dados.status == 'OK') {
                dispatch({ type: CADASTRODEPRODUTOSB2B_SEARCH, payload: [] })
                dispatch({ type: CADASTRODEPRODUTOSB2B_SEARCH_RESULT, payload: [] })


                //limpando checkbox
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infotextoprodutoflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infotextogarantiaflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infourlpdfgarantiaflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infourlvideoprincipalflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infourlvideosecundarioflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infotextomododeusarflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infotextocuidadosflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infourlpdfcuidadosflag", payload: false })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE, field: "infohtmlvalorreferenteflag", payload: false })

                //limpando campos
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infotextoproduto", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infotextogarantia", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infourlpdfgarantia", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infourlvideoprincipal", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infourlvideosecundario", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infotextomododeusar", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infotextocuidados", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infourlpdfcuidados", payload: "" })
                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "infohtmlvalorreferente", payload: "" })

                dispatch({ type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE, field: "produtos", payload: [] })
            }

            resolve(dados)
        })



    })


export const CadastroDeProdutosInfoSearchB2BAction = (search) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CADASTRODEPRODUTOSB2B_SEARCH, payload: search })
        dispatch({ type: CADASTRODEPRODUTOSB2B_SEARCH_RESULT, payload: [] })
        Api.postAllCadastroDeProdutosInfoSearch(search).then(dados => {

            let result = []

            for (let item of search) {
                let busca = {}

                let encontrou = dados.filter(r => (r.sku == item.searchint)).length;

                if (encontrou > 0) {
                    busca.sku = "" + dados.find(a => a.sku == item.searchint).sku + ""
                    busca.codprod = dados.find(a => a.sku == item.searchint).sku
                    busca.mitra = dados.find(a => a.sku == item.searchint).mitra
                    busca.fbits = dados.find(a => a.sku == item.searchint).fbits
                    busca.b2b = dados.find(a => a.sku == item.searchint).b2b
                    busca.produto = dados.find(a => a.sku == item.searchint).produto
                    busca.status = 1
                } else {
                    busca.sku = item.searchstr
                    busca.codprod = -1
                    busca.mitra = ""
                    busca.fbits = ""
                    busca.b2b = ""
                    busca.produto = ""
                    busca.status = 0
                }

                result.push(busca)
            }

            dispatch({ type: CADASTRODEPRODUTOSB2B_SEARCH_RESULT, payload: result })

            resolve([])

        })
    })


export const CadastroDeProdutosInfoCamposB2BAction = (name, value) => {
    return {
        type: CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE
        , field: name
        , payload: value
    }
}


export const CadastroDeProdutosInfoCheckboxB2BAction = (name, value) => {
    return {
        type: CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE
        , field: name
        , payload: value
    }
}


















