import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';




import If from '../../utils/If';


import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
    , Button, Table
    , Segment, Input, Checkbox, Message, Header, Search
} from 'semantic-ui-react'


import Renegociacao from './Renegociacao'
import ListConfiguracoes from './ListConfiguracoes'
import Consulta from './Consulta'


import {
    renegociacaoListConfiguracoesAction
    , renegociacaoListDividasAction
    , renegociacaoCheckItemAction
    , renegociacaoCheckAllAction
    , renegociacaoSaveConfigAction
    , renegociacaoSaveAction
    , renegociacaoListConsultaAction
    , renegociacaoSimulacaoAction
    , renegociacaoListCreditoAction
    , renegociacaoCancelConsultaAction
} from '../../actions/RenegociacaoDeDividasActions';






import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
td{
  font-size:12px;
  
  
}

`;







class RenegociacaoDeDividas extends Component {

    state = {}


    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }




    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 7 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        this.props.renegociacaoListConfiguracoesAction().then(data=>{})


    }



    render() {

        let { configuracoes } = this.props




        let panes = [
            {
                menuItem: <Menu.Item key='TABRenegociar'><Icon name='handshake' />Renegociar</Menu.Item>,
                render: () =>
                    <Tab.Pane
                    loading={this.props.loaderconfig}
                    >
                    
                    <Renegociacao
                    configuracoes={this.props.configuracoes}
                    dividas={this.props.dividas}
                    loaderdividas={this.props.loaderdividas}
                    renegociacaoListDividasAction={this.props.renegociacaoListDividasAction}
                    renegociacaoCheckItemAction={this.props.renegociacaoCheckItemAction}
                    renegociacaoCheckAllAction={this.props.renegociacaoCheckAllAction}
                    renegociacaoSaveAction={this.props.renegociacaoSaveAction}
                    userid={this.props.userid}
                    loaderissaving={this.props.loaderissaving}
                    renegociacaoSimulacaoAction={this.props.renegociacaoSimulacaoAction}
                    loaderissimulating={this.props.loaderissimulating}
                    renegociacaoListCreditoAction={this.props.renegociacaoListCreditoAction}
                    credito={this.props.credito}
                    codparc={this.props.codparc}

                    creditodados={this.props.creditodados}
                    compesdados={this.props.compesdados}
                    dividasdados={this.props.dividasdados}

                    
                    />
                        
                    </Tab.Pane>

            }
            
            

        ]

        panes.push(
            {
                menuItem: <Menu.Item key='TABConfig'><Icon name='cogs'/>Consulta</Menu.Item>,
                render: () =>  
                <Tab.Pane
                loading={this.props.loaderconfig}
                >    
                    <Consulta
                    consulta={this.props.consulta}
                    loaderconsulta={this.props.loaderconsulta}
                    renegociacaoListConsultaAction={this.props.renegociacaoListConsultaAction}
                    renegociacaoCancelConsultaAction={this.props.renegociacaoCancelConsultaAction}
                    />
             </Tab.Pane>
            }
        )

        if(this.props.userid=="3" || this.props.userid=="2" || this.props.userid=="4"){

            panes.push(
                {
                    menuItem: <Menu.Item key='TABConfig'><Icon name='cogs'/>Configurações</Menu.Item>,
                    render: () =>  
                    <Tab.Pane
                    loading={this.props.loaderconfig}
                    >    
                        <ListConfiguracoes
                        dataList={configuracoes}
                        loader={false}
                        renegociacaoSaveConfigAction={this.props.renegociacaoSaveConfigAction}
                        />
                 </Tab.Pane>
                }
            )
            
        }



      
        

        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Renegociação de Dívidas </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , configuracoes: state.RenegociacaoDeDividasReducer.configuracoes
        , dividas: state.RenegociacaoDeDividasReducer.dividas
        , loaderdividas: state.RenegociacaoDeDividasReducer.loaderdividas
        , loaderissaving: state.RenegociacaoDeDividasReducer.loaderissaving
        , loaderconfig: state.RenegociacaoDeDividasReducer.loaderconfig

        , consulta: state.RenegociacaoDeDividasReducer.consulta
        , loaderconsulta: state.RenegociacaoDeDividasReducer.loaderconsulta

        , loaderissimulating: state.RenegociacaoDeDividasReducer.loaderissimulating

        , codparc: state.RenegociacaoDeDividasReducer.codparc
        , credito: state.RenegociacaoDeDividasReducer.credito

        , creditodados: state.RenegociacaoDeDividasReducer.creditodados
        , compesdados: state.RenegociacaoDeDividasReducer.compesdados
        , dividasdados: state.RenegociacaoDeDividasReducer.dividasdados



    }
);


export default withRouter(connect(mapStateToProps, {
    renegociacaoListConfiguracoesAction
    , renegociacaoListDividasAction
    , renegociacaoCheckItemAction
    , renegociacaoCheckAllAction
    , renegociacaoSaveConfigAction
    , renegociacaoSaveAction
    , renegociacaoListConsultaAction
    , renegociacaoSimulacaoAction
    , renegociacaoListCreditoAction
    , renegociacaoCancelConsultaAction
}
)(RenegociacaoDeDividas));

