import {
    MONITORAMENTO_ERROS
} from '../actions/ActionsTypes'

const INITIAL_STATE = {
    moniterros: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case MONITORAMENTO_ERROS:
            return { ...state, moniterros: action.payload }

        default:
            return state;
    }
}