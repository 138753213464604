import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Segment, List, Image, Tab, Checkbox, Label, Popup, Icon } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
    font-size: .8em;
}
`

class ListGeral extends Component {

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }

    componentDidMount() {

    }

    /*contaconcorrenteporsku=(codprod,geral)=>{
        let numero = geral.filter(f=>f.codprod===codprod).length
        return numero
    }

    analisaproduto=(codprod,webpriceid,geral)=>{
        let status = ''

        let countproduto = geral.filter(f=>f.codprod===codprod && f.webpriceid ===webpriceid).length
        if(countproduto>0){
            if(geral.filter(f=>f.codprod===codprod && f.webpriceid ===webpriceid)[0].preco_avista > 0){
                status='tem estoque'
            }else{
                status='Indisponível'
            }
        }else{
            status='N/M'
        }

        return status
    }*/

 

    render() {
        let { produtos, concorrentes, geral,loader
            ,vermelho,amarelo,azul
        } = this.props;

        return (
            <Tab.Pane loading={loader}>

                    <Divider horizontal >Urgente - Temos estoque e não estamos monitorando ou WEBPRICE não sincronizou</Divider>

                    <StyledTable celled id="ListFaturadosSemRota" compact='very' size='small' color='black' inverted>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell>SKU</Table.HeaderCell>
                            <Table.HeaderCell>Produto</Table.HeaderCell>
                            <Table.HeaderCell>Qtd</Table.HeaderCell>
                            <Table.HeaderCell>Estoque</Table.HeaderCell>
                            {concorrentes.map(item => (
                                <Table.HeaderCell>{item.concorrente}</Table.HeaderCell>
                            ))}

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {vermelho.map(data => (
                            <Table.Row key={data.codprod}>
                                <Table.Cell>{data.codprod}</Table.Cell>
                                <Table.Cell>{data.descrprod}</Table.Cell>
                                <Table.Cell>{data.qtdecc}</Table.Cell>
                                <Table.Cell>{data.estoque}</Table.Cell>
                                {concorrentes.map(item => (
                                    <Table.Cell negative={(data[`cc${item.webpriceid}`]=='Indisponível'?true:false)}>
                                        {data[`cc${item.webpriceid}`]}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>




                <Divider horizontal >Temos estoque mas há concorrente(s) indisponível(is). Verificar na WEBPRICE e no site do concorrente</Divider>

                    <StyledTable celled id="ListFaturadosSemRota" compact='very' size='small' color='grey' inverted>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell>SKU</Table.HeaderCell>
                            <Table.HeaderCell>Produto</Table.HeaderCell>
                            <Table.HeaderCell>Qtd</Table.HeaderCell>
                            <Table.HeaderCell>Estoque</Table.HeaderCell>
                            {concorrentes.map(item => (
                                <Table.HeaderCell>{item.concorrente}</Table.HeaderCell>
                            ))}

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {amarelo.map(data => (
                            <Table.Row key={data.codprod} negative={(data.vermelho==1?true:false)}>
                                <Table.Cell>{data.codprod}</Table.Cell>
                                <Table.Cell>{data.descrprod}</Table.Cell>
                                <Table.Cell>{data.qtdecc}</Table.Cell>
                                <Table.Cell>{data.estoque}</Table.Cell>
                                {concorrentes.map(item => (
                                    <Table.Cell negative={(data[`cc${item.webpriceid}`]=='Indisponível'?true:false)}>
                                        {data[`cc${item.webpriceid}`]}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>




                <Divider horizontal >Produtos inativo no Mitra</Divider>

                    <StyledTable celled id="ListFaturadosSemRota" compact='very' size='small' color='blue' inverted>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell>SKU</Table.HeaderCell>
                            <Table.HeaderCell>Produto</Table.HeaderCell>
                            <Table.HeaderCell>Qtd</Table.HeaderCell>
                            <Table.HeaderCell>Estoque</Table.HeaderCell>
                            {concorrentes.map(item => (
                                <Table.HeaderCell>{item.concorrente}</Table.HeaderCell>
                            ))}

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {azul.map(data => (
                            <Table.Row key={data.codprod}>
                                <Table.Cell>{data.codprod}</Table.Cell>
                                <Table.Cell>{data.descrprod}</Table.Cell>
                                <Table.Cell>{data.qtdecc}</Table.Cell>
                                <Table.Cell>{data.estoque}</Table.Cell>
                                {concorrentes.map(item => (
                                    <Table.Cell negative={(data[`cc${item.webpriceid}`]=='Indisponível'?true:false)}>
                                        {data[`cc${item.webpriceid}`]}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>



                <Divider horizontal >OK - Temos estoque e concorrentes com estoque</Divider>

                    <StyledTable celled id="ListFaturadosSemRota" compact='very' size='small' color='green' inverted>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell>SKU</Table.HeaderCell>
                            <Table.HeaderCell>Produto</Table.HeaderCell>
                            <Table.HeaderCell>Qtd</Table.HeaderCell>
                            <Table.HeaderCell>Estoque</Table.HeaderCell>
                            {concorrentes.map(item => (
                                <Table.HeaderCell>{item.concorrente}</Table.HeaderCell>
                            ))}

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {geral.map(data => (
                            <Table.Row key={data.codprod}>
                                <Table.Cell>{data.codprod}</Table.Cell>
                                <Table.Cell>{data.descrprod}</Table.Cell>
                                <Table.Cell>{data.qtdecc}</Table.Cell>
                                <Table.Cell>{data.estoque}</Table.Cell>
                                {concorrentes.map(item => (
                                    <Table.Cell negative={(data[`cc${item.webpriceid}`]=='Indisponível'?true:false)}>
                                        {data[`cc${item.webpriceid}`]}
                                    </Table.Cell>
                                ))}
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>
                
            </Tab.Pane>
        )
    }
}

ListGeral.defaultProps = {
    geral: []
    ,vermelho:[]
    ,amarelo:[]
    ,azul:[]
    , concorrentes: []
    , produtos: []
    , loader: true
};

ListGeral.propTypes = {
    geral: PropTypes.array.isRequired
    ,vermelho: PropTypes.array.isRequired
    ,amarelo: PropTypes.array.isRequired
    ,azul: PropTypes.array.isRequired
    , concorrentes: PropTypes.array.isRequired
    , produtos: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
};



export default ListGeral
