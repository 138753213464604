
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ExcelRenderer, OutTable } from "react-excel-renderer";
import { withRouter } from 'react-router';
import { connect } from 'react-redux';


import InputMask from 'react-input-mask';



import moment from 'moment';
import 'moment/locale/pt-br'


import {
  bankpostArquivoMLAction
  , banklistArquivoMLAction
} from '../../actions/BankActions';

import {
  Table, Segment, Header, Menu, Breadcrumb, Message, Grid, Dimmer, Loader, Image
  , Tab, Button, Icon

} from 'semantic-ui-react'

import If from '../../utils/If';
import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;


class Bank extends Component {

  constructor(props) {
    super(props);
    this.state = {
      rows: [],
      cols: [],
      cabecalho: [],
      dados: [],
      arquivo: '',
      issaving: false,
      status: '',
      messagem: ''
    };
  }



  fileInputRef = React.createRef();

  changeHandler(event) {
    let fileObj = event.target.files[0];

    this.setState({ arquivo: event.target.files[0].name })

    //just pass the fileObj as parameter

    ExcelRenderer(fileObj, (err, resp) => {
      if (err) {
        console.log(err);
      } else {
        this.setState({
          cols: resp.cols,
          rows: resp.rows
        });

        //console.log(this.state);
        let registros = resp.rows;
        let cabecalho = registros[0];
        let dados = [];


        for (var i = 1; i < registros.length; i++) {
          //console.log(i); // index
          //console.log(registros[i]); // value

          let linha = {}

          let cont = 0
          for (let c of cabecalho) {
            //console.log(i)
            linha[c] = registros[i][cont];
            cont++
          }

          dados.push(linha)

        }

        //console.log('cabeçalho')
        //console.log(dados)
        this.setState({ cabecalho: cabecalho, dados: dados })

      }
    });
  }

  saveArquivoML() {
    if (!this.state.issaving) {

      if (this.state.dados.length > 0) {

        this.setState({ issaving: true })

        this.props.bankpostArquivoMLAction(this.state.arquivo, this.state.dados, this.props.login).then(d => {

          this.setState({ status: d[0].status, mensagem: d[0].mensagem })
          this.setState({ issaving: false })
        })
      }



    }

  }

  componentDidMount() {

    //Verificando permissão de funcionalidade do MENU-------------------
    let { menu, history } = this.props;
    if (menu.filter(m => m.menuid === 21 && m.status === 1).length === 0) { history.push(`/Home`) }
    //------------------------------------------------------------------

    this.props.banklistArquivoMLAction()

  }





  render() {

    let { filesml,filesmlloader } = this.props
    let { dados, cabecalho } = this.state;

    return (

      <div>

        <Menu stackable size='mini'>
          <Menu.Item>
            <Breadcrumb size='mini'>
              <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section active>Bank</Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu>


        <Tab panes={[
          {
            menuItem: 'Mercado Livre', render: () => <Tab.Pane >


              <Segment placeholder style={{ minHeight: 50 }}>

                <Grid>
                  <Grid.Row>
                    <Grid.Column width={6} textAlign="center">
                      <Header icon>
                        <Icon name='file excel outline' size="mini" />
                        Importação de arquivos de pagamentos
                      </Header>



                      <Button
                        primary
                        content="Escolher XLSX"
                        labelPosition="left"
                        icon="file"
                        onClick={() => this.fileInputRef.current.click()}
                      />
                      <input
                        ref={this.fileInputRef}
                        type="file"
                        hidden
                        onChange={this.changeHandler.bind(this)}
                      />
                    </Grid.Column>
                    <Grid.Column width={10}>
                      <Segment style={{ overflow: 'auto', maxHeight: 150 }}>

                        <If test={filesmlloader}>
                          <Segment>
                            <Dimmer active inverted>
                              <Loader inverted>Listando arquivos...</Loader>
                            </Dimmer>

                            <Image src='/img/short-paragraph.png' />

                            <Image src='/img/short-paragraph.png' />
                          </Segment>
                        </If>

                        <If test={!filesmlloader}>
                        <StyledTable celled compact >

                          <StyledTableHeader>
                            <Table.Row>

                              <Table.HeaderCell>Aquivo</Table.HeaderCell>
                              <Table.HeaderCell>Linhas</Table.HeaderCell>
                              <Table.HeaderCell>Usuário</Table.HeaderCell>

                            </Table.Row>
                          </StyledTableHeader>

                          <StyledTableBody>
                          {filesml.map(item =>
                            <Table.Row>
                              <Table.Cell collapsing>{item.nomearquivo}</Table.Cell>
                              <Table.Cell collapsing>{item.linhas}</Table.Cell>
                              <Table.Cell collapsing>{item.usuario}</Table.Cell>
                            </Table.Row>
                            )}
                            
                          </StyledTableBody>

                        </StyledTable>
                        </If>
                      </Segment>

                    </Grid.Column>
                  </Grid.Row>
                </Grid>




              </Segment>

              <If test={this.state.status != ''}>
                <Message attached='bottom' warning>
                  <Icon name='info' />
                  {this.state.mensagem}
                </Message>
              </If>

              <Segment style={{ overflow: 'auto', maxHeight: 230 }}>

                {/*<OutTable
                  data={this.state.rows}
                  columns={this.state.cols}
                  tableClassName="ExcelTable2007"
                  tableHeaderRowClass="heading"
              />*/}


                <StyledTable celled compact loading={true}>

                  <StyledTableHeader>
                    <Table.Row>
                      {cabecalho.map(item =>
                        <Table.HeaderCell>{item}</Table.HeaderCell>
                      )}
                    </Table.Row>
                  </StyledTableHeader>

                  <StyledTableBody>

                    {dados.map(item =>
                      <Table.Row>
                        {cabecalho.map(cab =>
                          <Table.Cell collapsing>{item[cab]}</Table.Cell>
                        )}
                      </Table.Row>
                    )}


                  </StyledTableBody>


                </StyledTable>

              </Segment>

              <If test={this.state.dados.length > 0}>

                <Button positive content={`Gravar (${this.state.dados.length}) linhas`} size="mini" floated="right"
                  loading={this.state.issaving}
                  onClick={() => this.saveArquivoML()}
                />
              </If>

              <br /><br /><br />







            </Tab.Pane>
          },

        ]} />




      </div>

    )

  }

}

Bank.defaultProps = {

};
Bank.propTypes = {
  //codigo: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  menu: state.LoginReducer.menu
  , login: state.LoginReducer.login
  , filesmlloader: state.BankReducer.filesmlloader
  , filesml: state.BankReducer.filesml

})

export default withRouter(connect(mapStateToProps, {
  bankpostArquivoMLAction
  , banklistArquivoMLAction
}
)(Bank));
