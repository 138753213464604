import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import If from '../../utils/If';


import {
    Grid, Menu, Breadcrumb, Tab, Icon, Table

} from 'semantic-ui-react'

import {
    serasaUpdateAction
    , serasaListSearchAction
} from '../../actions/SerasaActions';

import Historico from './Historico';


import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class Serasa extends Component {

    state = {}

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 18 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------


        /*
                this.props.corporativoListAction().then(dados => { })
        
        */



    }

    componentWillReceiveProps() {

    }

    render() {

        let { fechamento } = this.props

        let panes = [

            {
                menuItem: <Menu.Item key='TABHistorico'><Icon name='list alternate outline' />Histórico de Consultas</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={false}>
                        <Historico
                            datalist={this.props.search}
                            buscaEntity={this.props.SerasaBuscaEntity}
                            isSearching={this.props.isSearching}
                            serasaUpdateAction={this.props.serasaUpdateAction}
                            serasaListSearchAction={this.props.serasaListSearchAction}
                        />

                    </Tab.Pane>
            },


        ]



        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Serasa </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , SerasaBuscaEntity: state.SerasaReducer.SerasaBuscaEntity
        , isSearching: state.SerasaReducer.isSearching
        , search: state.SerasaReducer.search
    }
);


export default withRouter(connect(mapStateToProps, {
    serasaUpdateAction
    , serasaListSearchAction
}
)(Serasa));

