import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import { Tab, Icon, Menu, Breadcrumb, Grid } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';

import If from '../../utils/If';

import Contratos from './Contratos'

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}
`
class Frete extends Component {

    state = { query: '' }

    constructor(props) {
        super(props);
    }

    componentDidMount() {


        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 13 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

    }

    render() {

        let panes = []

        panes.push({
            menuItem: <Menu.Item key='TabContratos'><Icon name='list alternate outline' />Contratos</Menu.Item>,
            render: () =>
                <Tab.Pane>
                    <Contratos/>
                </Tab.Pane>
        })

        panes.push({
            menuItem: <Menu.Item key='TabReorganizar'><Icon name='list alternate outline' />Reorganizar</Menu.Item>,
            render: () =>
                <Tab.Pane>
                    <div>Par</div>
                </Tab.Pane>
        })

        panes.push({
            menuItem: <Menu.Item key='TabFaixas'><Icon name='list alternate outline' />Faixas</Menu.Item>,
            render: () =>
                <Tab.Pane>
                    <div>Par</div>
                </Tab.Pane>
        })

        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Frete </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }

}

const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated


    }
);


export default withRouter(connect(mapStateToProps, {

}
)(Frete));


