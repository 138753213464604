import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Segment, List, Image, Tab, Checkbox, Label, Header, Icon, Modal, Input, Form, TextArea, Dropdown, Popup } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';

import DateTimePicker from 'react-date-picker';
import logScreen from './LogScreen';
import LogScreen from './LogScreen';
import styled from 'styled-components';
import parse from 'html-react-parser';

const inlineStyle = {
    modal: {
        marginTop: '0px !important',
        marginLeft: 'auto',
        marginRight: 'auto'
    }
}

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }`

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}`

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}`

class ListEmTransportadora extends Component {

    state = { isSaving: false }

    constructor(props) {
        super(props);

        let { handleCHKInsertEmTransportadora
            , handleCHKRemoveEmTransportadora
            , handleCHKUpdateEmTransportadora
            , handleSavePedidosEmTransportadora
        } = this.props;

        this.updateStateList = this.updateStateList.bind(this);
        this.onChangeModalOpen = this.onChangeModalOpen.bind(this);
        this.onChangeModalClose = this.onChangeModalClose.bind(this);
        this.onChangePedido = this.onChangePedido.bind(this)
        this.onChangeDevolucao = this.onChangeDevolucao.bind(this);
    }



    onChangeDevolucao(e, pedido, carga) {

        if (!pedido.isupdatingdevolucao) {
            //console.log('#############')
            //console.log(pedido)
            //console.log('@@@@@@@@@@@')
            this.props.handleDevolucao(pedido, carga);
        }

    }

    updateStateList(e, { checked, value, pedido }) {
        this.setState({ isSaving: false });

        if (checked) {
            this.props.handleCHKInsertEmTransportadora(pedido)
        } else {
            this.props.handleCHKRemoveEmTransportadora(pedido)
        }
    }

    componentDidMount() {

    }

    onChangeModalOpen(e, pedido) {
        this.props.handlePedidoModal(pedido, true, false);
    }

    onChangeModalClose(e, pedido) {
        this.props.handlePedidoModal(pedido, false, false);
    }

    onChangePedido(e, pedido) {
        pedido.ad_trackndescr = e.target.value;
        pedido.alterado = true;
        this.props.handlePedido(pedido);
    }

    onChangePedidoMotivo(e, data) {
        data.pedido.ad_tracknentid = data.value;
        data.pedido.alterado = true;
        this.props.handlePedido(data.pedido);
    }

    onSaveMotivo(e, data) {
        if (data.pedido.ad_trackndescr != null && data.pedido.ad_tracknentid != -1)
            this.props.handlePedidoModal(data.pedido, false, true);
    }

    onCancelMotivo(e, data) {
        data.pedido.alterado = false;
        this.props.handlePedidoModal(data.pedido, false, false);
    }

    onSavePedido(e) {
        this.setState({ isSaving: true });
        this.props.handleSavePedidosEmTransportadora()
    }





    //Frete
    onChangeFreteOpen = (e, ordem) => {
        this.props.handleEmTransportadoraFreteOpenClose(ordem, true)
    }

    onChangeFreteClose = (e, ordem) => {
        // alert(`close ${ordem.id}`)
        this.props.handleEmTransportadoraFreteOpenClose(ordem, false)
    }

    onChangeFreteCheck = (e, ordem) => {
        this.props.handleEmTransportadoraFreteCheck(ordem, !ordem.fretecheck)
    }
    onChangeFreteSave = (e, ordem) => {
        //alert(`save ${ordem.id}`)
        if (!ordem.isfretesaving && ordem.fretecheck) {
            this.props.handleEmTransportadoraFreteSave(ordem)
        }

    }

    getMotivo = (id, motivos) => {
        let motivo = ''
        if (motivos.length > 0) {
            try {
                motivo = motivos.find(m => m.value.toString() == id).text + ' | '
            } catch (e) {
                motivo = ''
            }

        }
        return motivo;
    }

    onChangePrazos = (pedido, campo, data) => {
        /*console.log(`data: ${data}
        campo: ${campo}
        `)*/
        //console.log(pedido)
        this.props.ordemCargaEmTransportadoraUpdatePrazosAction(pedido, campo, data)
    }

    render() {
        let { cargas, loader, motivos, chkEmTransportadora
            , handleCHKInsertEmTransportadora, handleCHKRemoveEmTransportadora, handleCHKUpdateEmTransportadora
            , handleSavePedidosEmTransportadora
            , handlePedidoModal
            , handlePedido
            , logScreen
        } = this.props;

        return (
            <Tab.Pane loading={loader}>

                <LogScreen logData={logScreen} />

                {cargas.map(carga => (
                    <div key={carga.id}>
                        <List horizontal id={carga.id} size='small'>
                            <List.Item>
                                <List.Content>
                                    <List.Header>Transportadora: {carga.id}</List.Header>
                                </List.Content>
                            </List.Item>

                            <List.Item>
                                <List.Content>
                                    <Modal
                                        trigger={
                                            <Button basic icon={'location arrow'}
                                                color={'blue'} size="mini"
                                                onClick={(e) => this.onChangeFreteOpen(e, carga)}
                                            />}
                                        open={carga.freteopen}
                                        onClose={(e) => this.onChangeFreteClose(e, carga)}

                                    >
                                        <Modal.Header>Ordem de Carga: {carga.id} - Confirma que todos pedidos listados serão marcados como "Entregou" ?</Modal.Header>
                                        <Modal.Content>
                                            <Modal.Description>

                                                <StyledTable celled id={carga.id} compact='very' size='small'>
                                                    <StyledTableHeader>
                                                        <Table.Row>
                                                            <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                                                            <Table.HeaderCell width="1">Data Pedido</Table.HeaderCell>
                                                            <Table.HeaderCell width="1">Nota</Table.HeaderCell>
                                                            <Table.HeaderCell width="2">Data Nota</Table.HeaderCell>
                                                            <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                                                            <Table.HeaderCell width="2">Cidade</Table.HeaderCell>

                                                        </Table.Row>
                                                    </StyledTableHeader>

                                                    <StyledTableBody>
                                                        {carga.pedidos.map(pedido => (
                                                            <Table.Row key={pedido.numnota}>
                                                                <Table.Cell>{pedido.pedidofbits}</Table.Cell>
                                                                <Table.Cell>{moment(pedido.datapedido).format("DD/MM/YYYY")}</Table.Cell>
                                                                <Table.Cell>{pedido.numnota}</Table.Cell>
                                                                <Table.Cell>{moment(pedido.datanf).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                                                                <Table.Cell>{pedido.parceiro}</Table.Cell>
                                                                <Table.Cell>{pedido.cidadeparceiro}</Table.Cell>
                                                            </Table.Row>
                                                        ))}
                                                    </StyledTableBody>
                                                </StyledTable>

                                            </Modal.Description>

                                        </Modal.Content>
                                        <Modal.Actions>


                                            <Button color='red'
                                                onClick={(e) => this.onChangeFreteClose(e, carga)}
                                            >
                                                <Icon name='remove' /> Cancelar
                                            </Button>
                                            <Button color='green'
                                                loading={carga.isfretesaving}
                                                onClick={(e) => this.onChangeFreteSave(e, carga)}
                                            >
                                                <Icon name='checkmark' /> Confirmar
                                            </Button>


                                            <Checkbox toggle={true}
                                                onChange={(e) => this.onChangeFreteCheck(e, carga)}
                                                name="fretecheck"
                                                label='Estou ciente'

                                                checked={carga.fretecheck}
                                            />

                                        </Modal.Actions>
                                    </Modal>
                                </List.Content>
                            </List.Item>

                        </List>

                        <StyledTable celled id={carga.id} compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Data Pedido</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Nota</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Data Nota</Table.HeaderCell>
                                    <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Cidade</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Entregou</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Não Entregou</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Devolução</Table.HeaderCell>

                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {carga.pedidos.map(pedido => (
                                    <Table.Row key={pedido.numnota} active={(pedido.isatrasado == 3 ? true : false)} warning={(pedido.isatrasado == 1 ? true : false)} negative={(pedido.isatrasado == 2 ? true : false)}>
                                        {
                                            (pedido.iscartacorrecao == 'S' ?
                                                <Popup
                                                    trigger={<Table.Cell>{pedido.pedidofbits} <Icon link name='exclamation' /></Table.Cell>}
                                                    content={`O pedido ${pedido.pedidofbits} tem carta de correção`}
                                                    inverted
                                                />
                                                : <Table.Cell>{pedido.pedidofbits}</Table.Cell>
                                            )
                                        }
                                        <Popup
                                            trigger={<Table.Cell>{moment(pedido.datapedido).format("DD/MM/YYYY")}</Table.Cell>}

                                            flowing hoverable
                                            pinned
                                            on={['hover', 'click']}
                                        >
                                            <Segment secondary>
                                                <table width='100%' id={pedido.nunota + 'tb' + pedido.pedidofbits}>
                                                    <tbody>
                                                        <tr>
                                                            <td><b>Data Envio:</b></td>
                                                            <td><b>Previsão de entrega:</b></td>
                                                            <td></td>
                                                        </tr>
                                                        <tr>
                                                            <td>
                                                                <DateTimePicker
                                                                    onChange={this.onChangePrazos.bind(this, pedido, 'ad_dtenvio')}

                                                                    value={(pedido.ad_dtenvio == null ? "" : new Date(pedido.ad_dtenvio))}
                                                                    locale="pt-br"
                                                                    required={true}
                                                                    clearIcon=""
                                                                    disableClock={true}
                                                                    disableCalendar={true}

                                                                />
                                                            </td>
                                                            <td>
                                                                <DateTimePicker
                                                                    onChange={this.onChangePrazos.bind(this, pedido, 'ad_dtprevisaoen')}

                                                                    value={(pedido.ad_dtprevisaoen == null ? "" : new Date(pedido.ad_dtprevisaoen))}
                                                                    locale="pt-br"
                                                                    required={true}
                                                                    clearIcon=""
                                                                    disableClock={true}
                                                                    disableCalendar={true}

                                                                />
                                                            </td>
                                                            <td>
                                                                <Button positive size="tiny"
                                                                    loading={pedido.is_savingdt}
                                                                    onClick={(e) => this.props.ordemCargaEmTransportadoraSavePrazosAction(pedido, this.props.login)}
                                                                >Alterar
                                                                </Button></td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </Segment>
                                            {parse(pedido.detalhes)}
                                        </Popup>
                                        <Table.Cell>{pedido.numnota}</Table.Cell>
                                        <Table.Cell>{moment(pedido.datanf).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                                        <Table.Cell>{pedido.parceiro}</Table.Cell>

                                        <Popup
                                            trigger={<Table.Cell>{(pedido.cidadeparceiro != 'Retira' ? pedido.cidadeparceiro : '')}<Header color={(pedido.cidadeparceiro == 'Retira' ? 'teal' : '')} size='tiny'>{(pedido.cidadeparceiro == 'Retira' ? '(Retira)' : '')}</Header></Table.Cell>}
                                            content={pedido.enderecoentrega}
                                            inverted
                                        />
                                        <Table.Cell>{pedido.apelido}</Table.Cell>

                                        <Table.Cell>
                                            <div>
                                                <Checkbox toggle
                                                    name="chkEntregou"
                                                    key={pedido.nunota}
                                                    onChange={this.updateStateList}
                                                    pedido={pedido}
                                                />

                                            </div>
                                        </Table.Cell>
                                        <Table.Cell>

                                            <Modal
                                                trigger={

                                                    <Popup
                                                        trigger={<Button basic icon={(!pedido.alterado ? ('write') : ('edit'))}
                                                            color={(pedido.alterado ? ('red') : !pedido.alterado && pedido.ad_trackndescr != null ? ('green') : ('blue'))}
                                                            size="mini" onClick={(e) => this.onChangeModalOpen(e, pedido)}
                                                        />}
                                                        content={`
                                                             ${this.getMotivo(pedido.ad_tracknentid, motivos)}
                                                            ${pedido.ad_trackndescr}
                                                            `
                                                        }
                                                        inverted
                                                        disabled={(pedido.ad_trackndescr != null ? false : true)}
                                                    />


                                                }
                                                open={pedido.modal}
                                                onClose={(e) => this.onChangeModalClose(e, pedido)}
                                                basic
                                                style={inlineStyle.modal}
                                                size='small'>
                                                <br /><br /><br />
                                                <br /><br /><br />

                                                <Header icon='browser' content='Justifique o motivo da não entrega' />


                                                <Modal.Content>
                                                    <Form name="formModal">


                                                        <Form.Group widths='equal'>

                                                            <Dropdown
                                                                onChange={this.onChangePedidoMotivo.bind(this)}
                                                                options={motivos}
                                                                selection
                                                                value={pedido.ad_tracknentid}
                                                                pedido={pedido}
                                                                placeholder='Motivo'
                                                                name='combo'
                                                            />
                                                        </Form.Group>
                                                        <Form.TextArea label='ad_trackndescr' placeholder='Escreva a Descrição' value={(pedido.ad_trackndescr === null) ? "" : pedido.ad_trackndescr} onChange={(e) => this.onChangePedido(e, pedido)}
                                                            name="ad_trackndescr"
                                                            maxLength={255}
                                                        />
                                                        <Label as='a' basic color='red' pointing inverted>{(pedido.ad_tracknusuari != null) ? pedido.ad_tracknusuari : "Sem usuário"}</Label>
                                                    </Form>
                                                </Modal.Content>
                                                <Modal.Actions>
                                                    <Button color='red' onClick={this.onCancelMotivo.bind(this)} pedido={pedido} inverted>
                                                        <Icon name='cancel' /> Cancel
                                                    </Button>
                                                    <Button color='green'
                                                        onClick={this.onSaveMotivo.bind(this)}
                                                        pedido={pedido} inverted>
                                                        <Icon name='checkmark' /> Salvar
                                                    </Button>
                                                </Modal.Actions>
                                            </Modal>
                                        </Table.Cell>


                                        <Table.Cell>
                                            <div>

                                                <Button basic icon='hand point down outline' color={'red'} size="mini"
                                                    loading={pedido.isupdatingdevolucao}
                                                    onClick={(e) => this.onChangeDevolucao(e, pedido, carga)}
                                                />

                                            </div>

                                        </Table.Cell>

                                    </Table.Row>
                                ))}
                            </StyledTableBody>
                        </StyledTable>
                        {
                            (chkEmTransportadora.length > 0 ? (
                                <div>
                                    <Button.Group floated='right'>
                                        <Button positive compact
                                            onClick={this.onSavePedido.bind(this)}
                                            loading={this.state.isSaving}
                                        >Gravar</Button>
                                    </Button.Group>
                                    <br /><br />
                                    <Divider horizontal floated='left'>.</Divider>
                                </div>
                            ) : (
                                <Divider horizontal floated='left'>.</Divider>
                            ))
                        }
                    </div>
                ))}
            </Tab.Pane>
        )
    }
}

ListEmTransportadora.defaultProps = {
    cargas: []
    , loader: true
    , chkEmTransportadora: []
    , motivos: []
};

ListEmTransportadora.propTypes = {
    cargas: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , handleCHKInsertEmTransportadora: PropTypes.func.isRequired
    , handleCHKRemoveEmTransportadora: PropTypes.func.isRequired
    , handleCHKUpdateEmTransportadora: PropTypes.func.isRequired
    , chkEmTransportadora: PropTypes.array.isRequired
    , handleSavePedidosEmTransportadora: PropTypes.func.isRequired
    , handlePedidoModal: PropTypes.func.isRequired
    , handlePedido: PropTypes.func.isRequired
    , motivos: PropTypes.array.isRequired
    , handleDevolucao: PropTypes.func.isRequired

    , handleEmTransportadoraFreteOpenClose: PropTypes.func.isRequired
    , handleEmTransportadoraFreteCheck: PropTypes.func.isRequired
    , handleEmTransportadoraFreteSave: PropTypes.func.isRequired

    , ordemCargaEmTransportadoraUpdatePrazosAction: PropTypes.func.isRequired
    , ordemCargaEmTransportadoraSavePrazosAction: PropTypes.func.isRequired
    , login: PropTypes.string.isRequired
};

export default ListEmTransportadora
