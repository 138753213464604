import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
    , Modal, Button, Header, Table, Message, Form, TextArea
    , Container, Popup
} from 'semantic-ui-react'


import { loginRedirectAction } from '../../actions/LoginActions';
import {
    pesquisaListAction
    , pesquisaHandleChangeAction
    , pesquisaCheckAction
    , pesquisaSaveAction
} from '../../actions/PesquisaAction';

import ModalPendentes from './ModalPendentes'


import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;











class Pesquisa extends Component {


    state = {}


    handleChangeForm = (e, { value }) => {
        
        this.props.pesquisaHandleChangeAction(e)
    }

    handleSavePesquisa = () => {
        this.props.pesquisaSaveAction(this.props.TarefaEntity).then(data => {
            this.props.pesquisaListAction(this.props.userid).then(data => { })
         })
        
        this.handleClose()

    }

    handleClose =()=>{
        //fechando janela
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'modalOpen', 'value': false } })
    }



    onChangeModalOpen(e, tarefa) {

        //console.log('tarefa:')
        //console.log(tarefa)
        //this.props.handlePedidoModal(pedido, true, false);

        this.props.pesquisaHandleChangeAction({ target: { 'name': 'tarefaid', 'value': tarefa.tarefaid } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'codparc', 'value': tarefa.codparc } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'cliente', 'value': tarefa.cliente } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'datapesquisa', 'value': tarefa.datapesquisa } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'status', 'value': tarefa.status } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'statusdescricao', 'value': tarefa.statusdescricao } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'dtstatus', 'value': tarefa.dtstatus } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'questao', 'value': tarefa.questao } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'avaliacao', 'value': tarefa.avaliacao } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'resposta', 'value': tarefa.resposta } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'mensagem', 'value': (tarefa.mensagem != null ? tarefa.mensagem : "") } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'avaliacaogestor', 'value': (tarefa.avaliacaogestor != null ? tarefa.avaliacaogestor : "") } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'vendedor', 'value': tarefa.vendedor } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'consultor', 'value': tarefa.consultor } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'userid', 'value': this.props.userid } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'login', 'value': this.props.login } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'formOcorrido', 'value': "" } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'formSolucao', 'value': "" } })
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'formObservacao', 'value': "" } })

        //abrindo janela
        this.props.pesquisaHandleChangeAction({ target: { 'name': 'modalOpen', 'value': true } })

        //checando que usuário visualizou
        this.props.pesquisaCheckAction(tarefa.tarefaid,this.props.login);
    }


    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }

    componentDidMount() {



        if (!this.props.isAuthenticated) {
            sessionStorage.setItem("abriutarefa", 0);
            this.props.loginRedirectAction(`/Pesquisa/${this.props.tarefaid}/${this.props.pesquisaid}`)
        }


        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 4 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------


        let { userid } = this.props;

        if(this.props.isAuthenticated){
            this.props.pesquisaListAction(userid).then(data => {

                //abre tarefa
                if(this.props.tarefaid!=0 && this.props.pesquisaid!=0 && sessionStorage.getItem("abriutarefa")==0){
                    sessionStorage.setItem("abriutarefa", 1);
              
                    let tarefaParaAbrir= this.props.pesquisas.filter(t=>t.tarefaid==this.props.tarefaid)[0]
                    this.onChangeModalOpen({},tarefaParaAbrir);
                }

             })
            
            
        }
    


    }

    componentWillReceiveProps() {

        //abre tarefa
        if(this.props.tarefaid!=0 && this.props.pesquisaid!=0){
            sessionStorage.setItem("abriutarefa", 0);

        }
    }



   

    render() {

        let { tarefaid, pesquisaid, TarefaEntity
            , pesquisas
        } = this.props

        let pendentes = pesquisas.filter(f => f.status === 0 || f.status === 1 || f.status === 2)
        let concluidas = pesquisas.filter(f => f.status === 9)
        let demais = pesquisas.filter(f => f.status === 99)





        let panes = [



            {
                menuItem: <Menu.Item key='TABpendente'><Icon name='warning sign' />Pendentes<Label color='red'>{pendentes.length}</Label></Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderPesquisa}>
                        <div key='1'>{//this.props.tarefaid} Teste1 {this.props.pesquisaid
                        }
                        </div>

                        <StyledTable celled id="listPendentes" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Avaliação</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Data Pesquisa</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Questão</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Status</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Data do Status</Table.HeaderCell>
                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {pendentes.map(tarefa => (
                                    <Table.Row key={"Pen" + tarefa.tarefaid}>

                                        <Table.Cell>
                                            <ModalPendentes
                                                botao={<Button basic icon={'edit'} color={'blue'} size="mini" onClick={(e) => this.onChangeModalOpen(e, tarefa)} />}
                                                entidade={this.props.TarefaEntity}
                                                handleChangeForm={this.handleChangeForm.bind(this)}
                                                handleSavePesquisa={this.handleSavePesquisa.bind(this)}
                                                handleClose={this.handleClose.bind(this)}
                                            />


                                            {tarefa.codparc} - {tarefa.cliente}</Table.Cell>
                                        <Table.Cell>{tarefa.avaliacao}</Table.Cell>
                                        <Table.Cell>{moment(tarefa.datapesquisa).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                                        <Table.Cell>{tarefa.pergunta}</Table.Cell>

                                        <Table.Cell>{tarefa.statusdescricao}</Table.Cell>
                                        <Table.Cell>{moment(tarefa.dtstatus).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                                    </Table.Row>
                                ))}

                            </StyledTableBody>
                        </StyledTable>
                    </Tab.Pane>

                ,
            },
            {
                menuItem: <Menu.Item key='TABconcluidas'><Icon name='check' />Concluídas<Label color='blue'>{concluidas.length}</Label></Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderPesquisa}>
                        <div key='2'>{//this.props.tarefaid} Teste1 {this.props.pesquisaid
                        }
                        </div>

                        <StyledTable celled id="listConcluidas" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Avaliação</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Data Pesquisa</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Questão</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Status</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Data do Status</Table.HeaderCell>
                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {concluidas.map(tarefa => (
                                    <Table.Row key={"Pen" + tarefa.tarefaid}>
                                        <Table.Cell>
                                        <ModalPendentes
                                                botao={<Button basic icon={'edit'} color={'blue'} size="mini" onClick={(e) => this.onChangeModalOpen(e, tarefa)} />}
                                                entidade={this.props.TarefaEntity}
                                                handleChangeForm={this.handleChangeForm.bind(this)}
                                                handleSavePesquisa={this.handleSavePesquisa.bind(this)}
                                                handleClose={this.handleClose.bind(this)}
                                                //tarefaexterna={this.props.tarefaid}
                                            />
                                        {tarefa.codparc} - {tarefa.cliente}</Table.Cell>
                                        <Table.Cell>{tarefa.avaliacao}</Table.Cell>
                                        <Table.Cell>{moment(tarefa.datapesquisa).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                                        <Table.Cell>{tarefa.pergunta}</Table.Cell>

                                        <Table.Cell>{tarefa.statusdescricao}</Table.Cell>
                                        <Table.Cell>{moment(tarefa.dtstatus).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                                    </Table.Row>
                                ))}

                            </StyledTableBody>
                        </StyledTable>
                    </Tab.Pane>

                ,
            },
            {
                menuItem: <Menu.Item key='TABneutraspositivas'><Icon name='smile' />Demais Avaliações <Label color='green'>{demais.length}</Label></Menu.Item>,
                render: () => <Tab.Pane loading={this.props.loaderPesquisa}>
                <div key='2'>{//this.props.tarefaid} Teste1 {this.props.pesquisaid
                }
                </div>

                <StyledTable celled id="listDemais" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                            <Table.HeaderCell width="1">Avaliação</Table.HeaderCell>
                            <Table.HeaderCell width="1">Data Pesquisa</Table.HeaderCell>
                            <Table.HeaderCell width="1">Questão</Table.HeaderCell>
                            <Table.HeaderCell width="1">Observação</Table.HeaderCell>
                            
                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {demais.map(tarefa => (
                            <Table.Row key={"Dem" + tarefa.tarefaid}>
                                <Table.Cell>{tarefa.codparc} - {tarefa.cliente}</Table.Cell>
                                <Table.Cell>{tarefa.avaliacao}</Table.Cell>
                                <Table.Cell>{moment(tarefa.datapesquisa).format('DD/MM/YYYY HH:mm')}</Table.Cell>
                                <Table.Cell>{tarefa.pergunta}</Table.Cell>
                                <Table.Cell>
                                    <Popup
                                    trigger={<Icon circular name='comment alternate outline' />}
                                    content={tarefa.mensagem}
                                    inverted/>
                                
                                </Table.Cell>
                                
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>
            </Tab.Pane>,
            },



        ]

        return (
            <div>
                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Pesquisa de Satisfação {sessionStorage.getItem("abriutarefa")}</Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>



                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}


Pesquisa.defaultProps = {
    tarefaid: ''
    , pesquisaid: ''
};

Pesquisa.propTypes = {
    tarefaid: PropTypes.string.isRequired
    , pesquisaid: PropTypes.string.isRequired
};



const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated
        , TarefaEntity: state.PesquisaReducer.TarefaEntity
        , pesquisas: state.PesquisaReducer.pesquisas
        , loaderPesquisa: state.PesquisaReducer.loaderPesquisa
    }
);


export default withRouter(connect(mapStateToProps, {
    loginRedirectAction
    , pesquisaListAction
    , pesquisaHandleChangeAction
    , pesquisaCheckAction
    , pesquisaSaveAction
}
)(Pesquisa));

