export const  ProcessamentosParceiroEntity = {
    codparc: 0
    ,nomeparc:''
    ,ipiincicms:''
    ,ad_ultpedfbits:''
    ,codtab:0
    ,classificacaofin:''
    , ad_fixartabb2b:0
    , ad_emailb2b:''
    , parceirosemail:'' 
}