import React, { useState } from 'react';
import { Modal, ModalContent, Button, Input, Form, Grid, TextArea } from 'semantic-ui-react';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
import DropzoneComponent from './Dropzone.js'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { marketplacesDevoluçõesCriaDevoluçãoAction, marketplacesDevoluçõesListAction, marketplacesDevoluçõesModalInfoAction } from './../../../actions/MarketPlacesActions';

function ModalCriarDevolucao({ open, onClose, marketplacesDevoluçõesCriaDevoluçãoAction, userid, marketplacesDevoluçõesListAction, isdevolucaocrialoading, marketplacesDevoluçõesModalInfoAction, isDevolucaoModalInfoLoading, }) {



    const [numeroUnico, setNumeroUnico] = useState('');
    const [valor, setValor] = useState('');
    const [endDate, setEndDate] = useState(null);
    const [parceiro, setParceiro] = useState('');
    const [depositante, setDepositante] = useState('');
    const [motivo, setMotivo] = useState('');
    const [problema, setProblema] = useState('');
    const [inadimplencia, setInadimplencia] = useState(false);
    const [msgInadimplencia, setMsgInadimplencia] = useState('');
    const [uploadedFilesDevolucao, setUploadedFilesDevolucao] = useState([]);
    const [errorSaving, setErrorSaving] = useState([]);
    const [errorMsg, setErrorMsg] = useState(false);

    const formatarDataBrasileira = (data) => {
        if (!data) return null; // Caso a data não seja válida
        const dia = String(data.getDate()).padStart(2, '0'); // Obtém o dia com dois dígitos
        const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês (0-11) ajustado para 1-12
        const ano = data.getFullYear(); // Obtém o ano completo
        return `${dia}/${mes}/${ano}`;
    };



    const handleSalvar = () => {
        let listError = [];
        // Remove separadores de milhares (pontos) e converte a vírgula decimal para ponto
        const valorSemMascara = valor?.replace(/[^\d,.-]/g, '').replace('.', '').replace(',', '.');

        // Converte para número
        const valorNumerico = parseFloat(valorSemMascara);
        console.log(valorNumerico)
        console.log(Number.isNaN(valorNumerico))
        if (numeroUnico == '' || numeroUnico == undefined || numeroUnico == null) {
            listError.push({ nufin: numeroUnico, campo: 'nufin' })
        }
        if (valorNumerico === '' || valorNumerico === undefined || valorNumerico === null || Number.isNaN(valorNumerico)) {
            listError.push({ valor: valorNumerico, campo: 'valor' });
        }
        if (endDate == '' || endDate == undefined || endDate == null) {
            listError.push({ vencimento: endDate, campo: 'vencimento' })
        }
        if (parceiro == '' || parceiro == undefined || parceiro == null) {
            listError.push({ parceiro: parceiro, campo: 'parceiro' })
        }
        if (depositante == '' || depositante == undefined || depositante == null) {
            listError.push({ depositante: depositante, campo: 'depositante' })
        }
        if (motivo == '' || motivo == undefined || motivo == null) {
            listError.push({ motivo: motivo, campo: 'motivo' })
        }
        if (problema == '' || problema == undefined || problema == null) {
            listError.push({ relato: problema, campo: 'relato' })
        }

        setErrorSaving(listError);
        console.log(listError)
        if (listError.length == 0) {
            const dados = {
                nufin: Number(numeroUnico),
                valor: valorNumerico,
                vencimento: endDate ? formatarDataBrasileira(endDate) : null,
                parceiro: parceiro,
                depositante: depositante,
                motivo: motivo,
                relato: problema,
                usuario: Number(userid)
            };

            marketplacesDevoluçõesCriaDevoluçãoAction(dados, uploadedFilesDevolucao).then((dados) => {
                console.log(dados);
                if (dados[0].status == "OK" || dados == "OK") {
                    setNumeroUnico('');
                    setValor('');
                    setParceiro('');
                    setDepositante('');
                    setMotivo('');
                    setProblema('');
                    setEndDate(null);
                    setMsgInadimplencia('');
                    setErrorMsg(false);
                    setInadimplencia(false);

                    let pagination = {
                        page: 1,
                        limit: 10
                    };

                    marketplacesDevoluçõesListAction(pagination).then((data) => {
                    });
                    onClose();
                } else {
                    setErrorMsg(true);
                }
            })
        }
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <Input
            style={{ width: '100%' }}
            onClick={onClick}
            value={value}
            ref={ref}
            placeholder="Selecione uma data"
        />
    ));


    const handleFilesChangeDevolucao = (files) => {
        setUploadedFilesDevolucao(files);
    };



    return (
        <Modal open={open} onClose={onClose} closeIcon>
            <ModalContent>
                <h2>Criar Devolução</h2>
                <div style={{ display: 'flex', flexWrap: 'wrap', gap: '16px' }}>
                    <div style={{ flex: '1 1 calc(33.33% - 16px)', minWidth: '200px' }}>
                        <label style={{ display: 'block', marginBottom: '8px' }}>Número Único</label>
                        <Input
                            style={{ width: '100%' }}
                            value={numeroUnico}
                            onChange={(e) => setNumeroUnico(e.target.value)}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                            onBlur={() => {
                                marketplacesDevoluçõesModalInfoAction(numeroUnico).then((item) => {
                                    if (item == '') {
                                        setParceiro('');
                                        setValor('');
                                        setEndDate('')
                                        setMotivo('');
                                    }

                                    console.log(item[0]?.vencimento)
                                    setValor(item[0]?.valor);
                                    setParceiro(item[0]?.nomeparc);
                                    setEndDate(item[0]?.vencimento ? new Date(item[0]?.vencimento) : null)
                                    setMotivo(item[0]?.motivo)

                                    if (item[0]?.inadimplente > 0) {
                                        setMsgInadimplencia(`Cliente com inadimplência de R$${item[0]?.inadimplente}`);
                                        setInadimplencia(true);
                                    } else {
                                        setMsgInadimplencia('Cliente sem inadimplência')
                                        setInadimplencia(false);
                                    }

                                })
                            }}
                            name='nufin'
                            loading={isDevolucaoModalInfoLoading}
                            error={errorSaving.some(a => a.campo === 'nufin' && (!a.nufin || a.nufin === null))}

                        />
                        <span
                            style={{
                                color: inadimplencia ? 'red' : 'lightblue', // Vermelho para inadimplente, azul claro para sem inadimplência
                                fontWeight: 'bold',
                            }}
                        >
                            {msgInadimplencia}
                        </span>
                    </div>

                    <div style={{ flex: '1 1 calc(33.33% - 16px)', minWidth: '200px' }}>
                        <label style={{ display: 'block' }}>Valor</label>

                        <NumericFormat
                            style={{ width: '100%', padding: '8px', boxSizing: 'border-box' }}
                            prefix="R$ "
                            decimalSeparator=","
                            thousandSeparator="."
                            decimalScale={2}
                            fixedDecimalScale
                            allowNegative={false}
                            placeholder="R$ 0,00"
                            customInput={Input}
                            value={valor}
                            onValueChange={(values) => setValor(values.formattedValue)}
                            error={errorSaving.some(a => a.campo === 'valor' && (!a.valor || a.valor === null))}
                            name='valor'
                        />
                    </div>
                    <div style={{ flex: '1 1 calc(33.33% - 16px)', minWidth: '200px' }}>
                        <label style={{ display: 'block', marginBottom: '8px' }}>Vencimento</label>
                        <DatePicker
                            selected={endDate}
                            onChange={(date) => setEndDate(date)}
                            isClearable
                            todayButton="Hoje"
                            name="vencimento"
                            id="dtfinal"
                            dateFormat="dd/MM/yyyy"
                            customInput={<CustomInput />}
                        />
                    </div>
                    {/* Parceiro e Depositante */}
                    <div style={{ display: 'flex', flex: '1 1 100%', gap: '16px' }}>
                        <div style={{ flex: '1 1 50%' }}>
                            <label style={{ display: 'block', marginBottom: '8px' }}>Parceiro</label>
                            <Input
                                style={{ width: '100%' }}
                                value={parceiro}
                                onChange={(e) => setParceiro(e.target.value)}
                                name='parceiro'
                                error={errorSaving.some(a => a.campo === 'parceiro' && (!a.parceiro || a.parceiro === null))}

                            />
                        </div>
                        <div style={{ flex: '1 1 50%' }}>
                            <label style={{ display: 'block', marginBottom: '8px' }}>Depositante</label>
                            <Input
                                style={{ width: '100%' }}
                                value={depositante}
                                name='depositante'
                                error={errorSaving.some(a => a.campo === 'depositante' && (!a.depositante || a.depositante === null))}
                                onChange={(e) => setDepositante(e.target.value)}
                            />
                        </div>
                    </div>

                    {/* Problema na devolução */}
                    <div style={{ flex: '1 1 60%', marginBottom: '10px' }}>
                        <Grid>
                            <Grid.Column width={16}>
                                <Form.Group grouped>
                                    <label>Problema na devolução</label>
                                    <Form.Radio
                                        label="Problema Esplane"
                                        name="relato"
                                        value="Problema Esplane"
                                        checked={problema === 'Problema Esplane'}
                                        onChange={() => setProblema('Problema Esplane')}
                                    />
                                    <Form.Radio
                                        label="Problema Cliente"
                                        name="relato"
                                        value="Problema Cliente"
                                        checked={problema === 'Problema Cliente'}
                                        onChange={() => setProblema('Problema Cliente')}
                                    />
                                    <Form.Field error={true}>
                                        <Form.Radio
                                            label="Problema Terceiros"
                                            name="relato"
                                            value="Problema Terceiros"
                                            checked={problema === 'Problema Terceiros'}
                                            onChange={() => setProblema('Problema Terceiros')}
                                            error={true}
                                        />
                                    </Form.Field>
                                    {errorSaving.some(a => a.campo === 'relato' && (!a.depositante || a.depositante === null))
                                        ? <span style={{ color: 'red' }}>Selecione uma Ocorrência</span> : ''}

                                </Form.Group>
                            </Grid.Column>
                        </Grid>
                    </div>
                </div>

                <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end', justifyContent: 'flex-end', marginBottom: '10px' }}>
                    <DropzoneComponent
                        accept={{
                            'application/pdf': ['.pdf'],
                        }}
                        onFilesChange={handleFilesChangeDevolucao}
                    />
                </div>
                <label>Motivo</label>
                <TextArea
                    placeholder="..."
                    name="motivo"
                    maxLength={3500}
                    style={{
                        width: '100%', // Faz o TextArea ocupar 100% da largura disponível
                        resize: 'none', // Desabilita o redimensionamento
                        padding: '10px',
                    }}
                    value={motivo}
                    onChange={(e) => setMotivo(e.target.value)}
                />
                {errorSaving.some(a => a.campo === 'motivo' && (!a.motivo || a.motivo === null))
                    ? <span style={{ color: 'red' }}>Escreva um motivo</span> : ''}
                <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end', alignItems: 'center' }}>
                    {motivo?.length}/3500
                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    <Button negative onClick={onClose}>
                        Fechar
                    </Button>


                    {/* {inadimplencia ? '' :
                        <Button positive onClick={handleSalvar} loading={isdevolucaocrialoading}>
                            Salvar
                        </Button>
                    } */}

                    <Button positive onClick={handleSalvar} loading={isdevolucaocrialoading}>
                        Salvar
                    </Button>

                </div>
                <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '16px' }}>
                    {errorMsg ? <span style={{ color: 'red' }}>Erro ao criar Devolução!</span> : ''}
                </div>

            </ModalContent>
        </Modal>
    );
}


const mapStateToProps = (state) => ({
    userid: state.LoginReducer.userid,
    isdevolucaocrialoading: state.MarketPlacesReducer.isdevolucaocrialoading,
    isDevolucaoModalInfoLoading: state.MarketPlacesReducer.isDevolucaoModalInfoLoading
})
export default withRouter(connect(mapStateToProps, {
    marketplacesDevoluçõesCriaDevoluçãoAction,
    marketplacesDevoluçõesListAction,
    marketplacesDevoluçõesModalInfoAction

}
)(ModalCriarDevolucao));
