import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
    Table, Tab, Button, Icon, Checkbox
} from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { B2BRelatorioListAction, B2BRelatorioCheckedChamou, B2BRelatorioCheckedConverteu } from '../../actions/B2BActions';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;
const GreenCheckbox = styled(Checkbox)`
 &&&.ui.toggle.checkbox.checked label:before {
    background-color: green !important;
  }
  &&&.ui.toggle.checkbox.checked .box:before, 
  &&&.ui.toggle.checkbox.checked .box:after {
    background-color: green !important;
  }
`;

function Relatorio(props) {
    const [checkedItems, setCheckedItems] = useState({});
    const [checkedItems2, setCheckedItems2] = useState({});
    const [isLoadingList, setLoadingList] = useState(true);

    const { B2BRelatorioListAction, B2BRelatorioCheckedChamou, B2BRelatorioCheckedConverteu, listrelatorio, userid, menu, history } = props;

    useEffect(() => {
        B2BRelatorioListAction().then((d) => {
            setLoadingList(false);
        });
        setInterval(() => {
            setLoadingList(true);
            B2BRelatorioListAction().then((d) => {
                setLoadingList(false);
            });
        }, 60000);
        if (menu.filter(m => m.menuid === 29 && m.status === 1).length === 0) { history.push(`../Home`) }

    }, [menu]);

    const handleCheckboxClick = (id, userid, acao) => {
        setLoadingList(true);
        setCheckedItems({ ...checkedItems, [id]: acao });
        const entidade = {
            relatorioid: id,
            userid: userid
        };
        B2BRelatorioCheckedChamou(entidade).then((d) => {
            setLoadingList(false);
        });




    };

    const handleCheckboxConverteu = (id, acao) => {
        setLoadingList(true);
        setCheckedItems2({ ...checkedItems2, [id]: acao });
        const entidade = {
            relatorioid: id,
            converteu: acao == true ? 1 : 2
        };
        B2BRelatorioCheckedConverteu(entidade).then((d) => {
            setLoadingList(false);
        });

    };




    return (
        <Tab.Pane loading={isLoadingList}>
            <StyledTable celled id="listPendentes" compact='very' size='small'>
                <StyledTableHeader>
                    <Table.Row>
                        <Table.HeaderCell width="1">RelatorioID</Table.HeaderCell>
                        <Table.HeaderCell width="1">WID</Table.HeaderCell>
                        <Table.HeaderCell width="1">Telefone</Table.HeaderCell>
                        <Table.HeaderCell width="3">Data de Criação</Table.HeaderCell>
                        <Table.HeaderCell width="1">Campanha</Table.HeaderCell>
                        <Table.HeaderCell width="3">Ação</Table.HeaderCell>
                        <Table.HeaderCell width="1">Usuario</Table.HeaderCell>
                        <Table.HeaderCell width="1">UserID</Table.HeaderCell>
                        <Table.HeaderCell width="3">Data Checked</Table.HeaderCell>
                        <Table.HeaderCell>Chamou</Table.HeaderCell>
                        <Table.HeaderCell>Converteu</Table.HeaderCell>
                    </Table.Row>
                </StyledTableHeader>
                {listrelatorio.map(item =>
                    <Table.Row key={"usuariosmgr" + item.relatorioid}>
                        <Table.Cell>{item.relatorioid}</Table.Cell>
                        <Table.Cell>{item.wid}</Table.Cell>
                        <Table.Cell>{item.recipient_id}</Table.Cell>
                        <Table.Cell>
                            {new Date(item.dt_criacao).toLocaleDateString('pt-BR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            })}
                        </Table.Cell>
                        <Table.Cell>{item.campanha}</Table.Cell>
                        <Table.Cell>{item.acao}</Table.Cell>
                        <Table.Cell>{item.usuario}</Table.Cell>
                        <Table.Cell>{item.userid}</Table.Cell>
                        <Table.Cell>
                            {item.dt_checked ? new Date(item.dt_checked).toLocaleDateString('pt-BR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit',
                                second: '2-digit'
                            }) : ''}
                        </Table.Cell>
                        <Table.Cell>
                            <Checkbox
                                toggle
                                checked={item.dt_checked ? true : !!checkedItems[item.relatorioid]}
                                onChange={(e, data) => handleCheckboxClick(item.relatorioid, userid, data.checked)}
                                disabled={item.dt_checked ? true : !!checkedItems[item.relatorioid]}
                                basic
                                size='mini'
                            >
                            </Checkbox>
                        </Table.Cell>
                        <Table.Cell>
                            <GreenCheckbox
                                toggle
                                basic
                                checked={item.dt_converted ? true : !!checkedItems2[item.relatorioid]}
                                onChange={(e, data) => handleCheckboxConverteu(item.relatorioid, data.checked)}
                                //color='green'
                                size='mini'
                            />

                        </Table.Cell>
                    </Table.Row>
                )}
                <StyledTableBody>
                </StyledTableBody>
            </StyledTable>
        </Tab.Pane>
    )
}

const mapStateToProps = (state) => ({
    listrelatorio: state.B2BReducer.listrelatorio,
    userid: state.LoginReducer.userid,
    menu: state.LoginReducer.menu
})

export default withRouter(connect(mapStateToProps, {
    B2BRelatorioListAction,
    B2BRelatorioCheckedChamou,
    B2BRelatorioCheckedConverteu,
})(Relatorio));
