import React, { Component } from 'react';
import { Link } from 'react-router-dom'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { Grid, Segment, Icon, Header, Progress, Statistic, Breadcrumb, Menu, Tab, Label } from 'semantic-ui-react'

import ListConfiguracoes from './ListConfiguracoes'
import ListModificados from './ListModificados'
import ListMantidos from './ListMantidos'
import ListMonitorados from './ListMonitorados'
import ListForaDosLimites from './ListForaDosLimites'
import ListGeral from './ListGeral'

import {
    roboDePrecosConfiguracoesAction
    , roboDePrecosModificadosAction
    , roboDePrecosMantidosAction
    , roboDePrecosMonitoradosAction
    , roboHandleConfigChangeAction
    , roboClearConfiguracoesAction
    , roboSaveConfiguracoesAction
    , roboDePrecosStatusListAction
    , roboDePrecosStatusUpdateAction
    , roboDePrecosStatusStartTimerAction
    , roboDePrecosForaDosLimitesAction
    , roboDePrecosGeralListAction
} from '../../actions/RoboDePrecosActions';

class RoboDePrecos extends Component {

    constructor(props) {
        super(props);
    }

    componentDidMount() {
        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 1 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        this.props.roboDePrecosConfiguracoesAction().then(data => { })
        this.props.roboDePrecosModificadosAction().then(data => { })
        this.props.roboDePrecosMantidosAction().then(data => { })
        this.props.roboDePrecosMonitoradosAction().then(data => { })
        this.props.roboDePrecosForaDosLimitesAction().then(data =>{})

        this.props.roboDePrecosStatusListAction().then(data => { })
        this.props.roboDePrecosStatusStartTimerAction()
        this.props.roboDePrecosGeralListAction().then(data=>{})


    }

    render() {

        let { menu } = this.props;

        let { configuracoes, loaderConfiguracoes, configuracoesQtde, roboPrecosConfigEntity, robostatus, robodt } = this.props;
        let { modificados, loaderModificados, modificadosQtde } = this.props;
        let { mantidos, loaderMantidos, mantidosQtde } = this.props;
        let { monitorados, loaderMonitorados, monitoradosQtde } = this.props;
        let { foradoslimitesQtde, loaderForadoslimites, foradoslimites } = this.props;
        let { geral, concorrentes, produtos, loadergeral
        ,vermelho,amarelo,azul} = this.props;

        let panes = [
            
            {
                menuItem: <Menu.Item key='TAB1'><Icon name='lightning' />Geral<Label color='yellow'>{produtos.length}</Label></Menu.Item>,
                render: () => <ListGeral 
                
                geral={geral}
                vermelho={vermelho}
                amarelo={amarelo}
                azul={azul}

                concorrentes={concorrentes}
                produtos={produtos}

                loader={(robostatus==1?true:loadergeral)} />,
            },


            {
                menuItem: <Menu.Item key='TAB1'><Icon name='lightning' />Modificados<Label color='red'>{modificadosQtde}</Label></Menu.Item>,
                render: () => <ListModificados dataList={modificados} loader={loaderModificados} />,
            },

            {
                menuItem: <Menu.Item key='TAB2'><Icon name='checkmark' />Mantidos<Label color='blue'>{mantidosQtde}</Label></Menu.Item>,
                render: () => <ListMantidos dataList={mantidos} loader={loaderMantidos} />,
            },
            {
                menuItem: <Menu.Item key='TAB3'><Icon name='eye' />Monitorados<Label color='orange'>{monitoradosQtde}</Label></Menu.Item>,
                render: () => <ListMonitorados dataList={monitorados} loader={loaderMonitorados} />,
            },
            {
                menuItem: <Menu.Item key='TAB1'><Icon name='lightning' />Fora dos Limites<Label color='yellow'>{foradoslimitesQtde}</Label></Menu.Item>,
                render: () => <ListForaDosLimites dataList={foradoslimites} loader={loaderForadoslimites} />,
            },

            {
                menuItem: <Menu.Item key='TAB4'><Icon name='cogs' />Configurações<Label color='green'>{configuracoesQtde}</Label></Menu.Item>,
                render: () => <ListConfiguracoes
                    dataList={configuracoes}
                    loader={loaderConfiguracoes}
                    entidade={roboPrecosConfigEntity}
                    handleChangeConfig={this.props.roboHandleConfigChangeAction}
                    handleClearConfig={this.props.roboClearConfiguracoesAction}
                    handleSaveConfig={this.props.roboSaveConfiguracoesAction}
                    robostatus={robostatus}
                    robodt={robodt}
                    handleUpdateStatus={this.props.roboDePrecosStatusUpdateAction}
                />,

            }
            


        ]

        return (
            <div>
                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Robô de Preços</Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>


                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>

            </div>
        );
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu

        , configuracoes: state.RoboDePrecosReducer.configuracoes
        , loaderConfiguracoes: state.RoboDePrecosReducer.loaderConfiguracoes
        , configuracoesQtde: state.RoboDePrecosReducer.configuracoesQtde

        , modificados: state.RoboDePrecosReducer.modificados
        , loaderModificados: state.RoboDePrecosReducer.loaderModificados
        , modificadosQtde: state.RoboDePrecosReducer.modificadosQtde

        , mantidos: state.RoboDePrecosReducer.mantidos
        , loaderMantidos: state.RoboDePrecosReducer.loaderMantidos
        , mantidosQtde: state.RoboDePrecosReducer.mantidosQtde

        , monitorados: state.RoboDePrecosReducer.monitorados
        , loaderMonitorados: state.RoboDePrecosReducer.loaderMonitorados
        , monitoradosQtde: state.RoboDePrecosReducer.monitoradosQtde

        , roboPrecosConfigEntity: state.RoboDePrecosReducer.RoboPrecosConfigEntity

        , robostatus: state.RoboDePrecosReducer.robostatus
        , robodt: state.RoboDePrecosReducer.robodt

        , foradoslimitesQtde: state.RoboDePrecosReducer.foradoslimitesQtde
        , loaderForadoslimites: state.RoboDePrecosReducer.loaderForadoslimites
        , foradoslimites: state.RoboDePrecosReducer.foradoslimites

        , geral: state.RoboDePrecosReducer.geral
        , vermelho: state.RoboDePrecosReducer.vermelho
        , amarelo: state.RoboDePrecosReducer.amarelo
        , azul: state.RoboDePrecosReducer.azul
        , concorrentes: state.RoboDePrecosReducer.concorrentes
        , produtos: state.RoboDePrecosReducer.produtos
        , loadergeral: state.RoboDePrecosReducer.loadergeral
       
    }
);

export default withRouter(connect(mapStateToProps, {
    roboDePrecosConfiguracoesAction
    , roboDePrecosModificadosAction
    , roboDePrecosMantidosAction
    , roboDePrecosMonitoradosAction
    , roboHandleConfigChangeAction
    , roboClearConfiguracoesAction
    , roboSaveConfiguracoesAction
    , roboDePrecosStatusListAction
    , roboDePrecosStatusUpdateAction
    , roboDePrecosStatusStartTimerAction
    , roboDePrecosForaDosLimitesAction
    , roboDePrecosGeralListAction
}
)(RoboDePrecos));

