import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import {
    Table
    , Header

} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




class Relatorio extends Component {


    state = {}

    constructor(props) {
        super(props);

    }


    componentWillReceiveProps() {


    }

    componentDidMount() {

    }




    render() {

        let { datalist } = this.props

        let mesatual = datalist.filter(d => d.mes == 'atual')

        let mespassado = datalist.filter(d => d.mes == 'passado')


        return (
            <div>

                <If test={mesatual.length > 0}>

                    <Header as='h3' dividing>
                        Mês atual
                </Header>
                    <StyledTable celled id="listPedidos" compact='very' size='small'>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                                <Table.HeaderCell>Vendas</Table.HeaderCell>

                                <Table.HeaderCell >Meta 100%</Table.HeaderCell>
                                <Table.HeaderCell >% 100</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 100%</Table.HeaderCell>

                                <Table.HeaderCell >Meta 110%</Table.HeaderCell>
                                <Table.HeaderCell >% 110</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 110%</Table.HeaderCell>

                                <Table.HeaderCell >Meta 120%</Table.HeaderCell>
                                <Table.HeaderCell >% 120</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 120%</Table.HeaderCell>

                                <Table.HeaderCell >Meta 130%</Table.HeaderCell>
                                <Table.HeaderCell >% 130</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 130%</Table.HeaderCell>

                                <Table.HeaderCell>Pedidos</Table.HeaderCell>
                             

                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {mesatual.map(item => (
                                <Table.Row key={"rel_" + new Date().getMilliseconds}>
                                    <Table.Cell>{item.vendedor}</Table.Cell>
                                    <Table.Cell>{item.vendas.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta100.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m100.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta100.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta110.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m110.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta110.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta120.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m120.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta120.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta130.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m130.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta130.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.pedidos}</Table.Cell>
                              

                                </Table.Row>
                            ))}


                        </StyledTableBody>
                    </StyledTable>

                </If>




                <If test={mespassado.length > 0}>

                    <Header as='h3' dividing>
                        Mês Passado
                </Header>
                    <StyledTable celled id="listPedidos" compact='very' size='small'>
                        <StyledTableHeader>
                            <Table.Row>
                                <Table.HeaderCell width="2">Vendedor</Table.HeaderCell>
                                <Table.HeaderCell>Vendas</Table.HeaderCell>

                                <Table.HeaderCell >Meta 100%</Table.HeaderCell>
                                <Table.HeaderCell >% 100</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 100%</Table.HeaderCell>

                                <Table.HeaderCell >Meta 110%</Table.HeaderCell>
                                <Table.HeaderCell >% 110</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 110%</Table.HeaderCell>

                                <Table.HeaderCell >Meta 120%</Table.HeaderCell>
                                <Table.HeaderCell >% 120</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 120%</Table.HeaderCell>

                                <Table.HeaderCell >Meta 130%</Table.HeaderCell>
                                <Table.HeaderCell >% 130</Table.HeaderCell>
                                <Table.HeaderCell>Falta Meta 130%</Table.HeaderCell>

                                <Table.HeaderCell>Pedidos</Table.HeaderCell>
                             

                            </Table.Row>
                        </StyledTableHeader>

                        <StyledTableBody>
                            {mespassado.map(item => (
                                <Table.Row key={"rel_" + new Date().getMilliseconds}>
                                    <Table.Cell>{item.vendedor}</Table.Cell>
                                    <Table.Cell>{item.vendas.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta100.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m100.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta100.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta110.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m110.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta110.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta120.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m120.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta120.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.meta130.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.m130.toFixed(2)}</Table.Cell>
                                    <Table.Cell>{item.faltameta130.toFixed(2)}</Table.Cell>

                                    <Table.Cell>{item.pedidos}</Table.Cell>
                                 

                                </Table.Row>
                            ))}


                        </StyledTableBody>
                    </StyledTable>

                </If>
            </div>

        )
    }

}

Relatorio.defaultProps = {
    datalist: []

};

Relatorio.propTypes = {
    datalist: PropTypes.array.isRequired


};

export default Relatorio

