import React, {Component} from 'react'

import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Grid, Header, Icon, Label
    , Modal, Button, Table, Message, Image, Segment
    ,
} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';

import ReactToPrint from "react-to-print";



String.prototype.extenso = function(c){
    var ex = [
        ["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
        ["dez", "vinte", "trinta", "quarenta", "cinqüenta", "sessenta", "setenta", "oitenta", "noventa"],
        ["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
        ["mil", "milhão", "bilhão", "trilhão", "quadrilhão", "quintilhão", "sextilhão", "setilhão", "octilhão", "nonilhão", "decilhão", "undecilhão", "dodecilhão", "tredecilhão", "quatrodecilhão", "quindecilhão", "sedecilhão", "septendecilhão", "octencilhão", "nonencilhão"]
    ];
    var a, n, v, i, n = this.replace(c ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
    for(var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []){
        j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
        if(!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
        for(a = -1, l = v.length; ++a < l; t = ""){
            if(!(i = v[a] * 1)) continue;
            i % 100 < 20 && (t += ex[0][i % 100]) ||
            i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
            s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
            ((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t]) : ""));
        }
        a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
        a && r.push(a + (c ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
    }
    return r.join(e);
}



const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
    max-width: 700px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 4px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


const teste='150'

const textoestilo = {
    width: "600px",
    textAlign: "justify",
  }

  const textoestilodata = {
    width: "600px",
    textAlign: "right",
  }

  const textoestiloassinatura = {
    width: "600px",
    textAlign: "left",
  }

class ModalCarta extends Component{
    state = {}


    
descobremes = (numero) =>{
    let mes = ''
    
    switch (numero.toString()) {
        
        case "0":
            mes='Janeiro'
            break
        case "1":
            mes='Fevereiro'
            break
        case "2":
            mes='Março'
            break
        case "3":
            mes='Abril'
            break
        case "4":
            mes='Maio'
            break
        case "5":
            mes='Junho'
            break
        case "6":
            mes='Julho'
            break
        case "7":
            mes='Agosto'
            break
        case "8":
            mes='Setembro'
            break
        case "9":
            mes='Outubro'
            break
        case "10":
            mes='Novembro'
            break
        case "11":
            mes='Dezembro'
            break  
    }

    return mes

}

    

    constructor(props){
        super(props)
    }


    componentDidMount(){

    }

    componentWillReceiveProps(){
      
        
    }


    render(){

        return(


            <Modal
                trigger={<Button basic icon={'print'} color={'blue'} size="mini" />}
            //open={this.props.entidade.modalOpen}
            //onClose={(e) => this.props.handleClose(this.props.entidade)}
            >
                <Modal.Header>Cartas</Modal.Header>
                <Modal.Content image scrolling>

                    <Modal.Description>

                        <div ref={el => (this.componentRef = el)}>

                        {
                            //PROTESTO
                        }

                        {
                            this.props.entidade.dividas.filter(d=>d.chequenome==null).map(item=>(         
                                    <Segment>
                                        <Image src='img/logo_esplane.png' centered />

                                        <p align="center">
                                
                                        <b>CNPJ: 61.740.510/0001-90</b><br/>
                                        ESPLANE ESPAÇOS PLANEJADOS LTDA.<br/>
                                        Av. Gal. Euclides Figueiredo, 151<br/>
                                        Vila Industrial – CEP 13035 – 150<br/>
                                        Campinas / SP Tel: (19) 3303-7000<br/><br/>
                                        </p>
                                        <Header as='h2' textAlign='center'>DECLARAÇÃO DE ANUÊNCIA</Header>
                                        <center>
                                            <div style={textoestilo}>
                                        
                                            <b>ESPLANE ESPAÇOS PLANEJADOS LTDA</b>, legalmente estabelecida na Av.
                                            General Euclides Figueiredo, 151 – Vila Industrial – Campinas/SP,
                                            devidamente inscrita no CNPJ n° 61.740.510/0001-90 DECLARA, para todos
                                            os fins e efeitos de direito, que recebeu de {this.props.entidade.parceiro}
                                            , {(this.props.entidade.tippessoa=="F"?"":"empresa inscrita no")} {(this.props.entidade.tippessoa=="F"?"CPF":"CNPJ")} n° {this.props.entidade.documento}, a
                                            importância de {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.vlrdesdob)} ({item.vlrdesdob.toString().replace('.',',').extenso(true).toUpperCase()})
                                            referente ao DMI Nº {item.numnota} com vencimento em {moment(item.dtvenc).format("DD/MM/YYYY")+' '} 
                                            que da inteira e irrevogável quitação, bem como emite a presente 
                                            <b> DECLARAÇÃO DE ANUÊNCIA</b>, em conformidade com as Normas da
                                            Corregedoria Geral da Justiça do Estado de São Paulo. Declara ainda, sob as
                                            penas das leis, que o subscritor desta possui poderes para autorizar o
                                            cancelamento do protesto antes indicado, na forma dos contratos sociais
                                            registrados no órgão competente e que autoriza sejam os documentos
                                            apresentados a serem examinados onde for, especialmente na JUCESP, sem
                                            prejuízo de colocar-se a disposição para mais esclarecimentos através do
                                            telefone da empresa, sob n° (19) 3303-7000, no horário comercial, o qual
                                            poderá ser checado na lista oficial de assinantes.
                                    
                                            </div>
                                            
                                            <br/><br/><br/><br/>

                                            <div style={textoestilodata}>
                                                Campinas, {new Date().getDate()} de {this.descobremes(new Date().getMonth())} de {new Date().getFullYear()}.
                                            </div>

                                            <div style={textoestiloassinatura}>
                                                Atenciosamente,<br/><br/>
                                                ________________________________________________<br/>
                                                <b>ESPLANE Espaços Planejados Ltda.</b><br/>
                                                PEDRO NELSON MORELLI<br/>
                                                RG: 4.183.532 - SSP/SP

                                            </div>

                                        </center>
                                        
                                    </Segment>
                            ))

                        }

                        {


                            this.props.entidade.dividas.filter(d=>d.chequenome!=null).map(item=>(  

                                <Segment>
                                    <Image src='img/logo_esplane.png' centered />

                                    <p align="center">

                                    <b>CNPJ: 61.740.510/0001-90</b><br/>
                                    ESPLANE ESPAÇOS PLANEJADOS LTDA.<br/>
                                    Av. Gal. Euclides Figueiredo, 151<br/>
                                    Vila Industrial – CEP 13035 – 150<br/>
                                    Campinas / SP Tel: (19) 3303-7000<br/><br/>
                                    </p>
                                    <Header as='h2' textAlign='center'>DECLARAÇÃO DE ANUÊNCIA</Header>
                                    <center>
                                        <div style={textoestilo}>

                                        <b>ESPLANE ESPAÇOS PLANEJADOS LTDA</b>, legalmente estabelecida na Av.
                                        General Euclides Figueiredo, 151 – Vila Industrial – Campinas/SP,
                                        devidamente inscrita no CNPJ n° 61.740.510/0001-90 DECLARA, para todos
                                        os fins e efeitos de direito, que recebeu de {(item.chequenome==null?"______________________________________________":item.chequenome)}, {(item.chequecpf==null?"_______":(item.chequecpf.length>11?"CNPJ":"CPF"))} nº
                                        {(item.chequecpf==null?"____________________________":item.chequecpf)}, a importância de {(item.chequevlr==null?"_____________________________________________________________________________________":new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.chequevlr)+" ("+item.chequevlr.toString().replace('.',',').extenso(true).toUpperCase()+")")}
                                        , referente ao cheque nº. {(item.chequenumero==null?"_______________":item.chequenumero)} com vencimento em
                                        {(item.chequedata==null?"_____________________":moment(item.chequedata).format("DD/MM/YYYY"))}, que a referida carta é emitida em razão de extravio da 1º via pelo
                                        emitente do cheque, que em razão dá inteira e irrevogável quitação, bem como
                                        emite a 2º via presente <b>DECLARAÇÃO DE ANUÊNCIA</b>, em conformidade com
                                        as Normas da Corregedoria Geral da Justiça do Estado de São Paulo. Declara
                                        ainda, sob as penas das leis, que o subscritor desta possui poderes para
                                        autorizar o cancelamento do protesto antes indicado bem como a regularização
                                        no Banco Central do Brasil, na forma dos contratos sociais registrados no
                                        órgão competente e que autoriza sejam os documentos apresentados a serem
                                        examinados onde for, especialmente na JUCESP, sem prejuízo de colocar-se a
                                        disposição para mais esclarecimentos através do telefone da empresa, sob n°
                                        (19) 33037000, no horário comercial, o qual poderá ser checado na lista oficial
                                        de assinantes.

                                        </div>

                                        <br/><br/><br/><br/>

                                            <div style={textoestilodata}>
                                                Campinas, {new Date().getDate()} de {this.descobremes(new Date().getMonth())} de {new Date().getFullYear()}.
                                            </div>

                                            <div style={textoestiloassinatura}>
                                                Atenciosamente,<br/><br/>
                                                ________________________________________________<br/>
                                                <b>ESPLANE Espaços Planejados Ltda.</b><br/>
                                                PEDRO NELSON MORELLI<br/>
                                                RG: 4.183.532 - SSP/SP

                                            </div>
                                    </center>

                                </Segment>


                            ))

                        }
                        




                        


                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>

                    <ReactToPrint
                        trigger={() => <Button color='green'>Imprimir</Button>}
                        content={() => this.componentRef}
                        pageStyle="@media print {pre, blockquote, table, div {page-break-inside: avoid; border: 1px solid #ffff;}}"
                        //onAfterPrint={(e) =>this.props.handlePrint(this.props.datalist)}
                    />

                </Modal.Actions>

            </Modal >
        )

    }


}

ModalCarta.defaultProps = {
    entidade:{}
}
ModalCarta.PropTypes = {
    entidade: PropTypes.object.isRequired
}

export default ModalCarta