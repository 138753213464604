import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Segment, List, Image, Tab, Icon, Popup } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';

import InputMask from 'react-input-mask';


import If from '../../utils/If';




const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}
`

class ListConfiguracoes extends Component {

    constructor(props) {
        moment.locale('pt-br');
        super(props);
        //this.onChangeModalOpen = this.onChangeModalOpen.bind(this);
    }

    componentDidMount() {

    }

    onEditConfig(e, configuracao) {

        //console.log(configuracao)

        this.props.handleChangeConfig({ target: { 'name': 'id', 'value': configuracao.id } });
        this.props.handleChangeConfig({ target: { 'name': 'grupo', 'value': configuracao.grupo } });
        this.props.handleChangeConfig({ target: { 'name': 'pctminimo', 'value': configuracao.pctminimo } });
        this.props.handleChangeConfig({ target: { 'name': 'pctmaximo', 'value': configuracao.pctmaximo } });
        this.props.handleChangeConfig({ target: { 'name': 'pctdiferenca', 'value': configuracao.pctdiferenca } });
    }

    onCancelConfig(e, configuracao) {
        this.props.handleClearConfig();
    }

    onSaveConfig(entidade) {

        this.props.handleSaveConfig(entidade);

    }

    onUpdateStatus(){
        

        this.props.handleUpdateStatus();

    }

    render() {
        let { dataList, dataQtde, loader, entidade
        } = this.props;

        

        return (
            <Tab.Pane loading={loader}>

                <If test={this.props.robostatus === 1 || this.props.robostatus === 2}>
                    <Popup
                        trigger={<Icon loading name='cog' color='blue' size='large' />}
                        content='o Robô está em processamento...'
                        size='tiny'
                    />
                </If>

                <If test={this.props.robostatus === 0}>
                    <Popup
                        trigger={<Icon name='cog' color='red' size='large' />}
                        content='o Robô está parado.'
                        size='tiny'
                    />
                    
                        <Button basic color='blue' content='Iniciar' size='mini' onClick={(e) => this.onUpdateStatus() }/>
                        
                    Últ. Execução: {this.props.robodt}
              </If>
                <br />
                <br />
                <StyledTable celled id="ListFaturadosSemRota" compact='very' size='small'>
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">ID</Table.HeaderCell>
                            <Table.HeaderCell width="2">Concorrente</Table.HeaderCell>
                            <Table.HeaderCell width="1">Grupo</Table.HeaderCell>
                            <Table.HeaderCell width="1">PCT Mínimo</Table.HeaderCell>
                            <Table.HeaderCell width="1">PCT Máximo</Table.HeaderCell>
                            <Table.HeaderCell width="1">PCT Diferença</Table.HeaderCell>
                            <Table.HeaderCell width="1">WEBPRICE ID</Table.HeaderCell>
                            <Table.HeaderCell width="1"></Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {dataList.map(data => (
                            <Table.Row key={`conf${data.id}${data.grupo}`}>
                                <Table.Cell>{data.id}</Table.Cell>
                                <Table.Cell>{data.concorrente}</Table.Cell>
                                <Table.Cell>{data.grupo}</Table.Cell>
                                <Table.Cell>
                                    {(entidade.id === data.id && entidade.grupo === data.grupo) ?
                                        (<div >
                                            <InputMask type="number" name="pctminimo" placeholder="PCT Mínimo"
                                                value={entidade.pctminimo}
                                                onChange={this.props.handleChangeConfig}
                                                style={{ width: "65px" }}
                                            />
                                        </div>) :
                                        data.pctminimo}
                                </Table.Cell>
                                <Table.Cell>
                                    {(entidade.id === data.id && entidade.grupo === data.grupo) ?
                                        (<div style={{ width: "10px" }}>
                                            <InputMask type="number" name="pctmaximo" placeholder="PCT Máximo"
                                                value={entidade.pctmaximo}
                                                onChange={this.props.handleChangeConfig}
                                                style={{ width: "65px" }}
                                            />
                                        </div>) : data.pctmaximo}
                                </Table.Cell>
                                <Table.Cell>
                                    {(entidade.id === data.id && entidade.grupo === data.grupo) ?
                                        (<div style={{ width: "10px" }}>
                                            <InputMask type="number" name="pctdiferenca" placeholder="PCT Diferença"
                                                value={entidade.pctdiferenca}
                                                onChange={this.props.handleChangeConfig}
                                                style={{ width: "65px" }}
                                            />
                                        </div>) : data.pctdiferenca}
                                </Table.Cell>
                                <Table.Cell>{data.webpriceid}</Table.Cell>
                                <Table.Cell>
                                    {(entidade.id === data.id && entidade.grupo === data.grupo) ?
                                        (<div><Button key={`save_${data.id}${data.grupo}`} basic icon={'write'} color={'green'} size="mini"
                                            onClick={(e) => this.onSaveConfig(entidade)}
                                        />
                                            <Button key={`cancel_${data.id}${data.grupo}`} basic icon={'cancel'} color={'red'} size="mini"
                                                onClick={(e) => this.onCancelConfig(e, data)}
                                            /></div>) : (
                                            <Button key={`edit_${data.id}${data.grupo}`} basic icon={'edit'} color={'blue'} size="mini"
                                                onClick={(e) => this.onEditConfig(e, data)}
                                            />
                                        )
                                    }
                                </Table.Cell>
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>
            </Tab.Pane>
        )
    }
}

ListConfiguracoes.defaultProps = {
    dataList: []
    , loader: true
    , entidade: {}
    , robostatus: 0
    , robodt: null
};

ListConfiguracoes.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , entidade: PropTypes.object.isRequired
    , handleChangeConfig: PropTypes.func.isRequired
    , handleClearConfig: PropTypes.func.isRequired
    , handleSaveConfig: PropTypes.func.isRequired
    , robostatus: PropTypes.number.isRequired
    , robodt: PropTypes.string.isRequired
    , handleUpdateStatus: PropTypes.func.isRequired
};



export default ListConfiguracoes
