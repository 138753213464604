import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tab, Dropdown, Form, Progress } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';

import If from '../../utils/If';
import VccModalFaturar from './VccModalFaturar';
import VccModalPagar from './VccModalPagar';


const StyledDropdown = styled(Dropdown)`
  &&& {
    div{
        font-size:10px !important;
        width:80px !important;
    }
    span{
        font-size:10px !important;
        width:80px !important;
    }

   }`


const opcoes = {
    scales: {
        yAxes: [
            {
                ticks: {
                    callback: function (label, index, labels) {
                        //return 'R$' + label.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.');
                        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(label)
                        if (label > 75) {
                            return "Expert: " + label;
                        } else if (label > 50) {
                            return "Advanced: " + label;
                        } else if (label > 25) {
                            return "Intermediate: " + label;
                        } else {
                            return "Beginner: " + label;
                        }

                    }
                }
            }
        ]
    },
    responsive: true,

    tooltips: {
        callbacks: {
            title: function () {
                return '';
            },
            label: function (item, data) {
                var datasetLabel = data.datasets[item.datasetIndex].label || '';
                var dataPoint = item.yLabel;
                return datasetLabel + ' ' + new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dataPoint);
            }
        },
        mode: "x",//esse faz aparecer todos tooltip do dia
    }
}

let trataDecimal = (valor) => {
    if (valor != null) {
        valor = valor.toFixed(2)
    }
    return valor
}




let padLeft = (data, size, paddingChar) => {
    return (new Array(size + 1).join(paddingChar, '0') + String(data)).slice(-size);
}


let escolherCor = (count) => {
    let cor;
    switch (count) {
        case 0:
            cor = '151,187,205'
            break;
        case 1: {
            cor = '237,60,29'
            break;
        }
        case 2: {
            cor = '15,184,111'
            break;
        }
        case 3: {
            cor = '212,160,17'
            break;
        }
        case 4: {
            cor = '147,15,184'
            break;
        }
        case 5: {
            cor = '59,17,212'
            break;
        }
        case 6: {
            cor = '17,206,212'
            break;
        }
        case 7: {
            cor = '212,128,17'
            break;
        }
        case 8: {
            cor = '0,0,0'
            break;
        }
        case 9: {
            cor = '204,148,148'
            break;
        }
        case 10: {
            cor = '71,127,41'
            break;
        }
        default: {
            cor = '220,220,220'
            break;
        }
    }
    return cor
}




class VccChart extends Component {

    state = {
        dropDataSelecionado: ''
        , vendido: 0
        , previsao: 0
        , pedidos: 0
        , ticketmedio: 0
        , meta100: 0
        , meta100pct: 0
        , meta100falta: 0
        , meta110: 0
        , meta110pct: 0
        , meta110falta: 0
        , progressCorrente: 0
        , progressTotal: 0

        , dut: 0
        , duf: 0

        //##passo3
        , grafico: {}
        , distanciameta100: 0
        , distanciameta110: 0
        , distanciameta120: 0
        , distanciameta130: 0
    };


    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }




    componentDidMount() {
        this.selecionaDataPadrao()
    }

    componentWillReceiveProps() {

        if (this.state.dropDataSelecionado === '') {
            this.selecionaDataPadrao()
        } else {
            if (this.props.dataDrop.length > 0) {
                let valor = this.props.dataDrop[0].value.toString()
                this.getDataSelecionada(valor, true)
            }

        }
    }

    selecionaDataPadrao = () => {
        if (this.props.dataDrop.length > 0) {
            let valor = this.props.dataDrop[0].value.toString()
            this.setState({ dropDataSelecionado: valor })
            this.getDataSelecionada(valor, false)
        }
    }


    //##passo 5 criando função

    QtdeDiasUteisAteMomento = (dia, anomes) => {
        let valor = dia;

        let mes = this.props.datameses.filter(f => f.anomes == anomes)

        if (mes != undefined) {
            if (mes[0] != undefined) {
                valor = mes[0].dados[0]['dia' + dia];
            }
        }

        return valor
    }

    setdados = (anomes, dadoCorrente, diauteiscorrente) => {

        this.setState({ grafico: {} })

        let metaMesCorrenteVcc = dadoCorrente.meta110;
        let qtdeDiasUteisMesCorrente = diauteiscorrente

        let metaMesCorrenteVcc100 = dadoCorrente.meta100;
        let metaMesCorrenteVcc120 = dadoCorrente.meta120;
        let metaMesCorrenteVcc130 = dadoCorrente.meta130;

        /*alert(`
        dropDataSelecionado: ${this.state.dropDataSelecionado}
        anomes: ${anomes}
        metaMesCorrenteVcc: ${metaMesCorrenteVcc}
        qtdeDiasUteisMesCorrente: ${qtdeDiasUteisMesCorrente}
        `)*/

        let dtsource = []
        dtsource = this.props.dataList
        dtsource = dtsource.filter(f => f.id != 1234)

        //CALCULAR META DO MÊS CORRENTE

        let calcmetames110 = {
            anomes: "Meta 110% - " + anomes
            , dia01: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(1, anomes)
            , dia02: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(2, anomes)
            , dia03: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(3, anomes)
            , dia04: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(4, anomes)
            , dia05: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(5, anomes)
            , dia06: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(6, anomes)
            , dia07: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(7, anomes)
            , dia08: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(8, anomes)
            , dia09: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(9, anomes)
            , dia10: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(10, anomes)
            , dia11: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(11, anomes)
            , dia12: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(12, anomes)
            , dia13: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(13, anomes)
            , dia14: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(14, anomes)
            , dia15: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(15, anomes)
            , dia16: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(16, anomes)
            , dia17: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(17, anomes)
            , dia18: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(18, anomes)
            , dia19: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(19, anomes)
            , dia20: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(20, anomes)
            , dia21: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(21, anomes)
            , dia22: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(22, anomes)
            , dia23: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(23, anomes)
            , dia24: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(24, anomes)
            , dia25: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(25, anomes)
            , dia26: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(26, anomes)
            , dia27: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(27, anomes)
            , dia28: (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(28, anomes)
            , dia29: (this.QtdeDiasUteisAteMomento(29, anomes) === 99 ? null : (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(29, anomes))
            , dia30: (this.QtdeDiasUteisAteMomento(30, anomes) === 99 ? null : (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(30, anomes))
            , dia31: (this.QtdeDiasUteisAteMomento(31, anomes) === 99 ? null : (metaMesCorrenteVcc / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(31, anomes))
            , fechado: 0
            , id: 1234
            , meta100: 0
            , meta100falta: 0
            , meta100pct: 0
            , meta110: 0
            , meta110falta: 0
            , meta110pct: 0
            , pedidos: 0
            , previsao: 0
            , ticketmedio: 0
            , uteis: 0
            , uteishoje: 0
        }

        dtsource.push(calcmetames110)
        //----------------------------------


        //calculando todas distância meta----------------------------------------
        let daytoday = new Date().getDate()
        let vlrmetaday110 = calcmetames110[`dia${padLeft(daytoday, 2, '0')}`]

        let vlrmetaday100 = (metaMesCorrenteVcc100 / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(daytoday, anomes)
        let vlrmetaday120 = (metaMesCorrenteVcc120 / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(daytoday, anomes)
        let vlrmetaday130 = (metaMesCorrenteVcc130 / qtdeDiasUteisMesCorrente) * this.QtdeDiasUteisAteMomento(daytoday, anomes)

        this.setState({ distanciameta100: vlrmetaday100 - dadoCorrente.fechado })
        this.setState({ distanciameta110: vlrmetaday110 - dadoCorrente.fechado })
        this.setState({ distanciameta120: vlrmetaday120 - dadoCorrente.fechado })
        this.setState({ distanciameta130: vlrmetaday130 - dadoCorrente.fechado })
        //-----------------------------------------------------------------------


        let dados = {}
        dados.labels = []
        dados.datasets = []
        dados.labels.push("1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31")

        let count = 0;
        count = 0
        for (let i of dtsource) {
            let ds = {};

            ds.label = i.anomes
            ds.fill = false


            if (count === 0 || i.anomes.indexOf('Meta') == 0 || i.anomes == anomes) {
                ds.hidden = false
            } else {
                ds.hidden = true
            }

            ds.lineTension = 0.0
            ds.backgroundColor = `rgba(${escolherCor(count)},0.4)`
            ds.borderColor = `rgba(${escolherCor(count)},1)`
            ds.borderCapStyle = 'butt'
            ds.borderDash = []
            ds.borderDashOffset = 0.0
            ds.borderJoinStyle = 'miter'
            ds.pointBorderColor = `rgba(${escolherCor(count)},1)`
            ds.pointBackgroundColor = '#fff'
            ds.pointBorderWidth = 1
            ds.pointHoverRadius = 5
            ds.pointHoverBackgroundColor = `rgba(${escolherCor(count)},1)`
            ds.pointHoverBorderColor = 'rgba(220,220,220,1)'
            ds.pointHoverBorderWidth = 2
            ds.pointRadius = 4,
                ds.pointHitRadius = 10,

                ds.data =
                [trataDecimal(i.dia01), trataDecimal(i.dia02), trataDecimal(i.dia03), trataDecimal(i.dia04), trataDecimal(i.dia05)
                    , trataDecimal(i.dia06), trataDecimal(i.dia07), trataDecimal(i.dia08), trataDecimal(i.dia09), trataDecimal(i.dia10)
                    , trataDecimal(i.dia11), trataDecimal(i.dia12), trataDecimal(i.dia13), trataDecimal(i.dia14), trataDecimal(i.dia15)
                    , trataDecimal(i.dia16), trataDecimal(i.dia17), trataDecimal(i.dia18), trataDecimal(i.dia19), trataDecimal(i.dia20)
                    , trataDecimal(i.dia21), trataDecimal(i.dia22), trataDecimal(i.dia23), trataDecimal(i.dia24), trataDecimal(i.dia25)
                    , trataDecimal(i.dia26), trataDecimal(i.dia27), trataDecimal(i.dia28), trataDecimal(i.dia29), trataDecimal(i.dia30)
                    , trataDecimal(i.dia31)]
            dados.datasets.push(ds)

            count++
        }

        //adicionando gráfico
        this.setState({ grafico: dados })







    }

    getDataSelecionada = (anomes, automatico) => {


        let dadoCorrente = this.props.dataList.filter(f => f.anomes === anomes)[0]
        let diauteiscorrente = this.props.datameses.filter(f => f.anomes === anomes)[0].dados[0].uteis
        let diauteiscorrentehoje = this.props.datameses.filter(f => f.anomes === anomes)[0].dados[0].uteishoje

        if (automatico) {
            this.setdados(this.state.dropDataSelecionado, dadoCorrente, diauteiscorrente)
        } else {
            this.setdados(anomes, dadoCorrente, diauteiscorrente)
        }



        this.setState({ vendido: dadoCorrente.fechado })
        this.setState({ previsao: dadoCorrente.previsao })
        this.setState({ pedidos: dadoCorrente.pedidos })
        this.setState({ ticketmedio: dadoCorrente.ticketmedio })

        this.setState({ meta100: dadoCorrente.meta100 })
        this.setState({ meta100pct: dadoCorrente.meta100pct })
        this.setState({ meta100falta: dadoCorrente.meta100falta })

        this.setState({ meta110: dadoCorrente.meta110 })
        this.setState({ meta110pct: dadoCorrente.meta110pct })
        this.setState({ meta110falta: dadoCorrente.meta110falta })

        this.setState({ meta120: dadoCorrente.meta120 })
        this.setState({ meta120pct: dadoCorrente.meta120pct })
        this.setState({ meta120falta: dadoCorrente.meta120falta })

        this.setState({ meta130: dadoCorrente.meta130 })
        this.setState({ meta130pct: dadoCorrente.meta130pct })
        this.setState({ meta130falta: dadoCorrente.meta130falta })


        if (this.props.dataDrop[0].text == anomes) {
            this.setState({ diasuteis: `${diauteiscorrentehoje}/${diauteiscorrente}(${diauteiscorrente - diauteiscorrentehoje})` })
            this.setState({ dut: diauteiscorrente })
            this.setState({ duf: diauteiscorrente - diauteiscorrentehoje })
        } else {
            this.setState({ diasuteis: `` })
        }


        this.setState({ naopagos: dadoCorrente.naopagos })
        this.setState({ afaturar: dadoCorrente.afaturar })

        if (dadoCorrente.meta100pct <= 100) {
            //calculando progressbar
            this.setState({ progressCorrente: parseInt(dadoCorrente.meta100pct) })
            this.setState({ progressTotal: 100 })
        } else {
            //calculando progressbar
            this.setState({ progressCorrente: parseInt(dadoCorrente.meta100pct) })
            this.setState({ progressTotal: parseInt(dadoCorrente.meta100pct) })
        }




    }



    //DropDowns
    handleDropData = (e, { value }) => {
        this.setState({ dropDataSelecionado: value.toString() })
        this.getDataSelecionada(value.toString(), false)
    }


    render() {
        let { dataList, loader, dataDrop

            , GraficoDeVendasListFaturarAction, GraficoDeVendasListPagarAction
            , graficodevendas_popup_faturar, graficodevendas_popup_faturar_loader
            , graficodevendas_popup_pagar, graficodevendas_popup_pagar_loader
        } = this.props;


        let exibe = false

        if (this.props.dataDrop.length > 0) {
            if (this.props.dataDrop[0].value.toString() == this.state.dropDataSelecionado) {
                exibe = true
            }
        }

        const { openModalFaturar, openModalPagar, textoModal } = this.state;


        const AbrirModal = (texto) => {

            if (texto === 'Pedidos a Faturar') {
                this.setState({ openModalFaturar: true, textoModal: texto });

                GraficoDeVendasListFaturarAction().then(d => {
                    console.log('DADOS Faturar ---->', graficodevendas_popup_faturar)
                })



            } else if (texto === 'Pedidos a Pagar') {
                this.setState({ openModalPagar: true, textoModal: texto });

                GraficoDeVendasListPagarAction().then(d => {


                })
            }

        }
        const FecharModal = () => {
            this.setState({ openModalFaturar: false });
            this.setState({ openModalPagar: false });
        }


        return (
            <Tab.Pane loading={loader}>

                <VccModalFaturar
                    loader={graficodevendas_popup_faturar_loader} dadosModal={graficodevendas_popup_faturar} textoHeader={textoModal}
                    open={openModalFaturar} openModal={() => AbrirModal()}
                    closeModal={() => FecharModal()}
                />
                <VccModalPagar
                    loader={graficodevendas_popup_pagar_loader} dadosModal={graficodevendas_popup_pagar} textoHeader={textoModal}
                    open={openModalPagar} openModal={() => AbrirModal()}
                    closeModal={() => FecharModal()}
                />

                <Form>
                    <Form.Group widths='equal' color='gray'>

                        <div className="field">
                            <label>Ano/Mês: {this.state.dropDataSelecionado}</label>
                            <div>
                                <StyledDropdown
                                    options={dataDrop}
                                    selection
                                    placeholder='Ano/Mês'
                                    name='anomes'
                                    fluid
                                    value={this.state.dropDataSelecionado}
                                    onChange={this.handleDropData}
                                    scrolling
                                />
                            </div>

                        </div>

                        <div className="field">
                            <label>Vendido:</label>
                            <div>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.vendido)}
                            </div>
                        </div>

                        <div className="field">
                            <label>Previsão:</label>
                            <div>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.previsao)}
                            </div>
                        </div>

                        <div className="field">
                            <label>Pedidos:</label>
                            <div>
                                {this.state.pedidos}
                            </div>
                        </div>
                        <div className="field">
                            <label>Ticket Médio:</label>
                            <div>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.ticketmedio)}
                            </div>
                        </div>
                        <div className="field">
                            <label>Dias úteis:</label>
                            <div>
                                {this.state.diasuteis}
                            </div>
                        </div>
                        <div className="field">
                            <label onClick={() => AbrirModal('Pedidos a Pagar')}>A Pagar:</label>
                            <div onClick={() => AbrirModal('Pedidos a Pagar')}>
                                {this.state.naopagos}
                            </div>
                        </div>

                        <div className="field">
                            <label onClick={() => AbrirModal('Pedidos a Faturar')}>A Faturar:</label>
                            <div onClick={() => AbrirModal('Pedidos a Faturar')}>
                                {this.state.afaturar}
                            </div>
                        </div>


                    </Form.Group>
                    <Form.Group color='gray' widths={(exibe && this.state.duf > 0 ? '16' : 'equal')}>
                        <div className="field">
                            <label>Meta 100% - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta100)} - {Number(this.state.meta100pct).toFixed(2)}%
                                {(this.state.meta100falta > 0 ? `- (faltam: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta100falta)})` : "")}
                            </label>
                            <label>Meta 120% - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta120)} - {Number(this.state.meta120pct).toFixed(2)}%
                                {(this.state.meta120falta > 0 ? `- (faltam: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta120falta)})` : "")}
                            </label>
                        </div>

                        <If test={exibe && this.state.duf > 0}>
                            <div className="field">
                                <label>
                                    {parseFloat(this.state.meta100 / this.state.dut / 1000).toFixed(0)}k|{parseFloat(this.state.meta100falta / this.state.duf / 1000).toFixed(0)}k
                                    &nbsp;&nbsp;{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.distanciameta100)}
                                </label>
                                <label>
                                    {parseFloat(this.state.meta120 / this.state.dut / 1000).toFixed(0)}k|{parseFloat(this.state.meta120falta / this.state.duf / 1000).toFixed(0)}k
                                    &nbsp;&nbsp;{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.distanciameta120)}
                                </label>
                            </div>
                            <div className="field">
                                <label>  </label>
                            </div>
                        </If>



                        <div className="field">
                            <label>Meta 110% - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta110)} - {Number(this.state.meta110pct).toFixed(2)}%
                                {(this.state.meta110falta > 0 ? `- (faltam: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta110falta)})` : "")}

                            </label>
                            <label>Meta 130% - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta130)} - {Number(this.state.meta130pct).toFixed(2)}%
                                {(this.state.meta130falta > 0 ? `- (faltam: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta130falta)})` : "")}
                            </label>

                        </div>
                        <If test={exibe && this.state.duf > 0}>
                            <div className="field">
                                <label>
                                    {parseFloat(this.state.meta110 / this.state.dut / 1000).toFixed(0)}k|{parseFloat(this.state.meta110falta / this.state.duf / 1000).toFixed(0)}k
                                    &nbsp;&nbsp;{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.distanciameta110)}
                                </label>
                                <label>
                                    {parseFloat(this.state.meta130 / this.state.dut / 1000).toFixed(0)}k|{parseFloat(this.state.meta130falta / this.state.duf / 1000).toFixed(0)}k
                                    &nbsp;&nbsp;{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.distanciameta130)}
                                </label>

                            </div>
                        </If>
                    </Form.Group>



                    <Form.Group widths='equal' color='gray'>
                        <div className="field">
                            <div>
                                <Progress value={this.state.progressCorrente} total={this.state.progressTotal} progress='value' indicating></Progress>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
                {

                    (this.state.grafico.datasets != undefined
                        ? <Line data={this.state.grafico} width={450} heigth={450} options={opcoes} />
                        : "")

                    /*(dados.datasets.length > 0
                        ? <Line data={dados} width={450} heigth={450} options={opcoes} />
                        : "")*/



                }
            </Tab.Pane>
        )
    }
}

VccChart.defaultProps = {
    dataList: []
    , loader: true
    , dataDrop: []
    //##passo 1
    , datameses: []

    , graficodevendas_popup_faturar: []
    , graficodevendas_popup_faturar_loader: false
    , graficodevendas_popup_pagar: []
    , graficodevendas_popup_pagar_loader: false
};

VccChart.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , dataDrop: PropTypes.array.isRequired
    //##passo 1
    , datameses: PropTypes.array.isRequired

    , GraficoDeVendasListFaturarAction: PropTypes.func.isRequired
    , GraficoDeVendasListPagarAction: PropTypes.func.isRequired

    , graficodevendas_popup_faturar: PropTypes.array.isRequired
    , graficodevendas_popup_faturar_loader: PropTypes.bool.isRequired
    , graficodevendas_popup_pagar: PropTypes.array.isRequired
    , graficodevendas_popup_pagar_loader: PropTypes.bool.isRequired
};



export default VccChart
