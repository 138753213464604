import {
    PROCESSAMENTOS_DESCONTOFBITS_ISLOADING
    , PROCESSAMENTOS_DESCONTOFBITS_LIST

    , PROCESSAMENTOS_PARCEIROS_LIST
    , PROCESSAMENTOS_PARCEIROS_ISSEARCHING
    , PROCESSAMENTOS_PARCEIROS_ISSAVING
    , PROCESSAMENTOS_PARCEIROS_CHANGE

    , PROCESSAMENTOS_CHANGE_LIBMARKETPLACE

    } from '../actions/ActionsTypes';

    import {ProcessamentosParceiroEntity} from '../entities/ProcessamentosParceiroEntity'
    
    const INITIAL_STATE = {
        descontofbitsisloading: false
        , descontofbitsstatus:-1
        , descontofbitsdt:null
        , ProcessamentosParceiroEntity
        , issearchingparceiro: false
        , issavingparceiro: false
        , libmarketplace: false
        
    }
    
    export default (state = INITIAL_STATE, action) => {
        switch (action.type) {

            
            case PROCESSAMENTOS_CHANGE_LIBMARKETPLACE:
                return {...state, libmarketplace: action.payload}

                
            
            case PROCESSAMENTOS_DESCONTOFBITS_ISLOADING:
                return { ...state, descontofbitsisloading: action.payload }

            case PROCESSAMENTOS_DESCONTOFBITS_LIST:
                return {...state, descontofbitsstatus: action.status, descontofbitsdt: action.dt}

            case PROCESSAMENTOS_PARCEIROS_LIST:
                    return {...state, ProcessamentosParceiroEntity: action.payload}

            case PROCESSAMENTOS_PARCEIROS_ISSEARCHING:
                    return {...state, issearchingparceiro: action.payload}

            case PROCESSAMENTOS_PARCEIROS_ISSAVING:
                    return {...state, issavingparceiro: action.payload}
            
            case PROCESSAMENTOS_PARCEIROS_CHANGE:
                    return { ...state, ProcessamentosParceiroEntity: {...state.ProcessamentosParceiroEntity, [action.field]: action.payload}}

    
            default:
                return state;
        }
    }