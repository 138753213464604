import sortBy from 'sort-by';
import moment from 'moment-timezone';



import {
    TRACKERS_PAINEL_LIST
    , TRACKERS_PAINEL_LOADER
    , TRACKERS_PAINEL_LIST_SPLIT
    , TRACKERS_PAINEL_SPLIT
    , TRACKERS_PAINEL_SCREEN
    , TRACKERS_PAINEL_LIST_CHANGE_LOGS
    , TRACKERS_LIST_REASONS
    , TRACKERS_DIFFERENT_CARS
} from '../actions/ActionsTypes'


import { START_TIMER } from 'redux-timer';
import { STOP_TIMER } from 'redux-timer';

import * as Api from '../utils/API';


export const trackerSaveLogsAction = (oc, logitem) => (dispatch, getState) =>

    new Promise(function (resolve, reject) {

        const { login } = getState().LoginReducer;
        let dtagora = moment.tz(new Date(), 'America/Sao_Paulo').format('YYYY-MM-DD HH:mm:ss ZZ');


        dispatch({ type: TRACKERS_PAINEL_LIST_CHANGE_LOGS, payload: login, oc: oc, field: 'status_login', logid: logitem.logid });
        dispatch({ type: TRACKERS_PAINEL_LIST_CHANGE_LOGS, payload: dtagora, oc: oc, field: 'status_time', logid: logitem.logid });
        dispatch({ type: TRACKERS_PAINEL_LIST_CHANGE_LOGS, payload: true, oc: oc, field: 'issaving', logid: logitem.logid });

        Api.postSaveTrackersLog(logitem).then(dados => {
            dispatch({ type: TRACKERS_PAINEL_LIST_CHANGE_LOGS, payload: false, oc: oc, field: 'issaving', logid: logitem.logid });
            dispatch({ type: TRACKERS_PAINEL_LIST_CHANGE_LOGS, payload: 1, oc: oc, field: 'status', logid: logitem.logid });
            resolve([])
        }).catch(error => {
            //dispatch({ type: MARKETPLACES_ISSEARCHING, payload: false })
            dispatch({ type: TRACKERS_PAINEL_LIST_CHANGE_LOGS, payload: false, oc: oc, field: 'issaving', logid: logitem.logid });
            //reject(error);
            resolve([])
        })

    });








export const trackerHandleChangeLogsAction = (event, oc, logid) => {
    return {
        type: TRACKERS_PAINEL_LIST_CHANGE_LOGS
        , oc: oc
        , logid: logid
        , field: event.target.name
        , payload: event.target.value
    }
}


export const trackerTimerSplitAction = () => async (dispatch) => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'painelSplitTimer',
            action: async () => {
                try {
                    // console.log('tick trackerTimerSplitAction')

                    dispatch(trackersPainelSplitAction())


                } catch (e) {
                    // do something with this error
                }
            },
            interval: 20000,//33seg
            runImmediately: false
        }
    });
};



export const trackerTimerPainelListAction = () => async (dispatch) => {
    dispatch({
        type: START_TIMER,
        payload: {
            name: 'painelListTimer',
            action: async () => {
                try {
                    // console.log('tick trackersPainelListAction')

                    dispatch(trackersPainelListAction())


                } catch (e) {
                    // do something with this error
                }
            },
            interval: 300000, //5minutos
            runImmediately: false
        }
    });
};


export const trackersPainelListAction = () => (dispatch, getState) =>
    //export const trackersPainelListAction = () =>
    new Promise(function (resolve, reject) {


        const { painelscreen } = getState().TrackersReducer;



        if (!painelscreen) {

            //resolve([]);
            dispatch({ type: TRACKERS_PAINEL_LOADER, payload: true })

            Api.getAllTrackersPainel().then(dados => {


                if (dados.result.status == 'OK') {

                    dados.paineldata = dados.paineldata.sort(sortBy('status', '-cd_dt'));

                    dispatch({ type: TRACKERS_PAINEL_LIST, payload: [] })
                    dispatch({ type: TRACKERS_PAINEL_LOADER, payload: false })
                    dispatch({ type: TRACKERS_PAINEL_LIST, payload: dados.paineldata })

                    let datasplit = [];
                    let count = 0;
                    let qtde = 12;
                    let obj = { dados: [] };

                    for (let i of dados.paineldata) {
                        obj.dados.push(i);

                        count++
                        if (count == qtde) {
                            count = 0;
                            datasplit.push(obj);
                            obj = { dados: [] };
                        }
                    }
                    if (count > 0 && count < qtde) {
                        datasplit.push(obj);
                    }


                    //for (let i = 0; i < 5; i++) {
                    // Do Stuff
                    //}

                    dispatch({ type: TRACKERS_PAINEL_SPLIT, payload: 0 })
                    dispatch({ type: TRACKERS_PAINEL_LIST_SPLIT, payload: datasplit })


                } else {
                    dispatch({ type: TRACKERS_PAINEL_LOADER, payload: false })
                    dispatch({ type: TRACKERS_PAINEL_LIST, payload: [] })
                }

                resolve(dados)
            }).catch(error => {
                dispatch({ type: TRACKERS_PAINEL_LOADER, payload: false })
                dispatch({ type: TRACKERS_PAINEL_LIST, payload: [] })
                reject(error);
            })


            //let dados = []

            //dados.push({ id: 0, name: 'FSW2753' }, { id: 1, name: 'teste1' }, { id: 2, name: 'ETB5002' }, { id: 3, name: 'teste3' }, { id: 4, name: 'teste4' }, { id: 5, name: 'teste5' }, { id: 6, name: 'GCW3251' }, { id: 7, name: 'GBW2049' }, { id: 8, name: 'teste8' }, { id: 9, name: 'FWR0498' }, { id: 10, name: 'teste10' }, { id: 10, name: 'teste10' });

        } else {
            resolve([]);
        }




    });


export const trackersPainelSplitAction = () => (dispatch, getState) =>
    //export const trackersPainelListAction = () =>
    new Promise(function (resolve, reject) {


        const { paineldatasplit, painelsplit, painelscreen, painelloader } = getState().TrackersReducer;

        if (!painelscreen && !painelloader) {
            //let dados = paineldatasplit;

            //dispatch({ type: TRACKERS_PAINEL_LIST_SPLIT, payload: [{dados:[]}] })

            let total = paineldatasplit.length - 1;
            let posicao = painelsplit;

            if (posicao >= total) {
                posicao = 0;
            } else {
                posicao += 1;
            }

            //dispatch({ type: TRACKERS_PAINEL_LIST_SPLIT, payload: dados })
            dispatch({ type: TRACKERS_PAINEL_SPLIT, payload: posicao })

        }




        resolve([]);


    });



export const trackersPainelScreenAction = (open) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //alert('entrou');
        dispatch({ type: TRACKERS_PAINEL_SCREEN, payload: open });
        resolve([]);
    });

export const trackersListReasonsAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        let reasons = new Array({ key: "0", text: 'Selecione um motivo', value: '0', reasonid: 0 });

        Api.getTrackersLogReasons().then(data => {
            Object.values(data)[0].forEach(element => {
                reasons.push(
                    { key: JSON.stringify(element.reasonid), text: element.reason, value: JSON.stringify(element.reasonid), reasonid: element.reasonid },
                )
            });
        })
        dispatch({ type: TRACKERS_LIST_REASONS, payload: reasons });
        resolve([]);
    });

export const trackersDifferentCarsAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {

        Api.getTrackersDifferentCars().then(dados => {
            dispatch({ type: TRACKERS_DIFFERENT_CARS, payload: dados })
            resolve([])
        })
    }
    );