import React, { useState, useEffect, useRef } from 'react';
import { Modal, Form, Button, Segment, TextArea, Icon, Grid } from 'semantic-ui-react';
import { assistenciaModalClearAction, assistenciaRespostaClienteAction, assistenciaSaveImageVisitaTecnicaAction } from '../../actions/AssistenciaActions';
import StatusDropdown from './StatusDropdown ';
import DropzoneComponent from './Dropzone.js'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import If from '../../utils/If.js'
import ReactToPrint from "react-to-print";

import styled from 'styled-components';


const ImageContainer = styled.div`
  display: inline-block;
  margin: 10px; /* Espaçamento entre as imagens */
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.3);
  width: 230px; /* Largura do container */
  height: 230px; /* Aumentado para incluir o texto */
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;

  img {
    max-width: 100%;
    max-height: 100%; /* Altura da imagem */
    object-fit: contain;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px; /* Altura para ícones */
    width: 100%;
  }

  p {
    margin-top: 5px;
    font-size: 1em;
    word-wrap: break-word;
    max-width: 100%;
    height: 40px; /* Espaço reservado para o texto */
    overflow: hidden;
    text-overflow: ellipsis; /* Adiciona reticências caso o texto seja muito longo */
  }
`;

const ContainerWrapper = styled.div`
  margin-bottom: 10px; // Ajuste o espaço entre o título e os containers
  padding: 20px;
`;

const Label = styled.label`
  display: block;
  font-size: 18px; // Ajuste o tamanho do título
  margin-bottom: 10px; // Espaço entre o título e o primeiro container
  text-align: left; // Alinha o título à esquerda (ou use 'center' se quiser centralizar)
  padding-left: 0;
`;

const TextContainer = styled.div`
  display: flex;
  overflow-x: auto;
  
  & > div {
    flex: 0 0 auto;
    width: 400px;
    margin-right: 10px;
    background-color: #f1f1f1;
    padding: 10px;
    border-radius: 5px;
    cursor: pointer;
  }
`;

const ClearButton = styled.button`
  margin-left:20px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #f44336; /* Vermelho */
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;

  &:hover {
    background-color: #d32f2f; /* Vermelho mais escuro */
  }

  &:focus {
    outline: none;
  }

  &:active {
    background-color: #b71c1c; /* Vermelho mais escuro ao clicar */
  }
`;

const IconWrapper = styled(Icon)`
  margin-right: 8px;
`;

const CharCounter = styled.div`
  position: absolute;
  bottom: 8px;
  right: 10px;
  font-size: 12px;
  color: #666;
`;

// Define o contêiner para os botões no rodapé
const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  padding-top: 120px;
  position: relative;
`;

// Alinha o dropdown de status no canto inferior esquerdo
const StatusDropdownWrapper = styled.div`
  position: absolute;
  bottom: 10px;

`;

// Alinha o botão de fechar no canto inferior direito
const CloseButtonWrapper = styled.div`
  position: absolute;
  bottom: 10px;
  right: 10px;
`;
const PrintOnlyField = styled.p`
    display: none;
    @media print {
        display: block;
        font-size: 12pt;
        margin-top: 20px;
        width: 100%;
        height: 500px;
        border: 1px solid #ccc;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 5px; 
        padding: 20px;
        white-space: pre-wrap;
        overflow-wrap: break-word; 
        overflow: auto;
        page-break-inside: avoid
    }
`;




const PrintOnlyFieldAssinatura = styled.p`
    display: none;
    @media print {
        display: block;
        font-size: 12pt;
        width: 100%;
        height: 100px;
        border: 1px solid #ccc;
        padding: 15px;
        background-color: #f9f9f9;
        border-radius: 5px; 
        padding: 20px;
 
    }
`;
const getStatusColor = (status) => {
    switch (status) {
        case 0:
            return '#6c757d';
        case 1:
            return '#20c997';
        case 2:
            return '#fd7e14';
        case 3:
            return '#383845';
        case 4:
            return '#28a745';
        case 5:
            return '#dc3545';
        default:
            return '#6c757d'; // Caso o status não seja 1, 2, 3 ou 4
    }
};

const StatusLabel = styled.span`
  background-color: ${props => getStatusColor(props.status)};
  color: white;
  padding: 0.2em 0.5em;
  border-radius: 5px;
  font-weight: bold;
`;

const UploadContainer = styled.div`
    padding: 10px;
    border: 2px dashed #ccc;
    border-radius: 5px;
    text-align: center;
    width: 290px;
    cursor: pointer;  // Adiciona o cursor pointer para o dropzone
`;
function AssistenciaModal({ modalOpen, modal, handleModalClose, assistenciaRespostaClienteAction, userid, nomeparc, isLoadginSave, isLoadginSaveImageVisita, assistenciaSaveImageVisitaTecnicaAction }) {

    const uploadRef = useRef(null);
    const [textareaValue, setTextareaValue] = useState('');
    const [selectedStatus, setSelectedStatus] = useState(modal?.DataSource1?.[0].status);
    const [saveError, setSaveError] = useState(false);
    const [openModal, setOpenModal] = useState(false);
    const [modalContent, setModalContent] = useState({}); // Para definir o conteúdo do modal
    const [uploadedFiles, setUploadedFiles] = useState([]);
    const [uploadedFilesVisita, setUploadedFilesVisita] = useState([]);
    const [fileSucess, setFileSucess] = useState('');
    const [uploadError, setUploadError] = useState('');
    const [fileError, setFileError] = useState('');


    useEffect(() => {
        if (modal?.DataSource1?.length > 0) {
            setSelectedStatus(modal.DataSource1[0].status);
        }
    }, [modal]);
    const maxLength = 4000;

    const createImageURL = (buffer) => {
        const blob = new Blob([new Uint8Array(buffer)], { type: 'image/jpeg' }); // Ajuste o tipo de imagem conforme necessário
        return URL.createObjectURL(blob);
    };

    const handleTextClick = (text) => {
        setTextareaValue((prevValue) => {
            // Verifica se a adição do novo texto ultrapassará o limite
            if (prevValue.length + text.length <= maxLength) {
                let datareturn = prevValue ? prevValue + '\n' + text : text
                return datareturn
            } else {
                // Se ultrapassar, trunca o texto para que o limite não seja excedido
                const remainingChars = maxLength - prevValue.length;
                return prevValue + text.substring(0, remainingChars);
            }
        });
    };

    const handleTextareaChange = (e) => {
        setTextareaValue(e.target.value);
    };

    const handleClearText = () => {
        setTextareaValue('');
        setUploadedFiles([]);
    }

    const handleStatusChange = (newStatus) => {
        setSelectedStatus(newStatus);
        // Aqui você pode adicionar lógica para salvar a alteração do status
    };

    const handleSave = () => {
        console.log('selectedStatus:', selectedStatus);
        console.log('status padrao:', modal?.DataSource1?.[0]?.status);
        console.log('Arquivos enviados:', uploadedFiles);

        const statusNaoAlterado = selectedStatus === modal?.DataSource1?.[0]?.status;
        const textoVazio = !textareaValue || textareaValue.trim() === '';

        const statusText = selectedStatus === 0 ? 'Aguardando Análise' :
            selectedStatus === 1 ? 'Em análise' :
                selectedStatus === 2 ? 'Aguardando Cliente' :
                    selectedStatus === 3 ? 'Aguardando Técnico' :
                        selectedStatus === 4 ? 'Procedente' :
                            selectedStatus === 5 ? 'Improcedente' : '';

        if ((selectedStatus === 4 || selectedStatus === 5) && uploadedFiles.length === 0) {
            setUploadError('Anexe o arquivo Laudo da Assistencia para finalizar.')
        } else {
            setUploadError('')
            if (statusNaoAlterado && textoVazio) {
                setSaveError(true);
                setModalContent({
                    title: 'Erro',
                    message: 'Nenhuma alteração feita.',
                    showConfirmButton: false
                });
                setOpenModal(true);
                return;
            }

            let message = '';
            if (!statusNaoAlterado && !textoVazio) {
                message = `Status alterado para: <b>${statusText}</b> <br/><br/>
                   Texto que será enviado ao cliente: <b>${textareaValue}</b>`;
            } else if (!statusNaoAlterado) {
                message = `Status alterado para: <b>${statusText}</b>`;
            } else if (!textoVazio) {
                message = `Texto que será enviado ao cliente: <b>${textareaValue}</b>`;
            }


            setSaveError(false);
            setModalContent({
                title: 'Confirme as Alterações',
                message: message,
                showConfirmButton: true
            });
            setOpenModal(true);
        }
    };

    const confirmSave = () => {
        const data = {
            selectedStatus: selectedStatus,
            textareaValue: textareaValue,
            userid: parseInt(userid),
            nomeparc: nomeparc,
            assistenciaid: modal?.DataSource1?.[0]?.assistencia_id,
            assistencia_tipo_id: modal?.DataSource1?.[0]?.assistencia_tipo_id,
        };
        assistenciaRespostaClienteAction(data, uploadedFiles).then(() => {
            setTextareaValue('');
            setUploadedFiles([]);
            handleModalClose();
        });
        setOpenModal(false);
    };

    const handleFilesChange = (files) => {
        console.log(files);
        setUploadedFiles(files);
    };

    const handleFilesChangeVisitas = (files) => {
        setUploadedFilesVisita(files);
    };

    let componentRef = ''
    let componentRefVisita = ''

    const handleSaveImageVisitaTecnica = () => {
        if (uploadedFilesVisita.length > 0) {
            const data = {
                assistenciaid: modal?.DataSource1?.[0]?.assistencia_id,
                assistencia_tipo_id: modal?.DataSource1?.[0]?.assistencia_tipo_id,
            };
            assistenciaSaveImageVisitaTecnicaAction(data, uploadedFilesVisita).then((dados) => {
                console.log(dados);
                if (dados[0].status == 'OK') {
                    setUploadedFilesVisita([])
                    // Chama a função de limpar arquivos do Dropzone

                    setFileSucess('Arquivos enviados com sucesso!')
                    setTimeout(() => {
                        setFileSucess('')
                        if (uploadRef.current) {
                            uploadRef.current.clearFiles();
                        }
                    }, 3000)
                }
            })
        } else {
            setFileError('Não é possivel salvar sem adicionar ao menos 1 arquivo.')
        }
    }

    return (
        <>
            <div>
                <Modal
                    open={openModal}
                    onClose={() => setOpenModal(false)}
                    size="small"
                >
                    <Modal.Header>{modalContent.title}</Modal.Header>
                    <Modal.Content>
                        <p dangerouslySetInnerHTML={{ __html: modalContent.message }} />
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setOpenModal(false)}>Fechar</Button>
                        {modalContent.showConfirmButton && (
                            <Button onClick={confirmSave} positive>Confirmar</Button>
                        )}
                    </Modal.Actions>
                </Modal>
            </div>
            <Modal
                open={modalOpen}
                onClose={handleModalClose}
                size='large'
                scrolling
            > {modal?.DataSource1?.map((item) => {
                return (
                    <>

                        <div ref={el => (componentRef = el)} style={{
                            maxWidth: '1200px',
                        }}>
                            <Modal.Header>
                                <Modal.Header>
                                    <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%', padding: '15px', lineHeight: '2', fontWeight: '700' }}>
                                        <div>
                                            <span>Protocolo: {item.protocolo}</span>
                                            <br />
                                            <span style={{ fontSize: '16px', color: '#666' }}>
                                                Nº Nota: {item.nunota}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: '16px' }}>
                                                <StatusLabel status={item.status}>
                                                    {item.status === 0 ? 'Aguardando Análise' :
                                                        item.status === 1 ? 'Em análise' :
                                                            item.status === 2 ? 'Aguardando Cliente' :
                                                                item.status === 3 ? 'Aguardando Técnico' :
                                                                    item.status === 4 ? 'Procedente' :
                                                                        item.status === 5 ? 'Improcedente' : ''}
                                                </StatusLabel>
                                            </span>
                                        </div>
                                        <div style={{ textAlign: 'right' }}>
                                            <span>
                                                Data de Criação: {new Date(item.dtcriacao).toLocaleDateString('pt-BR', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                    hour: '2-digit',
                                                    minute: '2-digit',
                                                })}
                                            </span>
                                            <br />
                                            <span style={{ fontSize: '16px', color: '#666' }}>
                                                Prazo: {new Date(item.prazo).toLocaleDateString('pt-BR', {
                                                    day: '2-digit',
                                                    month: '2-digit',
                                                    year: 'numeric',
                                                })}
                                            </span>
                                        </div>
                                    </div>
                                </Modal.Header>
                            </Modal.Header>
                            <Modal.Content
                                style={{
                                    maxWidth: '100%', // Ajusta o conteúdo para o tamanho do modal
                                    overflowWrap: 'break-word' // Quebra palavras longas
                                }}>
                                <div ref={el => (componentRefVisita = el)} style={{
                                    maxWidth: '1200px',
                                }}>
                                    <Form>
                                        <Segment>
                                            <span style={{ fontSize: '23px' }}>Produto: <b>{item.produto}</b> ({item.fabricante}) </span>
                                            <Form.Group style={{ fontSize: '14px', marginTop: '15px' }} widths='equal'>
                                                <Form.Field>
                                                    <label>Nome/Codparc</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='Nome'
                                                        name='nome'
                                                        label={item.nomeparc + ' ' + '(' + item.codparc + ')'}
                                                        readOnly
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Telefone</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='Telefone'
                                                        name='telefone'
                                                        label={item.telefone}
                                                        readOnly
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Email</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='Email'
                                                        name='email'
                                                        label={item.email}
                                                        readOnly
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>CPF/CNPJ</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='CPF'
                                                        name='cpf'
                                                        label={item.cpf}
                                                        readOnly
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Cidade</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='Cidade'
                                                        name='cidade'
                                                        label={item.cidade}
                                                        readOnly
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Bairro</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='Bairro'
                                                        name='bairro'
                                                        label={item.bairro}
                                                        readOnly
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Endereço</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='Endereço'
                                                        name='endereco'
                                                        label={item.endereco}
                                                        readOnly
                                                    />
                                                </Form.Field>
                                            </Form.Group>




                                            {/* item.assistencia_tipo === 1 Piso */}
                                            <If test={item.assistencia_tipo === 1}>
                                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Dados do instalador:</span>
                                                <Grid style={{ marginTop: '1px', width: '100%' }}>
                                                    <Grid.Column>
                                                        <Form.Group widths='4'>
                                                            <Form.Field>
                                                                <label>Nome.</label>
                                                                <Form.Field
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                        wordWrap: 'break-word',
                                                                        overflowWrap: 'break-word',
                                                                        whiteSpace: 'pre-wrap',
                                                                        maxWidth: '100%',
                                                                        boxSizing: 'border-box'
                                                                    }}
                                                                    fluid
                                                                    placeholder='inst_nome'
                                                                    name='inst_nome'
                                                                    label={item.inst_nome}
                                                                    readOnly

                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Endereço.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='inst_end'
                                                                    name='inst_end'
                                                                    label={item.inst_end}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                        wordWrap: 'break-word',
                                                                        overflowWrap: 'break-word',
                                                                        whiteSpace: 'pre-wrap',
                                                                        maxWidth: '100%',
                                                                        boxSizing: 'border-box'
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Bairro.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='inst_bairro'
                                                                    name='inst_bairro'
                                                                    label={item.inst_bairro}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                        wordWrap: 'break-word',
                                                                        overflowWrap: 'break-word',
                                                                        whiteSpace: 'pre-wrap'
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Cidade.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='inst_cidade'
                                                                    name='inst_cidade'
                                                                    label={item.inst_cidade}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                        wordWrap: 'break-word',
                                                                        overflowWrap: 'break-word',
                                                                        whiteSpace: 'pre-wrap'
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid style={{ marginTop: '-25px' }}>
                                                    <Grid.Column>
                                                        <Form.Group widths='equal'>
                                                            <Form.Field>
                                                                <label>CPF.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='inst_cpf'
                                                                    name='inst_cpf'
                                                                    label={item.inst_cpf}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Telefone.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='inst_telefone'
                                                                    name='inst_telefone'
                                                                    label={item.inst_telefone}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Email.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='inst_email'
                                                                    name='inst_email'
                                                                    label={item.inst_email}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                        wordWrap: 'break-word',
                                                                        overflowWrap: 'break-word',
                                                                        whiteSpace: 'pre-wrap'
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>Data da Instalação.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='dt_instalacao'
                                                                    name='dt_instalacao'
                                                                    label={new Date(item.dt_instalacao).toLocaleDateString('pt-BR', {
                                                                        year: 'numeric',
                                                                        month: '2-digit',
                                                                        day: '2-digit',
                                                                    })}
                                                                    readOnly
                                                                    style={{
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid>
                                                <hr></hr>
                                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Respostas do questionario:</span>
                                                <Grid style={{ marginTop: '1px' }}>
                                                    <Grid.Column>
                                                        <Form.Group widths='equal'>
                                                            <Form.Field>
                                                                <label>1. Local em que o piso está instalado.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quesst_resp1'
                                                                    name='quest_resp1'
                                                                    label={item.quest_resp1}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>2. Tem umidade no local?</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp2'
                                                                    name='quest_resp2'
                                                                    label={item.quest_resp2}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>3. Preparação do contra-piso</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp3'
                                                                    name='quest_resp3'
                                                                    label={item.quest_resp3}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid>

                                                <Grid style={{ marginTop: '1px' }}>
                                                    <Grid.Column>
                                                        <Form.Group widths='equal'>
                                                            <Form.Field>
                                                                <label>4. Os recortes/amarração estão de acordo com as normas? (para pisos clicados)</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp4'
                                                                    name='quest_resp4'
                                                                    label={item.quest_resp4}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                {/* usando caractere invisivel para manter todos os campos na mesma linha */}
                                                                <label>5. Foram instalados móveis planejados sobre o piso? ‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎  </label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp5'
                                                                    name='quest_resp5'
                                                                    label={item.quest_resp5}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>6. Foram instalados perfis de dilatação/transição entre ambientes? (para pisos laminados)</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp6'
                                                                    name='quest_resp6'
                                                                    label={item.quest_resp6}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid>
                                                <Grid style={{ marginTop: '1px' }}>
                                                    <Grid.Column>
                                                        <Form.Group widths='2'>
                                                            <Form.Field>
                                                                <label>7. Foram instalados perfis de dilatação/transição para área acima de 8mts? (para pisos laminados)</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp7'
                                                                    name='quest_resp7'
                                                                    label={item.quest_resp7}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                {/* usando caractere invisivel para manter todos os campos na mesma linha */}
                                                                <label>8. Como é feita a manutenção (limpeza) do piso? ‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎‎ ‎ ‎ ‎‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎ ‎</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp8'
                                                                    name='quest_resp8'
                                                                    label={item.quest_resp8}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>

                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid>
                                            </If>


                                            {/* item.assistencia_tipo === 2 Forros e Perfis */}
                                            <If test={item.assistencia_tipo === 2}>
                                                <span style={{ fontSize: '16px', fontWeight: 'bold' }}>Respostas do questionario:</span>
                                                <Grid style={{ marginTop: '1px' }}>
                                                    <Grid.Column>
                                                        <Form.Group widths='equal'>
                                                            <Form.Field>
                                                                <label>1. Local em que está instalado.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quesst_resp1'
                                                                    name='quest_resp1'
                                                                    label={item.quest_resp1}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>2. Tipo de Cobertura</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp2'
                                                                    name='quest_resp2'
                                                                    label={item.quest_resp2}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid>
                                            </If>

                                            {/* item.assistencia_tipo === 4 Rodapé/Cola/Painel/Deck */}
                                            <If test={item.assistencia_tipo === 4 && item.quest_resp1 !== null}>
                                                <Grid>
                                                    <Grid.Column>
                                                        <Form.Group widths='equal'>
                                                            <Form.Field>
                                                                <label>1. Local em que está instalado.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp1'
                                                                    name='quest_resp1'
                                                                    label={item.quest_resp1}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>2. Tem umidade no local?</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp2'
                                                                    name='quest_resp2'
                                                                    label={item.quest_resp2}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                            <Form.Field>
                                                                <label>3. Método de instalação.</label>
                                                                <Form.Field
                                                                    fluid
                                                                    placeholder='quest_resp3'
                                                                    name='quest_resp3'
                                                                    label={item.quest_resp3}
                                                                    readOnly
                                                                    style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                                        padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                                    }}
                                                                />
                                                            </Form.Field>
                                                        </Form.Group>
                                                    </Grid.Column>
                                                </Grid>
                                                <Form.Field>
                                                    <label>4. Como é feita a manutenção(limpeza)?</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='quest_resp4'
                                                        name='quest_resp4'
                                                        label={item.quest_resp4}
                                                        readOnly
                                                        style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                            border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                            padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                            backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                            borderRadius: '5px',      // Arredonda levemente os cantos
                                                        }}
                                                    />
                                                </Form.Field>
                                            </If>

                                            <Form.Group widths='equal'>
                                                <Form.Field>
                                                    <label>Reclamação do cliente:</label>
                                                    <Form.Field
                                                        fluid
                                                        placeholder='reclamação'
                                                        name='reclamação'
                                                        label={item.observacao}
                                                        readOnly
                                                        style={{        // Define a altura do campo para melhorar a visualização de longos textos
                                                            border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                            padding: '15px',          // Espaçamento interno para melhorar a legibilidade
                                                            backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                            borderRadius: '5px',      // Arredonda levemente os cantos
                                                        }}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                        </Segment>
                                    </Form>
                                    <If test={modal?.DataSource1?.[0].status !== 4 && modal?.DataSource1?.[0].status !== 5}>
                                        <PrintOnlyField>
                                            <p>Observações da visita Tecnica.</p>
                                        </PrintOnlyField>
                                        <PrintOnlyFieldAssinatura>
                                            <p>Assinatura do Cliente:</p>
                                            <p>____________________________________</p>
                                        </PrintOnlyFieldAssinatura>
                                    </If>

                                </div>

                                <If test={modal?.DataSource1?.[0].status !== 4 && modal?.DataSource1?.[0].status !== 5}>
                                    <div style={{ marginLeft: '20px' }}>
                                        <ReactToPrint
                                            trigger={() => <Button color='orange'>Imprimir Visita</Button>}
                                            content={() => componentRefVisita}
                                        // onAfterPrint={(e) => this.props.handlePrint(this.props.datalist)}
                                        />
                                    </div>
                                </If >


                                <Form>
                                    <Segment>


                                        <Form.Group style={{ marginTop: '10px' }} widths='equal'>
                                            <Form.Field>
                                                <label>Anexos:</label>
                                                <div style={{ display: 'flex', flexDirection: 'column' }}>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {modal.DataSource2.map((foto, index) => (
                                                            foto && foto.type === 'video/mp4' ? (
                                                                <ImageContainer key={index}>
                                                                    <video width="200" height="150" controls>
                                                                        <source src={foto.arquivo} type="video/mp4" />
                                                                        Seu navegador não suporta o elemento de vídeo.
                                                                    </video>
                                                                    <p>{foto.nomearq}</p>
                                                                </ImageContainer>
                                                            ) : foto.type === 'application/pdf' ? (
                                                                <ImageContainer key={index}>
                                                                    <a src={foto.arquivo} href={foto.arquivo} target="_blank" rel="noopener noreferrer" className="icon-container">
                                                                        <Icon name="file pdf outline" size="huge" color="red" />
                                                                    </a>
                                                                    <p>{foto.nomearq}</p>
                                                                </ImageContainer>
                                                            ) : foto.type === 'video/quicktime' ? (
                                                                <ImageContainer key={index}>
                                                                    <p>Baixe o Video:</p>
                                                                    <a src={foto.arquivo} href={foto.arquivo} target="_blank" rel="noopener noreferrer" className="icon-container">
                                                                        <Icon name="file video outline" size="huge" color="red" />
                                                                    </a>
                                                                    <p>{foto.nomearq}</p>
                                                                </ImageContainer>
                                                            ) : ''
                                                        ))}
                                                    </div>
                                                    <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                                        {modal.DataSource2.map((foto, index) => (
                                                            foto && foto.type !== 'application/pdf' && foto.type !== 'video/mp4' && foto.type !== 'video/quicktime' && (
                                                                <ImageContainer key={index}>
                                                                    <a href={foto.arquivo} target="_blank" rel="noopener noreferrer">
                                                                        <img src={foto.arquivo} alt={foto.nomearq} />
                                                                    </a>
                                                                    <p>{foto.nomearq}</p>
                                                                </ImageContainer>
                                                            )
                                                        ))}
                                                    </div>
                                                </div>
                                            </Form.Field>

                                        </Form.Group>

                                        <Form.Field>
                                            <label>Adicione Imagens da Visita Técnica.</label>
                                            <DropzoneComponent
                                                ref={uploadRef}
                                                accept={{
                                                    'image/png': ['.png'],
                                                    'image/jpeg': ['.jpeg'],
                                                    'image/jpg': ['.jpg'],
                                                    'application/pdf': ['.pdf'],
                                                    'video/mp4': ['.mp4'],
                                                    'video/quicktime': ['.mov'],
                                                    // Adicione outros formatos conforme necessário
                                                }}
                                                onFilesChange={handleFilesChangeVisitas}
                                            />
                                            <div style={{ marginTop: '15px' }}>
                                                <Button color='blue' onClick={handleSaveImageVisitaTecnica} loading={isLoadginSaveImageVisita}>Salvar Imagens</Button>
                                            </div>
                                            {fileError && <p style={{ color: 'red' }}>{fileError}</p>}
                                            {fileSucess && <p style={{ color: 'green' }}>{fileSucess}</p>}

                                        </Form.Field>
                                    </Segment>



                                    <If test={modal?.DataSource1?.[0].status !== 4 && modal?.DataSource1?.[0].status !== 5}>
                                        <Form.Group widths='equal' style={{ padding: '20px' }}>
                                            <Form.Field>
                                                <label>Histórico de conversa:</label>
                                                <Form.Field
                                                    fluid
                                                    dangerouslySetInnerHTML={{ __html: modal?.DataSource1?.[0].historico }}
                                                    readOnly
                                                    style={{
                                                        marginTop: '10px',          // Define a altura do campo para melhorar a visualização de longos textos
                                                        border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                        padding: '10px',          // Espaçamento interno para melhorar a legibilidade
                                                        backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                        whiteSpace: 'pre-wrap',   // Preserva as quebras de linha
                                                        borderRadius: '5px',      // Arredonda levemente os cantos
                                                    }}
                                                />
                                            </Form.Field>
                                        </Form.Group>
                                    </If>

                                    <If test={modal?.DataSource1?.[0].status !== 4}>
                                        <If test={modal?.DataSource1?.[0].status !== 5}>
                                            <ContainerWrapper>
                                                <Label>Templates:</Label>
                                                <TextContainer>
                                                    <div>
                                                        <Label><b>Negativa de defeito:</b></Label>
                                                        <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nAgradecemos por nos contatar. Gostaríamos de esclarecer que a garantia do produto cobre defeitos de fabricação. Com base nas informações fornecidas, não identificamos indícios de um defeito de fabricação. No entanto, se você observar qualquer desacordo com o material conforme relatado, recomendamos que solicite a troca ou devolução do produto para que possamos resolver a questão adequadamente.\n\n Atenciosamente,`.replace(/<br\/?>/g, '\n'))}>
                                                            Prezado(a) {item.nomeparc},<br /><br />
                                                            Agradecemos por nos contatar. Gostaríamos de esclarecer que a garantia do produto cobre defeitos de fabricação. Com base nas informações fornecidas, não identificamos indícios de um defeito de fabricação. No entanto, se você observar qualquer desacordo com o material conforme relatado, recomendamos que solicite a troca ou devolução do produto para que possamos resolver a questão adequadamente.<br /><br />
                                                            Atenciosamente,<br />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Label><b>Negativa de coleta:</b></Label>
                                                        <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nApós a realização da instalação, confirmamos que o material está em conformidade com as especificações e apto para uso. Portanto, não é possível proceder com a coleta e troca do produto neste momento.\n\n Atenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                            Prezado(a) {item.nomeparc},<br /><br />
                                                            Após a realização da instalação, confirmamos que o material está em conformidade com as especificações e apto para uso. Portanto, não é possível proceder com a coleta e troca do produto neste momento.<br /><br />
                                                            Atenciosamente,<br />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Label><b>Negativa de Tonalidade:</b></Label>
                                                        <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nEste chamado não se qualifica para assistência técnica. As cores exibidas nas fotos do site podem variar de acordo com a tela e a resolução de cada dispositivo, servindo apenas como referência visual. Assim, este chamado é considerado improcedente, pois não envolve defeitos de fabricação..\n\n Atenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                            Prezado(a) {item.nomeparc},<br /><br />
                                                            Este chamado não se qualifica para assistência técnica. As cores exibidas nas fotos do site podem variar de acordo com a tela e a resolução de cada dispositivo, servindo apenas como referência visual. Assim, este chamado é considerado improcedente, pois não envolve defeitos de fabricação.<br /><br />
                                                            Atenciosamente,<br />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Label><b>Negativa Mau Uso:</b></Label>
                                                        <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nLamentamos o seu descontentamento. Informamos que seguimos rigorosamente as normas do CDC e da ABNT para emissão e conclusão dos processos de assistência técnica. No entanto, constatamos que o produto foi utilizado fora das orientações do fabricante, o que impossibilita a cobertura pelo serviço de assistência.\n\n Atenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                            Prezado(a) {item.nomeparc},<br /><br />
                                                            Lamentamos o seu descontentamento. Informamos que seguimos rigorosamente as normas do CDC e da ABNT para emissão e conclusão dos processos de assistência técnica. No entanto, constatamos que o produto foi utilizado fora das orientações do fabricante, o que impossibilita a cobertura pelo serviço de assistência.<br /><br />
                                                            Atenciosamente,<br />
                                                        </div>
                                                    </div>


                                                    <div>
                                                        <Label><b>Confirmação de vistoria não recebida:</b></Label>
                                                        <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nInformamos que, como não recebemos a confirmação para a vistoria conforme solicitado no e-mail anterior, estamos encerrando o atendimento referente a este chamado.\n\nSe precisar de assistência adicional ou desejar reabrir o chamado no futuro, por favor, entre em contato conosco.\n\n Atenciosamente,  `.replace(/<br\/?>/g, '\n'))}>
                                                            Prezado(a) {item.nomeparc},<br /><br />
                                                            Informamos que, como não recebemos a confirmação para a vistoria conforme solicitado no e-mail anterior, estamos encerrando o atendimento referente a este chamado.<br /><br />
                                                            Se precisar de assistência adicional ou desejar reabrir o chamado no futuro, por favor, entre em contato conosco.<br /><br />
                                                            Atenciosamente,
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Label><b>Certificado :</b></Label>
                                                        <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nInformamos que todos os produtos possuem certificado de garantia onde constam as informações e classificações de uso.\n\n Atenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                            Prezado(a) {item.nomeparc},<br /><br />
                                                            Informamos que todos os produtos possuem certificado de garantia onde constam as informações e classificações de uso.<br /><br />
                                                            Atenciosamente,<br />
                                                        </div>
                                                    </div>

                                                    <div>
                                                        <Label><b>Treinamento :</b></Label>
                                                        <div onClick={() => handleTextClick(`Prezado(a) ${item.nomeparc},\n\nDisponibilizamos de treinamento para equipe de vendas e instaladores, com agendamento prévio, afim e sanar dúvidas e orientar sobre a utilização de cada produtos\n\n Atenciosamente, `.replace(/<br\/?>/g, '\n'))}>
                                                            Prezado(a) {item.nomeparc},<br /><br />
                                                            Disponibilizamos de treinamento para equipe de vendas e instaladores, com agendamento prévio, afim e sanar dúvidas e orientar sobre a utilização de cada produtos<br /><br />
                                                            Atenciosamente,<br />
                                                        </div>
                                                    </div>
                                                </TextContainer>
                                            </ContainerWrapper>

                                            <Form.Group style={{ padding: '20px' }} widths='equal'>
                                                <Form.Field control={TextArea} label='Resposta:'
                                                    placeholder='Digite ou selecione um Template para responder o cliente...'
                                                    onChange={handleTextareaChange}
                                                    value={textareaValue}
                                                    maxLength={4000}
                                                    rows='9'
                                                />
                                                <CharCounter>
                                                    {textareaValue.length}/{maxLength}
                                                </CharCounter>
                                            </Form.Group>
                                            <ClearButton onClick={handleClearText}>
                                                <IconWrapper name='trash' /> Limpar
                                            </ClearButton>
                                        </If>
                                    </If>
                                    <If test={modal?.DataSource1?.[0].status === 4 || modal?.DataSource1?.[0].status === 5}>
                                        <Segment>


                                            <Form.Group>
                                                <Form.Field>
                                                    <label>Histórico de conversa:</label>
                                                    <Form.Field
                                                        fluid
                                                        dangerouslySetInnerHTML={{ __html: modal?.DataSource1?.[0].historico }}
                                                        readOnly
                                                        style={{
                                                            width: '680px',          // Define a altura do campo para melhorar a visualização de longos textos
                                                            border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                            padding: '10px',          // Espaçamento interno para melhorar a legibilidade
                                                            backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                            whiteSpace: 'pre-wrap',   // Preserva as quebras de linha
                                                            borderRadius: '5px',      // Arredonda levemente os cantos
                                                        }}
                                                    />
                                                </Form.Field>
                                                <Form.Field>
                                                    <label>Histórico de status:</label>
                                                    <Form.Field
                                                        fluid
                                                        dangerouslySetInnerHTML={{ __html: modal?.DataSource1?.[0].historico_status }}
                                                        readOnly
                                                        style={{
                                                            border: '1px solid #ccc', // Adiciona uma borda leve para delimitar o campo
                                                            padding: '10px',          // Espaçamento interno para melhorar a legibilidade
                                                            backgroundColor: '#f9f9f9', // Um fundo suave para separar visualmente do resto do layout
                                                            whiteSpace: 'pre-wrap',   // Preserva as quebras de linha
                                                            borderRadius: '5px',      // Arredonda levemente os cantos
                                                        }}
                                                    />
                                                </Form.Field>
                                            </Form.Group>
                                        </Segment>
                                    </If>
                                </Form>
                            </Modal.Content >
                        </div >
                    </>
                );
            })}
                <Modal.Content>
                    <FooterContainer>
                        <StatusDropdownWrapper>
                            <Form.Field>
                                <label>Status:</label>
                                <StatusDropdown onFilesChange={handleFilesChange} value={selectedStatus} onChange={handleStatusChange} status={modal?.DataSource1?.[0]?.status} />
                                <label>{uploadError}</label>
                            </Form.Field>
                        </StatusDropdownWrapper>
                        <CloseButtonWrapper>
                            <Button color='red' onClick={() => {
                                handleClearText();
                                handleModalClose();
                            }}
                            >
                                <Icon name='close' /> Fechar
                            </Button>
                            <If test={modal?.DataSource1?.[0].status !== 4 && modal?.DataSource1?.[0].status !== 5}>
                                <Button color='green' onClick={handleSave} loading={isLoadginSave}>
                                    <Icon name='checkmark' /> Salvar
                                </Button>
                            </If>
                            <If test={modal?.DataSource1?.[0].status === 4 || modal?.DataSource1?.[0].status === 5}>
                                <ReactToPrint
                                    trigger={() => <Button color='green'>Imprimir</Button>}
                                    content={() => componentRef}

                                // onAfterPrint={(e) => this.props.handlePrint(this.props.datalist)}
                                />

                            </If>
                        </CloseButtonWrapper>
                    </FooterContainer>
                </Modal.Content>
            </Modal >
        </>
    );
}

const mapStateToProps = (state) => ({
    nomeparc: state.LoginReducer.nomeparc,
    userid: state.LoginReducer.userid,
    isLoadginSave: state.AssistenciaReducer.isLoadginSave,
    isLoadginSaveImageVisita: state.AssistenciaReducer.isLoadginSaveImageVisita

})


export default withRouter(connect(mapStateToProps, {
    assistenciaModalClearAction,
    assistenciaRespostaClienteAction,
    assistenciaSaveImageVisitaTecnicaAction
}
)(AssistenciaModal));