import React, { useState, useEffect } from 'react';
import { Modal, ModalContent, Button, Label, Input, Icon, Dropdown, Form } from 'semantic-ui-react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import { NumericFormat } from 'react-number-format';
import DatePicker from 'react-datepicker';
import { marketplacesDevoluçõesEditAction, marketplacesDevoluçõesAnaliseAction, marketplacesDevoluçõesListAction } from './../../../actions/MarketPlacesActions';
import styled from 'styled-components';
import FolhaDevolucao from '../FolhaDevolução';
import ReactToPrint from "react-to-print";


const ImageContainer = styled.div`
  display: inline-block;
  margin: 10px; /* Espaçamento entre as imagens */
  text-align: center;
  cursor: pointer;
  border: 1px solid rgba(0,0,0,0.3);
  width: 230px; /* Largura do container */
  height: 230px; /* Aumentado para incluir o texto */
  overflow: hidden;
  padding: 10px;
  border-radius: 5px;

  img {
    max-width: 100%;
    max-height: 100%; /* Altura da imagem */
    object-fit: contain;
  }

  .icon-container {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 120px; /* Altura para ícones */
    width: 100%;
  }

  p {
    margin-top: 5px;
    font-size: 1em;
    word-wrap: break-word;
    max-width: 100%;
    height: 40px; /* Espaço reservado para o texto */
    overflow: hidden;
    text-overflow: ellipsis; /* Adiciona reticências caso o texto seja muito longo */
  }
`;

const PrintOnlyFieldAssinatura = styled.p`
    display: none;
    @media print {
        display: block;
        width: 100%;
        border: 1px solid #ccc;
        padding: 20px;
 
    }
`;

function ModalAnalisar({ open, onClose, devolucaoAnalisar, marketplacesDevoluçõesEditAction, userid, marketplacesDevoluçõesAnaliseAction, marketplacesDevoluçõesListAction, isLoadingEdit }) {
    const [isEditing, setIsEditing] = useState(false);
    const [formValues, setFormValues] = useState([]);
    const [originalValues, setOriginalValues] = useState([]);
    const [selectedDate, setSelectedDate] = useState(null);



    const stateOptions = [
        { text: 'Problema Esplane', value: 'Problema Esplane' },
        { text: 'Problema Cliente', value: 'Problema Cliente' },
        { text: 'Problema Terceiros', value: 'Problema Terceiros' }
    ];


    useEffect(() => {
        if (devolucaoAnalisar && devolucaoAnalisar.length > 0) {
            // Converter `vencimento` para objetos Date
            const convertedData = devolucaoAnalisar.map((item) => ({
                ...item,
                vencimento: item.vencimento ? new Date(item.vencimento) : null,
            }));
            setFormValues(convertedData);
            setOriginalValues(convertedData); // Guardar os valores originais
        }
    }, [devolucaoAnalisar]);

    const handleInputChange = (index, field, value) => {
        const updatedValues = [...formValues];
        updatedValues[index][field] = value;
        setFormValues(updatedValues);
    };

    const formatarDataBrasileira = (data) => {
        if (!data) return null; // Caso a data não seja válida
        const dia = String(data.getDate()).padStart(2, '0'); // Obtém o dia com dois dígitos
        const mes = String(data.getMonth() + 1).padStart(2, '0'); // Mês (0-11) ajustado para 1-12
        const ano = data.getFullYear(); // Obtém o ano completo
        return `${dia}/${mes}/${ano}`;
    };


    const handleClose = () => {
        let pagination = {
            page: 1,
            limit: 10
        }
        marketplacesDevoluçõesListAction(pagination)
        onClose()
    }

    const handleConcluirEdicao = () => {

        const valorSemMascara = formValues[0].valor?.replace(/[^\d,.-]/g, '').replace('.', '').replace(',', '.');

        // Converte para número
        const valorNumerico = parseFloat(valorSemMascara);

        let data = {
            nomeparc: formValues[0].nomeparc,
            valor: parseFloat(valorNumerico),
            vencimento: formatarDataBrasileira(selectedDate),
            relato: formValues[0].relato,
            motivo: formValues[0].motivo,
            devolucaoid: formValues[0].devolucaoid,
            nufin: formValues[0].nufin,
            depositante: formValues[0].depositante,
            user: userid
        }
        marketplacesDevoluçõesEditAction(data).then((dados) => {
            marketplacesDevoluçõesAnaliseAction(formValues[0].devolucaoid)
        })
        console.log("Valores finais do formulário:", data);
        setIsEditing(false);
    };

    const CustomInput = React.forwardRef(({ value, onClick }, ref) => (
        <Input
            style={{ width: '100%' }}
            onClick={onClick}
            value={value}
            ref={ref}
            placeholder="Selecione uma data"
        />
    ));




    let componentRefDevolucao = '';

    let error = true;
    return (
        <>
            <Modal open={open} closeIcon>
                <ModalContent>
                    {formValues.map((item, index) => (
                        <div
                            key={index}
                            style={{ display: 'flex', flexDirection: 'column', gap: '1.5rem', marginBottom: '1rem' }}
                        >
                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {isEditing ? (
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            Nomeparc:
                                            <Input
                                                style={{ flex: 1, marginRight: '1rem' }}
                                                value={item.nomeparc}
                                                onChange={(e) => handleInputChange(index, 'nomeparc', e.target.value)}
                                                placeholder="Parceiro"
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            Valor:
                                            <NumericFormat
                                                style={{ flex: 1, marginRight: '1rem' }}
                                                prefix="R$ "
                                                decimalSeparator=","
                                                thousandSeparator="."
                                                decimalScale={2}
                                                fixedDecimalScale
                                                allowNegative={false}
                                                placeholder="R$ 0,00"
                                                customInput={Input}
                                                value={item.valor}
                                                onChange={(e) => handleInputChange(index, 'valor', e.target.value)}
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            <Form>
                                                <Form.Field>
                                                    <label>Vencimento:</label>
                                                    <DatePicker
                                                        selected={item.vencimento}
                                                        onChange={(date) => {
                                                            handleInputChange(index, 'vencimento', date); // Atualiza no `formValues`
                                                            setSelectedDate(date); // Atualiza o estado específico para a data selecionada
                                                        }}
                                                        isClearable
                                                        todayButton="Hoje"
                                                        name="dtfinal"
                                                        id="dtfinal"
                                                        dateFormat="dd/MM/yyyy"
                                                        customInput={<CustomInput />}
                                                        className={error ? "datepicker-error" : "datepicker"} // Classe condicional para erro
                                                    />
                                                </Form.Field>
                                            </Form>

                                        </div>

                                    </>
                                ) : (
                                    <>
                                        <Label style={{ flex: 1, textAlign: 'center' }}>Parceiro: {item.nomeparc}</Label>
                                        <Label style={{ flex: 1, textAlign: 'center' }}>
                                            Valor: {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}
                                        </Label>
                                        <Label style={{ flex: 1, textAlign: 'center' }}>
                                            Vencimento: {new Date(item.vencimento).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            })}
                                        </Label>
                                    </>
                                )}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {isEditing ? (
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            Número Único(Financeiro):
                                            <Input
                                                style={{ flex: 1, marginRight: '1rem' }}
                                                value={item.nufin}
                                                onChange={(e) => handleInputChange(index, 'nufin', e.target.value)}
                                                placeholder="Número Único"
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            Depositante:
                                            <Input
                                                style={{ flex: 1, marginRight: '1rem' }}
                                                value={item.depositante}
                                                onChange={(e) => handleInputChange(index, 'depositante', e.target.value)}
                                                placeholder="depositante"
                                            />
                                        </div>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            Ocorrência:
                                            <Dropdown
                                                style={{ flex: 1, marginRight: '1rem' }}
                                                selection
                                                defaultValue={item.relato}
                                                value={item.relato}
                                                options={stateOptions}
                                                fluid
                                                placeholder="Ocorrência"
                                                onChange={(e, data) => handleInputChange(index, 'relato', data.value)}
                                            />
                                        </div>



                                    </>
                                ) : (
                                    <>
                                        <Label bold style={{ flex: 1, textAlign: 'center' }}>
                                            Número Único(financeiro): {item.nufin}
                                        </Label>
                                        <Label style={{ flex: 1, textAlign: 'center' }}>
                                            <span style={{ fontWeight: 'bold' }}>Ocorrência:</span> {item.relato}
                                        </Label>
                                        <Label style={{ flex: 1, textAlign: 'center' }}><b>Depositante:</b> {item.depositante}</Label>
                                    </>
                                )}
                            </div>

                            <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }}>
                                {isEditing ? (
                                    <>
                                        <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                            Motivo:
                                            <textarea
                                                style={{
                                                    flex: 1,
                                                    marginRight: '1rem',
                                                    resize: 'none',
                                                    minHeight: '100px',
                                                }}
                                                value={item.motivo}
                                                onChange={(e) => handleInputChange(index, 'motivo', e.target.value)}
                                                placeholder="Motivo"
                                                maxLength={3500}
                                            />

                                        </div>

                                    </>
                                ) : (
                                    <><div style={{ display: 'flex', flexDirection: 'column' }}>
                                        Motivo:
                                        <Label
                                            style={{
                                                flex: 1,
                                                textAlign: 'left',
                                                whiteSpace: 'normal', // Permite quebra de linha
                                                overflowWrap: 'break-word', // Quebra palavras longas
                                                wordBreak: 'break-word',    // Alternativa para navegadores modernos
                                                maxWidth: '100%',           // Garante que o texto respeite os limites do contêiner
                                            }}
                                        >
                                            {item.motivo}
                                        </Label>
                                        <div style={{ marginTop: '15px' }}>
                                            <p>Devolução criada por {item.usuario} em {new Date(item.dtinsert).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                            })}</p>
                                        </div>

                                    </div>

                                    </>
                                )}
                            </div>
                        </div>
                    ))}

                    <div style={{ display: 'flex', justifyContent: 'flex-end', marginTop: '2rem' }}>
                        {isEditing ? '' :
                            <>
                                <Button color="blue" onClick={() => setIsEditing(!isEditing)}>
                                    Editar
                                </Button>
                                <ReactToPrint
                                    trigger={() => <Button color='yellow'>Imprimir</Button>}
                                    content={() => componentRefDevolucao}
                                // onAfterPrint={(e) => this.props.handlePrint(this.props.datalist)}
                                />
                            </>

                        }
                        {isEditing && (
                            <>
                                <Button color="green" onClick={handleConcluirEdicao} loading={isLoadingEdit}>
                                    Concluir Edição
                                </Button>
                                <Button color="red" onClick={() => setIsEditing(!isEditing)} disabled={isLoadingEdit}>
                                    Fechar
                                </Button>
                            </>
                        )}
                    </div>

                    {formValues.map((item, index) => (
                        <div>
                            {isEditing ? '' :
                                <>
                                    <p>Histórico:</p><ImageContainer key={index}>
                                        <a src={item.url} href={item.url} target="_blank" rel="noopener noreferrer" className="icon-container">
                                            <Icon name="file pdf outline" size="huge" color="red" />
                                        </a>
                                        <p>{item.nomearq}</p>
                                    </ImageContainer>
                                </>
                            }
                        </div>
                    ))}


                    {isEditing ? '' :
                        <Button negative onClick={handleClose}>Fechar</Button>
                    }
                </ModalContent>
            </Modal>
            <div ref={el => (componentRefDevolucao = el)} >
                <PrintOnlyFieldAssinatura>
                    <FolhaDevolucao />
                </PrintOnlyFieldAssinatura>

            </div>
        </>
    );
}

const mapStateToProps = (state) => ({
    devolucaoAnalisar: state.MarketPlacesReducer.devolucaoAnalisar,
    userid: state.LoginReducer.userid,
    isLoadingEdit: state.MarketPlacesReducer.isLoadingEdit

});

export default withRouter(connect(mapStateToProps, {
    marketplacesDevoluçõesEditAction,
    marketplacesDevoluçõesAnaliseAction,
    marketplacesDevoluçõesListAction
})(ModalAnalisar));
