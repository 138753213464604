import {
    CONSULTORES_LIST_FECHAMENTO
    , CONSULTORES_LOADER_FECHAMENTO

    , CONSULTORES_LIST_FECHAMENTO90
    , CONSULTORES_LOADER_FECHAMENTO90
    , CONSULTORES_UPDATE_BUSCA
    , CONSULTORES_VALIDACAO_BUSCA_ADD
    , CONSULTORES_VALIDACAO_BUSCA_REMOVE
    , CONSULTORES_ISSEARCHING_BUSCA
    , CONSULTORES_LIST
    , CONSULTORES_LIST_SEARCH
    , CONSULTORES_LIST_CIDADES
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';



export const consultoresListCidadesAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllCidades().then(dados => {

            //Cidades
            let cidades = []
            cidades.push({ key: "0", text: `Todas`, value: "0" })
            if (dados.length > 0) {
                for (let c of dados) {
                    cidades.push({ key: c.codcid.toString(), text: `${c.uf} -(${c.nomecid})`, value: c.codcid.toString() })
                }
            }

            dispatch({ type: CONSULTORES_LIST_CIDADES, payload: cidades })
            resolve([])
        })
    }
)

export const consultoresListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllConsultores().then(dados => {

            //Consultores
            let consultores = []
            consultores.push({ key: "0", text: `Todos`, value: "0" })
            if (dados.length > 0) {
                for (let c of dados) {
                    consultores.push({ key: c.codvend.toString(), text: `${c.apelido} (${c.codvend})`, value: c.codvend.toString() })
                }
            }


            dispatch({ type: CONSULTORES_LIST, payload: consultores })
            resolve([])
        })
    }
)




export const consultoresListSearchAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CONSULTORES_ISSEARCHING_BUSCA, payload: true })
        //console.log("###ENTIDADE")
        //console.log(entidade)
        Api.postConsultoresSearch(entidade).then(dados => {
            dispatch({ type: CONSULTORES_LIST_SEARCH, payload: dados })
            dispatch({ type: CONSULTORES_ISSEARCHING_BUSCA, payload: false })
            resolve([])
        })
    }
)



export const consultoresListFechamentoAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllConsultoresFechamento().then(dados => {
            dispatch({ type: CONSULTORES_LIST_FECHAMENTO, payload: dados })
            dispatch({ type: CONSULTORES_LOADER_FECHAMENTO, payload: false })
            resolve([])
        })
    }
)

export const consultoresListFechamento90Action = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllConsultoresFechamento90().then(dados => {
            dispatch({ type: CONSULTORES_LIST_FECHAMENTO90, payload: dados })
            dispatch({ type: CONSULTORES_LOADER_FECHAMENTO90, payload: false })
            resolve([])
        })
    }
)



export const consultoresUpdateAction = (event) => {
    return {
        type: CONSULTORES_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}


export const consultoresAddValidacao = (id, campo, mensagem) => {
    return {
        type: CONSULTORES_VALIDACAO_BUSCA_ADD
        , payload: { id: id, item: campo, mensagem: mensagem, status: 'Error' }
    }
}


export const consultoresRemoveValidacao = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CONSULTORES_VALIDACAO_BUSCA_REMOVE, payload: [] })
        resolve([])
    }
    );










