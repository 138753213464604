import {
    B2B_PARCEIROS_LIST
    , B2B_PARCEIROS_ISSEARCHING
    , B2B_PARCEIROS_ISSAVING
    , B2B_PARCEIROS_CHANGE
    , B2B_RELATORIO_LIST
    , B2B_RELATORIO_CHECKED_CHAMOU
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';

import { START_TIMER } from 'redux-timer';

import { B2BParceiroEntity } from '../entities/B2BParceiroEntity';


export const ProcessamentosLibMarketPlaceListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        /*Api.getAllProcessamentosLibMarketPlace().then(dados => {
            let blnvalor = false

            if(dados[0].valor=='1'){
                blnvalor = true
            }else{
                blnvalor = false
            }

            dispatch({ type: PROCESSAMENTOS_CHANGE_LIBMARKETPLACE, payload: blnvalor})
            resolve(dados)
        }).catch(error => {
            reject(error);
        })*/
    });


export const ProcessamentosLibMarketPlaceUpdateAction = (valor) => (dispatch) =>
    new Promise(function (resolve, reject) {

        /*let entidade = {}
        entidade.valor = valor

        Api.postSaveProcessamentosMarketPlace(entidade).then(dados => {
            dispatch({ type: PROCESSAMENTOS_CHANGE_LIBMARKETPLACE, payload: valor})
            resolve([])
        }).catch(error => {
            reject(error);
        })*/


    });











export const B2BListParceiroAction = (codparc) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: B2B_PARCEIROS_ISSEARCHING, payload: true })

        Api.getAllB2BParceiro(codparc).then(dados => {



            let entidade = B2BParceiroEntity;
            entidade.codparc = dados[0].codparc;
            entidade.nomeparc = dados[0].nomeparc;
            entidade.ipiincicms = dados[0].ipiincicms;
            entidade.ad_ultpedfbits = dados[0].ad_ultpedfbits;
            entidade.codtab = dados[0].codtab;
            entidade.classificacaofin = dados[0].classificacaofin;
            entidade.tipoclassificacaofin = dados[0].tipoclassificacaofin;
            entidade.classificacaofinnovo = dados[0].classificacaofin;
            entidade.dtclassificacaofin = dados[0].dtclassificacaofin;
            entidade.valormina = dados[0].valormina;
            entidade.valorminb = dados[0].valorminb;
            entidade.valormeses = dados[0].valormeses;
            entidade.mesestotal = dados[0].mesestotal;
            entidade.nometab = dados[0].nometab;


            dispatch({ type: B2B_PARCEIROS_LIST, payload: entidade })
            dispatch({ type: B2B_PARCEIROS_ISSEARCHING, payload: false })

            resolve(dados)
        }).catch(error => {
            reject(error);
        })
    });



export const B2BChangeParceiroAction = (event) => {
    return {
        type: B2B_PARCEIROS_CHANGE
        , field: event.target.name
        , payload: event.target.value
    }
}



export const B2BSaveParceiroAction = (entidade, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {


        if (acao === 'save') {
            dispatch({ type: B2B_PARCEIROS_ISSAVING, payload: true })

            Api.postSaveB2BParceiro(entidade).then(dados => {

                let entity = B2BParceiroEntity
                entity.codparc = 0
                entity.nomeparc = ''


                if (dados[0].status == 'OK') {
                    dispatch({ type: B2B_PARCEIROS_LIST, payload: entity })
                }

                dispatch({ type: B2B_PARCEIROS_ISSAVING, payload: false })
                resolve(dados)
            }).catch(error => {
                reject(error);
            })
        } else {
            let entity = ProcessamentosParceiroEntity
            entity.codparc = 0
            entity.nomeparc = ''
            entity.ipiincicms = ''
            dispatch({ type: B2B_PARCEIROS_ISSAVING, payload: true })
            dispatch({ type: B2B_PARCEIROS_LIST, payload: entity })
            dispatch({ type: B2B_PARCEIROS_ISSAVING, payload: false })
            resolve([])
        }




    });


export const B2BRelatorioListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRelatorio().then(dados => {
            dispatch({ type: B2B_RELATORIO_LIST, payload: dados })
            resolve([])
        }).catch(error => {
            reject(error);
        })
    });


export const B2BRelatorioCheckedChamou = (entidade, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.postB2BCheckedChamou(entidade)
            .then((dados) => {
                if (dados[0].status == 'OK') {
                    dispatch(B2BRelatorioListAction());
                }
                resolve([]);

            })
            .catch((error) => {
                console.log(error);
                resolve([]);
            });
    });

export const B2BRelatorioCheckedConverteu = (entidade, acao) => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.postB2BCheckedConverteu(entidade)
            .then((dados) => {
                if (dados[0].status == 'OK') {
                    dispatch(B2BRelatorioListAction());
                }
                resolve([]);

            })
            .catch((error) => {
                console.log(error);
                resolve([]);
            });
    });

