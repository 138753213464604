import React, {Component} from 'react'

import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Grid, Header, Icon, Label
    , Modal, Button, Table, Message, Image, Segment
    ,
} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';

import ReactToPrint from "react-to-print";



String.prototype.extenso = function(c){
    var ex = [
        ["zero", "um", "dois", "três", "quatro", "cinco", "seis", "sete", "oito", "nove", "dez", "onze", "doze", "treze", "quatorze", "quinze", "dezesseis", "dezessete", "dezoito", "dezenove"],
        ["dez", "vinte", "trinta", "quarenta", "cinqüenta", "sessenta", "setenta", "oitenta", "noventa"],
        ["cem", "cento", "duzentos", "trezentos", "quatrocentos", "quinhentos", "seiscentos", "setecentos", "oitocentos", "novecentos"],
        ["mil", "milhão", "bilhão", "trilhão", "quadrilhão", "quintilhão", "sextilhão", "setilhão", "octilhão", "nonilhão", "decilhão", "undecilhão", "dodecilhão", "tredecilhão", "quatrodecilhão", "quindecilhão", "sedecilhão", "septendecilhão", "octencilhão", "nonencilhão"]
    ];
    var a, n, v, i, n = this.replace(c ? /[^,\d]/g : /\D/g, "").split(","), e = " e ", $ = "real", d = "centavo", sl;
    for(var f = n.length - 1, l, j = -1, r = [], s = [], t = ""; ++j <= f; s = []){
        j && (n[j] = (("." + n[j]) * 1).toFixed(2).slice(2));
        if(!(a = (v = n[j]).slice((l = v.length) % 3).match(/\d{3}/g), v = l % 3 ? [v.slice(0, l % 3)] : [], v = a ? v.concat(a) : v).length) continue;
        for(a = -1, l = v.length; ++a < l; t = ""){
            if(!(i = v[a] * 1)) continue;
            i % 100 < 20 && (t += ex[0][i % 100]) ||
            i % 100 + 1 && (t += ex[1][(i % 100 / 10 >> 0) - 1] + (i % 10 ? e + ex[0][i % 10] : ""));
            s.push((i < 100 ? t : !(i % 100) ? ex[2][i == 100 ? 0 : i / 100 >> 0] : (ex[2][i / 100 >> 0] + e + t)) +
            ((t = l - a - 2) > -1 ? " " + (i > 1 && t > 0 ? ex[3][t].replace("ão", "ões") : ex[3][t]) : ""));
        }
        a = ((sl = s.length) > 1 ? (a = s.pop(), s.join(" ") + e + a) : s.join("") || ((!j && (n[j + 1] * 1 > 0) || r.length) ? "" : ex[0][0]));
        a && r.push(a + (c ? (" " + (v.join("") * 1 > 1 ? j ? d + "s" : (/0{6,}$/.test(n[0]) ? "de " : "") + $.replace("l", "is") : j ? d : $)) : ""));
    }
    return r.join(e);
}



const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
    max-width: 700px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 4px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


const teste='150'

const textoestilo = {
    width: "600px",
    textAlign: "justify",
  }

  const textoestilodata = {
    width: "600px",
    textAlign: "right",
  }

  const textoestiloassinatura = {
    width: "600px",
    textAlign: "left",
  }

class ModalConfissao extends Component{

    state = {}


    
descobremes = (numero) =>{
    let mes = ''
    
    switch (numero.toString()) {
        
        case "0":
            mes='Janeiro'
            break
        case "1":
            mes='Fevereiro'
            break
        case "2":
            mes='Março'
            break
        case "3":
            mes='Abril'
            break
        case "4":
            mes='Maio'
            break
        case "5":
            mes='Junho'
            break
        case "6":
            mes='Julho'
            break
        case "7":
            mes='Agosto'
            break
        case "8":
            mes='Setembro'
            break
        case "9":
            mes='Outubro'
            break
        case "10":
            mes='Novembro'
            break
        case "11":
            mes='Dezembro'
            break  
    }

    return mes

}

    

    constructor(props){
        super(props)
    }


    componentDidMount(){

    }

    componentWillReceiveProps(){
      
        
    }


    render(){

        return(


            <Modal
                trigger={<Button basic icon={'print'} color={'red'} size="mini" />}
            //open={this.props.entidade.modalOpen}
            //onClose={(e) => this.props.handleClose(this.props.entidade)}
            >
                <Modal.Header>Cartas</Modal.Header>
                <Modal.Content image scrolling>

                    <Modal.Description>

                        <div ref={el => (this.componentRef = el)}>

                     

                        {
                                 
                                    <Segment>
                                        <Image src='img/logo_esplane.png' centered />

                                       
                                        <Header as='h2' textAlign='center'>TERMO DE CONFISSÃO DE DÍVIDA</Header>
                                        <center>
                                            <div style={textoestilo}>

                                            <b>CREDORA:</b> ESPLANE ESPAÇOS PLANEJADOS, legalmente estabelecida na Av. Gal. Euclides
                                            Figueiredo, 151, Vila Industrial, Campinas, SP, CNPJ 61.740.510/0001-90 e filial
                                            sito na Estrada Luis Fernando Rodrigues, 2225, Vl. Boa Vista, Campinas, SP,
                                            CNPJ 61.740.510/0002-70;
                                            <br/><br/>
                                            <b>DEVEDOR:</b> ({this.props.entidade.parceiro}), {(this.props.entidade.tippessoa=="F"?"CPF:":"CNPJ:")} {this.props.entidade.documento}<br/>
                                            <b>Endereco: </b> {this.props.entidade.endereco}, {this.props.entidade.endereconumero} {this.props.entidade.complemento} {this.props.entidade.Bairro} - {this.props.entidade.cidade}/{this.props.entidade.estado}
                                             <br/><br/>



                                            Pelo presente instrumento particular e na melhor forma de direito, confessam e assumem como
                                            líquida e certa a dívida a seguir descrita:

                                            <br/><br/>

                                            CLÁUSULA 1ª - Ressalvadas qualquer outras obrigações aqui não incluídas, pelo presente instrumento
                                            e na melhor forma de direito, o DEVEDOR, confessa dever à CREDORA, o valor de 
                                            {" "+new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.props.entidade.vlrtotal)} ({this.props.entidade.vlrtotal.toString().replace('.',',').extenso(true).toUpperCase()}), 
                                            referente as notas fiscais faturas números ({this.props.entidade.dividas.map(d=>(d.numnota+","))})

                                            <br/><br/>

                                            CLÁUSULA 2ª - Que desejando quitar a divida, declara que faz o pagamento por meio de cartão de credito,
                                            cujo os quatro últimos dígitos são ______________ e que é o titular do cartão ou teve autorização do titular para realizar o pagamento 
                                            da presente divida, ficando ciente que poderá responder civil e criminalmente por esta declaração e que 
                                            não poderá contestar o pagamento ora realizado por meio de tal cartão junto a operadora.
                                        
                                            <br/><br/>

                                            CLÁUSULA 3ª - Para dirimir eventuais dúvidas oriundas do presente instrumento, fica eleito o Foro da 
                                            Comarca de Campinas, Estado de São Paulo, excluindo qualquer outro por melhor que seja.
                                            
                                            <br/><br/>

                                            </div>
                                            
                                            <br/><br/><br/><br/>

                                            <div style={textoestilodata}>
                                                Campinas, {new Date().getDate()} de {this.descobremes(new Date().getMonth())} de {new Date().getFullYear()}.
                                            </div>
                                            <br/><br/><br/>

                                            <If test={this.props.entidade.tippessoa=="J"}>
                                                <div style={textoestiloassinatura}>
                                                    
                                                    <b>{this.props.entidade.parceiro}</b><br/><br/>
                                                    <b>Responsável Legal:</b>________________________________________________<br/>
                                                    <b>RG:</b><br/>
                                                    
                                                    <br/><br/><br/>
                                                </div>
                                            </If>

                                            
                                            <If test={this.props.entidade.tippessoa=="F"}>
                                                <div style={textoestiloassinatura}>
                                                    
                                                    <b>{this.props.entidade.parceiro}</b><br/><br/>
                                                    <b>Assinatura:</b>________________________________________________<br/>
                                                    <b>RG:</b><br/>
                                                    
                                                    <br/><br/><br/>
                                                </div>
                                            </If>
                                            

                                        </center>
                                        
                                    </Segment>
                           

                        }

                        
                        




                        


                        </div>
                    </Modal.Description>
                </Modal.Content>
                <Modal.Actions>

                    <ReactToPrint
                        trigger={() => <Button color='green'>Imprimir</Button>}
                        content={() => this.componentRef}
                        pageStyle="@media print {pre, blockquote, table, div {page-break-inside: avoid; border: 1px solid #ffff;}}"
                        //onAfterPrint={(e) =>this.props.handlePrint(this.props.datalist)}
                    />

                </Modal.Actions>

            </Modal >
        )

    }


}

ModalConfissao.defaultProps = {
    entidade:{}
}
ModalConfissao.PropTypes = {
    entidade: PropTypes.object.isRequired
}

export default ModalConfissao