import {
    COMPARATIVOPRODUTOS_LIST
    , COMPARATIVOPRODUTOS_ISSEARCHING
    , COMPARATIVOPRODUTOS_LIST_COMBO
    , COMPARATIVOPRODUTOS_CHANGE
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';


/*
export const consultoresListCidadesAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllCidades().then(dados => {

            //Cidades
            let cidades = []
            cidades.push({ key: "0", text: `Todas`, value: "0" })
            if (dados.length > 0) {
                for (let c of dados) {
                    cidades.push({ key: c.codcid.toString(), text: `${c.uf} -(${c.nomecid})`, value: c.codcid.toString() })
                }
            }

            dispatch({ type: CONSULTORES_LIST_CIDADES, payload: cidades })
            resolve([])
        })
    }
)*/

//COMPARATIVOPRODUTOS_CHANGE
export const comparativoProdutosListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {


        dispatch({ type: COMPARATIVOPRODUTOS_ISSEARCHING, payload: true })
        Api.getAllComparativoProdutos().then(resultado => {

            let imagens = []
            imagens = resultado.imagens

            let dados = []
            dados = resultado.produtos

            /*
            let consultores = []
            consultores.push({ key: "0", text: `Todos`, value: "0" })
            if (dados.length > 0) {
                for (let c of dados) {
                    consultores.push({ key: c.codvend.toString(), text: `${c.apelido} (${c.codvend})`, value: c.codvend.toString() })
                }
            }*/

            let comparativos = []
            comparativos.push({ key: "0", text: `Selecione`, value: "0" })



            for (let item of dados) {
                comparativos.push({ key: `${item.comparativoid}`, text: `Comparativo ${item.comparativoid}`, value: `${item.comparativoid}` })
            }

            //distinct
            comparativos = [...new Set(comparativos.map(o => JSON.stringify(o)))]
            comparativos = comparativos.map(o => JSON.parse(o))


            dispatch({ type: COMPARATIVOPRODUTOS_LIST_COMBO, payload: comparativos })

            dispatch({ type: COMPARATIVOPRODUTOS_LIST, produtos: dados, imagens: imagens })

            dispatch({ type: COMPARATIVOPRODUTOS_ISSEARCHING, payload: false })
            resolve([])
        })
    }
    )






let getlogotipo = (linha) => {
    let logotipo = ``


    switch (linha) {
        case "Tarkett": {
            logotipo = '<img src="https://recursos.voceconstroi.com.br/i/fabricante/Tarkett.png" style="width:60px;height: 25px;" />'
            break;
        }
        case "EspaçoFloor": {
            logotipo = '<img src="https://recursos.voceconstroi.com.br/i/fabricante/869.png" style="width:60px;height: 25px;" />'
            break;
        }
        case "EspaçoFloor1": {
            logotipo = '<img src="https://recursos.voceconstroi.com.br/i/fabricante/869.png" style="width:60px;height: 25px;" />'
            break;
        }
        case "Durafloor": {
            logotipo = '<img src="https://recursos.voceconstroi.com.br/i/fabricante/797.png" style="width:60px;height: 25px;" />'
            break;
        }
        case "Durafloor1": {
            logotipo = '<img src="https://recursos.voceconstroi.com.br/i/fabricante/797.png" style="width:60px;height: 25px;" />'
            break;
        }


        default: {
            logotipo = 'Sem logotipo'
            break;
        }
    }



    return logotipo
}


let getimagens = (imagens) => {
    let conteudo = ``


    for (let item of imagens) {
        conteudo += `
                                <a href="${item.link}" target="_blank">${item.nome}</a><br><a href="${item.link}" target="_blank">
                                    <img src=${item.img} style="width:100px;height: 100px;">
                                </a><br>
            `
    }





    return conteudo

}


let getseloprecomedio = (preco) => {
    let selo = ``


    switch (preco) {
        case "4": {
            selo = ''
            break;
        }
        case "3": {
            
            selo = '<img src="https://recursos.voceconstroi.com.br/MediaCenter/money1.png" style="width:35px;height: 35px;" />'
            //selo = '<img src="https://flyclipart.com/thumb2/money-icon-png-270555.png" style="width:60px;height: 45px;" />'
            //selo = '<img src="https://flyclipart.com/thumb2/how-to-save-money-during-the-holidays-lees-summit-honda-blog-270552.png" style="width:60px;height: 35px;" />'
            //selo = '<img src="http://www.delgallo.com.br/money5.png" style="width:25px;height: 30px;" />'
            //selo = "🤑💰"
            
            break;
        }


        default: {
            selo = '-----'
            break;
        }
    }

    return selo
}


//COMPARATIVOPRODUTOS_CHANGE
export const comparativoProdutosChangeAction = (comparativoid, dados, imagens) => (dispatch) =>
    new Promise(function (resolve, reject) {



        //---------------------------------------------
        //LINHA DE PRODUTO

        let qtdelinhas = 0
        let linhas = []

        for (let item of dados) {
            linhas.push({ linha: item.linha })
        }

        linhas = [...new Set(linhas.map(o => JSON.stringify(o)))]
        linhas = linhas.map(o => JSON.parse(o))
        qtdelinhas = linhas.length

        //---------------------------------------------
        //ATRIBUTOS DO PRODUTO

        let qtdeatributos = 0
        let atributos = []

        for (let item of dados) {
            atributos.push({ atributo: item.atributo, ordematributos: item.ordematributos })
        }

        atributos = [...new Set(atributos.map(o => JSON.stringify(o)))]
        atributos = atributos.map(o => JSON.parse(o))
        qtdeatributos = atributos.length

        //---------------------------------------------
        //CABEÇALHO DA TABELA


        let deskcabecalho = ``
        deskcabecalho += `
                    <tr>`
                    deskcabecalho += `
                        <td style="font-size:1.575em; font-variant: small-caps;color:#0c5195;" align="right">COMPARADOR <br>de Pisos<br><br></td>`
        for (let item of linhas) {
            deskcabecalho += `
                        <td align="center">
                            ${getlogotipo(item.linha)}
                        </td>`
        }
        deskcabecalho += `
                    </tr>`


       

        let mobcabecalho = ``
        mobcabecalho += `
                    <tr>`
        mobcabecalho += `
                        <td style="font-variant: small-caps;color:#0c5195;" align="right">COMPARADOR <br>de Pisos<br><br></td>`
        for (let item of linhas) {
            mobcabecalho += `
                        <td align="center" style="padding: .1rem;">
                            ${getlogotipo(item.linha)}
                        </td>`
        }
        mobcabecalho += `
                    </tr>`

        //---------------------------------------------


        let deskregistros = ``
        let mobregistros= ``


        for (let item of atributos) {
            deskregistros += `
                    <tr>`

            deskregistros += `
                        <td style="font-size:18px;font-weight: normal;color:#053562;" align="right">${item.atributo}</td>`

            for (let l of linhas) {
                let valor = dados.filter(f => f.linha == l.linha && f.atributo == item.atributo)[0].valor
                if (item.atributo == "Valor Médio") {
                    deskregistros += `
                        <td align="center">${(valor != null ? getseloprecomedio(valor) : "")}</td>`
                } else {
                    deskregistros += `
                        <td align="center">${(valor != null ? valor : "")}</td>`
                }


            }

            deskregistros += `
                    </tr>`
        }


        for (let item of atributos) {
            mobregistros += `
                    <tr>`

            mobregistros += `
                        <td style="color:#053562;padding: .1rem;">${item.atributo}</td>`

            for (let l of linhas) {
                let valor = dados.filter(f => f.linha == l.linha && f.atributo == item.atributo)[0].valor
                if (item.atributo == "Valor Médio") {
                    mobregistros += `
                        <td style="padding: .1rem;border:1px solid #cccccc;" align="center">${(valor != null ? getseloprecomedio(valor) : "")}</td>`
                } else {
                    mobregistros += `
                        <td style="padding: .1rem;border:1px solid #cccccc;" align="center">${(valor != null ? valor : "")}</td>`
                }

            }

            mobregistros += `
                    </tr>`
        }







        let dtimagens = []

        dtimagens = imagens.filter(f => f.comparativoid == comparativoid)


        let deskcabecalhoimagens = ``
        if (dtimagens.length > 0) {
            deskcabecalhoimagens += `
                        <tr>`
            deskcabecalhoimagens += `
                            <td style="padding: .1rem;border:0px solid #000000;">&nbsp;</td>`
            for (let item of linhas) {

                deskcabecalhoimagens += `
                            <td align="center" valign="top" style="padding: .1rem;border:0px solid #000000;">
                                ${getimagens(dtimagens.filter(f => f.linha == item.linha))}
                            </td>`
            }
            deskcabecalhoimagens += `
                        </tr>`
        }


        let mobcabecalhoimagens = ``
        if (dtimagens.length > 0) {
            mobcabecalhoimagens += `
                        <tr>`
                mobcabecalhoimagens += `
                            <td style="padding: .1rem;border:0px solid #000000;">&nbsp;</td>`
            for (let item of linhas) {

                mobcabecalhoimagens += `
                            <td align="center" valign="top" style="padding: .1rem;border:0px solid #000000;">
                                ${getimagens(dtimagens.filter(f => f.linha == item.linha))}
                            </td>`
            }
            mobcabecalhoimagens += `
                        </tr>`
        }









        let bodyhtml = ``

        let deskbodyhtml = `
        <div class="paddingbox">
            <table style="width:100%;">
                <tbody>
                    ${deskcabecalho}
                    ${deskregistros}

                    <tr>
                        <td  align="center"  colspan="${qtdelinhas + 1}"><br>##conteúdo##<br></td>
                    </tr>
                   
                    ${deskcabecalhoimagens}
                    
                </tbody>
            </table>
        </div>
        `



        let mobbodyhtml = `
        <div class="paddingbox">
            <table style="border-collapse: collapse; border:0px solid #000000;">
                <tbody>
                    ${mobcabecalho}
                    ${mobregistros}

                    <tr>
                        <td style="padding: .1rem;border:0px solid #000000;" align="center"  colspan="${qtdelinhas + 1}">##conteúdo##</td>
                    </tr>
                   
                    ${mobcabecalhoimagens}
                    
                </tbody>
            </table>
        </div>
        `






        bodyhtml = `
        <div class="ajuda-suporte-desktop">
            ${deskbodyhtml}
        </div>
        <div class="ajuda-suporte-mobile">
            ${mobbodyhtml}
        </div>

        `




        if (comparativoid == "0") {
            bodyhtml = ``
        }

        dispatch({ type: COMPARATIVOPRODUTOS_CHANGE, payload: bodyhtml })

    }
    )




