export const  CadastroDeProdutosInfoB2BEntity = {
    issaving: false
    ,infotextoprodutoflag:false
    , infotextoproduto:""
    , infotextogarantiaflag:false
    , infotextogarantia:""
    , infourlpdfgarantiaflag:false
    , infourlpdfgarantia:""
    , infourlvideoprincipalflag:false
    , infourlvideoprincipal:""
    , infourlvideosecundarioflag:false
    , infourlvideosecundario:""
    , infotextomododeusarflag:false
    , infotextomododeusar:""
    , infotextocuidadosflag:false
    , infotextocuidados:""
    , infourlpdfcuidadosflag:false
    , infourlpdfcuidados:""
    , infohtmlvalorreferenteflag:false
    , infohtmlvalorreferente:""
    , produtos:[]
}