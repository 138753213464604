import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Table, Popup, Button, Grid, GridColumn, Header
} from 'semantic-ui-react';
import If from '../../utils/If';
import styled, { isStyledComponent } from 'styled-components';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 4px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;
const popstyle = {
    whiteSpace: "normal",
};
class Financeiro extends Component {
    state = {}

    constructor(props) {
        super(props);
    }

    onSave = (item) => {
        let entidade = {}
        entidade.nufin = item.nufinArray;
        entidade.userid = parseInt(this.props.userid); // Convertendo para número inteiro
        entidade.motorista = item.motorista
        this.props.despesasDeViagensSaveFinanceiroGeradoAction(entidade)
    }

    render() {
        let { datalist, userid, usersfin } = this.props
        let blnBloqueia = true
        let totalocunico = null;
        let useridString = userid.toString();
        if (usersfin.includes(useridString)) {
            blnBloqueia = false
        }

        return (
            <StyledTable celled id="listGerados" compact='very' size='small'>
                <StyledTableHeader>
                    <Table.Row >
                        <Table.HeaderCell width="1">Veículo</Table.HeaderCell>
                        <Table.HeaderCell width="3">Motorista</Table.HeaderCell>
                        <Table.HeaderCell width="1">Valor</Table.HeaderCell>
                        <Table.HeaderCell width="2">DT Financeiro</Table.HeaderCell>
                        <Table.HeaderCell width="1"></Table.HeaderCell>
                    </Table.Row>
                </StyledTableHeader>

                <StyledTableBody>
                    {datalist.map(item => (
                        <Table.Row key={`${item.motorista}_${item.nufin}`}>
                            <Popup
                                trigger={<Table.Cell>{item.placa}</Table.Cell>}
                                content={item.veiculo}
                                inverted
                            />
                            <Popup
                                trigger={
                                    <Table.Cell>
                                        {item.motorista}
                                    </Table.Cell>
                                }
                                flowing
                                hoverable
                                pinned
                                on={["hover", "click"]}
                            >
                                <p>Motorista: <b>{item.motorista}</b></p>
                                <table border='1px' style={{ width: '100%', borderCollapse: 'collapse' }}>
                                    <thead>
                                        <tr>
                                            <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}><b>OC</b></th>
                                            <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>NUFIN</th>
                                            <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>VALOR</th>
                                            <th style={{ padding: '8px', textAlign: 'left', backgroundColor: '#f2f2f2', borderBottom: '1px solid #ddd' }}>NATUREZA</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {item.ordensDeCarga.map(itempopup => (
                                            <tr key={itempopup.ordemcarga}>
                                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.ordemcarga}</td>
                                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.nufin}</td>
                                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(itempopup.valorespopup)}</td>
                                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{itempopup.natureza}</td>
                                            </tr>
                                        ))}
                                        {Object.entries(item.totalPorOrdemDeCarga[0]).map(([chave, valor]) => (
                                            <tr key={chave}>
                                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }} colSpan={2} align='right'><b>Total OC {chave}:</b></td>
                                                <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(valor)}</td>
                                            </tr>
                                        ))}
                                        <tr key={item.valoresdob}>
                                            <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }} colSpan={2} align='right'><b>Total:</b></td>
                                            <td style={{ padding: '8px', borderBottom: '1px solid #ddd' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valoresdob)}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </Popup>

                            <Table.Cell>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valoresdob)}
                            </Table.Cell>
                            <Table.Cell>
                                {moment(item.dtprocfinanceiro).format("DD/MM/YYYY HH:mm")}
                            </Table.Cell>
                            <Table.Cell>
                                <Button
                                    key={`save_${item.ordemcarga}`} basic
                                    icon={'gavel'} color={'green'} size="mini"
                                    onClick={(e) => this.onSave(item)}
                                    loading={item.issaving}
                                    disabled={blnBloqueia}
                                />
                            </Table.Cell>
                        </Table.Row>
                    ))}
                </StyledTableBody>
            </StyledTable >
        )
    }
}

Financeiro.defaultProps = {
    datalist: [],
    userid: "",
    usersfin: []
};

Financeiro.propTypes = {
    datalist: PropTypes.array.isRequired,
    userid: PropTypes.string.isRequired,
    usersfin: PropTypes.array.isRequired,
    despesasDeViagensSaveFinanceiroGeradoAction: PropTypes.func.isRequired
};

export default Financeiro;
