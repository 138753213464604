import {
    BLACKFRIDAY_LIST_CLIENTES
    , BLACKFRIDAY_ISLOADING
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';


export const blackfridayListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({type: BLACKFRIDAY_ISLOADING, payload: true})  

        Api.getAllBlackFriday().then(dados => {

            dados.sort(sortBy('-dataregistro'));
            dispatch({type: BLACKFRIDAY_LIST_CLIENTES, payload: dados})
            dispatch({type: BLACKFRIDAY_ISLOADING, payload: false})  
            resolve([])
        })
    }
)



