import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from 'moment';
import {
    Checkbox, Table, Button, Popup, Grid, Segment, Form, Header, Message
} from 'semantic-ui-react'

import If from '../../utils/If';
import styled from 'styled-components';
import { DebounceInput } from 'react-debounce-input';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class Informacoes extends Component {


    state = {status:'',mensagem:''}





    componentWillReceiveProps() {

    }

    componentDidMount() {

    }

    handleCodigos = (value) => {
        //alert('aqui'+value)

        let dados = []

        value.trim().split('\n').map(i => {
            let numero = 0
            try {
                numero = Number.parseInt(i)
            } catch (err) {
                numero = " "
            }
            if (Number.isInteger(numero)) {
                dados.push({ searchint: numero, searchstr: i, valid: 1 })
            } else {
                dados.push({ searchint: 0, searchstr: i, valid: 0 })
            }
        });



        if(dados.filter(d=>d.valid=1).length>0){
            this.props.CadastroDeProdutosInfoSearchAction(dados)
        }
        

        //console.log(dados)

    }

    updateData = (e, { checked, value, name }) => {


        if (checked === undefined) {
            this.props.CadastroDeProdutosInfoCamposAction(name, value)
        } else {
            let check = checked
            this.props.CadastroDeProdutosInfoCheckboxAction(name, check)
        }


        /*alert(`
        aki
        name:${name}
        value:${value}
        checked:${checked}
        `)*/

    }


    handleSave = () => {
        this.setState({mensagem:'', status:''})

        let {CadastroDeProdutosInfoEntity, searchresult, login} = this.props;


        if (CadastroDeProdutosInfoEntity.issaving === false) { //trava pra aceitar apenas uma requisicao ao clicar várias vezes

            if(CadastroDeProdutosInfoEntity.infotextoprodutoflag===true
                || CadastroDeProdutosInfoEntity.infotextogarantiaflag===true
                || CadastroDeProdutosInfoEntity.infourlpdfgarantiaflag===true
                || CadastroDeProdutosInfoEntity.infourlvideoprincipalflag===true
                || CadastroDeProdutosInfoEntity.infourlvideosecundarioflag===true
                || CadastroDeProdutosInfoEntity.infotextomododeusarflag===true
                || CadastroDeProdutosInfoEntity.infotextocuidadosflag===true
                || CadastroDeProdutosInfoEntity.infourlpdfcuidadosflag===true
                || CadastroDeProdutosInfoEntity.infohtmlvalorreferenteflag===true
                ){

                    let produtos = []
                    produtos = searchresult.filter(d=>d.status===1)

                    if(produtos.length>0){
                        this.props.CadastroDeProdutosInfoSaveAction(CadastroDeProdutosInfoEntity,produtos, login).then(result => {
                            this.setState({mensagem:result.mensagem, status:result.status})
                        })
                    }
                    
                }

        }



    }



    render() {

        let { searchresult, CadastroDeProdutosInfoEntity } = this.props

        return (
            <div>

                <Grid columns={2} doubling>
                    <Grid.Column>

                        <Segment secondary>

                            <Form loading={false}>

                                <Form.Group widths='equal'>
                                    <DebounceInput
                                        element="textarea"
                                        rows={15}
                                        minLength={2}
                                        debounceTimeout={1000}
                                        onChange={e => this.handleCodigos(e.target.value)}
                                    />
                                </Form.Group>

                            </Form>


                        </Segment>




                    </Grid.Column>
                    <Grid.Column>
                        <Segment secondary>

                            <div className="cadastrodeprodutos_search">

                                <StyledTable celled id="listPedidos" compact='very' size='small'>
                                    <StyledTableHeader>
                                        <Table.Row>
                                            <Table.HeaderCell>SKU</Table.HeaderCell>
                                            <Table.HeaderCell>Mitra</Table.HeaderCell>
                                            <Table.HeaderCell>VCC</Table.HeaderCell>
                                            <Table.HeaderCell>B2B</Table.HeaderCell>
                                            <Table.HeaderCell>Produto</Table.HeaderCell>
                                        </Table.Row>
                                    </StyledTableHeader>

                                    <StyledTableBody>
                                        {
                                            searchresult.map(item => (
                                                <Table.Row key={"produto_" + item.sku}>
                                                    <Table.Cell negative={(item.status === 0 || item.produto === null ? true : false)}>{item.sku}</Table.Cell>
                                                    <Table.Cell negative={(item.status === 0 || item.produto === null ? true : false)}>{item.mitra}</Table.Cell>
                                                    <Table.Cell negative={(item.status === 0 || item.produto === null ? true : false)}>{item.fbits}</Table.Cell>
                                                    <Table.Cell negative={(item.status === 0 || item.produto === null ? true : false)}>{item.b2b}</Table.Cell>
                                                    <Table.Cell negative={(item.status === 0 || item.produto === null ? true : false)}>{item.produto}</Table.Cell>
                                                </Table.Row>
                                            ))
                                        }
                                    </StyledTableBody>
                                </StyledTable>
                            </div>

                        </Segment>
                    </Grid.Column>

                </Grid>

                <Grid columns={1} stackable>
                    <Grid.Column>

                        <Segment secondary>
                            <Popup
                                trigger={<Header as='h2'>Informações do Produto</Header>}
                                content={`
                                Informações do Produto
                                Texto principal do produto (TextoProduto)
                                *** Campo TextoProduto que será automaticamente concatenado com o nome do produto e salvo no campo HTMLInformacoes
                                `}
                                wide='very'
                            />

                            <Form key='Informacoesdoproduto'>
                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infotextoprodutoflag"
                                    label='Texto principal do produto' 
                                    checked={CadastroDeProdutosInfoEntity.infotextoprodutoflag}/>
                                <Form.Group widths='equal'>
                                    <Form.TextArea
                                        name="infotextoproduto" placeholder='Dígite o conteúdo'
                                        onChange={this.updateData}
                                        rows={10}
                                        value={CadastroDeProdutosInfoEntity.infotextoproduto}
                                        disabled={!CadastroDeProdutosInfoEntity.infotextoprodutoflag}
                                    />
                                </Form.Group>
                            </Form>
                        </Segment>

                        <Segment secondary>
                        <Popup
                                trigger={<Header as='h2'>Certificado de Garantia</Header>}
                                content={`
                                Certificado de Garantia
                                *** Campos TextoGarantia e URLPDFGarantia que serão formatados e salvos no campo HTMLEspecificacoes. Não é obrigatório ter PDF.
                                Texto sobre a garantia (TextoGarantia)
                                URL do PDF para download da garantia (URLPDFGarantia)
                                `}
                                wide='very'
                            />
                            <Form key='certificadodegarantia'>
                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infotextogarantiaflag"
                                    label='Texto sobre a garantia' 
                                    checked={CadastroDeProdutosInfoEntity.infotextogarantiaflag}
                                    />
                                <Form.Group widths='equal'>
                                    <Form.TextArea
                                        name="infotextogarantia" placeholder='Dígite o conteúdo'
                                        onChange={this.updateData}
                                        rows={10}
                                        value={CadastroDeProdutosInfoEntity.infotextogarantia}
                                        disabled={!CadastroDeProdutosInfoEntity.infotextogarantiaflag}
                                    />
                                </Form.Group>

                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infourlpdfgarantiaflag"
                                    label='URL do PDF da Garantia'
                                    checked={CadastroDeProdutosInfoEntity.infourlpdfgarantiaflag}
                                     />
                                    
                                <Form.Input
                                    name="infourlpdfgarantia"
                                    onChange={this.updateData}
                                    disabled={!CadastroDeProdutosInfoEntity.infourlpdfgarantiaflag}
                                    value={CadastroDeProdutosInfoEntity.infourlpdfgarantia}
                                />
                            </Form>
                        </Segment>

                        <Segment secondary>
                        <Popup
                                trigger={<Header as='h2'>Instalação</Header>}
                                content={`
                                Instalação
                                *** Campos de vídeos que demonstram instalação/modo de usar ou texto quando não tiver vídeo. Não pode ter texto e vídeo junto e a prioridade é vídeo. Se tem vídeo principal não exibirá o texto. Automaticamente salvo no campo HTMLModoDeUsar
                                Url do Youtube para o vídeo principal (URLVideoPrincipal)
                                Url do Youtube para o vídeo secundário (URLVideoSecundario)
                                Texto sobre como usar ou instalar (TextoModeoDeUsar)
                                `}
                                wide='very'
                            />
                            <Form key='instalacao'>
                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infotextomododeusarflag"
                                    label='Texto sobre como usar ou instalar' 
                                    checked={CadastroDeProdutosInfoEntity.infotextomododeusarflag}
                                    />
                                <Form.Group widths='equal'>
                                    <Form.TextArea
                                        name="infotextomododeusar" placeholder='Dígite o conteúdo'
                                        onChange={this.updateData}
                                        rows={10}
                                        value={CadastroDeProdutosInfoEntity.infotextomododeusar}
                                        disabled={!CadastroDeProdutosInfoEntity.infotextomododeusarflag}
                                    />
                                </Form.Group>

                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infourlvideoprincipalflag"
                                    label='Url do Youtube para o vídeo principal ' 
                                    checked={CadastroDeProdutosInfoEntity.infourlvideoprincipalflag}/>
                                <Form.Input
                                    name="infourlvideoprincipal"
                                    onChange={this.updateData}
                                    disabled={!CadastroDeProdutosInfoEntity.infourlvideoprincipalflag}
                                    value={CadastroDeProdutosInfoEntity.infourlvideoprincipal}
                                />

                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infourlvideosecundarioflag"
                                    label='Url do Youtube para o vídeo secundário '
                                    checked={CadastroDeProdutosInfoEntity.infourlvideosecundarioflag} />
                                <Form.Input
                                    name="infourlvideosecundario"
                                    onChange={this.updateData}
                                    disabled={!CadastroDeProdutosInfoEntity.infourlvideosecundarioflag}
                                    value={CadastroDeProdutosInfoEntity.infourlvideosecundario}
                                />
                            </Form>
                        </Segment>



                        <Segment secondary>
                        <Popup
                                trigger={<Header as='h2'>Manutenção e Limpeza</Header>}
                                content={`
                                Manutenção e Limpeza
                                *** Campos TextoCuidados e URLPDFCuidados que serão formatados e salvos no campo HTMLCUIDADOS. Não é obrigatório ter PDF.
                                Texto sobre manutenção e limpeza (TextoCuidados)
                                URL do PDF para donwload (URLPDFCuidados)
                                `}
                                wide='very'
                            />
                            <Form key='manutencaoelimpeza'>
                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infotextocuidadosflag"
                                    label='Texto sobre manutenção e limpeza' 
                                    checked={CadastroDeProdutosInfoEntity.infotextocuidadosflag}
                                    
                                    />
                                <Form.Group widths='equal'>
                                    <Form.TextArea
                                        name="infotextocuidados" placeholder='Dígite o conteúdo'
                                        onChange={this.updateData}
                                        rows={10}
                                        disabled={!CadastroDeProdutosInfoEntity.infotextocuidadosflag}
                                        value={CadastroDeProdutosInfoEntity.infotextocuidados}
                                    />
                                </Form.Group>

                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infourlpdfcuidadosflag"
                                    label='URL do PDF para Download' 
                                    checked={CadastroDeProdutosInfoEntity.infourlpdfcuidadosflag}
                                    />
                                <Form.Input
                                    name="infourlpdfcuidados"
                                    onChange={this.updateData}
                                    disabled={!CadastroDeProdutosInfoEntity.infourlpdfcuidadosflag}
                                    value={CadastroDeProdutosInfoEntity.infourlpdfcuidados}
                                />
                            </Form>
                        </Segment>


                        <Segment secondary>
                            <Popup
                                trigger={<Header as='h2'>Alerta do Produto</Header>}
                                content={`
                                Alerta do Produto
                                *** Campo que avisará cliente de alguma situação do produto. Já formatar HTML, pode ser vermelho.
                                Mensagem de alerta do produto (HTMLValorReferente)
                                `}
                                wide='very'
                            />

                            <Form key='alertadoproduto'>
                                <Checkbox toggle={true}
                                    onChange={this.updateData}
                                    name="infohtmlvalorreferenteflag"
                                    label='Mensagem de alerta do produto' 
                                    checked={CadastroDeProdutosInfoEntity.infohtmlvalorreferenteflag}
                                    />
                                <Form.Group widths='equal'>
                                    <Form.TextArea
                                        name="infohtmlvalorreferente" placeholder='Dígite o conteúdo'
                                        onChange={this.updateData}
                                        rows={10}
                                        value={CadastroDeProdutosInfoEntity.infohtmlvalorreferente}
                                        disabled={!CadastroDeProdutosInfoEntity.infohtmlvalorreferenteflag}
                                    />
                                </Form.Group>
                            </Form>
                        </Segment>
                        
                        <If test={this.state.mensagem!=''}>
                            <Message attached='top' color={(this.state.status==='OK'?'olive':'red')}>
                                {this.state.mensagem}
                            </Message>
                        </If>

                        <Button
                        floated="right" 
                        loading={CadastroDeProdutosInfoEntity.issaving} 
                        primary
                        onClick={this.handleSave}
                        >Gravar</Button>
                    
                
                    </Grid.Column>


                </Grid>


            </div>

        )
    }

}

Informacoes.defaultProps = {
    search: []
    , searchresult: []
    , login:''
};

Informacoes.propTypes = {
    search: PropTypes.array.isRequired
    , searchresult: PropTypes.array.isRequired
    , CadastroDeProdutosInfoSearchAction: PropTypes.func.isRequired
    , CadastroDeProdutosInfoCamposAction: PropTypes.func.isRequired
    , CadastroDeProdutosInfoCheckboxAction: PropTypes.func.isRequired
    , CadastroDeProdutosInfoEntity: PropTypes.object.isRequired
    , CadastroDeProdutosInfoSaveAction: PropTypes.func.isRequired
    , login: PropTypes.string.isRequired
};

export default Informacoes

