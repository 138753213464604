import React, { useState } from 'react';
import { Message, Tab } from 'semantic-ui-react';
import DescFbitsTabela from "./DescFBitsTabela";

function GroupDesconto({
    descontofbitsstatus,
    descontofbitsdt,
    libmarketplace,
    updateLibMarketPlace
}) {
    const [successMessage, setSuccessMessage] = useState(''); // Estado para a mensagem de sucesso
    const [errors, setErrors] = useState(null); // Inicialize errors como null


    return (
        <>
            {successMessage && (
                <Message
                    success
                    header='Sucesso!'
                    content={successMessage}
                />
            )}
            {errors && (
                <Message
                    error
                    header='Houve alguns erros com o seu envio'
                    content={errors}
                />
            )}
            <Tab.Pane loading={(descontofbitsstatus === -1 ? true : false)}>
                <DescFbitsTabela
                    descontofbitsstatus={descontofbitsstatus}
                    descontofbitsdt={descontofbitsdt}
                    libmarketplace={libmarketplace}
                    updateLibMarketPlace={updateLibMarketPlace}
                    setSuccessMessage={setSuccessMessage}
                    setErrors={setErrors}
                />
            </Tab.Pane>
        </>
    )
}

export default GroupDesconto;
