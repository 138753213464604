import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';


import If from '../../utils/If';

import {
    Grid, Menu, Breadcrumb, Tab, Icon
    , Table, Button, Popup, Checkbox
} from 'semantic-ui-react'


import {
    ProcessamentosDescontoFbits
    , ProcessamentosStartTimerAction
    , ProcessamentosStatusListAction
    , ProcessamentosStatusUpdateAction

    , ProcessamentosListParceiroAction
    , ProcessamentosChangeParceiroAction
    , ProcessamentosSaveParceiroAction
    , ProcessamentosLibMarketPlaceListAction
    , ProcessamentosLibMarketPlaceUpdateAction
} from '../../actions/ProcessamentosActions';

//import Fechamento from './Fechamento';


import Parceiros from './Parceiros';


import styled from 'styled-components';
import DescFbitsIndividual from './Descontos/DescFbitsIndividual';
import DescFbitsTabela from './Descontos/DescFBitsTabela';
import GroupDesconto from './Descontos/GroupDesconto';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class Processamentos extends Component {

    state = {}

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 12 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------



        //this.props.consultoresListAction().then(dados => { })

        //this.props.consultoresListCidadesAction().then(dados => { })

        this.props.ProcessamentosStartTimerAction()
        this.props.ProcessamentosStatusListAction()

        this.props.ProcessamentosLibMarketPlaceListAction()



    }

    componentWillReceiveProps() {

    }


    onUpdateStatus() {


        this.props.ProcessamentosStatusUpdateAction();

    }


    updateLibMarketPlace = (e, { checked, value, name }) => {


        if (checked === undefined) {
            this.props.ProcessamentosLibMarketPlaceUpdateAction(false)
        } else {
            let check = checked
            this.props.ProcessamentosLibMarketPlaceUpdateAction(check)
        }


        /*alert(`
        aki
        name:${name}
        value:${value}
        checked:${checked}
        `)*/

    }




    render() {

        let { descontofbitsstatus } = this.props

        let panes = []

        panes.push({
            menuItem: <Menu.Item key='TABparceiros'><Icon name='list alternate outline' />Parceiro</Menu.Item>,
            render: () =>
                <Tab.Pane>
                    <Parceiros
                        ProcessamentosParceiroEntity={this.props.ProcessamentosParceiroEntity}
                        issavingparceiro={this.props.issavingparceiro}
                        issearchingparceiro={this.props.issearchingparceiro}
                        ProcessamentosListParceiroAction={this.props.ProcessamentosListParceiroAction}
                        ProcessamentosChangeParceiroAction={this.props.ProcessamentosChangeParceiroAction}
                        ProcessamentosSaveParceiroAction={this.props.ProcessamentosSaveParceiroAction}
                    />
                </Tab.Pane>
        })


        panes.push({
            menuItem: <Menu.Item key='TABProcessamentos'><Icon name='list alternate outline' />VCC</Menu.Item>,
            render: () =>
                <>
                    <GroupDesconto
                        descontofbitsstatus={this.props.descontofbitsstatus}
                        libmarketplace={this.props.libmarketplace}
                        updateLibMarketPlace={this.updateLibMarketPlace}
                        descontofbitsdt={this.props.descontofbitsdt}

                    />
                    {/* <DescFbitsTabela
                        descontofbitsstatus={this.props.descontofbitsstatus}
                        libmarketplace={this.props.libmarketplace}
                        updateLibMarketPlace={this.updateLibMarketPlace}
                        descontofbitsdt={this.props.descontofbitsdt}
                    />
                    <DescFbitsIndividual /> */}
                </>
        })






        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Processamentos </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , descontoisloading: state.ProcessamentosReducer.descontofbitsisloading
        , descontofbitsstatus: state.ProcessamentosReducer.descontofbitsstatus
        , descontofbitsdt: state.ProcessamentosReducer.descontofbitsdt

        , ProcessamentosParceiroEntity: state.ProcessamentosReducer.ProcessamentosParceiroEntity
        , issavingparceiro: state.ProcessamentosReducer.issavingparceiro
        , issearchingparceiro: state.ProcessamentosReducer.issearchingparceiro
        , libmarketplace: state.ProcessamentosReducer.libmarketplace

    }
);


export default withRouter(connect(mapStateToProps, {
    ProcessamentosDescontoFbits
    , ProcessamentosStartTimerAction
    , ProcessamentosStatusListAction
    , ProcessamentosStatusUpdateAction

    , ProcessamentosListParceiroAction
    , ProcessamentosChangeParceiroAction
    , ProcessamentosSaveParceiroAction

    , ProcessamentosLibMarketPlaceListAction
    , ProcessamentosLibMarketPlaceUpdateAction
}
)(Processamentos));

