import sortBy from 'sort-by';

import {
    LOGIN_POST
    , LOGIN_MENU_POST
    , LOGIN_REDIRECT
} from '../actions/ActionsTypes';



const INITIAL_STATE = {
    isAuthenticated: sessionStorage.getItem("isAuthenticated") || false
    , login: sessionStorage.getItem("login") || ""
    , userid: sessionStorage.getItem("userid") || ""
    , vendedorvcc: sessionStorage.getItem("vendedorvcc") || ""
    , nomeparc: sessionStorage.getItem("nomeparc") || ""
    , menu: JSON.parse(sessionStorage.getItem("menu")) || []
    , redirect: ''
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        //desativado
        case LOGIN_POST:
            return { ...state, isAuthenticated: action.isAuthenticated, login: action.login, userid: action.userid, vendedorvcc: action.vendedorvcc, nomeparc: action.nomeparc }
        case LOGIN_MENU_POST:
            return { ...state, menu: action.payload }
        case LOGIN_REDIRECT:
            return { ...state, redirect: action.payload }
        default:
            return state;
    }
}