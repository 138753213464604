import React, { useCallback, useEffect, useRef, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import parse from 'html-react-parser';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

import {
    Table, Tab, Button, Icon, ModalHeader,
    ModalDescription,
    ModalContent,
    ModalActions,
    Modal,
    TextArea,
    Form,
    Dropdown,
    Input,
    Loader,
    Dimmer
} from 'semantic-ui-react';
import styled from 'styled-components';
import {
    pixListPixAction,
    pixFiltroPixAction,
    pixDetalhePixAction
} from '../../actions/PixActions';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;
const StyledCell = styled(Table.Cell)`
&&&{
    div{
        font-size:15px !important;
    }
    span{
        font-size:15px !important;
      }
    }
`;

function ListaPix(props) {
    //actions
    const { pixListPixAction, pixFiltroPixAction, pixDetalhePixAction } = props;

    //props reducer
    const { listpix, menu, history } = props;


    const [errors, setErrors] = useState(false);
    const [isLoadingList, setLoadingList] = useState(true);
    const [isLoadingPopup, setIsLoadingPopup] = useState(null);
    const [open, setOpen] = useState(false);
    const [pixPopup, setPixPopup] = useState([])
    const [pixIdPopup, setPixIdPopup] = useState(0)
    const [dropdownValue, setDropdownValue] = useState('');
    const [inputValue, setInputValue] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [lastScrollY, setLastScrollY] = useState(0);
    const [isLoadingScroll, setIsLoadingScroll] = useState(false);
    const [page, setPage] = useState(1);
    const [isInitialLoad, setIsInitialLoad] = useState(true);
    const [isCrescente, setIsCrescente] = useState(null);
    const [isPixidCrescente, setIsPixidCrescente] = useState(null);
    const datePickerRef = useRef(null);

    // ------ Effect para chamar as actions
    useEffect(() => {
        if (isInitialLoad) {
            const today = new Date();
            setStartDate(today);

            const jsonData = {
                dropdownValues: null,
                inputValue: null,
                startDate: null,
                endDate: null
            };

            setLoadingList(true);
            pixListPixAction(jsonData, { page: page }).then((d) => {
                setLoadingList(false);
                setIsInitialLoad(false); // Marca a carga inicial como concluída
            });

            if (menu.filter(m => m.menuid === 27 && m.status === 1).length === 0) {
                history.push(`../Home`);
            }
        }
    }, [menu, isInitialLoad, page, isCrescente, isPixidCrescente]);



    const handleScroll = useCallback(() => {
        const scrollY = window.scrollY || document.documentElement.scrollTop;
        const isScrolledToBottom = window.innerHeight + scrollY >= document.documentElement.scrollHeight - 2;


        const jsonData = {
            dropdownValues: dropdownValue,
            inputValue: inputValue,
            startDate: startDate,
            endDate: endDate,
            valorOrdem: isCrescente === true ? isPixidCrescente ? null : 'desc' : isCrescente === false ? isPixidCrescente ? null : 'asc' : isPixidCrescente !== null ? null : null,
            valorPixid: isPixidCrescente === true ? 'asc' : isPixidCrescente === false ? 'desc' : null,
        }


        if (!isLoadingScroll && isScrolledToBottom) {
            console.log('Rolou até o final, carregando mais dados...');
            setIsLoadingScroll(true);

            setPage((prevPage) => {
                const newPage = prevPage + 1;
                console.log('VARIAVEL NEW PAGE ->', newPage);

                pixFiltroPixAction(jsonData, { page: newPage })
                    .then(() => {
                        setIsLoadingScroll(false);
                    })
                    .catch(() => setIsLoadingScroll(false));

                return newPage;
            });
        }
    }, [dropdownValue, inputValue, startDate, endDate, isLoadingScroll, isCrescente, isPixidCrescente]);

    const handleFiltro = () => {
        if (isCrescente === null) {
            setIsCrescente(true);
        }
        setIsCrescente(!isCrescente);
        setLoadingList(true);

        console.log('SET IS CRESCENTE ->', isCrescente);

        const jsonData = {
            dropdownValues: dropdownValue,
            inputValue: inputValue,
            startDate: startDate,
            endDate: endDate,
            valorOrdem: isCrescente === true ? 'asc' : 'desc',
        }
        setPage(1);
        pixFiltroPixAction(jsonData).then((d) => {
            setLoadingList(false);
        });
    }

    const handlePixidFiltro = () => {

        if (isPixidCrescente === null) {
            setIsPixidCrescente(false);
        }

        setIsPixidCrescente(!isPixidCrescente);

        setLoadingList(true);
        const jsonData = {
            dropdownValues: dropdownValue,
            inputValue: inputValue,
            startDate: startDate,
            endDate: endDate,
            valorOrdem: null,
            pixidOrdem: isPixidCrescente === true ? 'asc' : 'desc',
        }
        setPage(1);
        pixFiltroPixAction(jsonData).then((d) => {
            setLoadingList(false);
        });
    }

    useEffect(() => {

        // pixFiltroPixAction(jsonData, { page: page });
        window.addEventListener('scroll', handleScroll);
        return () => window.removeEventListener('scroll', handleScroll);
    }, [handleScroll]);


    const onOpenPopup = (usuario) => {
        setIsLoadingPopup(usuario.pixid)
        let jsonData = { pixid: usuario.pixid }
        pixDetalhePixAction(jsonData).then((d) => {
            setIsLoadingPopup(null);
            setOpen(true);
        });
        setPixIdPopup(usuario)
    };
    const stateOptions = [
        { text: 'Todos', value: 0 },
        { text: 'Nunota', value: 1 },
        { text: 'PixId', value: 2 },
        { text: 'Codparc', value: 3 },
        { text: 'VCC', value: 4 },
        { text: 'Esplane', value: 5 }
    ];

    const handleSave = () => {
        const jsonData = {
            dropdownValues: dropdownValue,
            inputValue: inputValue,
            startDate: startDate,
            endDate: endDate
        };

        const newErrors = [];

        if (newErrors.length === 0) {
            setLoadingList(true);
            setErrors(false);
            setIsInitialLoad(false); // Define como falso para ignorar o carregamento inicial no próximo `useEffect`

            if (dropdownValue !== 0 && inputValue === '' && dropdownValue !== 4 && dropdownValue !== 5) {
                newErrors.push('O Busca é obrigatorio');
                // setErrors(true);
            }

            if ((dropdownValue === 0 && inputValue === '') || (dropdownValue === 0 && endDate == null)) {
                setPage(1);
                pixFiltroPixAction(jsonData).then((d) => {
                    countSim(d);
                    setLoadingList(false);
                });
            } else if (dropdownValue === 4) {
                setPage(1);
                jsonData.inputValue = 'VCC';
                pixFiltroPixAction(jsonData).then((d) => {
                    countSim(d);
                    setLoadingList(false);
                });
            } else if (dropdownValue === 5) {
                setPage(1);
                jsonData.inputValue = 'Esplane';
                pixFiltroPixAction(jsonData).then((d) => {
                    countSim(d);
                    setLoadingList(false);
                });
            } else {
                pixFiltroPixAction(jsonData).then((d) => {
                    countSim(d);
                    setLoadingList(false);
                });
            }
        }
    };
    const handleInputChange = (e) => {
        const value = e.target.value;
        if (/^\d*$/.test(value)) { // Verifica se o valor contém apenas dígitos
            setInputValue(value);
        }
    };
    const countSim = (listpix) => {
        return listpix.filter(item => item.status_pagto === 1).length;
    };

    const { detalhepix } = props;

    const handleRawChange = (e) => {
        let inputValue = e.target.value;

        // Verifica se o valor contém somente números e tem exatamente 8 dígitos
        if (/^\d{8}$/.test(inputValue)) {
            // Formata a data adicionando "/"
            inputValue = `${inputValue.slice(0, 2)}/${inputValue.slice(2, 4)}/${inputValue.slice(4)}`;
            e.target.value = inputValue; // Atualiza o valor no campo
        }
    };

    return (
        <Tab.Pane>
            <Modal
                open={open}
                onClose={() => setOpen(false)}
                onOpen={() => setOpen(true)}
            >
                <ModalHeader>PixID({pixIdPopup.pixid}) NuNota({pixIdPopup.nunota})</ModalHeader>
                <ModalContent image scrolling>
                    <ModalDescription>
                        {detalhepix?.map(pixItem => (
                            <>
                                <div key={pixItem.pixid} style={{ display: 'flex', flexWrap: 'wrap' }}>
                                    <div style={{ flex: '1 1 50%', padding: '10px' }}>
                                        <section>
                                            <h3>Informações do Devedor</h3>
                                            <span><strong>Devedor Nome:</strong> {pixItem.devedor_nome}</span><br></br>
                                            <span><strong>Devedor Documento:</strong> {pixItem.devedor_doc}</span><br></br>
                                            <span><strong>Codigo Parceiro:</strong> {pixItem.codparc}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Mensagens do Vendedor</h3>
                                            <span><strong>Cod Vendedor:</strong> {pixItem.codvend}</span><br></br>
                                            <span><strong>Nome Vendedor:</strong> {pixItem.nome_vend}</span><br></br>
                                            <span><strong>Email Vendedor:</strong> {pixItem.email_vend}</span><br></br>
                                            <span><strong>ID Mensagem:</strong> {pixItem.mensagemid}</span><br></br>
                                            <span><strong>ID WT:</strong> {pixItem.wtid}</span><br></br>
                                            <span><strong>ID Mensagem Vendedor:</strong> {pixItem.mensagemid_vendedor}</span><br></br>
                                            <span><strong>ID Mensagem Vendedor Pagamento:</strong> {pixItem.mensagemid_vendedor_pagto}</span><br></br>
                                            <span><strong>Data Inserção:</strong>{pixItem.dt_insert ? new Date(pixItem.dt_insert).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            }) : ' '} </span><br></br>
                                            <span><strong>Data Envio:</strong> {pixItem.mensagem_vendedor_dtenvio ? new Date(pixItem.mensagem_vendedor_dtenvio).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            }) : ' '}</span><br></br>
                                            <span><strong>Status Mensagem:</strong> {pixItem.mensagem_vendedor_status}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Mensagens do Cliente</h3>
                                            <span><strong>Email To:</strong> {pixItem.mensagem_cliente_emailto}</span><br></br>
                                            <span><strong>Data Envio:</strong> {pixItem.mensagem_cliente_dtenvio ? new Date(pixItem.mensagem_cliente_dtenvio).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            }) : ' '}</span><br></br>
                                            <span><strong>Status Mensagem:</strong> {pixItem.mensagem_cliente_status}</span><br></br>
                                        </section>
                                    </div>


                                    <div style={{ flex: '1 1 50%', padding: '10px' }}>
                                        <section>
                                            <h3>Detalhes da Transação</h3>
                                            <span><strong>TxID:</strong> {pixItem.txid}</span><br></br>
                                            <span><strong>Nunota:</strong> {pixItem.nunota}</span><br></br>
                                            <span><strong>E2EID:</strong> {pixItem.e2eid}</span><br></br>
                                            <span><strong>Valor:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pixItem.valor)}</span><br></br>
                                            <span><strong>Valor Original:</strong> {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(pixItem.valor_original)}</span><br></br>
                                            <span style={{ maxWidth: '500px', wordBreak: 'break-word', whiteSpace: 'normal', display: 'block' }}><strong>Copia e Cola:</strong> {pixItem.pix_cop_col}</span><br></br>

                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Status</h3>
                                            <span><strong>Status SQS:</strong> {pixItem.status_sqs == 0 ? 'Pendente' : pixItem.status_sqs == 1 ? 'Aguardando Itau' : pixItem.status_sqs == 2 ? 'Pix Criado' : pixItem.status_sqs == 3 ? 'Erro' : pixItem.status_sqs == 9 ? 'Pix Cancelado' : ''}</span><br></br>
                                            <span><strong>Data Status SQS:</strong> {pixItem.dt_status_sqs ? new Date(pixItem.dt_status_sqs).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            }) : ' '}</span><br></br>
                                            <span><strong>Status Pagamento:</strong> {pixItem.status_pagto == 0 ? 'Não' : 'Sim'}</span><br></br>
                                            <span><strong>Data Status Pagamento:</strong> {pixItem.dt_status_pagto ? new Date(pixItem.dt_status_pagto).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            }) : ' '}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>Mensagens de Pagamento</h3>
                                            <span><strong>Enviado Para:</strong>{pixItem.mensagem_pagto_vendedor_emailto}</span><br></br>
                                            <pspan><strong>Data Envio:</strong> {pixItem.mensagem_pagto_vendedor_dtenvio ? new Date(pixItem.mensagem_pagto_vendedor_dtenvio).toLocaleDateString('pt-BR', {
                                                day: '2-digit',
                                                month: '2-digit',
                                                year: 'numeric',
                                                hour: '2-digit',
                                                minute: '2-digit',
                                                second: '2-digit'
                                            }) : ' '}</pspan><br></br>
                                            <span><strong>Status Mensagem:</strong> {pixItem.mensagem_pagto_vendedor_status}</span><br></br>
                                        </section>
                                        <hr></hr>
                                        <section>
                                            <h3>QRCode</h3>
                                            {/* <p><strong>QRCode:</strong> {pixItem.qrcode}</p> */}
                                            <img src={pixItem.qrcode_base64} alt="Imagem qrcode" />
                                            {/* <p><strong>QRCode Base64:</strong> {pixItem.qrcode_base64}</p> */}
                                        </section>
                                        <section>
                                            <h3>API</h3>
                                            <p><strong>API Request Pix:</strong></p>
                                            <Form>
                                                <TextArea>{JSON.stringify(pixItem.apirequest_pix, null, 2)}</TextArea>
                                                <p><strong>API Response Pix:</strong></p>
                                                <TextArea>{JSON.stringify(pixItem.apiresponse_pix, null, 2)}</TextArea>
                                                <p><strong>API Response Pagamento:</strong></p>
                                                <TextArea>{JSON.stringify(pixItem.apiresponse_pagto, null, 2)}</TextArea>
                                                <p><strong>Status Code Response:</strong> {pixItem.status_code_response}</p>
                                                <p><strong>Log Message:</strong></p>
                                                <TextArea>{JSON.stringify(pixItem.logmsg, null, 2)}</TextArea>
                                            </Form>
                                        </section>
                                    </div>
                                </div>
                                <section>
                                    <strong>Mensagem Pagamento Vendedor:</strong>
                                    <div style={{
                                        width: '800px',
                                        height: '300px', /* You can set a fixed height as per your requirement */
                                        overflowY: 'scroll',
                                        border: '1px solid #ccc',
                                        padding: '10px'
                                    }} ><p>{pixItem.mensagem_pagto_vendedor ? parse(pixItem.mensagem_pagto_vendedor) : ''}</p><hr></hr></div><br></br>
                                    <strong>Mensagem Cliente:</strong>
                                    <div style={{
                                        width: '800px',
                                        height: '300px', /* You can set a fixed height as per your requirement */
                                        overflowY: 'scroll',
                                        border: '1px solid #ccc',
                                        padding: '10px'
                                    }} ><p> {pixItem.mensagem_cliente ? parse(pixItem.mensagem_cliente) : ''}</p><hr></hr></div><br></br>
                                    <strong>Mensagem Vendedor:</strong>
                                    <div style={{
                                        width: '800px',
                                        height: '300px', /* You can set a fixed height as per your requirement */
                                        overflowY: 'scroll',
                                        border: '1px solid #ccc',
                                        padding: '10px'
                                    }} ><p>{pixItem.mensagem_vendedor ? parse(pixItem.mensagem_vendedor) : ''}</p><hr></hr></div><br></br>
                                </section>
                            </>
                        ))}
                    </ModalDescription>
                </ModalContent>
                <ModalActions>
                    <Button onClick={() => setOpen(false)} color='red'>
                        Fechar  <Icon name='cancel' />
                    </Button>
                </ModalActions>
            </Modal >


            <div key='1'>
            </div>
            <Tab.Pane style={{ margin: '0px' }} >
                <h3>Buscar Pix</h3>
                <StyledTable celled id="listPendentes" compact="very" size="">
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell width="1">NU/Pixid/Codparc</Table.HeaderCell>
                            <Table.HeaderCell width="3">Busca</Table.HeaderCell>
                            <Table.HeaderCell width="4">Data Inicial</Table.HeaderCell>
                            <Table.HeaderCell width="4">Data Final</Table.HeaderCell>
                            <Table.HeaderCell width="1"></Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>
                    <Table.Row>
                        <Table.Cell>
                            <Dropdown
                                selection
                                name='Filtrar'
                                options={stateOptions}
                                fluid
                                placeholder='Filtrar'
                                onChange={(e, { value }) => { setDropdownValue(value); setInputValue('') }}
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Input
                                disabled={dropdownValue === 0 || dropdownValue === 4 || dropdownValue === 5}
                                placeholder={dropdownValue === 0 && 'Não preenchível...' || dropdownValue === 4 && 'Não preenchível...' || dropdownValue === 5 && 'Não preenchível...'}
                                error={errors}
                                onChange={handleInputChange}
                                value={inputValue} // Adiciona o valor do input controlado
                            />
                        </Table.Cell>
                        <Table.Cell>
                            <Form>
                                <div className="field">
                                    <div>
                                        <DatePicker
                                            selected={endDate}
                                            onChange={(date) => setEndDate(date)}
                                            isClearable={true}
                                            todayButton={"Hoje"}
                                            name="dtfinal"
                                            id="dtfinal"
                                            dateFormat="dd/MM/yyyy"
                                            required={true}
                                            onChangeRaw={handleRawChange}
                                        />
                                    </div>
                                </div>
                            </Form>
                        </Table.Cell>
                        <Table.Cell>
                            <Form>
                                <div className="field">
                                    <div>
                                        <DatePicker
                                            selected={startDate}
                                            onChange={(date) => setStartDate(date)}
                                            isClearable={true}
                                            todayButton={"Hoje"}
                                            name="dtinicial"
                                            id="dtinicial"
                                            dateFormat="dd/MM/yyyy"
                                            required={true}
                                            onChangeRaw={handleRawChange}
                                            customInput={<input ref={datePickerRef} />}
                                        />

                                    </div>
                                </div>
                            </Form>

                        </Table.Cell>
                        <Table.Cell>
                            <Button basic color="blue" onClick={handleSave}>
                                <Icon name="check" />
                                Buscar
                            </Button>
                        </Table.Cell>
                    </Table.Row>
                </StyledTable>
            </Tab.Pane>
            <Tab.Pane loading={isLoadingList}>
                <StyledTable celled id="listPendentes" compact="very" size="small">
                    <StyledTableHeader>
                        {/* Cabeçalhos da Tabela */}
                        <Table.Row>
                            <Table.HeaderCell width="1" style={{ textAlign: 'center', display: 'flex' }}>PixID <Button onClick={handlePixidFiltro} style={{ all: 'unset', marginLeft: '5px', cursor: 'pointer' }}><Icon name={isPixidCrescente === true || isPixidCrescente == null ? 'arrow alternate circle up outline' : isPixidCrescente === false ? 'arrow alternate circle down outline' : null} /></Button></Table.HeaderCell>
                            <Table.HeaderCell width="4">Codparc/Nome</Table.HeaderCell>
                            <Table.HeaderCell width="1" style={{ textAlign: 'center' }}>Nunota</Table.HeaderCell>
                            <Table.HeaderCell width="1.5" style={{ textAlign: 'center', display: 'flex' }}>Valor<Button onClick={handleFiltro} style={{ all: 'unset', marginLeft: '5px', cursor: 'pointer' }}><Icon name={isCrescente === true || isCrescente === null ? 'arrow alternate circle up outline' : isCrescente === false ? 'arrow alternate circle down outline' : null} /></Button></Table.HeaderCell>
                            <Table.HeaderCell width="2" style={{ textAlign: 'center' }}>Data de Criação</Table.HeaderCell>
                            <Table.HeaderCell width="1.2" style={{ textAlign: 'center' }}>Status</Table.HeaderCell>
                            <Table.HeaderCell width="1" style={{ textAlign: 'center' }}>Pago({listpix[0]?.pix_pagos})</Table.HeaderCell>
                            <Table.HeaderCell width="2" style={{ textAlign: 'center' }}>Data Pagto</Table.HeaderCell>
                            <Table.HeaderCell style={{ textAlign: 'center' }}>Detalhes</Table.HeaderCell>
                            <Table.HeaderCell width="1">Empresa</Table.HeaderCell>
                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {/* Mapeia dados de 'listpix' */}
                        {listpix.map((item) => (
                            <Table.Row key={"usuariosmgr" + item.pixid}>
                                <Table.Cell style={{ textAlign: 'center' }}>{item.pixid}</Table.Cell>
                                <Table.Cell>({item.codparc}) {item.devedor_nome}</Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>{item.nunota}</Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>
                                    {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}
                                </Table.Cell>
                                <Table.Cell>
                                    {new Date(item.dt_insert).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    })}
                                </Table.Cell>
                                <Table.Cell style={{
                                    textAlign: 'center',
                                    color: item.status_sqs === 0 ? 'black' :
                                        item.status_sqs === 1 ? 'orange' :
                                            item.status_sqs === 2 ? 'green' :
                                                item.status_sqs === 3 ? 'red' :
                                                    item.status_sqs === 9 ? 'red' : 'black',
                                }}>
                                    {item.status_sqs === 0 ? 'Pendente' :
                                        item.status_sqs === 1 ? 'Aguardando Itau' :
                                            item.status_sqs === 2 ? 'Pix Criado' :
                                                item.status_sqs === 3 ? 'Erro' :
                                                    item.status_sqs === 9 ? 'Pix Cancelado' : ''}
                                </Table.Cell>
                                <Table.Cell style={{ textAlign: 'center', color: item.status_pagto === 0 ? 'red' : 'green' }}>
                                    {item.status_pagto === 0 ? 'Não' : 'Sim'}
                                </Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>
                                    {item.dt_status_pagto ? new Date(item.dt_status_pagto).toLocaleDateString('pt-BR', {
                                        day: '2-digit',
                                        month: '2-digit',
                                        year: 'numeric',
                                        hour: '2-digit',
                                        minute: '2-digit',
                                        second: '2-digit'
                                    }) : ''}
                                </Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>
                                    <Button
                                        key={item.pixid}
                                        loading={isLoadingPopup === item.pixid}
                                        onClick={() => onOpenPopup(item)}
                                        basic
                                        color="green"
                                        size="mini"
                                    >
                                        <Icon name="pencil" />
                                        Detalhes
                                    </Button>
                                </Table.Cell>
                                <Table.Cell style={{ textAlign: 'center' }}>
                                    {item.tipovendedor === 3 || item.tipovendedor === 0 ? 'Esplane' : 'VCC'}
                                </Table.Cell>
                            </Table.Row>
                        ))}
                        {/* Indicador de carregamento de mais itens */}
                        {isLoadingScroll && (
                            <Table.Row>
                                <Table.Cell colSpan="10" style={{ textAlign: 'center' }}>
                                    <Loader active inline />
                                </Table.Cell>
                            </Table.Row>
                        )}
                    </StyledTableBody>
                </StyledTable>
            </Tab.Pane>

        </Tab.Pane >
    )
}

const mapStateToProps = (state) => ({
    menu: state.LoginReducer.menu
    , userid: state.LoginReducer.userid
    , listpix: state.PixReducer.listpix
    , detalhepix: state.PixReducer.detalhepix
})


export default withRouter(connect(mapStateToProps, {
    pixListPixAction,
    pixFiltroPixAction,
    pixDetalhePixAction
}
)(ListaPix));