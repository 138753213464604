import React, { useEffect } from 'react';
import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
} from 'semantic-ui-react'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import Relatorio from './Relatorio.js';

function Whatsapp(props) {

    let panes = [
        {
            menuItem: <Menu.Item key='TABpendente'><Icon name='chart bar outline' />Relatorio</Menu.Item>,
            render: () =>
                <Relatorio />,
        },
    ]


    return (
        <>
            <Menu stackable size='mini'>
                <Menu.Item>
                    <Breadcrumb size='mini'>
                        <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                        <Breadcrumb.Divider icon='right chevron' />
                        <Breadcrumb.Section active>Whatsapp</Breadcrumb.Section>
                    </Breadcrumb>
                </Menu.Item>
            </Menu>
            <div>
                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        </>
    );
}

export default Whatsapp;