import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Table, Label, Grid, Button, Input

} from 'semantic-ui-react'
import { despesasDeViagensListAssinaturasFilterAction, despesasDeViagensListAssinaturasAction } from '../../actions/DespesasDeViagensActions'
import If from '../../utils/If';

import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
    max-width: 600px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 4px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class Assinaturas extends Component {


    state = {}

    constructor(props) {
        super(props);
        this.state = {
            inputValue: ''
        };
    }


    componentWillReceiveProps() {




    }

    componentDidMount() {




    }

    calculaTotal(despesas) {

        let valor = 0
        for (let i of despesas) {
            valor += i.valor
        }
        return valor
    }


    handleInputChange = (e) => {
        this.setState({ inputValue: e.target.value });
    };

    handleClick = () => {
        let data = {
            oc: this.state.inputValue
        }
        if (this.state.inputValue == '') {
            this.props.despesasDeViagensListAssinaturasAction().then(dados => { })
        } else {
            this.props.despesasDeViagensListAssinaturasFilterAction(data).then((dados) => {
                console.log('chamou a action', dados)
            });
        }

        console.log("Valor do input:", this.state.inputValue);
    };

    render() {
        let { cargas, userid, usersrh } = this.props

        let blnBloqueia = true


        if (usersrh.filter(i => i.toString() === userid.toString()).length > 0) {
            blnBloqueia = false
        }



        return (


            <div>
                <h5>Busca por Ordem de Carga</h5>
                <div style={{ display: 'flex', alignItems: 'center', marginBottom: '1rem' }}>
                    <div style={{ display: 'flex', gap: '0.5rem' }}>
                        <Input
                            placeholder=''
                            value={this.state.inputValue}
                            onChange={this.handleInputChange}
                            onKeyPress={(event) => {
                                if (!/[0-9]/.test(event.key)) {
                                    event.preventDefault();
                                }
                            }}
                        />
                    </div>
                    <Button style={{ marginLeft: '15px' }} onClick={this.handleClick} loading={this.props.isLoadingAssinaturasFilter}>
                        Buscar
                    </Button>
                </div>
                {
                    cargas.map(ordem =>
                        <Grid doubling columns={2} id={`grid${new Date()}`} key={`grid${ordem.ordemcarga}`}>
                            <If test={ordem.motoristadespesas.length > 0}>
                                {ordem.motoristadespesas.map(despesadia =>
                                    <Grid.Column id={`grid1${new Date()}`} key={`grid1${ordem.ordemcarga}`}>

                                        {
                                            despesadia.despesas.map(despesaquebrada =>

                                                <div id={`div1${new Date()}`} key={`div${ordem.ordemcarga}`} style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                    <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                    <br></br><b>Motorista:</b> {ordem.motorista}
                                                    <br></br>
                                                    <br></br>
                                                    <StyledTable celled>
                                                        <StyledTableHeader>
                                                            <Table.Row>
                                                                <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                            </Table.Row>
                                                        </StyledTableHeader>

                                                        <StyledTableBody key="bodymotorista">
                                                            {

                                                                despesaquebrada.map(despesa => (

                                                                    <Table.Row key={'m' + despesa.idviagemdespesa}>
                                                                        <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                    </Table.Row>
                                                                ))
                                                            }

                                                        </StyledTableBody>

                                                        <StyledTableFooter>
                                                            <Table.Row>
                                                                <Table.HeaderCell>
                                                                    <Button disabled={blnBloqueia} size='mini' basic color='red' onClick={(e) => this.props.handleRecebidoRH(ordem.ordemcarga, despesaquebrada)}>Recebido pelo RH</Button>
                                                                </Table.HeaderCell>

                                                                <Table.HeaderCell><Label color='green' horizontal>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</Label></Table.HeaderCell>
                                                            </Table.Row>
                                                        </StyledTableFooter>
                                                    </StyledTable>
                                                </div>
                                            )
                                        }
                                    </Grid.Column>
                                )}
                            </If>



                            <If test={ordem.ajudante != "<Sem Ajudante>"} key={`grid5${ordem.ordemcarga}`}>
                                {ordem.ajudantedespesas.map(despesadia =>
                                    <Grid.Column id={`grid2${new Date()}`} key={`grid2${ordem.ordemcarga}`}>


                                        {
                                            despesadia.despesas.map(despesaquebrada =>

                                                <div id={`div2${new Date()}`} key={`div1${ordem.ordemcarga}`} style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                    <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                    <br></br><b>Ajudante:</b> {ordem.ajudante}
                                                    <br></br>
                                                    <br></br>
                                                    <StyledTable celled>
                                                        <StyledTableHeader>
                                                            <Table.Row>
                                                                <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                            </Table.Row>
                                                        </StyledTableHeader>

                                                        <StyledTableBody key="bodyajudante">
                                                            {

                                                                despesaquebrada.map(despesa => (

                                                                    <Table.Row key={'m1' + despesa.idviagemdespesa}>
                                                                        <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                    </Table.Row>
                                                                ))
                                                            }

                                                        </StyledTableBody>

                                                        <StyledTableFooter>
                                                            <Table.Row>
                                                                <Table.HeaderCell>
                                                                    <Button disabled={blnBloqueia} size='mini' basic color='blue' onClick={(e) => this.props.handleRecebidoRH(ordem.ordemcarga, despesaquebrada)}>Recebido pelo RH</Button>
                                                                </Table.HeaderCell>


                                                                <Table.HeaderCell><Label color='green' horizontal>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</Label></Table.HeaderCell>
                                                            </Table.Row>
                                                        </StyledTableFooter>
                                                    </StyledTable>
                                                </div>
                                            )
                                        }
                                    </Grid.Column>
                                )}
                            </If>



                            <If test={ordem.ajudanteduplo != "<Sem Ajudante>"}>
                                {ordem.ajudanteduplodespesas.map(despesadia =>
                                    <Grid.Column id={`grid3${new Date()}`} key={`grid3${ordem.ordemcarga}`}>

                                        {
                                            despesadia.despesas.map(despesaquebrada =>

                                                <div id={`div3${new Date()}`} key={`div2${ordem.ordemcarga}`} style={{ border: '1px solid #000000', width: '500px', textAlign: 'center', margin: '5px 0px 0px 0px' }}>
                                                    <b>Ordem de Carga:</b> {ordem.ordemcarga} | <b>Data:</b> {moment(despesadia.dia).format("DD/MM/YYYY")}
                                                    <br></br><b>Ajudante Duplo:</b> {ordem.ajudanteduplo}
                                                    <br></br>
                                                    <br></br>
                                                    <StyledTable celled>
                                                        <StyledTableHeader>
                                                            <Table.Row>
                                                                <Table.HeaderCell width={3}>Despesa</Table.HeaderCell>

                                                                <Table.HeaderCell width={1}>Valor</Table.HeaderCell>

                                                            </Table.Row>
                                                        </StyledTableHeader>

                                                        <StyledTableBody key="bodyajudanteduplo">
                                                            {

                                                                despesaquebrada.map(despesa => (

                                                                    <Table.Row key={'m2' + despesa.idviagemdespesa}>
                                                                        <Table.Cell>{despesa.viagemdespesa} - {despesa.tipodespesaviagem}</Table.Cell>

                                                                        <Table.Cell>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</Table.Cell>
                                                                    </Table.Row>
                                                                ))
                                                            }

                                                        </StyledTableBody>

                                                        <StyledTableFooter>
                                                            <Table.Row>
                                                                <Table.HeaderCell>
                                                                    <Button disabled={blnBloqueia} size='mini' basic color='green' onClick={(e) => this.props.handleRecebidoRH(ordem.ordemcarga, despesaquebrada)}>Recebido pelo RH</Button>
                                                                </Table.HeaderCell>


                                                                <Table.HeaderCell><Label color='green' horizontal>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.calculaTotal(despesaquebrada))}</Label></Table.HeaderCell>
                                                            </Table.Row>
                                                        </StyledTableFooter>
                                                    </StyledTable>
                                                </div>
                                            )
                                        }
                                    </Grid.Column>
                                )}
                            </If>




                        </Grid>


                    )
                }
            </div>



        )

    }

}

Assinaturas.defaultProps = {
    cargas: {}
    , userid: ""
    , usersrh: []
};

Assinaturas.propTypes = {
    cargas: PropTypes.array.isRequired
    , handleRecebidoRH: PropTypes.func.isRequired
    , userid: PropTypes.string.isRequired
    , usersrh: PropTypes.array.isRequired
};

const mapStateToProps = state => (
    {
        isLoadingAssinaturasFilter: state.DespesasDeViagensReducer.isLoadingAssinaturasFilter
    }
);

export default withRouter(connect(mapStateToProps, {
    despesasDeViagensListAssinaturasFilterAction,
    despesasDeViagensListAssinaturasAction
}
)(Assinaturas));
