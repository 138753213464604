export const  B2BParceiroEntity = {
    codparc: 0
    ,nomeparc:''
    ,ipiincicms:''
    ,ad_ultpedfbits:''
    ,codtab:0
    ,classificacaofin:''
    ,classificacaofinnovo:''
    ,tipoclassificacaofin:''
    ,dtclassificacaofin:''
    ,valormina:''
    ,valorminb:''
    ,valormeses:''
    ,mesestotal:''
    ,nometab:''

}