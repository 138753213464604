import {
    PIX_LIST_PIX,
    PIX_FILTRO_PIX,
    PIX_DETALHE_PIX,
    PIX_APPEND_PIX
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    listpix: [],
    // filtropix: []
    detalhepix: null
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PIX_FILTRO_PIX:
            return { ...state, listpix: action.payload }; // Substitui a lista na primeira página
        case PIX_APPEND_PIX:
            return { ...state, listpix: [...state.listpix, ...action.payload] }; // Concatena novos itens
        case PIX_DETALHE_PIX:
            return { ...state, detalhepix: action.payload };
        default:
            return state;
    }
};