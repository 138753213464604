import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Tab, Dropdown, Form, Progress } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';
import { Line } from 'react-chartjs-2';

import If from '../../utils/If';

const StyledDropdown = styled(Dropdown)`
  &&& {
    div{
        font-size:10px !important;
        width:80px !important;
    },
    span{
        font-size:10px !important;
        width:80px !important;
    }

   }`

const opcoes = {
    scales: {
        yAxes: [
            {
                ticks: {
                    callback: function (label, index, labels) {
                        //return 'R$' + label.toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&.');
                        return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(label)
                        if (label > 75) {
                            return "Expert: " + label;
                        } else if (label > 50) {
                            return "Advanced: " + label;
                        } else if (label > 25) {
                            return "Intermediate: " + label;
                        } else {
                            return "Beginner: " + label;
                        }

                    }
                }
            }
        ]
    },
    responsive: true,

    tooltips: {
        callbacks: {
            title: function () {
                return '';
            },
            label: function (item, data) {
                var datasetLabel = data.datasets[item.datasetIndex].label || '';
                var dataPoint = item.yLabel;
                return datasetLabel + ' ' + new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(dataPoint);
            }
        },
        mode: "x",//esse faz aparecer todos tooltip do dia
    }
}

let trataDecimal = (valor) => {
    if (valor != null) {
        valor = valor.toFixed(2)
    }
    return valor
}


let escolherCor = (count) => {
    let cor;
    switch (count) {
        case 0:
            cor = '151,187,205'
            break;
        case 1: {
            cor = '237,60,29'
            break;
        }
        case 2: {
            cor = '15,184,111'
            break;
        }
        case 3: {
            cor = '212,160,17'
            break;
        }
        case 4: {
            cor = '147,15,184'
            break;
        }
        case 5: {
            cor = '59,17,212'
            break;
        }
        case 6: {
            cor = '17,206,212'
            break;
        }
        case 7: {
            cor = '212,128,17'
            break;
        }
        case 8: {
            cor = '0,0,0'
            break;
        }
        case 9: {
            cor = '204,148,148'
            break;
        }
        case 10: {
            cor = '71,127,41'
            break;
        }
        default: {
            cor = '220,220,220'
            break;
        }
    }
    return cor
}


class EsplaneChart extends Component {

    state = {
        dropDataSelecionado: ''
        , vendido: 0
        , previsao: 0
        , pedidos: 0
        , ticketmedio: 0
        , meta100: 0
        , meta100pct: 0
        , meta100falta: 0
        , meta110: 0
        , meta110pct: 0
        , meta110falta: 0
        , progressCorrente: 0
        , progressTotal: 0
        , dut: 0
        , duf: 0

    };



    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {
        this.selecionaDataPadrao()
    }

    componentWillReceiveProps() {
        if (this.state.dropDataSelecionado === '') {
            this.selecionaDataPadrao()
        } else {
            if (this.props.dataDrop.length > 0) {
                let valor = this.props.dataDrop[0].value.toString()
                this.getDataSelecionada(valor)
            }
        }
    }

    selecionaDataPadrao = () => {
        if (this.props.dataDrop.length > 0) {
            let valor = this.props.dataDrop[0].value.toString()
            this.setState({ dropDataSelecionado: valor })
            this.getDataSelecionada(valor)
        }
    }

    getDataSelecionada = (anomes) => {
        let dadoCorrente = this.props.dataList.filter(f => f.anomes === anomes)[0]

        this.setState({ vendido: dadoCorrente.fechado })
        this.setState({ previsao: dadoCorrente.previsao })
        this.setState({ pedidos: dadoCorrente.pedidos })
        this.setState({ ticketmedio: dadoCorrente.ticketmedio })

        this.setState({ meta100: dadoCorrente.meta100 })
        this.setState({ meta100pct: dadoCorrente.meta100pct })
        this.setState({ meta100falta: dadoCorrente.meta100falta })

        this.setState({ meta110: dadoCorrente.meta110 })
        this.setState({ meta110pct: dadoCorrente.meta110pct })
        this.setState({ meta110falta: dadoCorrente.meta110falta })

        this.setState({ dut: dadoCorrente.uteis })
        this.setState({ duf: dadoCorrente.uteis - dadoCorrente.uteishoje })


        if (dadoCorrente.meta100pct <= 100) {
            //calculando progressbar
            this.setState({ progressCorrente: parseInt(dadoCorrente.meta100pct) })
            this.setState({ progressTotal: 100 })
        } else {
            //calculando progressbar
            this.setState({ progressCorrente: parseInt(dadoCorrente.meta100pct) })
            this.setState({ progressTotal: parseInt(dadoCorrente.meta100pct) })
        }



    }

    //DropDowns
    handleDropData = (e, { value }) => {
        this.setState({ dropDataSelecionado: value.toString() })
        this.getDataSelecionada(value.toString())
    }


    render() {
        let { dataList, loader, dataDrop
        } = this.props;

        let dados = {}
        dados.labels = []
        dados.datasets = []
        dados.labels.push("1", "2", "3", "4", "5", "6", "7", "8", "9", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23", "24", "25", "26", "27", "28", "29", "30", "31")

        let count = 0;
        count = 0
        for (let i of dataList) {
            let ds = {};

            ds.label = i.anomes
            ds.fill = false

            if (count === 0 || i.anomes === 'Meta 100%') {
                ds.hidden = false
            } else {
                ds.hidden = true
            }

            ds.lineTension = 0.0
            ds.backgroundColor = `rgba(${escolherCor(count)},0.4)`
            ds.borderColor = `rgba(${escolherCor(count)},1)`
            ds.borderCapStyle = 'butt'
            ds.borderDash = []
            ds.borderDashOffset = 0.0
            ds.borderJoinStyle = 'miter'
            ds.pointBorderColor = `rgba(${escolherCor(count)},1)`
            ds.pointBackgroundColor = '#fff'
            ds.pointBorderWidth = 1
            ds.pointHoverRadius = 5
            ds.pointHoverBackgroundColor = `rgba(${escolherCor(count)},1)`
            ds.pointHoverBorderColor = 'rgba(220,220,220,1)'
            ds.pointHoverBorderWidth = 2
            ds.pointRadius = 4,
                ds.pointHitRadius = 10,

                ds.data =
                [trataDecimal(i.dia01), trataDecimal(i.dia02), trataDecimal(i.dia03), trataDecimal(i.dia04), trataDecimal(i.dia05)
                    , trataDecimal(i.dia06), trataDecimal(i.dia07), trataDecimal(i.dia08), trataDecimal(i.dia09), trataDecimal(i.dia10)
                    , trataDecimal(i.dia11), trataDecimal(i.dia12), trataDecimal(i.dia13), trataDecimal(i.dia14), trataDecimal(i.dia15)
                    , trataDecimal(i.dia16), trataDecimal(i.dia17), trataDecimal(i.dia18), trataDecimal(i.dia19), trataDecimal(i.dia20)
                    , trataDecimal(i.dia21), trataDecimal(i.dia22), trataDecimal(i.dia23), trataDecimal(i.dia24), trataDecimal(i.dia25)
                    , trataDecimal(i.dia26), trataDecimal(i.dia27), trataDecimal(i.dia28), trataDecimal(i.dia29), trataDecimal(i.dia30)
                    , trataDecimal(i.dia31)]
            dados.datasets.push(ds)

            count++
        }

        let exibe = false

        if (this.props.dataDrop.length > 0) {
            if (this.props.dataDrop[0].value.toString() == this.state.dropDataSelecionado) {
                exibe = true
            }
        }


        return (
            <Tab.Pane loading={loader}>
                <Form>
                    <Form.Group widths='equal' color='gray'>
                        <div className="field">
                            <label>Ano/Mês: {this.state.dropDataSelecionado}</label>
                            <div>
                                <StyledDropdown
                                    options={dataDrop}
                                    selection
                                    placeholder='Ano/Mês'
                                    name='anomes'
                                    fluid
                                    value={this.state.dropDataSelecionado}
                                    onChange={this.handleDropData}
                                    scrolling
                                />
                            </div>

                        </div>

                        <div className="field">
                            <label>Vendido:</label>
                            <div>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.vendido)}
                            </div>
                        </div>

                        <div className="field">
                            <label>Previsão:</label>
                            <div>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.previsao)}
                            </div>
                        </div>

                        <div className="field">
                            <label>Pedidos:</label>
                            <div>
                                {this.state.pedidos}
                            </div>
                        </div>
                        <div className="field">
                            <label>Ticket Médio:</label>
                            <div>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.ticketmedio)}
                            </div>
                        </div>



                    </Form.Group>
                    <Form.Group color='gray' widths={(exibe && this.state.duf>0? '16' : 'equal')}>
                        <div className="field">
                            <label>Meta 100% - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta100)} - {Number(this.state.meta100pct).toFixed(2)}%
                            {(this.state.meta100falta > 0 ? `- (faltam: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta100falta)})` : "")}
                            </label>
                        </div>
                        <If test={exibe && this.state.duf>0}>
                            <div className="field">
                                <label>
                                    {parseFloat(this.state.meta100 / this.state.dut / 1000).toFixed(0)}k|{parseFloat(this.state.meta100falta / this.state.duf / 1000).toFixed(0)}k
                            </label>
                            </div>
                            <div className="field">
                                <label> &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </label>
                            </div>
                        </If>

                        <div className="field">
                            <label>Meta 110% - {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta110)} - {Number(this.state.meta110pct).toFixed(2)}%
                            {(this.state.meta110falta > 0 ? `- (faltam: ${new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(this.state.meta110falta)})` : "")}
                            </label>
                        </div>
                        <If test={exibe && this.state.duf>0}>
                            <div className="field">
                                <label>
                                    {parseFloat(this.state.meta110 / this.state.dut / 1000).toFixed(0)}k|{parseFloat(this.state.meta110falta / this.state.duf / 1000).toFixed(0)}k
                            </label>
                            </div>
                        </If>
                    </Form.Group>
                    <Form.Group widths='equal' color='gray'>
                        <div className="field">
                            <div>
                                <Progress value={this.state.progressCorrente} total={this.state.progressTotal} progress='value' indicating></Progress>
                            </div>
                        </div>
                    </Form.Group>
                </Form>
                {
                    (dados.datasets.length > 0
                        ? <Line data={dados} width={450} heigth={450} options={opcoes} />
                        : "")
                }


            </Tab.Pane>
        )
    }
}

EsplaneChart.defaultProps = {
    dataList: []
    , loader: true
    , dataDrop: []
};

EsplaneChart.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , dataDrop: PropTypes.array.isRequired
};



export default EsplaneChart
