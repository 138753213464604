import React, { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Tab, Table, Button
} from 'semantic-ui-react';
import {
    despesasDeViagensListAdicionalAction,
    despesasDeViagensUpdateAdicionalAction,
} from '../../actions/DespesasDeViagensActions';
import styled from 'styled-components';

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
    font-size: 14px !important;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;
const StyledCell = styled(Table.Cell)`
&&&{
    div{
        font-size:13px !important;
    },
    span{
        font-size:13px !important;
      }
    }
`;

function Extra(props) {
    // actions
    const { despesasDeViagensListAdicionalAction,
        despesasDeViagensUpdateAdicionalAction
    } = props;

    // props reducer
    const { userid, adicionallist } = props;

    const [isLoadingList, setIsLoadingList] = useState(true)
    const [isLoadingSave, setIsLoadingSave] = useState(false)
    const [isLoadingCancel, setIsLoadingCancel] = useState(false)

    useEffect(() => {
        despesasDeViagensListAdicionalAction().then(() => {
            setIsLoadingList(false);
        })
    }, []);

    let jsonData = {}
    const handleConfirm = (despesa) => {
        setIsLoadingSave(true);
        jsonData = {
            userid: userid,
            aprovacao: 1,
            idviagemfinanceiro: despesa.idviagemfinanceiro
        }
        // console.log('Confirmando:', jsonData);
        despesasDeViagensUpdateAdicionalAction(jsonData).then(() => {
            setIsLoadingSave(false);
        })
    };

    const handleDelete = (despesa) => {

        jsonData = {
            userid: userid,
            aprovacao: 2,
            idviagemfinanceiro: despesa.idviagemfinanceiro
        }
        // console.log('Removendo:', jsonData);
        despesasDeViagensUpdateAdicionalAction(jsonData).then(() => {
            setIsLoadingCancel(false);
        })
    };

    // console.log(adicionallist);
    return (
        <Tab.Pane loading={isLoadingList}>
            <StyledTable celled id="listPendentes" compact='very' size='small'>
                <StyledTableHeader>
                    <Table.Row>
                        <Table.HeaderCell width="1">Ordem Carga</Table.HeaderCell>
                        {userid == 79 &&
                            <Table.HeaderCell width="1">IdViagem</Table.HeaderCell>
                        }
                        <Table.HeaderCell width="2">Motorista</Table.HeaderCell>
                        <Table.HeaderCell width="1">Data</Table.HeaderCell>
                        <Table.HeaderCell width="2">Despesa</Table.HeaderCell>
                        <Table.HeaderCell width="1">Valor</Table.HeaderCell>
                        <Table.HeaderCell width="1">Ações</Table.HeaderCell>
                    </Table.Row>
                </StyledTableHeader>
                <StyledTableBody>
                    {adicionallist.map((despesa) => (
                        <Table.Row key={despesa.ordemcarga}>
                            <StyledCell style={{ textAlign: 'center' }}>{despesa.ordemcarga}</StyledCell>
                            {userid == 79 &&
                                <StyledCell style={{ textAlign: 'center' }}>{despesa.idviagem}</StyledCell>
                            }
                            <StyledCell>{despesa.motorista}</StyledCell>
                            <StyledCell style={{ textAlign: 'center' }}>{moment(despesa.dtviagemfinanceiro).format('DD/MM/YYYY')}</StyledCell>
                            <StyledCell>{despesa.viagemdespesa}</StyledCell>
                            <StyledCell style={{ textAlign: 'center' }}>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(despesa.valor)}</StyledCell>
                            <StyledCell style={{ textAlign: 'center' }}>
                                <Button icon={'gavel'} color={'green'} size="mini" loading={isLoadingSave} onClick={() => handleConfirm(despesa)} />
                                <Button icon='remove ' color={'red'} size="mini" loading={isLoadingCancel} onClick={() => handleDelete(despesa)} />
                            </StyledCell>
                        </Table.Row>
                    ))}
                </StyledTableBody>
            </StyledTable>
        </Tab.Pane>
    );
}

const mapStateToProps = (state) => ({
    userid: state.LoginReducer.userid,
    adicionallist: state.DespesasDeViagensReducer.adicionallist
});

export default withRouter(connect(mapStateToProps, {
    despesasDeViagensListAdicionalAction
    , despesasDeViagensUpdateAdicionalAction
})(Extra));
