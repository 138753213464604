
import sortBy from 'sort-by';

import {
    RENEGOCIACAO_LIST_CONFIGURACOES
    , RENEGOCIACAO_LIST_DIVIDAS
    , RENEGOCIACAO_LOADER_DIVIDAS
    , RENEGOCIACAO_CHECK_ITEM
    , RENEGOCIACAO_CHECK_ALL
    , RENEGOCIACAO_UPDATE_CONFIG
    , RENEGOCIACAO_LOADER_ISSAVING
    , RENEGOCIACAO_LOADER_CONFIG
    , RENEGOCIACAO_LIST_CONSULTA
    , RENEGOCIACAO_LOADER_CONSULTA
    , RENEGOCIACAO_LOADER_ISSIMULATING
    , RENEGOCIACAO_LIST_CREDITO
    , RENEGOCIACAO_ADD_PARCEIRO
    , RENEGOCIACAO_LIST_CREDITODADOS
    , RENEGOCIACAO_LIST_COMPENSDADOS
    , RENEGOCIACAO_LIST_DIVIDASDADOS

    , RENEGOCIACAO_CANCEL_CONSULTA
    , RENEGOCIACAO_ISCANCELING

} from '../actions/ActionsTypes';

import * as Api from '../utils/API';


export const renegociacaoListCreditoAction = (codparc, dividas, configuracoes) => (dispatch) =>
    new Promise(function (resolve, reject) {
        //995
        Api.getAllRenegociacaoCredito(codparc).then(dados => {

            dados = dados.sort(sortBy('-nufindesp'));

            //recuperando configuração de desagio adsite
            let pctdesagioadsite = configuracoes.filter(d => d.configid == 28)[0].valor;//0.04;

            let vlrdividabruto = 0;//preencher com base  na divida
            let vlrjuros = 0;//preencher com base  na divida
            let vlrmulta = 0;//preencher com base  na divida
            let vlrcreditoutilizavelsoma = 0;


            if (dados.length > 0) {

                //simulando valor
                //dados[0].vlrdesdob = 5000;

                //recuperando as dívidas selecionadas para pagar
                let dividasselecionadas = []
                dividasselecionadas = dividas.filter(d => d.selecionado == true);
                for (let i of dividasselecionadas) {
                    vlrdividabruto += i.vlrdesdob;
                    vlrjuros += i.vlrjuros;
                    vlrmulta += i.vlrmulta;
                }

                //soma de crédito bruto
                let vlrcreditobrutosoma = 0
                for (let i of dados) {
                    vlrcreditobrutosoma += i.vlrdesdob;
                }

                //percorrendo cada linha de crédito encontrada
                for (let linha of dados) {
                    let vlrdivida = 0;
                    let vlrdesagio = 0;
                    let vlrdesagiocalculado = 0;
                    let vlrdescontoadsite = 0;
                    let vlrcreditoutilizavel = 0;

                    let vlrcreditobruto = linha.vlrdesdob; //preencher credito bruto com base no select

                    vlrdivida = vlrdividabruto + vlrjuros + vlrmulta;
                    vlrdesagio = (vlrdivida - vlrdividabruto) / vlrdividabruto;

                    vlrdesagiocalculado = vlrcreditobruto - vlrcreditobruto / (1 + vlrdesagio);
                    

                    //preechendo objeto
                    linha.vlrdesagio = vlrdesagiocalculado

                    //linha nova
                    linha.vlrcomdesagio = linha.vlrdesdob - linha.vlrdesagio
                    
                    vlrdescontoadsite = linha.vlrcomdesagio * pctdesagioadsite;

                    vlrcreditoutilizavel = vlrcreditobruto - vlrdesagiocalculado - vlrdescontoadsite;
                    vlrcreditoutilizavelsoma += vlrcreditoutilizavel

               

                    

                    linha.descontoadsite = vlrdescontoadsite
                    linha.vlrutilizavel = vlrcreditoutilizavel
                    linha.percjurosrec = vlrdesagio
                    linha.percjurosdesp = (vlrdesagiocalculado + vlrdescontoadsite) / vlrcreditoutilizavel


                }


                //Compensação---------------------------------------------------------------------
                let compensacao = []
                let creditos = dados;

                //para cada dívida, verificar se temos crédito para quitar
                for (let divida of dividasselecionadas) {

                    creditos = creditos.sort(sortBy('-nufindesp'));

                    let quitoudivida = false;
                    //percorre créditos disponíveis
                    let creditosnaoutilizados = []
                    creditosnaoutilizados = creditos.filter(d=>d.utilizou==0)
                    for (let c of creditosnaoutilizados) {
                        //alert('percorre créditos')
                        if (quitoudivida == false) {
                            //Cria compensação----------------------------
                            let comp = {}
                            comp.nufindesp = c.nufindesp
                            comp.dtvenc = divida.dtvenc
                            comp.tiptit = divida.codtiptit
                            comp.nufinrec = divida.nufin
                            comp.nucompens = null
                            if (divida.vlrdesdob >= c.vlrutilizavel) {
                                comp.vlrbaixa = c.vlrutilizavel
                            } else {
                                quitoudivida = true
                                comp.vlrbaixa = divida.vlrdesdob
                            }
                            comp.vlrutilizado = comp.vlrbaixa * (1 + c.percjurosdesp)
                            comp.vlrdesc = comp.vlrutilizado - comp.vlrbaixa
                            compensacao.push(comp)
                            c.utilizou=1
                            //-------------------------------------------

                            if (quitoudivida == true) {
                                //alert('sobrou')
                                /*let sobra = 0
                                alert('divida:'+comp.vlrutilizado)
                                sobra = c.vlrutilizavel - comp.vlrutilizado
                                alert('sobrou:'+sobra)
                                */


                                let credresto = {}
                                credresto.nufindesp = c.nufindesp
                                //verificando o resto que sobrou 
                                credresto.vlrdesdob = c.vlrdesdob - comp.vlrutilizado
                                credresto.dtvenc = c.dtvenc
                                //recalculando desagio com base no valor que sobrou
                                credresto.vlrdesagio = credresto.vlrdesdob - credresto.vlrdesdob / (1 + c.percjurosrec)

                                //linha nova
                                credresto.vlrcomdesagio = credresto.vlrdesdob - credresto.vlrdesagio

                                credresto.descontoadsite = credresto.vlrcomdesagio * pctdesagioadsite
                                //calculando novo valor utilizável do resto
                                credresto.vlrutilizavel = credresto.vlrdesdob - credresto.vlrdesagio - credresto.descontoadsite
                                credresto.percjurosrec = c.percjurosrec
                                credresto.percjurosdesp = (credresto.vlrdesagio + credresto.descontoadsite) / credresto.vlrutilizavel
                                credresto.utilizou=0
                                credresto.original=0
                                
                     
                       
                                /*alert(`
                                credresto.vlrdesdob:${credresto.vlrdesdob}
                                credresto.vlrdesagio:${credresto.vlrdesagio}
                                credresto.vlrutilizavel:${credresto.vlrutilizavel}
                                `)*/

                                creditos.push(credresto)

                                
                            }
                        }

                    }



                }


                /*
                //Verificar se na compensação precisaremos ajustar a última parcela com a diferença de centavos de parcelamento
                let desps  = []
                for(let o of compensacao){
                    desps.push({nufindesp:o.nufindesp})
                }
                
                //distinct
                let despesasunica = [...new Set(desps.map(o => JSON.stringify(o)))]
                despesasunica = despesasunica.map(o => JSON.parse(o))
                
                alert(despesasunica.length)

                //percorrer cada despesa(crédito) único
                for(let d of despesasunica){
                    
                    let compensacoesutilizadas = compensacao.filter(c=>c.nufindesp==d.nufindesp)

                    //se houve mais de uma compensação para o nufindesp corrente
                    if(compensacoesutilizadas.length>1){
                        
                        //soma o vlrutilizado de todas compensações 
                        let somacompensunica = 0
                        for(let i of compensacoesutilizadas){
                            somacompensunica+=i.vlrutilizado
                        }

                        let vlrdesdoboriginalcredito = 0 
                        
                        //pega o valor da despesa(crédito) original
                        let credoriginal = creditos.filter(j=>j.nufindesp==d.nufindesp && j.original==1)[0].vlrdesdob

                        let diffcred  = 0
                        diffcred = credoriginal - somacompensunica
                        alert(`
                        credoriginal: ${credoriginal}
                        soma: ${somacompensunica}
                        diferença: ${diffcred}
                        `)
                    }
                }
                */
                //-------------------------------------------------------------------------------------------------------------



                //compensações utilizadas
                dispatch({ type: RENEGOCIACAO_LIST_COMPENSDADOS, payload: compensacao })
                //-----------------------------------------------------------------------------------



                //Dividas----------------------------------------------------------------------------
                let dividasfinal = [];
                let compensrealizadas = compensacao;


                //percorrendo todas dividas e verificar se utilizou abateu algum  valor com crédito
                for (let d of dividasselecionadas) {


                    let divfinal = {}
                    divfinal.codparc = d.codparc
                    divfinal.parceiro = d.parceiro
                    divfinal.nufin = d.nufin
                    divfinal.nunota = d.nunota
                    divfinal.numnota = d.numnota
                    divfinal.dtnegcab = d.dtnegcab
                    divfinal.dtnegfin = d.dtnegfin
                    divfinal.dtfatur = d.dtfatur
                    divfinal.desdobramento = d.desdobramento
                    divfinal.dtvenc = d.dtvenc
                    divfinal.dtvencinic = d.dtvencinic
                    divfinal.top = d.top
                    divfinal.tipotitulo = d.tipotitulo
                    divfinal.tipotitulooriginal = d.tipotitulooriginal
                    divfinal.vlrdesdob = d.vlrdesdob
                    divfinal.dias = d.dias
                    divfinal.meses = d.meses
                    divfinal.codtipoper = d.codtipoper
                    divfinal.codtiptit = d.codtiptit
                    divfinal.existereneg = d.existereneg
                    divfinal.idrenegociacaoweb = d.idrenegociacaoweb
                    divfinal.statusreneg = d.statusreneg
                    divfinal.nomeemitente_cmc7 = d.nomeemitente_cmc7
                    divfinal.pctjuros = d.pctjuros
                    divfinal.vlrjuros = d.vlrjuros
                    divfinal.pctmulta = d.pctmulta
                    divfinal.vlrmulta = d.vlrmulta
                    divfinal.vlrtotal = d.vlrtotal
                    divfinal.regra = d.regra
                    divfinal.selecionado = d.selecionado

                    //Verifica qual dívida houve compensação parcial
                    if(compensrealizadas.filter(r => r.nufinrec == d.nufin).length>1){
                        divfinal.parcial = 1
                    }else{
                        divfinal.parcial = 0
                    }

                    
                    

                    //divfinal = d;


                    //percorre todas compensações dessa dívida e ver se conseguiu quitar ou foi parcial
                    let somavlrbaixacompes = 0
                    for (let c of compensrealizadas.filter(r => r.nufinrec == d.nufin)) {
                        somavlrbaixacompes += c.vlrbaixa
                    }

                    //problema aquiiiiiiiiii
                    divfinal.vlrdesdob = divfinal.vlrdesdob - somavlrbaixacompes

                    //conseguiu quitar?
                    /*if (divfinal.vlrdesdob > 0) {
                        //não, então deve adicionar a divida ainda
                        dividasfinal.push(divfinal)
                    }*/
                    dividasfinal.push(divfinal)
                }

                //recalcular multa e juros das dívidas que sobraram para pagar, pois pode ser que 
                //sobraram dívidas que o valor foi abatido parcialmente
                let conta = {}
                let compdata = {}
                dividasfinal.map(item => (
                    (
                        conta = calcula_juros_multa(configuracoes, item.dias, item.meses, item.vlrdesdob, item.codtipoper)
                        , compdata = getcompensado(compensacao,dividas,item)
                    ),

                    item.pctjuros = conta.pctjuros
                    , item.vlrjuros = conta.vlrjuros
                    , item.pctmulta = conta.pctmulta
                    , item.vlrmulta = conta.vlrmulta
                    , item.vlrtotal = conta.vlrtotal
                    , item.regra = conta.regra
                    , item.vlrcompensado = compdata.valor
                ))



                dispatch({ type: RENEGOCIACAO_LIST_DIVIDASDADOS, payload: dividasfinal })
                //-----------------------------------------------------------------------------------



                //vlrcreditoutilizavelsoma=1500;
                dispatch({ type: RENEGOCIACAO_LIST_CREDITO, payload: (vlrcreditoutilizavelsoma > 0 ? vlrcreditoutilizavelsoma : 0) })
                dispatch({ type: RENEGOCIACAO_LIST_CREDITODADOS, payload: (vlrcreditoutilizavelsoma > 0 ? creditos : []) })

            } else {
                dispatch({ type: RENEGOCIACAO_LIST_CREDITO, payload: 0 })
                dispatch({ type: RENEGOCIACAO_LIST_CREDITODADOS, payload: [] })
                dispatch({ type: RENEGOCIACAO_LIST_DIVIDASDADOS, payload: [] })
                dispatch({ type: RENEGOCIACAO_LIST_COMPENSDADOS, payload: [] })
            }
        });





        /*
        if (!isNaN(codparc)) {
            Api.getAllRenegociacaoConsulta(codparc).then(dados => {
                dispatch({ type: RENEGOCIACAO_LIST_CONSULTA, payload: dados })
                dispatch({ type: RENEGOCIACAO_LOADER_CONSULTA, payload: false })
                resolve([])
            })
        } else {
            dispatch({ type: RENEGOCIACAO_LIST_CONSULTA, payload: [] })
            dispatch({ type: RENEGOCIACAO_LOADER_CONSULTA, payload: false })
            resolve([])
        }*/
    })









export const renegociacaoListConsultaAction = (codparc) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: RENEGOCIACAO_LOADER_CONSULTA, payload: true })
        if (!isNaN(codparc)) {
            Api.getAllRenegociacaoConsulta(codparc).then(dados => {
                for(let i of dados){
                    i.iscanceling = false
                }

                dispatch({ type: RENEGOCIACAO_LIST_CONSULTA, payload: dados })
                dispatch({ type: RENEGOCIACAO_LOADER_CONSULTA, payload: false })
                resolve([])
            })
        } else {
            dispatch({ type: RENEGOCIACAO_LIST_CONSULTA, payload: [] })
            dispatch({ type: RENEGOCIACAO_LOADER_CONSULTA, payload: false })
            resolve([])
        }
    })


export const renegociacaoSaveAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: RENEGOCIACAO_LOADER_ISSAVING, payload: true })
        
        
        Api.postRenegociacaoSave(entidade).then(dados => {
            if (dados[0].status == "OK") {

                dispatch(renegociacaoListDividasAction(entidade.dividas[0].codparc, entidade.configuracoes)).then(d => {
                    dispatch({ type: RENEGOCIACAO_LOADER_ISSAVING, payload: false })
                })
            } else {
                dispatch({ type: RENEGOCIACAO_LOADER_ISSAVING, payload: false })
            }

            dispatch({ type: RENEGOCIACAO_LIST_CREDITO, payload: 0 })
            dispatch({ type: RENEGOCIACAO_LIST_CREDITODADOS, payload: [] })
            dispatch({ type: RENEGOCIACAO_LIST_DIVIDASDADOS, payload: [] })
            dispatch({ type: RENEGOCIACAO_LIST_COMPENSDADOS, payload: [] })
            resolve([])
        })
        


    })



export const renegociacaoSimulacaoAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: RENEGOCIACAO_LOADER_ISSIMULATING, payload: true })
        Api.postRenegociacaoSimulacao(entidade).then(dados => {
            dispatch({ type: RENEGOCIACAO_LOADER_ISSIMULATING, payload: false })

            dispatch(renegociacaoListDividasAction(entidade.dividas[0].codparc, entidade.configuracoes)).then(d => {
                dispatch({ type: RENEGOCIACAO_LOADER_ISSAVING, payload: false })
            })

            resolve([])
        })
    })




export const renegociacaoSaveConfigAction = (configid, valor) => (dispatch) =>
    new Promise(function (resolve, reject) {

        dispatch({ type: RENEGOCIACAO_UPDATE_CONFIG, payload: valor, configid: configid })

        let entidade = { configid: configid, valor: valor }



        Api.postRenegociacaoSaveConfiguracoes(entidade).then(dados => {

            if (dados[0].status != "OK") {
                dispatch(renegociacaoListConfiguracoesAction())
            }

            resolve([])
        })
    })



export const renegociacaoCheckAllAction = (check) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: RENEGOCIACAO_CHECK_ALL, payload: check })
        resolve([])

    })


export const renegociacaoCheckItemAction = (nufin) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: RENEGOCIACAO_CHECK_ITEM, payload: nufin })
        resolve([])

    })

export const renegociacaoListConfiguracoesAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: RENEGOCIACAO_LOADER_CONFIG, payload: true })

        Api.getAllRenegociacaoConfiguracoes().then(dados => {
            dispatch({ type: RENEGOCIACAO_LOADER_CONFIG, payload: false })
            dispatch({ type: RENEGOCIACAO_LIST_CONFIGURACOES, payload: dados })
            resolve([])
        })
    })



    let getcompensado = function (compensdados,dividas,dividaitem) {
        let compensado = {}
     
        let soma = 0
        let valororiginal = 0

        valororiginal = dividas.find(d=>d.nufin==dividaitem.nufin).vlrdesdob

        /*for(let c of compensdados.filter(d=>d.nufinrec==dividaitem.nufin)){
            soma+=c.vlrdesdob
        }*/


        /*
        criar coluna vlrcompens
        vlrorginal - vlrdesdob das dividas
        totalvlrdesdob + vlrcompensado

        
        

        
        */
       //compensado.valor = valororiginal//- soma
        compensado.valor = valororiginal - dividaitem.vlrdesdob


        return compensado
    }


let calcula_juros_multa = function (configuracoes, dias, meses, vlrdesdob, codtipoper) {


    let limite1dias = configuracoes.filter(d => d.configid == 1)[0].valor
    let limite2dias = configuracoes.filter(d => d.configid == 2)[0].valor
    let limite3dias = configuracoes.filter(d => d.configid == 3)[0].valor

    let ate_limite1_multa = configuracoes.filter(d => d.configid == 4)[0].valor
    let ate_limite1_juros = configuracoes.filter(d => d.configid == 5)[0].valor

    let entre_limite1_limite_2_multa = configuracoes.filter(d => d.configid == 6)[0].valor
    let entre_limite1_limite_2_juros = configuracoes.filter(d => d.configid == 7)[0].valor

    let entre_limite2_limite_3_multa = configuracoes.filter(d => d.configid == 8)[0].valor
    let entre_limite2_limite_3_juros = configuracoes.filter(d => d.configid == 9)[0].valor

    let acima_limite_3_multa = configuracoes.filter(d => d.configid == 10)[0].valor
    let acima_limite_3_juros = configuracoes.filter(d => d.configid == 11)[0].valor

    let cheque_devolvido_dias = configuracoes.filter(d => d.configid == 26)[0].valor


    let pctmulta = 0
    let vlrmulta = 0
    let pctjuros = 0
    let vlrjuros = 0
    let regra = ''


    //escolhendo faixa
    if (dias <= limite1dias) {
        pctmulta = ate_limite1_multa
        pctjuros = ate_limite1_juros
        regra = `Até o limite 1`

    } else if (dias > limite1dias && dias <= limite2dias) {
        pctmulta = entre_limite1_limite_2_multa
        pctjuros = entre_limite1_limite_2_juros
        regra = `Entre limite 1 e limite 2`

    } else if (dias > limite2dias && dias <= limite3dias) {
        pctmulta = entre_limite2_limite_3_multa
        pctjuros = entre_limite2_limite_3_juros
        regra = `Entre limite 2 e limite 3`


    } else if (dias > limite3dias) {
        pctmulta = acima_limite_3_multa
        pctjuros = acima_limite_3_juros
        regra = `Acima do limite 3`
    }




    let resultado = {}



    //se for cheque devolvido 1652 CODTIPOPER
    if (codtipoper === 1652 && dias <= cheque_devolvido_dias) {
        resultado.pctjuros = 0

        //juros composto
        resultado.vlrjuros = 0
        resultado.pctmulta = 0
        resultado.vlrmulta = 0
        resultado.vlrtotal = vlrdesdob + resultado.vlrmulta + resultado.vlrjuros
        resultado.regra = 'Carência Cheque Devolvido'


    } else {


        if (meses == 0) {
            meses = 1
        }

        resultado.pctjuros = pctjuros
        //juros simples
        //resultado.vlrjuros = (vlrdesdob * pctjuros / 100) * meses

        //juros composto
        resultado.vlrjuros = (vlrdesdob * Math.pow((1 + (pctjuros / 100)), meses) - vlrdesdob)

        resultado.pctmulta = pctmulta
        resultado.vlrmulta = vlrdesdob * pctmulta / 100
        resultado.vlrtotal = vlrdesdob + resultado.vlrmulta + resultado.vlrjuros
        resultado.regra = regra

    }


    //alert(regra)



    return resultado
}

export const renegociacaoListDividasAction = (codparc, configuracoes) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: RENEGOCIACAO_LOADER_DIVIDAS, payload: true })

        if (!isNaN(codparc)) {

            dispatch({ type: RENEGOCIACAO_ADD_PARCEIRO, payload: parseInt(codparc, 10) })

            Api.getAllRenegociacaoDividas(codparc).then(dados => {

                //regra de renegociação----------------------------------
                let date_diff_indays = function (date1, date2) {
                    let dt1 = new Date(date1);
                    let dt2 = new Date(date2);
                    return Math.floor((Date.UTC(dt2.getFullYear(), dt2.getMonth(), dt2.getDate()) - Date.UTC(dt1.getFullYear(), dt1.getMonth(), dt1.getDate())) / (1000 * 60 * 60 * 24));
                }


                let data = []
                data = dados

                let qtd = 0
                let conta = {}

                data.map(item => (
                    (
                        conta = calcula_juros_multa(configuracoes, item.dias, item.meses, item.vlrdesdob, item.codtipoper)
                    ),

                    item.pctjuros = conta.pctjuros
                    , item.vlrjuros = conta.vlrjuros
                    , item.pctmulta = conta.pctmulta
                    , item.vlrmulta = conta.vlrmulta
                    , item.vlrtotal = conta.vlrtotal
                    , item.regra = conta.regra
                    , item.selecionado = false
                ))
                //-------------------------------------------------------



                dispatch({ type: RENEGOCIACAO_LIST_DIVIDAS, payload: data })
                dispatch({ type: RENEGOCIACAO_LOADER_DIVIDAS, payload: false })
                resolve([])
            })
        } else {
            dispatch({ type: RENEGOCIACAO_LIST_DIVIDAS, payload: [] })
            dispatch({ type: RENEGOCIACAO_LOADER_DIVIDAS, payload: false })
            dispatch({ type: RENEGOCIACAO_ADD_PARCEIRO, payload: 0 })
            resolve([])
        }

    })


/*
export const consultoresUpdateAction = (event) => {
    return {
        type: CONSULTORES_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}*/




export const renegociacaoCancelConsultaAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
      

        dispatch({type: RENEGOCIACAO_ISCANCELING, payload: true, idrenegociacaoweb:entidade.idrenegociacaoweb})

    
        Api.postRenegociacaoConsultaCancel(entidade).then(dados => {
            if(dados.status=='OK'){
                dispatch({type: RENEGOCIACAO_CANCEL_CONSULTA, payload: entidade.idrenegociacaoweb})
                
            }else{
                dispatch({type: RENEGOCIACAO_ISCANCELING, payload: false, idrenegociacaoweb:entidade.idrenegociacaoweb})
            }
            resolve([])  
        })

    })









