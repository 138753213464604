import sortBy from 'sort-by';

import {
    SERASA_UPDATE_BUSCA
    , SERASA_ISSEARCHING_BUSCA
    , SERASA_LIST_SEARCH
} from '../actions/ActionsTypes';

import {SerasaBuscaEntity} from '../entities/SerasaBuscaEntity'

const INITIAL_STATE = {
    SerasaBuscaEntity
    , isSearching: false
    , search:[]

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
    
        case SERASA_UPDATE_BUSCA:
            return { ...state, SerasaBuscaEntity: {...state.SerasaBuscaEntity, [action.field]: action.payload}}

        case SERASA_ISSEARCHING_BUSCA:
            return {...state, isSearching:action.payload }

        case SERASA_LIST_SEARCH:
            return {...state, search:action.payload}

        default:
            return state;
    }
}