import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';

import parse from 'html-react-parser';

import moment from 'moment';
import 'moment/locale/pt-br'

import {
    comparativoProdutosListAction
    , comparativoProdutosChangeAction
} from '../../actions/ComparativoProdutosActions';

import {
    Table, Button, Modal, Form, Segment, Message, Image, Header, Menu, Breadcrumb, Grid

} from 'semantic-ui-react'

import { CopyToClipboard } from 'react-copy-to-clipboard';

import If from '../../utils/If';

import styled from 'styled-components';






const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class ComparativoProdutos extends Component {

    state = { copied: '' }

    constructor(props) {
        super(props);
    }

    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 9 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------


        this.props.comparativoProdutosListAction().then(d => { })

    }



    handleComparativo = (e, { value }) => {
        this.setState({ copied: '' })

        let entidade = [];

        entidade = this.props.dados.filter(f => f.comparativoid == value)

        this.props.comparativoProdutosChangeAction(value, entidade,this.props.imagens)
    }



    render() {

        let { combo, issearching, codigo } = this.props

        return (

            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Comparativo de Produtos </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>


                        <Segment secondary>

                            <Form loading={issearching}>
                                <Form.Group widths='equal'>

                                    <Form.Select name="comparativoid"
                                        options={combo}
                                        //defaultValue={this.props.ConsultorBuscaEntity.codconsultor.toString()} 
                                        label={`Comparativo ${'\uD83D\uDE80'} `}
                                        onChange={this.handleComparativo.bind(this)}
                                    />




                                </Form.Group>
                              

                                {parse('<center>'+codigo+'</center>')}

                                <CopyToClipboard text={codigo}
                                    onCopy={() => this.setState({ copied: 'Copiado' })}>
                                    <button>Copiar</button>
                                </CopyToClipboard>{this.state.copied}


                                <Form.Group widths='equal'>
                                    <Form.TextArea name="codigo" fluid label='' placeholder=''
                                        //onChange={this.props.marketplacesHandleChangeAction}
                                        value={codigo}
                                        rows={15}
                                    />
                                </Form.Group>

                               
                            </Form>

                            
                        </Segment>


                        

                    </Grid.Column>

                </Grid>


            </div>

        )

    }

}


const mapStateToProps = state => ({
    menu: state.LoginReducer.menu
    , dados: state.ComparativoProdutosReducer.dados
    , issearching: state.ComparativoProdutosReducer.issearching
    , combo: state.ComparativoProdutosReducer.combo
    , codigo: state.ComparativoProdutosReducer.codigo
    , imagens: state.ComparativoProdutosReducer.imagens
})

export default withRouter(connect(mapStateToProps, {
    comparativoProdutosListAction
    , comparativoProdutosChangeAction
}
)(ComparativoProdutos));
