import {
    APPENTREGAS_UPDATE_NF
    , APPENTREGAS_LIST_NF
    , APPENTREGAS_LIST_ISSEARCHING
} from '../actions/ActionsTypes'


const INITIAL_STATE = {
    searchnf: 451542//408667
    , dadosnf: []
    , issearchingnf: false
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case APPENTREGAS_UPDATE_NF:
            return { ...state, searchnf: action.payload }

        case APPENTREGAS_LIST_NF:
            return { ...state, dadosnf: action.payload }

        case APPENTREGAS_LIST_ISSEARCHING:
            return { ...state, issearchingnf: action.payload }

        default:
            return state;
    }
}