import {
    COMISSOES_LIST
    , COMISSOES_VENDEDORES_LIST
    , COMISSOES_PEDIDO_ISSAVING
    , COMISSOES_CHANGE_VENDEDOR
    , COMISSOES_PEDIDO_UPDATE
    , COMISSOES_ISLOADING
    , COMISSOES_RELATORIO_LIST
    , COMISSOES_UPDATE_TICKET
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';

export const comissoesUpdateTicketAction = (pedido, texto) => {
    return (dispatch) => {
        dispatch({ type: COMISSOES_UPDATE_TICKET, pedidofbits: pedido.pedidofbits, payload: Number.parseInt(texto) });
    };
};



export const comissoesListAction = (dataFromSockets) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({type: COMISSOES_LIST, payload: dataFromSockets})
        dispatch({type: COMISSOES_ISLOADING, payload: false})
        
        resolve([])
        /*Api.getAllComissoes().then(dados => {
            dispatch({ type: COMISSOES_LIST, payload: dados })
            resolve([])
        })*/
    }
)

export const comissoesSaveAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({type: COMISSOES_PEDIDO_ISSAVING, pedidofbits: entidade.pedidofbits, nunota:entidade.nunota, issaving:true})
        /*alert(`
        salvar o pedido
        pedidofbits: ${entidade.pedidofbits}
        vendedorvcc: ${entidade.vendedorvcc}
        login: ${entidade.login}
        `)*/
        Api.postComissoesSave(entidade).then(dados=>{
            dispatch({type: COMISSOES_PEDIDO_ISSAVING, pedidofbits: entidade.pedidofbits, nunota:entidade.nunota, issaving:false})
        })
        //dispatch({type: COMISSOES_PEDIDO_ISSAVING, pedidofbits: entidade.pedidofbits, issaving:false})
        //dispatch({type: COMISSOES_LIST, payload: dataFromSockets})

        resolve([])
    }
)


export const comissoesChangeVendedorAction = (entidade,vendedorvcc) => (dispatch) =>
    new Promise(function (resolve, reject) {
        /*alert(`
        alterar vendedor do pedido
        pedidofbits: ${entidade.pedidofbits}
        vendedorvcc: ${vendedorvcc}
        `)*/
        dispatch({type: COMISSOES_CHANGE_VENDEDOR, pedidofbits: entidade.pedidofbits, nunota:entidade.nunota, vendedorvcc:vendedorvcc})
        //dispatch({type: COMISSOES_LIST, payload: dataFromSockets})
        resolve([])
    }
)


export const comissoesPedidoUpdateAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
      
        dispatch({type: COMISSOES_PEDIDO_UPDATE, payload:entidade})

        resolve([])
    }
)





export const comissoesVendedoresListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
      
        Api.getAllComissoesVendedores().then(dados => {

               
               let vendedores = []
               //vendedores.push({ key: "0", text: "Sem Vendedor", value: "0" })
               if (dados.length > 0) {
                   for (let v of dados) {
                       vendedores.push({ key: v.login.toString(), text: v.login, value: v.vendedorvcc.toString() })
                   }
               }

            dispatch({type: COMISSOES_VENDEDORES_LIST, payload: vendedores})
            resolve([])
        })
    }
)

export const comissoesRelatorioListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
      
        Api.getAllComissoesRelatorio().then(dados => {
            dispatch({type: COMISSOES_RELATORIO_LIST, payload: dados})
            resolve([])
        })
    }
)



