import {
    CORPORATIVO_LIST_FECHAMENTO
    , CORPORATIVO_LOADER_FECHAMENTO
    , CORPORATIVO_UPDATE_BUSCA
    , CORPORATIVO_VALIDACAO_BUSCA_ADD
    , CORPORATIVO_VALIDACAO_BUSCA_REMOVE
    , CORPORATIVO_ISSEARCHING_BUSCA
    , CORPORATIVO_LIST
    , CORPORATIVO_LIST_SEARCH
    , CORPORATIVO_LIST_CIDADES
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';

 

export const corporativoListCidadesAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRepresentantesCidades().then(dados => {

            //Cidades
            let cidades = []
            cidades.push({ key: "0", text: `Todas`, value: "0" })
            if (dados.length > 0) {
                for (let c of dados) {
                    cidades.push({ key: c.codcid.toString(), text: `${c.uf} -(${c.nomecid})`, value: c.codcid.toString() })
                }
            }

            dispatch({ type: CORPORATIVO_LIST_CIDADES, payload: cidades })
            resolve([])
        })
    }
)

export const corporativoListAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRepresentantes().then(dados => {

            //Consultores
            let consultores = []
            consultores.push({ key: "0", text: `Todos`, value: "0" })
            if (dados.length > 0) {
                for (let c of dados) {
                    consultores.push({ key: c.codvend.toString(), text: `${c.apelido} (${c.codvend})`, value: c.codvend.toString() })
                }
            }


            dispatch({ type: CORPORATIVO_LIST, payload: consultores })
            resolve([])
        })
    }
)




export const corporativoListSearchAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CORPORATIVO_ISSEARCHING_BUSCA, payload: true })
        //console.log("###ENTIDADE")
        //console.log(entidade)
        Api.postRepresentantesSearch(entidade).then(dados => {

            let search = dados
            for(let i of search){
                i.kmdiferenca = (i.kmfinal-i.kminicial)
            }


            dispatch({ type: CORPORATIVO_LIST_SEARCH, payload: search })
            dispatch({ type: CORPORATIVO_ISSEARCHING_BUSCA, payload: false })
            resolve([])
        })
    }
)



export const corporativoListFechamentoAction = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        Api.getAllRepresentantesFechamento().then(dados => {
            dispatch({ type: CORPORATIVO_LIST_FECHAMENTO, payload: dados })
            dispatch({ type: CORPORATIVO_LOADER_FECHAMENTO, payload: false })
            resolve([])
        })
    }
)

export const corporativoUpdateAction = (event) => {
    return {
        type: CORPORATIVO_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}


export const corporativoAddValidacao = (id, campo, mensagem) => {
    return {
        type: CORPORATIVO_VALIDACAO_BUSCA_ADD
        , payload: { id: id, item: campo, mensagem: mensagem, status: 'Error' }
    }
}


export const corporativoRemoveValidacao = () => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: CORPORATIVO_VALIDACAO_BUSCA_REMOVE, payload: [] })
        resolve([])
    }
    );










