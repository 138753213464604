import {
    TRACKERS_PAINEL_LIST
    , TRACKERS_PAINEL_LOADER
    , TRACKERS_PAINEL_LIST_SPLIT
    , TRACKERS_PAINEL_SPLIT
    , TRACKERS_PAINEL_SCREEN
    , TRACKERS_PAINEL_LIST_CHANGE_LOGS
    , TRACKERS_LIST_REASONS
    , TRACKERS_DIFFERENT_CARS
} from '../actions/ActionsTypes';

// import * as Api from '../utils/API';

// let reasons = new Array({ key: "0", text: 'Selecione um motivo', value: '0', reasonid:0 });
// Api.getTrackersLogReasons().then(data => {
//     Object.values(data)[0].forEach(element => {
//         reasons.push(
//             { key: toString(element.id), text: element.reason, value: toString(element.id), reasonid:1 },
//         )
//     }); 
// })
// console.log(reasons,'qaaaa');
// console.log('reasons','qaaaa');


const INITIAL_STATE = {
    paineldata: []
    , paineldatasplit: [{ dados: [] }]
    , painelsplit: 0
    , painelloader: true
    , painelscreen: false
    , logsreasons: []
    , differentcars: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case TRACKERS_PAINEL_LIST:
            return { ...state, paineldata: action.payload }
        case TRACKERS_PAINEL_LIST_SPLIT:
            return { ...state, paineldatasplit: action.payload }
        case TRACKERS_PAINEL_SPLIT:
            return { ...state, painelsplit: action.payload }
        case TRACKERS_PAINEL_SCREEN:
            return { ...state, painelscreen: action.payload }

        case TRACKERS_PAINEL_LOADER:
            return { ...state, painelloader: action.payload }

        case TRACKERS_LIST_REASONS:
            return { ...state, logsreasons: action.payload }

        case TRACKERS_DIFFERENT_CARS:
            return { ...state, differentcars: action.payload }

        case TRACKERS_PAINEL_LIST_CHANGE_LOGS:
            //sub-array
            //subarray reducer
            //chatgpt
            const ocIndex = state.paineldata.findIndex(
                (oc) => oc.logs.some((log) => log.logid === action.logid)
            );
            const logIndex = state.paineldata[ocIndex].logs.findIndex(
                (log) => log.logid === action.logid
            );

            // Cria uma cópia do estado atual
            const newState = { ...state };

            // Altera o valor de issaving para o oposto do valor atual
            newState.paineldata[ocIndex].logs[logIndex][action.field] = action.payload

            // Retorna o novo estado
            return newState;

        default:
            return state;
    }
}