import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Button, Container, Divider, Segment, List, Image, Tab, Icon, Popup } from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';

import InputMask from 'react-input-mask';


import If from '../../utils/If';




const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&&
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}
`

class ListConfiguracoes extends Component {

    state={configid:0, valor:0}


    constructor(props) {

        moment.locale('pt-br');
        super(props);
        //this.onChangeModalOpen = this.onChangeModalOpen.bind(this);
    }

    componentDidMount() {

    }



    handleChangeConfig = (e) => {
        this.setState({valor: e.target.value})
    }




    onEditConfig(e, configuracao) {

        //console.log(configuracao)

        this.setState({
            configid:configuracao.configid
            , valor: configuracao.valor
        })


    }



    onCancelConfig(e, data) {
        this.setState({
            configid:0
            , valor: 0
        })
    }

    onSaveConfig(e,data) {

        this.props.renegociacaoSaveConfigAction(this.state.configid, this.state.valor).then(d=>{})

        this.setState({
            configid:0
            , valor: 0
        })

    }

    onUpdateStatus(){
       

        this.props.handleUpdateStatus();

    }

    render() {
        let { dataList, dataQtde, loader, entidade
        } = this.props;



        return (
            <Tab.Pane loading={loader}>

                <StyledTable collapsing compact celled  id="ListConfigReneg" >
                    <StyledTableHeader>
                        <Table.Row>
                            <Table.HeaderCell>ID</Table.HeaderCell>
                            <Table.HeaderCell>Descrição</Table.HeaderCell>
                            <Table.HeaderCell width='3'>Valor</Table.HeaderCell>
                            <Table.HeaderCell></Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>
                        {dataList.map(data => (
                            <Table.Row key={`conf${data.configid}${data.grupo}`}>
                                <Table.Cell>{data.configid}</Table.Cell>
                                <Table.Cell>{data.descricao}</Table.Cell>

                                <Table.Cell>
                                    {(this.state.configid === data.configid) ?
                                        (<div style={{ width: "10px" }}>
                                            <InputMask type="number" name="pctvalor" placeholder="Valor"
                                                value={this.state.valor}
                                                onChange={this.handleChangeConfig}
                                                style={{ width: "65px" }}
                                            />
                                        </div>) : data.valor}
                                </Table.Cell>

                                <Table.Cell>
                                    {(this.state.configid === data.configid) ?
                                        (<div><Button key={`save_${data.configid}`} basic icon={'write'} color={'green'} size="mini"
                                            onClick={(e) => this.onSaveConfig(e,data)}
                                        />
                                            <Button key={`cancel_${data.configid}`} basic icon={'cancel'} color={'red'} size="mini"
                                                onClick={(e) => this.onCancelConfig(e, data)}
                                            /></div>) : (
                                            <Button key={`edit_${data.configid}`} basic icon={'edit'} color={'blue'} size="mini"
                                                onClick={(e) => this.onEditConfig(e, data)}
                                            />
                                        )
                                    }
                                </Table.Cell>
                            </Table.Row>
                        ))}

                    </StyledTableBody>
                </StyledTable>
            </Tab.Pane>
        )
    }
}

ListConfiguracoes.defaultProps = {
    dataList: []
    , loader: true
    , entidade: {}
    , robostatus: 0
    , robodt: null
};

ListConfiguracoes.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
    , renegociacaoSaveConfigAction: PropTypes.func.isRequired
};



export default ListConfiguracoes
