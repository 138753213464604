import {
    APPENTREGAS_UPDATE_NF
    , APPENTREGAS_LIST_NF
    , APPENTREGAS_LIST_ISSEARCHING
} from './ActionsTypes';


import * as Api from '../utils/API';;


//só números
//somente números
function justNumbers(text) {
    var numbers = text.replace(/[^0-9]/g, '');
    return parseInt(numbers);
}




export const appEntregasHandleChangeAction = (event) => (dispatch) =>
    new Promise(function (resolve, reject) {
        if (event.target.name == 'nf') {
            dispatch({ type: APPENTREGAS_LIST_NF, payload: [] })
            dispatch({ type: APPENTREGAS_UPDATE_NF, payload: (isNaN(justNumbers(event.target.value)) ? "" : justNumbers(event.target.value)) })
        }

    });


export const appEntregasHandleCancelarAction = (event) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: APPENTREGAS_LIST_NF, payload: [] })
        dispatch({ type: APPENTREGAS_UPDATE_NF, payload: "" })

    });




export const appEntregasHandleSearchAction = () => (dispatch, getState) =>
    new Promise(function (resolve, reject) {


        
        const { searchnf } = getState().AppEntregasReducer;
        
        if (searchnf != '') {
         
            dispatch({ type: APPENTREGAS_LIST_ISSEARCHING, payload: true })

            Api.getAppEntregasNF(searchnf).then(dados => {
                dispatch({ type: APPENTREGAS_LIST_NF, payload: [dados] })
                dispatch({ type: APPENTREGAS_LIST_ISSEARCHING, payload: false })
                resolve(dados)
            }).catch(error => {
                dispatch({ type: APPENTREGAS_LIST_ISSEARCHING, payload: false })
                reject(error);
            })
      

        } else {
            dispatch({ type: APPENTREGAS_LIST_NF, payload: [] })
        }


    });



