import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import {
    Table, Button

} from 'semantic-ui-react'

import If from '../../utils/If';

import styled from 'styled-components';


import ReactExport from "react-data-export";

const ExcelFile = ReactExport.ExcelFile;
const ExcelSheet = ReactExport.ExcelFile.ExcelSheet;
const ExcelColumn = ReactExport.ExcelFile.ExcelColumn;



const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;




class Fechamento extends Component {


    state = {}

    constructor(props) {
        super(props);
    }


    componentWillReceiveProps() {




    }

    componentDidMount() {
        this.props.consultoresListFechamentoAction().then(d=>{})
    }

    calculaPremiacao(dados) {


        for(let item of dados){
           


            if (item.dias===null){
                if(item.dtultcompraantes===null && item.dtultcompradepois===null){
                    item.faixa='Abertura'
                }else if (item.dtultcompraantes!=null && item.dtultcompradepois===null){
                    item.faixa='Sem Compras'
                }else if (item.dtultcompraantes===null && item.dtultcompradepois!=null){
                    item.faixa='Sem Compras Depois'
                }

                item.premiacao=0.0
            }else{
                if(item.dias<=180){
                    item.faixa='Até 180 dias'
                    item.premiacao=30.00
                }else if(item.dias>180 && item.dias<=365){
                    item.faixa = 'De 181 à 365 dias'
                    item.premiacao=40.00
                }else if(item.dias>365){
                    item.faixa = 'Mais que 365 dias'
                    item.premiacao=50.00
                }

            }
            
        }
    
        return dados
    }




    render() {

        let { datalist } = this.props

        datalist = this.calculaPremiacao(datalist)




        return (
        <div>
            <ExcelFile element={<Button basic color={'blue'} size="mini">Exportar para Excel</Button>}>
                <ExcelSheet data={datalist} name={`Fechamento`}>
                    <ExcelColumn label="Hoje" value="hoje"/>
                    <ExcelColumn label="Consultor" value="consultor"/>
                    <ExcelColumn label="CodParc" value="codparc"/>
                    <ExcelColumn label="Nome do Parceiro" value="nomeparc"/>
                    <ExcelColumn label="Última Visita" value="dtultvisita"/>
                    <ExcelColumn label="DT da Última compra Antes da Visita" value="dtultcompraantes"/>
                    <ExcelColumn label="DT da Última compra Depois da Visita" value="dtultcompradepois"/>
                    <ExcelColumn label="Dias" value="dias"/>
                    <ExcelColumn label="Faixa" value="faixa"/>
                    <ExcelColumn label="Premiação" value="premiacao"/>
                </ExcelSheet>
            </ExcelFile>

            <StyledTable celled id="listFechamento" compact='very' size='small'>
                <StyledTableHeader>
                    <Table.Row>
                        <Table.HeaderCell width="1">Hoje</Table.HeaderCell>
                        <Table.HeaderCell width="2">Consultor</Table.HeaderCell>
                        <Table.HeaderCell width="1">CodParc</Table.HeaderCell>
                        <Table.HeaderCell width="1">Parceiro</Table.HeaderCell>
                        <Table.HeaderCell width="1">DT Últ. Visita</Table.HeaderCell>
                        <Table.HeaderCell width="1">DT Últ. Compra Antes</Table.HeaderCell>
                        <Table.HeaderCell width="1">DT Últ. Compra Depois</Table.HeaderCell>
                        <Table.HeaderCell width="1">Dias</Table.HeaderCell>
                        <Table.HeaderCell width="1">Faixa</Table.HeaderCell>
                        <Table.HeaderCell width="1">Premiação</Table.HeaderCell>

                    </Table.Row>
                </StyledTableHeader>

                <StyledTableBody>
                    {datalist.map(item => (
                        <Table.Row key={"fechamento_" + item.hoje +'_'+item.codparc}>

                            <Table.Cell>{item.hoje}</Table.Cell>
                            <Table.Cell>{item.consultor}</Table.Cell>

                            <Table.Cell>    
                                {item.codparc}
                            </Table.Cell>
                            <Table.Cell>
                                {item.nomeparc}
                            </Table.Cell>
                            <Table.Cell>
                                {item.dtultvisita}
                            </Table.Cell>
                            <Table.Cell>
                                {item.dtultcompraantes}
                            </Table.Cell>
                            <Table.Cell>
                                {item.dtultcompradepois}
                            </Table.Cell>
                            <Table.Cell>
                                {item.dias}
                            </Table.Cell>
                            <Table.Cell>
                                {item.faixa}
                            </Table.Cell>
                            <Table.Cell>
                                {new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.premiacao)}
                            </Table.Cell>



                        </Table.Row>
                    ))}


                </StyledTableBody>
            </StyledTable>
            </div>

        )
    }

}

Fechamento.defaultProps = {
    datalist: {}
};

Fechamento.propTypes = {
    datalist: PropTypes.array.isRequired
    , consultoresListFechamentoAction: PropTypes.func.isRequired
};

export default Fechamento

