import {
    B2B_PARCEIROS_LIST
    , B2B_PARCEIROS_ISSEARCHING
    , B2B_PARCEIROS_ISSAVING
    , B2B_PARCEIROS_CHANGE
    , B2B_RELATORIO_LIST
} from '../actions/ActionsTypes';

import { B2BParceiroEntity } from '../entities/B2BParceiroEntity'

const INITIAL_STATE = {
    B2BParceiroEntity
    , issearchingparceiro: false
    , issavingparceiro: false
    , listrelatorio: []
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case B2B_PARCEIROS_LIST:
            return { ...state, B2BParceiroEntity: action.payload }

        case B2B_PARCEIROS_ISSEARCHING:
            return { ...state, issearchingparceiro: action.payload }

        case B2B_PARCEIROS_ISSAVING:
            return { ...state, issavingparceiro: action.payload }

        case B2B_PARCEIROS_CHANGE:
            return { ...state, B2BParceiroEntity: { ...state.B2BParceiroEntity, [action.field]: action.payload } }


        case B2B_RELATORIO_LIST:
            return { ...state, listrelatorio: action.payload }

        default:
            return state;
    }
}