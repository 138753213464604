import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import If from '../../utils/If';


import {
    Grid, Menu, Breadcrumb, Tab, Icon, Table
} from 'semantic-ui-react'

import Pedidos from './Pedidos';

import Relatorio from './Relatorio';


import socketIOClient from "socket.io-client";

import {
    comissoesListAction
    , comissoesVendedoresListAction
    , comissoesSaveAction
    , comissoesChangeVendedorAction
    , comissoesPedidoUpdateAction
    , comissoesRelatorioListAction
    , comissoesUpdateTicketAction

} from '../../actions/ComissoesActions';


import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class Comissoes extends Component {

    state = {}

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 10 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        const socket = socketIOClient(process.env.REACT_APP_API);
        //socket.on("FromAPI", data => this.setState({ response: data }));

        this.props.comissoesVendedoresListAction().then(dados => {

            socket.emit("SocketComissoesConnect", "");
            setInterval(() => socket.emit("SocketComissoesConnect", ""), 3600000)//1 hora

            socket.on("SocketComissoesList", data => this.props.comissoesListAction(data));
            socket.on("SocketComissoesPedidoUpdate", data => this.props.comissoesPedidoUpdateAction(data));
        })

        this.props.comissoesRelatorioListAction().then(relatorio => { })

    }

    componentWillReceiveProps() {

    }

    render() {

        let { comissoes, vendedores, vendedorvcc, login, isloading, comissoesrelatorio, userid } = this.props

        let dadosfiltrados = []

        //Filtrando onde cada vendedor vê o próprio pedido mais e-commerce
        //exceto Guilherme
        //Aline Abreu  userid == 31  foi removida

        if (userid == 2 || userid == 4 || userid == 37 || userid == 3 || userid == 10 || userid == 70 || userid == 87) {
            dadosfiltrados = comissoes;
        } else {
            dadosfiltrados = comissoes.filter(d => d.codvend == vendedorvcc || d.codvend == 94);
        }

        let panes = [

            {
                menuItem: <Menu.Item key='TABPedidos'><Icon name='list alternate outline' />Pedidos</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={isloading}>
                        <Pedidos
                            datalist={dadosfiltrados}
                            vendedores={vendedores}
                            vendedorvcc={vendedorvcc}
                            comissoesSaveAction={this.props.comissoesSaveAction}
                            comissoesChangeVendedorAction={this.props.comissoesChangeVendedorAction}
                            login={login}
                            comissoesUpdateTicketAction={this.props.comissoesUpdateTicketAction}
                        />
                    </Tab.Pane>
            },


        ]

        //rodrigo|daniel|guilherme|michel
        if (userid == 2 || userid == 4 || userid == 37 || userid == 3) {


            panes.push({
                menuItem: <Menu.Item key='TABRelatorio'><Icon name='list alternate outline' />Relatórios</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={isloading}>
                        <Relatorio
                            datalist={comissoesrelatorio}
                        />
                    </Tab.Pane>
            })



        }








        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Comissões do E-Commerce </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated
        , vendedorvcc: state.LoginReducer.vendedorvcc

        , comissoes: state.ComissoesReducer.comissoes
        , vendedores: state.ComissoesReducer.vendedores
        , isloading: state.ComissoesReducer.isloading
        , comissoesrelatorio: state.ComissoesReducer.comissoesrelatorio
    }
);


export default withRouter(connect(mapStateToProps, {
    comissoesListAction
    , comissoesVendedoresListAction
    , comissoesSaveAction
    , comissoesChangeVendedorAction
    , comissoesPedidoUpdateAction
    , comissoesRelatorioListAction
    , comissoesUpdateTicketAction
}
)(Comissoes));

