import {
    ASSISTENCIA_LIST,
    ASSISTENCIA_CARD_LIST,
    ASSISTENCIA_LIST_LOADING,
    ASSISTENCIA_MODAL,
    ASSISTENCIA_MODAL_LOADING,
    ASSISTENCIA_LIST_FILTRO,
    ASSISTENCIA_GRID_LOADING,
    ASSISTENCIA_LOADING_SAVE,
    ASSISTENCIA_LOADING_SEARCH,
    ASSISTENCIA_LOADING_SAVE_IMG_VISITA,
    ASSISTENCIA_ATUALPAG,
    ASSISTENCIA_TOTALPAG,
    ASSISTENCIA_LIST_FILTRO_STATUS
} from '../actions/ActionsTypes';

const INITIAL_STATE = {
    assistencias: [],
    assistenciascard: [],
    assistenciamodal: [],
    isloading: true,
    isloadingGrid: true,
    isloadingModal: false,
    isFiltro: false,
    isLoadginSave: false,
    isLoadginSearch: false,
    isLoadginSaveImageVisita: false,
    atualPag: 1,
    totalPag: 1,
    isFiltroSts: null
}

export default (state = INITIAL_STATE, action) => {


    switch (action.type) {

        case ASSISTENCIA_LIST:
            return { ...state, assistencias: action.payload }
        case ASSISTENCIA_CARD_LIST:
            return { ...state, assistenciascard: action.payload }
        case ASSISTENCIA_LIST_LOADING:
            return { ...state, isloading: action.payload }

        case ASSISTENCIA_LOADING_SAVE:
            return { ...state, isLoadginSave: action.payload }

        case ASSISTENCIA_LOADING_SEARCH:
            return { ...state, isLoadginSearch: action.payload }

        case ASSISTENCIA_LOADING_SAVE_IMG_VISITA:
            return { ...state, isLoadginSaveImageVisita: action.payload }

        case ASSISTENCIA_ATUALPAG:
            return { ...state, atualPag: action.payload }

        case ASSISTENCIA_TOTALPAG:
            return { ...state, totalPag: action.payload }

        case ASSISTENCIA_LIST_FILTRO:
            return { ...state, isFiltro: action.payload }

        case ASSISTENCIA_LIST_FILTRO_STATUS:
            return { ...state, isFiltroSts: action.payload }

        case ASSISTENCIA_GRID_LOADING:
            return { ...state, isloadingGrid: action.payload }

        case ASSISTENCIA_MODAL:
            return { ...state, assistenciamodal: action.payload }
        case ASSISTENCIA_MODAL_LOADING:
            return { ...state, isloadingModal: action.payload }

        default:
            return state;
    }
}