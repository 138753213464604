
import {
    CADASTRODEPRODUTOS_EXPORT_LIST
    , CADASTRODEPRODUTOS_EXPORT_ISEXPORTING
    , CADASTRODEPRODUTOS_SEARCH
    , CADASTRODEPRODUTOS_SEARCH_RESULT

    , CADASTRODEPRODUTOS_EXPORT_LEROY
    , CADASTRODEPRODUTOS_EXPORT_ISLEROYEXPORTING

    , CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE
    , CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE



    , CADASTRODEPRODUTOSB2B_SEARCH
    , CADASTRODEPRODUTOSB2B_SEARCH_RESULT

    , CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE
    , CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE


} from '../actions/ActionsTypes';

import { CadastroDeProdutosInfoEntity } from '../entities/CadastroDeProdutosInfoEntity'

import { CadastroDeProdutosInfoB2BEntity } from '../entities/CadastroDeProdutosInfoB2BEntity'



const INITIAL_STATE = {
    exportar: []
    , isexporting: false
    , exportarLeroy: []
    , isexportingLeroy: false
    , search: []
    , searchresult: []
    , CadastroDeProdutosInfoEntity
    , CadastroDeProdutosInfoB2BEntity
    , searchb2b: []
    , searchresultb2b: []

}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {

        case CADASTRODEPRODUTOS_EXPORT_LIST:
            return { ...state, exportar: action.payload }

        case CADASTRODEPRODUTOS_EXPORT_ISEXPORTING:
            return { ...state, isexporting: action.payload }

        case CADASTRODEPRODUTOS_EXPORT_LEROY:
            return { ...state, exportarLeroy: action.payload }

        case CADASTRODEPRODUTOS_EXPORT_ISLEROYEXPORTING:
            return { ...state, isexportingLeroy: action.payload }

        case CADASTRODEPRODUTOS_SEARCH:
            return { ...state, search: action.payload }

        case CADASTRODEPRODUTOS_SEARCH_RESULT:
            return { ...state, searchresult: action.payload }

        case CADASTRODEPRODUTOS_INFO_FIELDS_CHANGE:
            return { ...state, CadastroDeProdutosInfoEntity: { ...state.CadastroDeProdutosInfoEntity, [action.field]: action.payload } }

        case CADASTRODEPRODUTOS_INFO_CHECKBOX_CHANGE:
            return { ...state, CadastroDeProdutosInfoEntity: { ...state.CadastroDeProdutosInfoEntity, [action.field]: action.payload } }

        case CADASTRODEPRODUTOSB2B_INFO_FIELDS_CHANGE:
            return { ...state, CadastroDeProdutosInfoB2BEntity: { ...state.CadastroDeProdutosInfoB2BEntity, [action.field]: action.payload } }

        case CADASTRODEPRODUTOSB2B_INFO_CHECKBOX_CHANGE:
            return { ...state, CadastroDeProdutosInfoB2BEntity: { ...state.CadastroDeProdutosInfoB2BEntity, [action.field]: action.payload } }



        case CADASTRODEPRODUTOSB2B_SEARCH:
            return { ...state, searchb2b: action.payload }

        case CADASTRODEPRODUTOSB2B_SEARCH_RESULT:
            return { ...state, searchresultb2b: action.payload }


        default:
            return state;
    }
}