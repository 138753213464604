import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import If from '../../utils/If';


import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
    , Button, Table
    , Popup, Dropdown
} from 'semantic-ui-react'


import {
    consultoresListFechamentoAction
    , consultoresListFechamento90Action
    , consultoresUpdateAction
    , consultoresAddValidacao
    , consultoresRemoveValidacao
    , consultoresListAction
    , consultoresListSearchAction
    , consultoresListCidadesAction
} from '../../actions/ConsultoresActions';

import Fechamento from './Fechamento';
import Fechamento90 from './Fechamento90';
import Historico from './Historico';


import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class Consultores extends Component {

    state = {}

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 6 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        //this.props.consultoresListFechamentoAction().then(dados => { })

        this.props.consultoresListAction().then(dados => { })

        this.props.consultoresListCidadesAction().then(dados => { })



    }

    componentWillReceiveProps() {

    }

    render() {

        let { fechamento, fechamento90 } = this.props

        let panes = [

            {
                menuItem: <Menu.Item key='TABHistorico'><Icon name='list alternate outline' />Historico</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={false}>
                        <Historico
                            entidade={this.props.ConsultorBuscaEntity}
                            consultoresUpdateAction={this.props.consultoresUpdateAction}
                            consultoresAddValidacao={this.props.consultoresAddValidacao}
                            consultoresRemoveValidacao={this.props.consultoresRemoveValidacao}
                            validacao={this.props.validacao}
                            isSearching={this.props.isSearching}
                            consultores={this.props.consultores}
                            datalist={this.props.search}
                            consultoresListSearchAction={this.props.consultoresListSearchAction}
                            ConsultorBuscaEntity={this.props.ConsultorBuscaEntity}
                            cidades={this.props.cidades}
                        />
                    </Tab.Pane>
            },







        ]


        if (this.props.userid == "3" || this.props.userid == "2" || this.props.userid == "4" || this.props.userid == "9") {
            panes.push({
                menuItem: <Menu.Item key='TABassinaturas'><Icon name='list alternate outline' />Fechamento</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderConsultoresFechamento}>
                        <Fechamento
                            datalist={fechamento}
                            consultoresListFechamentoAction={this.props.consultoresListFechamentoAction}
                        />
                    </Tab.Pane>
            })

            panes.push({
                menuItem: <Menu.Item key='TABassinaturas2'><Icon name='list alternate outline' />Fechamento90</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderConsultoresFechamento90}>
                        <Fechamento90
                            datalist={fechamento90}
                            consultoresListFechamento90Action={this.props.consultoresListFechamento90Action}
                        />
                    </Tab.Pane>
            })

        }

        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Consultores </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , fechamento: state.ConsultoresReducer.fechamento
        , loaderConsultoresFechamento: state.ConsultoresReducer.loaderConsultoresFechamento

        , fechamento90: state.ConsultoresReducer.fechamento90
        , loaderConsultoresFechamento90: state.ConsultoresReducer.loaderConsultoresFechamento90

        , ConsultorBuscaEntity: state.ConsultoresReducer.ConsultorBuscaEntity
        , validacao: state.ConsultoresReducer.validacao
        , isSearching: state.ConsultoresReducer.isSearching

        , consultores: state.ConsultoresReducer.consultores
        , search: state.ConsultoresReducer.search
        , cidades: state.ConsultoresReducer.cidades

    }
);


export default withRouter(connect(mapStateToProps, {
    consultoresListFechamentoAction
    , consultoresListFechamento90Action
    , consultoresUpdateAction
    , consultoresAddValidacao
    , consultoresRemoveValidacao
    , consultoresListAction
    , consultoresListSearchAction
    , consultoresListCidadesAction
}
)(Consultores));

