import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import If from '../../utils/If';

import {
    Grid, Menu, Breadcrumb, Tab, Icon
    , Table
} from 'semantic-ui-react'


import {
    CadastroDeProdutosExportListAction
    , CadastroDeProdutosExportLeroyAction
    , CadastroDeProdutosInfoSearchAction
    , CadastroDeProdutosInfoCamposAction
    , CadastroDeProdutosInfoCheckboxAction
    , CadastroDeProdutosInfoSaveAction


    , CadastroDeProdutosInfoSearchB2BAction
    , CadastroDeProdutosInfoSaveB2BAction
    , CadastroDeProdutosInfoCheckboxB2BAction
    , CadastroDeProdutosInfoCamposB2BAction


} from '../../actions/CadastroDeProdutosActions';

//import Fechamento from './Fechamento';

import Exportar from './Exportar';
import Informacoes from './Informacoes';
import Informacoesb2b from './Informacoesb2b'


import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class CadastroDeProdutos extends Component {



    constructor(props) {
        moment.locale('pt-br');
        super(props);

        this.state = { tabindex: 0 }


    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 11 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        //Jeter
        if (this.props.userid == '44') {
            this.setState({ tabindex: 1 })
        }


        //this.props.consultoresListAction().then(dados => { })

        //this.props.consultoresListCidadesAction().then(dados => { })



    }

    componentWillReceiveProps() {

    }

    handleTabChange = (e, { activeIndex }) => {
        this.setState({ tabindex: activeIndex });
    }

    render() {

        let { exportar, isexporting
            , exportarLeroy, isexportingLeroy
            , CadastroDeProdutosExportLeroyAction
            , CadastroDeProdutosExportListAction, search, searchresult
            , searchb2b, searchresultb2b

        } = this.props

        let panes = []

        panes.push({
            menuItem: <Menu.Item key='TABInformacoes'><Icon name='list alternate outline' />Informações VCC</Menu.Item>,
            render: () =>
                <Tab.Pane loading={this.props.loaderConsultoresFechamento}>
                    <Informacoes
                        search={search}
                        searchresult={searchresult}
                        CadastroDeProdutosInfoSearchAction={this.props.CadastroDeProdutosInfoSearchAction}
                        CadastroDeProdutosInfoCamposAction={this.props.CadastroDeProdutosInfoCamposAction}
                        CadastroDeProdutosInfoCheckboxAction={this.props.CadastroDeProdutosInfoCheckboxAction}
                        CadastroDeProdutosInfoEntity={this.props.CadastroDeProdutosInfoEntity}
                        CadastroDeProdutosInfoSaveAction={this.props.CadastroDeProdutosInfoSaveAction}
                        login={this.props.login}

                    />
                </Tab.Pane>
        })


        panes.push({
            menuItem: <Menu.Item key='TABInformacoes'><Icon name='list alternate outline' />Informações B2B</Menu.Item>,
            render: () =>
                <Tab.Pane loading={this.props.loaderConsultoresFechamento}>
                    <Informacoesb2b
                        search={searchb2b}
                        searchresult={searchresultb2b}
                        CadastroDeProdutosInfoSearchAction={this.props.CadastroDeProdutosInfoSearchB2BAction}
                        CadastroDeProdutosInfoCamposAction={this.props.CadastroDeProdutosInfoCamposB2BAction}
                        CadastroDeProdutosInfoCheckboxAction={this.props.CadastroDeProdutosInfoCheckboxB2BAction}
                        CadastroDeProdutosInfoEntity={this.props.CadastroDeProdutosInfoB2BEntity}
                        CadastroDeProdutosInfoSaveAction={this.props.CadastroDeProdutosInfoSaveB2BAction}
                        login={this.props.login}

                    />
                </Tab.Pane>
        })

        panes.push({
            menuItem: <Menu.Item key='TABExportacao'><Icon name='list alternate outline' />Exportação</Menu.Item>,
            render: () =>
                <Tab.Pane>
                    <Exportar isexporting={isexporting} isexportingLeroy={isexportingLeroy} exportar={exportar} exportarLeroy={exportarLeroy} CadastroDeProdutosExportLeroyAction={CadastroDeProdutosExportLeroyAction} CadastroDeProdutosExportListAction={CadastroDeProdutosExportListAction} />
                </Tab.Pane>
        })



        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Cadastro de Produtos </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes}
                            activeIndex={this.state.tabindex}
                            onTabChange={this.handleTabChange}


                        />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , isexporting: state.CadastroDeProdutosReducer.isexporting
        , exportar: state.CadastroDeProdutosReducer.exportar

        , exportarLeroy: state.CadastroDeProdutosReducer.exportarLeroy
        , isexportingLeroy: state.CadastroDeProdutosReducer.isexportingLeroy

        , search: state.CadastroDeProdutosReducer.search
        , searchresult: state.CadastroDeProdutosReducer.searchresult

        //Informações do produto
        , CadastroDeProdutosInfoEntity: state.CadastroDeProdutosReducer.CadastroDeProdutosInfoEntity


        , searchb2b: state.CadastroDeProdutosReducer.searchb2b
        , searchresultb2b: state.CadastroDeProdutosReducer.searchresultb2b

        //Informações do produto
        , CadastroDeProdutosInfoB2BEntity: state.CadastroDeProdutosReducer.CadastroDeProdutosInfoB2BEntity



    }
);


export default withRouter(connect(mapStateToProps, {
    CadastroDeProdutosExportListAction
    , CadastroDeProdutosExportLeroyAction
    , CadastroDeProdutosInfoSearchAction
    , CadastroDeProdutosInfoCamposAction
    , CadastroDeProdutosInfoCheckboxAction
    , CadastroDeProdutosInfoSaveAction

    , CadastroDeProdutosInfoSearchB2BAction
    , CadastroDeProdutosInfoSaveB2BAction
    , CadastroDeProdutosInfoCheckboxB2BAction
    , CadastroDeProdutosInfoCamposB2BAction
}
)(CadastroDeProdutos));

