
import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withRouter } from 'react-router';
import { connect } from 'react-redux';


import InputMask from 'react-input-mask';



import moment from 'moment';
import 'moment/locale/pt-br'


import {
  pentefinoListAction
} from '../../actions/PenteFinoActions';

import {
  Table, Segment, Header, Menu, Breadcrumb, Message, Grid, Dimmer, Loader, Image
  , Tab, Button, Icon

} from 'semantic-ui-react'

import If from '../../utils/If';
import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;



const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}
`;


class PenteFinoDePedidos extends Component {

  constructor(props) {
    super(props);
    this.state = {


    };
  }



  fileInputRef = React.createRef();




  componentDidMount() {

    //Verificando permissão de funcionalidade do MENU-------------------
    let { menu, history } = this.props;
    if (menu.filter(m => m.menuid === 21 && m.status === 1).length === 0) { history.push(`/Home`) }
    //------------------------------------------------------------------

    this.props.pentefinoListAction()

  }





  render() {

    let { pentefinoloader, pentefino } = this.props

    let categorias = [];

    for (let i of pentefino) {
      categorias.push({ categoria: i.status })
    }

    categorias = [...new Set(categorias.map(o => JSON.stringify(o)))]
    categorias = categorias.map(o => JSON.parse(o))

    let categoria1 = pentefino.filter(d => d.status == categorias[0].categoria)





    return (

      <div>

        <Menu stackable size='mini'>
          <Menu.Item>
            <Breadcrumb size='mini'>
              <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
              <Breadcrumb.Divider icon='right chevron' />
              <Breadcrumb.Section active>Pente Fino</Breadcrumb.Section>
            </Breadcrumb>
          </Menu.Item>
        </Menu>


        <Tab panes={[
          {
            menuItem: 'Pedidos', render: () => <Tab.Pane loading={pentefinoloader}>

              <If test={categorias.length > 0}>

                {categorias.map(cat =>

                  <div>
                    <hr></hr>
                    <Header size='small'>{cat.categoria}</Header>
                    <StyledTable celled compact >
                      <StyledTableHeader>
                        <Table.Row>
                          <Table.HeaderCell>Data Pedido</Table.HeaderCell>
                          <Table.HeaderCell>FBits</Table.HeaderCell>
                          <Table.HeaderCell>Marketplace</Table.HeaderCell>
                          <Table.HeaderCell>Cliente</Table.HeaderCell>
                          <Table.HeaderCell>NF</Table.HeaderCell>
                          <Table.HeaderCell>Data NF</Table.HeaderCell>
                          <Table.HeaderCell>Valor</Table.HeaderCell>
                          <Table.HeaderCell>Dados de Entrega</Table.HeaderCell>
                          <Table.HeaderCell>Observações BackOffice</Table.HeaderCell>

                        </Table.Row>
                      </StyledTableHeader>

                      <StyledTableBody>
                        {categoria1.map(item =>
                          <Table.Row>
                            <Table.Cell collapsing>{moment(item.dtpedido).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                            <Table.Cell collapsing>{item.pedidofbits}</Table.Cell>
                            <Table.Cell collapsing>{item.marketplace}</Table.Cell>
                            <Table.Cell collapsing>{item.cliente}</Table.Cell>
                            <Table.Cell collapsing> {item.nf}</Table.Cell>
                            <Table.Cell collapsing> {moment(item.dtnf).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                            <Table.Cell collapsing>{new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(item.valor)}</Table.Cell>
                            <Table.Cell collapsing>{item.entrega}</Table.Cell>
                            <Table.Cell collapsing>{item.observacoes}</Table.Cell>
                          </Table.Row>
                        )}

                      </StyledTableBody>

                    </StyledTable>
                  </div>

                )}

              </If>



            </Tab.Pane>
          },

        ]} />




      </div>

    )

  }

}

PenteFinoDePedidos.defaultProps = {

};
PenteFinoDePedidos.propTypes = {
  //codigo: PropTypes.string.isRequired
};

const mapStateToProps = state => ({
  menu: state.LoginReducer.menu
  , login: state.LoginReducer.login
  , pentefino: state.PenteFinoReducer.pentefino
  , pentefinoloader: state.PenteFinoReducer.pentefinoloader
  //, filesmlloader: state.BankReducer.filesmlloader
  //, filesml: state.BankReducer.filesml

})

export default withRouter(connect(mapStateToProps, {
  pentefinoListAction
  //, banklistArquivoMLAction
}
)(PenteFinoDePedidos));
