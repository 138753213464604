import {
    COMPARATIVOPRODUTOS_LIST
    ,COMPARATIVOPRODUTOS_ISSEARCHING
    , COMPARATIVOPRODUTOS_LIST_COMBO
    , COMPARATIVOPRODUTOS_CHANGE
} from '../actions/ActionsTypes'


const INITIAL_STATE = {
    dados:[]
    ,imagens:[]
    ,combo:[]
    ,issearching:false
    ,codigo:``
}

export default (state= INITIAL_STATE,action)=>{
    switch(action.type){
        case COMPARATIVOPRODUTOS_LIST:
            return {...state, dados: action.produtos, imagens: action.imagens}
    
        case COMPARATIVOPRODUTOS_ISSEARCHING:
            return {...state, issearching: action.payload}
        
        case COMPARATIVOPRODUTOS_LIST_COMBO:
            return {...state, combo: action.payload}
        
        case COMPARATIVOPRODUTOS_CHANGE:
            return {...state, codigo: action.payload}


        default:
            return state;
    }
}