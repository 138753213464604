import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import sortBy from 'sort-by';
import parse from 'html-react-parser';
import moment from 'moment';
import 'moment/locale/pt-br'

import {
    Table, Button, Modal, Form, Segment, Message, Image, Header
} from 'semantic-ui-react'

import If from '../../utils/If';
import InputMask from 'react-input-mask';
import styled from 'styled-components';
import ReactExport from "react-data-export";
import ReactToPrint from "react-to-print";


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: 0px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


const StyledTableFooter = styled(Table.Footer)`
&&& 
 th{
    padding: 2px 2px !important;
    margin: 0px 0px 0px 0px !important;
}

`;


class Historico extends Component {


    state = { errordtinicial: 0, errordtfinal: 0 }

    constructor(props) {
        super(props);
    }


    componentWillReceiveProps() {

    }

    componentDidMount() {

    }


    definestatus=(status)=>{
        let texto = ''
        if(status==0){
            texto='Aguardando Integrar'
        }else if(status==1){
            texto='Aguardando Serasa'
        }else if(status==2){
            texto='OK'
        }else if(status==3){
            texto='Erro'
        }
        return texto

    }
 

    handleBusca = () => {
        if(!this.props.isSearching){
            if((this.props.buscaEntity.codparc!="" || this.props.buscaEntity.codparc!=0) || (this.props.buscaEntity.cnpj!="")) {

                this.props.serasaListSearchAction(this.props.buscaEntity).then(dados => {

                }) 
            }
           
        }






    }





    render() {

        let { datalist } = this.props


        return (
            <div>
                <Segment secondary>

                    <Form loading={this.props.isSearching}>
                        <Form.Group widths='equal'>

                            <Form.Input name="codparc" fluid label='Parceiro' placeholder='Código de Parceiro'
                                onChange={this.props.serasaUpdateAction}
                                //onChange={this.handleParceiro.bind(this)}
                            />

                            <div className="field"><label>CNPJ:</label>
                                <InputMask mask="99.999.999/9999-99" maskChar=" " name="cnpj"
                                    placeholder="CNPJ do cliente"
                                onChange={this.props.serasaUpdateAction}
                                //onChange={this.props.novoClienteHandleChangeAction}
                                //onBlur={event => this.Resgata(event.target.value, this.state.radio)}

                                />
                            </div>

                            <div className="field"><label>&nbsp;</label>
                                <Form.Button primary
                                    onClick={this.handleBusca}
                                >Buscar</Form.Button>
                            </div>

                        </Form.Group>



                    </Form>
                </Segment>


                <StyledTable id="listFechamento" compact celled >
                    <StyledTableHeader>
                        <Table.Row>

                            <Table.HeaderCell>iddoc</Table.HeaderCell>
                            <Table.HeaderCell>Documento</Table.HeaderCell>
                            <Table.HeaderCell>Nome Parceiro</Table.HeaderCell>
                            <Table.HeaderCell>DT Consulta</Table.HeaderCell>
                            <Table.HeaderCell>Status</Table.HeaderCell>
                            <Table.HeaderCell width="1">

                            </Table.HeaderCell>

                        </Table.Row>
                    </StyledTableHeader>

                    <StyledTableBody>

                        {datalist.map(item => (
                            <Table.Row key={"search_" + item.iddoc}>

                                <Table.Cell>{item.iddoc}</Table.Cell>
                                <Table.Cell>{item.documento}</Table.Cell>
                                <Table.Cell>({item.codparc}) - {item.nomeparc}</Table.Cell>
                                <Table.Cell>{moment(item.dtconsulta).format("DD/MM/YYYY HH:mm")}</Table.Cell>
                                <Table.Cell negative={(item.status == 3 ? true : false)} positive={(item.status == 2 ? true : false)}>{
                                    this.definestatus(item.status)
                                    
                                    }</Table.Cell>
                    

                                <Table.Cell textAlign="center">

                                    <Modal trigger={<Button basic icon={'search'} color={'blue'} size="mini" />}>
                                        <Modal.Header>Consulta realizada em {moment(item.dtconsulta).format("DD/MM/YYYY HH:mm")} | ({item.codparc}){item.nomeparc} </Modal.Header>
                                        <Modal.Content>
                                            <Modal.Description>

                                            <div ref={el => (this.componentRef = el)}>
                                            {item.logmsg}
                                            {parse('<center>'+item.regrasbody+'</center>')}
                                            {parse('<center>'+item.emailbody+'</center>')}
                                            </div>        


                                        
                                            </Modal.Description>
                                        </Modal.Content>
                                        <Modal.Actions>

                                            <ReactToPrint
                                                trigger={() => <Button color='green'>Imprimir</Button>}
                                                content={() => this.componentRef}
                                                pageStyle="@media print {pre, blockquote, table, div {page-break-inside: avoid; border: 1px solid #ffff;}}"
                                                //onAfterPrint={(e) =>this.props.handlePrint(this.props.datalist)}


                                            />

                                        </Modal.Actions>

                                    </Modal>

                                </Table.Cell>
                            </Table.Row>
                        ))}



                    </StyledTableBody>
                </StyledTable>
            </div>

        )
    }

}

Historico.defaultProps = {
    datalist: []
    , buscaEntity: {}
    , isSearching: false
};

Historico.propTypes = {
    datalist: PropTypes.array.isRequired
    , buscaEntity: PropTypes.object.isRequired
    , isSearching: PropTypes.bool.isRequired
    , serasaUpdateAction: PropTypes.func.isRequired
    , serasaListSearchAction: PropTypes.func.isRequired
};

export default Historico

