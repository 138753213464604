import React, { Component, useState } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';
import DateTimePicker from 'react-datetime-picker';


import If from '../../utils/If';


import {
    Grid, Menu, Breadcrumb, Tab, Label, Icon
    , Button, Table
    , Popup, Dropdown, Divider, Modal
} from 'semantic-ui-react'

import Drop from './Drop'

import {
    despesasDeViagensListSaidasAction
    , despesasDeViagensListMotoristasAction
    , despesasDeViagensListAjudanteAction
    , despesasDeViagensSaidasChangeMotoristaAction
    , despesasDeViagensSaidasChangeAjudanteUmAction
    , despesasDeViagensSaidasChangeAjudanteDoisAction
    , despesasDeViagensSaidasChangeDtSaidaAction
    , despesasDeViagensSaidasCancelAction
    , despesasDeViagensSaidasSaveAction
    , despesasDeViagensAjudanteEditAction
    , despesasDeViagensAjudanteAtualizaAction
    //, despesasDeViagensAjudanteEditNewAction
    , despesasDeViagensAjudanteChangeNomeAction
    , despesasDeViagensAjudanteChangeAtivoAction
    , despesasDeViagensAjudanteInsereAction

    , despesasDeViagensChegadasChangeDtChegadaAction
    , despesasDeViagensChegadasHandleChangeAction
    , despesasDeViagensChegadasInsertAction
    , despesasDeViagensChegadasSaveAction
    , despesasDeViagensListFeriadosAction

    , despesasDeViagensListRecibosAction
    , despesasDeViagensChageDTRecibosAction

    , despesasDeViagensListAssinaturasAction
    , despesasDeViagensChageDTAssinaturasAction
    , despesasDeViagensListFinanceiroAProcessarAction

    , despesasDeViagensListFinanceiroGeradoAction

    , despesasDeViagensTimerFinanceiroGeradoAction
    , despesasDeViagensTimerFinanceiroAProcessarAction
    , despesasDeViagensSaveFinanceiroGeradoAction
    , despesasDeViagensCancelAction

    , despesasDeViagensUpdateBuscaAction
    , despesasDeViagensAddValidacao
    , despesasDeViagensRemoveValidacao
    , despesasDeViagensListSearchAction
    , despesasDeViagensRemoveMesmoDiaAction
    , despesasDeViagensListAdicionalAction

} from '../../actions/DespesasDeViagensActions';

import ModalDespesas from './ModalDespesas';

import ModalRecibos from './ModalRecibos';

import Assinaturas from './Assinaturas';

import AProcessar from './AProcessar';

import Financeiro from './Financeiro';

import ConsultaRJ from './ConsultaRJ';

import Ajudante from './Ajudante';

import Extra from './Extra';






import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;
const StyledTab = styled(Tab)`
&&& {
    div{
        font-size:13px !important;
     },
     span{
         font-size:13px !important;
    }

   }`









class ControleDeViagens extends Component {

    state = {}


    constructor(props) {
        moment.locale('pt-br');
        super(props);
        this.state = {
            valorInput: '', // Estado para o valor do input
            valorOriginal: '', // Estado para o valor original de viagemajudante
            openModal: false,
            closeModal: false
        };
    }


    handleInputAjudanteNomeChange = (e, id) => {
        const novoValor = e.target.value;
        // Atualize o estado com o novo valor
        //this.setState({ valorInput: novoValor });
        // console.log(`Novo valor inserido: ${novoValor} em ${id}`);
        this.props.despesasDeViagensAjudanteChangeNomeAction(id, novoValor);
        //
    };


    handleSalvarClick = () => {
        // Verifique se algum campo está vazio
        if (!idviagemajudanteInput || !viagemajudanteInput || !ativoSelecionado) {
            alert("Todos os campos são obrigatórios");
            return;
        }

        // Verifique se o campo "Nome" contém números
        if (/\d/.test(viagemajudanteInput)) {
            alert("O campo 'Nome' não pode conter números");
            return;
        }

        // Se todas as verificações passaram, crie o objeto entidade
        const entidade = {
            idviagemajudante: idviagemajudanteInput,
            viagemajudante: viagemajudanteInput,
            ativo: ativoSelecionado,
        };

        console.log("Objeto entidade criado:", entidade);
    };


    handleRecebidoRHAssinatura(ordemcarga, entidade) {
        //console.log('######recebeuuuuuu')
        //console.log(entidade)

        this.props.despesasDeViagensChageDTAssinaturasAction(ordemcarga, this.props.userid, entidade).then(d => {

        })
    }



    handlePrintRecibo(ordens) {

        this.props.despesasDeViagensChageDTRecibosAction(ordens, this.props.userid).then(d => {

        })



    }


    handleChangeFormChegada = (e, { value }) => {

    }

    handleSaveChegada = (entidade) => {
        //console.log('SAVE')
        //console.log(entidade)


        //console.log('userid:'+this.props.userid)
        // console.log(this.props.despesas)
        // console.log(this.props.despesasAdicional)
        if (entidade.issaving == false || entidade.issaving == undefined) {
            console.log()
            this.props.despesasDeViagensChegadasSaveAction(entidade, this.props.despesas, this.props.despesasAdicional, this.props.userid).then(d => { this.props.despesasDeViagensListAdicionalAction() })
        }


        //this.props.despesasDeViagensChegadasHandleChangeAction({ target: { 'name': 'modalOpen', 'value': false } }, entidade.ordemcarga)

    }

    handleCloseChegada = (entidade) => {

        //fechando janela
        this.props.despesasDeViagensChegadasHandleChangeAction({ target: { 'name': 'modalOpen', 'value': false } }, entidade.ordemcarga)
    }



    onCancelOrdemCarga(e, entidade) {
        if (this.props.iscanceling == false) {
            this.props.despesasDeViagensCancelAction(entidade).then(d => { })
        }

    }

    handleRemoveMesmodia = (e, ordemcarga) => {

        if (ordemcarga.isremoving == false) {

            this.props.despesasDeViagensRemoveMesmoDiaAction(ordemcarga).then(d => {
                console.log(d)
                if (d[0].status == 'ERRO') {
                    this.openModal()
                }
            })
        }

    }

    openModal = () => {
        this.setState({ closeModal: false });
        this.setState({ openModal: true });
    }

    closeModal = () => {
        this.setState({ openModal: false });
        this.setState({ closeModal: true });
    }









    onChangeModalOpenChegada(e, entidade) {

        //selecionando despesas
        let tipo1 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 1)
        let tipo2 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 2)
        let tipo3 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 3)
        let tipo4 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 4)
        let tipo5 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 5)
        let tipo6 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 6)
        let tipo7 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 7)
        let tipo10 = this.props.viagemdespesas.filter(d => d.tipodespesaviagem === 10)






        let criaDespesa = (ordemcarga, idviagem, idviagemdespesa, dtviagemfinanceiro, codparcmotorista, codparcajudante, codparcajudanteduplo, despesa, valor) => {
            let data = {}

            let dtfinanceiro = new Date(dtviagemfinanceiro)
            dtfinanceiro.setHours(0)
            dtfinanceiro.setMinutes(0)
            dtfinanceiro.setSeconds(0)
            dtfinanceiro.setMilliseconds(0)

            data.ordemcarga = ordemcarga;
            data.idviagem = idviagem;
            data.idviagemdespesa = idviagemdespesa;
            data.dtviagemfinanceiro = dtfinanceiro;
            data.codparcmotorista = codparcmotorista;
            data.codparcajudante = codparcajudante;
            data.codparcajudanteduplo = codparcajudanteduplo;

            data.despesa = despesa;
            data.qtde = 1;
            data.valor = valor;

            return data;
        };


        let isFeriado = (datacorrente) => {



            // alert('corrente antes:'+datacorrente)
            //let novadata = new Date(datacorrente)
            let feriado = false;

            //novadata.setHours(0);
            //novadata.setMinutes(0);
            //novadata.setSeconds(0);
            //novadata.setMilliseconds(0);
            let novadata = new Date(datacorrente.getUTCFullYear(), datacorrente.getUTCMonth(), datacorrente.getUTCDate(), 0, 0, 0);

            //alert('corrente:'+datacorrente)


            for (let f of this.props.feriados) {
                //if (f.descricao == 'REVOLUCAO CONSTITUCIONALISTA DE 1932') {
                let datadoferiado = new Date(f.dtferiado)

                let dtferiado = new Date(datacorrente.getUTCFullYear()
                    , datadoferiado.getUTCMonth(), datadoferiado.getUTCDate(), 0, 0, 0)


                /* alert(`
                 datadoferiado: ${datadoferiado}
                 dtferiado antigo:${f.dtferiado}
                 dtferiado novo:${dtferiado}
                 data para comparar:${novadata}
 
                 descricao:${f.descricao}
                 `)*/


                /*alert(`
                dtferiado:${dtferiado}
                novadata:${novadata}
                descricao:${f.descricao}

                DATA1:${moment(dtferiado).format("DD/MM/YYYY")}
                DATA2:${moment(novadata).format("DD/MM/YYYY")}
                `)*/

                if (moment(dtferiado).format("DD/MM/YYYY") == moment(novadata).format("DD/MM/YYYY")) {
                    //alert('igualNOVO')
                    feriado = true;
                    return feriado;
                }
                /*if (dtferiado == novadata) {
                    alert('igual')
                    feriado = true;
                }*/
                //}

            }

            //if (this.props.feriados.filter(d => moment(d.dtferiado).format("DD/MM/YYYY") == moment(novadata).format("DD/MM/YYYY")).length > 0) {
            /*if (this.props.feriados.filter(d => d.dtferiado == novadata).length > 0) {
                feriado = true;
                
                alert(`
                d.dtferiado:
                novadata:${moment(novadata).format("DD/MM/YYYY")}
                
                `)
            }*/



            return feriado;
        };

        let calculahora = (data) => {
            let valor = ''
            valor = data.getHours().toString() + data.getMinutes().toString().padStart(2, '0')
            return parseInt(valor, 10)

        }


        //Limpa
        this.props.despesasDeViagensChegadasInsertAction([]).then(d => { })

        // Returns an array of dates between the two dates
        let getDates = (startDate, endDate) => {
            var dates = [],
                currentDate = startDate,
                addDays = function (days) {
                    var date = new Date(this.valueOf());
                    date.setDate(date.getDate() + days);
                    return date;
                };
            while (currentDate <= endDate) {
                dates.push(currentDate);
                currentDate = addDays.call(currentDate, 1);
            }
            return dates;
        };


        var dtfim = new Date(entidade.dtprevchegada)
        dtfim.setSeconds(0);

        var dtinicio = new Date(entidade.dtsaida)
        var dtini = new Date(entidade.dtsaida)
        dtini.setHours(dtfim.getHours());
        dtini.setMinutes(dtfim.getMinutes());
        dtini.setSeconds(0);


        let DataDespesas = []



        // Usage
        var dates = getDates(new Date(dtini), new Date(dtfim));
        dates.forEach(function (date) {
            let isUltimoDia = false
            if (date.getDate() == dtfim.getDate()) {
                isUltimoDia = true
            }


            if (entidade.ismotornoturno === 'S') {
                //alert('motorista noturno')

                //noturno01-A cada noite que a viagem acontecer deverão ser acrescentadas as despesas 
                //se chegou até às 08:00 não ganha nada

                //se chegou até às 12:00
                //TIPODESPESAVIAGEM = 3 para o Motorista 
                //e TIPODESPESAVIAGEM = 4 para os Ajudantes.

                //se chegou após às 12:00
                //TIPODESPESAVIAGEM = 1 para o Motorista 
                //e TIPODESPESAVIAGEM = 2 para os Ajudantes.


                if (date < dtfim) { //pernoite

                    if (dtinicio.getDate() != dtfim.getDate()) {


                        if ((date.getDate() + 1) != dtfim.getDate()) {
                            /*alert(`pernoite 
                            date : ${date}
                            soma: ${date.getDate() + 1}
                            fim: ${dtfim.getDate()}
                            `)*/

                            //TIPODESPESAVIAGEM = 1 para o Motorista 
                            //e TIPODESPESAVIAGEM = 2 para os Ajudantes.

                            //TIPODESPESAVIAGEM = 1 para o Motorista 
                            if (entidade.codmotorista != 0) {
                                for (let item of tipo1) {
                                    DataDespesas.push(criaDespesa(
                                        entidade.ordemcarga
                                        , entidade.idviagem
                                        , item.idviagemdespesas
                                        , date
                                        , entidade.codmotorista
                                        , 0//entidade.codajudanteum
                                        , 0//entidade.codajudantedois
                                        , item.viagemdespesa + ' - ' + item.tipo
                                        , item.valor
                                    ));
                                }
                            }
                            //TIPODESPESAVIAGEM = 2 para os Ajudantes.
                            if (entidade.codajudanteum != 0) {
                                for (let item of tipo2) {
                                    DataDespesas.push(criaDespesa(
                                        entidade.ordemcarga
                                        , entidade.idviagem
                                        , item.idviagemdespesas
                                        , date
                                        , 0//entidade.codmotorista
                                        , entidade.codajudanteum
                                        , 0//entidade.codajudantedois
                                        , item.viagemdespesa + ' - ' + item.tipo
                                        , item.valor
                                    ));
                                }
                            }
                            if (entidade.codajudantedois != 0) {
                                for (let item of tipo2) {
                                    DataDespesas.push(criaDespesa(
                                        entidade.ordemcarga
                                        , entidade.idviagem
                                        , item.idviagemdespesas
                                        , date
                                        , 0//entidade.codmotorista
                                        , 0//entidade.codajudanteum
                                        , entidade.codajudantedois
                                        , item.viagemdespesa + ' - ' + item.tipo
                                        , item.valor
                                    ));
                                }
                            }
                        } else {
                            /*alert(`ultima noite 
                            date : ${date}
                            soma: ${date.getDate() + 1}
                            fim: ${dtfim.getDate()}
                            `)*/

                            //se maior que 8:00 ganha
                            if (calculahora(dtfim) > 800
                                && dtinicio.getDate() != dtfim.getDate() //começou e terminou em dias diferentes
                            ) {

                                //antes do meio dia 12:00
                                if (calculahora(dtfim) <= 1200) {
                                    //alert('entrou4')
                                    //alert('antes do meio dia')
                                    //TIPODESPESAVIAGEM = 3 para o Motorista 
                                    //e TIPODESPESAVIAGEM = 4 para os Ajudantes.

                                    //TIPODESPESAVIAGEM = 1 para o Motorista 
                                    if (entidade.codmotorista != 0) {
                                        for (let item of tipo3) {
                                            DataDespesas.push(criaDespesa(
                                                entidade.ordemcarga
                                                , entidade.idviagem
                                                , item.idviagemdespesas
                                                , date
                                                , entidade.codmotorista
                                                , 0//entidade.codajudanteum
                                                , 0//entidade.codajudantedois
                                                , item.viagemdespesa + ' - ' + item.tipo
                                                , item.valor
                                            ));
                                        }
                                    }
                                    //TIPODESPESAVIAGEM = 2 para os Ajudantes.
                                    if (entidade.codajudanteum != 0) {
                                        for (let item of tipo4) {
                                            DataDespesas.push(criaDespesa(
                                                entidade.ordemcarga
                                                , entidade.idviagem
                                                , item.idviagemdespesas
                                                , date
                                                , 0//entidade.codmotorista
                                                , entidade.codajudanteum
                                                , 0//entidade.codajudantedois
                                                , item.viagemdespesa + ' - ' + item.tipo
                                                , item.valor
                                            ));
                                        }
                                    }
                                    if (entidade.codajudantedois != 0) {
                                        for (let item of tipo4) {
                                            DataDespesas.push(criaDespesa(
                                                entidade.ordemcarga
                                                , entidade.idviagem
                                                , item.idviagemdespesas
                                                , date
                                                , 0//entidade.codmotorista
                                                , 0//entidade.codajudanteum
                                                , entidade.codajudantedois
                                                , item.viagemdespesa + ' - ' + item.tipo
                                                , item.valor
                                            ));
                                        }
                                    }



                                } else {//após o meio dia
                                    //TIPODESPESAVIAGEM = 1 para o Motorista 
                                    //e TIPODESPESAVIAGEM = 2 para os Ajudantes.
                                    //alert('depois do meio dia')

                                    //TIPODESPESAVIAGEM = 1 para o Motorista 
                                    if (entidade.codmotorista != 0) {
                                        for (let item of tipo1) {
                                            DataDespesas.push(criaDespesa(
                                                entidade.ordemcarga
                                                , entidade.idviagem
                                                , item.idviagemdespesas
                                                , date
                                                , entidade.codmotorista
                                                , 0//entidade.codajudanteum
                                                , 0//entidade.codajudantedois
                                                , item.viagemdespesa + ' - ' + item.tipo
                                                , item.valor
                                            ));
                                        }
                                    }
                                    //TIPODESPESAVIAGEM = 2 para os Ajudantes.
                                    if (entidade.codajudanteum != 0) {
                                        for (let item of tipo2) {
                                            DataDespesas.push(criaDespesa(
                                                entidade.ordemcarga
                                                , entidade.idviagem
                                                , item.idviagemdespesas
                                                , date
                                                , 0//entidade.codmotorista
                                                , entidade.codajudanteum
                                                , 0//entidade.codajudantedois
                                                , item.viagemdespesa + ' - ' + item.tipo
                                                , item.valor
                                            ));
                                        }
                                    }
                                    if (entidade.codajudantedois != 0) {
                                        for (let item of tipo2) {
                                            DataDespesas.push(criaDespesa(
                                                entidade.ordemcarga
                                                , entidade.idviagem
                                                , item.idviagemdespesas
                                                , date
                                                , 0//entidade.codmotorista
                                                , 0//entidade.codajudanteum
                                                , entidade.codajudantedois
                                                , item.viagemdespesa + ' - ' + item.tipo
                                                , item.valor
                                            ));
                                        }
                                    }

                                }

                            }



                        }


                    }

                }





            } else {

                //alert('motorista diurno')
                //1-Se a viagem começou antes das 18h e terminou no mesmo dia depois das 19h,
                //deverá ser incluída as despesas
                //TIPODESPESAVIAGEM = 5 para o Motorista 
                //e TIPODESPESAVIAGEM = 6 para os Ajudantes.
                if (isUltimoDia) {
                    if (dtinicio.getDate() == dtfim.getDate()//se começou e terminou no mesmo dia
                        && calculahora(dtinicio) < 1800 // começou antes das 18:00h 
                        && calculahora(dtfim) >= 1900 // terminou no mesmo dia depois das 19h
                    ) {

                        //alert(calculahora(dtfim))
                        /*alert(`último dia
                        ${dtinicio.getHours()}
                        ${dtfim.getHours()}
                        ${tipo5.length}
                        ${tipo6.length}
    
                        ajudante: ${entidade.codajudanteum}
                        `)*/


                        //TIPODESPESAVIAGEM = 5 para o Motorista
                        if (entidade.codmotorista != 0) {
                            for (let item of tipo5) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }


                        //TIPODESPESAVIAGEM = 6 para os Ajudantes.
                        if (entidade.codajudanteum != 0) {
                            for (let item of tipo6) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }
                        if (entidade.codajudantedois != 0) {
                            for (let item of tipo6) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }



                    }
                }



                //2-Se a viagem acontecer num sábado ou feriado e neste dia durar mais que 6 horas ou ultrapassar 12h00,
                //então acrescentar as despesas 
                //TIPODESPESAVIAGEM = 3 para o Motorista 
                //e TIPODESPESAVIAGEM = 4 para os Ajudantes.
                //##michel
                if (
                    date.getDay() == 6 || date.getDay() == 0 //se for sábado
                    || isFeriado(date) === true // se for feriado
                ) {
                    //alert('sábado ou feriado')
                    let horaInicio = 0;
                    //se a viagem começou no mesmo dia, considerar a hora digitada
                    if (dtinicio.getDate() == date.getDate()) {
                        horaInicio = dtinicio.getHours()

                        //se a viagem começou em outro dia, considerar 08:00 como hora inicial    
                    } else {
                        horaInicio = 8
                    }

                    if ((date.getHours() - horaInicio) >= 6 //durar mais que 6 horas
                        || calculahora(date) >= 1200 // ou ultrapassar 12h00
                        || date < dtfim//pernoite de fds
                    ) {
                        //alert('entrou1')
                        //TIPODESPESAVIAGEM = 3 para o Motorista
                        if (entidade.codmotorista != 0) {
                            for (let item of tipo3) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }
                        //TIPODESPESAVIAGEM = 4 para os Ajudantes.
                        if (entidade.codajudanteum != 0) {
                            for (let item of tipo4) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }
                        if (entidade.codajudantedois != 0) {
                            for (let item of tipo4) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }

                    }

                    //se terminou após às 19:00
                    //adicionar tipodespesaviagem 5 para motorista
                    //adicionar tiposdespesaviagem 6 para ajudantes
                    if (isUltimoDia && calculahora(date) >= 1900) {

                        //TIPODESPESAVIAGEM = 5 para o Motorista
                        if (entidade.codmotorista != 0) {
                            for (let item of tipo5) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }


                        //TIPODESPESAVIAGEM = 6 para os Ajudantes.
                        if (entidade.codajudanteum != 0) {
                            for (let item of tipo6) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }
                        if (entidade.codajudantedois != 0) {
                            for (let item of tipo6) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }

                    }

                }










                //2b - Se a viagem iniciar no sábado, acrescentar R$50,00 para motorista e ajudante
                //então acrescentar as despesas 
                //TIPODESPESAVIAGEM = 3 para o Motorista 
                //e TIPODESPESAVIAGEM = 4 para os Ajudantes.
                //##michel
                if (
                    dtinicio.getDay() == 6
                ) {
                    //TIPODESPESAVIAGEM = 3 para o Motorista
                    if (entidade.codmotorista != 0) {
                        for (let item of tipo10) {
                            DataDespesas.push(criaDespesa(
                                entidade.ordemcarga
                                , entidade.idviagem
                                , item.idviagemdespesas
                                , date
                                , entidade.codmotorista
                                , 0//entidade.codajudanteum
                                , 0//entidade.codajudantedois
                                , item.viagemdespesa + ' - ' + item.tipo
                                , item.valor
                            ));
                        }
                    }
                    //TIPODESPESAVIAGEM = 4 para os Ajudantes.
                    if (entidade.codajudanteum != 0) {
                        for (let item of tipo10) {
                            DataDespesas.push(criaDespesa(
                                entidade.ordemcarga
                                , entidade.idviagem
                                , item.idviagemdespesas
                                , date
                                , 0//entidade.codmotorista
                                , entidade.codajudanteum
                                , 0//entidade.codajudantedois
                                , item.viagemdespesa + ' - ' + item.tipo
                                , item.valor
                            ));
                        }
                    }
                    if (entidade.codajudantedois != 0) {
                        for (let item of tipo10) {
                            DataDespesas.push(criaDespesa(
                                entidade.ordemcarga
                                , entidade.idviagem
                                , item.idviagemdespesas
                                , date
                                , 0//entidade.codmotorista
                                , 0//entidade.codajudanteum
                                , entidade.codajudantedois
                                , item.viagemdespesa + ' - ' + item.tipo
                                , item.valor
                            ));
                        }
                    }

                }






                //3-A cada noite que a viagem acontecer deverão ser acrescentadas as despesas 
                //TIPODESPESAVIAGEM = 1 para o Motorista 
                //e TIPODESPESAVIAGEM = 2 para os Ajudantes.
                if (date < dtfim) {
                    //alert(`noite   ${date} - ${dtfim}`)

                    //TIPODESPESAVIAGEM = 1 para o Motorista 
                    if (entidade.codmotorista != 0) {
                        for (let item of tipo1) {
                            DataDespesas.push(criaDespesa(
                                entidade.ordemcarga
                                , entidade.idviagem
                                , item.idviagemdespesas
                                , date
                                , entidade.codmotorista
                                , 0//entidade.codajudanteum
                                , 0//entidade.codajudantedois
                                , item.viagemdespesa + ' - ' + item.tipo
                                , item.valor
                            ));
                        }
                    }
                    //TIPODESPESAVIAGEM = 2 para os Ajudantes.
                    if (entidade.codajudanteum != 0) {
                        for (let item of tipo2) {
                            DataDespesas.push(criaDespesa(
                                entidade.ordemcarga
                                , entidade.idviagem
                                , item.idviagemdespesas
                                , date
                                , 0//entidade.codmotorista
                                , entidade.codajudanteum
                                , 0//entidade.codajudantedois
                                , item.viagemdespesa + ' - ' + item.tipo
                                , item.valor
                            ));
                        }
                    }
                    if (entidade.codajudantedois != 0) {
                        for (let item of tipo2) {
                            DataDespesas.push(criaDespesa(
                                entidade.ordemcarga
                                , entidade.idviagem
                                , item.idviagemdespesas
                                , date
                                , 0//entidade.codmotorista
                                , 0//entidade.codajudanteum
                                , entidade.codajudantedois
                                , item.viagemdespesa + ' - ' + item.tipo
                                , item.valor
                            ));
                        }
                    }





                }






                //4-Se nas entregas da Ordem de Carga tiver a cidade do Rio de Janeiro - RJ deverá ser acrescentada as despesas 
                //TIPODESPESAVIAGEM = 7 para o Motorista.
                if (isUltimoDia) {
                    if (entidade.iscidaderj == 'S') {
                        //alert('rio de janeiro')

                        //TIPODESPESAVIAGEM = 7 para o Motorista 
                        if (entidade.codmotorista != 0) {
                            for (let item of tipo7) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }

                    }

                }

                //5-Regra - 26/02/2019 - regra nova. se último dia e terminou após às 19:00, liberar janta para motorista e ajudantes
                //se for diferente de sábado e terminou depois das 20:00, adicionar a janta
                if (date.getDay() != 6 && !isFeriado(date)) { //diferente de sábado, pois se for sábado ja tem regra pra janta na regra anterior

                    //se terminou após às 19:00
                    //adicionar tipodespesaviagem 5 para motorista
                    //adicionar tiposdespesaviagem 6 para ajudantes
                    if (isUltimoDia && calculahora(date) >= 1900
                        && dtinicio.getDate() != dtfim.getDate()
                    ) {

                        //TIPODESPESAVIAGEM = 5 para o Motorista
                        if (entidade.codmotorista != 0) {
                            for (let item of tipo5) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }


                        //TIPODESPESAVIAGEM = 6 para os Ajudantes.
                        if (entidade.codajudanteum != 0) {
                            for (let item of tipo6) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , entidade.codajudanteum
                                    , 0//entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }
                        if (entidade.codajudantedois != 0) {
                            for (let item of tipo6) {
                                DataDespesas.push(criaDespesa(
                                    entidade.ordemcarga
                                    , entidade.idviagem
                                    , item.idviagemdespesas
                                    , date
                                    , 0//entidade.codmotorista
                                    , 0//entidade.codajudanteum
                                    , entidade.codajudantedois
                                    , item.viagemdespesa + ' - ' + item.tipo
                                    , item.valor
                                ));
                            }
                        }

                    }


                }







            }








        });

        this.props.despesasDeViagensChegadasInsertAction(DataDespesas).then(d => { })

        /*this.props.despesasDeViagensChegadasInsertAction([
            { ordemcarga: 1111, idviagem: 1, idviagemdespesa: 0, dtviagemfinanceiro: new Date(), codparcmotorista: 1, codparcajudante: 0, codparcajudanteduplo: 0, despesa: "Despesa de Teste01 - Pernoite Motorista", qtde: 1, valor: 10 }
            , { ordemcarga: 1111, idviagem: 1, idviagemdespesa: 0, dtviagemfinanceiro: new Date(), codparcmotorista: 1, codparcajudante: 0, codparcajudanteduplo: 0, despesa: "Despesa de Teste02 - Pernoite Motorista", qtde: 1, valor: 10 }
            , { ordemcarga: 1111, idviagem: 1, idviagemdespesa: 0, dtviagemfinanceiro: new Date(), codparcmotorista: 1, codparcajudante: 0, codparcajudanteduplo: 0, despesa: "Despesa de Teste03 - Pernoite Motorista", qtde: 1, valor: 10 }

            , { ordemcarga: 1111, idviagem: 1, idviagemdespesa: 0, dtviagemfinanceiro: new Date(), codparcmotorista: 0, codparcajudante: 1, codparcajudanteduplo: 0, despesa: "Despesa de Teste01 - Pernoite Ajudante", qtde: 1, valor: 20 }
            , { ordemcarga: 1111, idviagem: 1, idviagemdespesa: 0, dtviagemfinanceiro: new Date(), codparcmotorista: 0, codparcajudante: 1, codparcajudanteduplo: 0, despesa: "Despesa de Teste02 - Pernoite Ajudante", qtde: 1, valor: 20 }
            , { ordemcarga: 1111, idviagem: 1, idviagemdespesa: 0, dtviagemfinanceiro: new Date(), codparcmotorista: 0, codparcajudante: 1, codparcajudanteduplo: 0, despesa: "Despesa de Teste03 - Pernoite Ajudante", qtde: 1, valor: 20 }

            //,{ordemcarga:1111, idviagem:1,idviagemdespesa:0,codparcmotorista:0,codparcajudante:0,codparcajudanteduplo:1,despesa:"Despesa de Teste01 - Pernoite Ajudante", qtde:1,valor:30}
            //, {ordemcarga:1111, idviagem:1,idviagemdespesa:0,codparcmotorista:0,codparcajudante:0,codparcajudanteduplo:1,despesa:"Despesa de Teste02 - Pernoite Ajudante", qtde:1,valor:30}
            //, {ordemcarga:1111, idviagem:1,idviagemdespesa:0,codparcmotorista:0,codparcajudante:0,codparcajudanteduplo:1,despesa:"Despesa de Teste03 - Pernoite Ajudante", qtde:1,valor:30}
        ]).then(d => { })*/




        //abrindo janela
        this.props.despesasDeViagensChegadasHandleChangeAction({ target: { 'name': 'modalOpen', 'value': true } }, entidade.ordemcarga)
    }
















    onChangeDTChegada = (ordemcarga, data) => {
        //console.log("dataaaaaa")
        //console.log(ordemcarga)
        //console.log(data)
        this.props.despesasDeViagensChegadasChangeDtChegadaAction(ordemcarga, data)
    }






    onChangeDTSaida = (ordemcarga, data) => {
        //console.log("dataaaaaa")
        //console.log(data)
        //console.log(ordemcarga)
        this.props.despesasDeViagensSaidasChangeDtSaidaAction(ordemcarga, data)
    }

    onChangeMotoristaSaida(e, { ordemcarga, value }) {
        //console.log(ordemcarga)
        //console.log(value)
        this.props.despesasDeViagensSaidasChangeMotoristaAction(ordemcarga, parseInt(value, 10))
    }

    onChangeAjudanteUmSaida(e, { ordemcarga, value }) {
        //console.log(ordemcarga)
        //console.log(value)
        this.props.despesasDeViagensSaidasChangeAjudanteUmAction(ordemcarga, parseInt(value, 10))
    }

    onChangeAjudanteDoisSaida(e, { ordemcarga, value }) {
        //console.log(ordemcarga)
        //console.log(value)

        this.props.despesasDeViagensSaidasChangeAjudanteDoisAction(ordemcarga, parseInt(value, 10))
    }

    onCancelSaida(ordemcarga) {
        //console.log(ordemcarga)
        this.props.despesasDeViagensSaidasCancelAction(ordemcarga)

    }


    isDate(date) {
        //console.log('retornou:' + new Date(date))
        return (new Date(date) !== "Invalid Date") && !isNaN(new Date(date));
    }



    onSaveSaida(entidade) {

        entidade.userid = parseInt(this.props.userid, 10)

        //console.log('##data:'+this.isDate(entidade.dtprevsaida))

        this.props.despesasDeViagensSaidasSaveAction(entidade)
    }







    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 5 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------

        this.props.despesasDeViagensListFeriadosAction().then(dados => { })
        this.props.despesasDeViagensListSaidasAction().then(saidas => { })
        this.props.despesasDeViagensListAjudanteAction().then(dados => { })
        this.props.despesasDeViagensListRecibosAction().then(dados => { })
        this.props.despesasDeViagensListAssinaturasAction().then(dados => { })
        this.props.despesasDeViagensListFinanceiroAProcessarAction().then(dados => { })
        this.props.despesasDeViagensListFinanceiroGeradoAction().then(dados => { })

        this.props.despesasDeViagensTimerFinanceiroGeradoAction()
        this.props.despesasDeViagensTimerFinanceiroAProcessarAction()
        this.props.despesasDeViagensListAdicionalAction()


    }

    componentWillReceiveProps() {

    }

    render() {

        let {
            saidas,
            chegadas,
            motoristas,
            ajudantes,
            recibos,
            assinaturas,
            financeiroaprocessar,
            financeirogerado,
            usersrh,
            usersfin,
            iscanceling,
            mesmodia,
            ajudante,
            userid,
            adicionallist,
        } = this.props


        let moto = []
        moto = [{ key: "0", text: `Todos`, value: "0" }, ...motoristas]


        let panes = [

            {
                menuItem: <Menu.Item key='TABpendente'><Icon name='arrow up' />Saídas<Label color='red'>{saidas.length}</Label></Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderDespesasDeViagensSaida}>
                        <div key='1'>{//this.props.tarefaid} Teste1 {this.props.pesquisaid
                        }
                        </div>

                        <Divider horizontal>Ordens de carga pendentes de saída</Divider>

                        <StyledTable celled id="listPendentes" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="1">Ordem de Carga</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Veículo</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Motorista</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="1">2º Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Saída</Table.HeaderCell>

                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {

                                    (saidas.length >= 0 ? (
                                        saidas.map(item => (
                                            <Table.Row key={"saida_" + item.ordemcarga} positive={(item.alterou === 1 ? true : false)}>

                                                {(item.rota != null) ?
                                                    (<Popup
                                                        trigger={<Table.Cell warning>{item.ordemcarga}</Table.Cell>}
                                                        content={`Rota: ${item.rota}`}
                                                        inverted
                                                    />)
                                                    :
                                                    (<Table.Cell>{item.ordemcarga}</Table.Cell>)
                                                }


                                                <Popup
                                                    trigger={<Table.Cell>{item.placa}</Table.Cell>}
                                                    content={item.veiculo}
                                                    inverted
                                                />


                                                <Table.Cell>
                                                    <Dropdown
                                                        options={motoristas}
                                                        selection
                                                        placeholder='Motoristas'
                                                        name='motoristas'
                                                        fluid
                                                        defaultValue={item.codmotorista.toString()}
                                                        onChange={this.onChangeMotoristaSaida.bind(this)}
                                                        ordemcarga={item.ordemcarga}

                                                    />
                                                </Table.Cell>
                                                <Table.Cell>
                                                    <Dropdown
                                                        options={ajudantes}
                                                        selection
                                                        placeholder='Ajudantes'
                                                        name='ajudantes'
                                                        fluid
                                                        onChange={this.onChangeAjudanteUmSaida.bind(this)}
                                                        ordemcarga={item.ordemcarga}
                                                        //defaultValue="0"
                                                        defaultValue={(item.ajudanteum == null ? "0" : item.ajudanteum.toString())}
                                                        search
                                                    /></Table.Cell>
                                                <Table.Cell>
                                                    <Dropdown
                                                        options={ajudantes}
                                                        selection
                                                        placeholder='Ajudantes'
                                                        name='ajudantes'
                                                        fluid
                                                        onChange={this.onChangeAjudanteDoisSaida.bind(this)}
                                                        ordemcarga={item.ordemcarga}
                                                        //defaultValue="0"
                                                        defaultValue={(item.ajudantedois == null ? "0" : item.ajudantedois.toString())}
                                                        search
                                                    /></Table.Cell>
                                                <Table.Cell>




                                                    <DateTimePicker
                                                        onChange={this.onChangeDTSaida.bind(this, item.ordemcarga)}
                                                        //item={item.ordemcarga}
                                                        value={new Date(item.dtprevsaida)}
                                                        locale="pt-br"
                                                        required={true}
                                                        clearIcon=""
                                                        disableClock={true}
                                                    />

                                                    {//<If test={item.alterou === 1}>
                                                    }
                                                    <Button key={`save_${item.ordemcarga}`} basic icon={'gavel'} color={'green'} size="mini"
                                                        onClick={(e) => this.onSaveSaida(item)}
                                                        loading={item.issaving}
                                                    />
                                                    {
                                                        /*
                                                        <Button key={`cancel_${item.ordemcarga}`} basic icon={'cancel'} color={'red'} size="mini"
                                                                                                            onClick={(e) => this.onCancelSaida(item.ordemcarga)}
                                                                                                        />
                                                        
                                                        */

                                                    }
                                                    {
                                                        //</If>
                                                    }



                                                </Table.Cell>


                                            </Table.Row>
                                        ))
                                    ) : (
                                        <Table.Row key={"saida_nennuma"}>
                                            <Table.Cell colSpan='6'>
                                                <p align="center">Não existem ordens de carga para informar a saída nesse momento</p>
                                            </Table.Cell>

                                        </Table.Row>
                                    )

                                    )
                                }


                            </StyledTableBody>
                        </StyledTable>


                        <Divider horizontal>Últimas ordens de carga com saída e chegada no mesmo dia</Divider>



                        <StyledTable celled id="listMesmoDia" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="1">Ordem de Carga</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Veículo</Table.HeaderCell>
                                    <Table.HeaderCell width="3">Motorista</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="2">2º Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Saída</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Chegada</Table.HeaderCell>
                                    <Table.HeaderCell width="1"></Table.HeaderCell>


                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {mesmodia.map(item => (
                                    <Table.Row key={"chegada_" + item.ordemcarga} positive={(item.alterou === 1 ? true : false)}>

                                        {(item.rota != null) ?
                                            (<Popup
                                                trigger={<Table.Cell warning>{item.ordemcarga}</Table.Cell>}
                                                content={`Rota: ${item.rota}`}
                                                inverted
                                            />)
                                            :
                                            (<Table.Cell>{item.ordemcarga}</Table.Cell>)
                                        }


                                        <Popup
                                            trigger={<Table.Cell>{item.placa}</Table.Cell>}
                                            content={item.veiculo}
                                            inverted
                                        />


                                        <Table.Cell>
                                            {item.motorista}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.ajudanteum}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.ajudantedois}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {moment(item.dtsaida).format("DD/MM/YYYY HH:mm")}
                                        </Table.Cell>
                                        <Table.Cell>

                                            {moment(item.dtchegada).format("DD/MM/YYYY HH:mm")}


                                        </Table.Cell>

                                        <Table.Cell textAlign="center">



                                            <Button basic icon={'cancel'} color={'red'} size="mini"
                                                onClick={(e) => this.handleRemoveMesmodia(e, item)}
                                                loading={item.isremoving}
                                            />





                                        </Table.Cell>


                                    </Table.Row>
                                ))}


                            </StyledTableBody>
                        </StyledTable>






                    </Tab.Pane>
            },

            {
                menuItem: <Menu.Item key='TABchegadas'><Icon name='arrow down' />Chegadas<Label color='green'>{chegadas.length}</Label></Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderDespesasDeViagensChegada}>


                        <StyledTable celled id="listChegadas" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="1">Ordem de Carga</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Veículo</Table.HeaderCell>
                                    <Table.HeaderCell width="3">Motorista</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="2">2º Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Saída</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Chegada</Table.HeaderCell>
                                    <Table.HeaderCell width="1"></Table.HeaderCell>


                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {chegadas.map(item => (
                                    <Table.Row key={"chegada_" + item.ordemcarga} positive={(item.alterou === 1 ? true : false)}>

                                        {(item.rota != null) ?
                                            (<Popup
                                                trigger={<Table.Cell warning>{item.ordemcarga}</Table.Cell>}
                                                content={`Rota: ${item.rota}`}
                                                inverted
                                            />)
                                            :
                                            (<Table.Cell>{item.ordemcarga}</Table.Cell>)
                                        }


                                        <Popup
                                            trigger={<Table.Cell>{item.placa}</Table.Cell>}
                                            content={item.veiculo}
                                            inverted
                                        />


                                        <Table.Cell>
                                            {item.motorista}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.ajudanteum}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {item.ajudantedois}
                                        </Table.Cell>
                                        <Table.Cell>
                                            {moment(item.dtsaida).format("DD/MM/YYYY HH:mm")}
                                        </Table.Cell>
                                        <Table.Cell>

                                            <DateTimePicker
                                                onChange={this.onChangeDTChegada.bind(this, item.ordemcarga)}
                                                value={new Date(item.dtprevchegada)}
                                                locale="pt-br"
                                                required={true}
                                                clearIcon=""
                                                disableClock={true}
                                                key={`dtchegada${item.ordemcarga}`}
                                            />


                                        </Table.Cell>

                                        <Table.Cell textAlign="center">





                                            <ModalDespesas
                                                botao={<Button basic icon={'edit'} color={'blue'} size="mini" onClick={(e) => this.onChangeModalOpenChegada(e, item)} />}
                                                entidade={item}
                                                handleChangeForm={this.handleChangeFormChegada.bind(this)}
                                                handleSave={this.handleSaveChegada.bind(this)}
                                                handleClose={this.handleCloseChegada.bind(this)}
                                                despesas={this.props.despesas}
                                                key={'modal' + item.ordemcarga}
                                            />





                                        </Table.Cell>


                                    </Table.Row>
                                ))}


                            </StyledTableBody>
                        </StyledTable>
                    </Tab.Pane>
            },
        ]
        //jao
        if (userid == 2 || userid == 3 || userid == 79 || userid == 26 || userid == 86) {
            panes.push(
                {
                    menuItem: <Menu.Item key='despadc'><Icon name='gavel' />Adicional<Label color='purple'>{adicionallist.length}</Label></Menu.Item>,
                    render: () =>
                        <>
                            <Extra>

                            </Extra>
                        </>
                },
            )
        }

        panes.push(
            {
                menuItem: <Menu.Item key='TABrecibo'><Icon name='print' />Recibos<Label color='red'>{recibos.length}</Label></Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderDespesasDeViagensRecibos}>


                        <ModalRecibos
                            botao={<Button basic color={'red'} size="mini"
                            //onClick={(e) => this.onChangeModalOpenChegada(e, item)} 
                            >Imprimir Todos</Button>}
                            datalist={recibos}
                            handlePrint={this.handlePrintRecibo.bind(this)}
                            key={'modalrecibostodos'}
                        />
                        <br></br><br></br>

                        <StyledTable celled id="listRecibos" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="1">Ordem de Carga</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Veículo</Table.HeaderCell>
                                    <Table.HeaderCell width="3">Motorista</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="2">2º Ajudante</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Saída</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Chegada</Table.HeaderCell>
                                    <Table.HeaderCell width="1">DT Impressão</Table.HeaderCell>
                                    <Table.HeaderCell width="1"></Table.HeaderCell>
                                </Table.Row>
                            </StyledTableHeader>


                            <StyledTableBody>
                                {
                                    recibos.map(item => (
                                        <Table.Row key={"recibo_" + item.ordemcarga}>

                                            {(item.rota != null) ?
                                                (<Popup
                                                    trigger={<Table.Cell warning>{item.ordemcarga}</Table.Cell>}
                                                    content={`Rota: ${item.rota}`}
                                                    inverted
                                                />)
                                                :
                                                (<Table.Cell>{item.ordemcarga}</Table.Cell>)
                                            }


                                            <Popup
                                                trigger={<Table.Cell>{item.placa}</Table.Cell>}
                                                content={item.veiculo}
                                                inverted
                                            />


                                            <Table.Cell>
                                                {item.motorista}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.ajudante}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {item.ajudanteduplo}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(item.dtsaida).format("DD/MM/YYYY HH:mm")}
                                            </Table.Cell>
                                            <Table.Cell>
                                                {moment(item.dtchegada).format("DD/MM/YYYY HH:mm")}
                                            </Table.Cell>

                                            <Table.Cell>
                                                {
                                                    (item.dtreciboimpresso != null) ?
                                                        moment(item.dtreciboimpresso).format("DD/MM/YYYY HH:mm")
                                                        : "--------------"
                                                }
                                            </Table.Cell>

                                            <Table.Cell>

                                                <ModalRecibos
                                                    botao={<Button basic icon={'print'} color={'blue'} size="mini"
                                                    //onClick={(e) => this.onChangeModalOpenChegada(e, item)} 
                                                    />}
                                                    datalist={[item]}
                                                    handlePrint={this.handlePrintRecibo.bind(this)}
                                                    //handleClose={this.handleCloseChegada.bind(this)}
                                                    key={'modalrecibos' + item.ordemcarga}
                                                />
                                                <Button key='btncancel' basic icon={'cancel'} color={'red'} size="mini"
                                                    onClick={(e) => this.onCancelOrdemCarga(e, item)}
                                                    loading={iscanceling}
                                                />

                                            </Table.Cell>


                                        </Table.Row>
                                    ))}


                            </StyledTableBody>


                        </StyledTable>


                    </Tab.Pane>

            },


            {
                menuItem: <Menu.Item key='TABassinaturas'><Icon name='pencil' />Assinaturas RH</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderDespesasDeViagensAssinaturas}>
                        <If test={usersrh.length > 0}>
                            <Assinaturas
                                cargas={assinaturas}
                                handleRecebidoRH={this.handleRecebidoRHAssinatura.bind(this)}
                                userid={this.props.userid}
                                usersrh={usersrh}
                            />
                        </If>
                    </Tab.Pane>

            },


            {
                menuItem: <Menu.Item key='TABFinProc'><Icon name='cogs' /><Label color='blue'>{financeiroaprocessar.filter(d => d.status === 'Aguardando rotina').length}</Label></Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderDespesasDeViagensFinanceiroAProcessar}>
                        <AProcessar
                            datalist={financeiroaprocessar}
                        />
                    </Tab.Pane>

            },

            {
                menuItem: <Menu.Item key='financeiro'>
                    <Icon name='money' />Financeiro<Label color='orange'>{financeirogerado.length}</Label>
                </Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderDespesasDeViagensFinanceiroGerado}>
                        <If test={usersfin.length > 0}>
                            <Financeiro
                                datalist={financeirogerado}
                                userid={this.props.userid}
                                usersfin={usersfin}
                                despesasDeViagensSaveFinanceiroGeradoAction={this.props.despesasDeViagensSaveFinanceiroGeradoAction}
                            />
                        </If>
                    </Tab.Pane>

            },

            {
                menuItem: <Menu.Item key='RJ'><Icon name='crosshairs' />RJ</Menu.Item>,
                render: () =>
                    <Tab.Pane loading={this.props.loaderDespesasDeViagensFinanceiroGerado}>

                        <ConsultaRJ
                            entidade={this.props.RJBuscaEntity}
                            despesasDeViagensUpdateBuscaAction={this.props.despesasDeViagensUpdateBuscaAction}
                            despesasDeViagensAddValidacao={this.props.despesasDeViagensAddValidacao}
                            despesasDeViagensRemoveValidacao={this.props.despesasDeViagensRemoveValidacao}
                            validacao={this.props.validacao}
                            isSearching={this.props.isSearching}
                            motoristas={moto}
                            datalist={this.props.search}
                            despesasDeViagensListSearchAction={this.props.despesasDeViagensListSearchAction}
                            RJBuscaEntity={this.props.RJBuscaEntity}

                        />

                    </Tab.Pane>

            },
        )


        if (userid == 2 || userid == 3 || userid == 19 || userid == 79 || userid == 89 || userid == 90) {
            panes.push(
                {
                    menuItem: <Menu.Item key='ajudantes'><Icon name='address card outline' /><Label color='black'>{ajudante?.length}</Label></Menu.Item>,
                    render: () =>
                        <>  <Ajudante
                            ajudante={ajudante}
                            handleSalvarClick={this.handleSalvarClick}
                            userid={this.props.userid}
                            despesasDeViagensAjudanteChangeNomeAction={this.props.despesasDeViagensAjudanteChangeNomeAction}
                            despesasDeViagensAjudanteChangeAtivoAction={this.props.despesasDeViagensAjudanteChangeAtivoAction}
                            despesasDeViagensAjudanteAtualizaAction={this.props.despesasDeViagensAjudanteAtualizaAction}
                            despesasDeViagensAjudanteEditAction={this.props.despesasDeViagensAjudanteEditAction}
                            despesasDeViagensAjudanteInsereAction={this.props.despesasDeViagensAjudanteInsereAction}
                        />
                        </>
                },
            )
        }




        return (
            <div>
                <Modal
                    open={this.state.openModal}
                    onClose={this.state.closeModal}
                    size='tiny'
                >
                    <Modal.Header>Esta Ordem de carga não pode ser excluida!</Modal.Header>
                    <Modal.Content image>

                        <Modal.Description>
                            <div>
                                <h2>Recibo já conferido pelo RH!</h2>
                            </div>
                        </Modal.Description>
                    </Modal.Content>
                    <Modal.Actions>
                        <Button color='red'
                            onClick={(e) => {
                                this.closeModal();
                            }}                    >
                            <Icon name='checkmark' /> Fechar
                        </Button>
                    </Modal.Actions>
                </Modal >
































































                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Controle de Viagens </Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <StyledTab
                            panes={panes}
                            width={500}
                        />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , saidas: state.DespesasDeViagensReducer.saidas
        , ajudante: state.DespesasDeViagensReducer.ajudante
        , chegadas: state.DespesasDeViagensReducer.chegadas
        , motoristas: state.DespesasDeViagensReducer.motoristas
        , ajudantes: state.DespesasDeViagensReducer.ajudantes
        , loaderDespesasDeViagensSaida: state.DespesasDeViagensReducer.loaderDespesasDeViagensSaida

        , chegadas: state.DespesasDeViagensReducer.chegadas
        , loaderDespesasDeViagensChegada: state.DespesasDeViagensReducer.loaderDespesasDeViagensChegada
        , viagemdespesas: state.DespesasDeViagensReducer.viagemdespesas
        , despesas: state.DespesasDeViagensReducer.despesas
        , despesasAdicional: state.DespesasDeViagensReducer.despesasAdicional
        , feriados: state.DespesasDeViagensReducer.feriados

        , recibos: state.DespesasDeViagensReducer.recibos
        , loaderDespesasDeViagensRecibos: state.DespesasDeViagensReducer.loaderDespesasDeViagensRecibos

        , assinaturas: state.DespesasDeViagensReducer.assinaturas
        , loaderDespesasDeViagensAssinaturas: state.DespesasDeViagensReducer.loaderDespesasDeViagensAssinaturas
        , usersrh: state.DespesasDeViagensReducer.usersrh

        , financeiroaprocessar: state.DespesasDeViagensReducer.financeiroaprocessar
        , loaderDespesasDeViagensFinanceiroAProcessar: state.DespesasDeViagensReducer.loaderDespesasDeViagensFinanceiroAProcessar

        , financeirogerado: state.DespesasDeViagensReducer.financeirogerado
        , loaderDespesasDeViagensFinanceiroGerado: state.DespesasDeViagensReducer.loaderDespesasDeViagensFinanceiroGerado
        , usersfin: state.DespesasDeViagensReducer.usersfin

        , iscanceling: state.DespesasDeViagensReducer.iscanceling

        , RJBuscaEntity: state.DespesasDeViagensReducer.RJBuscaEntity
        , isSearching: state.DespesasDeViagensReducer.isSearching
        , validacao: state.DespesasDeViagensReducer.validacao
        , search: state.DespesasDeViagensReducer.search

        , mesmodia: state.DespesasDeViagensReducer.mesmodia
        , adicionallist: state.DespesasDeViagensReducer.adicionallist


    }
);


export default withRouter(connect(mapStateToProps, {
    despesasDeViagensListSaidasAction
    // , despesasDeViagensListMotoristasAction
    , despesasDeViagensListAjudanteAction
    , despesasDeViagensSaidasChangeMotoristaAction
    , despesasDeViagensSaidasChangeAjudanteUmAction
    , despesasDeViagensSaidasChangeAjudanteDoisAction
    , despesasDeViagensSaidasChangeDtSaidaAction
    , despesasDeViagensSaidasCancelAction
    , despesasDeViagensSaidasSaveAction
    , despesasDeViagensAjudanteEditAction
    , despesasDeViagensAjudanteAtualizaAction
    //, despesasDeViagensAjudanteEditNewAction
    , despesasDeViagensAjudanteChangeNomeAction
    , despesasDeViagensAjudanteChangeAtivoAction
    , despesasDeViagensAjudanteInsereAction

    , despesasDeViagensChegadasChangeDtChegadaAction
    , despesasDeViagensChegadasHandleChangeAction
    , despesasDeViagensChegadasInsertAction
    , despesasDeViagensChegadasSaveAction

    , despesasDeViagensListFeriadosAction
    , despesasDeViagensListRecibosAction
    , despesasDeViagensChageDTRecibosAction

    , despesasDeViagensListAssinaturasAction
    , despesasDeViagensChageDTAssinaturasAction
    , despesasDeViagensListFinanceiroAProcessarAction

    , despesasDeViagensListFinanceiroGeradoAction

    , despesasDeViagensTimerFinanceiroGeradoAction
    , despesasDeViagensTimerFinanceiroAProcessarAction
    , despesasDeViagensSaveFinanceiroGeradoAction

    , despesasDeViagensCancelAction

    , despesasDeViagensUpdateBuscaAction
    , despesasDeViagensAddValidacao
    , despesasDeViagensRemoveValidacao
    , despesasDeViagensListSearchAction

    , despesasDeViagensRemoveMesmoDiaAction
    , despesasDeViagensListAdicionalAction


}
)(ControleDeViagens));

