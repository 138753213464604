import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router';
import { connect } from 'react-redux';
import moment from 'moment';

import If from '../../utils/If';

import { DebounceInput } from 'react-debounce-input';

import {
    Grid, Menu, Breadcrumb, Tab, Icon
    , Table, Segment, Form, Popup, Header, Checkbox, Button, Message
} from 'semantic-ui-react'


import {
    agrupadorListAction
    , agrupadorHandleChangeAction
    , agrupadorSaveAction

} from '../../actions/AgrupadorActions';



import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`;



class Agrupador extends Component {

    state = { query: '', status: '', mensagem: '' }

    constructor(props) {
        moment.locale('pt-br');
        super(props);
    }


    componentDidMount() {

        //Verificando permissão de funcionalidade do MENU-------------------
        let { menu, history } = this.props;
        if (menu.filter(m => m.menuid === 14 && m.status === 1).length === 0) { history.push(`/Home`) }
        //------------------------------------------------------------------



        //this.props.consultoresListAction().then(dados => { })

        //this.props.consultoresListCidadesAction().then(dados => { })



    }

    componentWillReceiveProps() {

    }

    handleSave = () => {

        if (this.props.issaving === false) {
            this.props.agrupadorSaveAction(this.props.parametros).then(d => {
                if (d[0].status = 'OK') {
                    let item = d[0].item

                    this.setState({ status: d[0].status, mensagem: d[0].mensagem })

                    this.props.agrupadorListAction(item).then(d => {
                        this.setState({ query: item })
                    })
                } else {
                    this.setState({ status: d[0].status, mensagem: d[0].mensagem })
                }
            })
        }

        
    }

    searchQuery = (query) => {

        if (query != '') {
            this.props.agrupadorListAction(query).then(d => {
                this.setState({ query: query })

            })
        }



    }

    render() {

        let { produtos } = this.props

        let panes = []

        panes.push({
            menuItem: <Menu.Item key='TABInformacoes'><Icon name='list alternate outline' />Agrupador</Menu.Item>,
            render: () =>
                <Tab.Pane loading={false}>
                    <Grid columns={2} doubling>
                        <Grid.Column>

                            <div className="ui input icon loading">
                                <DebounceInput
                                    minLength={2}
                                    debounceTimeout={1000}
                                    onChange={event => this.searchQuery(event.target.value)}
                                    maxLength={9}
                                    placeholder="Código do SKU..."
                                    value={this.state.query}
                                />

                                {this.props.issearching ? (<i aria-hidden="true" className="spinner icon"></i>) : (<div></div>)}
                            </div>
                            <Segment secondary>

                                <p>
                                    Interface para cadastrar agrupador nos produtos seguindo o exemplo abaixo:
                                    <br />
                                    650;PLACAC1;1200m x 2400mm x 6mm<br />
                                    647;PLACAC1;1200m x 3000mm x 10mm<br />

                                </p>

                                <Form loading={false}>

                                    <Form.Group widths='equal'>

                                        <DebounceInput
                                            element="textarea"
                                            rows={8}
                                            minLength={2}
                                            name="parametros"
                                            debounceTimeout={1000}
                                            onChange={this.props.agrupadorHandleChangeAction}
                                        //onChange={e => this.handleCodigos(e.target.value)}
                                        />
                                    </Form.Group>

                                </Form>


                            </Segment>




                        </Grid.Column>
                        <Grid.Column>
                            <Segment secondary>

                                <div className="agrupador_search">

                                    <StyledTable celled id="listPedidos" compact='very' size='small'>
                                        <StyledTableHeader>
                                            <Table.Row>
                                                <Table.HeaderCell>SKU</Table.HeaderCell>
                                                <Table.HeaderCell>Produto</Table.HeaderCell>
                                                <Table.HeaderCell>Agrupador</Table.HeaderCell>
                                                <Table.HeaderCell>Cor</Table.HeaderCell>
                                            </Table.Row>
                                        </StyledTableHeader>

                                        <StyledTableBody>
                                            {

                                                produtos.map(item => (
                                                    <Table.Row key={"produto_" + item.codprod}>
                                                        <Table.Cell negative={item.repetido}>{item.codprod}</Table.Cell>
                                                        <Table.Cell negative={item.repetido}>{item.nomeproduto}</Table.Cell>
                                                        <Table.Cell negative={item.repetido}>{item.agrupador}</Table.Cell>
                                                        <Table.Cell negative={item.repetido}>{item.cor}</Table.Cell>
                                                    </Table.Row>
                                                ))
                                            }
                                        </StyledTableBody>
                                    </StyledTable>
                                </div>

                            </Segment>
                        </Grid.Column>

                    </Grid>

                    <Grid columns={1} stackable>
                        <Grid.Column>



                            <If test={this.state.status === 'OK'}>
                                <Message attached='top' color={'olive'}>
                                    {this.state.mensagem
                                    }
                                </Message>
                            </If>

                            <If test={this.state.status === 'Error'}>
                                <Message attached='top' color={'red'}>
                                    {this.state.mensagem
                                    }
                                </Message>
                            </If>

                            <Button
                                floated="left"
                                loading={this.props.issaving}
                                primary
                                onClick={this.handleSave}
                            >Gravar</Button>


                        </Grid.Column>


                    </Grid>
                </Tab.Pane>
        })




        return (
            <div>

                <Menu stackable size='mini'>
                    <Menu.Item>
                        <Breadcrumb size='mini'>
                            <Breadcrumb.Section href="/Home">Home</Breadcrumb.Section>
                            <Breadcrumb.Divider icon='right chevron' />
                            <Breadcrumb.Section active>Agrupador</Breadcrumb.Section>
                        </Breadcrumb>
                    </Menu.Item>
                </Menu>

                <Grid columns={1} doubling>
                    <Grid.Column>
                        <Tab panes={panes} />
                    </Grid.Column>
                </Grid>
            </div>
        )
    }
}




const mapStateToProps = state => (
    {
        menu: state.LoginReducer.menu
        , userid: state.LoginReducer.userid
        , login: state.LoginReducer.login
        , isAuthenticated: state.LoginReducer.isAuthenticated

        , produtos: state.AgrupadorReducer.produtos
        , agrupador: state.AgrupadorReducer.agrupador
        , codprod: state.AgrupadorReducer.codprod
        , issearching: state.AgrupadorReducer.issearching
        , parametros: state.AgrupadorReducer.parametros
        , issaving: state.AgrupadorReducer.issaving

    }
);


export default withRouter(connect(mapStateToProps, {
    agrupadorListAction
    , agrupadorHandleChangeAction
    , agrupadorSaveAction

}
)(Agrupador));

