import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { Header, Tab} from 'semantic-ui-react'
import { Table } from 'semantic-ui-react'
import moment from 'moment';
import styled from 'styled-components';


const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
   }    
`;

const StyledTableHeader = styled(Table.Header)`
&&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
}
`;

const StyledTableBody = styled(Table.Body)`
&&& 
 td{
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
}

`

//


class ListFaturadosSemRota extends Component {

    constructor(props) {
        moment.locale('pt-br');
        super(props);
      }


    componentDidMount() {

    }

    

    render() {
        let { dataList, dataQtde, loader 
        } = this.props;
        
    

        return (
            <Tab.Pane loading={loader}>
                        <StyledTable celled id="ListFaturadosSemRota" compact='very' size='small'>
                            <StyledTableHeader>
                                <Table.Row>
                                    <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Data Pedido</Table.HeaderCell>
                                    <Table.HeaderCell width="1">Nota</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Data Nota</Table.HeaderCell>
                                    <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                                    <Table.HeaderCell width="2">Cidade</Table.HeaderCell>
                                    

                                </Table.Row>
                            </StyledTableHeader>

                            <StyledTableBody>
                                {dataList.map(data => (
                                    <Table.Row key={"SR"+data.fbits}>
                                        <Table.Cell negative={data.codtipoper==1063 || data.codtipoper==1112 || data.codtipoper==1069 || data.codtipoper==1111 || data.codtipoper==1068?true:false}>{data.fbits}</Table.Cell>
                                        <Table.Cell negative={data.codtipoper==1063 || data.codtipoper==1112 || data.codtipoper==1069 || data.codtipoper==1111 || data.codtipoper==1068?true:false}>{moment(data.datapedido).format('DD/MM/YYYY')}</Table.Cell>
                                        <Table.Cell negative={data.codtipoper==1063 || data.codtipoper==1112 || data.codtipoper==1069 || data.codtipoper==1111 || data.codtipoper==1068?true:false}>{data.nota}</Table.Cell>
                                        <Table.Cell negative={data.codtipoper==1063 || data.codtipoper==1112 || data.codtipoper==1069 || data.codtipoper==1111 || data.codtipoper==1068?true:false}>{(data.datanota!=null)?moment(data.datanota).format('DD/MM/YYYY HH:mm'):''}</Table.Cell>
                                        <Table.Cell negative={data.codtipoper==1063 || data.codtipoper==1112 || data.codtipoper==1069 || data.codtipoper==1111 || data.codtipoper==1068?true:false}>{data.parceiro}</Table.Cell>
                                        <Table.Cell negative={data.codtipoper==1063 || data.codtipoper==1112 || data.codtipoper==1069 || data.codtipoper==1111 || data.codtipoper==1068?true:false}>{(data.cidade!='Retira'?data.cidade:'')}<Header color={(data.cidade=='Retira'?'teal':'')} size='tiny'>{(data.cidade=='Retira'?'(Retira)':'')}</Header></Table.Cell>
                                    </Table.Row>
                                ))}

                            </StyledTableBody>
                        </StyledTable>

            </Tab.Pane>

        )
    }
}

ListFaturadosSemRota.defaultProps = {
    dataList: []
    , loader: true
};

ListFaturadosSemRota.propTypes = {
    dataList: PropTypes.array.isRequired
    , loader: PropTypes.bool.isRequired
};



export default ListFaturadosSemRota
