import {
    ORCAMENTOS_UPDATE_BUSCA
    , ORCAMENTOS_ISSEARCHING_BUSCA
    , ORCAMENTOS_LIST_SEARCH
} from '../actions/ActionsTypes';

import * as Api from '../utils/API';
import sortBy from 'sort-by';

export const orcamentosListSearchAction = (entidade) => (dispatch) =>
    new Promise(function (resolve, reject) {
        dispatch({ type: ORCAMENTOS_ISSEARCHING_BUSCA, payload: true })
        
        Api.postOrcamentosSearch(entidade).then(dados => {
            let search = dados
         
            dispatch({ type: ORCAMENTOS_LIST_SEARCH, payload: search })
            dispatch({ type: ORCAMENTOS_ISSEARCHING_BUSCA, payload: false })
            resolve([])
        })
    }
)


export const orcamentosUpdateAction = (event) => {

    return {
        type: ORCAMENTOS_UPDATE_BUSCA
        , field: event.target.name
        , payload: event.target.value
    }
}









