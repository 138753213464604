import {
    PENTEFINO_LIST
    , PENTEFINO_LIST_LOADER
} from '../actions/ActionsTypes'

const INITIAL_STATE = {
    pentefino: []
    ,pentefinoloader:true
}

export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case PENTEFINO_LIST:
            return { ...state, pentefino: action.payload }

        case PENTEFINO_LIST_LOADER:
            return { ...state, pentefinoloader: action.payload }

        default:
            return state;
    }
}