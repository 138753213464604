import React, { Component } from "react";
import PropTypes from "prop-types";
import { Button, Header, Tab, Popup, Icon } from "semantic-ui-react";
import { Table } from "semantic-ui-react";
import moment from "moment";
import styled from "styled-components";
import { CopyToClipboard } from "react-copy-to-clipboard";

const StyledTable = styled(Table)`
  &&& {
    border-spacing: 0px;
    margin-top: -10px;
    margin-bottom: 0px;
    padding: 0px 0px;
  }
`;

const StyledTableHeader = styled(Table.Header)`
  &&& th {
    padding: 0px;
    margin: 0px 0px 0px 0px;
  }
`;

const StyledTableBody = styled(Table.Body)`
  &&& td {
    padding: 0px 0px !important;
    margin: 0px 0px 0px 0px !important;
  }
`;

//font-size: .8em;

class ListEntregues extends Component {
  constructor(props) {
    moment.locale("pt-br");
    super(props);
    this.onChangeDevolucao = this.onChangeDevolucao.bind(this);
  }

  componentDidMount() { }

  onChangeDevolucao(e, pedido) {
    if (!pedido.isupdatingdevolucao) {
      //console.log('#############')
      //console.log(pedido)
      //console.log('@@@@@@@@@@@')
      this.props.handleDevolucao(pedido);
    }
  }
  onSavePedido(e) {
    this.setState({ isSaving: true });
    if (this.state.isSaving == false) {
      let pedidos = this.props.chkEmRota;

      for (let p of pedidos) {
        p.ad_tracknusuari = this.props.login;
      }

      this.props.handleSavePedidosEmRota(pedidos).then((d) => {
        this.setState({ isSaving: false });
      });
    }
  }
  scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth", // Faz a rolagem suave
    });
  };
  render() {
    let { dataList, dataQtde, loader } = this.props;
    return (
      <>
        <div style={{ display: 'flex', position: 'fixed', zIndex: '999', right: '20px' }}>
          <button
            onClick={this.scrollToTop}
            style={{
              backgroundColor: "#4CAF50", // cor de fundo verde
              color: "white", // cor do texto
              padding: "5px 10px", // espaçamento interno
              fontSize: "16px", // tamanho da fonte
              border: "none", // remove a borda padrão
              borderRadius: "5px", // cantos arredondados
              cursor: "pointer", // muda o cursor para 'pointer' ao passar o mouse
              boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)", // sombra leve
              transition: "background-color 0.3s ease", // transição suave na cor de fundo
            }}
            onMouseOver={(e) => (e.currentTarget.style.backgroundColor = "#45a049")} // cor ao passar o mouse
            onMouseOut={(e) => (e.currentTarget.style.backgroundColor = "#4CAF50")} // cor ao remover o mouse
          >
            <Icon name='arrow up' />
          </button>
        </div>
        <Tab.Pane loading={loader}>
          <StyledTable
            celled
            id="ListFaturadosSemRota"
            compact="very"
            size="small"
          >
            <StyledTableHeader>
              <Table.Row>
                <Table.HeaderCell width="1">FBits</Table.HeaderCell>
                <Table.HeaderCell width="1">Data Pedido</Table.HeaderCell>
                <Table.HeaderCell width="1">Nota</Table.HeaderCell>
                <Table.HeaderCell width="2">Data Nota</Table.HeaderCell>
                <Table.HeaderCell width="3">Parceiro</Table.HeaderCell>
                <Table.HeaderCell width="2">Cidade</Table.HeaderCell>
                <Table.HeaderCell width="2">Data Entrega</Table.HeaderCell>
                <Table.HeaderCell width="1">Tipo</Table.HeaderCell>
                <Table.HeaderCell width="1">Status</Table.HeaderCell>
                <Table.HeaderCell width="1">Devolução</Table.HeaderCell>
              </Table.Row>
            </StyledTableHeader>

            <StyledTableBody>
              {dataList.map((data) => (
                <Table.Row key={"LE" + data.numnota}>
                  <CopyToClipboard
                    text={data.pedidofbits}
                    style={{ cursor: "cell" }}
                  >
                    <Table.Cell>{data.pedidofbits}</Table.Cell>
                  </CopyToClipboard>

                  <Table.Cell>
                    {moment(data.datapedido).format("DD/MM/YYYY")}
                  </Table.Cell>
                  <CopyToClipboard
                    text={data.chavenfe}
                    onCopy={() =>
                      this.setState({ pedidofbits: data.pedidofbits })
                    }
                    style={{ cursor: "cell" }}
                  >

                    <Table.Cell>{data.numnota}</Table.Cell>
                  </CopyToClipboard>

                  <Table.Cell>
                    {data.datanf != null
                      ? moment(data.datanf).format("DD/MM/YYYY HH:mm")
                      : ""}
                  </Table.Cell>
                  <Table.Cell>{data.parceiro}</Table.Cell>
                  <Popup
                    trigger={
                      <Table.Cell>
                        {data.tipoentrega != "Retira" ? data.cidadeparceiro : ""}
                        <Header
                          color={data.tipoentrega == "Retira" ? "teal" : ""}
                          size="tiny"
                        >
                          {data.tipoentrega == "Retira" ? "(Retira)" : ""}
                        </Header>
                      </Table.Cell>
                    }
                    content={
                      data.tipoentrega != "Retira"
                        ? data.enderecoentrega
                        : "Retira"
                    }
                    inverted
                  />

                  <Popup
                    trigger={
                      <Table.Cell>
                        {data.dtentrega != null
                          ? moment(data.dtentrega).format("DD/MM/YYYY HH:mm")
                          : ""}
                      </Table.Cell>
                    }
                    content={data.trackusuario}
                    inverted
                  />

                  <Table.Cell>{data.tipoentrega}</Table.Cell>

                  <Table.Cell>
                    {data.devolucao > 0 ? "Devolução" : "Entregue"}
                  </Table.Cell>

                  <Table.Cell>
                    <div>
                      <Button
                        basic
                        icon="hand point down outline"
                        color={"red"}
                        size="mini"
                        loading={data.isupdatingdevolucao}
                        onClick={(e) => this.onChangeDevolucao(e, data)}
                      />
                    </div>
                  </Table.Cell>
                </Table.Row>
              ))}
            </StyledTableBody>
          </StyledTable>
        </Tab.Pane>
      </>
    );
  }
}

ListEntregues.defaultProps = {
  dataList: [],
  loader: true,
};

ListEntregues.propTypes = {
  dataList: PropTypes.array.isRequired,
  loader: PropTypes.bool.isRequired,
  handleDevolucao: PropTypes.func.isRequired,
};

export default ListEntregues;
